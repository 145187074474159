import { downloadBuffer } from "../../toolympus/api/core";
import { DateTimeInternalFormat } from "../../toolympus/api/datetimeUtil";
import { utc } from "../../toolympus/components/timezone";
import { PollConfig, PartyPoll, pollConfigToFields } from "./polls.types";
import ExcelJS from "exceljs";

export const exportPollsToXlsx = (config: PollConfig, pollsFlattenned: PartyPoll[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Опросы");

  const fields = pollConfigToFields(config);

  sheet.columns = [
      { header: "ID", key: "_id" },
      { header: "Заполнен", key: "filled_datetime" },
      ...fields.map(f => ({ header: f.label, key: f.key })),
      { header: "Создан", key: "created_datetime" },
  ];

  pollsFlattenned.forEach(poll => {
      const { fields, _id, created_datetime, filled_datetime } = poll;
      sheet.addRow({
          ...fields,
          _id,
          created_datetime: created_datetime ? utc.toLocal(created_datetime).format(DateTimeInternalFormat) : "",
          filled_datetime: filled_datetime ? utc.toLocal(filled_datetime).format(DateTimeInternalFormat) : "",
      })
  });

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `polls.xlsx`));
}
