import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';
import { FieldType, Schema } from '../../hooks/useSchema';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { Form, FormGrid } from '../primitives/Forms';
import { FormControlsForFields, TableForFields } from '../schemed';
import { EventLogData, useEventLog } from './useEventLog';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { ClipboardButton } from '../primitives/Buttons';
import { RefreshOutlined } from '@mui/icons-material';

interface Props {
    data: EventLogData;
}

const ELSchema: Schema = {
    _id: { type: FieldType.number, label_id: "eventlog.labels._id" },
    kind: { type: FieldType.text, label_id: "eventlog.labels.kind" },
    message: { type: FieldType.json, label_id: "eventlog.labels.message" },
    created_at: { type: FieldType.datetime, label_id: "eventlog.labels.created_at" },
}

const ELFilterSchema: Schema = {
    kind: { type: FieldType.text, label_id: "eventlog.labels.kind" },
    timeFrom: { type: FieldType.datetime, label_id: "eventlog.labels.time_from" },
    timeTo: { type: FieldType.datetime, label_id: "eventlog.labels.time_to" },
}

export const EventLogForm = ({ data }: Props) => {
    return (
        <Form
            title={<FormattedMessage id="eventlog.screen_title" />}
            headerItems={<ActionRow>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator />}
                <ClipboardButton getValue={data.getJson} />
            </ActionRow>}
            >
                <form style={{ width: "100%"}} onSubmit={e => { e.preventDefault(); data.applyFilters(); }}>
                    <FormGrid columns="1fr 1fr 1fr max-content" style={{ alignItems: "end" }} noMargin>
                        <FormControlsForFields
                            schema={ELFilterSchema}
                            data={data.filters}
                            onChange={(o,c) => data.updateFilters(c)}
                            fields={[
                                ["kind"],
                                ["timeFrom"],
                                ["timeTo"],
                            ]}
                            />

                        <Button type="submit" color="primary" startIcon={<RefreshOutlined />}><FormattedMessage id="searchField.buttonLabel" /></Button>
                    </FormGrid>
                </form>

                <ActionRow lastItemMarginRight="0" itemMarginTop="0">
                    <OccupyFreeSpace />
                    <Typography variant="caption"><FormattedMessage id="eventlog.labels.total_rows" /> {data.data.length}</Typography>
                </ActionRow>

                <TableForFields
                    schema={ELSchema}
                    data={data.data}
                    fields={[
                        ["kind"],
                        ["created_at", { utcToLocal: true }],
                        ["message"],
                        ["_id"],
                    ]}
                    />
        </Form>
    );
}


export const EventLogContainer = ({ apiPath}: { apiPath: string }) => {
    const data = useEventLog(apiPath);
    return <EventLogForm data={data} />
}
