import { FieldType, Schema } from "../../../hooks/useSchema";
import { nParseDecimal } from "../NumberEdit";
import { Filter, Row } from "./typings";

const valIsEmpty = (val: any) => val === null || val === undefined || val === "" || (Array.isArray(val) && val.length === 0);

const Comparer: Record<string, (a: any,b: any) => boolean> = {
    ">": (a,b) => a > b,
    ">=": (a,b) => a >= b,
    "<": (a,b) => a < b,
    "<=": (a,b) => a <= b,
}

export const createFilterFn = (schema: Schema, f: Filter): (r: Row) => boolean => {
    const s = schema[f?.field || ""] || {};

    if(f.op === 'equals') {
        if(s.type === FieldType.dictionarySelectMulti || s.type === FieldType.multiselect) {
            const selectedVals = f.value as any[] || [];
            const selectedValsSet = new Set(selectedVals);
            return (row: Row) => {
                const rowVals = (row[f.field || ""] || []) as any[];
                return selectedVals.length === rowVals.length && rowVals.every(v => selectedValsSet.has(v));
            } 
        }

        return (row: Row) => row[f.field || ""] === f.value;

    } else if(f.op === 'not_equals') {
        const eqFilter = createFilterFn(schema, { ...f, op: "equals" });
        return (row) => !eqFilter(row);

    } else if(f.op === "oneof" && f.value && Array.isArray(f.value)) {
        const selectedVals = f.value as any[] || [];
        const selectedValsSet = new Set(selectedVals);

        if(s.type === FieldType.dictionarySelectMulti || s.type === FieldType.multiselect) {
            return (row: Row) => {
                const rowVals = (row[f.field || ""] || []) as any[];
                return rowVals && rowVals.length && rowVals.find(v => selectedValsSet.has(v));
            }
        }
        
        return (row: Row) => selectedValsSet.has(row[f.field || ""]);
    } else if(["<", "<=", ">", ">="].includes(f.op)) {
        if(s.type === FieldType.decimal) {
            return (row: Row) => {
                if(!f.field) {
                    return true;
                }
                const rowValue = row[f.field] && nParseDecimal(row[f.field]);
                const filterValue = f.value && nParseDecimal(f.value);
                return !!rowValue && !!filterValue && Comparer[f.op](rowValue, filterValue);
            };
        }
        return (row: Row) => !f.field || Comparer[f.op](row[f.field], f.value);
    } else if(f.op === "empty") {
        return (row: Row) => !f.field || valIsEmpty(row[f.field]);
    } else if(f.op === "notempty") {
        return (row: Row) => !f.field || !valIsEmpty(row[f.field]);
    }
        
    return () => true;
}
