import React from 'react';
import styled from '@emotion/styled';
import { BoardStatementsPreparationData } from './useBoardStatementsPreparation';
import { BottomFloatingPanel, Buttons } from '../../../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';

const Panel = styled(BottomFloatingPanel)`
  .actions {
    margin-top: 0.25rem;
  }
`;

interface Props {
  data: BoardStatementsPreparationData;
}

export const BoardStatementsPreparationPanel = (props: Props) => {
  const { data } = props;

  const isActive = data.state === "_1selecting";
  const nSelected = data.selection.selectedItems.length;
  const canProceed = !!nSelected;

  if(!isActive) {
    return null
  }

  return (
    <Panel>
      <Typography textAlign="center">
        Выберите вопросы для подготовки постановлений
      </Typography>
      <Buttons className="actions">
        <Button size="small" onClick={() => data.selection.selectAll(true)}>
          выбрать все
        </Button>
        <Button size="small" onClick={() => data.selection.selectAll(false)}>
          сбросить все
        </Button>
        <Button size="small" onClick={() => data.cancel()}>
          отмена
        </Button>
        <Button color="primary" variant={canProceed ? "contained" : "text"} disabled={!canProceed} size="small" onClick={() => data.proceed()}>
          подготовить {nSelected || "..."}
        </Button>
      </Buttons>
    </Panel>
  );
}
