import { apiFetch } from "../../../toolympus/api/core";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { QuestionEventBus } from "./usePresidiumQuestion";
import { PresidiumQuestion } from "./usePresidiumQuestions";
import { Option } from './usePresidiumQuestions';

export interface PresidiumResponse {
    question_id: string;
    member_id: string;
    is_voted: boolean;
    is_abstained: boolean;
    is_against_all: boolean;
    is_conflict: boolean;
    response: { vote?: (string | null)[], candidates_conflict?: string[], option?: Pick<Option, "id"> | null } | null;
    comment: string;
    last_modified_by_id: string;
    is_last_modified_by_admin: boolean;
}

export interface PresidiumQuestionResponses extends LoadedData<PresidiumResponse[]> {
    updateResponse: (memberId: string, changes: Partial<PresidiumResponse>) => Promise<PresidiumResponse>;
    cancelResponse: (memberId: string) => Promise<void>;
    canEdit: boolean;
}

interface Config {
    doLoad?: boolean;
    question: PresidiumQuestion;
    eventBus: QuestionEventBus;
}

export const usePresidiumQuestionResponses = (questionId: string, cfg?: Config): PresidiumQuestionResponses => {
    const apiBase = `/api/presidium/question/${questionId}/response`;
    const data = useLoadedData<PresidiumResponse[]>(apiBase, [], !cfg || cfg.doLoad);

    const canEdit = !cfg?.question.is_closed;

    const onResponsesUpdate = () => {
        data.reload();
        if(cfg?.eventBus) {
            cfg.eventBus.dispatch("votes-updated");
        }
    }

    const updateResponse = (memberId: string, changes: Partial<PresidiumResponse>) => {
        return apiFetch<PresidiumResponse>(`${apiBase}/${memberId}`, "put", changes)
            .then(x => {
                onResponsesUpdate();
                return x;
            });
    }

    const cancelResponse = (memberId: string) => {
        return apiFetch<PresidiumResponse>(`${apiBase}/${memberId}`, "delete")
            .then(() => {
                onResponsesUpdate();
                return;
            });
    }

    return {
        ...data,
        updateResponse,
        cancelResponse,
        canEdit,
    }
}