import React, { ReactNode, useEffect } from 'react';
import { RestorePasswordData, useRestorePassword } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { SimpleButton } from '../primitives/Buttons';
import { Button, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from '../primitives';

interface RestorePasswordFormProps {
    restoreAPIPath: string;
    logoAlt?: string;
    successMessage?: ReactNode;
    successRedirect?: string;
    successRedirectTimeout?: number;
}

export const RestorePasswordForm: React.FC<RestorePasswordFormProps> = (props: RestorePasswordFormProps) => {
    const { restoreAPIPath, successRedirect, successRedirectTimeout, successMessage, } = props;
    const { data, update, send, isSaving, isSaved: isSuccess } = useRestorePassword(restoreAPIPath);

    const history = useHistory();

    useEffect(() => {
        if(isSuccess && successRedirect) {
            setTimeout(() => {
                history.replace(successRedirect);
            }, successRedirectTimeout || 1000);
        }
    }, [history, successRedirect, successRedirectTimeout, isSuccess]);

    return (
        <OuterCentralizedForm>
            {!isSuccess && <>
                <TextField
                    label={<FormattedMessage id="restorePassword.password"/>}
                    name='password'
                    type='password'
                    variant='outlined'
                    value={data.password}
                    onChange={e => update({ ...data, password: e.target.value || '' })} />
                <TextField
                    label={<FormattedMessage id="restorePassword.passwordRepeat"/>}
                    name='password repeat'
                    type='password'
                    variant='outlined'
                    value={data.passwordRepeat}
                    onChange={e => update({ ...data, passwordRepeat: e.target.value || '' })} />
                <SimpleButton
                    label={<FormattedMessage id="restorePassword.submit"/>}
                    action={send}
                    isLoading={isSaving}
                />
            </>}
            {isSuccess && (successMessage || <Typography><FormattedMessage id="restorePassword.success"/></Typography>)}
        </OuterCentralizedForm>
    )
};

export const RestorePasswordFields = (props: { data: RestorePasswordData, noButton?: boolean } & Omit<RestorePasswordFormProps, "restoreAPIPath" | "logoAlt">) => {
  const { successRedirect, successRedirectTimeout, successMessage, } = props;
  const { data, update, isSaved: isSuccess } = props.data;

  const history = useHistory();

  useEffect(() => {
      if(isSuccess && successRedirect) {
          setTimeout(() => {
              history.replace(successRedirect);
          }, successRedirectTimeout || 1000);
      }
  }, [history, successRedirect, successRedirectTimeout, isSuccess]);
  
  return <>
    {!isSuccess && <>
      <TextField
          label={<FormattedMessage id="restorePassword.password"/>}
          name='password'
          type='password'
          variant='outlined'
          value={data.password}
          onChange={e => update({ ...data, password: e.target.value || '' })} />
      <TextField
          label={<FormattedMessage id="restorePassword.passwordRepeat"/>}
          name='password repeat'
          type='password'
          variant='outlined'
          value={data.passwordRepeat}
          onChange={e => update({ ...data, passwordRepeat: e.target.value || '' })} />

      {!props.noButton && 
        <Button
          type='submit'
          variant='contained'
          color='primary'
          startIcon={props.data.isSaving ? <LoadingIndicator color="inherit" sizeVariant="s" /> : undefined}>
          <FormattedMessage id="restorePassword.submit"/>
        </Button>}
    </>}
    {isSuccess && (successMessage || <Typography><FormattedMessage id="restorePassword.success"/></Typography>)}
  </>
  
}
