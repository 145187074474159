import { useMemo, useState } from "react";
import { useBrowserStoredValue } from "../../hooks/useBrowserStoredValue";
import { useLoadedData, PagedData } from "../../hooks/useLoadedData";
import { NumberingSequence, NumberingSequenceEvent } from "./types";
import { useAction } from "../../api/useAction";
import { apiFetch } from "../../api/core";
import { useCopyText } from "../primitives";
import { useFormats } from "../schemed";

export const useNumberingSequences = (apiPath: string) => {

  const allSequences = useLoadedData<NumberingSequence[]>(`${apiPath}`, []);

  const currentId = useBrowserStoredValue("", "sequence", "_tl_numbering_sequence");

  const events = useLoadedData<PagedData<NumberingSequenceEvent>>(
    `${apiPath}/${currentId.value}/event?page-size=7`,
    { data: [], page_number: 1, page_size: 50, start: 0, total_pages: 1, total_rows: 0 },
    !!currentId.value);


  const current = useMemo(() => {
    return allSequences.data.find(s => s._id === +currentId.value);
  }, [currentId.value, allSequences.data]);

  const [generatedNumber, setGeneratedNumber] = useState<string | null>(null);
  const copyText = useCopyText();
  const formats = useFormats();
  const formatNumberForClipboard = (n: string) => `${n || ""} от ${formats.formatDate(new Date())}`;

  const generateNumber = useAction(() => {
    return apiFetch<{ formatted: string }>(`${apiPath}/${current?._id}/number`)
      .then(x => { setGeneratedNumber(x.formatted); copyText(formatNumberForClipboard(x.formatted)); events.reload(); return x; })
  }, !generatedNumber && !!current?._id);

  const reset = () => {
    setGeneratedNumber(null);
  }
  
  return {
    allSequences,
    currentId,
    events,
    current,

    generateNumber,
    generatedNumber,
    generatedNumberFormatted: generatedNumber ? formatNumberForClipboard(generatedNumber) : "",
    reset,
  }
}

export type NumberingSequencesData = ReturnType<typeof useNumberingSequences>;
