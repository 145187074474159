import React, { CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled/macro';
import {
    Card,
    Container,
    Paper,
    PaperProps,
    Typography,
} from '@mui/material';
import { scrollStyle } from './Common.styles';

export const FormWrapper = styled(Paper)`
    width: 100%;
    min-height: 100%;

    padding: 0.5rem 1rem;
    box-sizing: border-box;
`;

export const FormHeader = styled.div<{ alignItems?: React.CSSProperties["alignItems"]}>`
    display: flex;
    flex-flow: row;
    align-items: ${props => props.alignItems || "center"};

    & > button {
        margin-left: 10px;
    }
    gap: 0.5rem;

    margin-bottom: 10px;
`;

export const FormTitle = styled(Typography)<{ allowWrap?: boolean}>`
    white-space: ${props => props.allowWrap ? "initial" : "nowrap"};
`;
FormTitle.defaultProps = { variant: 'h4' };
export const FormSmallerTitle = styled(Typography)`
`;
FormSmallerTitle.defaultProps = { variant: 'h5' };

export const SectionTitle = styled(Typography)`
`;
SectionTitle.defaultProps = { variant: 'h6' };

export interface FormGridProps {
    noMargin?: boolean;
    columns?: string;
    gap?: 'normal' | 'dense';
    areas?: (string | null)[];
    forceEvenColumns?: boolean;
    keepColumnsOnSm?: boolean;
    suppressAreasOnSm?: boolean;
    suppressContentColumnsOnSm?: boolean;
    alignItems?: CSSProperties["alignItems"];
};

const FormGridDefaultColumns = "1fr 1fr 1fr"

export const FormGrid = styled.div<FormGridProps>`
    display: grid;
    height: max-content;
    align-items: ${props => props.alignItems || "flex-start"};
    grid-template-columns: ${props => props.columns || FormGridDefaultColumns};
    gap: ${props => props.gap === 'dense' ? '0.25rem 0.75rem' : '0.35rem 36px'};
    ${props => props.areas && props.areas.length ?
        `grid-template-areas: \n${props.areas.filter(a => !!a).map(a => "'" + a + "'").join('\n')};`
        : ''}

    margin-top: ${props => props.noMargin ? 0 : '1rem'};

    ${props => props.forceEvenColumns ? `
        & > * {
            min-width: 0;
        }
    ` : ''}

    ${props => props.theme?.breakpoints?.down("sm") || "@media(max-width: 959.95px)"} {
        grid-template-columns: ${props => props.keepColumnsOnSm ? props.columns || FormGridDefaultColumns : "1fr"};
        
        ${props => props.suppressAreasOnSm ? `
        grid-template-areas: none;

        & > * {
            grid-area: initial !important;
        }
        `
        : ""}
        & > * {
          ${props => props.suppressContentColumnsOnSm
            ? `grid-column: auto !important;`
            : ""}

        }
    }

`;

export const createColumns = (c: string, n: number) => Array(n).fill(c).join(" ");
export const cols = createColumns;

export const FormRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    &:first-child {
        margin-top: 1rem;
    }

    &:not(:first-child) {
        margin-top: 2rem;
    }
`;

const Column = styled.div`
    display: flex;
    flex-flow: column;

    & > * {
        margin: 0.35rem 0;
    }
`;

export const FormColumn = styled(Column)`
    width: 330px;
    & > * {
        width: 300px;
    }
`;

export const FormWiderColumn = styled(Column)`
    width: 490px;
    & > * {
        width: 460px;
    }
`;

export const FormFullColumn = styled(Column)`
    width: 100%;
`;

export const FormBody = styled.div`
    display: flex;
    flex-flow: column;

    ${props => scrollStyle(props.theme, { size: 5 })}
`;


interface SimpleFormProps {
    title?: string | ReactNode;
    headerItems?: ReactNode;
    children?: ReactNode;
    formPaperProps?: PaperProps;
    formBodyProps?: React.HTMLAttributes<HTMLDivElement>;
    formHeaderProps?: React.ComponentProps<typeof FormHeader>;
    fitFullHeight?: boolean;
    allowTitleWrap?: boolean;
    className?: string;
    noTitle?: boolean;
}

export const Form = (props: SimpleFormProps) => {
    const { title, headerItems, children, formPaperProps, formBodyProps } = props;

    let formPaperStyle = formPaperProps?.style || {};
    let formBodyStyle = formBodyProps?.style || {};

    if(props.fitFullHeight) {
        formPaperStyle = {
            display: 'flex',
            flexFlow: 'column',
            height: "100%",
            ...formPaperStyle
        };
        formBodyStyle = {
            maxHeight: "100%",
            overflowY: 'hidden',
            ...formBodyStyle,
        }
    }

    return (
        <FormWrapper className={`form-wrapper ${props.className}`} {...formPaperProps} style={formPaperStyle}>
            <FormHeader className="form-header" {...(props.formHeaderProps || {})}>
                {!props.noTitle && <FormTitle allowWrap={props.allowTitleWrap}>{title}</FormTitle>}

                {headerItems}
            </FormHeader>

            <FormBody className="form-body" {...formBodyProps} style={formBodyStyle} >
                {children}
            </FormBody>
        </FormWrapper>);
}

export const SimpleForm = (props: SimpleFormProps) => {
    const { title, headerItems, children } = props;

    return (
        <Form title={title} headerItems={headerItems} className={props.className}>
            <FormColumn>
                {children}
            </FormColumn>
        </Form>);
}


const OuterCentralizedFormWrapper = styled(Card)`
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 12px;
    
    padding: 6px 0;

    & > * {
        width: 300px;

        ${props => props.theme.breakpoints.down("sm")} {
          width: 100%;  
        }
    }

`;

export const OuterCentralizedForm = (props: SimpleFormProps) => {
    const { children } = props;

    return (
        <Container className={props.className}>
            <OuterCentralizedFormWrapper elevation={0}>
                {children}
            </OuterCentralizedFormWrapper>
        </Container>
    )
}