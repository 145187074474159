import { List, ListItem, ListItemText, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Editor } from 'slate';
import { useSlateStatic } from 'slate-react';
import { ActionRow, ButtonOptionPicker, DialogState, LoadingIndicator, OccupyFreeSpace, SimpleDialog } from '../primitives';
import { useTextFilter } from '../schemed/Filtering/useTextFilter';
import { useCurrentDocumentationPage } from './CurrentDocumentationPageContext';
import { useDocumentationPages } from './DocumentationPagesContext';
import { DocumentationDoc, DocumentationDocBase } from './useDocumentationPages';

interface Props {
    state: DialogState;
    createDocument: (editor: Editor, title: string) => Promise<any | void>;
    insertExistingDocument: (editor: Editor, document: DocumentationDocBase) => void;
    isSaving: boolean;
}

export const InsertDocumentationPageDialog = (props: Props) => {
    const [title, setTitle] = useState<string>("");
    const [mode, setMode] = useState<"new" | "existing">("new");
    const editor = useSlateStatic();
    const documents = useDocumentationPages();
    const currentDoc = useCurrentDocumentationPage();
    const filter = useTextFilter<Omit<DocumentationDoc, "content">>(d => d.title);

    const isOpen = props.state.isOpen;

    useEffect(() => setMode("new"), [isOpen]);

    const filteredDocuments = filter.filterData(documents.documents.filter(d => d._id !== currentDoc?._id));

    return (
        <SimpleDialog
            {...props.state}
            dialogTitle={<ActionRow itemMarginTop='0' lastItemMarginRight='0'>
                <FormattedMessage id="documentation.create_doc.dialog_title" />
                <OccupyFreeSpace />

                <ButtonOptionPicker
                    options={[
                        [<FormattedMessage id="documentation.create_doc.mode.new" />, "new"],
                        [<FormattedMessage id="documentation.create_doc.mode.existing" />, "existing"],
                    ]}
                    selected={mode}
                    setSelected={setMode}
                    />
                </ActionRow>}
            
            save={() => {
                if(editor && mode === "new" && title) {
                    props.createDocument(editor, title)
                        .then(() => {
                            setTitle("");
                            props.state.close();
                        });
                } else if(editor && mode === "existing" && filteredDocuments.length === 1) {
                    props.insertExistingDocument(editor, filteredDocuments[0]);
                    props.state.close();
                }
            }}
            saveLabel={props.isSaving ? <LoadingIndicator sizeVariant="font" /> : undefined}
            noFullscreen>
                
            {mode === "new" && <TextField
                value={title}
                onChange={e => setTitle(e.target.value)}
                fullWidth
                autoFocus
                label={<FormattedMessage id="documentation.create_doc.document_title" />}
                />}

            {mode === "existing" && <>
                <TextField
                    label={<FormattedMessage id="searchField.label" />}
                    value={filter.filter}
                    onChange={e => filter.setFilter(e.target.value)}
                    autoFocus
                    fullWidth
                    />
                <List>
                    {filteredDocuments.map(d => (
                        <ListItem key={d._id} button onClick={() => {
                            props.insertExistingDocument(editor, d);
                            props.state.close();
                            }}>
                            <ListItemText primary={d.title} />
                        </ListItem>
                    ))}
                </List>
            </>}
        </SimpleDialog>
    );
}
