import { Button, Typography, TextField, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { FieldDefinition, TableForFields } from "../schemed";
import { SelectDictionary } from "./SelectDictionary";
import { LoadingIndicator } from "../primitives/LoadingIndicator";
import { SaveButton } from "../primitives/Buttons";
import { FieldType, Schema } from "../../hooks/useSchema";
import { Form } from "../primitives/Forms";
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { DictionaryRecord, ExtraFieldPrefix, useDictsApi } from "../../hooks/useDictionaries";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "../../hooks/useQueryParamsState";
import { useHistory, useLocation } from "react-router-dom";
import { useKeyupWithAltEvent } from "../../hooks/useDocumentEvent";
import { Add, GetAppOutlined, PublishOutlined, Sort } from "@mui/icons-material";
import { FormGrid, MoreActionsMenuButton, SimpleDialog, useWindowHotkey } from "../primitives";
import { ReorderItemsDialog, useReorderItemsBySortorder } from "../primitives/ReorderItems";


export interface DictionariesProps {
    languages?: string[];
    hideDictionaries?: string[];
    apiPath?: string;
}

export const DictionarySchema: Schema = {
  code: { label: "Код", label_id: "dictionaries.fields.code" },
  label: { label: "Название", label_id: "dictionaries.fields.label" },
  label_translations: { label: "Переводы названия", label_id: "dictionaries.fields.label_translations" },
  sortorder: { label: "Порядок", label_id: "dictionaries.fields.sortorder", type: FieldType.number },
  comment: { label: "Комментарий", label_id: "dictionaries.fields.comment" },
  disabled: { label: "Отключено", label_id: "dictionaries.fields.disabled", type: FieldType.bool },
}

const DictionaryRecordToReorder = (props: { item: DictionaryRecord }) => (
  <Typography>
    {props.item.label} <Typography variant="caption" color="textSecondary">{props.item.code}</Typography>
  </Typography>
)

export const DictionariesList = (props: DictionariesProps) => {
    const { 
        loading,
        dicts, 
        dict, 
        newRecord,
        setDictByKey, 
        onUpdate, 
        remove,
        hasChanges,
        save,
        reload,
        updateRecordNow,

        exportAction,
        importAction,
    } = useDictsApi(props.apiPath);

    const { formatMessage } = useIntl();
    
    const schema = useMemo(
        () => {
            const labelTranslationsSchemas = (props.languages || []).reduce(
                (r,l) => ({
                  ...r,
                  [`label_${l}`]: {
                    ...DictionarySchema["label"],
                    label: formatMessage({ id: "dictionaries.fields.label_lang"}, { lang: l }),
                    label_id: null,
                  },
                }),
                {});
            return {
              ...DictionarySchema,
              ...labelTranslationsSchemas,
              ...Object.entries(dict?.extra?.ui || {}).reduce<Schema>((r,[f,s]) => { r[`${ExtraFieldPrefix}${f}`] = s; return r; }, {}),
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.languages, dict?.extra?.ui],
    );
    
    const fields: FieldDefinition[] = useMemo(
      () => ["code", "label"]
          .concat((props.languages || []).map(l => `label_${l}`)).concat(["sortorder", "comment", "disabled"])
          .concat(Object.keys(dict?.extra?.ui || {}).sort().map(f => `${ExtraFieldPrefix}${f}`))
          .map(key => [key, { editable: key !== 'code' }]),
      [props.languages, dict?.extra?.ui]);
    

    const query = useQuery();
    const history = useHistory();
    const location = useLocation();
    const queryParam = "dictionary_id";
    const selectedDictId = query.get(queryParam);

    useEffect(() => {
        if(selectedDictId && (!dict || selectedDictId !== dict.id)) {
            setDictByKey(selectedDictId);
        }
    }, [dict, selectedDictId, setDictByKey]);

    const startAddRecord = () => {
      if(dict) {
        newRecord.startEditing({ code: "", label: "" });
      }
    }

    const reorderItems = useReorderItemsBySortorder<DictionaryRecord>({
      updateItemOrder: (item, order) => {
        if(item.sortorder !== order) {
          return updateRecordNow(dict?.id || "", item.code, "", { sortorder: order });
        } else {
          return Promise.resolve(item);
        }
      },
      onSave: () => reload(),
    });

    useKeyupWithAltEvent('s', () => save());
    useKeyupWithAltEvent('ы', () => save());
    useWindowHotkey("alt+n", () => startAddRecord());

    const selectDictionary = (key: string) => {
        setDictByKey(key);
        query.set(queryParam, key);
        history.replace({ pathname: location.pathname, search: `?${query.toString()}`});
    }

    const getRowId = useCallback((r: DictionaryRecord) => r.code, []);

    const rowButtons = useMemo(() => {
      return (row: DictionaryRecord) => (
        <MoreActionsMenuButton actions={[["remove", <FormattedMessage id="common.delete" />, () => remove.run(row)]]} />
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dict?.id])
    
    return (
        <Form title={<FormattedMessage id="dictionaries.title" />}
            headerItems={(
                <>
                    <OccupyFreeSpace />

                    {!!dict?.id &&
                      <Button
                        size="small"
                        color="primary"
                        startIcon={<Sort />}
                        onClick={() => reorderItems.startEditing(dict.records)}
                        disabled={hasChanges}>
                        <FormattedMessage id="dictionaries.reorder" />
                      </Button>}

                    {!!dict?.id && <Button
                      size="small"
                      color="primary"
                      onClick={() => exportAction.run()}
                      disabled={!exportAction.canRun || exportAction.isRunning}
                      startIcon={<GetAppOutlined />}
                      endIcon={exportAction.isRunning && <LoadingIndicator sizeVariant="s" />}
                      >
                        <FormattedMessage id="cms.importexport.export_action" />
                    </Button>}
                    
                    {!!dict?.id && <Button
                      size="small"
                      color="primary"
                      onClick={() => importAction.run()}
                      disabled={!importAction.canRun || importAction.isRunning}
                      startIcon={<PublishOutlined />}
                      endIcon={importAction.isRunning && <LoadingIndicator sizeVariant="s" />}
                      >
                      <FormattedMessage id="cms.importexport.import_action" />
                    </Button>}
                    
                    <div style={{ width: "2rem "}} />

                    {dict && (
                        loading ? <LoadingIndicator /> :
                            (!hasChanges ? 
                                <Typography color="primary" variant="caption">
                                    <FormattedMessage id="dictionaries.statusSaved" />
                                </Typography>
                                :
                                <SaveButton action={() => save()} />))}
                </>
            )}>

            <FormGrid columns="1fr 2fr max-content" noMargin style={{ alignItems: "center" }}>
              <SelectDictionary 
                  value={dict?.id}
                  values={Object.keys(dicts)
                    .filter(key => !props.hideDictionaries || !props.hideDictionaries.length || !props.hideDictionaries.includes(key) )
                    .map(key => ({value: key, label: dicts[key].comment }))
                    .sort((a,b) => a.label > b.label ? 1 : -1)}
                  onChange={selectDictionary}
                  />

              <div />

              {!!dict && <IconButton color="primary" size="small" onClick={() => startAddRecord()}><Add /></IconButton> }
            </FormGrid>
            
            {dict && 
              <TableForFields 
                  fields={fields}
                  schema={schema}
                  data={dict.records}
                  onChange={onUpdate}
                  getRowId={getRowId}
                  editByOneRow
                  rowButtons={rowButtons}
              />}

            <SimpleDialog
              isOpen={newRecord.isEditing}
              close={newRecord.cancel}
              noFullscreen
              dialogTitle={<FormattedMessage id="common.add" />}
              save={() => {
                if(!loading && !!newRecord.item?.code?.trim()) {
                  newRecord.save();
                }
              }}
              >
              {newRecord.item && <FormGrid columns="1fr">
                <TextField
                  label={<FormattedMessage id="dictionaries.newRecordLabel" />}
                  value={newRecord.item.code || ""}
                  onChange={e => newRecord.update({ code: e.target.value})}
                  autoFocus
                  />
                <TextField
                  label={<FormattedMessage id="dictionaries.fields.label" />}
                  value={newRecord.item.label || ""}
                  onChange={e => newRecord.update({ label: e.target.value})}
                  />
              </FormGrid>}
            </SimpleDialog>

            {!!dict?.id &&
              <ReorderItemsDialog
                data={reorderItems}
                ItemComponent={DictionaryRecordToReorder}
                title={<FormattedMessage id="dictionaries.reorder" />}
                itemKey={r => r.code}
                />}
        </Form>);
}