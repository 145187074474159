import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { AttachFile, Battery20, BatteryChargingFull, CloudUploadOutlined, Code, DeleteOutlined, EventOutlined, FileCopyOutlined, InfoOutlined, ShortText } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { useItemWithControls } from '../../api/useWithControls';
import { ActionRow, Buttons, OccupyFreeSpace } from '../primitives/ActionRow';
import { useConfirmationDialog } from '../primitives/ConfirmationDialog';
import { FormGrid } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { Dialog as DialogTooly, useDialogState } from '../primitives/Dialogs';
import { Tooltip } from '../primitives/Tooltip';
import { TargetDisplay } from './TargetDisplay';
import { TargetsSelection } from './TargetsSelection';
import { NotificationTask, NotificationTasksData, getTargetsUploadXlsxExample } from './useNotificationTasks';
import { FormControl } from '../schemed';
import { FieldType } from '../../hooks/useSchema';
import { NotificationTasksListConfig } from './NotificationTaskList';
import { MediaLibPickerDialog } from '../medialib/MediaLibPickerDialog';
import { NotificationTemplateEditor } from './NotificationTemplateEditor';
import { PseudoLink2, useCopyText, useSaveable } from '../primitives';
import { openFileUploader } from '../../api/files';
import { CalendarEventDialog } from './CalendarEvents';

const SubjectRow = styled(ActionRow)`

  & .Mui-IconButton {
    margin-bottom: 0.25rem;
  }
`;
SubjectRow.defaultProps = { firstItemNoMargin: true, itemMarginTop: "0", alignItems: "end", lastItemMarginRight: "0" };

const PlaceholderHint = styled.button`
  color: ${props => props.theme.palette.primary.main};
  margin-left: 0.25rem;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;
PlaceholderHint.defaultProps = { role: "button" };


interface Props extends NotificationTasksListConfig {
    data: NotificationTasksData;
}

export const NotificationTaskPopup = (props: Props) => {
    const {
        isCreating,
        isEditing,
        cancelEdit,
        hasChanges,
        save,
        remove,

        schema,
        update,
        uploadTargets,
        cloneEdited,
        execute,
        testExecute,
        isExecuting,

        targets,

        attachments,
    } = props.data;

    const [isTargetsOpen, setIsTargetsOpen] = useState<boolean>(false);
    const [isTestOpen, setIsTestOpen] = useState<boolean>(false);
    const [testEmail, setTestEmail] = useState<string>("");
    const [showHtmlBody, setShowHtmlBody] = useState<boolean>(false);

    const task = props.data.newTask || props.data.editTask || null;

    const { controls } = useItemWithControls<Partial<NotificationTask>>(
        { data: task || {} as Partial<NotificationTask>, update: update },
        { schema });

    const editedTask = props.data.editTask;

    const placeholders = targets.getPlaceholders((task?.targets || []));

    const confirmationDialog = useConfirmationDialog();

    const addAttachmentDialog = useDialogState();

    const [tab,setTab] = useState<"content" | "settings">("content");

    const copyText = useCopyText();

    useSaveable(props.data);

    return (<>
        <Dialog
          key="task"
          open={isCreating || isEditing}
          onClose={cancelEdit}
          fullWidth
          maxWidth="md"
          PaperProps={{ style: { minHeight: "50vh" } }}
          >
            <DialogTitle>
                <ActionRow firstItemNoMargin>
                    <FormattedMessage id="notifications.task.controls.title_one" />

                    <Button
                      size="small"
                      color="primary"
                      style={{ marginLeft: "0.5rem" }}
                      onClick={() => setTab(x => x === "settings" ? "content" : "settings")}
                      >
                        <FormattedMessage id={tab === "settings" ? "emails.popup.tab_content" : "emails.popup.tab_settings"} />
                    </Button>

                    <OccupyFreeSpace />

                    {editedTask && 
                        <Tooltip text_id="common.delete">
                            <IconButton onClick={() => remove(editedTask)} size="small">
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>}

                    {!isCreating &&
                        <Tooltip text_id="common.copy">
                            <IconButton onClick={cloneEdited} size="small">
                                <FileCopyOutlined />
                            </IconButton>
                        </Tooltip>}

                    {editedTask && !editedTask.is_executed && 
                        <Button
                            color="primary"
                            onClick={() => setIsTestOpen(true)}
                            disabled={isExecuting || hasChanges || editedTask.targets.length === 0}
                            >
                            {isExecuting ? <LoadingIndicator sizeVariant="s" /> : <FormattedMessage id="notifications.task.controls.test_execute" />}
                        </Button>}

                    {editedTask && !editedTask.is_executed && 
                        <Button
                            color="primary"
                            onClick={() => confirmationDialog.open({
                                execute: () => execute(editedTask as NotificationTask),
                                title: <FormattedMessage id="notifications.task.controls.execute_confirmation" />,
                                confirmationLabel: <FormattedMessage id="notifications.task.controls.execute" />,
                            })}
                            disabled={isExecuting || hasChanges || editedTask.targets.length === 0}
                            >
                            {isExecuting ? <LoadingIndicator sizeVariant="s" /> : <FormattedMessage id="notifications.task.controls.execute" />}
                        </Button>}
                </ActionRow>
            </DialogTitle>

            {task && <DialogContent>
                <FormGrid columns="1fr" noMargin>
                  <SubjectRow>
                    {controls([
                        ["title", { disabled: task.is_executed, wrapperStyle: { flex: "1 0 auto"}, controlProps: { autoFocus: true } }],
                        task.is_executed ? ["executed_time", { disabled: true, utcToLocal: true }] : null,
                    ])}

                    {tab === "content" && <>
                      <Tooltip key="text" text_id="emails.popup.powertemplate_hint">
                          <IconButton
                            size="small"
                            color={task.is_powertemplate_content ? "primary" : "default"}
                            onClick={() => update({ is_powertemplate_content: !task.is_powertemplate_content })}>
                            {task.is_powertemplate_content ? <BatteryChargingFull /> : <Battery20 />}
                          </IconButton>
                      </Tooltip>
                      {!task.is_powertemplate_content && <>
                        <Tooltip key="text" text_id="emails.popup.textHint">
                            <IconButton size="small" color={showHtmlBody ? "default" : "primary"} onClick={() => setShowHtmlBody(false)}><ShortText /></IconButton>
                        </Tooltip>
                        <Tooltip key="html" text_id="emails.popup.htmlHint">
                            <IconButton size="small" color={showHtmlBody ? "primary" : "default"} onClick={() => setShowHtmlBody(true)}><Code /></IconButton>
                        </Tooltip>
                      </>}
                    </>}
                  </SubjectRow>

                  {tab === "content" && <>

                    {placeholders.availableOnAll.length > 0 && <FormHelperText>
                      <FormattedMessage id="notifications.task.controls.substitutions_hint" />
                      {placeholders.availableOnAll.map(s => `{{${s}}}`).map(s => <PlaceholderHint onClick={() => copyText(s)}>{s}</PlaceholderHint>)}
                    </FormHelperText>}

                    {!task.is_powertemplate_content && controls([
                      [showHtmlBody ? "message_html" : "message", { autoRows: true,  disabled: task.is_executed }],
                    ])}


                    {task.is_powertemplate_content && <>
                      <Typography color="textSecondary" variant="caption" component="p"><FormattedMessage id="emails.fields.body" /></Typography>
                      <NotificationTemplateEditor
                        content={task.message_template}
                        update={v => update({ message_template: v })}
                        disabled={task.is_executed}
                        />
                      </>}

                    {attachments.isAllowed && attachments.attachments.length > 0 &&
                        <FormGrid columns="1fr max-content" gap="dense">
                            <Typography variant="caption" color="textSecondary" style={{ gridColumn: "1 / span 2"}}>
                                <FormattedMessage id="notifications.task.attachments" />
                            </Typography>

                            {attachments.attachments.map((a,idx) => (
                                <React.Fragment key={a.filepath}>
                                    <TextField
                                        value={a.filename}
                                        onChange={e => attachments.update(idx, { filename: e.target.value })}
                                        disabled={task.is_executed}
                                        />
                                    <IconButton
                                      size="small"
                                      style={task.is_executed ? { visibility: "hidden"} : undefined}
                                      onClick={() => attachments.remove(idx)}>
                                        <DeleteOutlined />
                                    </IconButton>
                                </React.Fragment>
                            ))}
                        </FormGrid>}
                    
                    {attachments.isAllowed && !task.is_executed &&
                      <Buttons style={{ justifyContent: "flex-end"}}>
                        <Button
                            size="small"
                            color="primary"
                            startIcon={<EventOutlined />}
                            onClick={() => attachments.calendarEvent.startEditingNew()}>
                            <FormattedMessage id="notifications.task.calendar_event.create_dialog_title" />
                        </Button>

                        <Button
                            size="small"
                            color="primary"
                            startIcon={<AttachFile />}
                            onClick={addAttachmentDialog.open}>
                            <FormattedMessage id="notifications.task.controls.add_attachment" />
                        </Button>
                      </Buttons>}
                  </>}

                  {tab === "settings" && <>

                    {controls([
                      ["_id", {
                        disabled: true,
                        label: "ID",
                        controlProps: {
                          endAdornment: <InputAdornment position="end">
                            <IconButton size="small" disabled={!task._id} onClick={() => copyText(task?._id || "")}><FileCopyOutlined /></IconButton>
                          </InputAdornment>
                        }
                      }],
                      ["email_template", {
                        disabled: task.is_executed,
                        controlProps: {
                          startAdornment: <InputAdornment position="start">
                            <Tooltip text_id="notifications.task.controls.email_template_hint">
                                <a href={props.emailsPagePath || ""}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    style={{ color: "inherit", textDecoration: "none"}}>
                                    <InfoOutlined style={{ marginBottom: "4px" }} color="action" />
                                </a>
                            </Tooltip>
                          </InputAdornment>
                        }
                      }],
                      ["comment"],
                      ])}

                      
                    {(!isCreating || !!task.targets?.length) ?
                        <TargetDisplay
                            disabled={task.is_executed || isCreating}
                            targets={task.targets || []}
                            removeTarget={t => targets.removeTarget(t)}
                            openConfiguration={() => setIsTargetsOpen(true)} />
                        :
                        <Typography variant="caption"><FormattedMessage id="notifications.task.controls.save_to_edit_targets" /></Typography>}
                    
                    {!isCreating && !task.is_executed &&
                      <Buttons>
                        <Button
                          startIcon={<CloudUploadOutlined />}
                          color="primary"
                          size="small"
                          onClick={() => openFileUploader(f => uploadTargets(f))}>
                          <FormattedMessage id="notifications.task.controls.upload_targets" />
                        </Button>
                        <PseudoLink2 onClick={() => getTargetsUploadXlsxExample()}>
                          <FormattedMessage id="notifications.task.controls.download_targets_upload_example" />
                        </PseudoLink2>
                      </Buttons>}
                      
                  </>}
                  
              </FormGrid>

            </DialogContent>}

            <DialogActions>
                <Button onClick={cancelEdit}>
                    <FormattedMessage id="common.close" />
                </Button>
                <Button
                    onClick={() => save()}
                    variant="contained"
                    color="primary"
                    disabled={!isCreating && !hasChanges}>
                    <FormattedMessage id="common.save" />
                </Button>
            </DialogActions>
        </Dialog>

        <DialogTooly
            isOpen={isTestOpen}
            close={() => setIsTestOpen(false)}
            dialogTitle={<FormattedMessage id="notifications.task.controls.test_execute" />}
            noFullscreen
            actions={<>
                <Button
                    color="primary"
                    onClick={() => {
                        if(editedTask) {
                            testExecute(editedTask, { email: testEmail});
                            setIsTestOpen(false);
                        }
                    }}
                    >
                    <FormattedMessage id="notifications.task.controls.execute" />
                </Button>
            </>}>

            <Typography><FormattedMessage id="notifications.task.controls.test_execute_hint" /></Typography>
            <FormControl
                field="email"
                row={{ email: testEmail }}
                onChange={(o,c) => setTestEmail(c.email)}
                schema={{ type: FieldType.text, label_id: "notifications.task.controls.test_execute_email_label" }}
                />
        </DialogTooly>

        <TargetsSelection
            isOpen={isTargetsOpen}
            close={() => setIsTargetsOpen(false)}
            targets={targets}
            />

        {attachments.isAllowed && <MediaLibPickerDialog
            {...addAttachmentDialog}
            closeOnSelect
            selectFile={mf => attachments.add(mf)}
            unselectFile={() => {}}
            selectedUrls={[]}
            />}

        {attachments.isAllowed &&
          <CalendarEventDialog
            data={attachments.calendarEvent}
            />}
    </>);
}
