import React from 'react';
import { useTrackHistory } from '../HistoryFavorites';
import { useDocumentationPages } from './DocumentationPagesContext';
import { DocumentationPageUnbound } from './DocumentationPageUnbound';
import { useDocumentationRootPage } from './useDocumentationPages';

export const DocumentationRootPage = () => {
    const { config } = useDocumentationPages();

    const data = useDocumentationRootPage(config.apiPath, config.rootSlug, {
        defaultTitle: config.defaultRootPageTitle,
    });
    
    useTrackHistory(data.data.title);

    return (
        <DocumentationPageUnbound {...config} data={data} />
    );
}
