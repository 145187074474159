interface Config {
    localStoragePrefix?: string;
}

export type FeaturesStateBase = Record<string, boolean>;

export interface FeatureManager<T extends FeaturesStateBase> {
    defaultValues: T;
    features: (keyof T)[];
    isFeatureOn: (feature: keyof T) => boolean;
    setIsFeatureOn: (feature: keyof T, state: boolean) => void;
}

export const createFeatureManager = <T extends FeaturesStateBase>(defaultValues: T, cfg?: Config): FeatureManager<T> => {
    const getFeatureKey = (feature: string) => `${cfg?.localStoragePrefix || "__tooly_feature_"}${feature}`;

    return {
        defaultValues,
        features: Object.keys(defaultValues),
        isFeatureOn: (feature: keyof T) => {
            const setting = localStorage.getItem(getFeatureKey(feature as string));
            return setting === null
                ? defaultValues[feature] || false
                : setting === "1";
        },
        setIsFeatureOn: (feature: keyof T, state: boolean) => {
            localStorage.setItem(getFeatureKey(feature as string), state ? "1" : "0");
        },
    }
}
