import { useHistory } from "react-router-dom"
import { useSendData } from "../useSendData";
import { usePasswordCheck } from "./useCommonSignup";

export interface RestoreCredentials {
    login: string;
    password: string;
    passwordRepeat: string;
    token: string;
}

export const useRestorePassword = (restoreAPIPath: string) => {
    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);
    const login = urlParams.get("email");
    const token = urlParams.get("token");

    if (!login || !token) {
        throw new Error('No login or token');
    }

    const defaultCreds: RestoreCredentials = { login: login, password: '', passwordRepeat: '', token: token };

    const sendData = useSendData<RestoreCredentials, void>(restoreAPIPath, defaultCreds, {
        apiExtraParams: { noAuth: true }
    });

    const validatePasswords = usePasswordCheck(sendData.data);

    return {
        ...sendData,
        send: () => {
            if (!validatePasswords()) {
                return new Promise<void>(_ => {});
            }

            return sendData.send()
        }
    }
}

export type RestorePasswordData = ReturnType<typeof useRestorePassword>;
