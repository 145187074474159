import styled from '@emotion/styled';
import { Theme } from '@mui/material';

interface StrippedButtonCommonProps {
  color?: "primary" | "secondary" | "inherit";
}

interface StrippedButtonProps extends StrippedButtonCommonProps {
  textTransform?: React.CSSProperties["textTransform"];
}

const buttonTextColor = (color: StrippedButtonCommonProps["color"], theme: Theme) => {
  switch(color) {
    case "primary":
      return theme.palette.primary.main;
    case "secondary":
      return theme.palette.secondary.main;
    case "inherit":
      return "inherit";
    default:
      return "rgba(0, 0, 0, 0.54)";
  }
}

export const StrippedButton = styled.button<StrippedButtonProps>`
  padding: 4px 5px;
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: ${props => props.textTransform || "uppercase"};

  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  cursor: pointer;

  line-height: 1.75;
  border-radius: ${props => props.theme.shape.borderRadius}px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: ${props => buttonTextColor(props.color, props.theme)};

  &:hover {
    background: ${props => props.color === "primary"
      ? props.theme.palette.primary.main
      : props.color === "secondary"
        ? props.theme.palette.secondary.main
        : "#000000"}0A;
  }
`;

export const StrippedIconButton = styled.button<StrippedButtonCommonProps>`
  padding: 4px 5px;
  font-size: 0.8125rem;
  font-weight: 500;
  border-radius: 50%;
  padding: 5px;

  display: inline-flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  

  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  cursor: pointer;
  aspect-ratio: 1;

  color: ${props => buttonTextColor(props.color, props.theme)};

  &:hover {
    background: ${props => props.color === "primary"
      ? props.theme.palette.primary.main
      : props.color === "secondary"
        ? props.theme.palette.secondary.main
        : "#000000"}0A;
  }
`;
