import React from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected } from 'slate-react';
import { useDocumentationPages } from './DocumentationPagesContext';
import { DescriptionOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LoadingIndicator } from '../primitives';

export const DocumentationPageEditorBlockType = "documentation-page";

const DocumentWrapper = styled(Link)<{ isSelected?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.5rem;
    background: ${props => props.isSelected ? `${props.theme.palette.primary.main}20` : "transparent"};
    text-decoration: none;
    color: inherit;
`;

export const DocumentationPageEditorBlock = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const pages = useDocumentationPages();
    const isSelected = useSelected();

    const documentId = (element as any).document_id || "";

    return (
        <div contentEditable={false} {...attributes}>
            {children}

            <DocumentWrapper isSelected={isSelected} to={`${pages.config.pagePath}/${documentId}`}>
                <DescriptionOutlined color="action" /> <Typography variant="h6">{pages.getDocument(documentId)?.title || <LoadingIndicator sizeVariant="font" />}</Typography>
            </DocumentWrapper>
        </div>
    );
}
