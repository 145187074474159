import { Vote } from "./usePresidiumVoteList";
import moment from 'moment';
import { FieldType } from "../../../toolympus/hooks/useSchema";

export const remainingTime = (d: string | null | undefined) => {
    const now = moment.now();
    const end = moment(d + ' 23:59:59');
    const dur = moment.duration(end.diff(now));

    const remaining = now && end ? [
        dur.asDays() > 0 ? `${dur.asDays().toFixed(0)}д` : '',
        dur.hours() > 0 ? `${dur.hours()}ч` : '',
        dur.minutes() > 0 ? `${dur.minutes()}м` : '',
    ].join(' ') : '';

    return remaining;
}

export const addRemainingVoteTime = (v: Vote) => {
    return {
        ...v,
        remaining: remainingTime(v.end_date),
    }
}

export const VoteSchemaConfig = {
    schemaKey: 'presidium_vote',
    schemaChanges: {
        remaining: { type: FieldType.text, label: 'Осталось' },
    }
}
