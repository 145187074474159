import React, { useMemo } from "react";
import { TextFieldsOutlined } from "@mui/icons-material";
import { PowerDocEditPage, PowerDocListPage } from "../../toolympus/components/PowerDoc";
import { MenuRoute, Routes } from "../../toolympus/components/frame";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { RacaPowerEditor } from "./RacaPowerEditor";

export const PowerDocApiPath = "/api/powerdoc/doc";

export const PowerDocRoutes = (prefix: string, title: string): MenuRoute[] => [
    {
        path: `${prefix}/doc/:id`,
        title,
        hidden: true,
        component: () => (
            <PowerDocEditPage
                apiPath={PowerDocApiPath}
                pathPrefix={`${prefix}/doc`}
                editor={RacaPowerEditor}
                />),
    },

    {
        path: `${prefix}/doc`,
        title: title,
        icon: <TextFieldsOutlined />,
        alsoActivateForPath: p => p.startsWith(prefix),
        component: () => (
            <PowerDocListPage
                apiPath={PowerDocApiPath}
                pathPrefix={`${prefix}/doc`}
                headerActions={
                    <Link to={`${prefix}/block`} style={{ textDecoration: "none" }}>
                        <Button size="small" color="primary">Блоки</Button>
                    </Link>
                }
                title="Шаблоны документов"
                newDocumentTitle="Новый шаблон"
                />),
    },

    {
        path: `${prefix}/block/:id`,
        title: "Блок Х",
        hidden: true,
        component: () => (
            <PowerDocEditPage
                apiPath={PowerDocApiPath}
                pathPrefix={`${prefix}/block`}
                editor={RacaPowerEditor}
                />),
    },

    {
        path: `${prefix}/block`,
        title: "Блоки Х",
        hidden: true,
        component: () => (
            <PowerDocListPage
                apiPath={PowerDocApiPath}
                pathPrefix={`${prefix}/block`}
                view="block-templates"
                entity="block-template"
                title="Блоки"
                newDocumentTitle="Новый блок"
                />),
    },
];


export const PowerDocsPlace = () => {
  const routes = useMemo(() => PowerDocRoutes("/system/documents", "Шаблоны документов"), []);
  return <Routes routes={routes} notFoundRedirect="/system/documents/doc" />
}