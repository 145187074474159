import React from 'react';
import styled from '@emotion/styled';
import { DescriptionOutlined } from '@mui/icons-material';
import { downloadFile } from '../../../api/core';
import { Component } from '..';

const LinksWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    padding: 1rem 0;

    ${props => props.theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;
    }
`;

const LinkWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    cursor: pointer;

    & > :first-child {
        margin-right: 0.25em;
    }
`;

export const DocumentLinks = (c: Component) => {
    const documents = c.content_text.split('\n')
        .map(s => s.trim())
        .filter(s => s.length > 0)
        .map(s => s.split(';').map(s => s.trim()))
        .filter(items => items.length >= 3) as [string, string, string][];

    return (
        <LinksWrapper className="page-component documents">
            {documents.map(([label, url, filename]) => (
                <LinkWrapper key={url} onClick={() => downloadFile(url, filename)}>
                    <DescriptionOutlined fontSize="large" />
                    {label}
                </LinkWrapper>))}
            
        </LinksWrapper>
    );
}
