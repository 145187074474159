import { Box, Card, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetch } from '../../api/useFetch';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { switchWidget } from './default-widgets';
import { Gutter, WidgetLoadingContent } from './default-widgets/Common.styles';
import { OpenDetailsWidgetFn, WidgetConfig, WidgetResult } from './types';

interface WidgetData {
    data: WidgetResult | null;
    loading: boolean;
    error: any;
}

export interface WidgetProps {
    url: string;
    config: WidgetConfig;
    openDetails: OpenDetailsWidgetFn;
    customSwitchWidget?: Record<string, (config: WidgetConfig, result: WidgetResult) => JSX.Element>;
    getColumnWidth?: (v: number) => string;
};

export type WidgetType = (props: WidgetProps) => JSX.Element;

const PlaceholderResult: WidgetResult = {
    _id: "",
    widgettype: "placeholder",
    title: "",
    subtitle: "",
    result_fields: [],
    params: [],
    rows: [],
    value_field: "",
    label_field: "",
    display_settings: { show_pct: false, show_table: false, hide_chart: true },
    details_fields: [],
}

export const Widget = (props: WidgetProps) => {
    const { url, config } = props;
    const isPlaceholder = config.widgettype === PlaceholderResult.widgettype;
    const widgetData = useFetch<WidgetResult | null>(null, { url }, !isPlaceholder);
    const { setData } = widgetData;

    useEffect(() => setData(null), [setData, url]);

    return <WidgetUnbound {...props} data={widgetData} />
}

export const WidgetUnbound = (props: WidgetProps & { data: WidgetData }) => {
    const { config, customSwitchWidget = {}, getColumnWidth = () => '100%', openDetails } = props;
    const isPlaceholder = config.widgettype === PlaceholderResult.widgettype;
    const { data, loading, error } = props.data;

    const isEmptyState = data && (!data.rows || !data.rows.length) && config.display_settings.empty_state_message && config.display_settings.empty_state_message.length;

    return !isPlaceholder && (loading || !data || isEmptyState) ?
        <Gutter width={getColumnWidth(1)}>
            <Card>
                <WidgetLoadingContent>
                    <Typography align="center" variant="subtitle1">{config.title}</Typography>
                    <Typography align="center" variant="body1">{config.subtitle}</Typography>
                    <div style={{ marginTop: 15 }} />
                    {loading && <LoadingIndicator />}
                    {error &&
                        <Box textAlign="center">
                            <i><FormattedMessage id={error?.response?.data?.error_code || "dashboard.errors.unknown"} values={error?.response?.data?.error_params} /></i>
                        </Box>}
                    {!error && isEmptyState && 
                        <Box textAlign="center">
                            <i>{config.display_settings.empty_state_message}</i>
                        </Box>}
                </WidgetLoadingContent>
            </Card>
        </Gutter>
        :
        switchWidget(config, data || PlaceholderResult, openDetails, customSwitchWidget, getColumnWidth)
}