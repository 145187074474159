import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, IconButton, TextField, Typography } from '@mui/material';
import { Add, ArrowBack, ArrowForward, DragIndicator, KeyboardArrowDown, KeyboardArrowUp, PlaylistAdd, Settings } from '@mui/icons-material';
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../../hooks/useSchema';
import { WidgetConfig } from '../Dashboard/types';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { DialogButton } from '../primitives/DialogButton';
import { FormGrid } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FormControl } from '../schemed';
import { ParametersPane, WidgetsGrid } from './EditForm.style';
import { EditWidgetPopup, SqlInputProps } from './EditWidgetPopup';
import { Phantom } from './Phantom';
import { useDrag } from './useDrag';
import { useEditForm } from './useEditDash'
import { useWidgetList } from './useWidgetList';
import { WidgetListPopup } from './WidgetListPopup';
import { getWidgetWidth } from '../Dashboard/default-widgets/Diagram/DiagramContainer';

const findParent = (child: HTMLElement, parentClass: string): HTMLElement | null => {
    if (child.classList.contains(parentClass)) {
        return child;
    }

    if (!child.parentElement) {
        return null;
    }

    return findParent(child.parentElement, parentClass);
}

export type EditFormType = (props: { code: string }) => JSX.Element


export const EditForm: EditFormType = ({ code }) => {
    const {
        data,
        loading,
        setData,
        moveWidget,
        form,
        add,
        addExisting,
        save,
        saveWidget,
        removeWidgetLocal,
        removeWidgetGlobal,
        duplicateWidget,
        editedWidget,
        selectWidgetForEditing,
        widgetLoading,
    } = useEditForm(code);
    const widgetList = useWidgetList('/api/dashboard');
    const [dragWidget, setDragWidget] = useState<WidgetConfig | null>(null);
    const { element, setElement } = useDrag(() => {});
    const containerRef = useRef<HTMLElement>(null);
    const [showParams, setShowParams] = useState<boolean>(false);

    if (!data) {
        return <LoadingIndicator />
    }

    return <div>
        <ParametersPane>
            <Typography variant="subtitle1" className="section-title" onClick={() => setShowParams(x => !x)}>
                <FormattedMessage id="dashboard.screen.params" />
                <IconButton size="small">
                    {showParams ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </Typography>
            
            {showParams && (<>
                <FormGrid>
                    {data.params.map((param, id) => 
                        <TextField
                            fullWidth 
                            key={param.code}
                            label={param.code}
                            value={param.default}

                            onChange={e => 
                                setData({
                                    ...data, 
                                    params: data.params.map((param, index) => id === index? {...param, default: e.target.value} : param
                                )})} 
                            />
                    )}
                </FormGrid>

                <FormControl 
                    key="query_substitutions"
                    field="query_substitutions"
                    row={data}
                    schema={{ type: FieldType.textlong }}
                    onChange={(_, changes) => setData({ ...data, query_substitutions: changes.query_substitutions })}
                    extraProps={{
                        ...SqlInputProps,
                        label: <FormattedMessage id="dashboard.widget.query_substitutions" />,
                        hint: <FormattedMessage id="dashboard.widget.query_substitutions_hint" />,
                        }}
                />
            </>)}
        </ParametersPane>

        
        <ActionRow itemMarginTop="0">
            <DialogButton 
                button={<Button startIcon={<Add />}><FormattedMessage id="dashboard.screen.add" /></Button>}
                maxWidth="sm"
                fullWidth
            >{close => <>
                <DialogContent>
                    {form.input('title', { label: <FormattedMessage id="dashboard.screen.title" /> })}
                    {form.select(
                        'widgettype', 
                        [
                            {value: 'pie', label: <FormattedMessage id="dashboard.screen.pie" />} as any,
                            {value: 'bar', label: <FormattedMessage id="dashboard.screen.bar" />} as any,
                            {value: 'counter', label: <FormattedMessage id="dashboard.screen.counter" />} as any,
                            {value: 'placeholder', label: <FormattedMessage id="dashboard.screen.placeholder" />} as any,
                        ],
                        item => item,
                        { label: <FormattedMessage id="dashboard.screen.widgettype" /> }
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={!form.valid} onClick={close(add)}><FormattedMessage id="dashboard.screen.add" /></Button>
                </DialogActions>
                </>
            }
            </DialogButton>

            <Button onClick={widgetList.open} startIcon={<PlaylistAdd />}><FormattedMessage id="dashboard.screen.addexisting" /></Button>
            <WidgetListPopup data={widgetList} addWidget={addExisting} />

            <OccupyFreeSpace />
            {loading && <LoadingIndicator sizeVariant="m" />}
            <Button onClick={() => save()} variant="contained" color="primary"><FormattedMessage id="dashboard.screen.save" /></Button>
        </ActionRow>

        <WidgetsGrid ref={containerRef as any}>
            {data.widgets.map((widget, id) => {
                return <React.Fragment key={widget._id}>
                    {(!dragWidget || widget._id !== dragWidget._id) && <Phantom 
                        dragElement={element}
                        onSelect={() => {dragWidget && moveWidget(dragWidget, id)}}
                    />}
                    <Box 
                        className="widget-box"
                        width={getWidgetWidth(widget) * 100 / 6 + '%'} 
                    >
                        <Card>
                            <CardContent>
                                <div className="header">
                                    <div className="arrows">
                                        <div
                                            onMouseDown={e => {setDragWidget(widget); setElement(findParent(e.currentTarget, "widget-box"))}}
                                            className="move-icon"
                                        >
                                            <DragIndicator />
                                        </div>
                                        <div 
                                            onClick={() => moveWidget(widget, id-1)}
                                            className="arrow-icon"
                                        >
                                            <ArrowBack />
                                        </div>
                                        <div 
                                            onClick={() => moveWidget(widget, id + 2)}
                                            className="arrow-icon"
                                        >
                                            <ArrowForward />
                                        </div>
                                    </div>

                                    <div onClick={() => selectWidgetForEditing(widget)}><Settings /></div>
                                </div>
                                <Typography variant="subtitle1">{widget.title}</Typography>
                                <Typography>{widget.subtitle}</Typography>
                                <Typography><i><FormattedMessage id="dashboard.screen.type" />: {widget.widgettype}</i></Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </React.Fragment>
            })}

            <Dialog
                open={!!editedWidget}
                onClose={() => selectWidgetForEditing(null)}
                fullWidth
                maxWidth="lg">
                {editedWidget && <EditWidgetPopup 
                    dashboardCode={data.code}
                    widget={editedWidget}
                    onSave={saveWidget}
                    onRemove={() => removeWidgetLocal(editedWidget._id)}
                    onRemoveGlobal={() => removeWidgetGlobal(editedWidget._id)}
                    onDuplicate={() => duplicateWidget(editedWidget)}
                    isLoading={widgetLoading}
                />}
            </Dialog>

            <Phantom 
                dragElement={element}
                onSelect={() => {dragWidget && moveWidget(dragWidget, data.widgets.length)}}
            />
        </WidgetsGrid>
    </div>
}