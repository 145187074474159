import { Descendant } from "slate";
import { CustomElement } from "../../../../slate";
import { ConditionalBlockElementType } from "../plugins";
import { PlaceholderElementType } from "../plugins/Placeholders/usePlaceholders";
import { PowerDoc } from "../types";
import { ExpressionProcessor } from "./expressionProcessor";
import { ColumnsBlockElementType } from "../plugins/Columns/ColumnsBlockElement";
import { PanelBlockElementType } from "../plugins/Panel/PanelBlockElement";


const fillPlaceholders = (block: Descendant, exp: ExpressionProcessor) => {
  const { children, ...itemParams } = block as any;
  if(itemParams.type === PlaceholderElementType) {
      return {
        ...(itemParams.marks || {}),
        text: exp.getFormattedValue(itemParams.expression),
      };
  } else {
    if(itemParams.type === PanelBlockElementType) {
      return {
        ...itemParams,
        content: { blocks: (itemParams.content?.blocks || []).map((block: any) => fillPlaceholders(block, exp)) }
      }
    } else if(itemParams.type === ColumnsBlockElementType) {
      return {
        ...itemParams,
        columns_content: itemParams.columns_content.map((column: any) => ({
          ...column,
          blocks: (column.blocks || []).map((block: any) => fillPlaceholders(block, exp)),
        })),
      }
    } else if(children) {
      return { ...itemParams, children: children.map((c: Descendant) => fillPlaceholders(c, exp)) };
    } else {
      return { ...itemParams } 
    }
  }
}

const checkCondition = (condition: string | undefined, exp: ExpressionProcessor) => {
  return exp.checkCondition(condition);
}

const processConditionalBlocks = (blocks: Descendant[], exp: ExpressionProcessor): Descendant[] => {
  return blocks.reduce<Descendant[]>((result, block) => {
    if((block as any).type === ConditionalBlockElementType) {
      const b = (block as CustomElement);
      if(checkCondition(b.condition, exp)) {
        ((b.content.blocks || []) as Descendant[]).forEach(c => result.push(c));
      }
    } else if((block as any).condition) {
      const b = (block as CustomElement);
      if(checkCondition(b.condition, exp)) {
        result.push(block);
      }
    } else {
      result.push(block);
    }

    return result;
  }, []);
}

export const processPowerDocTemplate = (doc: PowerDoc, exp: ExpressionProcessor) => {
  const blocks = processConditionalBlocks(doc.content.blocks || [], exp)
    .map(b => fillPlaceholders(b, exp));

  return {
    ...doc,
    content: {
      ...doc.content,
      blocks,
    },
  };
}
