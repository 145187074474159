import { useEffect, useState } from "react";
import { apiFetch, downloadFile, FetchTypes } from "../../api/core";
import { useLoadedData } from "../../hooks/useLoadedData";
import { LoginAs, useLoginAs } from "../../hooks/auth/useLoginAs";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useQuery } from "../../hooks/useQueryParamsState";
import { SendInvitesData, useSendInvites } from "./useSendInvites";

export interface IDefaultUser {
    _id: string;
    email: string;
}

export interface IDefaultRolesUser extends IDefaultUser {
    roles: string[];
}

export interface IUserManagement<IUser extends IDefaultRolesUser> {
    users: IUser[];
    filter: string;
    setFilter: (v: string) => void;
    
    updateUsers: (v: IUser[]) => void;
    saveUser: (v: IUser) => void;
    saveRoles: (v: IUser) => void;
    unblockUser: (v: IUser) => void;
    blockUser: (v: IUser) => void;
    addUsers: (emails: string[]) => void;
    sendConfirmationRequest: (userId: string) => void;
    sendPasswordResetEmail: (userId: string) => void;
    changeUserEmail: (userId: string, newEmail: string) => void;
    changeUserDisplayName: (userId: string, newName: string) => void;
    changeUserPassword: (userId: string, newPassword: string) => void;
    loginAs: LoginAs;
    downloadUsers: () => void;
    isLoading: boolean;
    reload: () => void;

    invites: SendInvitesData<IUser>;
}

export const defaultUserFilter = (filter: string, user: IDefaultUser) => `${(user.email || "").toLowerCase()} ${user._id}`.includes(filter.toLowerCase())

export const useUserManagement = <IUser extends IDefaultRolesUser>(
    userAPIPath: string, 
    loginAsPath: string, 
    userMatchesFilter?: (f: string, u: IUser) => boolean): IUserManagement<IUser> => {

    const queryParams = useQuery();

    const { data, isLoading, reload } = useLoadedData<IUser[]>(userAPIPath, []);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>(data);
    const loginAs = useLoginAs(loginAsPath, '/');
    const [filter, setFilter] = useState<string>(queryParams.get("filter") || "");

    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    useEffect(() => setUsers(data), [isLoading, data]);

    const userMatches = userMatchesFilter || defaultUserFilter;

    const addUser = (email: string) => {
        return apiFetch<IUser>(userAPIPath, FetchTypes.POST, { email: email });
    }

    const fetchHandler = <IResponse>(fetch: Promise<IResponse>, onSuccess: VoidFunction | ((response: IResponse) => void)) => {
        setIsSaving(true);
        fetch
            .then(resp => {
                onSuccess(resp);
                setIsSaving(false);
            })
            .catch(er => {
                setIsSaving(false);
                throw er;
            })
    }

    const updateLocalUser = (updatedUser: IUser) => {
        let usersCopy = users.slice();
        let index = usersCopy.findIndex(u => u._id === updatedUser._id)

        if (index > -1) {
            usersCopy[index] = updatedUser;
        }
        else {
            usersCopy.push(updatedUser);
        }

        setUsers(usersCopy);
    }

    const addUsers = (emails: string[]) => {
        setIsSaving(true);

        const fetch = Promise.all(
            emails.map(e => (e || "").trim())
                .filter(e => !!e)
                .map(e => addUser(e)));
        fetchHandler(fetch, reload);
    }

    const save = (userId: string, body: any) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${userId}`, FetchTypes.PUT, body);
        fetchHandler(fetch, updateLocalUser);
    }

    const saveUser = (user: IUser) => save(user._id, { ...user, _id: undefined });

    const saveRoles = (user: IUser) => save(user._id, { roles: user.roles });

    const invites = useSendInvites<IUser>({
        apiPath: userAPIPath,
        onSuccess: reload,
    });

    const sendConfirmationRequest = (userId: string) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${userId}/request-confirmation`, FetchTypes.POST);
        fetchHandler(fetch, updateLocalUser);
    }

    const sendPasswordResetEmail = (userId: string) => {
        apiFetch<IUser>(`${userAPIPath}/${userId}/reset-password`, FetchTypes.POST);
    }

    const changeUserEmail = (userId: string, newEmail: string) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${userId}/email`, FetchTypes.PUT, { email: newEmail });
        fetchHandler(fetch, updateLocalUser);
    }

    const changeUserDisplayName = (userId: string, newName: string) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${userId}`, FetchTypes.PUT, { display_name: newName });
        fetchHandler(fetch, updateLocalUser);
    }

    const changeUserPassword = (userId: string, password: string) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${userId}/password`, FetchTypes.PUT, { password })
            .then(x => {
                enqueueSnackbar(formatMessage({ id: "userManagement.change_password_popup.success" }), { variant: "success", autoHideDuration: 5000 });
                return x;
            });
        fetchHandler(fetch, updateLocalUser);
    }

    const unblockUser = (user: IUser) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${user._id}`, FetchTypes.PUT, { blocked: false });
        fetchHandler(fetch, updateLocalUser);
    }

    const blockUser = (user: IUser) => {
        const fetch = apiFetch<IUser>(`${userAPIPath}/${user._id}`, FetchTypes.PUT, { blocked: true });
        fetchHandler(fetch, updateLocalUser);
    }

    const downloadUsers = () => {
        downloadFile(`${userAPIPath}/export`, 'users.xlsx');
    }

    return {
        users: filter && filter.length > 0 ? users.filter(u => userMatches(filter, u)) : users,
        filter,
        setFilter,
        updateUsers: setUsers,
        saveUser: saveUser,
        saveRoles,
        unblockUser,
        blockUser,
        addUsers,
        invites,
        sendConfirmationRequest,
        sendPasswordResetEmail,
        changeUserEmail,
        changeUserDisplayName,
        changeUserPassword,
        loginAs,
        downloadUsers,
        isLoading: isLoading || isSaving,
        reload,
    }
}

export interface ResendEmailConfirmationRequest {
    isLoading: boolean;
    request: () => Promise<void>;
}

export const useResendEmailConfirmationRequest = (usersApiPath: string): ResendEmailConfirmationRequest => {
    const [isLoading, setIsLoading] = useState(false);

    return {
        isLoading,
        request: () => {
            setIsLoading(true);
            return apiFetch<any>(`${usersApiPath}/request-confirmation`, FetchTypes.POST)
                .then(x => {
                    setIsLoading(false);
                    return x;
                })
                .catch(e => {
                    setIsLoading(false);
                    throw e;
                })
        },
    }
}
