import { Document, Packer } from "docx-dotx-hf-fix";

export const packDocx = (doc: Document) => Packer.toBlob(doc);

export const saveDocx = (doc: Document, filename: string) => {
    return Packer.toBlob(doc).then(blob => {
        const objectUrl = window.URL.createObjectURL(blob);
        
        const anchor = document.createElement("a");
        document.body.appendChild(anchor);
        anchor.href = objectUrl;
        anchor.download = filename;
        anchor.click();

        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(objectUrl);
      });
}
