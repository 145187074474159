import { useState } from "react";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { CaseSide } from "../../../typings/Cases";

export interface CaseSideSuggstion extends Omit<CaseSide, "kind" | "case_id"> {

}

export const useCaseSideSuggestions = () => {
  const [text,setText] = useState<string>("");
  const data = useLoadedData<CaseSideSuggstion[]>(`/api/case/side-suggest?inn=${text}`, [], !!text);

  const createSuggestionChanges = (s: CaseSideSuggstion, old?: Partial<CaseSide> | undefined | null) => ({
    ...s,
  });

  return {
    isLoading: data.isLoading,
    suggestions: data.data,
    requestSuggestions: (v: string) => { data.setData([]); setText(v); },
    clearSuggestions: () => { setText(""); data.setData([]); },
    createSuggestionChanges,
  }
}
