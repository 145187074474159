import React from 'react';
import { Form, Link, LoadingIndicator, OccupyFreeSpace } from '../primitives';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { NumberingSequenceSelector } from './NumberingSequenceSelector';
import { useNumberingSequences } from './useNumberingSequences';
import { NumberingSequenceEvents } from './NumberingSequenceEvents';
import { GetNumberBlock, GetNumberWrapper } from './GetNumberBlock';
import { PluggableDocumentationButton } from '../Documentation';



interface Props {
  apiPath: string;
  usersDictionary?: string;
  isEditAvailable?: boolean;
  documentationKey?: string;
}

export const NumberingSequencesPage = (props: Props) => {
  let { path } = useRouteMatch();

  const data = useNumberingSequences(props.apiPath);
  
  return (
    <Form
      title={<FormattedMessage id="numbering.page_title" />}
      headerItems={
        <>
          {data.allSequences.isLoading && <LoadingIndicator sizeVariant="s" />}
          <OccupyFreeSpace />
          {props.isEditAvailable && <Link to={`${path}/edit`} ><Button size="small"><FormattedMessage id="numbering.to_edit" /></Button></Link>}
          {props.documentationKey && <PluggableDocumentationButton documentationKey={props.documentationKey} />}
        </>
      }>
      
      <NumberingSequenceSelector
        available={data.allSequences.data}
        currentId={data.currentId.value ? +data.currentId.value : null}
        setCurrentId={v => data.currentId.update(v ? v.toString() : "")}
        />

      <GetNumberWrapper>
        <GetNumberBlock data={data} />
      </GetNumberWrapper>

      {!!data.currentId.value &&
        <>

          <NumberingSequenceEvents data={data.events} usersDictionary={props.usersDictionary} />
        </>}

    </Form>
  );
}
