import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
  InputLabel,
  MenuItem,
  Select as SelectMUI,
  FormControl as FormControlMaterial,
  Chip,
} from '@mui/material';
import { SuggestedColors as BasicColorsX } from '../Tags/TagEditPopup';


export const BasicTagColors = BasicColorsX;

const ColorChip = styled(Chip)`
  min-width: 100px;
  height: 20px;
`;

interface Props {
  value: string | null | undefined;
  update: (v: string | null | undefined) => void;
  label?: ReactNode;
  options?: string[];
}

export const OptionsColorPicker = (props: Props) => {
  const { value, update, label } = props;

  const options = props.options || BasicTagColors;

  return (
    <FormControlMaterial style={{width: '100%'}} size="small">
        <InputLabel>{label}</InputLabel>
        <SelectMUI
            value={value === null || value === "undefined" ? "" : value}
            onChange={e => update(e.target.value)}>
            <MenuItem key="undef">
              -
            </MenuItem>
            {(options || []).map((color) => (
              <MenuItem key={color || 'undef'} value={color}>
                <ColorChip size="small" style={{ background: color }} />
              </MenuItem>
            ))}
        </SelectMUI>
    </FormControlMaterial>
  );
}
