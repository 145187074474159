import React from 'react';
import { useQueryChunksConfiguration } from './useQueryChunksConfiguration';
import { EmptyState, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, SimpleDialog, useCopyText, useSaveable } from '../primitives';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../schemed';
import { IconButton, TextField } from '@mui/material';
import { Add, BugReportOutlined } from '@mui/icons-material';
import { CompositeQueryChunkEditor } from './CompositeQueryChunkEditor';
import { DataschemaContextBareProvider } from './DataschemaContext';
import { QueryChunksField } from './QueryChunksField';

interface Props {
  apiPath: string;
}

export const QueryChunksConfigurationPage = (props: Props) => {
  const data = useQueryChunksConfiguration(props.apiPath);
  useSaveable(data.data);
  const copyText = useCopyText();

  return (
    <DataschemaContextBareProvider dataschema={data.data.data}>
      <Form
        title={<FormattedMessage id="dataschema.query_chunks.page_title" />}
        fitFullHeight
        formBodyProps={{ style: { flex: "1 0 auto" }}}
        headerItems={<>
          <FormGrid columns="300px">
            <FormControlsForFields
              data={{ code: data.current.value }}
              onChange={(o,c) => data.current.update(c.code)}
              schema={data.schema}
              fields={[
                ["code"],
              ]}
              />
          </FormGrid>

          <OccupyFreeSpace />

          {data.isLoading && <LoadingIndicator />}

          {data.data.hasChanges &&
            <SaveButton action={() => data.data.save()} />}

          <IconButton size="small" color="primary" onClick={() => data.chunkTest.startEditing({ chunks: [] })}>
            <BugReportOutlined />
          </IconButton>
          <IconButton size="small" color="primary" onClick={() => data.newItem.startEditing()}>
            <Add />
          </IconButton>
        </>}>
          
          {!data.data.data.code && <EmptyState text={<FormattedMessage id="dataschema.query_chunks.select_config" />} />}

          {!!data.data.data.code &&
            <QueryChunksField data={data} />}



          <SimpleDialog
            isOpen={data.newItem.isEditing}
            close={data.newItem.cancel}
            save={() => data.newItem.save()}
            dialogTitle={<FormattedMessage id="dataschema.query_chunks.create_config" />}>

            {data.newItem.item &&
              <FormGrid columns="1fr">
                <FormControlsForFields
                  data={data.newItem.item}
                  onChange={(o,c) => data.newItem.update(c)}
                  schema={{ code: { label_id: data.schema.code.label_id }}}
                  fields={[
                    ["code"],
                  ]}
                  />
              </FormGrid>}
          </SimpleDialog>


          <SimpleDialog
            isOpen={data.chunkTest.isEditing}
            close={data.chunkTest.cancel}
            save={() => data.chunkTest.save()}
            saveLabel="test"
            dialogTitle={<FormattedMessage id="dataschema.query_chunks.test_title" />}
            submitOnModEnter>

            {data.chunkTest.item &&
              <FormGrid columns="1fr">
                <CompositeQueryChunkEditor
                  data={data.chunkTest.item}
                  update={c => data.chunkTest.update(c)}
                  />
                
                {!!data.chunkTest.item?.query &&
                  <TextField
                    multiline
                    value={data.chunkTest.item?.query || ""}
                    InputProps={{ readOnly: true }}
                    onClick={() => copyText(data.chunkTest.item?.query || "")}
                    label={<FormattedMessage id="dataschema.query_chunks.test.query" />}
                    />}
              </FormGrid>}
          </SimpleDialog>
      </Form>
    </DataschemaContextBareProvider>
  );
}
