import React, { useState } from 'react';
import { 
    Box,
    Button,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { Add, Autorenew, DeleteOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

import { useForm } from '../../hooks/useForm';
import { useSchema } from '../../hooks/useSchema';
import { WidgetConfig } from '../Dashboard/types';
import { FormControlsForFields, TableForFields } from '../schemed';
import { ActionRow, Buttons, OccupyFreeSpace } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { Widget } from '../Dashboard/Widget';
import { getWidgetUrl } from '../Dashboard/DashboardPage';
import { getWidgetColumnsWidth } from '../Dashboard/Dashboard';
import { FormGrid } from '../primitives/Forms';
import isHotkey from 'is-hotkey';
import { WidgetDetailResultFieldSchema, WidgetDisplaySettingsSchema, WidgetParamsSchema, WidgetSchema } from './Schema';
import { ButtonOptionPicker } from '../primitives';
import { CompositeQueryChunkEditor } from '../Dataschema';

const emptyVarForGettingFieldsTyles: WidgetConfig = null as any;
type ResFieldType = typeof emptyVarForGettingFieldsTyles.result_fields[0];

interface ResultFieldFormProps {
    field: ResFieldType,
    onChange: (f: ResFieldType) => void,
    onDelete: () => void,
}

const ResultFieldForm = ({ field, onChange, onDelete }: ResultFieldFormProps) => {
    const schemas: any = useSchema();

    const availableEntities = Object.keys(schemas);
    const isJustValue = !field?.entity;

    const change = (key: keyof ResFieldType, value: any) => {
        onChange({
            ...field, 
            [key]: value,
        })
    }

    return <Box padding="5px">
            <Card>
                <Box
                    display="grid" 
                    gridTemplateColumns="1fr 1fr max-content" 
                    gap="10px" 
                    padding="10px"
                    marginBottom="5px"
                >
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.name" />}
                        value={field.name}
                        onChange={e => change('name', e.target.value)}
                    />
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.label" />}
                        value={field.label}
                        onChange={e => change('label', e.target.value)}
                    />
                    <IconButton onClick={onDelete} size="small">
                        <DeleteOutlined />
                    </IconButton>

                    <Box gridColumn="1/4" padding="10px">
                        <FormControlLabel 
                            onClick={() => {
                                if(!isJustValue) {
                                    onChange({ ...field, entity: undefined, entity_field: undefined });
                                }
                            }}
                            control={<Radio checked={isJustValue}/>} 
                            label={<FormattedMessage id="dashboard.widget.select_res_field.fieldtype" />} 
                        />

                        <FormControlLabel 
                            onClick={() => { 
                                if(isJustValue && availableEntities.length) {
                                    onChange({ ...field, fieldtype: undefined, entity: availableEntities[0] });
                                }
                            }}
                            control={<Radio checked={!isJustValue}/>} 
                            label={<FormattedMessage id="dashboard.widget.select_res_field.entity" />} 
                        />

                        {isJustValue ?
                            <FormControl fullWidth>
                                <InputLabel><FormattedMessage id="dashboard.widget.select_res_field.fieldtype_label" /></InputLabel>
                                <Select value={field.fieldtype} onChange={e => change('fieldtype', e.target.value)}>
                                    <MenuItem value="number"><FormattedMessage id="dashboard.widget.select_res_field.number" /></MenuItem>
                                    <MenuItem value="text"><FormattedMessage id="dashboard.widget.select_res_field.text" /></MenuItem>
                                    <MenuItem value="percent"><FormattedMessage id="dashboard.widget.select_res_field.percent" /></MenuItem>
                                </Select>
                            </FormControl>
                            : 
                            <>
                                <FormControl fullWidth>
                                    <InputLabel><FormattedMessage id="dashboard.widget.select_res_field.entity_label" /></InputLabel>
                                    <Select value={field.entity} onChange={e => change('entity', e.target.value)}>
                                        {availableEntities.map(key => 
                                            <MenuItem key={key} value={key}>{key}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                {field.entity &&
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="dashboard.widget.select_res_field.entity_field" /></InputLabel>
                                        <Select value={field.entity_field || ''} onChange={e => change('entity_field', e.target.value)}>
                                            {Object.keys(schemas[field.entity]).map(key => {
                                                const schema = schemas[field.entity as string][key];
                                                const label = schema && schema.label ? `${schema.label} (${key})` : key;
                                                return { key, label }
                                            })
                                            .sort((a,b) => a.label > b.label ? 1 : -1)
                                            .map(({ key, label }) => <MenuItem key={key} value={key}>{label || key}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                }
                            </>
                        }
                    </Box>
                </Box>
            </Card>
        </Box>
    
}


const DisplaySettingsEdit = ({ data, widget, update }: { widget: WidgetConfig, data: WidgetConfig["display_settings"], update: (changes: Partial<WidgetConfig["display_settings"]>) => void }) => {
    return (
        <Card elevation={0}>
            <CardContent>
                <Typography variant="h6">
                    <FormattedMessage id="dashboard.widget.displaySettings" /> 
                </Typography>

                <FormGrid columns="max-content max-content max-content 1fr 1fr" style={{ alignItems: "baseline" }} noMargin>
                  <FormControlLabel 
                    control={
                      <Switch 
                        checked={data.show_pct} 
                        onClick={(e: any) => update({ show_pct: e.target.checked })}
                        />}
                    label={<FormattedMessage id="dashboard.widget.show_pct" />}
                    />
                  
                  <FormControlLabel 
                    control={
                      <Switch 
                        checked={data.show_table} 
                        onClick={(e: any) => update({ show_table: e.target.checked })}
                            />}
                    label={<FormattedMessage id="dashboard.widget.show_table" />} 
                    />
                  <FormControlLabel 
                    control={
                      <Switch 
                        checked={!data.hide_chart} 
                        onClick={(e: any) => update({ hide_chart: !e.target.checked })}
                        />}
                    label={<FormattedMessage id="dashboard.widget.show_chart" />} 
                    />

                  {/* <SelectTooly
                      schema={{
                          type: FieldType.select,
                          label_id: "dashboard.widget.total_row",
                          values: [{ value: "sum", label: "SUM"}, { value: "avg", label: "AVG"}],
                      }}
                      field="total_row"
                      row={form.state.display_settings}
                      onChange={(o,c) => form.change('display_settings', { ...form.state.display_settings, total_row: c.total_row })}
                      /> */}

                  <FormControlsForFields
                    schema={WidgetDisplaySettingsSchema}
                    fields={[
                      ["total_row"],
                      ["width"],
                    ]}
                    data={data}
                    onChange={(o,c) => update(c)}
                    />

                </FormGrid>


                <FormGrid columns="1fr 1fr" forceEvenColumns>
                  <TextField
                    margin="dense"
                    value={data.link}
                    multiline
                    onChange={e => update({ link: e.target.value })}
                    label={<FormattedMessage id="dashboard.widget.links_by_labels" />}
                    helperText={<FormattedMessage id="dashboard.widget.links_by_labels_hint" />}
                    />

                {widget.widgettype !== "counter" && widget.widgettype !== "placeholder" &&
                  <TextField
                    margin="dense"
                    value={data.empty_state_message}
                    onChange={e => update({ empty_state_message: e.target.value })}
                    label={<FormattedMessage id="dashboard.widget.empty_state_message" />}
                    helperText={<FormattedMessage id="dashboard.widget.empty_state_message_hint" />}
                    />}

                  {widget.widgettype === "counter" &&
                    <TextField
                      margin="dense"
                      value={data.counter_colors}
                      multiline
                      onChange={e => update({ counter_colors: e.target.value })}
                      label={<FormattedMessage id="dashboard.widget.counter_colors" />}
                      helperText={<FormattedMessage id="dashboard.widget.counter_colors_hint" />}
                      />}

                </FormGrid>
                
            </CardContent>
        </Card>
    );
}



interface EditWidgetPopupProps {
    dashboardCode: string;
    widget: WidgetConfig;
    onSave: (data: WidgetConfig) => void;
    onRemove: () => void;
    onRemoveGlobal: () => void;
    onDuplicate: () => void;
    isLoading: boolean;
}

let idcount = 0;

export const SqlInputProps = {
    autoRows: true,
    rows: 10,
    controlProps: { style: { fontFamily: 'monospace' }, spellCheck: false },
};


export const EditWidgetPopup = ({ widget, onRemove, onRemoveGlobal, onSave, onDuplicate, isLoading, dashboardCode }: EditWidgetPopupProps) => {
    const [widgetState] = useState({ 
        ...widget, 
        result_fields: widget.result_fields.map(field => ({...field, itemId: ++idcount})),
        details_fields: widget.details_fields.map(field => ({...field, itemId: ++idcount})),
        params: widget.params.map(param => ({...param, itemId: ++idcount}))
    });

    const [testParams, setTestParams] = useState<Record<string, string>>({});
    const [testUrl, setTestUrl] = useState<string>("");

    const form = useForm(widgetState);

    const { result_fields: res_fields, details_fields } = form.state;

    const saveWidget = () => {
        onSave({
            ...form.state, 
            result_fields: form.state.result_fields.map(({itemId, ...field}) => field),
            details_fields: form.state.details_fields.map(({itemId, ...field}) => field),
            params: form.state.params.map(({itemId, ...param}) => param)
        });
    }

    return <>
        <DialogContent
            onKeyUp={e => {
                if(isHotkey("alt+s", e) || isHotkey("mod+s", e)) {
                    e.preventDefault();
                    saveWidget();
                }
            }}>
            <ActionRow>
                <Typography variant="h5"><FormattedMessage id="dashboard.widget.dialog" /></Typography>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator sizeVariant="s" />}
                <Button onClick={onDuplicate}><FormattedMessage id="dashboard.widget.duplicate" /></Button>
            </ActionRow>

            <Card elevation={0}>
                <CardContent>
                    <FormGrid noMargin>
                      <FormControlsForFields
                        schema={WidgetSchema}
                        data={form.state}
                        onChange={(o,c) => form.update(c)}
                        fields={[
                          ["title"],
                          ["subtitle"],
                          ["widgettype", { labelIdPrefix: "dashboard.screen" }],
                        ]}
                        />
                    </FormGrid>
                </CardContent>
            </Card>

            <DisplaySettingsEdit
              widget={form.state}
              data={form.state.display_settings}
              update={c => form.change("display_settings", { ...form.state.display_settings, ...c })}
              />

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.resultfields" /> 
                        <IconButton onClick={() => form.change('result_fields', [...res_fields, { name: '', label: '', itemId: ++idcount }])}><Add /></IconButton>
                    </Typography>
                    {res_fields.map((field) => 
                        <ResultFieldForm 
                            key={field.itemId}
                            field={field} 
                            onChange={changed => form.change('result_fields', res_fields.map((f) => f.itemId === field.itemId ? changed : f))}
                            onDelete={() => form.change('result_fields', res_fields.filter((f) => f.itemId !== field.itemId))}
                        />
                    )}

                    <FormGrid columns="1fr 1fr">
                      {form.select(
                          'value_field', 
                          form.state.result_fields, 
                          field => ({value: field.name, label: field.label}),
                          { label: <FormattedMessage id="dashboard.widget.value_field" /> }
                      )}

                      {form.select(
                          'label_field', 
                          form.state.result_fields, 
                          field => ({value: field.name, label: field.label}),
                          { label: <FormattedMessage id="dashboard.widget.label_field" /> }
                      )}
                    </FormGrid>
                </CardContent>
            </Card>

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.params" /> 
                        <IconButton 
                            onClick={() => form.change(
                                'params', 
                                [
                                    { label: '', code: '', fieldtype: 'text', default: '', itemId: ++idcount }, 
                                    ...form.state.params,
                                ]
                            )}
                        >
                            <Add />
                        </IconButton>
                    </Typography>
                    <TableForFields
                        schema={WidgetParamsSchema} 
                        fields={[
                            ['label', { editable: true }],
                            ['code', { editable: true }],
                            ['fieldtype', { editable: true, labelIdPrefix: "dashboard.widget.param.fieldtypes" }],
                        ]}
                        data={form.state.params}
                        onChange={(old, changes) => form.change(
                            'params',
                            form.state.params.map(param => old.itemId === param.itemId? {...param, ...changes} : param)
                        )}
                        rowButtons={row => (
                          <IconButton
                            size="small"
                            onClick={() =>  form.change(
                                'params',
                                form.state.params.filter(param => row.itemId !== param.itemId)
                            )}>
                            <DeleteOutlined />
                          </IconButton>
                        )}
                      />
                </CardContent>
            </Card>


            <Card elevation={0}>
                <CardContent>
                  <Buttons>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.query" /> 
                    </Typography>

                    <OccupyFreeSpace />

                    <ButtonOptionPicker
                      selected={form.state.query_mode || "string"}
                      setSelected={v => form.update({ query_mode: v })}
                      options={[
                        ["text", "string"],
                        ["dataschema", "chunks"],
                      ]}
                      />
                  </Buttons>

                    {form.state.query_mode !== "chunks" &&
                      <FormGrid columns="1fr" noMargin>

                        {form.input('query_substitutions', {
                            ...SqlInputProps,
                            label: <FormattedMessage id="dashboard.widget.query_substitutions" />,
                            hint: <FormattedMessage id="dashboard.widget.query_substitutions_hint" />,
                            })}

                        {form.input('query_string', { ...SqlInputProps, label: <FormattedMessage id="dashboard.widget.query_string" /> })}

                        {form.input('details_query_string', { ...SqlInputProps, label: <FormattedMessage id="dashboard.widget.details_query_string" /> })}
                      
                      </FormGrid>}

                    {form.state.query_mode === "chunks" &&
                      <FormGrid columns="1fr 1fr" forceEvenColumns noMargin>
                        <Typography><FormattedMessage id="dashboard.widget.query_string" /></Typography>
                        <Typography><FormattedMessage id="dashboard.widget.details_query_string" /></Typography>
                        
                        <CompositeQueryChunkEditor
                          data={form.state.query_chunks_query}
                          update={v => form.update({ query_chunks_query: { ...(form.state.query_chunks_query || { chunks: [] }), ...v} })}
                          />

                        <CompositeQueryChunkEditor
                          data={form.state.query_chunks_details_query}
                          update={v => form.update({ query_chunks_details_query: { ...(form.state.query_chunks_details_query || { chunks: [] }), ...v} })}
                          />                        
                      
                      </FormGrid>}
                </CardContent>
            </Card>

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.detailsresultfields" /> 
                        <IconButton onClick={() => form.change('details_fields', [...details_fields, { name: '', label: '', itemId: ++idcount }])}><Add /></IconButton>
                    </Typography>

                    <TableForFields
                      schema={WidgetDetailResultFieldSchema} 
                      fields={[
                          ['name', { editable: true }],
                          ['label', { editable: true }],
                          ['link', { editable: true, }],
                      ]}
                      data={details_fields}
                      onChange={(old, changes) => form.change(
                          'details_fields',
                          details_fields.map(field => old.itemId === field.itemId? {...field, ...changes} : field)
                      )}
                      rowButtons={row => (
                        <IconButton
                          size="small"
                          onClick={() =>  form.change(
                              'details_fields',
                              details_fields.filter((f) => f.itemId !== row.itemId)
                          )}>
                          <DeleteOutlined />
                        </IconButton>
                      )}
                    />
                </CardContent>
            </Card>

            <Card elevation={0}>
                <CardContent>
                    <FormGrid key="test-title" columns="1fr max-content">
                        <Typography variant="h6">
                            <FormattedMessage id="dashboard.widget.test_query" /> 
                        </Typography>

                        
                    </FormGrid>

                    <FormGrid key="test-params" noMargin>
                        {form.state.params.map(p => (
                            <TextField
                                key={p.code}
                                value={testParams[p.code] || ""}
                                label={p.code}
                                onChange={e => { const v = e.target.value; setTestParams(ps => ({ ...ps, [p.code]: v }))}}
                                />
                        ))}
                        <Button
                            color="primary"
                            endIcon={<Autorenew />}
                            onClick={() => {
                                const updatedParams = { ...testParams, dashboard: dashboardCode, v: (+(testParams["v"] || 0) + 1).toString()};
                                setTestParams(updatedParams);
                                setTestUrl(getWidgetUrl(widget._id, updatedParams));
                            }}
                            />
                    </FormGrid>

                    {testUrl && <Widget
                        config={widget}
                        getColumnWidth={getWidgetColumnsWidth}
                        url={testUrl}
                        openDetails={() => {}}
                        />}
                </CardContent>
            </Card>



        </DialogContent>
        <DialogActions>
            <Button onClick={onRemove} color="secondary" disabled={isLoading}>
                <FormattedMessage id="dashboard.widget.remove" />
            </Button>
            <Button onClick={onRemoveGlobal} color="secondary" disabled={isLoading}>
                <FormattedMessage id="dashboard.widget.remove_global" />
            </Button>
            <Button onClick={saveWidget} variant="contained" color="primary" disabled={isLoading}>
                <FormattedMessage id="dashboard.screen.save" />
            </Button>
        </DialogActions>
    </>
}