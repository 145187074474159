import React from 'react';
import { CasesListPage } from './presentation/CasesListPage';
import { useCaseList } from './caseData';

const CaseListContainer = () => {
    const cases = useCaseList();

    return <CasesListPage cases={cases} />
}

export default CaseListContainer;