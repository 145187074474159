import { useMemo } from "react";
import { CalendarEvent } from "./useCalendarEvents";
import moment, { Moment } from "moment";

export const eventComparerByStart = (a: CalendarEvent, b: CalendarEvent) => a.start_datetime < b.start_datetime ? -1 : 1;

export const intervalsOverlap = (start1: Moment, end1: Moment, start2: Moment, end2: Moment, allowedIntersectionM = 2): boolean => {
  if(start1.isAfter(start2)) {
    return intervalsOverlap(start2, end2, start1, end1, allowedIntersectionM);
  }

  if(start2.diff(end1, "m") <= -allowedIntersectionM) {
    return true;
  } else {
    return false;
  }
}

const findConflicts = (events: CalendarEvent[]) => {
  const eventsSorted = events.slice().sort(eventComparerByStart);
  let maxEndTime: Moment | null = null;
  const conflicts: Record<number, boolean> = {};
  const eventsPassed: CalendarEvent[] = [];
  eventsSorted.forEach(e => {
    if(!maxEndTime) {
      maxEndTime = moment(e.end_datetime);
    } else {
      const start = moment(e.start_datetime);
      const end = moment(e.end_datetime);
      if(start.diff(maxEndTime, "m") < -2) {
        conflicts[e._id] = true;
        eventsPassed.forEach(ex => {
          if(intervalsOverlap(moment(ex.start_datetime), moment(ex.end_datetime), start, end)) {
            conflicts[ex._id] = true;
          }
        });
      }
      
      if(end.isAfter(maxEndTime)) {
        maxEndTime = end;
      }
    }
    
    eventsPassed.push(e);
  });

  return conflicts;
}

export const useCalendarEventsConflicts = (events: CalendarEvent[]) => {

  const eventsWithConflictsIds = useMemo(() => {
    const confRooms = Array.from(new Set<string>(events.map(e => e.conf_room_id).filter(x => !!x) as string[]));
    const conflictsByRoom = confRooms.map(room => findConflicts(events.filter(e => e.conf_room_id === room)));

    return conflictsByRoom.reduce<Record<number, boolean>>((r,forRoom) => ({ ...r, ...forRoom }), {});
  }, [events]);

  return {
    withConflicts: eventsWithConflictsIds,
  }
}
