import { useEffect, useMemo, useState } from "react"
import { PresidiumQuestion } from "../usePresidiumQuestions";
import { useListSelection } from "../../../../toolympus/hooks/useListSelection";
import { apiFetch, apiUploadFile, downloadBlob } from "../../../../toolympus/api/core";
import { ProcessingResult } from "../../../../toolympus/components/primitives/ProcessingResult";
import { usePrepareBoardStatementDocx } from "./prepareBoardStatement";
import { downloadZip } from "client-zip";
import { FieldType, Schema } from "../../../../toolympus/hooks/useSchema";

interface Config {
  onComplete?: () => void;
}

interface Options {
  is_attach_documents: boolean;
}

export const BoardStatementsOptionsSchema: Schema = {
  is_attach_documents: { type: FieldType.bool, label: "Добавить постановления в документы" },
}

export const useBoardStatementsPreparation = (available: PresidiumQuestion[], cfg?: Config) => {
  const [state,setState] = useState<"_0off" | "_1selecting" | "_2preparing" | "3_process" | "_4prepared">("_0off");

  const selection = useListSelection<PresidiumQuestion>(q => q._id, { items: available });

  const [results,setResults] = useState<PresidiumQuestion[]>([]);
  const [errors,setErrors] = useState<PresidiumQuestion[]>([]);
  const [files,setFiles] = useState<Record<string, File>>({});
  const [options,setOptions] = useState<Options>({ is_attach_documents: true });

  const docx = usePrepareBoardStatementDocx();

  useEffect(() => {
    if(state === "_0off") {
      selection.selectAll(false);
      setResults([]);
      setErrors([]);
      setFiles({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  
  const downloadPackedFiles = async (files: File[]) => {
    const zipBlob = await downloadZip(files).blob();
    downloadBlob(zipBlob, `Постановления президиума ${new Date().toISOString().substring(0,10)}.zip`);
  }
  
  const doPrepare = async () => {
    const toProcess = selection.selectedItems.filter(q => !results.find(qx => qx._id === q._id));
    const attachDocuments = options.is_attach_documents;

    if(toProcess.length && state === "_2preparing") {
      setState("3_process");
      setErrors([]);
      const filesLocal: Record<string, File> = { ...files };
      const resultsLocal: PresidiumQuestion[] = [...results];

      let isError = false;
      for (let i = 0; i < toProcess.length; i++) {
        const question = toProcess[i];
        try {
          const result = await apiFetch<PresidiumQuestion>(`/api/presidium/question/${question._id}`, "put", {
            is_board_statement_prepared: true
          });
          const { file } = await docx.generate(result);
          filesLocal[question._id] = file;
          setFiles(x => ({ ...x, [question._id]: file }));
          
          if(attachDocuments) {
            await apiUploadFile(`/api/presidium/question/${question._id}/documents`, "post", "file", file);
          }
          
          const resultX = { ...question, result };
          setResults(x => ([ ...x, resultX]));
          resultsLocal.push(resultX);
        } catch(e) {
          isError = true;
          setErrors(x => ([ ...x, question]));
        }
      }
      const allFiles = resultsLocal.map(x => filesLocal[x._id]).filter(x => !!x);
      if(allFiles.length > 1) {
        await downloadPackedFiles(allFiles);
      } else {
        downloadBlob(allFiles[0], allFiles[0].name);
      }
      setState(isError ? "_2preparing" : "_4prepared");
    }
  }

  const getProcessingStatus = useMemo(() => {
    if(results.length || errors.length) {
      return (q: PresidiumQuestion): ProcessingResult => results.find(qx => qx._id === q._id) 
        ? "success"
        : errors.find(qx => qx._id === q._id)
          ? "error"
          : state === "3_process" 
              ? "loading"
              : "unknown";
    } else {
      return undefined;
    }
  }, [results, errors, state]);


  return {
    state,
    start: () => {
      if(state === "_1selecting" && !!selection.selectedItems.length) {
        setState("_2preparing");
      } else {
        setState("_1selecting");
      }
    },
    selection,
    
    cancel: () => {
      if(state === "_4prepared" && cfg?.onComplete) {
        cfg.onComplete();
      }
      setState("_0off");
    },
    proceed: () => {
      if(state === "_1selecting" && !!selection.selectedItems.length) {
        setState("_2preparing");
      }
    },
    prepare: doPrepare,
    results,
    getProcessingStatus,

    options: {
      data: options,
      update: (c: Partial<Options>) => setOptions(x => ({ ...x, ...c })),
    },

    downloadPrepared: () => {
      const preparedFiles = results.map(x => files[x._id]).filter(x => !!x);
      if(preparedFiles.length) {
        downloadPackedFiles(preparedFiles);
      }
    }
  }
}

export type BoardStatementsPreparationData = ReturnType<typeof useBoardStatementsPreparation>;

