import React from 'react';
import { useTheme } from '@mui/material';
import { OpenDetailsWidgetFn, WidgetResult } from '../../types';
import { LargeText, Panel, PanelContainer, SmallText } from './Number.style';

export type NumberType = (props: {data: WidgetResult, openDetails: OpenDetailsWidgetFn, getColumnWidth?: (cols: number) => string}) => JSX.Element

const getColor = (value: number, settings: WidgetResult["display_settings"], defaultColor: string) => {
    const { show_pct, counter_colors } = settings;

    let colors = [[Infinity, defaultColor]];

    try {
        if(counter_colors && counter_colors.length) {
            colors = counter_colors.split('\n').map(s => {
                const [maxVal, color] = s.trim().split(' ');
                return color ? [parseFloat(maxVal), color] : [Infinity, maxVal];
            });
        }
    } catch { }

    const colorVal = show_pct ? value*100 : value;
    const color = colors.find(([maxVal, ]) => colorVal <= maxVal);
    return color ? color[1] : defaultColor;
}

export const Number: NumberType = ({data, openDetails, getColumnWidth = () => '100%'}) => {
    const theme = useTheme();
    const value = data.rows.length ? +data.rows[0][data.value_field] : 0;
    const valueView = data.display_settings?.show_pct? value * 100 + '%' : value;

    const color = getColor(value, data.display_settings, theme.palette.success.main);

    return (
        <PanelContainer style={{width: getColumnWidth(data.display_settings.width || 1)}}>
                <Panel color={color as string} onClick={() => openDetails(data.rows[0])}>
                    <SmallText>{data.title}</SmallText>
                    <LargeText>{valueView}</LargeText>
                    <SmallText>{data.subtitle}</SmallText>
                </Panel>
        </PanelContainer>);
}