import { useState, useEffect } from 'react';
import { apiFetch } from '../../../toolympus/api/core';
import { Arbitrator } from '../../../typings/Arbitrators';
import { SearchData as SD } from '../../../toolympus/typings/data';

export type SearchData<ItemType> = SD<ItemType>;

export const useArbitratorsSearch = (): SearchData<Arbitrator> => {
    const [results, setResults] = useState<Arbitrator[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchPhrase, setSearchPhrase] = useState<string>("");


    useEffect(() => {
        if(searchPhrase && searchPhrase.length > 3) {
            const toid = setTimeout(() => {
                setIsLoading(true);
                apiFetch<Arbitrator[]>(`/api/arbitrator/search?name=${searchPhrase}`)
                    .then(data => setResults(data))
                    .then(() => setIsLoading(false));
                }, 500);
            return () => clearTimeout(toid);
        }
    }, [searchPhrase]);

    return {
        results,
        isLoading,
        searchPhrase,
        setSearchPhrase,
    }
}