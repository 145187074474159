import {
  HorizontalPositionRelativeFrom,
  ImageRun,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextWrappingType,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
} from "docx-dotx-hf-fix";
import { NodeProcessor, commonBlockProperties, convertMillimetersToEmu, convertMillimetersToImageUnits } from "../../../../../toolympus/components/PowerDoc/docx";
import { CustomFieldsBlockElementType, extractCustomBlockFields } from "../../../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";
import { CaseDataIn } from "./types";
import { Settings } from "../../../../SettingsPage";

export const CaseDocumentFooterCustomBlockType = "signature";

export const createCaseDocumentFooterProcessor = (data: CaseDataIn, settings: Settings): NodeProcessor => {
  return {
    shallProcess: n => (n as any).type === CustomFieldsBlockElementType && extractCustomBlockFields(n as any)["block_type"] === CaseDocumentFooterCustomBlockType,
    process: (n, g, s, ctx) => {
      const fields = extractCustomBlockFields(n as any);

      const children = g([{ text: fields.title, bold: true }, { text: "\t" + fields.name, bold: true }], s, ctx);
      
      const imgData = settings.document_generator_signature_url && ctx ? ctx.getImage(settings.document_generator_signature_url) : null;
      if(imgData) {
        children.push(new ImageRun({
          data: imgData as any,
          transformation: {
            width: convertMillimetersToImageUnits(+fields.signature_width || 10),
            height: convertMillimetersToImageUnits(+fields.signature_height || 10),
          },
          floating: {
            horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.RIGHT_MARGIN,
                offset: convertMillimetersToEmu(fields.signature_x_offset ? +fields.signature_x_offset : 0),
            },
            verticalPosition: {
                relative: VerticalPositionRelativeFrom.LINE,
                ...(fields.signature_y_offset
                  ? {
                    offset: convertMillimetersToEmu(+fields.signature_y_offset)
                  } : {
                    align: VerticalPositionAlign.CENTER,
                  }),
            },
            wrap: {
                type: TextWrappingType.NONE,
            },
            behindDocument: true,
        },
        }));
      }

      return [
        new Paragraph({
          children,
          ...commonBlockProperties(n),
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position: TabStopPosition.MAX,
            },
          ],
        })
      ];
  },
  };
}