import React, { useState } from 'react';
import { DescriptionOutlined } from '@mui/icons-material';
import { Editor } from "slate";
import { CustomElement } from "../../../slate";
import { EditorPlugin } from "../PowerDoc";
import { useDialogState } from "../primitives";
import { useCurrentDocumentationPage } from './CurrentDocumentationPageContext';
import { DocumentationPageEditorBlock, DocumentationPageEditorBlockType } from "./DocumentationPageEditorBlock";
import { useDocumentationPages } from './DocumentationPagesContext';
import { InsertDocumentationPageDialog } from "./InsertDocumentationPageDialog";
import { DocumentationDocBase } from './useDocumentationPages';

const withDocumentationPages = (editor: Editor) => {
    const { isVoid } = editor;
    editor.isVoid = (element: CustomElement) => {
        return element.type === DocumentationPageEditorBlockType ? true : isVoid(element);
    }
    
    return editor;
}


export const useDocumentationPagesPlugin = (): EditorPlugin => {
    const dialogState = useDialogState();
    const docs = useDocumentationPages();
    const currentDoc = useCurrentDocumentationPage();

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const insertDocument = (editor: Editor, documentId: string) => {
        editor.insertNode({
            type: DocumentationPageEditorBlockType,
            document_id: documentId,
            children: [{ text: "", }],
        } as CustomElement);
    }

    const insertExistingDocument = (editor: Editor, doc: DocumentationDocBase) => {
        if(doc.parent_id || !currentDoc) {
            insertDocument(editor, doc._id);
        } else {
            return docs.updateDocument(doc._id, { parent_id: currentDoc._id })
                .then(doc => {
                    insertDocument(editor, doc._id);
                    setIsCreating(false);
                    return doc;
                });
        }
    }

    const createDocument = (editor: Editor, title: string) => {
        setIsCreating(true);
        return docs.createDocument({ title, parent_id: currentDoc ? currentDoc._id : null })
            .then(doc => {
                insertDocument(editor, doc._id);
                setIsCreating(false);
                return doc;
            });
    }

    return {
        key: "documentation-pages",
        customBlocks: { [DocumentationPageEditorBlockType]: DocumentationPageEditorBlock },
        inject: withDocumentationPages,
        dialogs: (
            <InsertDocumentationPageDialog
                state={dialogState}
                insertExistingDocument={insertExistingDocument}
                createDocument={createDocument}
                isSaving={isCreating} 
                />),
        commands: [{
            name: "insert-documentation-page",
            invoke: () => dialogState.open(),
            menu: {
                section: "insert-item",
                label_id: "documentation.create_doc.insert",
                label: "Insert document",
                icon: <DescriptionOutlined />,
            }
        }]
    };
}