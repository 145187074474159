import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { Arbitrator } from "../../../typings/Arbitrators";

export interface ArbitratorSuggestion extends Arbitrator {
    question_id: string;
    result?: string | null;
    source: string | null;
    case_id: string;
    casenbr: string;
}

export interface ArbitratorSuggestionsData extends LoadedData<ArbitratorSuggestion[]> {

}

export const useArbitratorSuggestions = (apiPath: string) => {
    return useLoadedData<ArbitratorSuggestion[]>(apiPath, []);
}

export const useCaseArbitratorSuggestions = (caseId: string) => useArbitratorSuggestions(`/api/case/${caseId}/arbitrator-suggestion`);
export const useArbArbitratorSuggestions = (arbitratorId: string) => useArbitratorSuggestions(`/api/arbitrator/${arbitratorId}/suggestion`);
