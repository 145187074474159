import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import React from 'react';
import { useItemWithControls } from '../../api/useWithControls';
import { FormGrid } from '../primitives';
import { ProgramOperation } from './types';
import { EmailTemplateSelector } from '../emails';
import { Schema } from '../../hooks/useSchema';

interface Props {
    idx: number;
    operation: ProgramOperation;
    update: (c: Partial<ProgramOperation>) => void;
    remove: () => void;
    schema: Schema;
}

const Wrapper = styled(FormGrid)`
    border-left: 2px solid ${props => props.theme.palette.primary.main};
    padding: 0 0 0.5rem 10px;
    margin-bottom: 1rem;
    align-items: end;

    & .caption-nbr {
      color: ${props => props.theme.palette.grey[400]};
      transform: translateY(-40%);
    }
`;

export const OperationEditor = (props: Props) => {
    const { operation, update, remove, idx, schema } = props;
    const { controls } = useItemWithControls({ data: operation, update: update }, {
        schema,
    });
    return (
        <Wrapper columns="6px 1fr 1fr max-content" gap="dense">
            <Typography variant="caption" className="caption-nbr">{idx+1}.</Typography>
            {controls([
                ["kind", { wrapperStyle: { gridColumn: "2 / span 2" }}]
            ])}
            <IconButton size="small" onClick={remove}><DeleteOutline /></IconButton>
            {controls([
                operation.kind === "sql" && ["script", { autoRows: true, wrapperStyle: { gridColumn: "2 / span 3" }}],
                operation.kind === "email" && ["user_email", { wrapperStyle: { gridColumn: "2 / span 1" }}],
                operation.kind === "custom" && ["name", { wrapperStyle: { gridColumn: "2 / span 3" }}],
            ])}
            {operation.kind === "email" &&
              <EmailTemplateSelector
                field="template"
                row={operation}
                onChange={(o,c) => update(c)}
                schema={schema["template"]}
                />}

            <div />
        </Wrapper>
    );
}
