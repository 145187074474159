import { Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { Add, DeleteOutlined, GetAppOutlined } from '@mui/icons-material';
import React from 'react';
import { ActionRow, Dialog, LoadingIndicator, OccupyFreeSpace, PseudoLink, useConfirmationDialog } from '../../../toolympus/components/primitives';
import { TableForFields } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { CasePowerDocsData } from '../caseData/useCasePowerDocs';
import { CaseDocumentDialog } from './CaseDocumentDialog';

interface Props {
    data: CasePowerDocsData;
}

const PowerDocSchema: Schema = {
    title: { label: "Название" },
    created_datetime: { label: "Создан", type: FieldType.datetime },
}

export const CaseDocuments = (props: Props) => {
    const {
        documents,
        templates,
        createDialog,
        createDocument,
        removeDocument,
        generateDocument,
        isGenerating,
        editDocument,
    } = props.data;

    const confirmation = useConfirmationDialog();

    return (
        <>
            <ActionRow>
                <OccupyFreeSpace />
                <Button
                    color="primary"
                    onClick={createDialog.open}
                    startIcon={<Add />}
                    >
                    создать
                </Button>
            </ActionRow>
            <TableForFields
                fields={[
                    ["title"],
                    ["created_datetime", { utcToLocal: true }],
                ]}
                schema={PowerDocSchema}
                data={documents.data}
                rowButtons={row => <>
                    <IconButton size="small" onClick={() => confirmation.open({
                        title: "Удалить документ?",
                        execute: () => removeDocument(row._id),
                        confirmationLabel: "Удалить",
                    })}>
                        <DeleteOutlined />
                    </IconButton>
                    <IconButton size="small" onClick={() => generateDocument(row._id)}>
                        {isGenerating ? <LoadingIndicator sizeVariant="s" /> : <GetAppOutlined />}
                    </IconButton>
                </>}
                fieldElement={f => {
                    if(f === "title") {
                        return (row, schema, original) => <PseudoLink onClick={() => editDocument.open(row._id)}>{original}</PseudoLink>
                    }
                }}
                />
            
            <Dialog
                dialogTitle="Создать документ"
                {...createDialog}
                noFullscreen
            >
                <List>
                    {templates.data.map(template => (
                        <ListItem key={template._id} button onClick={() => { createDocument(template); createDialog.close(); }}>
                            <ListItemText primary={template.title} />
                        </ListItem>
                    ))}
                </List>
                {templates.isLoading && <LoadingIndicator />}

            </Dialog>

            <CaseDocumentDialog
                isOpen={editDocument.isOpen}
                close={editDocument.close}
                data={editDocument}
                isGenerating={isGenerating}
                generateDocument={generateDocument}
                />
        </>
    );
}
