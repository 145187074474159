import styled from '@emotion/styled';

import {
    Paper,
} from '@mui/material';

export const FormContainer = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

export const Form = styled(Paper)`
    width: 400px;
    height: auto;

    padding: 20px;
`;

export const WideForm = styled(Form)`
    width: 60%;
    display: flex;
    flex-flow: column;
`;

export const FixedRightTopLebel = styled.div`
    position: fixed;
    top: 0;
    right: 0;
`;

FixedRightTopLebel.defaultProps = {className: 'FixedRightTopLebel'};