import React from 'react';
import { Typography } from '@mui/material';
import { SignupFormFields } from '../../toolympus/components/auth';
import { SignupMode, useSignup } from '../../toolympus/hooks/auth';
import { PublicPageHarness } from './PublicPageHarness';


export const SignupForm = () => {
  const mode = SignupMode.Common;
  const signupData = useSignup({ signupApiPath: "/api/signup", mode: mode });

  return (<PublicPageHarness>
    <form onSubmit={e => { e.preventDefault(); signupData.send(); }} className="panel">
        <Typography variant="h2">Регистрация</Typography>
        <SignupFormFields
          mode={mode}
          signupData={signupData}
          onSuccessMessage={<Typography>Запрос отправлен. Вы получите доступ в систему, как только его утвердит администратор</Typography>}
          />
        
    </form>
  </PublicPageHarness>);
};
