import React from 'react';
import { useParams } from 'react-router';
import { usePracticeItem } from './practiceData';
import { PracticeForm } from './PracticeForm';

export const PracticeFormContainer = () => {
    const { id } = useParams<{ id: string }>();
    const data = usePracticeItem(id);
    return <PracticeForm data={data} />;
}
