import { Badge, Button, Chip, IconButton } from '@mui/material';
import { Add, FileCopyOutlined, RefreshOutlined } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Buttons, DeleteButton, Form, FormGrid, OccupyFreeSpace, SaveButton, SectionTitle, TabPanel, TabsHeader, useSaveable, useTabsState } from '../primitives';
import { FormControlsForFields } from '../schemed';
import { RobudConfig } from './Config';
import { OperationEditor } from './OperationEditor';
import { ProgramTriggers } from './ProgramTriggers';
import { RobudExecutionsList } from './RobudExecutionsList';
import { RobudPagesLinks } from './RobudPagesLinks';
import { useProgram } from './useProgram';

interface Props extends RobudConfig {
    
}

export const RobudProgramPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = useProgram(props.apiPath, id);
    const history = useHistory();

    useSaveable(data);

    const tabs = useTabsState(
        [
            ["program", <FormattedMessage id="robud.program.tabs.program" />],
            [
                "triggers",
                <Badge color={data.triggers.activeCount ? "primary" : "secondary"} badgeContent={data.triggers.activeCount.toString()}>
                    <FormattedMessage id="robud.program.tabs.triggers" />&nbsp;&nbsp;
                </Badge>
            ],
            [
                "executions",
                <Buttons>
                    <FormattedMessage id="robud.program.tabs.executions" />
                    <IconButton
                        style={{ background: "transparent", border: "none"}}
                        size="small"
                        color="primary"
                        onClick={() => data.executions.reload()}>
                        <RefreshOutlined />
                    </IconButton>
                </Buttons>
            ],
        ],
        "program",
        "tab"
    );

    return (
        <Form
            title={<FormattedMessage id="robud.program.page_title" />}
            headerItems={<>
                <RobudPagesLinks {...props} />
                <OccupyFreeSpace />
                
                {data.data.is_hidden && <Chip color="secondary" size="small" label={<FormattedMessage id="robud.program.program_removed" />} />}
                {data.hasChanges && <SaveButton action={() => data.save()} />}
                <IconButton size="small" onClick={() => data.copyItem().then(p => history.push(`${props.pagePath}/program/${p._id}`))}>
                    <FileCopyOutlined />
                </IconButton>
                {!data.data.is_hidden && <DeleteButton
                    size="small"
                    remove={() => data.remove().then(() => history.push(`${props.pagePath}/program`))}
                    title={<FormattedMessage id="robud.program.remove" />}
                    preventGoBack
                    />}
            </>}
            >
            
            <FormGrid columns="2fr 1fr 1fr">
                <FormControlsForFields
                    fields={[
                        ["title"],
                        ["_id", { readOnly: true }],
                        ["created_at", { readOnly: true, utcToLocal: true }],
                    ]}
                    schema={data.schema}
                    data={data.data}
                    onChange={(o,c) => data.update(c)}
                    />
            </FormGrid>

            <TabsHeader state={tabs} />

            <TabPanel state={tabs.forTab("program")}>
                <FormGrid columns="1fr 1fr">
                    <div>
                        <SectionTitle><FormattedMessage id="robud.program.labels.data" /></SectionTitle>
                        <FormControlsForFields
                            fields={[
                                ["kind", { labelIdPrefix: "robud.program.data.kind"}],
                                data.data.data?.kind === "sql" && ["script", { autoRows: true }],
                                data.data.data?.kind === "constant" && ["values"],
                                data.data.data?.kind === "custom" && ["name"],
                            ]}
                            schema={data.dataSchema}
                            data={data.data.data || {}}
                            onChange={(o,c) => data.update({ data: { ...o, ...c }})}
                            />
                    </div>

                    <div>
                        <SectionTitle><FormattedMessage id="robud.program.labels.operations" /></SectionTitle>

                        {data.operations.operations.map((op,idx) => (
                            <OperationEditor
                                idx={idx}
                                operation={op}
                                remove={() => data.operations.removeOperation(idx)}
                                update={c => data.operations.updateOperation(idx, c)}
                                schema={data.operationsSchema}
                                />
                        ))}

                        <Button size="small" color="primary" startIcon={<Add />} onClick={data.operations.addOperation}>
                            <FormattedMessage id="robud.program.operations.add" />
                        </Button>
                    </div>
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabs.forTab("triggers")}>
                <ProgramTriggers data={data.triggers} />
            </TabPanel>

            <TabPanel state={tabs.forTab("executions")}>
                <RobudExecutionsList pagePath={props.pagePath} data={data.executions} />
            </TabPanel>
        </Form>
    );
}
