import React from 'react';
import { useUpdateNotificationsEdit } from './useUpdateNotificationsEdit';
import { Buttons, Dialog, DialogState, FormGrid, OccupyFreeSpace, SmallerButton, useDialogState } from '../../primitives';
import { Button, Divider } from '@mui/material';
import { FormControlsForFields } from '../../schemed';
import { Schema } from '../../../hooks/useSchema';
import { PowerEditorBase } from '../../PowerDoc';
import { FormattedMessage } from 'react-intl';
import { Add, DeleteOutlined, EditOutlined, NotificationsOutlined } from '@mui/icons-material';
import { NotificationItem } from './UpdateNotificationsDisplay';

interface PopupProps extends Pick<DialogState, "isOpen" | "close"> {
  
}

const UpdateNotificationSchema: Schema = {
  dismiss_label: { label: "Dismiss label", label_id: "documentation.update_notifications.fields.dismiss_label" },
}

export const UpdatesNotificationsEditPopup = (props: PopupProps) => {
  const data = useUpdateNotificationsEdit();
  return (
    <Dialog
      dialogTitle={<FormattedMessage id="documentation.update_notifications.edit_title" />}
      noFullscreen
      isOpen={props.isOpen}
      close={props.close}
      titleActions={<>
        {!data.newNotification.isEditing && <Buttons>
          <Button startIcon={<Add />} size="small" color="primary" onClick={() => data.newNotification.startEditing()}><FormattedMessage id="common.add" /></Button>
        </Buttons>}
      </>}>
      

      {!!data.newNotification.isEditing && !!data.newNotification.item &&
        <FormGrid columns="1fr">
          <PowerEditorBase
            content={data.newNotification.item.content}
            update={v => data.newNotification.update({ content: v })}
            autoFocus
            />
          <FormControlsForFields
            fields={[
              ["dismiss_label"],
            ]}
            schema={UpdateNotificationSchema}
            data={data.newNotification.item}
            onChange={(o,c) => data.newNotification.update(c)}
            />

          

          <Buttons>
            <OccupyFreeSpace />
            <Button onClick={() => data.newNotification.cancel()}><FormattedMessage id="common.cancel" /></Button>
            <Button color="primary" onClick={() => data.newNotification.save()}><FormattedMessage id="common.save" /></Button>
          </Buttons>
        </FormGrid>}

      {!!data.editNotification.isEditing && !!data.editNotification.item &&
        <FormGrid columns="1fr">
          <PowerEditorBase
            content={data.editNotification.item.content}
            update={v => data.editNotification.update({ content: v })}
            autoFocus
            />

          <FormControlsForFields
            fields={[
              ["dismiss_label"],
            ]}
            schema={UpdateNotificationSchema}
            data={data.editNotification.item}
            onChange={(o,c) => data.editNotification.update(c)}
            />

          <Buttons>
            <OccupyFreeSpace />
            <Button onClick={() => data.editNotification.cancel()}><FormattedMessage id="common.cancel" /></Button>
            <Button color="primary" onClick={() => data.editNotification.save()}><FormattedMessage id="common.save" /></Button>
          </Buttons>
        </FormGrid>}


      {((!!data.newNotification.isEditing && !!data.notifications.length) || !!data.editNotification.isEditing) && <Divider />}

      <div style={{ display: "flex", flexFlow: "column", alignItems: "stretch" }}>
        {data.notifications.slice().reverse().map((n,i) => (
          <NotificationItem
            key={n._id}
            isFirst={i === 0}
            dismiss={() => {}}
            notification={n}
            extraActions={<>
              <SmallerButton onClick={() => data.removeNotification.run(n)}>
                <DeleteOutlined fontSize="small" />
              </SmallerButton>
              <SmallerButton onClick={() => data.editNotification.startEditing({ ...n })}>
                <EditOutlined fontSize="small" />
              </SmallerButton>
            </>}
            />
        ))}

      </div>
    </Dialog>
  );
}


export const UpdateNotificationsEditButton = () => {
  const dialogState = useDialogState();

  return <>
    <Button startIcon={<NotificationsOutlined />} onClick={() => dialogState.open()}><FormattedMessage id="documentation.update_notifications.edit_title" /></Button>
    {dialogState.isOpen &&
      <UpdatesNotificationsEditPopup {...dialogState} />}
  </>
}