import React, { useState } from 'react';
import styled from '@emotion/styled';

import { ClipboardButton } from '../../components/primitives/Buttons';

import { useFileList, FileInfo, FileListData, EditDialogProps, useFileInfoDialog } from './useFileList';
import { FormattedMessage } from 'react-intl';
import { DeleteButton } from '../primitives/DeleteButton';
import { Tooltip } from '../primitives/Tooltip';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { EditOutlined, SwapHoriz, Link, GetAppOutlined, Add } from '@mui/icons-material';
import { FormControl, TableForFields } from '../schemed';
import { Form } from '../primitives/Forms';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { SearchField } from '../primitives/SearchField';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { createFileDropHandler } from '../../api/files';

interface Props {
    filelist: FileListData;
    title?: string;
}

const ButtonsWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
`;

const EditFileinfoDialog = ({ isOpen, close, fileinfo, schema, update, save }: EditDialogProps) => {
    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
            <DialogTitle><FormattedMessage id="files.editDialogTitle" /></DialogTitle>
            <DialogContent>
                {fileinfo && <FormControl
                    field="alias"
                    schema={schema.alias}
                    row={fileinfo}
                    onChange={(_, c) => update(c)}
                    />}

            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={() => save()}>
                    <FormattedMessage id="common.save" />
                </Button>
                <Button onClick={close}>
                    <FormattedMessage id="common.cancel" />
                </Button>
            </DialogActions>
        </Dialog>);
}


export const FileList = ({ filelist, title }: Props) => {
    const dialog = useFileInfoDialog(filelist);
    const [isDragTarget, setIsDragTarget] = useState<boolean>(false);

    const fileButtons = (fi: FileInfo) => (
        <ButtonsWrapper>
            <Tooltip text_id="files.copyLink" withWrapper>
                <ClipboardButton value={fi.url} icon={<Link />} />
            </Tooltip>
            <Tooltip text_id="common.download">
                <IconButton size="small" onClick={() => filelist.download(fi)}><GetAppOutlined /></IconButton>
            </Tooltip>
            <Tooltip text_id="common.edit">
                <IconButton size="small" onClick={() => dialog.open(fi)}><EditOutlined /></IconButton>
            </Tooltip>
            <Tooltip text_id="common.replace">
                <IconButton size="small" onClick={() => filelist.replace(fi)}><SwapHoriz /></IconButton>
            </Tooltip>
            <Tooltip text_id="common.delete" withWrapper>
                <DeleteButton
                    title={<FormattedMessage id="files.removeConfirmation" />}
                    remove={() => filelist.remove(fi)}
                    size="small"
                    preventGoBack />
            </Tooltip>
        </ButtonsWrapper>);

    return (<>
        <Form title={title}
            formBodyProps={{
                onDragEnter: (e: any) => { e.preventDefault(); e.stopPropagation(); setIsDragTarget(true); },
                onDragOver: (e: any) => { e.preventDefault(); e.stopPropagation(); setIsDragTarget(true); },
                onDragLeave: (e: any) => setIsDragTarget(false),
                onDrop: createFileDropHandler(f => { setIsDragTarget(false); filelist.upload(f); }, true),
                style: isDragTarget ? { opacity: 0.5 } : undefined,
            }}
            headerItems={<>
                <IconButton color='primary' onClick={() => filelist.create()}><Add /></IconButton>
                {filelist.isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />
                <SearchField
                    filter={filelist.filter}
                    setFilter={filelist.setFilter}
                    doSearch={() => {}}
                    buttonLabel=" "
                    noButton
                    autoFocus
                />
                </>}
            fitFullHeight>

            <TableForFields
                fields={[
                    ["filename"],
                    ["alias"],
                    ["contenttype"],
                    ["meta_text"],
                ]}
                data={filelist.data}
                schema={filelist.schema}
                rowButtons={fileButtons}
                />
            {/* <DragTarget style={{ display: isDragTarget ? "flex" : "none" }} /> */}
        </Form>
        <EditFileinfoDialog {...dialog} />
    </>);
}

interface SimplifiedProps {
    apiPath: string;
    title?: string;
}

export const FileListSimplified = ({ title, apiPath }: SimplifiedProps) => {
    const filelist = useFileList(apiPath);
    return <FileList filelist={filelist} title={title} />
}
