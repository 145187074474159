import React from 'react';
import { ExpandMore } from "@mui/icons-material";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { createWithVoidInjector } from "../common";
import { ExpanderBlock, ExpanderBlockType } from './ExpanderBlock';
import { CustomElement } from '../../../../../slate';

const withExpander = createWithVoidInjector(ExpanderBlockType);

export const ExpanderPlugin: EditorPlugin = {
    key: "expander",
    inject: withExpander,
    commands: [{
        name: "insert-expander",
        hotkey: "alt+shift+e",
        invoke: editor => {
            editor.insertNode({
                type: ExpanderBlockType,
                content: undefined,
                children: [{ text: "", }],
            } as CustomElement);
        },
        menu: {
            section: "insert-item",
            label: "Expander",
            label_id: "powerdoc.plugins.expander.title",
            icon: <ExpandMore />,
        },
    }],
    customBlocks: { [ExpanderBlockType]: ExpanderBlock },
}
