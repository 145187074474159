import { useEffect, useState } from "react"

export const useDrag = (onUp: () => void) => {
    const [element, setElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (element) {
            const css = {
                width: element.style.width,
                height: element.style.height,
                position: element.style.position
            }

            const width = element.clientWidth;
            const height = element.clientHeight;

            element.style.width = width + 'px';
            element.style.height = height + 'px';

            element.style.position = 'fixed';

            const move = (e: MouseEvent) => {
                element.style.top = e.clientY - height/2 + 'px';
                element.style.left = e.clientX - width/2 + 'px';
            }

            const onup = () => {
                document.removeEventListener('mousemove', move);

                setTimeout(() => {
                    element.style.height = css.height;
                    element.style.width = css.width;
                    element.style.position = css.position;
                    
                    onUp();

                    setElement(null);
                });
            }

            document.addEventListener('mousemove', move);
            document.addEventListener('mouseup', onup);

            return () => {
                document.removeEventListener('mousemove', move);
                document.addEventListener('mouseup', onup);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    return {
        setElement,
        element
    }
}