import { CssBaseline, Drawer, Hidden, IconButton, useTheme } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import React, { ReactNode } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import grey from '@mui/material/colors/grey';

const drawerWidth = 240;

const RootView = styled.div`
    display: flex;
    border-right: 1px solid ${grey[200]};
`

const DrawerView = styled.nav`
    ${props => props.theme.breakpoints.up('sm')} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }
`

const DrawerPaperView = styled(Drawer)`
    & .MuiDrawer-paper{
        width: ${drawerWidth}px;

        ${props => props.theme.breakpoints.only('xs')} {
            border-left: none;
    }
}
`

const MenuButtomView = styled(IconButton)`
    position: fixed;
    top: 0px;
    right: 0px;
    margin-right: ${props => props.theme.spacing(2)};

    ${props => props.theme.breakpoints.up('sm')} {
        display: none;
    }
`

const BottomView = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

export interface SidebarProps {
    top?: ReactNode;
    menu?: ReactNode;
    bottom?: ReactNode;
}

export const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            {props.top}
            {props.menu}
            <BottomView>
                {props.bottom}
            </BottomView>
        </div>
    )

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RootView>
                    <CssBaseline />
                    <MenuButtomView
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </MenuButtomView>
                    <DrawerView>
                        <Hidden smUp implementation="css">
                            <DrawerPaperView
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </DrawerPaperView>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <DrawerPaperView
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </DrawerPaperView>
                        </Hidden>
                    </DrawerView>
                </RootView>
            </ThemeProvider>
        </StylesProvider>
    )
}