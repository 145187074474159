import React from 'react';
import { EditItemProps, NewItemProps } from '../../../toolympus/api/useNewItem';
import { ConfRoom } from './useConfRoomsConfiguration';
import { FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';

interface Props {
  data: EditItemProps<ConfRoom> | NewItemProps<Partial<ConfRoom>, ConfRoom>;
  schema: Schema;
}

export const EditConfRoomDialog = (props: Props) => {
  const { data } = props;

  return (
    <SimpleDialog
      dialogTitle="Переговорка"
      noFullscreen
      submitOnModEnter
      isOpen={data.isEditing}
      close={() => data.cancel()}
      save={() => data.save()}>
      {!!data.item &&
        <FormGrid columns="1fr">
          <FormControlsForFields
            fields={[
              ["title", { controlProps: { autoFocus: true }}],
              ["description"],
            ]}
            schema={props.schema}
            data={data.item}
            onChange={(o,c) => data.update(c)}
            errors={data.errors}
            />
        </FormGrid>}
    </SimpleDialog>
  );
}
