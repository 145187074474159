import { useState } from "react";
import { ArbitratorSelection } from "./usePresidiumQuestionArbitratorsSelection";
import { PresidiumQuestionResponses, PresidiumResponse } from "./usePresidiumResponses";
import { Option } from './usePresidiumQuestions';

export const getResponseType = (r?: PresidiumResponse | null) => {
    return r?.is_abstained
        ? "is_abstained"
        : r?.is_against_all
        ? "is_against_all"
        : r?.is_conflict
        ? "is_conflict"
        : r?.response
        ? "vote"
        : "";
}

export const useEditResponse = (data: PresidiumQuestionResponses, isCandidatesQuestion: boolean, candidates: ArbitratorSelection[], options: Option[]) => {
    const [editedResponse, setEditedResponse] = useState<PresidiumResponse | null>(null);
    const [original, setOriginal] = useState<PresidiumResponse | null>(null);
    const [changes, setChanges] = useState<Partial<PresidiumResponse>>({});

    const cancel = () => {
        setEditedResponse(null);
        setOriginal(null);
        setChanges({});
    }

    const hasChanges = Object.keys(changes).length > 0;

    const update = (c: Partial<PresidiumResponse>) => {
        if(editedResponse) {
            setEditedResponse({ ...editedResponse, ...c });
            setChanges({ ...changes, ...c });
        }
    }

    const updateTo = (c: Partial<PresidiumResponse>) => {
        if(editedResponse && original) {
            setEditedResponse({ ...original, ...c });
            setChanges({ ...c });
        }
    }

    const updateResponseType = (x: string) => {
        const base: Partial<PresidiumResponse> = {
            is_abstained: false,
            is_conflict: false,
            is_against_all: false,
            response: null,
            comment: "",
        }

        let change: Partial<PresidiumResponse> = {};

        switch(x) {
            case "is_abstained":
                change = { is_abstained: true };
                break;
            case "is_conflict":
                change = { is_conflict: true };
                break;
            case "is_against_all":
                change = { is_against_all: true };
                break;
            case "vote":
                change = { response: isCandidatesQuestion ? { vote: candidates.map(() => null) } : { option: null } };
                break;
        }

        if(Object.keys(change).length) {
            updateTo({ ...base, ...change });
        }        
    }

    const responseType = getResponseType(editedResponse);

    const canSave = hasChanges && (responseType !== "vote" || (changes.response?.vote || []).filter(x => !!x).length > 0 || changes.response?.option);

    const selectCandidate = (position: number, candidateId: string | null) => {
        if(editedResponse && responseType === "vote" && isCandidatesQuestion && editedResponse?.response?.vote) {
            const v = [...editedResponse.response.vote];
            v[position] = candidateId || null;

            setEditedResponse({ ...editedResponse, response: { vote: v }})
            setChanges({ ...changes, response: { vote: v }})
        }
    }

    const selectOption = (option: Pick<Option, "id"> | null) => {
        if(editedResponse && responseType === "vote" && !isCandidatesQuestion) {
            setEditedResponse({ ...editedResponse, response: { option: option ? { id: option.id } : null }})
            setChanges({ ...changes, response: { option: option ? { id: option.id } : null }})
        }
    }

    return {
        isOpen: !!editedResponse,
        close: cancel,
        response: editedResponse,
        startEdit: (r: PresidiumResponse) => { setEditedResponse(r); setOriginal(r); setChanges({}); },
        hasChanges,
        canSave,
        update,
        responseType,
        updateResponseType,
        save: () => {
            if(editedResponse && hasChanges) {
                data.updateResponse(editedResponse.member_id, changes)
                    .then(() => { cancel(); })
            }
        },

        candidates,
        selectCandidate,
        selectOption,
        options,
        isCandidatesQuestion,
    }
}

export type EditResponseData = ReturnType<typeof useEditResponse>;
