import BackgroundImageHatchWhite from '../dot-hatch-w.svg';
import BackgroundImageHatchBlack from '../dot-hatch-b.svg';
import { ReactNode } from 'react';

export interface BackgroundOption {
  name: string;
  label: ReactNode;
  css: string;
}

export const BackgroundBlue = {
  name: "blue",
  label: "Голубой",
  css: `background: #5B99D2 url(${BackgroundImageHatchWhite});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundGray = {
  name: "gray",
  label: "Серый",
  css: `background: #f5f5f5 url(${BackgroundImageHatchBlack});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundWhite = {
  name: "white",
  label: "Белый",
  css: `background: #ffffff;\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundOptionDefault = BackgroundBlue;

export const BackgroundOptions = [
  BackgroundBlue,
  BackgroundGray,
  BackgroundWhite,
];
