import React from 'react';
import { PseudoLink2 } from '../../../toolympus/components/primitives';
import { useConfRoomsConfiguration } from './useConfRoomsConfiguration';
import { SimpleList2 } from '../../../toolympus/components/primitives/SimpleList2';
import { EditConfRoomDialog } from './EditConfRoomDialog';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';

interface Props {
  
}

export const ConfRoomsConfigurationPage = (props: Props) => {
  const data = useConfRoomsConfiguration();

  return (<>
    <SimpleList2
      title="Переговорки"
      data={{
        ...data,
        view: "",
        setView: () => {},
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => {
          data.newItem.startEditing();
        }
      }}
      fields={[
        ["title"],
        ["description"],
      ]}
      fieldElement={f => {
        switch(f) {
          case "title":
            return (r,s,o) => <PseudoLink2 onClick={() => data.editItem.startEditing(r)}>{o}</PseudoLink2>
        }
      }}
      schema={data.schema}
      headerActionsR={<>
        <PluggableDocumentationButton documentationKey="org_events" />
      </>}
      />

    <EditConfRoomDialog
      data={data.newItem}
      schema={data.schema}
      />
    <EditConfRoomDialog
      data={data.editItem}
      schema={data.schema}
      />
  </>);
}
