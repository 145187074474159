import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../frame';
import { DocumentationPage } from './DocumentationPage';
import { DocumentationPagesConfig, DocumentationPagesProvider } from './DocumentationPagesContext';
import { DocumentationRootPage } from './DocumentationRootPage';

interface Props extends Omit<DocumentationPagesConfig, "pagePath"> {
}

export const DocumentationRoutes = (props: Props) => {
    let { path } = useRouteMatch();
    
    return (
        <DocumentationPagesProvider pagePath={path} {...props}>
            <Routes
                routes={[
                    { path: `${path}/:id`, component: DocumentationPage },
                    { path: `${path}/`, component: DocumentationRootPage },
                ]}
            />
        </DocumentationPagesProvider>
    );
}
