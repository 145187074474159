import { useEffect, useState } from "react";
import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";

interface CaseEmployeeX {
    _id: string;
    is_primary?: boolean;
}

export interface CaseEmployees {
    assistant_id?: string | null;
    other: string[];
}

export interface CaseEmployeesData {
    employees: CaseEmployees;
    assistant?: CaseEmployeeX;
    other: CaseEmployeeX[];
    update: (changes: Partial<CaseEmployees>) => void;
    save: () => void;
    reload: () => void;
    isLoading: boolean;
    hasChanges: boolean;
    schema: Schema;
}

export const useCaseEmployees = (caseId: string): CaseEmployeesData => {
    const path = `/api/case/${caseId}/employee`;
    const { data, isLoading, reload, setData } = useLoadedData<CaseEmployeeX[]>(path, []);
    const [employees, setEmployees] = useState<CaseEmployees>({ assistant_id: null, other: [] });
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const transformToApi = (x: CaseEmployees): CaseEmployeeX[] => {
        return [
            ...(x.assistant_id ? [{ _id: x.assistant_id, is_primary: true }] : []),
            ...x.other.map(_id => ({ _id })),
        ];
    }

    const transformFromApi = (x: CaseEmployeeX[]): CaseEmployees => {
        const primary = x.find(e => e.is_primary);
        const other = x.filter(e => !e.is_primary);
        return {
            assistant_id: primary?._id || null,
            other: other.map(e => e._id),
        }
    }

    useEffect(() => {
        setEmployees(transformFromApi(data));
    }, [data]);

    const update = (changes: Partial<CaseEmployees>) => {
        setHasChanges(true);
        setEmployees(x => ({ ...x, ...changes }));
    }

    
    const save = () => {
        if(hasChanges) {
            setIsSaving(true);
            apiFetch<CaseEmployeeX[]>(path, FetchTypes.PUT, transformToApi(employees))
                .then(data => {
                    setData(data);
                    setHasChanges(false);
                    setIsSaving(false);
                })
                .catch(e => {
                    setIsSaving(false);
                });
        }
    }

    const dictionary = "Employees";

    const schema = {
        assistant_id: { type: FieldType.dictionarySelect, dictionary, label: "Ассистент" },
        other: { type: FieldType.dictionarySelectMulti, dictionary, label: "Другие сотрудники" },
    };


    return {
        employees,
        update,
        reload,
        save,
        
        schema,
        isLoading: isLoading || isSaving,
        hasChanges,

        assistant: data.find(e => e.is_primary),
        other: data.filter(e => !e.is_primary),
    }
}
