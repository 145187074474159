import React from 'react';
import { PowerEditorBase, PowerEditorBaseProps } from '../PowerDoc';
import { ColumnsPlugin } from '../PowerDoc/plugins/Columns';
import { ExpanderPlugin } from '../PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from '../PowerDoc/plugins/MediaFiles';
import { TablesPlugin } from '../PowerDoc/plugins/Tables';
import { TilesPlugin } from '../PowerDoc/plugins/Tiles';
import { PowerEditorSettingsProvider } from '../PowerDoc/PowerEditorSettingsContext';
import { useDocumentationPagesPlugin } from './DocumentationPagesEditorPlugin';
import { useDocumentationPages } from './DocumentationPagesContext';
import { CustomFieldsBlockPlugin } from '../PowerDoc/plugins/CustomFieldsBlock';
import { SettingsBlockPlugin } from '../PowerDoc/plugins/SettingsBlock';

const DocumentationEditorUnwrapped = (props: PowerEditorBaseProps) => {
    const { config } = useDocumentationPages();
    const mediaFilesPlugin = useMediaFilesPlugin();
    const pagesPlugin = useDocumentationPagesPlugin();

    const plugins = [
      TilesPlugin,
      ColumnsPlugin,
      TablesPlugin,
      ExpanderPlugin,
      mediaFilesPlugin,
      pagesPlugin,
      SettingsBlockPlugin,
    ];

    if(config.enableCustomFieldsBlock) {
      plugins.push(CustomFieldsBlockPlugin);
    }

    return (
        <PowerEditorBase
            {...props}
            plugins={plugins}
            autoFocus={!props.viewMode}
            />
    );
}


export const DocumentationEditor = (props: PowerEditorBaseProps) => {
    return (
        <PowerEditorSettingsProvider EditorComponent={DocumentationEditorUnwrapped}>
            <DocumentationEditorUnwrapped {...props} />
        </PowerEditorSettingsProvider>
    );
}
