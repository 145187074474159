import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Query } from './useQueryConsole';

interface Props {
    isActive: boolean;
    deactivate: () => void;
    history: Query[];
    restore: (id: string) => void;
    delete: (id: string) => void;
}

export const QueryHistoryPopup = (props: Props) => {
    return (
        <Dialog open={props.isActive} onClose={() => props.deactivate()} maxWidth="sm">
            <DialogTitle>
                <FormattedMessage id="queryconsole.history" />
            </DialogTitle>

            <List style={{minWidth: "600px"}}>
                {props.history.map(query => (
                    <ListItem button onClick={() => { props.restore(query._id || ""); props.deactivate(); }}>
                        <ListItemText primary={query.title} secondary={`${query.useremail} ${query.datetime}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => props.delete(query._id || "")}><Delete /></IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={() => props.deactivate()}>
                    <FormattedMessage id="common.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
