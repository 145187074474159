import { useState } from "react";
import { moveArrayItemTo } from "../../../hooks/useEditArray";

export interface ReorderItemsData<T> {
  items: T[] | null;
  startEditing: (items: T[]) => void;
  update: (items: T[]) => void;
  cancel: () => void;
  isEditing: boolean;
  moveItemTo: (item: T, targetIdx: number) => void;
  save: () => Promise<void>;
  isSaving: boolean;
}

interface ReorderItemsBySortorderConfig<T> {
  updateItemOrder: (item: T, order: number) => Promise<any>;
  startWith?: T[] | undefined;
  onSave?: () => void;
}

export const useReorderItemsBySortorder = <T,>(cfg: ReorderItemsBySortorderConfig<T>): ReorderItemsData<T> => {
  const [items, setItems] = useState<T[] | null>(cfg.startWith || null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const save = async () => {
    if(items) {
      setIsSaving(true);

      try {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          await cfg.updateItemOrder(item, i);
        }
        setItems(null);
        if(cfg.onSave) {
          cfg.onSave();
        }
      } finally {
        setIsSaving(false);
      }
    }
  }

  const moveItemTo = (item: T, targetIdx: number) => {
    if(items && item) {
      setItems(moveArrayItemTo(items, items.indexOf(item), targetIdx));
    }
  }

  return {
    items,
    update: setItems,
    startEditing: items => setItems(items),
    cancel: () => setItems(null),
    isEditing: !!items,
    
    moveItemTo,

    save,
    isSaving,
  }
}
