import { IconButton, Dialog, DialogContent, Typography, DialogActions, Button, TextField } from '@mui/material';
import { CallMade, DeleteOutline } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SimpleDialog } from '../../toolympus/components/primitives';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { ClipboardButton, SaveButton } from '../../toolympus/components/primitives/Buttons';
import { EmptyState } from '../../toolympus/components/primitives/EmptyState';
import { Form, FormRow, FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { PagingControl } from '../../toolympus/components/primitives/PagingControl';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives/Tabs';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { useEmployeeCaseList } from '../Cases/caseData';
import { CasesListTableOnly } from '../Cases/presentation/CasesList';
import { Employee, useEmployee, useUserSelectSchema } from './employeeData';


interface ButtonProps {
    action: () => void;
}

const DeleteButtonWithConfirm = ({ action }: ButtonProps) => {
    const [isPopupActive, setIsPopupActive] = useState<boolean>(false);
    return <>
        <IconButton onClick={() => setIsPopupActive(true)} size="small" color="secondary"><DeleteOutline /></IconButton>
        <Dialog open={isPopupActive} onClose={() => setIsPopupActive(false)}>
            <DialogContent>
                <Typography>Удалить сотрудника?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsPopupActive(false)}>Отмена</Button>
                <Button color="secondary" onClick={() => {setIsPopupActive(false); action()}}>Удалить</Button>
            </DialogActions>
        </Dialog>
    </>;
}

export const EmployeeForm = () => {
    const { id } = useParams<{ id: string }>();
    const schemas = useSchema();
    const userSchema = useUserSelectSchema();
    const schema = schemas["employee"];
    schema["user_id"] = userSchema.schema;

    const employee = useEmployee(id);
    const cases = useEmployeeCaseList(id);

    const [newUserEmail, setNewUserEmail] = useState<string | null>(null);

    const tabsState = useTabsState([
        ["cases", "Дела"],
    ]);

    const controls = (fs: FieldDefinition[]) => (
        <FormControlsForFields
            fields={fs}
            schema={schema}
            data={employee.data}
            errors={employee.errors}
            onChange={(_, changes) => employee.update(changes)} />
    );

    useSaveable(employee);
    
    return (
        <Form
            title="Сотрудник"
            headerItems={<>
                {employee.hasChanges && <SaveButton action={() => employee.save()} />}
                {employee.isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />
                {!employee.data.user_id && !employee.hasChanges && <Button size="small" onClick={() => setNewUserEmail("")}>создать пользователя</Button>}
                <DeleteButtonWithConfirm action={employee.remove} />
            </>}>

            <FormGrid>
                {controls([
                        ["lastname"],
                        ["firstname"],
                        ["middlename"],
                        ["position", { wrapperStyle: { gridColumn: "span 2"} }],
                        ["user_id", {
                            actions: (e: Employee) => <>
                                <ClipboardButton value={userSchema.getUserEmail(e.user_id || "")} />
                                <Link to={`/system/users?filter=${e.user_id}`}><IconButton size="small"><CallMade /></IconButton></Link>
                            </>
                        }],
                    ])}
            </FormGrid>

            <TabsHeader state={tabsState} scrollable />
            <TabPanel state={tabsState.forTab("cases")}>
                <FormRow>
                    <PagingControl data={cases} totalRecordsText="Всего дел: {0}" />
                </FormRow>
            
                <CasesListTableOnly
                    cases={cases.data}
                    emptyState={<EmptyState text={<p style={{ textAlign: 'center'}}><i>У сотрудника нет дел</i></p>} />} />
            </TabPanel>

            <SimpleDialog
                noFullscreen
                isOpen={newUserEmail !== null}
                close={() => setNewUserEmail(null)}
                dialogTitle="Создать пользователя"
                saveLabel="Создать"
                save={() => {
                    if(newUserEmail) {
                        employee.createUser(newUserEmail)
                            .then(() => {
                                setNewUserEmail(null);
                                userSchema.reload();
                            });
                    }
                }}>
                <TextField
                    label="Email"
                    value={newUserEmail || ""}
                    onChange={e => setNewUserEmail(e.target.value)}
                    fullWidth
                    />
            </SimpleDialog>
        </Form>
    );
}
