import React from 'react';
import { PartiesPollsPrepSettings } from './usePartiesPollsPrepSettings';
import { FormGrid } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { SettingsData } from '../../toolympus/api/useSettings';
import { EmailTemplateSelector } from '../../toolympus/components/emails';

interface Props {
  data: SettingsData<PartiesPollsPrepSettings>;
}

export const PartiesPollsPrepSettingsForm = (props: Props) => {
  const { data } = props;
  
  return (
    <FormGrid columns="1fr" noMargin>
      <FormControlsForFields
        data={data.settings}
        onChange={(o,c) => data.update(c)}
        fields={[
          ["poll_after_case_finished_days"],
          ["poll_prevent_repeat_days"]
        ]}
        schema={data.schema}
        />

      <EmailTemplateSelector
        field="poll_request_email_template"
        row={data.settings}
        onChange={(o,c) => data.update(c)}
        schema={data.schema?.poll_request_email_template}
        />
    </FormGrid>
  );
}
