import { useEffect, useState } from "react";
import { apiFetch, FetchTypes } from "../../api/core";
import { useFetch } from "../../api/useFetch"
import { useWithPromiseLoading } from "../../api/useWithPromiseLoading";
import { useForm } from "../../hooks/useForm";
import { DashboardConfig, WidgetConfig } from "../Dashboard/types";
import { DashItem } from "./types"
import { useItemActionWithConfirmation } from "../../api/useAction";
import { useIntl } from "react-intl";

interface Config {
    defaultDashboardCode?: string | null;
}

export const useEditDash = (cfg?: Config) => {
    const { data, loading, reload } = useFetch<DashItem[]>([], { url: '/dashboard' });
    const [dashboardCode, setDashboardCode] = useState<string | null>(cfg?.defaultDashboardCode || null);
    const actions = useFetch(null, {});

    return {
        data, 
        loading: loading || actions.loading,
        dashboardCode,
        setDashboardCode,
        add: (body: { code: string, title: string }) => {
            actions.request({ method: FetchTypes.POST, url: '/dashboard', body }).then(reload);
        },
    }
}

const newWidget = {
    title: '',
    widgettype: null as null | string,
}

const WidgetApiPath = "/api/dashboard/widget/";

export const useEditForm = (code: string) => {
    const {loading, data, setData, reload} = useFetch<null | DashboardConfig>(null, { url: '/dashboard/' + code });
    const actions = useFetch<any>(null, { url: '/dashboard/' + code});
    const form = useForm(newWidget, { required: ['title', 'widgettype'] });
    const widgetLoading = useWithPromiseLoading();

    const [editedWidget, setEditedWidget] = useState<WidgetConfig | null>();

    const { formatMessage } = useIntl();

    useEffect(() => {
        if (actions.data) {
            setData(actions.data);
        }
    }, [actions.data, setData]);

    const save = () => {
        if (data) {
            return actions
                .request({ 
                    method: FetchTypes.PUT,
                    body: {
                        params_defaults: data.params.reduce((acc, param) => ({...acc, [param.code]: param.default}), {}),
                        title: data.title,
                        code: data.code,
                        query_substitutions: data.query_substitutions || "",
                        widgets_ids: data.widgets.map(w => w._id)
                    }
                });
        }

        return new Promise(() => {});
    }

    const removeWidgetLocal = useItemActionWithConfirmation<string, {}>(
      (widgetId: string) => apiFetch<WidgetConfig>(`/api/dashboard/${code}/widget/${widgetId}`, "delete")
        .then(x => { reload(); return {}; }),
        {
          skipConfirmation: true,
        }
    );

    const removeWidgetGlobal = useItemActionWithConfirmation<string, {}>(
      (widgetId: string) => apiFetch<WidgetConfig>(`${WidgetApiPath}/${widgetId}`, "delete")
        .then(x => { reload(); return {}; }),
        {
          title: formatMessage({ id: "dashboard.widget.remove_global" }),
          confirmationHint: formatMessage({ id: "dashboard.widget.remove_global_hint" }),
        }
    );

    return {
        loading: loading || actions.loading,
        widgetLoading: widgetLoading.isLoading || removeWidgetLocal.isRunning || removeWidgetGlobal.isRunning,
        form,
        data,
        setData,
        add: () => {
            // для сохранения текущего положения виджетов
            const { title, widgettype } = form.state;
            save().then(() => {
                actions
                    .request({ 
                        method: FetchTypes.POST,
                        url: `/dashboard/${data?.code}/widget`,
                        body: { title, widgettype }
                    });
                
                form.setState({ title: '', widgettype: null });
            });
        },
        addExisting: (widget: WidgetConfig) => {
            save().then(() => {
                actions
                    .request({ 
                        method: FetchTypes.POST,
                        url: `/dashboard/${data?.code}/widget/${widget._id}`,
                    });
            });
        },
        duplicateWidget: (widget: WidgetConfig) => {
            const duplicated: Partial<WidgetConfig> = { ...widget, _id: undefined, title: `${widget.title} (copy)` };
            save().then(() => {
                actions
                    .request({ 
                        method: FetchTypes.POST,
                        url: `/dashboard/${data?.code}/widget`,
                        body: duplicated,
                    });
            });
        },
        save,
        moveWidget: (targetWidget: WidgetConfig, to: number) => {
            if (data && to >= 0 && to <= data.widgets.length) {
                const index = data.widgets.findIndex(w => w._id === targetWidget._id) > to ? to : to - 1;

                const newlist: typeof data.widgets = [];

                for (const widget of data.widgets) {
                    if (newlist.length === index) {
                        newlist.push(targetWidget);
                    }

                    if (widget._id !== targetWidget._id) {
                        newlist.push(widget);
                    }
                }

                if (newlist.length === index) { 
                    newlist.push(targetWidget);
                }

                setData({...data, widgets: newlist});
            }
        },
        saveWidget: ({_id, ...data}: WidgetConfig) => {
            widgetLoading.withLoading(
                apiFetch<WidgetConfig>(`${WidgetApiPath}/${_id}`, "put", data)
                    .then((widget: WidgetConfig) => {
                        setEditedWidget(w => widget && w?._id === widget._id ? widget : w);
                        reload();
                    }));
        },
        removeWidgetGlobal: (id: string) => removeWidgetGlobal.run(id),
        removeWidgetLocal: (id: string) => removeWidgetLocal.run(id),

        selectWidgetForEditing: (widget: WidgetConfig | null) => {
            setEditedWidget(widget);
        },
        editedWidget,
    }
}