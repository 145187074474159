import React from 'react';
import styled from '@emotion/styled';
import { TimetrackingRate } from './types';
import { ItemEditorProps, ItemWrapper, ListEditor } from '../../toolympus/components/primitives/ListEditor';
import { generateCode } from '../../toolympus/components/PowerDoc/plugins/common';
import { FormGrid } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';


const RateSchema: Schema = {
  rate: { type: FieldType.number, label: "Ставка" },
  actor_role: { label: "Роль" },
}


export const TimetrackingRateEditor = (props: ItemEditorProps<TimetrackingRate>) => {
  return (
    <FormGrid columns="2fr 1fr" noMargin>
      <FormControlsForFields
        data={props.item}
        onChange={(o,c) => props.update(c)}
        fields={[
          ["actor_role"],
          ["rate"],
        ]}
        schema={RateSchema}
        />
    </FormGrid>
  )
}


interface Props {
  rates: TimetrackingRate[];
  update: (v: TimetrackingRate[]) => void;
}

const ItemWrapperX = styled(ItemWrapper)`
  max-height: 100px;
  padding-left: 6px;
  padding-right: 6px;
`;

export const TimetrackingRatesEditor = (props: Props) => {
  return (
    <ListEditor
      idField="_id"
      items={props.rates}
      update={props.update}
      createDflt={() => ({ _id: generateCode(), actor_role: "", rate: 0 })}
      itemEditor={TimetrackingRateEditor}
      itemWrapper={ItemWrapperX}
      />
  );
}
