import { Add, Close } from '@mui/icons-material';
import { Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import isHotkey from 'is-hotkey';
import React, { ReactNode, SetStateAction, useState } from 'react';
import { PseudoLink2, FormGrid, Buttons } from '../primitives';
import { QueryChunk, QueryChunkQuery } from './types';
import styled from '@emotion/styled';


const PortionTogglesWrapper = styled(Buttons)`
  margin-top: 0.5rem;
  font-size: 0.85rem;
`;


interface PortionToggleProps {
  isOn: boolean;
  update: (v: Partial<QueryChunk>) => void;
  updateField: string;
  onWith: any;
  label: ReactNode;
  updateToggles: (x: SetStateAction<Record<string,boolean>>) => void;
}

const PortionToggle = (props: PortionToggleProps) => {
  return (
    <PseudoLink2
      border
      style={props.isOn ? undefined : { color: "inherit" } }
      onClick={() => {
        props.updateToggles(x => ({ ...x, [props.updateField]: !props.isOn }))
        props.update({ [props.updateField]: props.isOn ? null : props.onWith });
        }}>
      {props.label}
    </PseudoLink2>
  );
}


interface Props {
  item: QueryChunkQuery;
  update: (v: Partial<QueryChunkQuery>) => void;
}

export const RemoveEndAdornment = (props: { remove: () => void }) => {
  return (
    <InputAdornment position="end">
      <IconButton size="small" onClick={() => props.remove()}>
        <Close />
      </IconButton>
    </InputAdornment>
  )
}


export const QueryChunkQueryEditor = (props: Props) => {
  const [toggles,setToggles] = useState<Record<string,boolean>>({});

  const { item, update } = props;
  
  return (
    <>
      <PortionTogglesWrapper className="toggles">
        <PortionToggle label="select" updateField="select" isOn={(!!item.select && !!item.select.length) || !!toggles.select} onWith={[""]} update={update} updateToggles={setToggles} />
        <PortionToggle label="from" updateField="from" isOn={!!item.from || !!toggles.from} onWith={""} update={update} updateToggles={setToggles} />
        <PortionToggle label="join" updateField="join" isOn={!!item.join || !!toggles.join} onWith={[{ table: "", on: "" }]} update={update} updateToggles={setToggles} />
        <PortionToggle label="where" updateField="where" isOn={(!!item.where && !!item.where.length) || !!toggles.where} onWith={[""]} update={update} updateToggles={setToggles} />
        <PortionToggle label="group by" updateField="group" isOn={(!!item.group && !!item.group.length) || !!toggles.group} onWith={[""]} update={update} updateToggles={setToggles} />
        <PortionToggle label="having" updateField="having" isOn={(!!item.having && !!item.having.length) || !!toggles.having} onWith={[""]} update={update} updateToggles={setToggles} />
        <PortionToggle label="order by" updateField="order" isOn={(!!item.order && !!item.order.length) || !!toggles.order} onWith={[""]} update={update} updateToggles={setToggles} />
        <PortionToggle label="tail" updateField="tail" isOn={(!!item.tail && !!item.tail.length) || !!toggles.tail} onWith={[""]} update={update} updateToggles={setToggles} />
      </PortionTogglesWrapper>


      {(!!item.select || toggles.select) &&
        <FormGrid columns="1fr">
          <Typography variant="caption" color="textSecondary">Select</Typography>
          {(item.select || []).map((l,i) => (
            <TextField
              value={l}
              autoFocus
              multiline
              onChange={e => update({ select: item.select?.map((x,j) => j === i ? e.target.value : x) })}
              onKeyPress={e => {
                if(isHotkey("mod+enter", e)) {
                  update({ select: [...(item.select || []), ""] })
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => update({ select: [...(item.select || []), ""] })}>
                      <Add />
                    </IconButton>
                    <IconButton size="small" onClick={() => {
                        const updated = (item.select || []).filter((x,j) => j !== i);
                        update(updated.length ? { select: updated } : { select: undefined })
                        if(!updated.length) {
                          setToggles(x => ({ ...x, select: false }));
                        }
                      }}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
          ))}
        </FormGrid>}

      {(!!item.from || toggles.from) &&
        <FormGrid columns="1fr">
          <Typography variant="caption" color="textSecondary">From</Typography>
          <TextField
            value={item.from || ""}
            onChange={e => update({ from: e.target.value })}
            />
        </FormGrid>}

      {(!!item.join || toggles.join) &&
        <FormGrid columns="1fr 1fr" forceEvenColumns>
          <Typography variant="caption" color="textSecondary" style={{ gridColumn: "1 / span 2" }}>Join</Typography>
          {(item.join || []).map((l,i) => (
            <>
              <TextField
                value={l.table || ""}
                autoFocus
                placeholder="table"
                onChange={e => update({ join: item.join?.map((x,j) => j === i ? { ...x, table: e.target.value } : x) })}
                onKeyPress={e => {
                  if(isHotkey("enter", e)) {
                    update({ join: [...(item.join || []), { table: "", on: "" }] })
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => update({ join: [...(item.join || []), { table: "", on: "" }] })}>
                        <Add />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                />

              <TextField
                value={l.type || ""}
                placeholder="inner"
                onChange={e => update({ join: item.join?.map((x,j) => j === i ? { ...x, type: e.target.value } : x) })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => update({ join: (item.join || []).filter((x,j) => j !== i) })}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                />

              <TextField
                value={l.on || ""}
                placeholder="on"
                style={{ gridColumn: "1 / span 2" }}
                multiline
                onChange={e => update({ join: item.join?.map((x,j) => j === i ? { ...x, on: e.target.value } : x) })}
                />
            </>
          ))}
        </FormGrid>}

      {(!!item.where || toggles.where) &&
        <FormGrid columns="1fr">
          <Typography variant="caption" color="textSecondary">Where</Typography>
          {(item.where || []).map((l,i) => (
            <TextField
              value={l}
              autoFocus
              multiline
              onChange={e => update({ where: item.where?.map((x,j) => j === i ? e.target.value : x) })}
              onKeyPress={e => {
                if(isHotkey("mod+enter", e)) {
                  update({ where: [...(item.where || []), ""] })
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => update({ where: [...(item.where || []), ""] })}>
                      <Add />
                    </IconButton>
                    <IconButton size="small" onClick={() => {
                        const updated = (item.where || []).filter((x,j) => j !== i);
                        update(updated.length ? { where: updated } : { where: undefined })
                        if(!updated.length) {
                          setToggles(x => ({ ...x, where: false }));
                        }
                      }}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
          ))}
        </FormGrid>}

      {(!!item.group || toggles.group) &&
        <FormGrid columns="1fr">
          <Typography variant="caption" color="textSecondary">Group by</Typography>
          {(item.group || []).map((l,i) => (
            <TextField
              value={l}
              autoFocus
              multiline
              onChange={e => update({ group: item.group?.map((x,j) => j === i ? e.target.value : x) })}
              onKeyPress={e => {
                if(isHotkey("mod+enter", e)) {
                  update({ group: [...(item.group || []), ""] })
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => update({ group: [...(item.group || []), ""] })}>
                      <Add />
                    </IconButton>
                    <IconButton size="small" onClick={() => {
                        const updated = (item.group || []).filter((x,j) => j !== i);
                        update(updated.length ? { group: updated } : { group: undefined })
                        if(!updated.length) {
                          setToggles(x => ({ ...x, group: false }));
                        }
                      }}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
          ))}
        </FormGrid>}

        {(!!item.having || toggles.having) &&
          <FormGrid columns="1fr">
            <Typography variant="caption" color="textSecondary">Having</Typography>
            {(item.having || []).map((l,i) => (
              <TextField
                value={l}
                autoFocus
                multiline
                onChange={e => update({ having: item.having?.map((x,j) => j === i ? e.target.value : x) })}
                onKeyPress={e => {
                  if(isHotkey("mod+enter", e)) {
                    update({ having: [...(item.having || []), ""] })
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => update({ having: [...(item.having || []), ""] })}>
                        <Add />
                      </IconButton>
                      <IconButton size="small" onClick={() => {
                          const updated = (item.having || []).filter((x,j) => j !== i);
                          update(updated.length ? { having: updated } : { having: undefined })
                          if(!updated.length) {
                            setToggles(x => ({ ...x, having: false }));
                          }
                        }}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                />
            ))}
          </FormGrid>}
      
      {(!!item.order || toggles.order) &&
        <FormGrid columns="1fr">
          <Typography variant="caption" color="textSecondary">Order by</Typography>
          {(item.order || []).map((l,i) => (
            <TextField
              value={l}
              autoFocus
              multiline
              onChange={e => update({ order: item.order?.map((x,j) => j === i ? e.target.value : x) })}
              onKeyPress={e => {
                if(isHotkey("mod+enter", e)) {
                  update({ order: [...(item.order || []), ""] })
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => update({ order: [...(item.order || []), ""] })}>
                      <Add />
                    </IconButton>
                    <IconButton size="small" onClick={() => {
                        const updated = (item.order || []).filter((x,j) => j !== i);
                        update(updated.length ? { order: updated } : { order: undefined })
                        if(!updated.length) {
                          setToggles(x => ({ ...x, order: false }));
                        }
                      }}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              />
          ))}
        </FormGrid>}
        
        {(!!item.tail || toggles.tail) &&
          <FormGrid columns="1fr">
            <Typography variant="caption" color="textSecondary">Tail</Typography>
            {(item.tail || []).map((l,i) => (
              <TextField
                value={l}
                autoFocus
                multiline
                onChange={e => update({ tail: item.tail?.map((x,j) => j === i ? e.target.value : x) })}
                onKeyPress={e => {
                  if(isHotkey("mod+enter", e)) {
                    update({ tail: [...(item.tail || []), ""] })
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => update({ tail: [...(item.tail || []), ""] })}>
                        <Add />
                      </IconButton>
                      <IconButton size="small" onClick={() => {
                          const updated = (item.tail || []).filter((x,j) => j !== i);
                          update(updated.length ? { tail: updated } : { tail: undefined })
                          if(!updated.length) {
                            setToggles(x => ({ ...x, tail: false }));
                          }
                        }}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                />
            ))}
          </FormGrid>}
    </>
  );
}
