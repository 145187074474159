import React from 'react';
import { useHistory } from 'react-router-dom';
import { useNewCase } from './caseData';
import NewCaseModal from './presentation/NewCaseModal';

interface Props {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
}

const NewCaseContainer = (props: Props) => {
    const { isActive, setIsActive } = props;

    const history = useHistory();

    const newCase = useNewCase(c => history.push(`/case/${c._id}`));

    return <NewCaseModal
                isActive={isActive}
                setIsActive={setIsActive}
                isSaving={newCase.isSaving}
                case={newCase.data}
                updateCase={newCase.update}
                saveCase={newCase.send} />;
};

export default NewCaseContainer;