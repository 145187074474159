import React, { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { ConfRoom } from './useConfRoomsConfiguration';
import { Buttons } from '../../../toolympus/components/primitives';
import { Chip } from '@mui/material';

const Wrapper = styled(Buttons)`
  flex-flow: row wrap;
`;

interface Props {
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  available: ConfRoom[];
}

export const ConfRoomsActivatorPicker = (props: Props) => {
  return (
    <Wrapper>
      {props.available.map(room => (
        <Chip
          size="small"
          label={room.title}
          variant={props.selected.includes(room._id) ? "filled" : "outlined"}
          color={props.selected.includes(room._id) ? "primary" : "default"}
          onDelete={props.selected.includes(room._id)
              ? () => props.setSelected(x => x.filter(id => id !== room._id))
              : undefined}
          onClick={e => {
            if(e.shiftKey) {
              props.setSelected(x => x.includes(room._id) ? x.filter(id => id !== room._id) : [...x, room._id]);
            } else {
              props.setSelected(x => x.includes(room._id) ? [] : [room._id]);
            }
          }}
          />
      ))}
    </Wrapper>
  );
}
