import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
import { Add, FileCopyOutlined, PlayArrow } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useItemWithControls } from '../../api/useWithControls';
import { useQuery } from '../../hooks/useQueryParamsState';
import { ActionRow, DeleteButton, FormGrid, LoadingIndicator } from '../primitives';
import { FormControlsForFields, MaybeFieldDefinition } from '../schemed';
import { FocusTriggerParam } from './Config';
import { TriggerCalendarSchema, TriggerPeriodSchema, TriggerSchema } from './schema';
import { Trigger } from './types';
import { ProgramTriggersData } from './useTriggers';

interface Props {
    data: ProgramTriggersData;
}

const TriggerWrapper = styled(FormGrid)<{ isActive?: boolean, isFocused?: boolean }>`
    align-items: baseline;
    padding: 0 1rem;
    border-left: 2px solid ${props => props.isActive ? props.theme.palette.primary.main : props.theme.palette.secondary.main};
    margin-bottom: 2rem;

    background: ${props => props.isFocused ? `${props.theme.palette.primary.main}20` : "transparent"};
    transition: background 0.5s ease;
`;

interface TriggerControlsProps extends Props {
    trigger: Trigger;
    update: (c: Partial<Trigger>) => void;
}


const ManualTriggerControls = (props: TriggerControlsProps) => {
    const { trigger, data } = props;

    return <ButtonsWrapper>
        <Button
            size="small"
            color="primary"
            disabled={data.isTriggerExecuting}
            onClick={() => data.executeTrigger(trigger)}
            startIcon={<PlayArrow />}
            style={{ justifySelf: "end" }}
            endIcon={data.isTriggerExecuting && <LoadingIndicator size="s" />}
            >
            <FormattedMessage id="robud.program.triggers.execute" />
        </Button>
        <IconButton size="small" onClick={() => data.copyTriggerToClipboard(trigger)}><FileCopyOutlined /></IconButton>
        <DeleteButton
            size="small"
            color="secondary"
            remove={() => data.removeTrigger(trigger)}
            title={<FormattedMessage id="robud.program.triggers.remove" />}
            />
  </ButtonsWrapper>
}


const ScheduleTriggerControls = (props: TriggerControlsProps) => {
    const { trigger, update, data } = props;

    const configuration = trigger.configuration || {};
    const calendar = configuration.calendar || {};
    const period = configuration.period || {};

    const { controls: periodControls } = useItemWithControls({
        data: period,
        update: c => update({ configuration: { ...configuration, period: { ...period, ...c }}}),
    }, { schema: TriggerPeriodSchema });

    const { controls: calendarControls } = useItemWithControls({
        data: calendar,
        update: c => update({ configuration: { ...configuration, calendar: { ...calendar, ...c }}}),
    }, { schema: TriggerCalendarSchema });

    return <>
        <ButtonsWrapper>
            <FormGrid gap="dense" noMargin columns="40px 40px 100px" style={{ justifySelf: "flex-end", alignItems: "baseline" }}>
                {periodControls([
                    ["qty"],
                    ["unit"],
                ])}
                {calendarControls([
                    ["at", { controlProps: { placeholder: "00:00" }}],
                ])}
            </FormGrid>
            
            <IconButton size="small" onClick={() => data.copyTriggerToClipboard(trigger)}><FileCopyOutlined /></IconButton>
            <DeleteButton
                size="small"
                color="secondary"
                remove={() => data.removeTrigger(trigger)}
                title={<FormattedMessage id="robud.program.triggers.remove" />}
                />
        </ButtonsWrapper>

        <FormGrid gap="dense" noMargin columns="repeat(4,1fr)" style={{ gridColumn: "1 / span 4", alignItems: "baseline" }}>
        {calendarControls([
                ["from", { utcToLocal: true }],
                ["to", { utcToLocal: true }],
                ["dow", { labelIdPrefix: "robud.program.triggers.labels.configuration.calendar.dow_labels" }],
                ["dom"],
            ])}
        </FormGrid>
    </>
}

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
`;

export const ProgramTriggers = (props: Props) => {
    const data = props.data;

    const focusTriggerQuery = useQuery().get(FocusTriggerParam);
    const [focusTriggerId, setFocusTriggerId] = useState<number | null>(null);

    useEffect(() => {
        if(focusTriggerQuery) {
            const timeout1 = setTimeout(() => setFocusTriggerId(+focusTriggerQuery), 200);
            const timeout2 = setTimeout(() => setFocusTriggerId(null), 800);
            const el = document.getElementById(`trigger-${focusTriggerQuery}`);
            if(el) {
                el.scrollIntoView();
            }
            return () => {
                clearTimeout(timeout1);
                clearTimeout(timeout2);
            };
        }
    }, [focusTriggerQuery]);

    const controls = (trigger: Trigger, fields: MaybeFieldDefinition[]) => (
        <FormControlsForFields
            fields={fields}
            data={trigger}
            onChange={(o,c) => data.updateTrigger(trigger.trigger_id, c)}
            schema={TriggerSchema}
            />
    )

    const { pasteTriggerFromClipboard } = data;

    useEffect(() => {
        const handler = (e: ClipboardEvent) => {
            pasteTriggerFromClipboard(e);
        }
        document.addEventListener("paste", handler);
        return () => document.removeEventListener("paste", handler);
    }, [pasteTriggerFromClipboard]);

    return (
        <>
            {data.triggers.map(t => (
                <TriggerWrapper
                    key={t.trigger_id}
                    id={`trigger-${t.trigger_id}`}
                    columns="50px 2fr 2fr 2fr"
                    gap="dense"
                    isActive={t.is_active}
                    isFocused={focusTriggerId === t.trigger_id}>
                    {controls(t, [
                        ["trigger_id", { readOnly: true }],
                        ["kind", { labelIdPrefix: "robud.program.triggers.kind" }],
                        ["is_active"],
                    ])}


                    {t.kind === "manual" &&
                        <ManualTriggerControls
                            trigger={t}
                            update={c => data.updateTrigger(t.trigger_id, c)}
                            data={data}
                            />}

                    {t.kind === "schedule" &&
                        <ScheduleTriggerControls
                            trigger={t}
                            update={c => data.updateTrigger(t.trigger_id, c)}
                            data={data}
                            />}
                </TriggerWrapper>
            ))}
            
            <ActionRow firstItemNoMargin itemMarginTop="0">
                <Button
                    size="small"
                    color="primary"
                    startIcon={<Add />}
                    disabled={data.isAdding}
                    endIcon={data.isAdding && <LoadingIndicator sizeVariant="s" />}
                    onClick={() => data.addTrigger()}>
                    <FormattedMessage id="robud.program.triggers.add" />
                </Button>

                {data.hasUnsavedChanges && <LoadingIndicator sizeVariant="s" />}
            </ActionRow>
        </>
    );
}
