import React from 'react';
import styled from '@emotion/styled';
import { Schema } from '../../toolympus/hooks/useSchema';
import { ChangeDescription, CorpDispute, Party, Representative } from './useCorpDisputesList';

const FieldChange = styled.div`
    padding: 0.5rem 0;
`;

const OldValue = styled.p`
    color: #cc0000;
    margin: 0.5rem 0 0 0.5rem;
`;
const NewValue = styled.p`
    color: #00aa00;
    margin: 0.5rem 0 0 0.5rem;
`;

interface Props {
    schema: Schema;
    data: ChangeDescription;
}

const SidesKeys: (keyof CorpDispute)[] = ["claimants", "respondants", "third_parties", "entity_representative"];
const RepresentativesKey = "representatives";
const SpecialKeys: (keyof CorpDispute)[] = [RepresentativesKey, ...SidesKeys];

export const HistoryChangesDisplay = ({ data, schema }: Props) => {
    const original = (data.original as any || {}) as Record<string, any>;
    const change = (data.change as any || {}) as Record<string, any>;

    const partyString = (p: Party) => `${p.title}${p.inn ? " " : ""}${p.inn || ""}`;
    const representativeString = (r: Representative) => `${r.title} ${r.email || ""} ${r.phone || ""}`;
    
    return (
        <>
            {Object.keys(change).filter(f => schema[f]?.label && !SpecialKeys.includes(f as any)).map(f => (
                <FieldChange key={f}>
                    {schema[f].label}

                    <OldValue>{original[f]}</OldValue>
                    <NewValue>{change[f]}</NewValue>
                </FieldChange>
            ))}
            {Object.keys(change).filter(f => SidesKeys.includes(f as any)).map(f => (
                <FieldChange key={f}>
                    {schema[f].label}

                    <OldValue>{(original[f] as Party[]).map(partyString).join(", ")}</OldValue>
                    <NewValue>{(change[f] as Party[]).map(partyString).join(", ")}</NewValue>
                </FieldChange>
            ))}
            {change[RepresentativesKey] && (
                <FieldChange key={RepresentativesKey}>
                    Представители

                    <OldValue>{(Object.values(original[RepresentativesKey]) as Representative[]).map(representativeString).join(", ")}</OldValue>
                    <NewValue>{(Object.values(change[RepresentativesKey]) as Representative[]).map(representativeString).join(", ")}</NewValue>
                </FieldChange>
            )}
        </>
    );
}
