import React from 'react';
import styled from '@emotion/styled/macro';
import { Form } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { TableForFields } from '../../toolympus/components/schemed';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { PresidiumDropMenu } from './Menu/PresidiumDropMenu';
import { EventResultDescription } from './Questions/PresidiumQuestionFeed';
import { usePresidiumEventFeed } from './usePresidiumEventFeed';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';

const Loader = styled.div`
    position: absolute;
    background: #ffffff80;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PresidiumEventFeedPage = () => {
    const { data, anchor, isLoading } = usePresidiumEventFeed();
    const { presidium_event: schema } = useSchema();
    
    return (<>
        <Form
            formBodyProps={{ style: { maxHeight: "calc(100vh - 70px)" }}}
            title={<><PresidiumDropMenu current="events" /> Президиум: события</>}
            headerItems={<>
                <OccupyFreeSpace />
                {isLoading && <Loader><LoadingIndicator sizeVariant="m" /></Loader>}
            </>}>
                <TableForFields
                    data={data}
                    schema={schema}
                    fields={[
                        ["question_title"],
                        ["event_type"],
                        ["time", { utcToLocal: true }],
                        ["source_display_name"],
                        ["result", { label: " "}],
                    ]}
                    fieldElement={f => {
                        if(f === "result") {
                            return r => <EventResultDescription e={r} />;
                        }
                    }}
                    fieldLink={f => {
                        if(f === "question_title") {
                            return r => `/presidium/questions/${r.question_id}`;
                        }
                    }}
                    tailAnchor={anchor}
                    />
        </Form>
    </>);
}
