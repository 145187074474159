import { useCallback, useState } from "react";
import { apiFetch } from "../../api/core";
import { useCrudItemF } from "../../api/useCrudItemF";
import { CrudItemData } from "../../api/useSimpleCrud";

export interface DocumentationDocBase {
    _id: string;
    title: string;
    slug?: string;
    parent_id?: string | null;
}

export interface DocumentationDoc extends DocumentationDocBase {
    content: any;
    breadcrumbs?: DocumentationDocBase[];
}

interface Config {
    defaultTitle?: string;
}

export interface DocumentationPageData extends CrudItemData<DocumentationDoc> {
    viewMode: boolean;
    setViewMode: (v: boolean) => void;
}

const saveDocument = (apiPath: string, item: DocumentationDoc, changes: Partial<DocumentationDoc>) => {
    if(Object.keys(changes).length === 0) {
        return Promise.resolve(item);
    }
    
    if(item._id) {
        return apiFetch<DocumentationDoc>(`${apiPath}/${item._id}`, "put", changes);
    } else {
        throw new Error("nothing to save");
    }
}

const removeDocument = (apiPath: string, item: DocumentationDoc) => {
    if(item._id) {
        return apiFetch<DocumentationDoc>(`${apiPath}/${item._id}`, "delete");
    } else {
        throw new Error("nothing to remove");
    }
}


export const useDocumentationRootPage = (apiPath: string, slug: string, cfg?: Config): DocumentationPageData => {
    const defaultTitle = cfg?.defaultTitle || "Documentation";

    const load = useCallback(() => {
        return apiFetch<DocumentationDoc>(`${apiPath}/slug/${slug}`, "get")
            .catch(e => {
                if(e?.response?.status === 404) {
                    return apiFetch<DocumentationDoc>(apiPath, "post", {
                        title: defaultTitle,
                        slug,
                    });
                } else {
                    throw e;
                }
            })
    }, [apiPath, slug, defaultTitle]);

    const data = useCrudItemF({
        defaultValue: { _id: "", title: "", slug, content: undefined },
        noLoad: !slug,
        load,
        saveChanges: (item, changes) => saveDocument(apiPath, item, changes),
        remove: (item) => removeDocument(apiPath, item),
    });


    const [viewMode, setViewMode] = useState<boolean>(true);

    return {
        ...data,
        
        viewMode,
        setViewMode,
    }
}


export const useDocumentationPage = (apiPath: string, id: string): DocumentationPageData => {
    const load = useCallback(() => {
        return apiFetch<DocumentationDoc>(`${apiPath}/${id}`, "get");
    }, [apiPath, id]);

    const data = useCrudItemF({
        defaultValue: { _id: "", title: "", content: undefined },
        noLoad: !id,
        load,
        saveChanges: (item, changes) => saveDocument(apiPath, item, changes),
        remove: (item) => removeDocument(apiPath, item),
    });


    const [viewMode, setViewMode] = useState<boolean>(true);

    return {
        ...data,
        
        viewMode,
        setViewMode,
    }
}
