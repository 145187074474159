import React from 'react';
import { Button, Card, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FieldSchema } from '../../hooks/useSchema';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FormControl } from '../schemed';
import { WidgetConfig, WidgetResult } from './types';
import { UseDashboardType } from './useDashboard';
import { WidgetUnbound } from './Widget';
import { Autorenew } from '@mui/icons-material';
import { WidgetDetailsPopup } from './WidgetDetailsPopup';
import { FormField, ParamsForm, WidgetsWrapper } from './Dashboard.styles';
import { FormGrid, useMediaBreakpoint } from '../primitives';

interface DashboardProps {
    data: ReturnType<UseDashboardType>,
    customSwitchWidget?: Record<string, (config: WidgetConfig, result: WidgetResult) => JSX.Element>,
    hideTitle?: boolean,
    hideRefresh?: boolean,
}

export const getWidgetColumnsWidth = (nColumns: number) => nColumns / 6 * 100 + '%';

export const Dashboard = (props: DashboardProps) => {
    const { data, customSwitchWidget, hideTitle, hideRefresh } = props;
    const { dashboard, loading, params, setParams, getWidgetUrl, widgetsParams, update, dashboardResult } = data;

    const isMobile = useMediaBreakpoint("down", "sm");

    if (!dashboard || loading) {
        return <LoadingIndicator />
    }

    return (<div>
        {!hideTitle && <Typography variant="h4">{dashboard.title}</Typography>}
        {(!hideRefresh || !!dashboard.params.length) &&
          <Card elevation={0}>
            <ParamsForm onSubmit={e => {e.preventDefault(); update();}}>
                <FormGrid noMargin style={{ width: "100%" }}>
                    {dashboard.params.map(p => 
                        <FormField key={p.code}>
                            <FormControl 
                                row={params}
                                schema={p as FieldSchema}
                                field={p.code}
                                onChange={(_, change) => setParams({...params, [p.code]: change[p.code]})}
                            />
                        </FormField>)
                    }
                </FormGrid>
                {!hideRefresh && <Button color="primary" variant="outlined" type="submit" endIcon={<Autorenew />}><FormattedMessage id="dashboard.update" /></Button>}
            </ParamsForm>
        </Card>}
        <WidgetsWrapper>
            {dashboard.widgets.map(d =>
                <WidgetUnbound
                    key={d._id}
                    url={getWidgetUrl(d._id, widgetsParams)}
                    openDetails={row => data.openLink(d, row) || data.details.open(d, (row || {})[d.label_field], widgetsParams)}
                    config={d}
                    customSwitchWidget={customSwitchWidget}
                    getColumnWidth={isMobile ? () => "100%" : getWidgetColumnsWidth}
                    data={{
                        loading: dashboardResult.isLoading,
                        data: dashboardResult.resultByWidget[d._id],
                        error: dashboardResult.resultByWidget[d._id]?.error,
                    }}
                    />)}
        </WidgetsWrapper>
        <WidgetDetailsPopup data={data.details} />
    </div>)
}