import { DragEventHandler, useState } from "react";

export interface DragHandler {
    isDragTarget: boolean;
    clearTarget: () => void;
    onDragEnter: DragEventHandler<any>;
    onDragLeave: DragEventHandler<any>;
    onDragOver: DragEventHandler<any>;
    onDrop?: DragEventHandler<any>;
}

interface Config {
    disabled: boolean;
    onDrop: DragEventHandler<any>;
}

export const useDragTarget = (cfg?: Partial<Config>): DragHandler => {
    const [dragCounter, setDragCounter] = useState<number>(0);

    const clearTarget = () => {
        setDragCounter(0);
    }

    return {
        isDragTarget: !cfg?.disabled && dragCounter > 0,
        onDragEnter: () => setDragCounter(x => x+1),
        onDragLeave: () => setDragCounter(x => Math.max(0, x-1)),
        onDragOver: e => { e.preventDefault(); },
        onDrop: cfg?.disabled ? undefined : (e => {
            clearTarget();
            if(cfg?.onDrop) {
                cfg?.onDrop(e);
            }
        }),

        clearTarget,
    }
}
