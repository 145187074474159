import { useState } from 'react';

export interface SortSetting {
    field: string;
    direction: 'asc' | 'desc';
}
export interface FieldSorting {
    sort: SortSetting | null;
    setSort: (s: SortSetting | null) => void;
    setNextSort: (field: string) => void;
    fieldSortAllowed: (field: string) => boolean;
    queryParameter: string;
}

export interface SortingConfig {
    defaultSort?: SortSetting;
    allowedFields?: string[];
    disallowedFields?: string[];
    paramName?: string;
}

const defaultParamName = 'order-by';
const useFieldSortingNew = (config?: SortingConfig): FieldSorting => {
    const [sort, setSort] = useState<SortSetting | null>(config?.defaultSort || null);

    const disallowedFields = config?.disallowedFields || [];

    const fieldSortAllowed = (field: string) => (!config?.allowedFields || !config?.allowedFields.length || config?.allowedFields.includes(field)) && !disallowedFields.includes(field);

    const setNextSort = (field: string) => {
        if(field === sort?.field) {
            if(sort.direction === 'asc') {
                setSort({ field, direction: 'desc' });
            } else {
                setSort(null);
            }
        } else {
            setSort({ field, direction: 'asc' })
        }
    }

    return {
        sort,
        setSort,
        setNextSort,
        fieldSortAllowed,
        queryParameter: sort ? `${config?.paramName || defaultParamName}=${sort.field}:${sort.direction}` : "",
    }
}

export const useFieldSorting = (configOrDefaultSort?: SortSetting | SortingConfig, allowedFields?: string[], paramName: string = defaultParamName): FieldSorting => {
    const config: SortingConfig = !(configOrDefaultSort === undefined || (configOrDefaultSort as SortSetting).direction) ?
        configOrDefaultSort as SortingConfig
        : {
            defaultSort: (configOrDefaultSort as SortSetting),
            allowedFields,
            paramName,
        };

    return useFieldSortingNew(config);
}    
