import React from 'react';
import styled from '@emotion/styled';
import { useItemWithControls } from '../../../api/useWithControls';
import { FieldType, mergeSchema } from '../../../hooks/useSchema';
import { FormControl } from '../../schemed';
import { CriteriaSchema } from './useScoringSheetEdit';
import { Buttons } from '../../primitives';
import { ItemEditorProps } from '../../primitives/ListEditor';
import { Criteria } from './typings';

const Wrapper = styled.div`
    padding: 0 1rem;
    gap: 0.5rem 1rem;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const CriteriaEdit = (props: ItemEditorProps<Criteria>) => {
  const { item, update } = props;
    
  const isCustomType = !["number", "text", "bool", "dropdown"].includes(item.type);
  const schema = mergeSchema(CriteriaSchema, isCustomType ? { type: { type: FieldType.text }} : {});

  const { controls } = useItemWithControls({ data: item, update: c => update(c) }, { schema });


  return (
      <Wrapper>
          {controls([
              ["name", { wrapperStyle: { gridColumnStart: "span 2"}}],
              ["type"],
              ])}

          {controls([
              ["comment", { autoRows: true, wrapperStyle: { gridColumnStart: "span 2"}}]
              ])}

          <Buttons>
            <FormControl
                row={{ isCustomType }}
                onChange={(o,c) => update(c.isCustomType ? { type: "custom" } : { type: "number" })}
                schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.custom" }}
                field="isCustomType"
                />
            {item.type === "text" && <FormControl
                row={item}
                onChange={(o,c) => update({ ...c })}
                schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.is_optional" }}
                field="is_optional"
                />}
          </Buttons>
          
          {item.type === "number" && <>
              {controls([
                  ["min_value"],
                  ["max_value"],
              ])}
              
              <Buttons>
                <FormControl
                    row={{ useChips: item.display_settings?.control !== "input" }}
                    onChange={(o,c) => update({ display_settings: { ...item.display_settings, control: c.useChips ? null : "input" }})}
                    schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.use_chips" }}
                    field="useChips"
                    />
                <FormControl
                    row={item.display_settings || {}}
                    onChange={(o,c) => update({ display_settings: { ...item.display_settings, ...c }})}
                    schema={{ type: FieldType.bool, label_id: "contests.scoring.fields.criteria.reverse_values_order" }}
                    field="reverse_values_order"
                    />
              </Buttons>
          </>}
      </Wrapper>
  );
}
