import { useEffect } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { QuestionEventBus } from "./usePresidiumQuestion";

export interface FeedEvent {
    question_id: string;
    question_title?: string;
    event_type: string;
    data?: Record<string, any>;
}

export interface Message {
    text: string;
    is_require_attention?: boolean;
    is_urgent?: boolean;
    urgent_members_ids?: string[];
}


export interface QuestionFeed extends LoadedData<FeedEvent[]> {
    sendMessage: (message: Message) => Promise<{}>;
}

interface Config {
    doLoad: boolean;
    eventBus: QuestionEventBus;
}


export const useQuestionFeed = (questionId: string, cfg: Config): QuestionFeed => {
    const apiPathBase = `/api/presidium/question/${questionId}`;
    const data = useLoadedData<FeedEvent[]>(`${apiPathBase}/event`, [], !!questionId && cfg.doLoad);

    useEffect(() => {
        if(cfg.eventBus) {
            const subid = cfg.eventBus.subscribe("votes-updated", () => data.reload());
            return () => cfg.eventBus && cfg.eventBus.unsubscribe(subid);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ...data,
        sendMessage: (m: Message) => {
            if(!m.text) {
                return Promise.resolve({});
            }
            return apiFetch<{}>(`${apiPathBase}/message`, "POST", m)
                .then(x => { data.reload(); return x; });
        }
    }
}
