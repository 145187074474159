import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, TextField } from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, AssignmentReturnOutlined, CallMade, DeleteOutlined, FileCopyOutlined } from '@mui/icons-material';
import { RenderElementProps, useSelected } from 'slate-react';
import { BlockSelectionCss, Buttons } from '../../elements/Common';
import { Tile as TileT, TilesEditData, useTiles, WithTiles } from './useTiles';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionRow, FormGrid, Tooltip } from '../../../primitives';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import isHotkey from 'is-hotkey';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { MediaLibPicker, useMediaLibContext } from '../../../medialib';
import { TilesBlockView } from './TilesBlockView';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';

export const TilesBlockElementType = "tiles_block";


const Wrapper = styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
`;

const Tile = styled(FormGrid)`
    padding: 0.5rem 0;

    &:nth-child(2n) {
        background: #cccccc18;
    }

    & > :first-child .placeholder {
        height: 150px;
    }
    & > :first-child:hover {
        background: #00000080;
    }

    & > :last-child > * {
        display: block;
        transform: scale(0.75);
    }
`;
Tile.defaultProps = { columns: "150px 1fr max-content", forceEvenColumns: true, noMargin: true };


const TileEditor = (props: { tile: TileT, tiles: TilesEditData }) => {
  const { tile, tiles } = props;
  const { formatMessage } = useIntl();
  const medialib = useMediaLibContext();
  const settings = usePowerEditorSettings();
  const EditorComponent = settings.EditorComponent || PowerEditorBase;

  return (
    <Tile key={tile.id}>
        <MediaLibPicker
            selectFile={mf => tiles.updateTile(tile.id, { image_url: mf ? medialib.getFilepath(mf) : null })}
            selectedUrl={tile.image_url}
            size="small"
            closeOnSelect
            />

        <FormGrid columns="1fr" onKeyDown={e => { if(isHotkey("mod+Enter", e)) { tiles.insertTile() }}} noMargin>
            <TextField
                key="title"
                value={tile.title || ""}
                onChange={e => tiles.updateTile(tile.id, { title: e.target.value })}
                label=""
                placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                autoFocus={tile.id === tiles.insertedId}
                />
            <FormGrid columns="2fr 1fr" noMargin gap="dense">
              <TextField
                  key="link"
                  value={tile.link || ""}
                  onChange={e => tiles.updateTile(tile.id, { link: e.target.value })}
                  label=""
                  placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.link"})}
                  InputProps={{
                      endAdornment: tile.link && <a href={tile.link} target="_blank" rel="noreferrer noopener"><IconButton size="small"><CallMade /></IconButton></a>,
                  }}
                  />

              <TextField
                  key="_class"
                  value={tile._class || ""}
                  onChange={e => tiles.updateTile(tile.id, { _class: e.target.value })}
                  label=""
                  placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile._class"})}
                  />

            </FormGrid>
            <EditorComponent
                content={tile.content}
                update={v => tiles.updateTile(tile.id, { content: v })}
                placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.content"})}
                />
        </FormGrid>

        <div>
            {tiles.tiles.length > 0 && <IconButton size="small" onClick={() => tiles.moveTileUp(tile.id)}><ArrowUpward /></IconButton>}
            <Tooltip text_id="common.copy">
              <IconButton size="small" onClick={() => tiles.copyTileToClipboard(tile)}><FileCopyOutlined /></IconButton>
            </Tooltip>
            <IconButton size="small" onClick={() => tiles.removeTile(tile.id)}><DeleteOutlined /></IconButton>
            {tiles.tiles.length > 0 && <IconButton size="small" onClick={() => tiles.moveTileDown(tile.id)}><ArrowDownward /></IconButton>}
        </div>
    </Tile>
  )
}

const TileEditorMemo = React.memo(TileEditor);


export const TilesBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const tiles = useTiles(element);

    const isSelected = useSelected();

    const { formatMessage } = useIntl();
    const { viewMode } = usePowerEditorContext();

    if(viewMode) {
        return <TilesBlockView {...(element as Partial<WithTiles>)} />;
    }

    return (
        <Wrapper {...attributes} className="tiles-block-element" isSelected={isSelected}>
            {children}

            <div style={{ width: "100%" }} contentEditable={false}>
              {!viewMode && <ActionRow lastItemMarginRight='0' itemMarginTop='0' firstItemNoMargin style={{ marginBottom: "0.25rem" }}>
                <TextField
                  value={tiles.title || ""}
                  onChange={e => tiles.update({ title: e.target.value })}
                  label=""
                  placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                  fullWidth
                  />

                <Button onClick={tiles.toggleColumns} style={{ textTransform: "none", flex: "1 0 auto" }}>
                    <FormattedMessage id="powerdoc.plugins.tiles.columns" values={{ columns: tiles.columns }} />
                </Button>
              </ActionRow>}

              {tiles.tiles.map(tile => (
                <TileEditorMemo key={tile.id} tile={tile} tiles={tiles} />
              ))}
            </div>

            <Buttons>
                <IconButton size="small" onClick={() => tiles.insertTile()}><Add /></IconButton>
                <Tooltip text_id="common.paste">
                  <IconButton size="small" onClick={() => tiles.pasteTileFromClipboard()}><AssignmentReturnOutlined /></IconButton>
                </Tooltip>
            </Buttons>
        </Wrapper>
    );
}
