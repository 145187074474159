import { PageWithComponents } from ".";
import { useLoadedData } from "../../hooks/useLoadedData";

export interface PageData {
    page: PageWithComponents;
    isLoading: boolean;
}

const defaultPage: PageWithComponents = {
    _id: "",
    slug: "",
    components: [],
    language: "",
    styles: "",
    properties: {},
}

export const usePage = (apiPath: string, slug: string) => {
    const { data: page, isLoading } = useLoadedData<PageWithComponents>(`${apiPath}/${slug}`, defaultPage);

    return {
        page,
        isLoading,
    }
}
