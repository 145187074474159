import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { PagingData } from '../../hooks/useLoadedData';
import { OccupyFreeSpace } from './ActionRow';

const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    & > :not(:first-child) {
        margin-left: 1em;
    }
`;

interface Props {
    data: PagingData;
    totalRecordsText?: string;
    hideIfOnePage?: true
}

export const PagingControl = ({ totalRecordsText, data, hideIfOnePage }: Props) => {
    const { page_number, total_pages, total_rows } = data.paging;
    let pagesToShow = [...Array(total_pages)].map((_, i) => i+1);
    if(total_pages > 10) {
        pagesToShow = [1].concat(
            (page_number - 2 > 1 ? [-1] : []),
            (page_number - 1 > 1 ? [ page_number-1 ] : []),
            (page_number > 1 ? [ page_number ] : []),
            (page_number + 1 <= total_pages ? [ page_number+1 ] : []),
            (page_number + 2 < total_pages ? [-1] : []),
            (page_number + 1 < total_pages ? [ total_pages ] : []),
            );
    }
    
    return (<Wrapper>
        <OccupyFreeSpace />
        {totalRecordsText && !!total_rows && <Typography>{totalRecordsText.replace('{0}', total_rows.toString())}</Typography>}
        {total_pages > 0 && (!hideIfOnePage || total_pages > 1) &&
            <ButtonGroup>
                <Button size="small" onClick={() => data.goPrevPage()}>{"<"}</Button>
                {pagesToShow.map(p => (
                    <Button size="small"
                        disabled={p === -1}
                        onClick={page_number === p ? () => null : () => data.goPage(p)}
                        style={page_number === p ? { textDecoration: 'underline', fontWeight: 'bold'} : {}}>
                        {p === -1 ? "..." : p}
                    </Button>))}
                <Button size="small" onClick={() => data.goNextPage()}>{">"}</Button>
            </ButtonGroup>}
        </Wrapper>);
}
