import { useState } from "react";
import { useHistory } from "react-router";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { CrudListData, useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { PagedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldSchema, FieldType } from "../../toolympus/hooks/useSchema";
import { useUserManagement } from "../../toolympus/hooks/useUserManagement";

export interface Employee {
    _id: string;
    firstname: string;
    lastname: string;
    middlename?: string;
    user_id?: string;
}

const EmployeeApiPath = '/api/employee';

export const useEmployeeList = (): CrudListData<Employee> => {
    const [filter, setFilter] = useState<string>("");
    const history = useHistory();

    const data = useLoadedData<PagedData<Employee>>(`${EmployeeApiPath}?page-size=100`, {
        start: 0, page_size: 0, total_rows: 0, page_number: 0, total_pages: 0, data: [] });

    const create = () => {
        return apiFetch<Employee>(EmployeeApiPath, FetchTypes.POST, { lastname: 'Новый сотрудник', firstname: 'Новый сотрудник' })
            .then(e => { history.push(`/system/employee/${e._id}`); return e; });
    }

    const filterEmployee = (e: Employee) => `${e.lastname} ${e.firstname} ${e.middlename}`.toLowerCase().includes(filter.toLowerCase());
    const filteredData = filter && filter.length > 0 ? data.data.data.filter(filterEmployee) : data.data.data;

    return {
        data: filteredData,
        setData: (x: any) => {},
        isLoading: data.isLoading,
        reload: data.reload,

        filter,
        setFilter,
        view: 'all',
        setView: (_) => null,

        create,
    }
}

export const useEmployee = (id: string) => {
    const data = useCrudItem<Employee>(`${EmployeeApiPath}/${id}`, {
        defaultValue: { _id: "", firstname: "", lastname: "" },
        returnPath: '/system/employee',
    });

    const createUser = (email: string) => {
        return apiFetch<Employee>(`${EmployeeApiPath}/${id}/user`, "post", { email })
            .then(() => data.reload());
    }

    return {
        ...data,
        createUser,
    }
}

interface UserSelectSchema {
    schema: FieldSchema;
    getUserEmail: (id: string) => string;
    reload: () => void;
}

export const useUserSelectSchema = (label?: string): UserSelectSchema => {
    const { users, reload } = useUserManagement('/api/user', '');
    const userData = users.map(user => ( { value: user._id, label: ((user as any).email as string) } ));
    const userDict = userData.reduce((r, { value, label }) => ({ ...r, [value]: label }), ({} as Record<string, string>));

    const schema: FieldSchema = {
        label: label || "Пользователь",
        type: FieldType.select,
        values: userData,
        valueDict: userDict,
    };

    return {
        schema,
        getUserEmail: (id) => userDict[id] || "",
        reload,
    }
}
