import { useMemo } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { WidgetResult } from "./types";
import { queryParams } from "./useDashboard"

interface GroupedWidgetResult extends WidgetResult {
    error?: { error_code?: string };
}

export const useDashboardResult = (apiPath: string, dashboardCode: string | null, params: Record<string, any>) => {
    const p = queryParams(params);

    const { data, isLoading, } = useLoadedData<GroupedWidgetResult[]>(`${apiPath}/${dashboardCode}/result${p}`, [], !!dashboardCode);

    const resultByWidget = useMemo(
        () => data.reduce<Record<string, GroupedWidgetResult>>((r,v) => { r[v._id] = v; return r;}, {}),
        [data]
    )

    return {
        isLoading,
        data,
        resultByWidget,
    }
}


export type DashboardResultData = ReturnType<typeof useDashboardResult>;
