import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, InputAdornment, TextField, Theme, Typography } from '@mui/material';
import { Add, Check, EditOutlined } from '@mui/icons-material';
import { CopyText } from '../primitives/CopyText';

const Item = styled(Typography)`
    border-bottom: 1px dotted ${props => props.theme.palette.text.primary};
`;

const ItemWrapper = styled.span`
    position: relative;

    & > .edit-button {
        display: none;
    }

    &:hover {
        & > .edit-button {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background: #ffffffd0;
            transform: scale(0.8);
        }
    }

`;

const ItemsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 0.5rem;
`;

const Wrapper = styled.div`
    & .label {
        color: ${props => (props.theme as Theme).palette.text.secondary};
    }
`;

interface Props {
    separator?: string;
    label?: ReactNode;
    value: string | undefined | null;
    onChange: (v: string) => void;
}

export const MultiTextEdit = (props: Props) => {
    const { value, onChange, label } = props;
    const separator = props.separator || ";";
    const items = (value || "").split(separator).filter(x => !!x);

    const updateValue = (v: string) => {
        let newV = (v || "").replace(`${separator}${separator}`, separator);

        if(newV && newV[0] === separator) {
            newV = newV.substring(1);
        }
        if(newV && newV[newV.length - 1] === separator) {
            newV = newV.substring(0, newV.length-1);
        }
        onChange(newV);
    }

    const [newItem, setNewItem] = useState<string | null>(null);
    const addNewIfAny = () => {
        if(newItem && newItem.trim().length) {
            updateValue((value || "") + ";" + newItem);
        }
    }

    const startAddNew = () => {
        addNewIfAny();
        setNewItem("");
    }

    const completeAddNew = () => {
        addNewIfAny();
        setNewItem(null);
    }

    
    const [editedItem, setEditedItem] = useState<{ original: string, updated: string } | null>(null);
    const startEdit = (v: string) => {
        setEditedItem({ original: v, updated: v });
    }

    const updateEdited = (v: string) => {
        if(editedItem) {
            setEditedItem({ ...editedItem, updated: v });
        }
    }

    const completeEdit = () => {
        if(editedItem) {
            updateValue((value || "").replace(editedItem.original, editedItem.updated));
            setEditedItem(null);
        }
    }

    return (
        <Wrapper>
            <Typography className="label" variant="caption">{label}</Typography>
            <ItemsContainer>
                {items.map((item,i) => (
                    <ItemWrapper key={i}>
                        {/*
                        // @ts-ignore */}
                        <CopyText wrapper={props => <Item component="span" {...props} />} value={item} />
                        <IconButton className="edit-button" size="small" onClick={() => startEdit(item)}><EditOutlined /></IconButton>
                    </ItemWrapper>))}

                {newItem != null && (
                    <TextField
                        key="new"
                        size="small"
                        value={newItem}
                        onChange={e => setNewItem(e.target.value)}
                        onKeyUp={e => {
                            if(e.key === "Enter") {
                                completeAddNew();
                            } else if(e.key === "Escape") {
                                setNewItem(null);
                            }
                        }}
                        autoFocus
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size="small" onClick={completeAddNew}><Check /></IconButton>
                                </InputAdornment>)
                        }}
                        />
                )}

                {editedItem != null && (
                    <TextField
                        key="update"
                        size="small"
                        value={editedItem.updated}
                        onChange={e => updateEdited(e.target.value)}
                        onKeyUp={e => {
                            if(e.key === "Enter") {
                                completeEdit();
                            } else if(e.key === "Escape") {
                                setEditedItem(null);
                            }
                        }}
                        autoFocus
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size="small" onClick={completeEdit}><Check /></IconButton>
                                </InputAdornment>)
                        }}
                        />
                )}

                <IconButton key="add" size="small" onClick={startAddNew}><Add /></IconButton>
            </ItemsContainer>
        </Wrapper>
    );
}
