import { FieldType } from '../../toolympus/hooks/useSchema';

export const ImportConfigSchema = {
    "updateExisting": { label: "Обновлять существующие", type: FieldType.bool, valueDict: {} },
    "updateChecked": { label: "Обновлять проверенные", type: FieldType.bool, valueDict: {} },
    "matchByEsacID": { label: "Обновлять по совпадению ЭСАЦ ID (вместо номера)", type: FieldType.bool, valueDict: {} },
    "failUnknownArbiters": { label: "Пропускать дела с неизвестными арбитрами", type: FieldType.bool, valueDict: {} },
    "dryRun": { label: "Пробный импорт (без сохранения)", type: FieldType.bool, valueDict: {} },
}

export const ImportResultSchema = {
    "created_datetime": { label: "Создан", type: FieldType.text, valueDict: {} },
    "created_by": { label: "Пользователь", type: FieldType.text, valueDict: {} },
    "filename": { label: "Имя файла", type: FieldType.text, valueDict: {} },
    "processed_n": { label: "Обработано", type: FieldType.text, valueDict: {} },
    "skipped_n": { label: "Пропущено", type: FieldType.text, valueDict: {} },
    "errors_n": { label: "С ошибками", type: FieldType.text, valueDict: {} },
}

export const ImportResultLogSchema = {
    "row-n": { label: "Всего записей", type: FieldType.number, valueDict: {} },
    "processed-n": { label: "Обработано", type: FieldType.number, valueDict: {} },
    "skipped-n": { label: "Пропущено", type: FieldType.number, valueDict: {} },
    "errors-n": { label: "Ошибки", type: FieldType.number, valueDict: {} },
}