import React, { PropsWithChildren, useMemo } from 'react';
import { PowerEditorBaseProps } from '../../PowerDoc';
import { DefaultUser } from '../../../userContext/UserContext';

export interface PluggableDocumentationSettings {
  canManage: (u: DefaultUser) => boolean | undefined | null;
  apiPath: string;
  editor?: React.ComponentType<PowerEditorBaseProps>;
}

const PluggableDocumentationContext = React.createContext<PluggableDocumentationSettings>({
  apiPath: "",
  canManage: () => false,
});

export const usePluggableDocumentationContext = () => React.useContext(PluggableDocumentationContext);

export const PluggableDocumentationProvider = (props: PropsWithChildren<PluggableDocumentationSettings>) => {
  const value = useMemo(() => {
    return {
      apiPath: props.apiPath,
      canManage: props.canManage,
      editor: props.editor,
    }
  }, [props.canManage, props.apiPath, props.editor]);
  return (
    <PluggableDocumentationContext.Provider value={value}>
      {props.children}
    </PluggableDocumentationContext.Provider>
  );
}
