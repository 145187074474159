import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router';
import { OccupyFreeSpace } from '../../primitives/ActionRow';
import { Form } from '../../primitives/Forms';
import { SearchField } from '../../primitives/SearchField';
import { FormControlsForFields, TableForFields } from '../../schemed';
import { ScoringSheetsListData, useScoringSheetsList } from './useScoringSheetsList';

interface Props {
    data: ScoringSheetsListData;
}

export const ScoringSheetsList = (props: Props) => {
    const {
        data,
        filter,
        setFilter,
        schema,
        newSheet,
    } = props.data;

    const { path } = useRouteMatch();

    return (
        <Form
            title={<FormattedMessage id="contests.scoring.sheets_list_title" />}
            headerItems={<>
                <IconButton onClick={() => newSheet.startEdit({ code: "" })}>
                    <Add />
                </IconButton>
                <OccupyFreeSpace/>
                <SearchField
                    filter={filter}
                    setFilter={setFilter}
                    doSearch={() => {}}
                    noButton
                    />
            </>}
            >
            <TableForFields
                data={data}
                fields={[
                    ["code"],
                    ["title"],
                ]}
                schema={schema}
                fieldLink={f => {
                    if(f === "code") {
                        return r => `${path}/${r.code}`;
                    }
                }}
            />

            <Dialog open={newSheet.isActive} onClose={newSheet.cancelEdit}>
                <DialogTitle><FormattedMessage id="contests.scoring.create_sheet" /></DialogTitle>
                <DialogContent>
                    {newSheet.data &&
                        <FormControlsForFields
                            data={newSheet.data}
                            fields={[["code"]]}
                            onChange={(o,c) => newSheet.update(c)}
                            schema={schema}
                        />}

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={newSheet.cancelEdit}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={newSheet.isLoading || !newSheet.canComplete}
                        onClick={newSheet.completeEdit}>
                        <FormattedMessage id="common.save" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Form>
    );
}

export const ScoringSheetsListContainer = ({ apiPath }: {apiPath: string}) => {
    const data = useScoringSheetsList(apiPath);
    return <ScoringSheetsList data={data} />
}
