import React from 'react';
import { CaseCreate } from '../../../typings/Cases';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material';
import {
    FormContainer,
} from '../../../toolympus/components/primitives/Modal.styles';
import { FormControlsForFields, PlaceholderField } from "../../../toolympus/components/schemed";
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { SectionTitle, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';

interface Props {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    isSaving: boolean;
    case: CaseCreate;
    updateCase: (c: CaseCreate) => void;
    saveCase: () => void;
};


const NewCaseModal = (props: Props) => {
    const case_ = props.case;
    const { updateCase, saveCase, isActive, setIsActive, isSaving } = props;

    const { case: schema, case_side: sideSchema } = useSchema();

    const SidesSchema = {
        claimant_title: sideSchema.title,
        claimant_inn: sideSchema.inn,
        respondant_title: sideSchema.title,
        respondant_inn: sideSchema.inn,
    };

    return (
        <Dialog open={isActive} onClose={() => setIsActive(false)} maxWidth="lg">
            <DialogTitle>Новое дело</DialogTitle>
            <DialogContent>
                <FormContainer id="newcaseform" onSubmit={e => { e.preventDefault(); saveCase(); }}>
                    <FormGrid columns="1fr 1fr">
                        <FormControlsForFields
                            fields={[
                                ["casenbr", { controlProps: { autoFocus: true }}],
                                [PlaceholderField],
                                ["case_type"],
                                ["rules"],
                            ]}
                            schema={schema}
                            data={case_}
                            onChange={(oldData, changes) => updateCase({ ...oldData, ...changes })} />
                    </FormGrid>

                    <FormGrid columns="1fr 1fr" style={{ width: "100%" }}>
                        <FormGrid columns="1fr" gap="dense">
                            <SectionTitle>Истец</SectionTitle>
                            <FormControlsForFields
                                fields={[
                                    ["claimant_inn"],
                                    ["claimant_title"],
                                ]}
                                schema={SidesSchema}
                                data={case_}
                                onChange={(oldData, changes) => updateCase({ ...oldData, ...changes })} />
                        </FormGrid>

                        <FormGrid columns="1fr" gap="dense">
                            <SectionTitle>Ответчик</SectionTitle>
                            <FormControlsForFields
                                fields={[
                                    ["respondant_inn"],
                                    ["respondant_title"],
                                ]}
                                schema={SidesSchema}
                                data={case_}
                                onChange={(oldData, changes) => updateCase({ ...oldData, ...changes })} />
                        </FormGrid>
                    </FormGrid>
                </FormContainer>
            </DialogContent>
            <DialogActions>
                <Button type='submit'
                    form="newcaseform"
                    disabled={isSaving}
                    color='primary'
                    variant='contained'
                    endIcon={isSaving && <LoadingIndicator sizeVariant="font" />}>
                    Создать
                </Button>
                <Button disabled={isSaving} color='secondary' variant='contained' onClick={() => setIsActive(false)}>Отмена</Button>
            </DialogActions>
        </Dialog>);
};

export default NewCaseModal;