import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import ReactMde from 'react-mde';
import Showdown, { ShowdownOptions } from "showdown";
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import 'react-mde/lib/styles/css/react-mde-all.css';

export const Container = styled.div`
    width: 100%;
`;
Container.defaultProps = {className: 'Container'};

const DefaultConverterOptions: ShowdownOptions =  {
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
    openLinksInNewWindow: true,
}

const converter = new Showdown.Converter(DefaultConverterOptions);

interface Props {
    value: string;
    setValue: (v: string) => void;
    label?: ReactNode;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const Markdown = ({ value, setValue, label, onKeyDown }: Props) => {
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

    return <Container onKeyDown={onKeyDown}>
        <Typography variant="caption" color="textSecondary">{label}</Typography>
        <ReactMde
            l18n={{
                write: <FormattedMessage id="formcontrol.markdown.write" />,
                preview: <FormattedMessage id="formcontrol.markdown.preview" />,
                uploadingImage: '',
                pasteDropSelect: ''
            }}
            value={value}
            onChange={setValue}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={async markdown => await converter.makeHtml(markdown)}
        />
    </Container>
}

interface DisplayProps {
    text: string;
    mdOptions?: ShowdownOptions;
}

export const MarkdownDisplay = ({ text, mdOptions }: DisplayProps) => {
    const cnv = mdOptions ? new Showdown.Converter(mdOptions) : converter;
    return <div dangerouslySetInnerHTML={{__html: cnv.makeHtml(text)}}></div>;
}