import { useEffect } from "react";
import { useUserSettings } from "../../Settings/UserSettingsContext";
import { useEditItem2 } from "../../../api/useNewItem";
import { generateCode } from "../../../api/data";
import { useItemActionWithConfirmation } from "../../../api/useAction";
import { useIntl } from "react-intl";

export interface UpdateNotification {
  _id: string;
  content?: any;
  dismiss_label?: string;
}

const UpdateNotificationsSettingsKey = "_tl_update_notifications";
const UpdateNotificationsDismissedSettingsKey = "_tl_update_notifications_dismissed";

const Empty: UpdateNotification[] = [];

export const useUpdateNotificationsEdit = () => {
  const settings = useUserSettings();
  useEffect(() => {
    settings.ensureLoaded();
  })

  const { formatMessage } = useIntl();

  const notifications = (settings.global || {})[UpdateNotificationsSettingsKey] as UpdateNotification[] || Empty;

  const newNotification = useEditItem2<UpdateNotification>({
    save: (item) => {
      settings.updateGlobal({ [UpdateNotificationsSettingsKey]: [...notifications, item ]});
      return Promise.resolve(item);
    }
  });

  const editNotification = useEditItem2<UpdateNotification>({
    save: (item, changes) => {
      settings.updateGlobal({ [UpdateNotificationsSettingsKey]: notifications.map(n => n._id === item._id ? { ...n, ...changes } : n)}); 
      return Promise.resolve(item);
    }
  });

  const removeNotification = useItemActionWithConfirmation<UpdateNotification, UpdateNotification>(
    item => {
      settings.updateGlobal({ [UpdateNotificationsSettingsKey]: notifications.filter(n => n._id !== item._id )});
      return Promise.resolve(item);
    }, {
      title: formatMessage({ id: "documentation.update_notifications.remove_confirmation" }),
      memoTrigger: notifications,
    }
  )

  return {
    notifications,
    newNotification: {
      ...newNotification,
      startEditing: () => {
        newNotification.startEditing({ _id: generateCode() });
      },
    },
    editNotification,
    removeNotification,
  }
}

export type UpdateNotificationsEditData = ReturnType<typeof useUpdateNotificationsEdit>;


export const useUpdateNotifications = () => {
  const settings = useUserSettings();
  useEffect(() => {
    settings.ensureLoaded();
  });

  const dismissed = (settings.user || {})[UpdateNotificationsDismissedSettingsKey] as string[] || [];

  const notifications = (settings.global || {})[UpdateNotificationsSettingsKey] as UpdateNotification[] || [];
  const unread = notifications.filter(n => !dismissed.includes(n._id));

  const hasUnreadNotifications = !!unread.length;

  const dismiss = (n: UpdateNotification) => {
    settings.updateUser({ [UpdateNotificationsDismissedSettingsKey]: [ ...dismissed.filter(id => !!notifications.find(nx => nx._id === id)), n._id ]})
  }

  return {
    notifications: unread.slice().reverse(),
    hasUnreadNotifications,
    dismiss,
  }
}