import React from 'react';
import { BoardStatementsPreparationData, BoardStatementsOptionsSchema } from './useBoardStatementsPreparation';
import { Dialog, LoadingIndicator } from '../../../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { PresidiumQuestionsTable } from '../PresidiumQuestionsTable';
import { Schema } from '../../../../toolympus/hooks/useSchema';
import { Checkbox } from '../../../../toolympus/components/schemed/Checkbox';

interface Props {
  data: BoardStatementsPreparationData;
  schema: Schema;
}

export const BoardStatementsPreparationPopup = (props: Props) => {
  const { data, schema } = props;
  return (
    <Dialog
      isOpen={data.state === "_2preparing" || data.state === "3_process" || data.state === "_4prepared"}
      close={() => data.cancel()}
      dialogTitle="Подготовка постановлений президиума"
      maxWidth="lg"
      fullWidth
      titleActions={<>
        <Checkbox
          field="is_attach_documents"
          row={data.options.data}
          onChange={(o,c) => data.options.update(c)}
          schema={BoardStatementsOptionsSchema.is_attach_documents}
          />
      </>}
      actions={<>
        {data.state === "_4prepared" && <Typography>Постановления подготовлены</Typography>}
        {data.state === "_2preparing" && <Button size="small" onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>}
        {data.state !== "_4prepared" && <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!data.selection.selectedItems.length}
          startIcon={data.state === "3_process" ? <LoadingIndicator sizeVariant="s" color="inherit" /> : undefined}
          onClick={() => data.prepare()}>
          подготовить постановления ({data.selection.selectedItems.length || "..."})
        </Button>}

        {data.state === "_4prepared" && <Button size="small" onClick={() => data.downloadPrepared()}>скачать</Button>}
        {data.state === "_4prepared" && <Button size="small" color="primary" onClick={() => data.cancel()}><FormattedMessage id="common.close" /></Button>}
      </>}>
      
      <PresidiumQuestionsTable
        questions={data.selection.selectedItems}
        schema={schema}
        fields={[
          ["title"],
          ["committee"],
          ["question_type"],
          ["casenbr"],
          ["vote_end_date"],
        ]}
        selection={data.state === "_2preparing" ? data.selection : undefined}
        getProcessingResult={data.getProcessingStatus}
        />
    </Dialog>
  );
}
