import { useEditItem2 } from "../../api/useNewItem";
import { generateCode } from "../PowerDoc/plugins/common";
import { QueryChunk } from "./types";
import { QueryChunksConfigurationData } from "./useQueryChunksConfiguration";

const EmptyChunks: QueryChunk[] = [];

export const useQueryChunksField = (data: QueryChunksConfigurationData) => {
  const config = data.data.data.chunks_schema;
  
  const newChunk = useEditItem2<QueryChunk>({
    save: (item, changes) => {
      data.data.update({
        chunks_schema: {
          ...config,
          chunks: [
            ...(config?.chunks || []),
            { ...item },
          ]
        }
      });
      return Promise.resolve(item);
    }
  });


  const updateChunk = (xid: string, changes: Partial<QueryChunk>) => {
    data.data.update({
      chunks_schema: {
        ...config,
        chunks: (config?.chunks || []).map(other => other._xid === xid ? { ...other, ...changes } : other),
      }
    })
  }

  const editChunk = useEditItem2<QueryChunk>({
    save: (item, changes) => {
      updateChunk(item._xid, changes);
      return Promise.resolve(item);
    }
  });

  const removeChunk = (xid: string) => {
    data.data.update({
      chunks_schema: {
        ...config,
        chunks: (config?.chunks || []).filter(other => other._xid !== xid),
      }
    })
  }

  const cloneChunk = (chunk: QueryChunk) => {
    const clone: QueryChunk = {
      ...chunk,
      _xid: generateCode(),
      _id: `${chunk._id}-copy`,
      title: chunk.title ? `${chunk.title} (copy)` : chunk.title,
      x: (chunk.x || 0) + 220,
    };
    const realClone = JSON.parse(JSON.stringify(clone));
    data.data.update({
      chunks_schema: {
        ...config,
        chunks: [...(config?.chunks || []), realClone],
      }
    })
  }
  
  return {
    chunks: config?.chunks || EmptyChunks,
    newChunk,
    editChunk,
    updateChunk,
    removeChunk,
    cloneChunk,
  }
}
