import React, { useState } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Add, Check } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Dialog, LoadingIndicator, SearchField } from '../../primitives';
import { TableForFields } from '../../schemed';
import { useCMSConfiguration } from '../Configuration/CMSConfigurationContext';
import { DefaultRecordSchema } from '../useRecordTypeConfig';
import { LinkedRecordsSelectionData } from './useLinkedRecordsSelectionData';
import { RecordChip } from './LinkedRecordsBlockElement';
import { CMSRecord } from '../types';

interface Props {
    data: LinkedRecordsSelectionData;
}

const SelectedWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
`;

const ToggleButton = styled(IconButton)<{ isSelected?: boolean }>`
    background: ${props => props.isSelected ? props.theme.palette.primary.main : props.theme.palette.action.selected};
    color: ${props => props.isSelected ? props.theme.palette.primary.contrastText : props.theme.palette.text.primary};
    width: 24px;
    min-width: 30px;

    &:hover {
        color: ${props => props.theme.palette.primary.main};
    }
`;
ToggleButton.defaultProps = { size: "small" };

export const LinkedRecordsSelectionDialog = ({ data }: Props) => {
    const { getRecordUrl } = useCMSConfiguration();

    const [draggingRecord, setDraggingRecord] = useState<CMSRecord | null>(null);

    return (
        <Dialog
            dialogTitle={<FormattedMessage id="cms.editor.insert_linked_records" />}
            isOpen={data.isOpen}
            close={() => data.finishSelection()}
            fullWidth
            maxWidth="md"
            >
                <SelectedWrapper>
                    {data.selectedRecords.map((r,rIdx) => (
                      <RecordChip
                        record={r}
                        isDraggingRecord={!!draggingRecord}
                        onDelete={() => r && data.toggleSelectRecord(r)}
                        onDragStart={() => {
                          if(r) {
                            setDraggingRecord(r);
                          }
                        }}
                        onDragEnd={() => {
                          if(draggingRecord) {
                            setDraggingRecord(null);
                          }
                        }}
                        onDrop={() => {
                          if(draggingRecord) {
                            data.moveSelectedRecordTo(draggingRecord, rIdx);
                            setDraggingRecord(null);
                          }
                        }}
                        />
                    ))}
                </SelectedWrapper>

                <SearchField
                    filter={data.filter.filter}
                    setFilter={data.filter.setFilter}
                    autoFocus
                    fullWidth
                    noButton
                    small
                    doSearch={() => {
                        if(data.records.length === 1) {
                            data.toggleSelectRecord(data.records[0]);
                            data.filter.setFilter("");
                        }
                    }}
                    />

                

                {data.isRecordsLoading && <LoadingIndicator />}

                {!data.isRecordsLoading &&
                    <TableForFields
                        data={data.records}
                        schema={DefaultRecordSchema}
                        dense
                        fields={[
                            ["select", { label: " " }],
                            ["title"],
                            ["slug"],
                            ["_id"],
                            ["created_datetime", { utcToLocal: true }],
                        ]}
                        fieldElement={f => {
                            if(f === "select") {
                                return r => {
                                    const isSelected = data.isRecordSelected(r);
                                    return (
                                    <ToggleButton
                                        isSelected={isSelected}
                                        onClick={() => data.toggleSelectRecord(r)}
                                    >
                                        {isSelected ? <Check /> : <Add />}
                                    </ToggleButton>
                                )};
                            }
                        }}
                        fieldLink={f => {
                            switch(f) {
                                case "title":
                                case "slug":
                                case "_id":
                                    return r => getRecordUrl(r) || "";
                            }
                        }}
                        autoexpander={{ increment: 20, initialRows: 20 }}
                        />}
        </Dialog>
    );
}
