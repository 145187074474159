import { useState } from "react";
import { apiFetch, FetchTypes } from "../../api/core";
import { useLoadedData } from "../../hooks/useLoadedData";
import { Action } from "./types";

export interface UseActions {
    data: Action[];
    loading: boolean;
    onAction: (body: any, code: string) => void;
    reload: () => void;
    selectedAction?: Action | null;
    startAction?: (actionCode: string) => void;
    cancelAction?: () => void;

}

export const useActions = (url: string, callback: () => void): UseActions => {
    const {data, isLoading: loading, reload} = useLoadedData<Action[]>(url, []);
    const [selectedAction, setSelectedAction] = useState<Action | null>(null);

    const startAction = (actionCode: string) => {
        const action = data.find(a => a.code === actionCode);
        if(action) {
            setSelectedAction(action);
        }
    }

    const cancelAction = () => {
        setSelectedAction(null);
    }

    return {
        data,
        loading,
        reload,
        onAction: (body: any, code: string) => {
            return apiFetch(`${url}/${code}`, FetchTypes.POST, body)
                .then(callback)
                .then(reload);
        },
        selectedAction,
        startAction,
        cancelAction,
    };
}