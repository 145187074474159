import React from 'react';
import { RenderLeafProps } from 'slate-react';
import styled from '@emotion/styled';


const Highlighted = styled.span`
    background: #f7ee0088;
    padding: 0.2em 0.15em;
`;
const Classed = styled.span`
    background: #00000020;
    padding: 0.2em 0.15em;
`;
Highlighted.defaultProps = { className: "highlight" };

export const Leaf = (props: RenderLeafProps) => {
    let { attributes, children, leaf } = props;
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }
    
    if (leaf.strikethrough) {
        children = <s>{children}</s>
    }

    if(leaf.highlight) {
        children = <Highlighted>{children}</Highlighted>
    }
    if(leaf._class) {
      children = <Classed className={leaf._class}>{children}</Classed>
    }

    return <span {...attributes}>{children}</span>;
};