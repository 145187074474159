import { useState, useCallback, useEffect, useRef } from "react";
import { apiFetch, FetchTypes } from "../api/core";

export const generateRequestId = () => Math.floor(1e8+Math.random()*1e8);

export interface LoadedData<DataType> {
    data: DataType;
    isLoading: boolean;
    reload: () => void;
    setData: (data: DataType | ((data: DataType) => DataType)) => void;
}
const justReturn = <T>(d: T) => d;

interface Config {
    requestParams?: any;
    // should return true if the error is handled and shouldn't be re-thrown
    onError?: (e: any) => boolean | undefined;
}

export const useLoadedData = <DataType>(apiPath: string, defaultValue: DataType, runAuto: boolean = true, process: (data: DataType) => DataType = justReturn, cfg?: Config): LoadedData<DataType> => {
    const [data, setData] = useState<DataType>(defaultValue);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const requestParams = cfg?.requestParams;

    const requestId = useRef<number>(0);
    
    const reload = useCallback(() => {
        const code = generateRequestId();
        requestId.current = code;
        setIsLoading(true);
        apiFetch<DataType>(apiPath, FetchTypes.GET, null, requestParams)
            .then(process)
            .then(data => {
                if(requestId.current === code) {
                    setData(data)
                }
            })
            .then(() => setIsLoading(false))
            .catch(e => {
              setIsLoading(false);
              if(cfg?.onError) {
                if(cfg.onError(e)) {
                  return;
                }
              }
              throw e;
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiPath, process, requestParams]);

    useEffect(() => {
        if(runAuto) {
            reload();
        }
    }, [reload, runAuto]);

    return {
        data,
        isLoading,
        reload,
        setData
    }
}

interface Paging {
    start: number;
    page_size: number;
    total_rows: number;
    page_number: number;
    total_pages: number;
}

export interface PagedData<T> extends Paging {
    data: T[];
}

export interface PagingData {
    paging: Paging;
    goPage: (n: number) => void;
    goPrevPage: () => void;
    goNextPage: () => void;
}

export type PagedLoadedData<RecordType> = PagingData & LoadedData<RecordType[]>;

const emptyPaging = { start: 0, page_size: 0, total_rows: 0, page_number: 0, total_pages: 0 };

export const usePagedLoadedData = <X>(apiPath: string, defaultValue: X[] = [], runAuto: boolean = true, process: (data: X[]) => X[] = justReturn): PagedLoadedData<X> => {
    const [data, setData] = useState<X[]>([]);
    const [paging, setPaging] = useState<Paging>(emptyPaging);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reload = useCallback(() => {
        setIsLoading(true);
        apiFetch<PagedData<X>>(`${apiPath}${apiPath.indexOf('?') > 0 ? '&' : '?'}start=${paging.start}`)
            .then(responseData => {
                setData(process(responseData.data));
                setPaging({ ...responseData });
                setIsLoading(false);
            })
            .catch(e => { setIsLoading(false); throw e; });
    }, [apiPath, paging.start, process]);

    useEffect(() => {
        if(runAuto) {
            reload();
        }
    }, [reload, runAuto]);

    const goPage = (n: number) => {
        const newStart = (n-1)*paging.page_size;
        const newStartLimited = newStart < 0 ? 0 : newStart > paging.total_rows ? (paging.total_pages-1)*paging.page_size : newStart;
        setPaging({ ...paging, start: newStartLimited});
    }

    return {
        data: data,
        paging: paging,
        goPage,
        goPrevPage: () => goPage(paging.page_number - 1),
        goNextPage: () => goPage(paging.page_number + 1),
        isLoading,
        reload,
        setData
    }
}



export const urlWithQuery = (url: string, query?: Record<string,any>) => {
  if(!query) {
    return url;
  }
  const presentParams = Object.entries(query).filter(([k,v]) => v !== null && v !== undefined && v !== "");
  if(!presentParams.length) {
    return url;
  }

  return `${url}?${presentParams.map(([k,v]) => `${k}=${v}`).join("&")}`;
}
