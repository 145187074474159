import React from 'react';
import { NewItemProps } from '../../api/useNewItem';
import { NewNumberingSequence, NumberingSequence } from './types';
import { FormGrid, SimpleDialog } from '../primitives';
import { FormattedMessage } from 'react-intl';
import { useItemWithControls } from '../../api/useWithControls';
import { Schema } from '../../hooks/useSchema';

interface Props {
  data: NewItemProps<NewNumberingSequence, NumberingSequence>;
  schema: Schema;
}

export const NewNumberingSequenceDialog = (props: Props) => {
  const { data, schema } = props;

  const { controls } = useItemWithControls({ data: data.item || {}, update: data.update }, { schema });

  return (
    <SimpleDialog
      close={data.cancel}
      isOpen={data.isEditing}
      save={() => data.save()}
      dialogTitle={<FormattedMessage id="numbering.create_title" /> }>
      <FormGrid noMargin columns="1fr">
        {controls([
          ["title"],
          ["format", { hint: <FormattedMessage id="numbering.format_hint" /> }],
        ])}
      </FormGrid>
    </SimpleDialog>
  );
}
