import React from 'react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

const Editor = styled(TextField)`
    & .MuiInputBase-root {
        color: ${props => props.theme.palette.text.primary};
        font-size: 2.125rem;
        font-weight: 400;
        line-height: 1.235;
        letter-spacing: 0.00735em;
        margin: 0;
    }
    
    & .MuiInputBase-root:before, & .MuiInput-underline:hover:before {
        border-bottom: none;
    }
`;

interface Props {
    value?: string;
    update: (v: string) => void;
    style?: React.CSSProperties;
    placeholder?: string;
    autoFocus?: boolean;
    readOnly?: boolean;
}

export const DocumentTitle = (props: Props) => {
    const { value, update, style, placeholder, autoFocus, readOnly } = props;
    const { formatMessage } = useIntl();
    return (
        <Editor
            value={value || ""}
            onChange={e => update(e.target.value)}
            placeholder={placeholder || formatMessage({ id: "powerdoc.pages.document_title_placeholder"})}
            InputProps={{ readOnly: readOnly }}
            autoFocus={autoFocus}
            style={style}
            />
    );
}
