import React from 'react';
import { PartiesPollConfigEdit } from './usePartiesPolls';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { PollConfigSchema } from './polls.types';

interface Props {
  data: PartiesPollConfigEdit;
}

export const PartiesPollsConfigEditForm = (props: Props) => {
  return (
    <>
      <FormControlsForFields
        data={props.data.data}
        onChange={(o,c) => props.data.update(c)}
        schema={PollConfigSchema}
        fields={[
          ["config"],
        ]}
        />
      
    </>
  );
}
