import React from 'react';
import styled from '@emotion/styled';
import { Chip } from "@mui/material";
import { Tag } from './useTagManagement';

interface Props {
    tag: Tag;
    wrap?: boolean;
    style?: React.CSSProperties;
}

export const TagChip = styled(Chip)<{ tagColor?: string} & { component?: React.ElementType}>`
  ${props => props.tagColor ? `background: ${props.tagColor};` : ""}
`;

export const ChipTextWrap = styled.div`
    overflow: hidden;
    height: auto;
    min-height: 1em;
    padding: 4px;
    & > .MuiChip-label {
        white-space: break-spaces;
        text-align: center;
    }
`;

export const TagDisplay = ({ tag, wrap, style }: Props) => {
    return <TagChip key={tag._id} label={tag.label} tagColor={tag.color} style={style} component={wrap ? ChipTextWrap : "div"} />;
}


const TagsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    cursor: ${props => props.onClick ? 'pointer' : undefined};

    & > * {
        cursor: ${props => props.onClick ? 'pointer' : undefined};
    }
`;

interface TagsProps {
    tags: Tag[];
    canEdit?: boolean;
    onClick?: () => void;
    remove?: (t: Tag) => void;
    wrap?: boolean;
}

export const TagsDisplay = (props: TagsProps) => {
    const { tags, onClick, remove } = props;
    return <TagsWrapper onClick={onClick}>
        {tags.map(t => (
            <TagChip
                key={t._id}
                label={t.label}
                tagColor={t.color}
                onDelete={remove ? () => remove(t) : undefined}
                component={props.wrap ? ChipTextWrap : "div"}
                />))}
    </TagsWrapper>
}
