import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ActionRow } from '../../../toolympus/components/primitives/ActionRow';
import { FormGrid, FormWrapper } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FormControl, TableForFields } from '../../../toolympus/components/schemed';
import { CaseCourtData } from '../caseData';
import { FormattedMessage } from 'react-intl';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { CaseCourt } from '../../../typings/Cases';
import { ClipboardButton } from '../../../toolympus/components/primitives/Buttons';

interface Props {
    data: CaseCourtData;
}

const PseudoLink = styled.div`
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
`;

const NoWrap = styled.div`
    white-space: nowrap;
`;

const ColorsByBatch: Record<number, string> = {
    2: "#00ff9512",
    3: "#0400ff12",
    4: "#ffee0012",
    5: "#ff660012",
};

export const CaseCourtInfo = (props: Props) => {
    const { isLoading, records, schema, create, edit } = props.data;
    return (
        <>
            <ActionRow>
                <IconButton onClick={create}><Add /></IconButton>
                {isLoading && <LoadingIndicator />}
            </ActionRow>
            <TableForFields
                data={records}
                schema={schema}
                fields={[
                    ["batch"],
                    ["court"],
                    ["result"],
                    ["approved"],
                    ["reason"],
                    ["date_start"],
                    ["date_end"],
                    ["court_case_nbr"],
                    ["court_case_link"],
                ]}
                fieldElement={f => {
                    switch(f) {
                        case "court":
                            return (row, _, original) => <PseudoLink onClick={() => edit(row)}>{original}</PseudoLink>;
                        case "court_case_nbr":
                        case "court_case_link":
                            return (row, _, original) => <NoWrap>{original}{row[f] && row[f].length && <ClipboardButton size="small" getValue={() => row[f]} />}</NoWrap>;
                        default:
                            return null;
                    }
                }}
                rowStyle={row => {
                    const color = ColorsByBatch[row.batch];
                    return color ? { background: color } : undefined;
                }}
                />
            <CaseCourtEditPopup {...props} />
        </>
    );
}

const CaseCourtEditPopup = (props: Props) => {
    const { isEditing, editRecord, updateEdit, errors, save, remove, isSaving, cancelEdit, schema, } = props.data;

    const control = (field: string, extraProps?: any) => (
        <FormControl
                key={field}
                field={field}
                schema={schema[field]}
                row={editRecord}
                extraProps={extraProps}
                onChange={(o,c) => updateEdit(c)}
                errors={errors}
                />
    );

    if(editRecord === null) {
        return null;
    }

    return (
        <Dialog open={isEditing} onClose={cancelEdit} fullWidth maxWidth="md">
            <FormWrapper elevation={0}>
                <FormGrid forceEvenColumns >
                    {control("court")}
                    {control("batch")}
                    {control((editRecord.court === "1-e" || editRecord.court === "1-x") ? "result" : "approved")}

                    {control("date_start")}
                    {control("date_end")}
                    {control("reason")}

                    {control("court_case_nbr")}
                    {control("court_case_link", { wrapperStyle: { gridColumnStart: 'span 2' }})}
                </FormGrid>
            </FormWrapper>
            <DialogActions>
                {isSaving && <LoadingIndicator />}
                {editRecord.line !== null && <DeleteButton
                    button={<Button color="secondary">удалить</Button>}
                    title="Удалить запись?"
                    preventGoBack
                    remove={() => remove(editRecord as CaseCourt)}
                    />}                    
                <Button onClick={cancelEdit}><FormattedMessage id="common.cancel"/></Button>
                <Button variant="contained" color="primary" onClick={() => save()}><FormattedMessage id="common.save"/></Button>
            </DialogActions>
        </Dialog>);
}
