import { useNewItem } from "../../api/useNewItem"
import { useCrudItem } from "../../api/useSimpleCrud";
import { useBrowserStoredValue } from "../../hooks/useBrowserStoredValue";
import { PagedData, useLoadedData } from "../../hooks/useLoadedData";
import { useSingleSchema } from "../../hooks/useSchema";
import { NewNumberingSequence, NumberingSequence, NumberingSequenceEvent } from "./types"


export const useNumberingSequencesEdit = (apiPath: string) => {
  const { schema } = useSingleSchema(`${apiPath}/uiconfig`);
  const newItem = useNewItem<NewNumberingSequence, NumberingSequence>(
    apiPath,
    { title: "" },
  );

  const allSequences = useLoadedData<NumberingSequence[]>(`${apiPath}?view=all`, []);

  const currentId = useBrowserStoredValue("", "sequence", "_tl_numbering_sequence");

  const item = useCrudItem<NumberingSequence>(`${apiPath}/${currentId.value}`, { 
    noLoad: !currentId.value,
    defaultValue: { } as NumberingSequence,
    resetChangesOnReload: true,
  });

  const events = useLoadedData<PagedData<NumberingSequenceEvent>>(
    `${apiPath}/${currentId.value}/event`,
    { data: [], page_number: 1, page_size: 50, start: 0, total_pages: 1, total_rows: 0 },
    !!currentId.value);

  return {
    newItem: {
      ...newItem,
      save: (extra?: Partial<NumberingSequence>) => newItem.save(extra)
        .then(x => { allSequences.reload(); currentId.update(x._id.toString()); return x; }),
    },

    schema,
    allSequences,
    currentId,
    item: {
      ...item,
      save: (extra?: Partial<NumberingSequence>) => item.save(extra)
        .then(x => { allSequences.reload(); events.reload(); return x; }),
    },
    events,
  }
}
