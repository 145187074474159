import React, { PropsWithChildren, ReactNode, useContext } from 'react';

interface CommentsConfiguration {
  commentAbsentIcon?: ReactNode;
  commentPresentIcon?: ReactNode;
}

const CommentsConfigurationContext = React.createContext<CommentsConfiguration>({});

export const useCommentsConfiguration = () => useContext(CommentsConfigurationContext);

export const CommentsConfigurationProvider = (props: PropsWithChildren<CommentsConfiguration>) => {
  const { children, ...config } = props;
  return (
    <CommentsConfigurationContext.Provider value={config}>
      {children}
    </CommentsConfigurationContext.Provider>
  );
}
