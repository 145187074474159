import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

export interface AppContentProps {
    children: ReactNode;
}

const MainView = styled.main`
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem;
`


export const AppContent: React.FC<AppContentProps> = (props: AppContentProps) => {
    return (
        <MainView>
            {props.children}
        </MainView>
    )
}