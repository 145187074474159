import { FieldType, Schema, createSelectSchema } from "../../hooks/useSchema"

export const WidgetSchema: Schema = {
  title: { label_id: "dashboard.widget.title" },
  subtitle: { label_id: "dashboard.widget.subtitle" },
  widgettype: createSelectSchema([
    { value: 'pie', label: 'pie' },
    { value: 'bar', label: 'bar' },
    { value: 'counter', label: 'counter' },
    { value: 'placeholder', label: 'placeholder' },
  ],
  { label_id: "dashboard.screen.widgettype" }),
}

export const WidgetParamsSchema: Schema = {
  label: { label_id: "dashboard.widget.param.label" },
  code: { label_id: "dashboard.widget.param.code" },
  fieldtype: createSelectSchema([
    { value: FieldType.text, label: FieldType.text },
    { value: FieldType.number, label: FieldType.number },
  ],
  { label_id: "dashboard.widget.param.fieldtype" }),
}

export const WidgetDetailResultFieldSchema: Schema = {
  name: { label_id: "dashboard.widget.name" },
  label: { label_id: "dashboard.widget.label" },
  link: { label_id: "dashboard.widget.link" },
}

export const WidgetDisplaySettingsSchema: Schema = {
  total_row: createSelectSchema([
    { value: "sum", label: "SUM"},
    { value: "avg", label: "AVG"},
    ],
    { label_id: "dashboard.widget.total_row" }),
  
  width: {
    type: FieldType.number,
    label_id: "dashboard.widget.width",
  },

}