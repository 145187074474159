import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { AlertColor } from '../../../toolympus/components/primitives/Alert';
import { Status } from '../../../toolympus/components/StatusAction';
import { PresidiumQuestion } from './usePresidiumQuestions';

interface Props {
    question: PresidiumQuestion;   
}

const SLink = styled(Link)`
    color: initial;
    text-decoration: none;
    & * {
        cursor: pointer;
    }
`;

export const QuestionStatus = ({ question }: Props) => {
    const status = question.status || "draft";

    const s = <Status
        status={status}
        labels={{
            draft: "Черновик",
            invote: "Голосование",
            "invote-draft": "Включен в голосование",
            closed: "Закрыт",
            "closed-down": "Снят",
        }}
        hints={{
            draft: { },
            invote: { color: AlertColor.warning },
            "invote-draft": { },
            closed: { color: AlertColor.success },
            "closed-down": { color: AlertColor.error },
        }}
        />

    return question.poll_id ?
        <SLink to={`/presidium/votes/${question.poll_id}`}>
            {s}
        </SLink>
        :
        s;
}
