import React from 'react';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { LoginFormFields } from '../../toolympus/components/auth';
import { useReturnUrl } from '../../toolympus/hooks/auth/useReturnUrl';
import { PublicPageHarness } from './PublicPageHarness';
import { Typography } from '@mui/material';


export const LoginForm = () => {
  const returnUrl = useReturnUrl();
  const login = useLoginForm('/api/login', returnUrl.fromParam || "/home");

  return (<PublicPageHarness>
    <form onSubmit={e => { e.preventDefault(); login.send(); }} className="panel">
        <Typography variant="h2">Авторизация</Typography>
        <LoginFormFields loginData={login} />
        
    </form>
  </PublicPageHarness>);
};
