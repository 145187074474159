import { RecordPowerDocsConfig, useRecordPowerDocsList } from "../../../toolympus/components/PowerDoc";
import { PowerDocApiPath } from "../../PowerEditor";
import { useSettings } from "../../SettingsPage";

const PowerDocCaseEntity = "case";


export const useCasePowerDocs = (caseId: string, cfg?: Partial<RecordPowerDocsConfig>) => {
    const { settings } = useSettings();
    return useRecordPowerDocsList(PowerDocApiPath, PowerDocCaseEntity, caseId, {
        ...(cfg || {}),
        dotxTemplateUrl: settings.document_generator_dotx_url || undefined,
    });
}

export type CasePowerDocsData = ReturnType<typeof useCasePowerDocs>;
