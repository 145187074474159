import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { DocumentationDocBase } from './useDocumentationPages';
import { useDocumentationPages } from './DocumentationPagesContext';
import { Breadcrumbs as BreadcrumbsMUI } from '@mui/material';

export const Breadcrumb = styled(Link)`
    color: inherit;
    text-decoration: none;
`;


interface Props {
    breadcrumbs?: DocumentationDocBase[];    
}

export const Breadcrumbs = (props: Props) => {
    const { config } = useDocumentationPages();
    
    if(!props.breadcrumbs?.length) {
        return null;
    }

    return (
        <BreadcrumbsMUI separator="\">
            {props.breadcrumbs.map(b => (
                <Breadcrumb to={b.slug === config.rootSlug ? config.pagePath : `${config.pagePath}/${b._id}`}>{b.title}</Breadcrumb>
            ))}
        </BreadcrumbsMUI>
    );
}
