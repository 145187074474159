import { Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { Tooltip } from '../../../toolympus/components/primitives';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { ButtonOptionPicker } from '../../../toolympus/components/primitives/ButtonOptionPicker';
import { PseudoLink } from '../../../toolympus/components/primitives/Common.styles';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { Form } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { TableForFields } from '../../../toolympus/components/schemed';
import { PresidiumDropMenu } from '../Menu/PresidiumDropMenu';
import { PresidiumMemberPopup } from './PresidiumMemberPopup';
import { usePresidiumMembers } from './usePresidiumMembers';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';

export const PresidiumPage = () => {
    const data = usePresidiumMembers();
    const tabs: [string, string][] = [
        ["Весь", "all"],
        ["Внутренний", "internal"],
        ["МКА", "international"],
        ["Исключенные", "excluded"],
    ];

    return (
        <Form
            title={<><PresidiumDropMenu current="members" /> Президиум</>}
            headerItems={<>
                {data.isLoading && <LoadingIndicator />}
                <OccupyFreeSpace />
                <ButtonOptionPicker
                    options={tabs}
                    selected={data.view}
                    setSelected={data.setView}
                    />
                <IconButton color="primary" size="small" onClick={() => data.newItem.startEditing()}><Add /></IconButton>
                <PluggableDocumentationButton documentationKey="board_members" />
            </>}
            >
            <TableForFields
                data={data.data}
                schema={data.schema}
                fields={[
                    ["lastname"],
                    ["firstname"],
                    ["middlename"],
                    ["role_full"],
                    ["role_internal"],
                    ["role_international"],
                    ["user_id"],
                    ["language"],
                    ["notifications_mode"],
                ]}
                fieldElement={field => {
                    if(field === "lastname" || field === "firstname" || field === "middlename") {
                        return (row, schema, original) => <PseudoLink onClick={() => data.editedItem.startEditing(row)}>{original}</PseudoLink>
                    }
                    return null;
                }}
                rowButtons={row => <Buttons>
                    {!row.is_excluded &&
                        <Tooltip text="Исключить вышедшего из состава члена президиума. Он потеряет доступ в систему и не будет включаться в новые вопросы">
                            <Button size="small" onClick={() => data.exclude(row)}>
                                исключить
                            </Button>
                        </Tooltip>}
                    {row.is_excluded &&
                        <Tooltip text="Вернуть члена президиума в состав">
                            <Button size="small" onClick={() => data.unExclude(row)}>
                                вернуть
                            </Button>
                        </Tooltip>}

                    <DeleteButton
                        preventGoBack
                        size="small"
                        title="Удалить члена президиума?"
                        hint="Удаляйте члена президиума только если он был создан по ошибке. Если он просто вышел из состава президиума, исключите его. После удаления лучше заблокировать пользователя."
                        remove={() => data.remove(row)}
                        />
                </Buttons>}
                />
            <PresidiumMemberPopup schema={data.schema} data={data.newItem} />
            <PresidiumMemberPopup schema={data.schema} data={data.editedItem} />
        </Form>
    );
}
