import React from 'react';
import styled from '@emotion/styled';
import { EditItemProps } from '../../api/useNewItem';
import { QueryChunk, QueryChunkParamConfig } from './types';
import { ItemEditorProps, ItemWrapperBasic, ListEditor, ListWrapper } from '../primitives/ListEditor';
import { generateCode } from '../PowerDoc/plugins/common';
import { FormGrid, InputIconButton } from '../primitives';
import { useItemWithControls } from '../../api/useWithControls';
import { Schema } from '../../hooks/useSchema';
import { DeleteOutlined } from '@mui/icons-material';

const ListWrapperX = styled(ListWrapper)`
  padding: 0.5rem 0;
`;

interface Props extends Pick<EditItemProps<QueryChunk>, "item" | "update"> {
  
}

const ParamConfigSchema: Schema = {
  code: { label_id: "dataschema.query_chunks.chunk_config.params_config.code" },
  default: { label_id: "dataschema.query_chunks.chunk_config.params_config.default" },
}

const ParamConfigEditor = (props: ItemEditorProps<QueryChunkParamConfig>) => {
  const { controls } = useItemWithControls({ data: props.item, update: props.update }, { schema: ParamConfigSchema })
  return (
    <FormGrid columns="1fr 1fr" forceEvenColumns>
      {controls([
        ["code", {
          controlProps: {
            endAdornment: <InputIconButton action={props.remove} icon={<DeleteOutlined />} />,
          }
        }],
        ["default"],
      ])}
    </FormGrid>
  )
}

export const QueryChunkParamsConfigEditor = (props: Props) => {
  const { item, update } = props;

  return (
    <ListEditor
      items={item?.params_config}
      update={v => update({ params_config: v })}
      createDflt={() => ({ xid: generateCode(), code: "", default: "" })}
      idField="xid"
      itemEditor={ParamConfigEditor}
      itemWrapper={ItemWrapperBasic}
      wrapperComponent={ListWrapperX}
      noItemMoveButtons
      noItemRemoveButton
      noAddButton
      />
  );
}
