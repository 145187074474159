import { useState } from 'react';
import { apiUploadFile, downloadFile, FetchTypes } from '../../toolympus/api/core';
import { useFetch } from '../../toolympus/api/useFetch';

export interface ImportResult {
    filename: string;
    log: any;
    mode: any;
    created_by: string;
    created_datetime: string;
    last_modified_by: string;
    last_modified_datetime: string;

    processed_n: number;
    skipped_n: number;
    errors_n: number;
}

export interface ImportData {
    data: ImportResult[];
    isLoading: boolean;
    reload: () => Promise<void>;
}

export const useCaseImportResults = (): ImportData => {
    const { data, loading, reload } = useFetch<ImportResult[]>([], { url: "/io/case/import" });

    return {
        isLoading: loading,
        data: data ? data.map(d => ({
            ...d,
            processed_n: d.log.rows["processed-n"],
            skipped_n: d.log.rows["skipped-n"],
            errors_n: d.log.rows["errors-n"],
        })) : [],
        reload,
    };
}

interface ImportConfig {
    updateExisting: boolean;
    updateChecked: boolean;
    matchByEsacID: boolean;
    dryRun: boolean;
    failUnknownArbiters: boolean;
};

export interface Importer {
    config: ImportConfig;
    updateConfig: (changes: Partial<ImportConfig>) => void;
    file: any;
    setFile: (f: any) => void;
    performImport: () => Promise<ImportResult | null>;
    isLoading: boolean;
    clear: () => void;
}

const defaultImportConfig: ImportConfig = {
    updateExisting: true,
    updateChecked: false,
    matchByEsacID: true,
    failUnknownArbiters: true,
    dryRun: true,
 };

export const useCaseImport = (): Importer => {
    const [config, setConfig] = useState<ImportConfig>(defaultImportConfig)
    const [file, setFile] = useState<any>(null);
    const [isLoading, setIsloading] = useState<boolean>(false);

    const clear = () => {
        setConfig(defaultImportConfig);
        setFile(null);
    }

    const performImport = () => {
        if(file) {
            setIsloading(true);
            const params = [
                ["run-mode", config.dryRun ? "dry" : "normal"],
                ["update-mode", config.updateExisting ? "update" : "skip"],
                ["update-checked-mode", config.updateChecked ? "update" : "skip"],
                ["match-mode", config.matchByEsacID ? "esac" : "nbr"],
                ["unknown-arbiter-mode", config.failUnknownArbiters ? "fail" : "add"],
            ];
            const url = `/api/io/case/import?${params.map(([k,v]) => `${k}=${v}`).join('&')}`;
            return apiUploadFile(url, FetchTypes.POST, "file", file)
                .then(({ data }) => {
                    setIsloading(false);
                    setFile(null);
                    return data as ImportResult 
                })
                .catch(e => { setIsloading(false); throw e});
        } else {
            return Promise.resolve(null);
        }
    }
    
    return {
        config,
        updateConfig: (changes: Partial<ImportConfig>) => setConfig(c => ({ ...c, ...changes })),
        file,
        setFile,
        performImport,
        isLoading,
        clear,
    }
}

export interface Exporter {
    performExport: () => void;
    isLoading: boolean;
}

export const useCaseExport = (): Exporter => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const performExport = () => {
        setIsLoading(true);
        downloadFile('/api/io/case/export', "stats.xlsx")
            .then(() => {
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            })
    };

    return {
        performExport,
        isLoading,
    }
}