import React from 'react';
import { useParams } from 'react-router-dom';
import ArbitratorForm from './presentation/ArbitratorForm';
import { useArbitrator } from './arbitratorsData';


const ArbitratorContainer = () => {
    const { id } = useParams<{ id: string }>();
    const arbitratorData = useArbitrator(id);

    
    return <ArbitratorForm data={arbitratorData} />
};

export default ArbitratorContainer;