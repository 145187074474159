import React from 'react';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';

export const DropzoneView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: ${props => props.theme.palette.primary.dark};
    outline: none;
    transition: border .24s ease-in-out;
    &:hover{
        cursor: pointer;
    }
`;


interface Props {
    file: any;
    onFileAdded: (file: any) => void;
}

export const FileUpload = (props: Props) => {
    const { file, onFileAdded } = props;

    const addFile = (files: any[]) => {
        if(files && files.length > 0) {
            onFileAdded(files[0]);
        }
    }

    return (
        file ?
            <DropzoneView>
                <p>{file.path}</p>
            </DropzoneView>
            :
            <Dropzone
                onDrop={addFile}
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <DropzoneView {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>{'Кликните для загрузки или перетащите документ в это поле'}</p>
                    </DropzoneView>
                )}
            </Dropzone>
    );
}
