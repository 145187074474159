import React from 'react';
import { PowerEditorBase } from '../PowerDoc';
import { ScriptPlugin } from '../PowerDoc/plugins/Script';
import { ScriptableBlockPlugin } from './ScriptableBlockPlugin';
import { CustomFieldsBlockPlugin } from '../PowerDoc/plugins/CustomFieldsBlock';
import { SettingsBlockPlugin } from '../PowerDoc/plugins/SettingsBlock';

export const getDefaultTemplateContent = (baseUrl: string) => {
  return {
    blocks: [{
      type: "custom_fields_block",
      fields: {
        x1: {label: "pt_image_url_prefix", value: new URL(baseUrl).origin }
      },
      children: [{text: ""}],
      fieldsCodes: ["x1"]},
      { type: "paragraph", children: [{text: "" }]},
    ]
  }
}

interface Props {
  content: any;
  update: (v: any) => void;
  disabled?: boolean;
}

export const NotificationTemplateEditor = (props: Props) => {
  return (
    <PowerEditorBase
      content={props.content}
      update={props.update}
      viewMode={props.disabled}
      plugins={[
        ScriptPlugin,
        ScriptableBlockPlugin,
        CustomFieldsBlockPlugin,
        SettingsBlockPlugin,
      ]}
      />
  );
}
