import React, { useEffect } from 'react';

interface Props {
  onRender?: () => void;
  onMount?: () => void;
}

export const Invoker = (props: Props) => {
  useEffect(() => {
    if(props.onMount) {
      props.onMount();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(props.onRender) {
    props.onRender();
  }

  return (
    <> 
    </>
  );
}
