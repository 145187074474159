import React, { ReactNode } from 'react';
import { Button, ButtonGroup } from '@mui/material';

interface Props {
    locale: string;
    setLocale: (l: string) => void;
    availableLocales: [string, ReactNode][];
}

export const LocaleSwitch = (props: Props) => {
    const { locale, availableLocales, setLocale } = props;
    return (
        <ButtonGroup variant="text" size="small">
            {availableLocales.map(([l, label]) => (
                <Button
                    key={l}
                    color={l === locale ? "primary" : "inherit"}
                    variant={l === locale ? "outlined" : "outlined"}
                    onClick={() => setLocale(l)}
                    >
                    {label}
                </Button>
            ))}
        </ButtonGroup>
    );
}
