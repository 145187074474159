import { useEffect, useState } from 'react';
import { apiFetch } from '../../api/core';
import { OpenDetailsFn, ResultField, WidgetConfig } from './types';
import { queryParams } from './useDashboard';

export const toTSV = (fields: ResultField[], items: Record<string, any>[]) => {
    if(items && items.length) {
        const header = fields.map(f => f.label).join('\t');
        const rows = items.map(i => fields.map(f => i[f.name]).join('\t'));
        return [header, ...rows].join('\n');
    } else {
        return "";
    }
};

export interface WidgetDetailsData {
    isOpen: boolean;
    isLoading: boolean;
    details: Record<string, any>[];
    fields: ResultField[];
    getResultTSV: () => string;
    widget: WidgetConfig | null;
    open: OpenDetailsFn;
    close: () => void;
    error?: string | null;
}

export const useWidgetDetails = (apiPrefix: string): WidgetDetailsData => {
    const [widget, setWidget] = useState<(WidgetConfig & { label: any, dashboardParams: Record<string, any> }) | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [details, setDetails] = useState<Record<string,any>[]>([]);
    const [error, setError] = useState<string | null>();

    const widgetId = widget?._id;
    const labelParam = widget?.label;
    const dashboardParams = widget?.dashboardParams;

    const fields: ResultField[] = widget?.details_fields && widget?.details_fields.length > 0 ?
        widget?.details_fields :
        Object.keys(details && details.length > 0 ? details[0] : {}).map(k => ({ name: k, label: k }));

    useEffect(() => {
        setError(null);
        setDetails([]);

        if(widgetId) {
            const params = labelParam || labelParam === 0 ? { ...dashboardParams, label: labelParam } : dashboardParams;
            setIsLoading(true);
            apiFetch<Record<string, any>[]>(`/api${apiPrefix}/widget/${widgetId}/details${queryParams(params)}`)
                .then(result => {
                    setIsLoading(false);
                    setDetails(result);
                })
                .catch(error => {
                    setIsLoading(false);
                    setError(error?.response?.data?.error_code || "dashboard.errors.unknown");
                })
        }
    }, [apiPrefix, widgetId, labelParam, dashboardParams]);

    return {
        isOpen: !!widget,
        isLoading,
        details,
        fields,
        getResultTSV: () => toTSV(fields, details),
        widget,
        open: (widget, label, dashboardParams) => { setWidget({ ...widget, label, dashboardParams }); },
        close: () => setWidget(null),
        error,
    }
}