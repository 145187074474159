import React, { useState } from 'react';
import { Case, StatusHints } from '../../../typings/Cases';
import { IconButton, InputAdornment, } from '@mui/material';
import { Add, CallMade, Close } from '@mui/icons-material';

import { FieldDefinition, FormControlsForFields, PlaceholderField, TableForFields } from "../../../toolympus/components/schemed";
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { Form, SectionTitle, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { CaseData, CaseArbitrators, SearchData } from '../caseData';
import { Arbitrator } from '../../../typings/Arbitrators';
import { SearchModal } from '../../../toolympus/components/SearchModal';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { SaveButton } from '../../../toolympus/components/primitives/Buttons';
import { Actions, ExtraAction, Status } from '../../../toolympus/components/StatusAction';
import { NewPracticeForm } from '../../Practice/NewPracticeForm';
import { NewPracticeData } from '../../Practice/practiceData';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { Comments } from '../../Comments';
import { CaseCourtInfo } from './CaseCourtInfo';
import { CaseActionsTable } from './CaseActionsTable';
import { Link, useHistory } from 'react-router-dom';
import { PresidiumQuestionsTable } from '../../Presidium/Questions';
import { ArbitratorSuggestionsTable } from '../../Presidium/Questions/ArbitratorSuggestionsTable';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { isAdminManager } from '../../UserManagement/roles';
import { useItemWithControls } from '../../../toolympus/api/useWithControls';
import { CaseSidesForm } from './CaseSidesForm';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { CaseDocuments } from './CaseDocuments';
import { useSaveable } from '../../../toolympus/components/primitives/useSaveable';
import { CaseMoneyForm } from './CaseMoneyForm';
import { useTrackHistory } from '../../../toolympus/components/HistoryFavorites';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { CasePartiesPollsList } from '../../PartiesPolls/CasePartiesPollsList';
import { CaseTimeRecordsTable } from '../../Timetracking/CaseTimeRecordsTable';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';


interface Props {
    case: CaseData;
    caseArbitrators: CaseArbitrators;
    arbitratorSearch: SearchData<Arbitrator>;
    newPracticeData: NewPracticeData;
};

export const PracticeLinksUnbound = ({ links }: { links: { _id: string }[] }) => {
  return <>
    {links.map(p => (
      <Link key={p._id} to={`/practice/${p._id}`}>
        <IconButton size="small"><CallMade /></IconButton>
      </Link>
    ))}
  </>
}

const PracticeLinks = ({ case_id }: { case_id: string }) => {
  const data = useLoadedData<{ _id: string }[]>(`/api/case/${case_id}/practice`, [], !!case_id);

  return (data.isLoading || !!data.data.length)
    ? <InputAdornment position="end">
        {data.isLoading
          ? <LoadingIndicator sizeVariant="s" /> 
          : <PracticeLinksUnbound links={data.data} /> }
      </InputAdornment>
    : null
}

const CaseForm = (props: Props) => {
    const { case: caseData, caseArbitrators, arbitratorSearch, newPracticeData } = props;
    const case_ = caseData.data;
    const { employees, sides } = caseData;
    const employeesData = useItemWithControls({ data: employees.employees, update: employees.update }, { schema: employees.schema });

    const { arbitratorSuggestions } = caseData;

    const {
        case: schema,
        arbitrator: arbitratorSchema,
        casearbitrator: caseArbitratorSchema,
    } = useSchema();

    const [isArbSearchActive, setIsArbSearchActive] = useState<boolean>(false);

    const history = useHistory();

    const { user } = useUser();
    const isAdminOrManager = isAdminManager(user?.roles || []);

    const onFieldChange = (oldData: Case, changes: Partial<Case>) => caseData.update(changes);
    const caseArbitratorsIds = new Set(caseArbitrators.data.map(a => a._id));

    const {historyData} = props.case;
    
    const tabsState = useTabsState([
        ["administration", "Администрирование"],
        ["money", "Бюджет"],
        ["timetracking", "Таймтрекинг", { hidden: !caseData.data.time_billed }],
        ["sides","Стороны"],
        ["arbitrators","Арбитры"],
        ["arbitrator-suggestions", "Кандидатуры арбитров"],
        ["presidium_questions", "Голосования"],
        ["process","Ход дела"],
        ["court","Государственный суд"],
        ["documents","Документы"],
        ["polls","Опросы"],
        ["actions", "История действий"],
        ["history","Аудит", { hidden: !isAdminManager }],
    ], "administration", "tab");

    const extraActions: ExtraAction[] = [
        {
          label: "Заготовка документа",
          action: () => props.case.documentStub.generate.run(),
          disabled: props.case.documentStub.generate.isRunning,
        },
        { label: "Создать запись практики", action: () => newPracticeData.setIsActive(true) },
        {
            label: "Создать голосование по формированию СА",
            action: () => history.push(`/presidium/questions?new_for_case=${case_._id}&new_question_type=arbitration_jury_formation`)
        },
    ];

    const fields = (fs: FieldDefinition[]) => (
        <FormControlsForFields
            fields={fs}
            schema={schema}
            data={case_}
            errors={caseData.errors}
            onChange={onFieldChange} />
    );

    useSaveable({ save: caseData.save, hasChanges: caseData.isUpdated });
    useTrackHistory(caseData.data.casenbr ? `Дело ${caseData.data.casenbr}` : undefined);

    return <Form
                title="Дело"
                headerItems={<>
                    {caseData.isUpdated && <SaveButton action={() => caseData.save()} />}
                    {caseData.isLoading && <LoadingIndicator sizeVariant="m" />}
                    <OccupyFreeSpace />
                    <Status
                        status={case_.status}
                        labels={schema["status"].valueDict}
                        hints={StatusHints}
                        chipStyles={{ marginRight: '1rem' }}
                        />
                    <Actions data={caseData.actions} extraActions={extraActions} />

                    <Buttons gap="small">
                      {case_._id && <Comments entity="case" recordId={case_._id} />}
                      <DeleteButton title="Удалить дело?" remove={caseData.remove} />
                      <PluggableDocumentationButton documentationKey="cases" />
                    </Buttons>
                </>}
                >
                <FormGrid forceEvenColumns>
                    {fields([
                        ["casenbr", { readOnly: true }],
                        ["case_type"],
                    ])}
                    <div style={{ display: "flex" }}>
                        {fields([
                            ["is_checked"],
                            ["is_hidden"],
                        ])}
                    </div>
                    {fields([
                        ["arbitration_procedure"],
                        ["rules"],
                        ["is_requested_to_court"],
                        ["claim_received_date"],
                        ["arbitration_start_date", { readOnly: true }],
                        ["arbitration_end_date", { readOnly: true }],
                    ])}
                </FormGrid>

                <TabsHeader state={tabsState} scrollable />

                
                <TabPanel state={tabsState.forTab("administration")}>
                  <FormGrid style={{ alignItems: "flex-end" }}>
                    {employeesData.controls([
                        ["assistant_id"],
                    ])}
                    {fields([ 
                      ["applicable_law"],
                      ["place_of_arbitration_city"],
                    ])}

                    {employeesData.controls([
                        ["other"],
                    ])}
                    {fields([ 
                      ["arbitration_language"],
                      ["place_of_arbitration_country", { autoComplete: true }],
                      
                      ["esac_id", { readOnly: true }],
                      ["case_area"],
                      [PlaceholderField],
                      ["practice_casenbr", {
                        label: "Номер в Практике",
                        readOnly: true,
                        controlProps: {
                          value: case_?.practice_casenbr || "без номера",
                          endAdornment: isAdminOrManager
                            ? <PracticeLinks case_id={caseData.data._id} />
                            : null,
                        }
                      }],
                      ["time_billed"],
                    ])}

                  </FormGrid>
                    
                </TabPanel>

                <TabPanel state={tabsState.forTab("money")}>
                    <FormGrid columns="1fr 1fr" noMargin style={{ columnGap: "3rem" }}>
                        <CaseMoneyForm data={caseData} />
                        {case_.had_reply_claim && <CaseMoneyForm data={caseData} sectionsSuffix=" (встречный иск)" fieldPrefix="counter_" />}
                    </FormGrid>
                </TabPanel>


                <TabPanel state={tabsState.forTab("timetracking")}>
                  <CaseTimeRecordsTable
                    case={caseData.data}
                    updateCase={caseData.update}
                    saveCase={caseData.save}
                    />
                </TabPanel>
                
                <TabPanel state={tabsState.forTab("sides")}>
                    <CaseSidesForm data={sides} />
                </TabPanel>


                <TabPanel state={tabsState.forTab("arbitrators")}>
                    <FormGrid columns="1fr 1fr 1fr max-content">
                        {fields([ 
                            ["arbitration_formed_date"],
                        ])}

                        <div key="1" />
                        <div key="2" />

                        {caseArbitrators.isLoading
                            ? <LoadingIndicator />
                            : <IconButton onClick={() => setIsArbSearchActive(true)}><Add /></IconButton>}
                    </FormGrid>
                
                    <TableForFields
                        fields={[
                            ["lastname"],
                            ["firstname"],
                            ["middlename"],
                            ["citizenship"],
                            ["assigned_by", { editable: true }],
                            ["fee_paid", { editable: true }],
                        ]}
                        schema={caseArbitratorSchema}
                        data={caseArbitrators.data}
                        fieldLink={
                            f => (f === "lastname" || f === "firstname" || f === "middlename" ?
                                ((arbitrator: Arbitrator) => `/arbitrator/${arbitrator._id}`) : null)}
                        rowButtons={arbitrator => (
                            <IconButton
                                onClick={e =>{ e.stopPropagation(); caseArbitrators.remove(arbitrator);}}>
                                <Close />
                            </IconButton>)}
                        emptyStateClick={() => setIsArbSearchActive(true)}
                        onChange={(a, changes) => caseArbitrators.update({ ...a, ...changes })}
                        />
                </TabPanel>

                <TabPanel state={tabsState.forTab("arbitrator-suggestions")}>
                    <ArbitratorSuggestionsTable data={arbitratorSuggestions} hideCaseFields />
                </TabPanel>


                <TabPanel state={tabsState.forTab("process")}>
                  <FormGrid>
                    <FormGrid noMargin columns="1fr">
                      <SectionTitle>Особенности движения дела</SectionTitle>
                      {fields([ 
                          ["no_movement", { disabled: true }],
                          ["claim_returned", { disabled: true }],
                          ["return_date", { disabled: true }],
                          ["had_pause", { readOnly: true }],
                          ["joined"],
                          ["had_joined_claim_requirements"],
                      ])}
                    </FormGrid>

                    <FormGrid noMargin columns="1fr">
                      <SectionTitle>Устные слушания</SectionTitle>
                      {fields([ 
                          ["had_hearing"],
                          ["nbr_oral_hearings", { visibleIf: (c: Case) => c.had_hearing}],
                          ["had_vcs"],
                      ])}
                    </FormGrid>

                    <FormGrid noMargin columns="1fr">
                      <SectionTitle>Особенности процесса</SectionTitle>
                      {fields([ 
                          ["had_competency_questioned"],
                          ["rejections"],
                          ["had_reply_claim"],
                          ["had_many_sides"],
                          ["had_third_parties", { disabled: true }],
                          ["had_expertize"],
                          ["had_witness"],
                      ])}
                    </FormGrid>
                  </FormGrid>

                  <FormGrid>
                    <FormGrid noMargin columns="1fr">
                      <SectionTitle>Обеспечительные меры</SectionTitle>
                      {fields([ 
                          ["had_collateral"],
                          ["had_timed_collateral"],
                      ])}
                    </FormGrid>

                    <FormGrid noMargin columns="1fr">
                      <SectionTitle>Арбитражный сбор и процессуальные расходы</SectionTitle>
                      {fields([ 
                          ["had_unestimable_claims"],
                          ["had_fee_return"],
                          ["return_reason", { visibleIf: (c: Case) => c.had_fee_return}],
                          ["extra_expense"],
                      ])}
                    </FormGrid>

                    <FormGrid noMargin columns="1fr">
                      <SectionTitle>Прекращение арбитража</SectionTitle>
                      {fields([ 
                          ["has_decision"],
                          ["has_agreed_terms"],
                          ["has_no_competency_statement"],
                          ["continue_impossible"],
                          ["had_claim_cancelled"],
                          ["had_fixing_typos"],
                          ["had_explanations"],
                          ["had_extra_decision"],
                      ])}
                    </FormGrid>
                  </FormGrid>

                </TabPanel>

                <TabPanel state={tabsState.forTab("presidium_questions")}>
                    <FormGrid columns="1fr">
                        {fields([
                            ["presidium_conflicts"],
                        ])}
                    </FormGrid>
                    <PresidiumQuestionsTable
                        questions={caseData.presidiumQuestionsData.data}
                        fields={[
                            ["question_type"],
                            ["title"],
                            ["committee"],
                            ["is_board_statement_prepared"],
                        ]}
                        />
                </TabPanel>

                <TabPanel state={tabsState.forTab("court")}>
                    <CaseCourtInfo data={caseData.courtData} />
                </TabPanel>

                <TabPanel state={tabsState.forTab("documents")}>
                    <CaseDocuments data={caseData.documents} />
                </TabPanel>

                <TabPanel state={tabsState.forTab("polls")}>
                    <CasePartiesPollsList caseId={caseData.data._id} />
                </TabPanel>

                <TabPanel state={tabsState.forTab("actions")}>
                    <CaseActionsTable data={caseData.actionsData} />
                </TabPanel>


                <TabPanel state={tabsState.forTab("history")}>
                  <FormGrid columns="repeat(4,1fr)">
                    {fields([ 
                      ["created_by", { readOnly: true }],
                      ["created_datetime", { readOnly: true, utcToLocal: true }],
                      ["last_modified_by", { readOnly: true }],
                      ["last_modified_datetime", { readOnly: true, utcToLocal: true }],
                    ])}
                  </FormGrid>
                    
                    {historyData.loading?
                        <LoadingIndicator />
                        :
                        <TableForFields 
                            schema={historyData.uiconfig}
                            fields={[
                                ['time', { utcToLocal: true }],
                                ['action'],
                                ['params'],
                                ['track'],
                            ]}
                            fieldElement={field => {
                                switch(field) {
                                    case "params":
                                        return row => <div>{Object.entries(row.params).map(([key,value]) => <div key={key}>{key}: {value}</div>)}</div>;
                                    case "track":
                                        return row => <div>{Object.entries(row.track || {}).map(([key,value]) => <div key={key}>{key}: {value}</div>)}</div>;
                                }
                            }}
                            data={historyData.data}
                        />
                    }
                </TabPanel>
                
                <div onSubmit={e => e.preventDefault()}>
                    <SearchModal
                        isActive={isArbSearchActive}
                        setIsActive={setIsArbSearchActive}
                        search={arbitratorSearch}
                        title="Добавить арбитра"
                        searchFieldLabel="Введите имя арбитра"
                        buttonAction={a => caseArbitrators.add(a)}
                        disableButton={a => caseArbitratorsIds.has(a._id)}
                        buttonLabel="Добавить"
                        resultsSchema={arbitratorSchema}
                        resultsFields={[
                            ["lastname"],
                            ["firstname"],
                            ["middlename"],
                            ["citizenship"],
                            ["dont_assign"],
                        ]}
                        />
                </div>

                <NewPracticeForm data={newPracticeData} />
            </Form>;
};

export default CaseForm;