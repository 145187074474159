import { useSimpleListWithNewItem } from "../../../toolympus/api/useSimpleListWithNewItem";

export interface Vote {
    _id: string;
    batch?: number;
    committee: string;
    start_date: string;
    end_date: string;
    confirming_start_date?: string;
    confirming_end_date?: string;
    status?: string;
}

const apiPath = "/api/presidium/vote";

export const usePresidiumVoteList = (onCreate?: (item: Vote) => void) => {
    return useSimpleListWithNewItem<Vote>(apiPath, {
        defaultView: 'inprogress',
        views: [
            ["Все", "all"],
            ["Черновики", "drafts"],
            ["В процессе", "inprogress"],
            ["Завершенные", "closed"],
        ],
        viewApiQueryParamKey: 'view',
        viewQueryParamKey: 'view',
        onCreate,
    });
}
