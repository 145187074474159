import { useState, useCallback, useEffect } from "react";
import { useValidationErrors } from "../components/schemed";
import { CrudItemData } from "./useSimpleCrud";

interface Config<T> {
    defaultValue: T;
    load: () => Promise<T>;
    saveChanges: (item: T, changes: Partial<T>) => Promise<T>;
    remove: (item: T) => Promise<void | any>;

    noLoad?: boolean;
}

export const useCrudItemF = <T>(config: Config<T>): CrudItemData<T> => {
    const { defaultValue, load, saveChanges } = config;

    const [data, setData] = useState<T>(defaultValue);
    const [changes, setChanges] = useState<Partial<T>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();

    const reload = useCallback(() => {
        setIsLoading(true);
        errors.clearErrors();
        return load()
            .then(item => {
                setData(item);
                setIsLoading(false);
                return item;
            })
            .catch(e => { setIsLoading(false); throw e; });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);

    const save = () => {
        setIsLoading(true);
        errors.clearErrors();
        return saveChanges(data, changes)
            .then(item => { setData(item); setIsLoading(false); setChanges({}); return item; })
            .catch(e => { setIsLoading(false); errors.handleErrors(e); throw e;});
    }

    const remove = () => {
        setIsLoading(true);
        return config.remove(data)
            .then(x => {
                setIsLoading(false);
                return x; })
            .catch(e => { setIsLoading(false); throw e; });
    }

    const update = (newChanges: Partial<T>) => {
        setChanges(old => ({ ...old, ...newChanges }));
        setData(old => ({ ...old, ...newChanges }));
    }

    const noLoad = config?.noLoad || false;
    
    useEffect(() => {
        if(!noLoad) {
            reload();
        }
    }, [reload, noLoad]);

    const hasChanges = changes && Object.keys(changes).length !== 0;

    return {
        data,
        changes,
        hasChanges,
        isLoading,

        reload,
        save,
        update,
        remove,
        setData,

        errors,
    }
}
