import { useState } from "react";

export interface Editable<T> {
    state: T | null;
    reset: (s?: T | null) => void;
    changes: Partial<T>;
    update: (changes: Partial<T>) => void;
    hasChanges: boolean;
}

export const useEditable = <T,>(defaultState: T | null): Editable<T> => {
    const [state, setStateX] = useState<T | null>(defaultState);
    const [changes, setChanges] = useState<Partial<T>>({});

    const reset = (s?: T | null) => {
        setStateX(s || null);
        setChanges({});
    }

    const update = (changes: Partial<T>) => {
        if(state) {
            setStateX({ ...state, ...changes });
            setChanges(c => ({ ...c, ...changes }));
        }
    }

    return {
        state,
        reset,
        changes,
        update,
        hasChanges: Object.keys(changes).length > 0,
    }
}
