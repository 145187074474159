import { useHistory } from "react-router-dom";
import { apiFetch } from "../../api/core";
import { LoadedData, useLoadedData } from "../../hooks/useLoadedData";
import { login, useUser } from "../../userContext/UserContext";

export interface Snapshot {
    name: string;
    created_datetime: string;
}

export interface SnapshotsConfig {
    apiPath: string;
    redirectToOnSwitch: string;
}

const DefaultConfig: SnapshotsConfig = {
    apiPath: "/api/snapshot",
    redirectToOnSwitch: "/",
}

export interface SnapshotsData extends LoadedData<Snapshot[]> {
    create: (name: string) => Promise<Snapshot>;
    switchToSnapshot: (name: string) => Promise<void>;

}

export const useSnapshots = (cfg?: Partial<SnapshotsConfig>): SnapshotsData => {
    const { apiPath, redirectToOnSwitch } = { ...DefaultConfig, ...(cfg || {}) };
    const data = useLoadedData<Snapshot[]>(apiPath, []);
    
    const history = useHistory();
    const userContext = useUser();

    const create = (name: string) => {
        return apiFetch<Snapshot>(apiPath, "post", { name })
            .then(s => { data.reload(); return s; });
    }

    const switchToSnapshot = (name: string) => {
        return apiFetch<string>(`${apiPath}/switch/${name}`, "post")
            .then(token => {
                login(userContext, token);
                history.replace(redirectToOnSwitch)
            });
    }

    return {
        ...data,
        create,
        switchToSnapshot,
    }
}