import { IconButton, InputAdornment } from '@mui/material';
import { CallMade, Refresh } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getApiToken } from '../../../toolympus/api/core';
import { useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { SimpleDocumentList } from '../../../toolympus/components/files';
import { useTrackHistory } from '../../../toolympus/components/HistoryFavorites';
import { Tooltip } from '../../../toolympus/components/primitives';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { SaveButton } from '../../../toolympus/components/primitives/Buttons';
import { Link } from '../../../toolympus/components/primitives/Common.styles';
import { ConfirmationDialogProvider } from '../../../toolympus/components/primitives/ConfirmationDialog';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { Form, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { useSaveable } from '../../../toolympus/components/primitives/useSaveable';
import { MaybeFieldDefinition, FormControlsForFields, NewTabLinkProps } from '../../../toolympus/components/schemed';
import { SimpleActions } from '../../../toolympus/components/StatusAction';
import { mergeSchema } from '../../../toolympus/hooks/useSchema';
import { CaseSelect } from '../../Cases/CaseSelect';
import { PresidiumDropMenu } from '../Menu/PresidiumDropMenu';
import { remainingTime } from '../Votes/VoteEnhancements';
import { ExtraCandidatesList } from './ExtraCandidatesList';
import { PresidiumQuestionFeed } from './PresidiumQuestionFeed';
import { PresidiumQuestionNoVoteResults } from './PresidiumQuestionNoVoteResults';
import { PresidiumQuestionOptionResults } from './PresidiumQuestionOptionResults';
import { PresidiumQuestionResponsesList } from './PresidiumQuestionResponsesList';
import { PresidiumQuestionResults } from './PresidiumQuestionResults';
import { QuestionArbitratorsSelection } from './QuestionArbitratorsSelection';
import { QuestionOptionsForm } from './QuestionOptionsForm';
import { QuestionSelectedArbitrators } from './QuestionSelectedArbitrators';
import { QuestionStatus } from './QuestionStatus';
import { usePresidiumQuestion } from './usePresidiumQuestion';
import { QuestionType_JuryFormation, QuestionType_NoVote } from './usePresidiumQuestions';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';

interface Props {
    
}

const PresidiumQuestionPageX = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = usePresidiumQuestion(id);
    const { 
        schema: questionSchema, 
        attributesSchema,
        data: item, 
        update, 
        remove, 
        errors, 
        hasChanges, 
        save,
        closeDown,
        canCloseDown,
        rollback,
        canRollback,
        reopen,
        canReopen,
        documents, 
        arbitratorSelection,
        isLoading,
        activate,
        regenerateTitle,
    } = data;

    const isJuryQuestion = item.question_type === QuestionType_JuryFormation;

    const schema = mergeSchema(questionSchema, { remaining: { label: "Осталось "}});

    const controls = (fields: MaybeFieldDefinition[], noArea?: boolean) => (
        <FormControlsForFields
            schema={schema}
            data={{ ...item, remaining: remainingTime(item.end_date)}}
            fields={fields}
            onChange={(o,c) => update(c)}
            errors={errors}
            readOnly={item.is_closed}
            withArea={!noArea}
            />
    );

    const attributesControls = (fields: MaybeFieldDefinition[]) => (
        <FormControlsForFields
            schema={attributesSchema}
            data={item.attributes}
            fields={fields}
            onChange={(o,c) => {
              const updated = { ...o, ...c };
              if(updated.jury_type !== "multiple") {
                delete updated.assigning;
              }
              update({ attributes: updated });
            }}
            errors={errors}
            readOnly={item.is_closed}
            />
    );

    const attributes: MaybeFieldDefinition[] = item.question_type === QuestionType_JuryFormation ?
        [
            ["jury_type"],
            item.attributes?.jury_type === "multiple" ? ["assigning"] : null,
        ]
        :
        [
        ];

    const tabsState = useTabsState([
        ["details", "Детали"],
        ["documents", "Документы"],
        ...(data.data.question_type === QuestionType_JuryFormation ?
            [
                ["arbiters-selection","Подбор кандидатов"],
                ["extra-candidates", "Предложения кандидатов"],
                ["arbiters","Кандидаты"],
            ] :
            []) as [string, string][],
        ["options", "Варианты", { hidden: item.is_candidates_question || item.question_type === QuestionType_NoVote }],
        ["responses", "Ответы", { hidden: item.question_type === QuestionType_NoVote }],
        ["result", "Результаты"],
        ["feed", "События"],
    ], "details", "tab", "__presidium_question_tab");

    const curTab = tabsState.current;
    useEffect(() => {
        activate(curTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curTab]);

    const closeDownAction = useActionWithConfirmation(closeDown, {
        canRun: canCloseDown,
        title: "Снять вопрос с голосования",
        confirmationLabel: "снять",
    });

    const rollbackQuestion = useActionWithConfirmation(rollback, {
        canRun: canRollback,
        title: "Перезапустить голосование",
        confirmationHint: "Результаты голосования будут сброшены (кроме сообщений о конфликтах), а текущие кандидаты исключены. Вы сможете добавить новых кандидатов и установить новые даты голосования.",
        confirmationLabel: "перезапустить",
    });

    const reopenQuestion = useActionWithConfirmation(reopen, {
        canRun: canReopen,
        title: "Отменить завершение",
        confirmationHint: "Вопрос будет переоткрыт. Если по завершении вопроса были назначены арбитры, они будут сняты с дела. Даты голосования необходимо изменить вручную.",
        confirmationLabel: "переоткрыть вопрос",
    });

    const boardAppLink = `https://board.modernarbitration.ru/employee/question/${data.data._id}?token=${getApiToken()}`;

    useSaveable(data);
    useTrackHistory(item.title);

    const voteLink = item.poll_id
        ? `/presidium/votes/${item.poll_id || ""}`
        : `/presidium/votes?view=drafts&create=true&committee=${item.committee}${item.start_date ? "&start_date=" : ""}${item.start_date || ""}${item.end_date ? "&end_date=" : ""}${item.end_date || ""}`

    return (
        <Form
            title={<><PresidiumDropMenu current="questions" /> Вопрос</>}
            headerItems={<>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator />}

                <Buttons gap="small">
                  <SimpleActions
                      actions={[
                          canCloseDown && { action: closeDownAction.run, label: "снять с голосования" },
                          canRollback && { action: rollbackQuestion.run, label: "перезапустить голосование" },
                          canReopen && { action: reopenQuestion.run, label: "отменить завершение" },
                          {
                              action: data.subscription.toggle,
                              label: data.subscription.isSubscribed ? "отписаться от E-mail уведомлений" : "подписаться на E-mail уведомления",
                          },
                      ]}
                      />
                  
                  <PluggableDocumentationButton documentationKey="board_questions" />
                  <Tooltip text="посмотреть на вопрос в приложении президиума">
                      <IconButton size="small" href={boardAppLink} target="_blank" rel="noreferrer noopener">
                          <CallMade />
                      </IconButton>
                  </Tooltip>
                  <QuestionStatus question={item} />
                  {hasChanges && <SaveButton action={() => save()} />}
                  {item.status === "draft" && <DeleteButton
                      title="Удалить вопрос?"
                      remove={remove}
                      size="small" />}
                </Buttons>
            </>}
            >
            <FormGrid
                suppressAreasOnSm
                alignItems="flex-end"
                columns="2fr 2fr 1fr 1fr"
                areas={[
                    "question_type committee batch case_id",
                    "title title is_board_statement_prepared is_board_statement_prepared",
                ]}>
                    {controls([
                      ["question_type", { readOnly: item.is_closed || item.is_launched }],
                      ["committee", { readOnly: item.is_closed || item.is_launched }],
                      ["batch", {
                        readOnly: true,
                        controlProps: {
                          InputProps: {
                            endAdornment: <InputAdornment position="end">
                              <Link to={voteLink} {...NewTabLinkProps}>
                                  <IconButton size="small"><CallMade /></IconButton>
                              </Link>
                            </InputAdornment>
                          }}}]
                        ])}

                    <CaseSelect
                      field="case_id"
                      schema={schema}
                      setCaseId={id => update({ case_id: id })}
                      case_id={item.case_id}
                      key="case_id"
                      disabled={item.is_closed || item.is_launched}
                      />

                    {controls([
                      ["title", { actions: () => !item.is_closed && <IconButton size="small" onClick={() => regenerateTitle()}><Refresh /></IconButton>}],
                      ["is_board_statement_prepared", { readOnly: false }],
                      ])}
            </FormGrid>

            <TabsHeader state={tabsState} scrollable />

            <TabPanel state={tabsState.forTab('details')}>
                <FormGrid key="attributes">
                  {attributesControls(attributes)}
                </FormGrid>

                <FormGrid key="dates">
                  {controls([
                      ["start_date"],
                      isJuryQuestion && ["suggestions_end_date"],
                      isJuryQuestion && ["vote_start_date"],

                      isJuryQuestion && ["vote_end_date"],
                      ["end_date"],
                      ["remaining", { disabled: true }],
                  ], true)}
                </FormGrid>

                <br/>

                {controls([
                    ["comments"],
                ])}
            </TabPanel>

            <TabPanel state={tabsState.forTab('documents')}>
                <SimpleDocumentList data={documents} disabled={item.is_closed} />
            </TabPanel>

            <TabPanel state={tabsState.forTab('arbiters-selection')}>
                <QuestionArbitratorsSelection data={arbitratorSelection} caseId={item.case_id} />
            </TabPanel>

            <TabPanel state={tabsState.forTab('arbiters')}>
                <QuestionSelectedArbitrators data={arbitratorSelection} />
            </TabPanel>

            <TabPanel state={tabsState.forTab('extra-candidates')}>
                <ExtraCandidatesList data={data.extraCandidates} />
            </TabPanel>

            <TabPanel state={tabsState.forTab('options')}>
                <QuestionOptionsForm data={data} />
            </TabPanel>

            <TabPanel state={tabsState.forTab('responses')}>
                <PresidiumQuestionResponsesList
                    data={data.responses}
                    isCandidatesQuestion={data.data.is_candidates_question}
                    options={data.data.options || []}
                    candidates={arbitratorSelection.selected}
                    />
            </TabPanel>

            <TabPanel state={tabsState.forTab('result')}>
                {data.data.question_type === QuestionType_NoVote
                    ? <PresidiumQuestionNoVoteResults data={data.result} />
                    : data.data.is_candidates_question
                        ? <PresidiumQuestionResults data={data.result} />
                        : <PresidiumQuestionOptionResults data={data.result} />}
            </TabPanel>

            <TabPanel state={tabsState.forTab('feed')}>
                <PresidiumQuestionFeed
                  data={data.feed}
                  responses={data.responses}
                  ensureResponsesLoaded={() => data.activate("responses")}
                  />
            </TabPanel>

        </Form>
    );
}

export const PresidiumQuestionPage = (props: Props) => {
    return (
        <ConfirmationDialogProvider>
            <PresidiumQuestionPageX />
        </ConfirmationDialogProvider>
    )
}