import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { FormGrid, OccupyFreeSpace, Tooltip } from '../../../primitives';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Add, DeleteOutlined, ExpandMore, UnfoldLess } from '@mui/icons-material';
import { Editor, Transforms } from 'slate';
import { CustomElement } from '../../../../../slate';
import { EditorPlugin } from '../../slate/PowerEditorConfig';
import { useIntl } from 'react-intl';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { BlockSelectionCss, Buttons } from '../../elements/Common';
import isHotkey from 'is-hotkey';
import { generateCode } from '../common';
import { moveArrayItemTo } from '../../../../hooks/useEditArray';
import { toMap } from '../../../../api/data';

export const CustomFieldsBlockElementType = "custom_fields_block";

interface Field {
    label: string;
    value: string;
}

interface WithFields {
    fieldsCodes: string[];
    fields: Record<string, Field>;
    is_collapsed?: boolean;
}

const Wrapper = styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}
`;

const FieldWrapper = styled(FormGrid)<{ isDragged?: boolean, isDragTarget?: boolean }>`
  opacity: ${props => props.isDragged ? 0.5 : 1};
  border-top: 3px solid ${props => props.isDragTarget ? props.theme.palette.primary.main : "transparent"};
`;
FieldWrapper.defaultProps = { columns: "1fr 1fr max-content", noMargin: true, forceEvenColumns: true };

export const extractCustomBlockFields = (block: RenderElementProps["element"]) => {
  const fields = Object.values((block as any).fields || {}) as { label: string; value: string }[];
  return fields.reduce<Record<string, string>>((r, { label, value }) => { r[label] = value; return r; }, {});
}


const useCustomFields = (element: Partial<WithFields> & CustomElement) => {
    const editor = useSlateStatic();
    const [insertedCode, setInsertedCode] = useState<string>("");
    
    // local state is needed to help react keep track of control state with the async updates by Slate/Transforms
    const [localState, setLocalState] = useState<WithFields>({
        fieldsCodes: element.fieldsCodes || [],
        fields: element.fields || {},
        is_collapsed: element.is_collapsed,
    });
    
    const codes = localState.fieldsCodes;
    const fields = localState.fields;

    const path = ReactEditor.findPath(editor, element);

    const updateState = (updated: WithFields) => {
        setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    const updateCollapsed = (value: boolean) => {
      updateState({ ...localState, is_collapsed: value });
    }

    const insertField = () => {
        const newCode = generateCode();
        setInsertedCode(newCode);
        updateState({
            fieldsCodes: [ ...codes, newCode],
            fields: { ...fields, [newCode]: { label: "", value: "" }},
        })
    };

    const updateField = (code: string, changes: Partial<Field>) => {
        updateState({
            fields: { ...fields, [code]: { ...(fields[code]), ...changes }},
            fieldsCodes: localState.fieldsCodes,
        });
    }

    const removeField = (code: string) => {
        const filteredCodes = codes.filter(c => c !== code);
        updateState({
            fieldsCodes: filteredCodes,
            fields: filteredCodes.reduce<Record<string, Field>>((r, c) => {
                if(c !== code) {
                    r[c] = fields[c];
                } 
                return r;
            }, {}),
        });
    }

    const moveField = (idxFrom: number, idxTo: number) => {
      updateState({
        fieldsCodes: moveArrayItemTo(codes, idxFrom, idxTo),
        fields: { ...fields },
      });
    }

    return {
        codes: localState.fieldsCodes,
        fields: localState.fields,
        insertField,
        updateField,
        removeField,
        moveField,
        insertedCode,
        updateState,

        isCollapsed: !!localState.is_collapsed,
        updateCollapsed,
    }
}

export const CustomFieldsBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    
    const {
        codes,
        fields,
        insertField,
        updateField,
        removeField,
        moveField,
        insertedCode,
        updateCollapsed,
        isCollapsed,
    } = useCustomFields(element as Partial<WithFields> & CustomElement);
    const isSelected = useSelected();

    const { formatMessage } = useIntl();
    const { viewMode } = usePowerEditorContext();

    const [dragging,setDragging] = useState<string | null>(null);
    const [dragTarget,setDragTarget] = useState<string | null>(null);

    const fieldsValues = isCollapsed ? toMap(Object.values(fields), f => f.label, f => f.value) : {};

    const blockTitle = fieldsValues.block_title || fieldsValues.title || fieldsValues.block_type;

    return (
        <Wrapper {...attributes} isSelected={isSelected}>
            {children}

            {isCollapsed && <>
              <Buttons style={{ paddingTop: 0 }}>
                {!!blockTitle &&
                  <Typography onClick={() => updateCollapsed(false)}>{blockTitle}</Typography>}
                <OccupyFreeSpace />

                <Tooltip text_id="common.show_details">
                  <IconButton size="small" onClick={() => updateCollapsed(false)}><ExpandMore /></IconButton>
                </Tooltip>
              </Buttons>
            </>}

            {!isCollapsed && <>
              <FormGrid columns="1fr" gap="dense" contentEditable={false}>
                {codes.map((f,idx) => (
                    <FieldWrapper
                      key={f}
                      draggable={!viewMode}
                      isDragged={f === dragging}
                      isDragTarget={f === dragTarget}
                      onDragStart={() => setDragging(f)}
                      onDragEnter={e => { e.preventDefault(); setDragTarget(f)}}
                      onDragOver={e => { e.preventDefault(); setDragTarget(f)}}
                      onDragLeave={e => { e.preventDefault(); setDragTarget(null)}}
                      onDrop={e => {
                        e.stopPropagation();
                        if(dragging && f !== dragging) {
                          const idxFrom = codes.indexOf(dragging);
                          if(idxFrom >= 0 && idxFrom !== idx) {
                            moveField(idxFrom, idx);
                          }
                        }
                        setDragging(null);
                        setDragTarget(null);
                      }}>
                        <TextField
                            key="label"
                            value={fields[f]?.label || ""}
                            onChange={e => updateField(f, { label: e.target.value })}
                            label=""
                            placeholder={formatMessage({ id: "powerdoc.plugins.custom_fields_block.field.label"})}
                            autoFocus={f === insertedCode}
                            InputProps={{ readOnly: viewMode }}
                            />
                        <TextField
                            key="value"
                            value={fields[f]?.value || ""}
                            onChange={e => updateField(f, { value: e.target.value })}
                            label=""
                            placeholder={formatMessage({ id: "powerdoc.plugins.custom_fields_block.field.value"})}
                            multiline
                            onKeyDown={e => {
                                if(isHotkey("mod+Enter", e)) {
                                    insertField();
                                    e.preventDefault();
                                }
                            }}
                            InputProps={{ readOnly: viewMode }}
                            />
                        <IconButton size="small" onClick={() => removeField(f)}><DeleteOutlined /></IconButton>
                    </FieldWrapper>
                ))}
              </FormGrid>
              <Buttons>
                <IconButton size="small" onClick={() => insertField()}><Add /></IconButton>
                <Button size="small" startIcon={<UnfoldLess />} onClick={() => updateCollapsed(true)}>{formatMessage({ id: "common.hide_details"})}</Button>
              </Buttons>
            </>}


        </Wrapper>
    );
}

const withCustomFieldsBlock = (editor: Editor) => {
    const { isVoid } = editor;
    editor.isVoid = (element: CustomElement) => {
        return element.type === CustomFieldsBlockElementType ? true : isVoid(element);
    }
    
    return editor;
}

export const CustomFieldsBlockPlugin: EditorPlugin = {
    key: "custom-fields-block",
    commands: [{
        name: "insert-custom-fields-block",
        menu: { section: "insert-item", label: "Custom fields", label_id: "powerdoc.plugins.custom_fields_block.title" },
        invoke: editor => {
            const firstFieldCode = generateCode();
            editor.insertNode({
                type: CustomFieldsBlockElementType,
                fieldsCodes: [firstFieldCode],
                fields: { [firstFieldCode]: { label: "", value: "" }},
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [CustomFieldsBlockElementType]: CustomFieldsBlockElement },
    inject: e => withCustomFieldsBlock(e),
};
