import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { FormGrid } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { SimpleActions } from '../../toolympus/components/StatusAction';
import { Schema } from '../../toolympus/hooks/useSchema';
import { EditCorpDisputeData, SideKey } from './useEditCorpDispute';


const PartySchema: Schema = {
    title: { label: "Имя или название" },
    inn: { label: "ИНН" },
}

const SideWrapper = styled.div`
    display: flex;
    flex-flow: column;
`;

const PartyWrapper = styled.div<{ isGroupingTarget?: boolean }>`
    width: 100%;
    padding: 2px;
    border-radius: 4px;
    border: 2px dotted transparent;

    &:hover {
        border: 2px dotted ${props => props.isGroupingTarget ? props.theme.palette.grey[300] : "transparent"};
    }
`;

const PartyGrid = styled(FormGrid)`
    align-items: baseline;
`;
PartyGrid.defaultProps = { gap: "dense", noMargin: true };

const AddingToGroupPanel = styled.div`
    display: flex;
    flex-flow: row;
    gap: 0.5rem;
    align-items: center;
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 300;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px #00000040;
    padding: 0.5rem 1rem;
`;


interface Props {
    data: EditCorpDisputeData;
}


interface SideProps extends Props {
    sideKey: SideKey;
    title: string;
}

const PartiesSection = ({ data, sideKey, title }: SideProps) => {
    const parties = data.parties.side(sideKey);

    const { startAddingToGroup, completeAddingToGroup, canAddToThisGroup, removeFromGroup } = data.parties.groups;

    return (
        <SideWrapper>
            <Typography key="title" variant="h6" style={{ gridColumn: "span 4"}}>{title}</Typography>

            {parties.map((party,idx) => {
                const isGroupMember = party.group_id !== null && party.group_id !== undefined;
                const groupId = isGroupMember ? party.group_id : party.id;
                const representative = groupId === undefined ? undefined : data.parties.representative(groupId as number);

                return (<PartyWrapper
                        key={idx}
                        isGroupingTarget={canAddToThisGroup(party)}
                        onClick={() => completeAddingToGroup(sideKey, party.id as number)}
                        >
                    <PartyGrid key="party" columns="12px 2fr 1fr max-content">

                        <Typography variant="caption">{idx+1}.</Typography>
                        <FormControlsForFields
                            data={party}
                            fields={[
                                ["title"],
                                ["inn"],
                            ]}
                            onChange={(o,c) => data.parties.update(sideKey, idx, c)}
                            schema={PartySchema}
                            />
                        <SimpleActions
                            actions={[
                                { label: "Удалить", action: () => data.parties.remove(sideKey, idx) },
                                !representative && party.id !== undefined && !isGroupMember && { label: "Добавить в группу", action: () => startAddingToGroup(sideKey, party) },
                                isGroupMember && { label: "Исключить из группы", action: () => removeFromGroup(sideKey, party.id as number) }
                            ]}
                            title={<MoreVert />}
                            buttonComponent={IconButton}
                            buttonProps={{ size: "small" }}
                            />
                    </PartyGrid>

                    {isGroupMember && (
                        <PartyGrid key="in-group-with" columns="12px 1fr">
                            <div key="empty-idx" />
                            <Typography variant="caption">В группe c {data.parties.findParty(party.group_id as number)?.title}</Typography>
                        </PartyGrid>
                    )}
                </PartyWrapper>)
            })}

            <Button color="primary" size="small" onClick={() => data.parties.add(sideKey)} style={{ alignSelf: "end"}}>добавить</Button>
        </SideWrapper>
    )
}


export const PartiesForm = ({ data }: Props) => {
    return (
        <FormGrid columns="1fr 1fr">
            <PartiesSection data={data} key="claimants" sideKey="claimants" title="Истцы" />
            <PartiesSection data={data} key="respondants" sideKey="respondants" title="Ответчики" />
            <PartiesSection data={data} key="entity_representative" sideKey="entity_representative" title="Представители ЮЛ, обратившиеся с иском" />
            <PartiesSection data={data} key="third_parties" sideKey="third_parties" title="Иные лица" />

            {data.parties.groups.addingPartyToGroup && (
                <AddingToGroupPanel>
                    <Typography>Добавляем {data.parties.groups.addingPartyToGroup.party.title} в группу</Typography>
                    <Button size="small" color="secondary" onClick={data.parties.groups.cancelAddingToGroup}>отмена</Button>
                </AddingToGroupPanel>
            )}
        </FormGrid>
    );
}
