import { Button } from '@mui/material';
import React from 'react';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { TableForFields } from '../../../toolympus/components/schemed';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { FeedEvent, QuestionFeed } from './useQuestionFeed';
import { downloadFile } from '../../../toolympus/api/core';
import { ActionRow, Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives';
import { PresidiumSendMessageButton } from './PresidiumSendMessageButton';
import { PresidiumQuestionResponses } from './usePresidiumResponses';

interface Props {
    data: QuestionFeed;
    responses: PresidiumQuestionResponses;
    ensureResponsesLoaded: () => void;
}

const votedCandidates = (e: FeedEvent) => {
    const candidates = e?.data?.candidates;
    return (e?.data?.response?.vote || []).map((cid: string) => {
        const c = candidates[cid];
        if(c) {
            return `${c.lastname} ${c.firstname} ${c.middlename}`;
        } else {
            return cid;
        }
    }).join(", ");
}

export const getEventDescription = (e: FeedEvent): string => {
    switch(e.event_type) {
        case "message":
        case "admin-message":
            return e?.data?.text;
        case "suggest_candidate":
            return `${e?.data?.fullname} (${e?.data?.email}, ${e?.data?.phone}). ${e?.data?.comment && "Комментарий: "} ${e?.data?.comment}`;
        case "suggest_option":
            return `${e?.data?.option?.label} ${e?.data?.comment && "Комментарий: "} ${e?.data?.comment}`;
        case "vote":
            return e?.data?.option ? e.data.option.label : votedCandidates(e);
        case "question-closed":
            return e?.data?.selected_option ? `Выбран вариант: ${e?.data?.selected_option.label}` : "";
        default:
            return "";
    }
}

export const EventResultDescription = ({ e }: { e: FeedEvent}) => {
  const action = <EventAction e={e} />
  return action
    ? <Buttons><span style={{ whiteSpace: "pre-line" }}>{getEventDescription(e)}</span> {action}</Buttons>
    : <><span style={{ whiteSpace: "pre-line" }}>{getEventDescription(e)}</span></>
}

export const EventAction = ({ e }: { e: FeedEvent}) => {
  if(e.data?.attachment_file_id) {
    return <Button size="small" color="primary" onClick={() => downloadFile(`/api/presidium-app/member-document/${e.data?.attachment_file_id}`, e.data?.text)}>скачать</Button>
  }

  return null;
}


export const PresidiumQuestionFeed = (props: Props) => {
    const { data, isLoading } = props.data;
    const { presidium_event: schema } = useSchema();

    return (
        <>
          <ActionRow lastItemMarginRight='0'>
            <OccupyFreeSpace />
            {isLoading && <LoadingIndicator />}
            <PresidiumSendMessageButton {...props} />
          </ActionRow>

          <TableForFields
              data={data}
              schema={schema}
              fields={[
                  ["event_type"],
                  ["time", { utcToLocal: true }],
                  ["source_display_name"],
                  ["result", { label: " "}],
              ]}
              fieldElement={f => {
                  if(f === "result") {
                      return r => <EventResultDescription e={r} />;
                  }
              }}
              />
        </>
    );
}
