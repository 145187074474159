import { useState } from "react";
import { LoadedData, useLoadedData } from "../hooks/useLoadedData";
import { useQueryParams } from "../hooks/useQueryParamsState";
import { apiFetch, FetchTypes } from "./core";

export interface SimpleListWithNewItem<T> extends LoadedData<T[]> {
    view: string;
    setView: (v: string) => void;
    views?: [string, string][];

    newItem: Partial<T>;
    updateNewItem: (changes: Partial<T>) => void;
    submitNewItem: () => Promise<T>;
    isNewItemPopupActive: boolean;
    setIsNewItemPopupActive: (v: boolean) => void;
}

interface Config<T> {
    viewQueryParamKey?: string;
    viewApiQueryParamKey?: string;
    defaultView?: string;
    views?: [string, string][];
    onCreate?: (item: T) => void;
}

export const useSimpleListWithNewItem = <T,>(apiPath: string, config?: Config<T>): SimpleListWithNewItem<T> => {
    const { viewQueryParamKey, viewApiQueryParamKey, defaultView, views, onCreate } = config || {};
    const { params, update } = useQueryParams({ [viewQueryParamKey || "view"]: defaultView || 'all' });
    const viewParam = !!viewApiQueryParamKey ? `${viewApiQueryParamKey}=${params.view}` : '';

    const data = useLoadedData<T[]>(`${apiPath}?${viewParam}`, []);

    const [newItem, setNewItem] = useState<Partial<T>>({ });
    const [isNewItemPopupActive, setIsNewItemPopupActive] = useState<boolean>(false);

    const submitNewItem = () => {
        return apiFetch<T>(`${apiPath}`, FetchTypes.POST, newItem)
            .then(item => {
                setIsNewItemPopupActive(false);
                if(onCreate) {
                    onCreate(item);
                }
                return item;
            });
    }

    return {
        ...data,
        view: params.view,
        setView: v => update({ view: v}),
        views,

        newItem,
        updateNewItem: c => setNewItem(ni => ({ ...ni, ...c })),
        submitNewItem,
        isNewItemPopupActive,
        setIsNewItemPopupActive,
    }
}