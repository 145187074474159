import React from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { FormBody, FormHeader, FormWrapper, LoadingIndicator, SaveButton, useWindowHotkey } from '../primitives';
import { DocumentTitle } from './DocumentTitle';
import { PowerEditor } from './slate/PowerEditor';
import { PowerEditorBaseProps, PowerEditorConfig, PowerEditorStockConfig } from './slate/PowerEditorConfig';
import { PowerDocPageConfig } from './types';
import { usePowerDocEdit } from './usePowerDocEdit';

interface Props extends PowerDocPageConfig {
    editorConfig?: PowerEditorConfig & PowerEditorStockConfig;
    editor?: React.ComponentType<PowerEditorBaseProps>;
    viewMode?: boolean;
}

const LowerSaveButtonWrapper = styled.div`
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }

    & > * {
        background: #ffffff;
    }
`;

export const PowerDocEditPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = usePowerDocEdit(props.apiPath, id, props);

    const Editor = props.editor;
    const { viewMode } = props;

    useWindowHotkey("alt+s", () => {
        if(!viewMode && data.hasChanges) {
            data.save();
        }
    });

    return (
        <FormWrapper>
            <FormHeader>
                <DocumentTitle
                    value={data.data.title}
                    update={v => data.update({ title: v })}
                    style={{ flex: "1 0 auto"}}
                    autoFocus={!viewMode}
                    readOnly={viewMode}
                    />
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </FormHeader>
            <FormBody>
                {data.isLoading && !data.data.content
                    ? <LoadingIndicator />
                    : (Editor
                        ? <Editor
                            content={data.data.content}
                            update={c => data.update({ content: c })}
                            viewMode={viewMode}
                            />
                        : <PowerEditor
                            content={data.data.content}
                            update={c => data.update({ content: c })}
                            {...(props.editorConfig || {})}
                            viewMode={viewMode}
                            />)}
            </FormBody>

            {data.hasChanges && <LowerSaveButtonWrapper><SaveButton action={() => data.save()} /></LowerSaveButtonWrapper>}
        </FormWrapper>
    );
}
