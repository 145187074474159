import { NewItemProps, useNewItem } from "../../../toolympus/api/useNewItem";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";

export interface ExtraCandidate {
    fullname: string;
    email?: string;
    phone?: string;
    created_at: string;
    created_by: string;
}

export interface PresidiumQuestionExtraCandidates extends LoadedData<ExtraCandidate[]> {
    newCandidate: NewItemProps<Omit<ExtraCandidate, "created_at" | "created_by">, ExtraCandidate>;
}

export const usePresidiumQuestionExtraCandidates = (questionId: string, doLoad?: boolean) => {
    const apiPath = `/api/presidium/question/${questionId}/arbitrator/extra`;
    const data = useLoadedData<ExtraCandidate[]>(
        apiPath,
        [],
        !!questionId && (doLoad || doLoad === undefined));

    const newCandidate = useNewItem<Omit<ExtraCandidate, "created_at" | "created_by">, ExtraCandidate>(
        apiPath,
        { fullname: "", email: "", phone: ""},
    );

    const saveNew = () => {
        return newCandidate.save()
            .then(x => {
                data.reload();
                return x;
            });
    }

    return {
        ...data,
        newCandidate: {
            ...newCandidate,
            save: saveNew,
        },
    }
}
