import { useEffect } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { QuestionEventBus } from "./usePresidiumQuestion";
import { ArbitratorSelection } from "./usePresidiumQuestionArbitratorsSelection";
import { PresidiumQuestion, QuestionType_NoVote } from "./usePresidiumQuestions";
import { Option } from './usePresidiumQuestions';


export interface PresidiumQuestionResult {
    candidates: ArbitratorSelection[];
    options: Option[];
    has_admin_votes: boolean;
    has_all_votes: boolean;
    has_enough_votes: boolean;
    has_all_approvals: boolean;
    has_ties: boolean;
    has_unbroken_tie?: boolean;
}

export interface PresidiumQuestionResultData extends LoadedData<PresidiumQuestionResult> {
    canFinish: boolean;
    finish: () => Promise<void>;
    updateCandidate: (id: string, changes: Partial<ArbitratorSelection>) => void;
    question: PresidiumQuestion;
}

interface Config {
    doLoad: boolean;
    eventBus: QuestionEventBus;
    question: PresidiumQuestion;
}

export const usePresidiumQuestionResult = (questionId: string, cfg: Config): PresidiumQuestionResultData => {
    const data = useLoadedData<PresidiumQuestionResult>(
        `/api/presidium/question/${questionId}/result`,
        { candidates: [] } as unknown as PresidiumQuestionResult,
        !!questionId && cfg.doLoad);

    const { question } = cfg;

    useEffect(() => {
        if(cfg?.eventBus) {
            const subid = cfg.eventBus.subscribe("votes-updated", () => data.reload());
            return () => cfg?.eventBus && cfg.eventBus.unsubscribe(subid);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canFinish = !data.isLoading && !question.is_closed && (
        (question.question_type === QuestionType_NoVote && question.is_launched)
        ||
        (data.data.has_enough_votes && (!cfg.question.is_candidates_question || data.data.has_all_approvals)));
    const finish = () => {
        if(canFinish) {
            return apiFetch(`/api/presidium/question/${questionId}/finish`, "post")
                .then(() => {
                    data.reload();
                    cfg.eventBus.dispatch("status-change-invoked");
                });
        } else {
            throw Error("cant_finish");
        }
    }

    const updateCandidate = (id: string, changes: Partial<ArbitratorSelection>) => {
        apiFetch(`/api/presidium/question/${questionId}/arbitrator/${id}`, "put", changes)
            .then(() => {
                data.reload();
            });
    }

    return {
        ...data,
        canFinish,
        finish,
        updateCandidate,
        question,
    }
}
