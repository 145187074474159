import React from 'react';
import { NumberingSequencesRoutes } from '../../toolympus/components/Numbering';
import { checkRole, useUser } from '../../toolympus/userContext/UserContext';

interface Props {
  
}

export const NumberingPages = (props: Props) => {
  const { user } = useUser();

  return (
    <NumberingSequencesRoutes
      apiPath="/api/numbering"
      usersDictionary="Users"
      isEditAvailable={checkRole(user, "admin")}
      documentationKey="numbering"
      />
  );
}
