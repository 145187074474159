import { AlertColor } from "../toolympus/components/primitives/Alert";

export interface CaseCreate {
    casenbr: string;
    rules: string;
    case_type: string;
    claimant_inn: string;
    claimant_title: string;
    respondant_inn: string;
    respondant_title: string;
}

export interface DescribedEmail {
    email?: string;
    description?: string;
}

export interface CaseSide {
    case_id?: string;
    line_nbr?: number;
    kind: "claimant" | "respondant" | "other";

    title: string;
    title_full?: string;
    inn: string;
    ogrn?: string;
    kpp?: string;
    geo_country?: string;
    geo_region?: string;
    geo_city?: string;
    address_combined?: string;

    emails?: DescribedEmail[];
}


export interface Case extends CaseCreate {
    _id: string;
    is_checked?: boolean;
    claim_received_date?: string;
    arbitration_start_date?: string;
    arbitration_end_date?: string;

    arbitration_procedure?: string;
    place_of_arbitration_country?: string;
    place_of_arbitration_city?: string;

    status: string;
    status_started?: boolean;
    status_finished?: boolean;
    status_paused?: boolean;
    status_review?: boolean;
    status_no_movement?: boolean;

    sides: CaseSide[];
    sides_alt?: CaseSide[];
    no_movement: boolean;
    claim_returned: boolean;
    arbitration_formed_date?: string;
    claimant_geo?: string;
    respondant_geo?: string;
    case_area?: string;
    assistant?: string;
    challenged: boolean;
    rejections?: boolean;
    arbitration_place?: string;
    arbitration_language?: string;
    applicable_law?: string;
    had_hearing: boolean;
    had_vcs: boolean;
    nbr_oral_hearings: number;
    had_pause: boolean;
    had_stop: boolean;
    had_fee_return: boolean;
    had_forced_execution: boolean;
    had_decision_questioned: boolean;
    had_cassation: boolean;
    had_cassation2: boolean;
    had_nadzor: boolean;
    had_reply_claim?: boolean;
    time_billed?: boolean;
              
    created_by: string;
    created_datetime: string;
    last_modified_by: string;
    last_modified_datetime: string;

    practice_ids?: string[];
    practice_casenbr?: string;

    timetracking_ratetable_id?: number | null;
};


export interface CaseCourt {
    case_id: string;
    line: number;
    batch: number;
    court: string;
    approved?: string;
    date_end: string;
    date_start?: string;
    court_case_nbr: string;
    court_case_link: string;
};

export interface CaseStatusAction {
    case_id: string;
    line: number;
    action: string;
    date: string;
    days?: string;
    reason?: string;
    is_retro: boolean;
}


export const StatusHints = {
    finished: { color: AlertColor.success },
    paused: { color: AlertColor.info },
    started: { color: AlertColor.warning },
    returned: { },
    nomovement: { },
    review: { color: AlertColor.error },
};
