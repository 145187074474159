import { convertInchesToTwip, convertMillimetersToTwip } from "docx-dotx-hf-fix"

export const convertMillimetersToEmu = (mm: number) => {
  return convertMillimetersToTwip(mm)*635;
}

export const convertInchesToEmu = (mm: number) => {
  return convertInchesToTwip(mm)*635;
}


export const convertMillimetersToImageUnits = (mm: number) => {
  return 3.78558449424591*mm;
}
