import React from 'react';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Form } from '../../../toolympus/components/primitives/Forms';
import { FieldSettingsPopupButton, useFields } from '../../../toolympus/components/schemed';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { NewPresidiumQuestionPopup } from './NewPresidiumQuestionPopup';
import { usePresidiumQuestionsList } from './usePresidiumQuestions';
import { PresidiumDropMenu } from '../Menu/PresidiumDropMenu';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { SearchField } from '../../../toolympus/components/primitives/SearchField';
import { ButtonOptionPicker } from '../../../toolympus/components/primitives/ButtonOptionPicker';
import { FieldFiltersPopupButton } from '../../../toolympus/components/schemed/Filtering';
import { useBoardStatementsPreparation } from './BoardStatements/useBoardStatementsPreparation';
import { MoreActionsMenuButton } from '../../../toolympus/components/primitives';
import { BoardStatementsPreparationPanel } from './BoardStatements/BoardStatementsPreparationPanel';
import { BoardStatementsPreparationPopup } from './BoardStatements/BoardStatementsPreparationPopup';
import { PresidiumQuestionsTable } from './PresidiumQuestionsTable';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';


export const PresidiumQuestionsList = () => {
    const data = usePresidiumQuestionsList();
    const { isLoading, filter, newQuestion, view, setView, schema, } = data;

    const statements = useBoardStatementsPreparation(data.data, { onComplete: () => data.reload() });

    const fields = useFields({
      defaultFields: [
          "title",
          "committee",
          "question_type",
          "casenbr",
          "batch",
          "votes_of", // when not drafts
      ],
      schema,
      storageKey: "__presidium_questions_fields",
  });

    return (<>
        <Form
            title={<><PresidiumDropMenu current="questions" /> Президиум: вопросы</>}
            formHeaderProps={{ alignItems: "flex-end" }}
            headerItems={<>
                <IconButton color='primary' onClick={() => newQuestion.startEditing()}><Add /></IconButton>
                {isLoading && <LoadingIndicator sizeVariant="m" />}

                <OccupyFreeSpace />
                
                <SearchField
                    filter={filter.filter}
                    setFilter={filter.setFilter}
                    noButton
                />
                <ButtonOptionPicker
                    options={[
                        ["Черновики", "drafts"],
                        ["Текущие", "current"],
                        ["Закрытые", "closed"],
                        ["Снятые", "closed-down"],
                        ["Все", "all"],
                    ]}
                    selected={view}
                    setSelected={setView} />

                <Buttons gap="small">
                  <FieldFiltersPopupButton fieldFilters={data.filtering} />
                  <FieldSettingsPopupButton fieldsSettings={fields} />

                  <PluggableDocumentationButton documentationKey="board_questions" />

                  <MoreActionsMenuButton
                    actions={[
                      ["statements", "Подготовка постановлений президиума", () => statements.start()]
                    ]}
                    />
                </Buttons>
              </>}>

                <PresidiumQuestionsTable
                    questions={data.data}
                    schema={schema}
                    fields={fields.activeFields}
                    sorting={data.sorting}
                    selection={statements.state === "_1selecting" ? statements.selection : undefined}
                    tailAnchor={data.limitTrigger.pagingAnchor}
                    />
        </Form>
        <NewPresidiumQuestionPopup schema={data.schema} data={data.newQuestion} />
        <BoardStatementsPreparationPanel data={statements} />
        <BoardStatementsPreparationPopup data={statements} schema={schema} />
    </>);
}
