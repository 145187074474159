import React from 'react';
import { Settings, useComments } from '../toolympus/components/Comments';
import { CommentsForm } from '../toolympus/components/Comments/CommentsForm';
import { TableComments, useTableComments as useTC } from '../toolympus/components/Comments/useTableComments';
import { useUser } from '../toolympus/userContext/UserContext';
import { useEmployeeList } from './Employees/employeeData';

type Entity = "arbitrator" | "case" | "practice" | "presidium_vote";

interface Props {
    entity: Entity;
    recordId: string;
}

const useCommentsSettings = (): Settings => {
    const employees = useEmployeeList();
    const { user } = useUser();

    return {
        getUserName: id => {
            const emp = employees.data.find(e => e.user_id === id);
            return emp ? `${emp.firstname} ${emp.lastname}` : id;
        },
        isRemoveAllowed: (comment) => {
            return user?._id === comment._id || user?.roles.includes("admin") || false;
        }
    };
}

export const Comments = ({ entity, recordId }: Props) => {
    const settings = useCommentsSettings();

    const comments = useComments('/api/comment', entity, recordId, settings);
    return (
        <CommentsForm data={comments} />
    );
}

export const useTableComments = (entity: Entity): TableComments => {
    const settings = useCommentsSettings();

    return useTC('/api/comment', entity, settings);
}
