import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NewItemProps } from '../../toolympus/api/useNewItem';
import { Dialog } from '../../toolympus/components/primitives/Dialogs';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { CorpDispute, NewCorpDispute } from './useCorpDisputesList';

interface Props {
    schema: Schema;
    data: NewItemProps<NewCorpDispute, CorpDispute>;
}

export const NewCorpDisputeDialog = ({ data, schema }: Props) => {
    const { isEditing, cancel, item, update, save } = data;
    return (
        <Dialog
            dialogTitle="Создать запись"
            isOpen={isEditing}
            close={cancel}
            actions={<>
                <Button onClick={cancel}><FormattedMessage id="common.cancel" /></Button>
                <Button onClick={() => save()} color="primary" variant="contained"><FormattedMessage id="common.save" /></Button>
            </>}>
            {item && <>
                <FormGrid key="1" columns="1fr">
                    <FormControlsForFields
                        fields={[
                            ["casenbr"],
                        ]}
                        schema={schema}
                        data={item}
                        onChange={(o,c) => update(c)}
                        />
                </FormGrid>

                <FormGrid key="2" columns="1fr">
                    <FormControlsForFields
                        fields={[
                            ["claimant"],
                            ["respondant"],
                        ]}
                        schema={{ claimant: { type: FieldType.text, label: "Истец" }, respondant: { type: FieldType.text, label: "Ответчик" } }}
                        data={{ claimant: item.claimants[0].title, respondant: item.respondants[0].title, }}
                        onChange={(o,c) => update({
                            claimants: [{ title: c.claimant || o.claimant }],
                            respondants: [{ title: c.respondant || o.respondant }],
                        })}
                        />
                </FormGrid>

                <FormGrid key="3" columns="1fr">
                    <FormControlsForFields
                        fields={[
                            ["entity_title"],
                            ["entity_inn"],
                            ["entity_ogrn"],
                        ]}
                        schema={schema}
                        data={item}
                        onChange={(o,c) => update(c)}
                        />
                </FormGrid>
            </>}

        </Dialog>
    );
}
