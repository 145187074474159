import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { login, useUser } from "../../userContext/UserContext";
import { useSendData } from "../useSendData";
import { useFormattedMessage } from "./useFormattedMessage";
import { Signup, SignupCommonProps, SignupCredentials } from "./useSignup";

type PasswordCheck = (form: { password: string, passwordRepeat: string}) => () => boolean;

export const usePasswordCheck: PasswordCheck = ({ password, passwordRepeat}) => {
    const noMatchPasswordsMessage = useFormattedMessage('errorPasswordsDontMatch');
    const { enqueueSnackbar } = useSnackbar();

    return password !== passwordRepeat ?
        () => { enqueueSnackbar(noMatchPasswordsMessage, { variant: 'error' }); return false; }
        : () => true;
}

export const useCommonSignup = ({ signupApiPath, loginOnSuccess, redirectPath }: SignupCommonProps): Signup => {
    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const user = useUser();

    const history = useHistory();

    const { data, update, send, isSaving } = useSendData<SignupCredentials, string>(signupApiPath, { login: '', password: '', passwordRepeat: '' }, {
        apiExtraParams: { noAuth: true },
        onSuccess: (result: string) => {
            if(loginOnSuccess) {
                login(user, result);
            }
            if (redirectPath) {
                history.push(redirectPath);
            }
        }
    })

    const validatePasswords = usePasswordCheck(data);

    const sendWrapper = () => {
        if (!validatePasswords()) {
            return new Promise<void>(_ => {});
        }
        
        setIsError(false);

        return send()
            .catch(er => {
                setIsError(true);
                throw er;
            })
            .then(x => { setIsSubmitted(true); return x; });
    }

    return {
        credentials: data,
        update: creds => {
          update({
            ...creds,
            login: (creds.login || "").trim(),
          })
        },
        send: sendWrapper,
        isSaving,
        isError,
        isSubmitted,
    }
}