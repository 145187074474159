import React from 'react';
import styled from '@emotion/styled';
import { interpolateTurbo } from 'd3-scale-chromatic';


const ScoreMarker = styled.span<{ color: string }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 3px;
  background-color: ${props => props.color};
  opacity: 0.85;
`;

interface Props {
  score?: number;
  min_score?: number;
  max_score?: number;
}

export const ColorMarker = ({ score, min_score, max_score }: Props) => {
  if(score === undefined || score === null) {
    return null;
  }

  const [min,max] = [min_score || 0, max_score || 10];
  const nx = (score-min)/(max-min);
  const color = interpolateTurbo(0.95 - 0.5*nx);

  return <ScoreMarker color={color} />;
}
