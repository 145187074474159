import moment from "moment";
import { useState } from "react";
import { FetchTypes, apiFetch } from "../../../toolympus/api/core";
import { ValidationErrors, useValidationErrors } from "../../../toolympus/components/schemed";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { Schema, useSchema } from "../../../toolympus/hooks/useSchema";
import { CaseCourt } from "../../../typings/Cases";

export interface CaseCourtData {
    records: CaseCourt[];
    isLoading: boolean;

    editRecord: Partial<CaseCourt> | null;
    edit: (record: CaseCourt) => void;
    updateEdit: (changes: Partial<CaseCourt>) => void;
    create: () => void;
    isEditing: boolean;
    save: () => Promise<CaseCourt> | Promise<void>;
    remove: (record: CaseCourt) => Promise<void>;
    cancelEdit: () => void;
    isSaving: boolean;
    schema: Schema;

    errors: ValidationErrors;
}

export const useCaseCourt = (caseid: string): CaseCourtData => {
    const apiPath = `/api/case/${caseid}/court`;
    const data = useLoadedData<CaseCourt[]>(apiPath, []);
    const [editRecord, setEditRecord] = useState<Partial<CaseCourt> | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const errors = useValidationErrors();
    const { case_court: schema } = useSchema();

    const save = () => {
        if(editRecord === null) {
            return Promise.resolve();
        }
        setIsSaving(true);
        const path = editRecord.line === undefined ? apiPath : `${apiPath}/${editRecord.line}`;
        const method = editRecord.line === undefined ? FetchTypes.POST : FetchTypes.PUT;
        const body = { ...editRecord, line: undefined, case_id: undefined };
        return apiFetch<CaseCourt>(path, method, body)
            .then(r => {
                setIsSaving(false);
                errors.clearErrors();
                setEditRecord(null);
                data.reload();
            })
            .catch(e => {
                setIsSaving(false);
                errors.handleErrors(e);
                throw e;
            });
    }

    const remove = (record: CaseCourt) => {
        setIsSaving(true);
        return apiFetch(`${apiPath}/${record.line}`, FetchTypes.DELETE)
            .then(r => {
                setIsSaving(false);
                errors.clearErrors();
                setEditRecord(null);
                data.reload();
            })
            .catch(e => {
                setIsSaving(false);
                throw e;
            });
    }

    return {
        records: data.data,
        isLoading: data.isLoading,
        isEditing: editRecord !== null,

        editRecord,
        edit: (r) => setEditRecord({...r}),
        updateEdit: (c) => setEditRecord({...editRecord, ...c}),
        create: () => setEditRecord({ date_start: moment().format("YYYY-MM-DD") }),
        cancelEdit: () => setEditRecord(null),
        save,
        remove,
        isSaving,
        errors,

        schema,
    }
}
