import { Settings, useSettings } from "../../SettingsPage";
import { apiFetch, apiFetchFile } from "../../../toolympus/api/core";
import { useAction } from "../../../toolympus/api/useAction";
import { NodeProcessor, generateDocx, saveDocx } from "../../../toolympus/components/PowerDoc/docx";
import { ExpressionProcessor, processPowerDocTemplate } from "../../../toolympus/components/PowerDoc/template";
import { PowerDoc } from "../../../toolympus/components/PowerDoc/types";
import { collectDocImagesByUrls, collectDocImagesUrls } from "../../../toolympus/components/PowerDoc/useRecordPowerDocsList";
import { CaseDataIn, createCaseDocumentFooterProcessor } from "./DocumentGeneration/processors";
import { SchemaFormatter, useSchemaFormatter } from "../../../toolympus/components/schemed";
import { readDocumentSettings } from "./documentSettings";


const createCustomProcessors = (data: CaseDataIn, formatter: SchemaFormatter, settings: Settings): NodeProcessor[] => {
  return [
    createCaseDocumentFooterProcessor(data, settings),
  ];
}


export const useCaseDocumentStub = (cfg: { expressionProcessor: ExpressionProcessor, data: CaseDataIn }) => {
  const { settings } = useSettings();
  const formatter = useSchemaFormatter();

  const getTemplate = (): Promise<Blob | null> => settings.document_generator_dotx_url
    ? apiFetchFile(settings.document_generator_dotx_url)
    : Promise.resolve(null);
        
  const generateDocument = () => {
    const customProcessors = createCustomProcessors(cfg.data, formatter, settings);
    return apiFetch<PowerDoc>(`/api/powerdoc/doc/${settings.case_generic_document_template_id}`, "get")
      .then(template => {
        return getTemplate()
          .then(dotxTemplate => {
            const doc = processPowerDocTemplate(template, cfg.expressionProcessor);
            const content = doc.content?.blocks || [];

            return collectDocImagesByUrls([ ...collectDocImagesUrls(content), settings.document_generator_signature_url || ""])
              .then(images => {
                return generateDocx(
                  { getImage: url => images[url], customProcessors },
                  content,
                  dotxTemplate,
                  readDocumentSettings(content))
                  .then(file => saveDocx(file, `${cfg?.expressionProcessor?.getFormattedValue("case.casenbr") || ""} ${doc.title}.docx`));;
              });
          });
      });
  }

  const generate = useAction(generateDocument);

  return {
    generate,
  }
}

export type DocumentStubGenerator = ReturnType<typeof useCaseDocumentStub>;