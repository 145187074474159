import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CaseForm from './presentation/CaseForm';
import { useCase, useCaseArbitrators } from './caseData';
import { useNewPracticeData } from '../Practice/practiceData';
import { useArbitratorsSearch } from '../Arbitrators/arbitratorsData';
import { ConfirmationDialogProvider } from '../../toolympus/components/primitives';


const CaseContainer = () => {
    const { casenbr } = useParams<{ casenbr: string}>();
    
    const caseData = useCase(casenbr);
    const caseArbitrators = useCaseArbitrators(casenbr);
    const arbitratorSearch = useArbitratorsSearch();

    const newPracticeData = useNewPracticeData();
    const history = useHistory();
    const submitAndOpen = () => newPracticeData.submit().then(p => { history.push(`/practice/${p._id}`); return p; });
    const updateAndSetIsActive = (isActive: boolean) => {
        const { _id, casenbr, arbitration_procedure, rules, place_of_arbitration_country, place_of_arbitration_city } = caseData.data;
        newPracticeData.update({
            case_id: _id,
            case_casenbr: casenbr,
            casenbr,
            rules,
            procedure: arbitration_procedure,
            place_country: place_of_arbitration_country,
            place_city: place_of_arbitration_city,
            place_city_en: place_of_arbitration_city === "Москва" ? "Moscow" : "",
        });
        newPracticeData.setIsActive(isActive);
    }
    
    return (
        <ConfirmationDialogProvider>
            <CaseForm
                case={caseData}
                caseArbitrators={caseArbitrators}
                arbitratorSearch={arbitratorSearch}
                newPracticeData={{ ...newPracticeData, submit: submitAndOpen, setIsActive: updateAndSetIsActive }} />
        </ConfirmationDialogProvider>);
};

export default CaseContainer;