import React from 'react';
import styled from '@emotion/styled';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { Component, PageContainer } from '../../toolympus/components/Pages';
import { DashboardPage } from '../../toolympus/components/Dashboard/DashboardPage';


export const InfoPageWrapper = styled(FormWrapper)`
    padding: 16px 60px;
    ${props => props.theme.breakpoints.down("sm")} {
        padding: 0 12px 16px;
    }
`;


interface Props {
    slug?: string;
}

const components = {
    dashboard: (c: Component) => <DashboardPage hideTitle isEmbeded defaultDashboard={c.content_text.split('\n')[0].trim()} />
}

export const PagePage = ({ slug }: Props) => {
    return <PageContainer
                apiPath="/api/page"
                slug={slug}
                wrapper={InfoPageWrapper}
                customComponents={components}
                />;
}
