export const pluralizeRu = (n: number, one: string, two: string, five?: string) => {
  const mod10 = n % 10;
  const mod100 = n % 100;
  if(mod10 === 1 && mod100 !== 11) {
    return one;
  } else if(2 <= mod10 && mod10 <= 4 && (mod100 < 10 || 20 < mod100)) {
    return two;
  } else {
    return five || two || one;
  }
}
