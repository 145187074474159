import React from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogState, FormGrid } from '../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { BackgroundOptions } from './BackgroundOptions';
import { usePersonalization } from './usePersonalization';

interface Props extends Pick<DialogState, "isOpen" | "close"> {
  
}

const BackgroundDemo = styled.div<{ backStyle: string, isSelected?: boolean }>`
  border-top: 3px solid ${props => props.isSelected ? props.theme.palette.primary.main : "transparent"};
  border-bottom: 3px solid ${props => props.isSelected ? props.theme.palette.primary.main : "transparent"};
  padding: 0rem 0 0.5rem;
  cursor: pointer;

  &:after {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    display: block;
    content: '';
    box-shadow: 0px 0px 10px -5px #33333380;
    ${props => props.backStyle};
  }
`;

export const PersonalizationDialog = (props: Props) => {
  const p13n = usePersonalization();

  return (
    <Dialog
      isOpen={props.isOpen}
      close={() => props.close()}
      noFullscreen
      dialogTitle="Настройки"
      actions={<>
        <Button size="small" onClick={() => props.close()}><FormattedMessage id="common.close" /></Button>
      </>}>
      
      <Typography>Фон</Typography>
      <FormGrid columns={`repeat(${Math.min(4, BackgroundOptions.length)}, 1fr)`} forceEvenColumns noMargin>
        {BackgroundOptions.map(option => (
          <BackgroundDemo
            key={option.name}
            backStyle={option.css}
            role="button"
            onClick={() => p13n.setBackground(option.name)}
            isSelected={option.name === p13n.background}>
            <Typography variant="caption" color="textSecondary">{option.label}</Typography>
          </BackgroundDemo>
        ))}
      </FormGrid>
    </Dialog>
  );
}
