import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';
import {
    FormContainer,
} from '../../../toolympus/components/primitives/Modal.styles';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { FormControlsForFields, FieldDefinition, TableForFields } from "../../../toolympus/components/schemed";
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { NewArbitratorData } from '../arbitratorsData';

interface Props {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    data: NewArbitratorData;
};

const fields: FieldDefinition[] = [
    ["lastname", { controlProps: { autoFocus: true } }],
    ["firstname"],
    ["middlename"],
];

export const NewArbitratorModal = (props: Props) => {
    const { isActive, setIsActive } = props;
    const { data: arbitrator, update: updateArbitrator, send: saveArbitrator, isSaving, duplicatesSearch } = props.data;

    const { arbitrator: schema } = useSchema();

    return (
        <Dialog open={isActive} onClose={() => setIsActive(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Новый арбитр</DialogTitle>
            
            <DialogContent>
                <FormContainer
                    id="new-arbitrator-form"
                    onSubmit={e => { e.preventDefault(); saveArbitrator(); }}>
                    <FormGrid style={{ width: "100%" }} gap="dense">
                        <FormControlsForFields
                            fields={fields}
                            data={arbitrator}
                            schema={schema}
                            onChange={(oldRow,changes) => updateArbitrator({ ...oldRow, ...changes })} />
                    </FormGrid>
                </FormContainer>
                
                {duplicatesSearch.results.length > 0 && <>
                    <Typography style={{ marginTop: "1rem" }}>В системе уже есть похожие арбитры:</Typography>
                    <TableForFields
                        data={duplicatesSearch.results}
                        schema={schema}
                        fields={[
                            ["lastname"],
                            ["firstname"],
                            ["middlename"],
                            ["info"],
                        ]}
                        fieldLink={f => {
                            switch(f) {
                                case "lastname":
                                case "firstname":
                                case "middlename":
                                    return row => `/arbitrator/${row._id}`;
                            }
                        }}
                        />
                </>}
                {duplicatesSearch.isLoading && <p> <LoadingIndicator sizeVariant="s" /></p>}
            </DialogContent>


            <DialogActions>
                <Button type='submit'
                    form="new-arbitrator-form"
                    disabled={isSaving}
                    color='primary'
                    variant='contained'
                    endIcon={isSaving && <LoadingIndicator sizeVariant='font' />}>
                    Создать
                </Button>
                <Button disabled={isSaving} color='secondary' variant='contained' onClick={() => setIsActive(false)}>Отмена</Button>
            </DialogActions>
        </Dialog>);
};
