import React from 'react';
import { useFormats } from '.';
import { FieldType } from '../../hooks/useSchema';
import { utc } from '../timezone';
import { DisplayProps } from './FormControlProps';

export const TimeDisplay = (props: DisplayProps) => {
  const { row, field, schema, config } = props;
  const { formatDate, formatDatetime, formatDatetimeWithFormat } = useFormats();
  
  const value = row[field];
  const valueTransformed = value ? (props.config as any)?.utcToLocal ? utc.toLocal(value) : value : "";

  if(config?.format) {
    return <>{formatDatetimeWithFormat(valueTransformed, config.format)}</>
  } else if(schema.type === FieldType.date || config?.formatAsDate) {
    return <>{formatDate(valueTransformed)}</>;
  } else {
    return <>{formatDatetime(valueTransformed)}</>;
  }
}
