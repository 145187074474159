import { apiFetch, FetchTypes } from '../../../toolympus/api/core';
import { useCrudItem, CrudItemData } from '../../../toolympus/api/useSimpleCrud';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { PresidiumQuestion } from '../Questions/usePresidiumQuestions';
import { Vote } from './usePresidiumVoteList';

interface PresidiumVoteQuestions {
    isLoading: boolean;
    available: PresidiumQuestion[];
    selected: PresidiumQuestion[];
    all: PresidiumQuestion[];
    reload: () => void;

    add: (questionId: string) => void;
    remove: (questionId: string) => void;
}

const apiPathQuestion = '/api/presidium/question';

const usePresidiumVoteQuestions = (vote: Pick<Vote, '_id' | 'committee' | 'status'>): PresidiumVoteQuestions => {
    const availableQuestions = useLoadedData<PresidiumQuestion[]>(`${apiPathQuestion}?view=drafts`, [], !!vote._id && vote.status === 'draft');
    const selectedQuestions = useLoadedData<PresidiumQuestion[]>(`/api/presidium/vote/${vote._id}/question`, [], !!vote._id);

    const updateQuestion = (questionId: string, include: boolean) => {
        return apiFetch<PresidiumQuestion>(`${apiPathQuestion}/${questionId}`, FetchTypes.PUT, {
            poll_id: include ? vote._id : null
        }).then(() => {
            availableQuestions.reload();
            selectedQuestions.reload();
        });
    }

    const available = availableQuestions.data.filter(q => !q.poll_id && q.committee === vote.committee);

    return {
        isLoading: availableQuestions.isLoading || selectedQuestions.isLoading,
        available,
        selected: selectedQuestions.data,
        all: [...selectedQuestions.data, ...(vote._id && vote.status === "draft" ? available : [])],
        add: (questionId: string) => updateQuestion(questionId, true),
        remove: (questionId: string) => updateQuestion(questionId, false),
        reload: () => {
            selectedQuestions.reload();
        },
    }
}


export interface PresidiumVoteData extends CrudItemData<Vote> {
    questions: PresidiumVoteQuestions;

    canLaunch: boolean;
    launch: () => void;
    canFinish: boolean;
    finish: () => Promise<void>;
    canCloseDown: boolean;
    closeDown: () => Promise<void>;
}

export const usePresidiumVote = (id: string): PresidiumVoteData => {
    const data = useCrudItem<Vote>(`/api/presidium/vote/${id}`, {
        defaultValue: {} as Vote,
        returnPath: '/presidium/votes',
    });

    const questions = usePresidiumVoteQuestions(data.data);

    const canLaunch = data.data.status === "draft";
    const launch = () => {
        apiFetch(`/api/presidium/vote/${id}/start`, FetchTypes.POST)
            .then(() => {
                data.reload();
                questions.reload();
            });
    }

    const canFinish = data.data.status === "inprogress";
    const finish = () => {
        if(!canFinish) {
            throw new Error("cant_finish");
        }

        return apiFetch(`/api/presidium/vote/${id}/finish`, FetchTypes.POST)
            .then(() => {
                data.reload();
                questions.reload();
            });
    }

    const canCloseDown = data.data.status === "inprogress";
    const closeDown = () => {
        if(!canCloseDown) {
            throw new Error("cant_close_down");
        }

        return apiFetch(`/api/presidium/vote/${id}/close-down`, FetchTypes.POST)
            .then(() => {
                data.reload();
                questions.reload();
            });
    }

    return {
        ...data,
        questions,
        
        canLaunch,
        launch,
        canFinish,
        finish,
        canCloseDown,
        closeDown,
    }
}