import { useSchemaFormatter } from "../../../toolympus/components/schemed";
import { FieldType, useSchema } from "../../../toolympus/hooks/useSchema";
import { CaseSide } from "../../../typings/Cases";
import { entitySetExpressionProcessor, ExpressionProcessor } from "../../../toolympus/components/PowerDoc/template";
import { CaseSides } from "./useCase";
import { CaseDataIn } from "./DocumentGeneration/processors";

const sideDescription = (side: CaseSide) => {
  return [
    !side.title_full || side.title_full === side.title ? side.title : `${side.title_full} (${side.title})`,
    [side.ogrn ? `ОГРН ${side.ogrn}` : "", side.inn ? `ИНН ${side.inn}` : ""].filter(x => !!x).join(", "),
    side.address_combined,
    (side.emails || []).map(e => e.email || "").filter(e => !!e).join(", ")
  ].filter(x => !!x).join("\n")
}

const parepareSidesDescription = (sides: CaseSides) => {
  return {
    claimants_full_text: sides.claimants.map(c => sideDescription(c)).join("\n\n"),
    claimants_full_title: sides.claimants.length > 1 ? "Истцы" : "Истец",

    respondants_full_text: sides.respondants.map(c => sideDescription(c)).join("\n\n"),
    respondants_full_title: sides.respondants.length > 1 ? "Ответчики" : "Ответчик",

    others_full_text: sides.others.map(c => sideDescription(c)).join("\n\n"),
    others_full_title: sides.others.length > 0 ? "Иные лица" : "",
    others_full_title_colon: sides.others.length > 0 ? "Иные лица:" : "",
  }
}

export const useCaseExpressionProcessor = (data: CaseDataIn & Record<string, any>): ExpressionProcessor => {
    const { case: schema } = useSchema();
    const formatter = useSchemaFormatter();

    return entitySetExpressionProcessor(
        {
            ...data,
            sides_descriptions: parepareSidesDescription(data.sides),
            date: {
                today: new Date(),
            },
        },
        (entity: string, field: string, value: any) => {
            if(entity === "case") {
                return formatter(value, schema[field]);
            } else if(entity === "date") {
                return formatter(value, { type: FieldType.date });
            }
            return value;
        }
    );
}
