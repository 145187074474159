import React, { ReactNode, useState } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { FullscreenExit, Fullscreen } from '@mui/icons-material';
import { Tooltip } from './Tooltip';

interface FullscreenWrapped {
    isFullscreen: boolean;
    setIsFullscreen: (v: boolean) => void;
    toggleFullscreen: () => void;
    renderContent: (content: ReactNode) => JSX.Element;
    button: ReactNode;
}

interface Config {
    defaultFullscreen?: boolean;
}

export const useFullscreen = (config?: Config): FullscreenWrapped => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(config?.defaultFullscreen || false);

    const toggleFullscreen = () => setIsFullscreen(isfs => !isfs);

    const renderContent = (content: ReactNode): JSX.Element => (
        <>
            <Dialog open={isFullscreen} onClose={() => setIsFullscreen(false)} fullScreen>
                {isFullscreen && content}
            </Dialog>
            {!isFullscreen && content}
        </>);

    const button = (
        <Tooltip text_id="common.fullscreen">
            <IconButton size="small" onClick={toggleFullscreen}>
                {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
        </Tooltip>
    );

    return {
        isFullscreen,
        setIsFullscreen,
        toggleFullscreen,
        renderContent,
        button,
    }
}