import { Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { CopyText } from '../../../toolympus/components/primitives/CopyText';
import { Dialog } from '../../../toolympus/components/primitives/Dialogs';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { PresidiumQuestionExtraCandidates } from './usePresidiumQuestionExtraCandidates';

interface Props {
    data: PresidiumQuestionExtraCandidates;
}

export const ExtraCandidatesList = (props: Props) => {
    const { data, isLoading, newCandidate } = props.data;
    const { presidium_extra_candidate: schema } = useSchema();

    return (
        <>
            <ActionRow>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator />}
                <IconButton size="small" color="primary" onClick={() => newCandidate.startEditing()}><Add /></IconButton>
            </ActionRow>
            <TableForFields
                schema={schema}
                data={data}
                fields={[
                    ["fullname"],
                    ["email"],
                    ["phone"],
                    ["comment"],
                    ["created_by"],
                    ["created_at", { utcToLocal: true }],
                ]}
                fieldElement={f => {
                    if(["fullname", "email", "phone"].includes(f)) {
                        return (r,s,o) => <CopyText copyValue={(r as any)[f]} value={o} />;
                    }
                }}
                />

            <Dialog
                isOpen={newCandidate.isEditing}
                close={newCandidate.cancel}
                dialogTitle="Предложить кандидата"
                noFullscreen
                actions={<>
                    <Button key="cancel" onClick={newCandidate.cancel}>отмена</Button>
                    <Button variant="contained" color="primary" key="suggest" onClick={() => newCandidate.save()}>предложить</Button>
                </>}
                >
                <FormGrid columns="1fr">
                    {newCandidate.item && <FormControlsForFields
                        schema={schema}
                        fields={[
                            ["fullname"],
                            ["email"],
                            ["phone"],
                            ["comment"],
                        ]}
                        data={newCandidate.item}
                        onChange={(o,c) => newCandidate.update(c)}
                        />}
                </FormGrid>
            </Dialog>
            
        </>
    );
}
