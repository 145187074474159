import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ScoringControlProps } from './typings';
import { FormattedMessage } from 'react-intl';

export const CheckboxScore = ({ data, criteria }: ScoringControlProps) => {
    const {
        scores,
        update,
    } = data;

    const id = criteria._id as string;

    return (
        <RadioGroup
            className="checkbox radio"
            row
            value={scores[id] === true ? "t" : scores[id] === false ? "f" : null}
            onChange={e => update({ [id]: e.target.value === "t" ? true : false })}>
            <FormControlLabel value="t" control={<Radio />} label={<FormattedMessage id="contests.scoring.checkbox.yes" />} />
            <FormControlLabel value="f" control={<Radio />} label={<FormattedMessage id="contests.scoring.checkbox.no" />} />
        </RadioGroup>);
}
