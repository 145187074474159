import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { GetAppOutlined } from '@mui/icons-material';
import isHotkey from 'is-hotkey';
import React from 'react';
import { ActionRow, LoadingIndicator, SaveButton } from '../../../toolympus/components/primitives';
import { DocumentTitle, EditRecordPowerDocData } from '../../../toolympus/components/PowerDoc';
import { RacaPowerEditor } from '../../PowerEditor';

interface Props {
    data: EditRecordPowerDocData;
    isOpen: boolean;
    close: () => void;
    generateDocument?: (id: string) => void;
    isGenerating?: boolean;
}

export const CaseDocumentDialog = ({ data, isOpen, close, generateDocument, isGenerating }: Props) => {
    return (
        <Dialog
            open={isOpen}
            onClose={close}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { minHeight: "80%" }}}
            onKeyDown={e => {
                if(isHotkey("alt+s", e) && data.hasChanges) {
                    e.preventDefault();
                    data.save();
                } else if(isHotkey("Esc", e)) {
                    close();
                }
            }}>
            <DialogTitle>
                <ActionRow firstItemNoMargin itemMarginTop="0" alignItems="center">
                    <DocumentTitle
                        value={data.data?.title || ""}
                        update={v => data.update({ title: v })}
                        style={{ flex: "1 0 auto"}}
                        />
                    {generateDocument && data.data._id && (
                        <Button
                            size="small"
                            color="primary"
                            startIcon={isGenerating ? <LoadingIndicator sizeVariant="s" /> : <GetAppOutlined />}
                            disabled={data.hasChanges || data.isLoading || isGenerating}
                            onClick={() => generateDocument(data.data._id)}>
                            скачать
                        </Button>
                        )}
                    {data.isLoading && <LoadingIndicator />}
                    {data.hasChanges && (
                        <SaveButton action={() => data.save()} />
                    )}
                </ActionRow>
            </DialogTitle>
            <DialogContent>
                {data.isLoading && (!data.data.content || data.documentId !== data.data._id)
                    ? <LoadingIndicator />
                    : <RacaPowerEditor
                        key={data.data._id}
                        content={data.data.content}
                        update={c => data.update({ content: c })}
                        autoFocus
                        />}
            </DialogContent>
        </Dialog>
    );
}
