import { useMemo, useState } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { CustomElement } from "../../../../../slate";
import { generateCode } from "../common";

export interface Tile {
    id: string;
    image_url?: string | null;
    title?: string | null;
    link?: string | null;
    _class?: string | null;
    content?: any;
}

export interface WithTiles {
    title?: string;
    tiles: Tile[];
    columns?: number | null;
}

export const copyTileToClipboard = (tile: Tile) => {
  const copy = { ...tile };
  copy.id = generateCode();
  const text = JSON.stringify({ _tl_pd_tile_copy: copy});
  navigator.clipboard.writeText(text);
}

export const getTileFromClipboard = (): Promise<Tile | null> => {
  return navigator.clipboard.readText()
    .then(text => {
      if(text.includes("_tl_pd_tile_copy")) {
        const data = JSON.parse(text);
        const tile = data._tl_pd_tile_copy as Tile;
        if(tile) {
          tile.id = generateCode();
          return tile;
        }
      }
      return null;
    });
}

export const DefaultColumns = 2;
const columnsSequence = [2,3,4,1];

export const useTiles = (element: Partial<WithTiles> & CustomElement) => {
    const editor = useSlateStatic();
    const [localState, setLocalState] = useState<WithTiles>({
        tiles: element.tiles || [],
        title: element.title,
        columns: element.columns,
    });
    const [insertedId, setInsertedId] = useState<string>("");

    const path = ReactEditor.findPath(editor, element);

    const updateState = (changes: Partial<WithTiles>) => {
        const updated = { ...localState, ...changes };
        setLocalState(updated);
        Transforms.setNodes(
            editor,
            updated as any,
            { at: path },
        );
    }

    const toggleColumns = () => {
        const columnsNow = localState.columns || DefaultColumns;
        updateState({
            columns: columnsSequence[columnsSequence.indexOf(columnsNow)+1] || columnsSequence[0],
        });
    }

    const insertTile = () => {
        const id = generateCode();
        updateState({
            tiles: [ ...localState.tiles, { id }],
        });
        setInsertedId(id);
    };

    const updateTile = (id: string, changes: Partial<Tile>) => {
        updateState({
            tiles: localState.tiles.map(t => t.id === id ? { ...t, ...changes } : t),
        });
    }

    const removeTile = (id: string) => {
        updateState({
            tiles: localState.tiles.filter(t => t.id !== id),
        });
    }

    const moveTile = (id: string, direction: 1 | -1) => {
        const movedIdx = localState.tiles.findIndex(t => t.id === id);
        if(movedIdx < 0) {
            return;
        }

        const resultIdx = movedIdx + direction;
        const tiles = localState.tiles;
        const moved = tiles[movedIdx];
        if(resultIdx < 0) {
            updateState({
                tiles: [...tiles.slice(1), moved],
            });
        } else if (resultIdx >= tiles.length) {
            updateState({
                tiles: [moved, ...tiles.slice(0, movedIdx)],
            });
        } else {
            const l = Math.min(movedIdx, resultIdx);
            updateState({
                tiles: [...tiles.slice(0,l), tiles[l+1], tiles[l], ...tiles.slice(l+2),],
            });
        }
    }

    const pasteTileFromClipboard = () => {
      getTileFromClipboard()
        .then(tile => {
          if(tile) {
            const id = generateCode();
            updateState({
                tiles: [ ...localState.tiles, { ...tile, id }],
            });
            setInsertedId(id);
          }
        })
    }

    const x = useMemo(() => ({
      tiles: localState.tiles,
      title: localState.title,
      insertTile,
      updateTile,
      removeTile,
      moveTileUp: (id: string) => moveTile(id, -1),
      moveTileDown: (id: string) => moveTile(id, +1),
      insertedId,

      columns: localState.columns || DefaultColumns,
      toggleColumns,
      update: updateState,
      copyTileToClipboard,
      pasteTileFromClipboard,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [localState, insertedId]);

    return x;
}


export type TilesEditData = ReturnType<typeof useTiles>;
