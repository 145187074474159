import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { EmptyState } from '../../toolympus/components/primitives/EmptyState';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { NewPracticeData, PracticeFilter, PracticeItem, PracticeListData } from './practiceData';
import { Form } from '../../toolympus/components/primitives/Forms';
import { FieldElementRenderer, TableForFields } from '../../toolympus/components/schemed';
import { mergeSchema, useSchema } from '../../toolympus/hooks/useSchema';
import { NewPracticeForm } from './NewPracticeForm';
import { useFields } from '../../toolympus/components/schemed';
import { FieldSettingsPopupButton } from '../../toolympus/components/schemed';
import { useTableComments } from '../Comments';
import { FieldFiltersPopupButton } from '../../toolympus/components/schemed/Filtering';
import { Buttons, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { SearchField } from '../../toolympus/components/primitives/SearchField';
import { ButtonOptionPicker } from '../../toolympus/components/primitives/ButtonOptionPicker';
import { PracticeCasenbrWrapper } from '../Cases/presentation/CasesList';

interface Props {
    data: PracticeListData;
    newRecordData: NewPracticeData;
}

const CaseNbrWrapper = styled.span`
  white-space: nowrap;
  & > span {
    margin-left: 0.5rem;
  }
`;

const practiceFieldElement = (f: string): FieldElementRenderer<PracticeItem> => {
  switch(f) {
    case "arbitrators":
      return row => (<>
        {Object.values(row.arbitrators_consent || {}).sort((a,b) => (a.name || "") > (b.name || "") ? 1 : -1).map(arb => (
          <p key={arb._id} style={{ whiteSpace: "nowrap", margin: "0.25rem 0"}}>{arb.name}: {arb.is_available ? "да" : "нет"}</p>
      ))}</>);
    case "case_casenbr":
      return (row,s,orig) => <CaseNbrWrapper>
        {orig} <PracticeCasenbrWrapper>{row.practice_casenbr || "без номера"}</PracticeCasenbrWrapper>
      </CaseNbrWrapper>;
  }
}

export const PracticeListPage = ({ data, newRecordData }: Props) => {
    const { practice } = useSchema();
    const schema = mergeSchema(practice, {
        is_published: { label: " "},
        case_casenbr: { label: "Дело" },
        document_date: { label: "Дата" },
        place_country: { label: "МА:Страна" },
        place_city: { label: "МА:Город" },
        place_city_en: { label: "МА:Город (en)" },
    });

    const [isNoCreateDialogActive, setIsNoCreateDialogActive] = useState<boolean>(false);

    
    const fields = useFields({
        defaultFields: [
            "is_published",
            "case_casenbr",
            "document_type",
            "document_date",
            "procedure",
            "rules",
            "place_country",
            "place_city",
        ],
        schema,
        storageKey: "fields_practice",
        blacklist: ["arbitrators_consent"],
        extraSettings: {
            case_casenbr: { cellStyle: { whiteSpace: "nowrap" } },
        },
    });

    const comments = useTableComments('practice');

    return (
        <Form title="Практика"
            headerItems={
              <Buttons fullWidth alignItems="flex-end">
                <IconButton color='primary' onClick={() => setIsNoCreateDialogActive(true)}><Add /></IconButton>
                <Typography variant="caption" color="textSecondary">Всего записей: {data.data.length}</Typography>
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                
                <OccupyFreeSpace />
                
                <SearchField
                    autoFocus
                    filter={data.filter}
                    setFilter={data.setFilter}
                    noButton
                />
                <ButtonOptionPicker
                    options={[
                        ["Опубликованное", PracticeFilter.Published],
                        ["Черновики", PracticeFilter.Drafts],
                        ["Все", PracticeFilter.All],
                    ]}
                    selected={data.listtype}
                    setSelected={data.setListType} />
                <FieldFiltersPopupButton fieldFilters={data.filtering} />
                <FieldSettingsPopupButton fieldsSettings={fields} />
              </Buttons>}>

            <NewPracticeForm data={newRecordData} />

            
            <TableForFields
                fields={fields.activeFields}
                schema={schema}
                data={data.data}
                sorting={data.sorting}
                autoexpander={{ initialRows: 20, increment: 20 }}
                fieldLink={field => field === 'document_type'? ({_id}: { _id: string}) => `/practice/${_id}` : null}
                emptyState={<EmptyState text={<p style={{ textAlign: 'center'}}>
                    Уточните критерии поиска и нажмите кнопку "Загрузить"
                    </p>} />}
                fieldElement={practiceFieldElement}
                rowButtons={row => comments.buttonForRecord(row._id)}
            />
            {comments.popup}

            <Dialog open={isNoCreateDialogActive} onClose={() => setIsNoCreateDialogActive(false)}>
                <DialogContent>
                    <Typography>Создание записей практики теперь возможно только с формы дела.</Typography>
                    <Typography>Это необходимо, чтобы правильно устанавливать связь между делом и практикой. Возможно, это ограничение будет снято в будущем.</Typography>
                    <DialogActions>
                        <Button color="primary" onClick={() => setIsNoCreateDialogActive(false)}>ok</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            
        </Form>
    );
}

    
    
    
    
    
    
    
    
    