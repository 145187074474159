import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { MediaLibPicker, useMediaLibContext } from '../toolympus/components/medialib';
import { ActionRow, Form, FormGrid, InputLinkIconButton, OccupyFreeSpace, SaveButton } from '../toolympus/components/primitives';
import { Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useSaveable } from '../toolympus/components/primitives/useSaveable';
import { useDocumentTemplatesSelector } from './PowerEditor/useDocumentTemplates';
import { useItemWithControls } from '../toolympus/api/useWithControls';
import { mergeSchema } from '../toolympus/hooks/useSchema';
import { createSettingsCombined } from '../toolympus/api/useSettingsCombined';
import { UpdateNotificationsEditButton } from '../toolympus/components/Documentation/UpdatesNotifications';

const SelectedFileWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 0.25rem;
`;

export interface Settings {
  document_generator_dotx_url?: string | null;
  document_generator_signature_url?: string | null;
  case_generic_document_template_id?: string | null;
  board_statement_common_template_id?: string | null;
  board_statement_international_template_id?: string | null;
  geo_region_to_city_default?: string | null;
}

export const SettingsApiPath = "/api/settings";

export const { SettingsProvider, useSettings, useSettingsEdit } = createSettingsCombined<Settings>(
  SettingsApiPath,
  {},
)


export const SettingsPage = () => {
    const { settings, update, hasChanges, save, errors, schema } = useSettingsEdit();
    const { getFilepath } = useMediaLibContext();

    const documentTemplates = useDocumentTemplatesSelector();

    const fullSchema = useMemo(() => {
      return mergeSchema(
        schema,
        {
          case_generic_document_template_id: { ...documentTemplates.selectorSchema },
          board_statement_common_template_id: { ...documentTemplates.selectorSchema },
          board_statement_international_template_id: { ...documentTemplates.selectorSchema, },
        }
      )
    }, [schema, documentTemplates.selectorSchema])


    const { controls } = useItemWithControls({ data: settings, update, errors }, { schema: fullSchema });

    useSaveable({ save, hasChanges });

    return (
        <Form
            title="Настройки"
            headerItems={<ActionRow>
                <OccupyFreeSpace />
                <UpdateNotificationsEditButton />
                {hasChanges && <SaveButton action={() => save()} />}
              </ActionRow>}>

            <FormGrid columns="1fr">
              
              <Typography variant="h6">Генератор документов</Typography>
              <FormGrid noMargin>
                <MediaLibPicker
                  label="Шаблон dotx для документов"
                  selectedUrl={settings.document_generator_dotx_url}
                  selectFile={mf => update({ document_generator_dotx_url: mf ? getFilepath(mf) : null })}
                  closeOnSelect
                  allowFileActions
                  size="small"
                  showFilenameInsteadOfImage
                  getDisplayFilename={() => <SelectedFileWrapper><Check /><Typography>выбран</Typography></SelectedFileWrapper>}
                  />

                <MediaLibPicker
                  label="Подпись для документов"
                  selectedUrl={settings.document_generator_signature_url}
                  selectFile={mf => update({ document_generator_signature_url: mf ? getFilepath(mf) : null })}
                  closeOnSelect
                  allowFileActions
                  size="small"
                  showFilenameInsteadOfImage
                  getDisplayFilename={() => <SelectedFileWrapper><Check /><Typography>выбран</Typography></SelectedFileWrapper>}
                  />

                <div />

                {controls([
                  ["case_generic_document_template_id", {
                    controlProps: {
                      startAdornment: (
                        <InputLinkIconButton
                          link={settings.case_generic_document_template_id ? `/system/documents/doc/${settings.case_generic_document_template_id}` : undefined}
                          position="start"
                          />)
                    }
                  }],
                  ["board_statement_common_template_id", {
                    controlProps: {
                      startAdornment: (
                        <InputLinkIconButton
                          link={settings.board_statement_common_template_id ? `/system/documents/doc/${settings.board_statement_common_template_id}` : undefined}
                          position="start"
                          />),
                    }
                  }],
                  ["board_statement_international_template_id", {
                    controlProps: {
                      startAdornment: (
                        <InputLinkIconButton
                          link={settings.board_statement_international_template_id ? `/system/documents/doc/${settings.board_statement_international_template_id}` : undefined}
                          position="start"
                          />),
                    }
                  }],
                ])}
                
              </FormGrid>

              <Typography variant="h6">Прочее</Typography>
              <FormGrid noMargin>
                {controls([
                  ["geo_region_to_city_default"],
                  ])}
              </FormGrid>

            </FormGrid>
        </Form>
    );
}
