export const base64ToBytes = (base64: any) => {
  const binString = atob(base64);
  return Uint8Array.from(binString as any, (m) => (m as any).codePointAt(0) as any);
}

export const bytesToBase64 = (bytes: any) => {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte as any),
  ).join("");
  return btoa(binString);
}

export const base64encode = (v: string) => {
  return bytesToBase64(new TextEncoder().encode(v));
}

export const base64decode = (v: string) => {
  return new TextDecoder().decode(base64ToBytes(v));
}

export const base64urlSafe = (v: string) => v.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
export const base64urlUnsafe = (v: string) => {
  const withReplacements = v.replace(/-/g, '+').replace(/_/g, '/');
  const padding = v.length % 4 === 0 ? '' : '='.repeat(4 - (v.length % 4));
  return withReplacements + padding;
};

export const base64encodeUrl = (v: string) => base64urlSafe(base64encode(v));
export const base64decodeUrl = (v: string) => base64decode(base64urlUnsafe(v));

