import { NewItemProps, useNewItem } from "../../toolympus/api/useNewItem";
import { TextFilter, useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { Schema, useSchema } from "../../toolympus/hooks/useSchema";

export interface Party {
    id?: number;
    title: string;
    inn?: string;
    group_id?: number | null | undefined;
}

export interface ChangeDescription {
    date: string;
    description: string;
    change?: Partial<CorpDispute>;
    original?: Partial<CorpDispute>;
}

export interface Representative {
    title: string;
    phone?: string;
    email?: string;
    group_label?: string;
}

export interface CorpDispute {
    _id: number;
    casenbr: string;
    claimants: Party[];
    respondants: Party[];
    third_parties: Party[];
    entity_representative: Party[];
    entity_title: string;
    entity_inn: string;
    entity_ogrn: string;
    representatives: Record<number, Representative>;
    id_counter: number;
    document_title: string;
    claim_details: string;
    changes: ChangeDescription[];
    change_description: ChangeDescription;
    is_published: boolean;
    published_date: string;
    created_datetime: string;
}

export type NewCorpDispute = Pick<CorpDispute, "casenbr" | "claimants" | "respondants" | "third_parties" | "entity_title" | "entity_inn" | "entity_ogrn">;

export interface CorpDisputeListData extends LoadedData<CorpDispute[]> {
    filter:  TextFilter<CorpDispute>;
    schema: Schema;
    newItem: NewItemProps<NewCorpDispute, CorpDispute>;
}

export const partiesTitles = (parties: Party[]) => (parties || []).map(p => p.title).join(", ");

export const CorpDisputeApiPath = "/api/corp-dispute";

export const useCorpDisputesList = (): CorpDisputeListData => {
    const data = useLoadedData<CorpDispute[]>(CorpDisputeApiPath, []);

    const filter = useTextFilter<CorpDispute>(d => `${d.casenbr} ${d.document_title} ${d.entity_inn} ${partiesTitles(d.claimants)} ${partiesTitles(d.respondants)}`);

    const { corp_dispute: schema } = useSchema();

    const newItem = useNewItem<NewCorpDispute, CorpDispute>(CorpDisputeApiPath, { claimants: [{ title: ""}], respondants: [{ title: ""}] } as NewCorpDispute);

    return {
        ...data,
        schema,
        data: filter.filterData(data.data),
        filter,
        newItem: {
            ...newItem,
            save: () => newItem.save().then(x => { data.reload(); return x; })
        },
    }
}