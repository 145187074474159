import moment from "moment";
import { utc } from "../components/timezone";

export const DateInternalFormat = "YYYY-MM-DD";
export const DateTimeInternalFormat = "YYYY-MM-DD HH:mm:ss";
export const TimeInternalFormat = "HH:mm:ss";

export const getMomentLocalDate = (utcDate: Date | string, locale: string, format: string) =>
    moment.utc(utcDate).locale(locale).format(format);

export const getLocalDate = (utcDate: Date | string, locale: string = 'ru', format: string = 'D MMMM YYYY') =>
    getMomentLocalDate(utcDate, locale, format);

export const getLocalDateTime = (utcDate: Date | string, locale: string = 'ru', format: string = 'D MMMM, HH:mm') =>
    getMomentLocalDate(utcDate, locale, format);

export const getDateTimeNoYear = (utcDate: Date | string, locale: string = 'ru', format: string = 'D MMMM, HH:mm') =>
    getMomentLocalDate(utcDate, locale, format);

export const getFullLocalDateTime = (utcDate: Date | string, locale: string = 'ru', format: string = 'D MMMM YYYY HH:mm') =>
    getMomentLocalDate(utcDate, locale, format);

export const today = () => moment().format(DateInternalFormat);

export const toLocalDatetimeInternal = (dt?: string) => dt
    ? moment(utc.toLocal(dt)).format(DateTimeInternalFormat)
    : "";