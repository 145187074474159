import moment from "moment";
import { DateInternalFormat } from "../../toolympus/api/datetimeUtil";
import { useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { FieldType, Schema, createSelectSchema, mergeSchema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { TimetrackingRatetable, TimetrackingRecord } from "./types"
import { useCallback, useMemo } from "react";
import { CreatedBySchema } from "./useTimetrackingRateTablesConfiguration";

export const EmptyFilters = { date_from: null, date_to: null, actor_role: null };

export const useCaseTimeTracking = (caseId: string, rateTable: TimetrackingRatetable) => {
  const apiPath = `/api/timetracking/case/${caseId}/record`;
  const records = useLoadedData<TimetrackingRecord[]>(apiPath, [], !!caseId);

  const { schema: schemaBase } = useSingleSchema(`${apiPath}/uiconfig`);

  const schema = useMemo(
    () => mergeSchema(schemaBase, {
      created_by: CreatedBySchema, description: { type: FieldType.textlong },
      actor_role: rateTable?.rates?.length
        ? createSelectSchema(rateTable.rates.map(r => ({ value: r.actor_role, label: r.actor_role })))
        : {},
      total: { ...schemaBase.rate, label: "Стоимость" },
      time_logged: { hint: "например 4ч 15м или 4:15" },
    }),
    [schemaBase, rateTable]);

  const totalsSchema = useMemo(() => {
    return {
      actor_role: schemaBase.actor_role,
      total: { ...schemaBase.rate, label: "Всего" },
      time_logged: schemaBase.time_logged,
    }
  }, [schemaBase]);

  const onTimeRecordChange = useCallback((original: Partial<TimetrackingRecord>, changes: Partial<TimetrackingRecord>) => {
    if(changes.actor_role && original.actor_role !== changes.actor_role && !!rateTable) {
      const actorRate = rateTable.rates.find(r => r.actor_role === changes.actor_role);
      if(actorRate) {
        return { ...changes, rate: actorRate.rate };
      }
    }
    return changes;
  }, [rateTable]);

  const newItem = useNewItem<Partial<TimetrackingRecord>, TimetrackingRecord>(
    apiPath,
    { case_id: caseId, record_date: moment().format(DateInternalFormat), description: "", actor_role: "" },
    { onChange: onTimeRecordChange },
  );

  const editItem = useEditItem2<TimetrackingRecord>({
    getApiPath: r => `${apiPath}/${r._id}`,
    onChange: onTimeRecordChange,
  });

  const filters = useEditItem2<{ date_from: string | null, date_to: string | null, actor_role: string | null }>({
  });

  const filtersSchema = useMemo(() => {
    const actors = Array.from(new Set(records.data.map(r => r.actor_role)));
    actors.sort();
    const schema: Schema = {
      date_from: { type: FieldType.date, label: "С" },
      date_to: { type: FieldType.date, label: "По" },
      actor_role: createSelectSchema(actors.map(a => ({ label: a, value: a })), { label: "Роль" }), 
    }
    return schema;
  }, [records.data]);

  const filteredRecords = useMemo(() => {
    if(!filters.item) {
      return records.data;
    }

    const { date_from, date_to, actor_role } = filters.item;
    if(!date_from && !date_to && !actor_role) {
      return records.data;
    }

    const filterDF = date_from ? (r: TimetrackingRecord) => r.record_date >= date_from : () => true;
    const filterDT = date_to ? (r: TimetrackingRecord) => r.record_date <= date_to : () => true;
    const filterAR = actor_role ? (r: TimetrackingRecord) => r.actor_role === actor_role : () => true;

    return records.data.filter(r => filterDF(r) && filterDT(r) && filterAR(r));
  }, [records.data, filters.item]);

  const totals = useMemo(() => {
    const result = (rateTable.rates || []).map(r => ({ actor_role: r.actor_role, total: 0, time_logged: 0, is_total: false }));
    filteredRecords.forEach(r => {
      if(r.rate && r.time_logged) {
        const row = result.find(rx => rx.actor_role === r.actor_role);
        if(row) {
          row.total += r.rate * r.time_logged / 60;
          row.time_logged += r.time_logged;
        } else {
          result.push({ actor_role: r.actor_role, total: r.rate * r.time_logged / 60, time_logged: r.time_logged, is_total: false });
        }
      }
    });

    result.forEach(r => {
      r.total = Math.floor(r.total);
    });
    result.push(result.reduce<typeof result[0]>((r,v) => {
      r.total += v.total;
      r.time_logged += v.time_logged;
      return r;
    }, { is_total: true, actor_role: "Всего", total: 0, time_logged: 0 }));
    return result;
  }, [rateTable, filteredRecords]);

  return {
    records,
    filteredRecords,
    newItem: {
      ...newItem,
      save: (c?: Partial<TimetrackingRecord>) => {
        return newItem.save(c).then(x => {
          records.reload();
          return x;
        })
      },
    },
    editItem: {
      ...editItem,
      save: (c?: Partial<TimetrackingRecord>) => {
        return editItem.save(c).then(x => {
          records.reload();
          return x;
        })
      },
    },
    schema,
    
    totals,
    totalsSchema,
    
    filters,
    filtersSchema,
  }
}
