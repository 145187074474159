import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SimpleButton } from '../primitives/Buttons';
import { IDefaultUser } from './typings';


interface Props<IUser extends IDefaultUser = IDefaultUser> {
    user: IUser | null;
    close: () => void;
    changeEmail: (u: IUser, newEmail: string) => void;
}

export const ChangeEmailPopup = <IUser extends IDefaultUser = IDefaultUser>(props: Props<IUser>) => {
    const { user, close, changeEmail } = props;

    const [newEmail, setNewEmail] = useState<string>("");
    useEffect(() => {
        if(user) {
            setNewEmail(user.email);
        }
    }, [user]);

    return (
        <Dialog open={!!user} onClose={close} maxWidth='md' fullWidth>
            <DialogTitle>
                <FormattedMessage id="userManagement.changeEmailPopup.title"/>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={<FormattedMessage id="userManagement.changeEmailPopup.oldEmail"/>}
                    margin="normal"
                    fullWidth
                    disabled
                    value={user ? user.email : ""}
                />
                <TextField
                    label={<FormattedMessage id="userManagement.changeEmailPopup.newEmail"/>}
                    margin="normal"
                    fullWidth
                    autoFocus
                    value={newEmail}
                    onChange={(ev) => setNewEmail(ev.target.value || '')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.cancel"/></Button>
                <SimpleButton
                    label={<FormattedMessage id="common.save"/>}
                    action={() => {
                        if(user) {
                            changeEmail(user, newEmail);
                            close();
                        }
                    }}
                    />
            </DialogActions>
        </Dialog>
    );
}
