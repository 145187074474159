import React from 'react';
import { IconButton } from '@mui/material';
import { CallMade } from '@mui/icons-material';
import { Tooltip } from '../../primitives';
import { useCMSConfiguration } from '../Configuration/CMSConfigurationContext';
import { CMSRecord } from '../types';

interface Props {
    record?: Partial<CMSRecord>;
}

export const SiteLink = (props: Props) => {
    const { getSiteUrl } = useCMSConfiguration();

    const link = getSiteUrl && getSiteUrl(props.record || null);

    return link
        ? (
            <a href={link} target="_blank" rel="noreferrer noopener nofollow">
                <Tooltip text_id="cms.form.open_site">
                <IconButton size="small"><CallMade /></IconButton>
                </Tooltip>
            </a>
        )
        : null;
}
