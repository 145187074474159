import { StarBorder } from '@mui/icons-material';
import React from 'react';
import { SimpleList } from '../../toolympus/components/primitives/SimpleList';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { useUser } from '../../toolympus/userContext/UserContext';
import { useEmployeeList, useUserSelectSchema } from './employeeData';

export const EmployeeList = () => {
    const data = useEmployeeList();
    const schemas = useSchema();
    const { schema: userSchema } = useUserSelectSchema("Email");
    schemas.employee["user_id"] = userSchema;
    const user = useUser();

    return <SimpleList
            title="Сотрудники"
            data={data}
            fields={[
                ["lastname"],
                ["firstname"],
                ["middlename"],
                ["position"],
                ["user_id"],
            ]}
            linkFields={["lastname", "firstname", "middlename"]}
            getLinkValue={e => `/system/employee/${e._id}`}
            totalRecordsText="Всего сотрудников: {0}"
            schema={schemas.employee}
            rowButtons={e => e.user_id === user.user?._id && <StarBorder />}
            />;
}
