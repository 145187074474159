import axios, { Method } from 'axios';
import jwt_decode from 'jwt-decode';

export enum FetchTypes {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export type FetchTypesX = FetchTypes | Method;

export class UnauthenticatedError extends Error {
    constructor() {
        super("Authentication missing");
    }
}

const extractFileName = (contentDisposition?: string) => {
    if(!contentDisposition) {
      return "";
    }
    
    const prefix = "filename=";
    const startPre = contentDisposition.indexOf(prefix);
    if(startPre < 0) {
        return "";
    }
    const start = startPre + prefix.length;
    const end = contentDisposition.indexOf(";", start);
    const result = end < 0 ? contentDisposition.substring(start) : contentDisposition.substring(start, end);
    const trimmed = result.trim();
    if(result.startsWith("\"") && result.endsWith("\"")) {
        return trimmed.substring(1, result.length-1);
    } else {
        return trimmed;
    }
}

let onAuthError = () => {};

export const setOnAuthError = (handler: () => void) => 
    onAuthError = handler;

export function apiFetch<T>(url: string, method: FetchTypesX = FetchTypes.GET, body: any = undefined, extraParams: any = undefined): Promise<T> {
    const auth = extraParams?.noAuth ?
            {} :
            (extraParams?.authToken ?
                { "Authorization": createAuthHeader(extraParams.authToken) } :
                { "Authorization": getApiTokenForRequest() });
    let params = {
        ...extraParams,
        url,
        headers: auth,
    } as any;

    if (method !== FetchTypes.GET) {
        params = { ...params, method: method as string, data: body };
    }
    return axios.request<T>(params)
        .then(response => {
            const data = response.data;
            
            if(extraParams?.blobDetailed) {
                const filename = extractFileName(response.headers["content-disposition"]);
                return { blob: data, filename };
            }

            return data;
        })
        .catch(error => {
            if (error.response?.status === 401 && !extraParams?.noAuth) {
                onAuthError();
                return {} as any; 
            } else {
                throw error;
            }
        });
}

export const apiUploadFile = (url: string, method: FetchTypesX, formfield: string, file: any): Promise<any> => {
    const data = new FormData();
    data.append(formfield, file);
    return axios.request({
        url,
        method,
        data,
        headers: {
            "Authorization": getApiTokenForRequest(),
        },
        timeout: 120000,
    })
        .catch(error => {
            if (error.response?.status === 401) {
                onAuthError();
                return {} as any; 
            } else {
                throw error;
            }
        });
}

interface BlobWithDetails {
    blob: Blob;
    filename: string;
}

export const apiFetchFile = (url: string) => apiFetch<Blob>(url, FetchTypes.GET, undefined, { responseType: 'blob' });
export const apiFetchFileDetailed = (url: string) => apiFetch<BlobWithDetails>(url, FetchTypes.GET, undefined, { responseType: 'blob', blobDetailed: true });

export const downloadBlob = (blob: Blob, filename: string) => {
  const anchor = document.createElement("a");
  const url = URL.createObjectURL(blob);
  anchor.href = url;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  
  setTimeout(() => {
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }, 0);
}

export const downloadBuffer = (buffer: ArrayBuffer, mimetype: string, filename: string) => {
    const anchor = document.createElement("a");
    const data = new Blob([buffer], { type: mimetype });
  
    const url = URL.createObjectURL(data);
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    
    setTimeout(() => {
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

export const downloadFile = (url: string, filename?: string) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    return apiFetchFileDetailed(url)
        .then(data => {
            let objectUrl = window.URL.createObjectURL(data.blob);
            
            anchor.href = objectUrl;
            anchor.download = filename || data.filename;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
}

const apiTokenStorageKey = 'apitoken';

export const storeApiToken = (token: string) => localStorage.setItem(apiTokenStorageKey, token);
export const clearApiToken = () => localStorage.removeItem(apiTokenStorageKey);
export const getApiToken = () => localStorage.getItem(apiTokenStorageKey);
export const decodeToken = <IToken>(token: string): IToken => jwt_decode(token) as IToken;

export const getApiTokenThrowing = () => {
    const token = getApiToken();
    if (!token) {
        onAuthError();
        return "";
    }
    return token;
}

export const createAuthHeader = (token: string) => `Bearer ${token}`;

export const getApiTokenForRequest = () => createAuthHeader(getApiTokenThrowing());
