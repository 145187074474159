export const createTsv = <T,>(header: string[], getRow: (r: T, idx: number) => any[], data: T[]): string => {
    return [
        header.join("\t"),
        ...data.map((r,i) => getRow(r, i).join("\t")),
    ].join("\n");
}

export const createMDTable = <T,>(header: string[], getRow: (r: T, idx: number) => any[], data: T[]): string => {
    return [
        header.map(h => `| ${h} `).join("") + '|',
        header.map(() => `|---`).join("") + '|',
        ...data.map((r,i) => getRow(r, i).map(x => `| ${x} `).join("") + '|'),
    ].join("\n");
}