import React from 'react';
import { Button, IconButton } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { FormControlsForFields, TableForFields } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { PresidiumQuestionData } from './usePresidiumQuestion';
import { Option } from './usePresidiumQuestions';

interface Props {
    data: PresidiumQuestionData;
}

export const OptionSchema: Schema = {
    label: {
        type: FieldType.text,
        label: "Название",
    },
    label_en: {
        type: FieldType.text,
        label: "Название (en)",
    },
    suggested_by: {
        type: FieldType.dictionarySelect,
        label: "Предложено",
        dictionary: "presidiummember",
    },
    score: {
        type: FieldType.number,
        label: "Результат",
    }
}

const useEditOptions = (data: PresidiumQuestionData) => {
    const options = data.data.options || [];

    const addOption = () => {
        data.update({ options: [...options, { id: (options.reduce((r,o) => Math.max(r, o.id), 0) || 0) + 1, label: "?", label_en: "?" }]})
    }

    const updateOption = (original: Option, changes: Partial<Option>) => {
        data.update({ options: options.map((o,i) => original.id === o.id ? { ...o, ...changes } : o)});
    }

    const removeOption = (option: Option) => {
        data.update({ options: options.filter(o => o.id !== option.id) });
    }

    return {
        options,
        addOption,
        updateOption,
        removeOption,
    }
}

export const QuestionOptionsForm = (props: Props) => {
    const data = props.data;

    const { options, addOption, updateOption, removeOption } = useEditOptions(data);

    const canEdit = !data.data.is_closed;

    return (
        <>
            <FormGrid>
                <FormControlsForFields
                    schema={data.schema}
                    fields={[
                        ["candidates_can_suggest_options", { disabled: !canEdit }]
                    ]}
                    data={data.data}
                    onChange={(o,c) => data.update(c)}
                    />

            </FormGrid>

            <TableForFields
                schema={OptionSchema}
                data={options}
                getRowId={o => o.id}
                withRowNbr
                fields={[
                    ["label", { editable: canEdit }],
                    ["label_en", { editable: canEdit }],
                    ["suggested_by"],
                ]}
                onChange={(o,c) => updateOption(o,c)}
                rowButtons={o => canEdit && (
                    <IconButton size="small" onClick={() => removeOption(o)}><DeleteOutlined /></IconButton>
                )}
                />
            
            {canEdit && <ActionRow>
                <OccupyFreeSpace />
                <Button color="primary" size="small" startIcon={<Add />} onClick={addOption}>добавить вариант</Button>
            </ActionRow>}

        </>
    );
}
