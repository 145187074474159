import React, { useState, ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { MediaFile } from './useMediaLib';
import { IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Close } from '@mui/icons-material';
import { MediaLibPickerDialog } from './MediaLibPickerDialog';
import { useMediaLibContext } from './MediaLibContext';

interface Props {
    selectedUrl: string | null | undefined;
    selectFile: (mf: MediaFile | null, url: string | null) => void;
    label?: ReactNode;
    placeholder?: ReactNode;
    disabled?: boolean;
    closeOnSelect?: boolean;
    noUpload?: boolean;
    showFilenameInsteadOfImage?: boolean;
    allowFileActions?: boolean;
    size?: "large" | "small";
    getDisplayFilename?: (url: string) => ReactNode;
}

export const MediaLibPickerWrapper = styled.div<Pick<Props, "size">>`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    min-height: calc(1rem + 25px);

    & .image {
        width: 100%;
        height: auto;
        max-height: 300px;
        object-fit: contain;
    }

    & .placeholder {
        width: 100%;
        height: ${props => props.size === "small" ? "auto" : "300px"};
        box-shadow: 0 0 5px 0 #00000020;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background: ${props => props.theme.palette.grey[200]};
        border-radius: ${props => props.theme.shape.borderRadius}px;
        cursor: pointer;
    }

    & .clear {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background: #ffffffd0;
        display: none;
    }

    &:hover .clear {
        display: block;
    }

    & .prev-image {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        transform: translateY(-50%);
        background: #ffffffd0;
    }
    & .next-image {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        background: #ffffffa0;
    }
`;

export const MediaLibPicker = (props: Props) => {
    const { selectedUrl, selectFile, label, disabled, closeOnSelect } = props;
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isFileError, setIsFileError] = useState<boolean>(false);

    useEffect(() => {
      setIsFileError(false);
    }, [selectedUrl]);

    const medialib = useMediaLibContext();

    useEffect(() => {
      if(isDialogOpen && medialib.requestLoad) {
        medialib.requestLoad();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialogOpen]);

    const urlParts = (selectedUrl || "").split(/[/\\]/);

    return (
        <>
            <MediaLibPickerWrapper
                className="medialib-picker"
                size={props.size}
                onClick={() => !disabled && setIsDialogOpen(true)}
                >
                {label && <Typography variant="caption" color="textSecondary">{label}</Typography>}
                {selectedUrl
                    ? (props.showFilenameInsteadOfImage
                        ? <div className="placeholder">
                            {props.getDisplayFilename
                                ? props.getDisplayFilename(selectedUrl)
                                : <Typography>{urlParts[urlParts.length - 1]}</Typography>}
                        </div>
                        : <img src={selectedUrl} alt="" className="image" onError={() => setIsFileError(true)} />)
                    : <div className="placeholder">{props.placeholder || (<Typography><FormattedMessage id="medialib.select" /></Typography>)}</div>}
                
                {selectedUrl && <IconButton className="clear" size="small" onClick={e => { e.stopPropagation(); selectFile(null, null); }}><Close /></IconButton>}
                {isFileError && <div className="placeholder"><Typography><FormattedMessage id="medialib.error.file_load_error" /></Typography></div>}

            </MediaLibPickerWrapper>
            <MediaLibPickerDialog
                isOpen={isDialogOpen}
                close={() => setIsDialogOpen(false)}
                selectedUrls={selectedUrl ? [selectedUrl] : []}
                selectFile={selectFile}
                closeOnSelect={closeOnSelect}
                noUpload={props.noUpload}
                allowFileActions={props.allowFileActions}
                />
        </>
    );
}
