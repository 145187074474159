import { useDictionaries } from "../../hooks/useDictionaries";
import { FieldSchema, FieldType } from "../../hooks/useSchema";
import { useFormats } from "./FormatsContext";

export type SchemaFormatter = (value: any, schema: FieldSchema) => string;

export const useSchemaFormatter = (): SchemaFormatter => {
    const formats = useFormats();
    const dictionaries = useDictionaries();

    return (value: any, schema?: FieldSchema) => {
        const fieldType = schema?.type || FieldType.text;
        const fieldSchema = schema as FieldSchema;

        switch(fieldType) {
            case FieldType.decimal:
                return formats.formatDecimal(value);
            case FieldType.date:
                return value ? formats.formatDate(value) : "";
            case FieldType.datetime:
                return value ? formats.formatDatetime(value) : "";
            case FieldType.select:
                const label = fieldSchema.valueDict[value] || "";
                return label;
            case FieldType.multiselect:
                const labels = (value || []).map((v: any) => fieldSchema.valueDict[v]);
                return labels.join(", ");
            case FieldType.dictionarySelect:
                const dict = fieldSchema.dictionary ? dictionaries[fieldSchema.dictionary] : { values: []};
                const item = dict.values?.find(item => item.value === value);
                return item ? item.label : "";
            case FieldType.dictionarySelectMulti:
                const dict2 = fieldSchema.dictionary ? dictionaries[fieldSchema.dictionary] : { values: []};
                const items = (value as any[])
                    .map(v => dict2.values?.find(item => item.value === v))
                    .filter(x => x !== undefined)
                    .map(item => item?.label);
                return items.join(", ");
            case FieldType.bool:
                return formats.formatBoolean(value);
            case FieldType.json:
            case FieldType.password:
                return "";
            default:
                return `${value || ""}`;
        }
    }
}
