import { useEffect, useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { CrudItemData, useCrudItem } from "../../../toolympus/api/useSimpleCrud";
import { SimpleDocumentListData, useSimpleDocumentList } from "../../../toolympus/components/files";
import { Schema, useSchema } from "../../../toolympus/hooks/useSchema";
import { EventBus, useEventBus } from "../../../toolympus/hooks/useEventBus";
import { ArbitratorSelectionData, usePresidiumQuestionArbitratorsSelection } from "./usePresidiumQuestionArbitratorsSelection";
import { PresidiumQuestionResultData, usePresidiumQuestionResult } from "./usePresidiumQuestionResult";
import { PresidiumQuestion, useQuestionTitleGenerator } from "./usePresidiumQuestions";
import { PresidiumQuestionResponses, usePresidiumQuestionResponses } from "./usePresidiumResponses";
import { PresidiumQuestionExtraCandidates, usePresidiumQuestionExtraCandidates } from "./usePresidiumQuestionExtraCandidates";
import { QuestionFeed, useQuestionFeed } from "./useQuestionFeed";
import { QuestionSubscriptionData, useQuestionSubscription } from "./useQuestionSubscription";

export interface PresidiumQuestionData extends CrudItemData<PresidiumQuestion> {
    schema: Schema;
    attributesSchema: Schema;
    documents: SimpleDocumentListData;
    arbitratorSelection: ArbitratorSelectionData;
    extraCandidates: PresidiumQuestionExtraCandidates;
    responses: PresidiumQuestionResponses;
    result: PresidiumQuestionResultData;
    feed: QuestionFeed;
    subscription: QuestionSubscriptionData;
    
    canCloseDown: boolean;
    closeDown: () => Promise<void>;
    canRollback: boolean;
    rollback: () => Promise<void>;
    canReopen: boolean;
    reopen: () => Promise<void>;
    regenerateTitle: () => void;

    activate: (sectionKey: string) => void;
}

type QuestionEventT = "status-change-invoked" | "votes-updated";
export type QuestionEventBus = EventBus<QuestionEventT>;

export const usePresidiumQuestion = (id: string): PresidiumQuestionData => {
    const [activated, setActivated] = useState<Record<string, boolean>>({});

    const questionTitleGen = useQuestionTitleGenerator();

    const regenerateTitle = () => {
        data.update({ title: questionTitleGen.generate(data.data.question_type, data.data.case_id) });
    }

    const data = useCrudItem(`/api/presidium/question/${id}`, {
        defaultValue: {} as unknown as PresidiumQuestion,
        returnPath: '/presidium/questions',
        onChange: questionTitleGen.injectInChanges,
    });

    const eventBus = useEventBus<QuestionEventT>();

    useEffect(() => {
        eventBus.subscribe("status-change-invoked", () => data.reload());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const documents = useSimpleDocumentList(`/api/presidium/question/${id}/documents`, undefined, { noLoad: !activated["documents"]});
    const arbitratorSelection = usePresidiumQuestionArbitratorsSelection(id, { disableEdits: data.data.is_closed });

    const extraCandidates = usePresidiumQuestionExtraCandidates(id, activated["extra-candidates"] || false);

    const { presidium_question: schema, presidium_question_attributes: attributesSchema } = useSchema();

    const responses = usePresidiumQuestionResponses(id, {
        doLoad: activated["responses"] || false,
        question: data.data,
        eventBus,
    });

    const result = usePresidiumQuestionResult(id, {
        doLoad: (!!data.data.status && data.data.status !== "draft" && activated["result"]) || false,
        question: data.data,
        eventBus,
    });

    const feed = useQuestionFeed(id, {
        doLoad: activated["feed"] || false,
        eventBus,
    });

    

    const canCloseDown = !data.isLoading && !data.data.is_closed && data.data.status !== "draft";
    const closeDown = () => {
        if(canCloseDown) {
            return apiFetch(`/api/presidium/question/${id}/close-down`, "post")
                .then(() => {
                    data.reload();
                });
        } else {
            throw Error("cant_finish");
        }
    }

    const canRollback = canCloseDown;
    const rollback = () => {
        if(canCloseDown) {
            return apiFetch(`/api/presidium/question/${id}/rollback`, "post")
                .then(() => {
                    data.reload();
                    arbitratorSelection.reloadSelected();
                    result.reload();
                    responses.reload();
                });
        } else {
            throw Error("cant_rollback");
        }
    }

    const canReopen = !data.isLoading && !!data.data.is_closed;
    const reopen = () => {
        if(canReopen) {
            return apiFetch(`/api/presidium/question/${id}/reopen`, "post")
                .then(() => {
                    data.reload();
                    arbitratorSelection.reloadSelected();
                    result.reload();
                    responses.reload();
                });
        } else {
            throw Error("cant_reopen");
        }
    }

    const subscription = useQuestionSubscription({
        question: data.data,
        onUpdated: saved => data.setData({ ...data.data, subscribed_user_ids: saved.subscribed_user_ids }),
    });

    return {
        ...data,
        schema,
        attributesSchema,
        documents: {
            ...documents,
            documents: documents.documents.filter(d => !d.meta?.unlisted)
        },
        arbitratorSelection,
        extraCandidates,
        responses,
        result,
        feed,
        subscription,


        canCloseDown,
        closeDown,
        canRollback,
        rollback,
        canReopen,
        reopen,
        regenerateTitle,

        activate: (k: string) => { setActivated(x => ({ ...x, [k]: true }))},
    }
}
