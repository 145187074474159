import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useSchema, Schema, FieldType } from '../../../../hooks/useSchema';
import { TableForFields } from '../../../schemed';
import { OpenDetailsWidgetFn, ResultField, WidgetConfig, WidgetResult } from '../../types';
import { toTSV } from '../../useWidgetDetails';
import { ClipboardButton } from '../../../primitives/Buttons';
import { useDictionaries } from '../../../../hooks/useDictionaries';
import { adjustDictionarySelectSchema } from '../../../schemed/Select';
import { ChartWrapper, ChartHeader, ChartTitle, Table, Gutter } from '../Common.styles';
import { PieChart } from './PieChart';
import { ColumnChart } from './ColumnChart';
import { getChartRows } from '../util';
import { useMediaBreakpoint } from '../../../primitives';

export interface DiagramContainerProps {
    res: WidgetResult;
    openDetails: OpenDetailsWidgetFn;
    getColumnWidth?: (col: number) => string;
    type: 'pie' | 'bar';
}

const calcTotalRow = (totalRowType: string | null | undefined, rows: Record<string, any>[], fields: ResultField[]) => {
    if(totalRowType && rows.length) {
        return fields.reduce((ac, field) => {
            const v1 = rows[0][field.name];
            if(typeof v1 === "number") {
                const sum = rows.reduce((summ, row) => summ + row[field.name], 0);
                const total = totalRowType === "avg" ? (sum / rows.length).toFixed(2) : sum;
                return { ...ac, [field.name]: total };
            }

            return ac;
        }, { is_total: true });
    }

    return null;
}

export const getWidgetWidth = (widget: WidgetConfig | WidgetResult) => {
  const { widgettype } = widget;
  const { width, hide_chart, show_table } = widget.display_settings;

  if(width) {
    return width;
  }

  switch(widgettype) {
    case "pie":
      return (hide_chart ? 0 : 1) + (show_table ? 2 : 0);
    case "bar":
      return (hide_chart ? 0 : 2) + (show_table ? 2 : 0);
    default:
      return 1;
  }
}

export const DiagramContainer = ({ res, openDetails, getColumnWidth = () => '100%', type }: DiagramContainerProps) => {
    const { show_table, hide_chart, total_row } = res.display_settings || { show_table: true, hide_chart: false };

    const schemas = useSchema();
    const dictionaries = useDictionaries();

    const percentFields = res.result_fields
        .filter(field => field.fieldtype === 'percent')
        .map(field => field.name);

    const rowsWithPercent = res.rows
        .map(row => Object.keys(row)
        .reduce((acc, key) => ({
            ...acc, 
            [key]: percentFields.includes(key)?(row[key] * 100).toFixed(2) + '%' : row[key]
        }), {}));

    const totalRow = calcTotalRow(total_row, rowsWithPercent as Record<string,any>[], res.result_fields);
    const allRows = totalRow ? [...rowsWithPercent, totalRow] : rowsWithPercent;

    const resultSchema: Schema = res.result_fields
        .reduce((ac, field) => ({ 
            ...ac, 
            [field.name]: 
                field.entity && field.entity_field? 
                    adjustDictionarySelectSchema(schemas[field.entity][field.entity_field], dictionaries)
                    : 
                    {
                        ...field, 
                        type: field.fieldtype
                    } 
                }), {});

    const getResultTSV = () => {
        const fields = res.result_fields.map(f => ({
            name: f.name,
            label: (f.label || resultSchema[f.name]?.label) as string,
            fieldtype: f.entity && f.entity_field ? resultSchema[f.name].type : f.fieldtype,
            schema: f.entity && f.entity_field ? resultSchema[f.name] : null,
        }));
        const rows = allRows.map(row => {
            const r: Record<string, any> = { ...row };
            fields.forEach(({ name, fieldtype, schema }) => {
                if((fieldtype === FieldType.select || fieldtype === FieldType.dictionarySelect) && schema && schema.valueDict) {
                    r[name] = schema.valueDict[r[name]];
                }
            });
            return r;
        });
        return toTSV(fields, rows);
    };

    const chartRows = useMemo(
        () => getChartRows(res, dictionaries, schemas),
        [res, dictionaries, schemas]);

    const chartHeaderRef = useRef<HTMLDivElement | null>(null);
    const [chartWidth, setChartWidth] = useState<number>(0);

    const isMobile = useMediaBreakpoint("down", "sm");

    useLayoutEffect(() => {
        if(chartHeaderRef.current) {
            const fullW = chartHeaderRef.current.getBoundingClientRect().width;
            setChartWidth(
                isMobile
                    ? fullW
                    : !show_table
                        ? fullW
                        : type === "pie"
                            ? fullW * 0.35
                            : fullW * 0.5);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return <Gutter width={getColumnWidth(getWidgetWidth(res))}>
        <ChartWrapper>
            <ChartHeader ref={chartHeaderRef}>
                <ChartTitle>
                    <Typography variant="body1">{res.title}</Typography>
                    <Typography variant="body2">{res.subtitle}</Typography>
                </ChartTitle>
                <ClipboardButton getValue={getResultTSV} />
            </ChartHeader>

            {!hide_chart && type === "bar" && <ColumnChart width={chartWidth} rows={chartRows} openDetails={openDetails} />}
            {!hide_chart && type === "pie" && <PieChart width={chartWidth} rows={chartRows} openDetails={openDetails} />}

            {show_table &&
                <Table type={type} hideChart={hide_chart}>
                    <TableForFields
                        data={allRows}
                        fields={res.result_fields.map(field => [field.name])}
                        schema={resultSchema}
                        rowStyle={row => (row as any).is_total ? { fontStyle: 'italic' } : undefined}
                        onRowClick={row => openDetails(row as Record<string, any>)}
                    />
                </Table>
            }
        </ChartWrapper>
    </Gutter>
}
