import { useMemo } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { schemePaired } from 'd3-scale-chromatic';
import { ChartRow } from './util';


export const useColorScheme = (data: ChartRow[]) => {
    const n = Math.max(data.length, 2);

    const colors = [...schemePaired];

    const [getColor, getColorUnscaled] = useMemo(() => {
      return [
        scaleOrdinal({
          domain: data.map(r => r.label),
          range: [...schemePaired],
        }),
        (n: number) => colors[Math.floor(n / colors.length)],
      ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [n]);

    return {
        getColor,
        getColorUnscaled,
        singleColor: colors[1],
        axisColor: "#33333340",
        gridColor: "#33333310",
        axisLabelColor: "#333333",
    }
}
