import React from 'react';
import { ButtonOptionPicker, Buttons, Form, FormGrid, LoadingIndicator, OccupyFreeSpace, SaveButton, SimpleDialog, useSaveable } from '../../toolympus/components/primitives';
import { useTimetrackingRateTablesConfiguration } from './useTimetrackingRateTablesConfiguration';
import { Select } from '../../toolympus/components/schemed/Select';
import { Button, IconButton, Typography } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { TimetrackingRatesEditor } from './TimetrackingRatesEditor';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';

interface Props {
  
}

export const TimetrackingRateTablesConfiguration = (props: Props) => {
  const data = useTimetrackingRateTablesConfiguration();

  useSaveable(data.editItem);

  return (
    <Form
      title="Таблицы ставок"
      headerItems={
        <>
          <div style={{ width: 300 }}>
            <Select
              field="current"
              row={{ current: data.current.value ? +data.current.value : null }}
              onChange={(o,c) => data.current.update(c.current || "")}
              schema={data.tableSelectorSchema}
              extraProps={{ autoComplete: true }}
              />
          </div>

          {data.view.value !== "custom" && <IconButton
            size="small"
            color="primary"
            onClick={() => data.newItem.startEditing()}
            >
            <Add />
          </IconButton>}

          <OccupyFreeSpace />

          {(data.isLoading || data.editItem.isLoading) && <LoadingIndicator />}

          <Button size="small" color="primary" onClick={() => data.editItem.data?._id && data.viewUsages.startEditing({ tableId: data.editItem.data?._id })}>
            где используется?
          </Button>

          <ButtonOptionPicker
            options={[
              ["стандартные", "normal"],
              ["индивидуальные", "custom"],
            ]}
            selected={data.view.value}
            setSelected={data.view.update}
            />

          <Buttons gap="small">

            {data.removeItem.canRun && <IconButton onClick={() => data.removeItem.run()}><DeleteOutlined /></IconButton>}

            {data.editItem.hasChanges && <SaveButton action={() => data.editItem.save()} />}
            
            <PluggableDocumentationButton documentationKey="timetracking" />
          </Buttons>
        </>
      }
      >

        {!!data.editItem.data._id &&
          <>
            <FormGrid noMargin>
              <FormControlsForFields
                schema={data.schema}
                data={data.editItem.data}
                onChange={(o,c) => data.editItem.update(c)}
                errors={data.editItem.errors}
                fields={[
                  ["title"],
                  ["currency", { autoComplete: true }],
                  ["is_active"],
                  ["created_by", { readOnly: true }],
                  ["created_at", { utcToLocal: true, readOnly: true }],
                ]}
                />
            </FormGrid>

            <Typography variant="h6" style={{ marginTop: "1rem" }}>Ставки</Typography>
            <TimetrackingRatesEditor
              rates={data.editItem.data.rates}
              update={v => data.editItem.update({ rates: v })}
              />
          </>}
      

      <SimpleDialog
        dialogTitle="Новая таблица ставок"
        isOpen={data.newItem.isEditing}
        close={() => data.newItem.cancel()}
        save={() => data.newItem.save()}
        submitOnModEnter>
        {data.newItem.item &&
          <FormGrid noMargin columns="1fr">
            <FormControlsForFields
              schema={data.schema}
              data={data.newItem.item}
              onChange={(o,c) => data.newItem.update(c)}
              errors={data.newItem.errors}
              fields={[
                ["title"],
                ["currency", { autoComplete: true }],
              ]}
              />
          </FormGrid>}
      </SimpleDialog>

      <SimpleDialog
        dialogTitle="Дела"
        isOpen={data.viewUsages.isEditing}
        close={() => data.viewUsages.cancel()}
        save={() => data.viewUsages.cancel()}
        noSave
        noFullscreen>
          {data.usages.isLoading
            ? <LoadingIndicator />
            : <TableForFields
                data={data.usages.data}
                fields={[
                  ["casenbr", { label: "Дело" }]
                ]}
                fieldLink={f => {
                  return (row) => `/case/${row._id}?tab=timetracking`
                }}
                schema={{}}
              />}
      </SimpleDialog>
    </Form>
  );
}
