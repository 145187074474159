import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Typography, Paper } from '@mui/material';

interface EmptyStateContainerProps {
  padding?: "large" | "small";
}

const Container = styled(Paper)<EmptyStateContainerProps>`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding: ${props => props.padding === "small" ? "0.5rem" : "2em"} 2em;
    cursor: default;

    background: ${props => props.theme.palette.grey[100]};

`;

interface Props {
    text?: string | ReactNode;
    onClick?: () => void;
}

export const EmptyState = (props: Props & EmptyStateContainerProps) => {
  const { text, ...otherProps } = props;
    return <Container elevation={0} {...otherProps}>
        <Typography variant="body1">{text || "..."}</Typography>
    </Container>;
}