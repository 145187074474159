import { useState } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { WidgetConfig } from "../Dashboard/types";

export interface WidgetList {
    isOpen: boolean;
    open: () => void;
    close: () => void;

    filter: string;
    setFilter: (v: string) => void;

    widgets: WidgetConfig[];
    isLoading: boolean;
}

export const useWidgetList = (apiPrefix: string): WidgetList => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);
    const [filter, setFilter] = useState("");
    const { data: widgets, isLoading } = useLoadedData<WidgetConfig[]>(`${apiPrefix}/widget`, [], load);

    return {
        isOpen,
        open: () => { setLoad(true); setIsOpen(true); },
        close: () => setIsOpen(false),

        filter,
        setFilter,

        widgets: filter && filter.length > 0 ? widgets.filter(w => `${w.title} ${w.subtitle}`.toLowerCase().includes(filter.toLowerCase())) : widgets,
        isLoading,
    };
}
