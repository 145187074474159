export const toMap = <TIn,TKey extends string | number | symbol,TOut=TIn>(data: TIn[], key: (r: TIn) => TKey, transform?: (r: TIn) => TOut) => {
  const t: (r: TIn) => TOut = transform || (x => x as any as TOut);
  return (data || []).reduce<Record<TKey,TOut>>((r,v) => {
    r[key(v)] = t(v);
    return r;
  }, {} as Record<TKey, TOut>);
}


export const generateCode = (range: number = 1e8) => Math.floor(range+Math.random()*range).toString(32);
