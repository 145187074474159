import React from 'react';
import { RestorePasswordFields, RestorePasswordForm } from '../../toolympus/components/auth';
import { PublicPageHarness } from './PublicPageHarness';
import { useRestorePassword } from '../../toolympus/hooks/auth';
import { Typography } from '@mui/material';

export const ResetPasswordForm2 = () => {
    return (
        <RestorePasswordForm restoreAPIPath="/api/restore-access/reset" successRedirect="/" />
    );
}


export const ResetPasswordForm = () => {
  const data = useRestorePassword("/api/restore-access/reset");

  return (<PublicPageHarness>
    <form onSubmit={e => { e.preventDefault(); data.send(); }} className="panel">
      <Typography variant="h2">Смена пароля</Typography>
      <RestorePasswordFields
        data={data}
        successRedirect="/"
        />
        
    </form>
  </PublicPageHarness>);
};

