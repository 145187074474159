import React, { ReactNode, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { UpdateNotification, useUpdateNotifications } from './useUpdateNotificationsEdit';
import { Collapse, Fade, Paper, Popper } from '@mui/material';
import { PowerEditorBase } from '../../PowerDoc';
import { Buttons, OccupyFreeSpace, SmallerButton } from '../../primitives';
import { FormattedMessage } from 'react-intl';

const Anchor = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
`;


const NotificationItemWrapper = styled.div<{ isFirst?: boolean }>`
  padding: 0.5rem 0.75rem;
  min-width: 300px;
  width: 400px;
  max-width: 400px;

  & > :first-child {
    margin-top: 0;
  }

  & p {
    margin: 0.1rem 0;

    & + p {
      margin-top: 0.25rem;
    }
  }
  border-top: ${props => props.isFirst ? 0 : 1}px solid #00000030;
`;

interface ItemProps {
  notification: UpdateNotification;
  dismiss: (n: UpdateNotification) => void;
  extraActions?: ReactNode;
  isFirst?: boolean;
}

export const NotificationItem = (props: ItemProps) => {
  const { notification, dismiss } = props;

  const [isDismissing,setIsDismissing] = useState<boolean>(false);

  const doDismiss = () => {
    setIsDismissing(true);
    setTimeout(() => dismiss(notification), 150);
  }

  return (
    <Collapse orientation="horizontal" in={!isDismissing}>
      <NotificationItemWrapper isFirst={props.isFirst}>
        {!!notification.content?.blocks?.length && <PowerEditorBase
          content={notification.content}
          update={() => {}}
          viewMode
          />}
        <Buttons>
          <OccupyFreeSpace />
          {props.extraActions}
          <SmallerButton size="small" onClick={doDismiss} color="primary">
            {notification.dismiss_label || <FormattedMessage id="common.ok" />}
          </SmallerButton>
        </Buttons>
      </NotificationItemWrapper>
    </Collapse>
  )
}

interface Props {
  
}

export const UpdateNotificationsDisplay = (props: Props) => {
  const data = useUpdateNotifications();
  const anchorRef = useRef<HTMLDivElement | null>(null);

  return data.hasUnreadNotifications
    ? (
      <>
        <Anchor ref={anchorRef} />
        {!!anchorRef.current && 
          <Popper
            open
            anchorEl={anchorRef.current}
            placement="top-start"
            transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    {data.notifications.map((n,i) => (
                      <NotificationItem key={n._id} isFirst={i === 0} notification={n} dismiss={data.dismiss} />
                    ))}
                  </Paper>
                </Fade>
              )}
          </Popper>}
      </>
    )
    : null;
}
