import React from 'react';
import { EditItemProps } from '../../api/useNewItem';
import { QueryChunk, QueryChunkItem, QueryChunkParamConfig } from './types';
import { ButtonOptionPicker, Buttons, FormGrid, OccupyFreeSpace, OptionsColorPicker } from '../primitives';
import { FormattedMessage } from 'react-intl';
import { CompositeQueryChunkEditor } from './CompositeQueryChunkEditor';
import { QueryChunkQueryEditor } from './QueryChunkQueryEditor';
import { FieldType, Schema } from '../../hooks/useSchema';
import { useItemWithControls } from '../../api/useWithControls';
import { Button, Typography } from '@mui/material';
import { QueryChunkParamsConfigEditor } from './QueryChunkParamsConfigEditor';
import { Add } from '@mui/icons-material';
import { generateCode } from '../PowerDoc/plugins/common';

interface Props {
  data: EditItemProps<QueryChunk>;
}

const QueryChunkSchema: Schema = {
  _id: { label_id: "dataschema.query_chunks.chunk_config._id_label" },
  title: { label_id: "dataschema.query_chunks.chunk_config.title" },
  description: { type: FieldType.textlong, label_id: "dataschema.query_chunks.chunk_config.description" },
  color: { label_id: "dataschema.query_chunks.chunk_config.color" },
}

const ParamsCatcherRegex = /::([^\s]+)::/gi;

const collectParams = (chunk: QueryChunk): QueryChunkParamConfig[] => {
  const fullText = `
  ${chunk.select?.join(" ") || ""}
  ${chunk.from || ""}
  ${chunk.where?.join(" ") || ""}
  ${chunk.join?.map(j => `${j.table} ${j.on}`)?.join(" ") || ""}
  ${chunk.group?.join(" ") || ""}
  ${chunk.having?.join(" ") || ""}
  ${chunk.where?.join(" ") || ""}
  ${chunk.order?.join(" ") || ""}
  ${chunk.tail?.join(" ") || ""}
  ${(chunk.chunks || []).map(x => Object.values(x.params || {}).join(" ")).join(" ")}
  `;

  const params = Array.from(fullText.matchAll(ParamsCatcherRegex));
  const codes = params.map(([_,x]) => x);
  const codesUnique: string[] = [];
  codes.forEach(c => {
    const cx = c.toLowerCase();
    if(codesUnique.indexOf(cx) < 0) {
      codesUnique.push(cx);
    }
  });

  const existing = chunk.params_config || [];
  const codesUnused = codesUnique.filter(c => !existing.find(p => p.code.toLowerCase() === c));

  return codesUnused.map(code => ({ xid: generateCode(), code, default: code }));
}

export const QueryChunkEditForm = (props: Props) => {
  const { item, update } = props.data;
  const { controls } = useItemWithControls({ data: item || {} as QueryChunk, update }, { schema: QueryChunkSchema });

  return (
    <FormGrid columns="1fr" noMargin>
      <FormGrid columns="1fr 130px" noMargin>
        {controls([
          ["_id", { controlProps: { autoFocus: true } }],
        ])}

        <OptionsColorPicker
          value={item?.color}
          update={v => update({ color: v })}
          label={<FormattedMessage id={QueryChunkSchema.color.label_id} />}
          />
      </FormGrid>

      {controls([
        ["title"],
        ["description"],
      ])}
      
      <Buttons style={{ marginTop: "2rem" }}>
        <Typography style={{ fontWeight: 500 }}><FormattedMessage id="dataschema.query_chunks.chunk_config.query" /></Typography>
        <OccupyFreeSpace />

        <ButtonOptionPicker
          options={[
            [<FormattedMessage id="dataschema.query_chunks.chunk_config.mode.common" />, "common"],
            [<FormattedMessage id="dataschema.query_chunks.chunk_config.mode.composite" />, "composite"],
          ]}
          selected={item?.composite ? "composite" : "common"}
          setSelected={v => update({ composite: v === "composite" })}
          />
      </Buttons>

      {item?.composite &&
        <CompositeQueryChunkEditor
          data={item as QueryChunkItem}
          update={update}
          />}
      
      {!!item && !item?.composite &&
        <QueryChunkQueryEditor
          item={item}
          update={update}
          />}

      
      <Buttons style={{ marginTop: "2rem" }}>
        <Typography style={{ fontWeight: 500 }}><FormattedMessage id="dataschema.query_chunks.chunk_config.params_config.section_title" /></Typography>
        <OccupyFreeSpace />

        <Button size="small" onClick={() => !!item && update({ params_config: [ ...(item?.params_config || []), ...collectParams(item)] })}>
          <FormattedMessage id="dataschema.query_chunks.chunk_config.params_config.collect" />
        </Button>

        <Button size="small" startIcon={<Add />} onClick={() => update({ params_config: [...(item?.params_config || []), { xid: generateCode(), code: "", default: "" }]})}>
          <FormattedMessage id="dataschema.query_chunks.chunk_config.params_config.add" />
        </Button>
      </Buttons>

      {!!item &&
        <QueryChunkParamsConfigEditor
          item={item}
          update={update}
          />}
    </FormGrid>
  );
}
