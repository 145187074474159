import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material"
import { Rate, useCurrencyRates } from "./useCurrencyRates";
import { TableForFields } from "../schemed/TableForFields";
import { FieldType } from "../../hooks/useSchema";
import { FormControl } from "../schemed";
import { FormattedMessage } from "react-intl";

interface Props {
    code: string | null;
    close: (rate?: string) => void;
    defaultDate?: string | null;
}

export const CurrencyPopup = ({ code, close, defaultDate }: Props) => {
    const [date, setDate] = useState<string | null>(null);
    const rates = useCurrencyRates(code, date);
    const [selected, setSelected] = useState<Rate | null>(null);

    useEffect(() => {
        setDate(defaultDate || null);
    }, [defaultDate])

    return <Dialog onClose={() => close()} open={!!code} fullWidth maxWidth="sm">
        <DialogTitle><FormattedMessage id="currency_rates.title" /></DialogTitle>
        <DialogContent>
            <FormControl
                row={{ date }}
                field="date"
                schema={{ type: FieldType.date, label_id: "currency_rates.date" }}
                onChange={(o,c) => setDate(c.date)}
            />
            <TableForFields
                fields={[["date"], ["rate"]]}
                schema={{
                    date: {
                        type: FieldType.date,
                        label_id: 'currency_rates.date',
                    },
                    rate: {
                        type: FieldType.decimal,
                        label_id: 'currency_rates.rate',
                    }
                }}
                data={rates}
                selected={({date}) => date === selected?.date}
                onRowClick={setSelected}
                onDblClick={({rate}) => close(rate)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => close()}><FormattedMessage id="common.cancel" /></Button>
            <Button disabled={!selected} onClick={() => close((selected as Rate).rate)}><FormattedMessage id="common.select" /></Button>
        </DialogActions>
    </Dialog>
}