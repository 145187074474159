import React from 'react';
import { useParams } from 'react-router-dom';
import { useTrackHistory } from '../HistoryFavorites';
import { useDocumentationPages } from './DocumentationPagesContext';
import { DocumentationPageUnbound } from './DocumentationPageUnbound';
import { useDocumentationPage } from './useDocumentationPages';

export const DocumentationPage = () => {
    const { id } = useParams<{ id: string }>();
    
    const { config } = useDocumentationPages();

    const data = useDocumentationPage(config.apiPath, id);
    useTrackHistory(data.data.title);

    return (
        <DocumentationPageUnbound {...config} data={data} />
    );
}
