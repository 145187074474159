import React, { useCallback, useState } from "react";
import { useDialogState, useItemDialogState } from "../../../primitives";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { Editor, Transforms, Element } from "slate";
import { ImageElement, ImageElementType as ImageElementTypeC } from "./ImageElement";
import { PhotoOutlined } from "@mui/icons-material";
import { useMediaLibContext } from "../../../medialib";
import { EditImageCommand, InlineImageProperties } from "./types";
import { MediaLibPickerDialog } from "../../../medialib/MediaLibPickerDialog";
import { EditImageDialog } from "./EditImageDialog";

export const ImageElementType = ImageElementTypeC;

export const insertImage = (editor: Editor, item: InlineImageProperties) => {
    const image = {
        ...item,
        type: ImageElementType,
        children: [{ text: "" }],
    }
    
    Transforms.insertNodes(editor, image)
}

export const updateImage = (editor: Editor, item: InlineImageProperties) => {
    const itemCopy: InlineImageProperties = {
        url: item.url,
        _class: item._class,
        width: item.width,
        height: item.height,
    }
    

    Transforms.setNodes(
        editor,
        itemCopy,
        {
            match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === ImageElementType,
            voids: true
        },
    )
}


export const withImages = (editor: Editor) => {
    const { isInline, isVoid } = editor;
    editor.isInline = element => element.type === ImageElementType ? true : isInline(element);
    editor.isVoid = element => element.type === ImageElementType ? true : isVoid(element);
    return editor;
}

interface EditingContext {
    editor: Editor;
}

export const useInlineImagesPlugin = (): EditorPlugin => {
    const [editingContext, setEditingContext] = useState<EditingContext | null>(null);
    const insertImageDialogState = useItemDialogState<InlineImageProperties>();
    const replaceImageDialogState = useDialogState();
    const editImageDialogState = useItemDialogState<Element & InlineImageProperties>();
    const mediaLib = useMediaLibContext();


    const startEdit = useCallback(
        (editor: Editor, e?: Element) => {
            if(e?.type !== ImageElementType) {
                setEditingContext({ editor });
                insertImageDialogState.open({ });
            } else {
                setEditingContext({ editor });
                editImageDialogState.open(e);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const dialog = (<>
        <EditImageDialog
            {...editImageDialogState}
            openImagePicker={() => replaceImageDialogState.open()}
            updateImageSettings={settings => {
                if(editingContext?.editor) {
                    updateImage(editingContext.editor, settings)
                }
            }}
            />
        {replaceImageDialogState.isOpen
            ? <MediaLibPickerDialog
                {...replaceImageDialogState}
                selectFile={mf => editingContext?.editor && editImageDialogState.isOpen &&
                    editImageDialogState.update({ url: mediaLib.getFilepath(mf) })}
                closeOnSelect
                selectedUrls={[]}
                />
            : <MediaLibPickerDialog
                {...insertImageDialogState}
                selectFile={mf => editingContext?.editor && insertImage(editingContext?.editor, { ...insertImageDialogState.item, url: mediaLib.getFilepath(mf) })}
                closeOnSelect
                selectedUrls={[]}
                />}
    </>);

    return {
        key: "images",
        inject: e => withImages(e),
        customBlocks: { [ImageElementType]: ImageElement },
        dialogs: dialog,
        commands: [{
            isAvailable: () => !!mediaLib.isAvailable,
            name: "insert-image",
            invoke: editor => startEdit(editor),
            menu: { section: "insert-item", label: "Image", label_id: "powerdoc.plugins.images.title", icon: <PhotoOutlined /> },
        }, {
            name: EditImageCommand,
            isInternal: true,
            invoke: (editor, element) => startEdit(editor, element),
        }],
    };
}
