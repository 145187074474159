import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { openFileUploader } from '../../toolympus/api/files';
import { ClipboardButton, SaveButton } from '../../toolympus/components/primitives/Buttons';
import { Form, FormGrid } from '../../toolympus/components/primitives/Forms';
import { FormControlsForFields, FieldDefinition, TableForFields } from '../../toolympus/components/schemed';
import { FieldType, useSchema } from '../../toolympus/hooks/useSchema';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { PracticeItemData } from './practiceData';
import { Close, Check, DeleteOutlined, CallMadeOutlined } from '@mui/icons-material';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { DeleteButton } from '../../toolympus/components/primitives/DeleteButton';
import { Comments } from '../Comments';
import { Link } from 'react-router-dom';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';

interface Props {
    data: PracticeItemData;
}

interface DocumentProps extends Props {
    file_url?: string | null;
    lang: string;
}

const DocWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    & > :first-child {
        min-width: 125px;
    }
`;

const DocumentControl = ({ lang, file_url, data }: DocumentProps) => {
    const { uploadDocument, deleteDocument, isDocumentUploading } = data;
    return (<DocWrapper>
        <Typography component="span" >Документ {lang ? `(${lang})` : ''} </Typography>
        {file_url ?
            <>
                <Check />
                <ClipboardButton value={`https://files.modernarbitration.ru/${file_url}`} />
            </> :
            <Close />}
        <Button color="primary" onClick={() => openFileUploader(f => uploadDocument(f, lang))}>
            {file_url ? "заменить " : "загрузить "}{isDocumentUploading && <LoadingIndicator sizeVariant="s" />}
        </Button>
        {file_url && (
            <IconButton size="small" onClick={() => deleteDocument(lang)}>
                <DeleteOutlined />
            </IconButton>
        )}
    </DocWrapper>);
}

const ArbitratorsConsentTable = ({ data }: Props) => {
    const { data: practice, update } = data;

    const updateConsent = (id: string, consent: boolean) => {
        update({
            arbitrators_consent: {
                ...practice.arbitrators_consent,
                [id]: {
                    ...practice.arbitrators_consent[id],
                    is_available: consent,
                }
            }
        });
    }

    const tabled = Object.values((practice.arbitrators_consent || {})).sort((a,b) => (a.name || "") > (b.name || "") ? 1 : -1);

    return practice._id ? (
        <TableForFields
            dense
            fields={[
                ["is_available", { editable: true }],
                ["name"],
            ]}
            schema={{
                is_available: { type: FieldType.bool, label: "Согласен" },
                name: { type: FieldType.text, label: "Арбитр" },
            }}
            data={tabled}
            onChange={(o,c) => updateConsent(o._id, c.is_available)}
            fieldLink={f => f === "name" ? (r => `/arbitrator/${r._id}`) : null}
            />
    ) : null;
}

export const PracticeForm = ({ data }: Props) => {
    const { data: practice, update, save, remove, isUpdated, isLoading } = data;
    const { practice: schema } = useSchema();

    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            fields={fields}
            data={practice}
            schema={schema}
            onChange={(_,changes) => update(changes)} />
    )

    useSaveable({ save, hasChanges: isUpdated });

    return (
        <Form
            title="Практика"
            headerItems={<>
                {isUpdated && <SaveButton action={() => save()} />}
                {isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />
                {practice._id && <Comments entity="practice" recordId={practice._id} />}
                <DeleteButton title="Удалить запись?" remove={remove} />
                </>}>
            <FormGrid columns="minmax(0,2fr) 2fr 3fr">
                <FormGrid columns="1fr">
                    {controls([
                        ["document_type"],
                        ["document_date"],
                    ])}
                    {controls([
                            ["is_published"],
                        ])}
                </FormGrid>
                <FormGrid columns="1fr">
                  <FormGrid noMargin columns="1fr 1fr">
                    {controls([
                      ["practice_casenbr", {
                        readOnly: true,
                        label: "Номер для практики",
                        controlProps: {
                          style: { color: "#cc0000" },
                          value: practice.practice_casenbr || "без номера",
                          endAdornment: practice.is_published
                            ? <InputAdornment position="end">
                                <a href={`https://centerarbitr.ru/practice?id=${practice.short_id}&page-size=1`} target="_blank" rel="noopener noreferrer"><IconButton size="small"><CallMadeOutlined /></IconButton></a>
                              </InputAdornment>
                            : null
                          }
                      }],
                      ["case_casenbr", {
                        readOnly: true,
                        controlProps: {
                          endAdornment: practice.case_id
                            ? <InputAdornment position="end">
                                <Link style={{ color: "inherit"}} to={`/case/${practice.case_id}`}><IconButton size="small"><CallMadeOutlined /></IconButton></Link>
                              </InputAdornment>
                            : null
                          }
                      }],
                    ])}
                  </FormGrid>

                  <DocumentControl data={data} file_url={data.data.file_url} lang="ru" />
                  <DocumentControl data={data} file_url={data.data.file_url_en} lang="en" />
                </FormGrid>
                <div>
                    <ArbitratorsConsentTable data={data} />
                </div>
            </FormGrid>
            
            <FormGrid columns="repeat(4,1fr)" forceEvenColumns>
                {controls([
                    ["procedure"],
                    ["rules"],
                    ["rules_edition"],
                    ["case_area", { disabled: true }],
                    ["place_country"],
                    ["place_city"],
                    ["place_city_en"],
                ])}
            </FormGrid>
            
            <FormGrid columns="1fr">
                {controls([
                        ["other_info"],
                        ["description", { autoRows: true }],
                        ["description_en", { autoRows: true }],
                    ])}
            </FormGrid>
        </Form>);
}
