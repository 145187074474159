import { IconButton } from '@mui/material';
import { EditOutlined, RefreshOutlined, WarningOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { Alert, AlertColor } from '../../../toolympus/components/primitives/Alert';
import { ConfirmationDialog } from '../../../toolympus/components/primitives/ConfirmationDialog';
import { Tooltip } from '../../../toolympus/components/primitives/Tooltip';
import { FieldDefinition, TableForFields, TableRowButtons } from '../../../toolympus/components/schemed';
import { useDictionaries } from '../../../toolympus/hooks/useDictionaries';
import { mergeSchema, useSchema } from '../../../toolympus/hooks/useSchema';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { EditResponseDialog } from './EditResponseDialog';
import { getResponseType, useEditResponse } from './useEditResponse';
import { ArbitratorSelection } from './usePresidiumQuestionArbitratorsSelection';
import { Option } from './usePresidiumQuestions';
import { PresidiumQuestionResponses, PresidiumResponse } from './usePresidiumResponses';

interface Props {
    data: PresidiumQuestionResponses;
    isCandidatesQuestion?: boolean;
    candidates: ArbitratorSelection[];
    options: Option[];
}

export const PresidiumQuestionResponsesList = ({ data, isCandidatesQuestion, candidates, options }: Props) => {
    const { presidium_response: schema } = useSchema();

    const editResponse = useEditResponse(data, isCandidatesQuestion || false, candidates, options || []);
    const [cancelResponse, setCancelResponse] = useState<PresidiumResponse | null>(null);

    const dicts = useDictionaries();
    const presidiumMembers = dicts["PresidiumMember"];

    const getResultDescription = (r: PresidiumResponse) => {
        const responseType = getResponseType(r);
        switch(responseType) {
            case "is_abstained":
                return "Воздержался";
            case "is_against_all":
                return "Против всех";
            case "is_conflict":
                return `Конфликт: ${r.comment}`;
            case "vote":
                return <>
                    {isCandidatesQuestion
                        ? <span>
                            Проголосовал: {(r.response?.vote || []).map(cid => candidates.find(c => c._id === cid)?.lastname || '"').join(", ")}
                            {!!r.response?.candidates_conflict?.length && <br />}
                            {!!r.response?.candidates_conflict?.length && `Конфликты: ${(r.response?.candidates_conflict || []).map(cid => candidates.find(c => c._id === cid)?.lastname || "").join(", ")}`}
                          </span>
                        : `Проголосовал: ${options.find(o => o.id === r.response?.option?.id)?.label || "-"}`}
                    {r.comment && <br />}
                    {r.comment && <span style={{ fontStyle: "italic"}}>{r.comment}</span>}
                </>
            default:
                return "";
        }
    }

    const changedByAdmin = data.data.filter(r => r.is_last_modified_by_admin);
    const notVoted = data.data.filter(r => !r.is_voted)
        .map(r => presidiumMembers.valueDict[r.member_id])
        .sort();
    
    const user = useUser();
    const isManager = (user.user?.roles || []).includes("manager");

    return (
        <>
            {changedByAdmin.length > 0 && (
                <Alert
                    key="has_changed_by_admin"
                    icon={<WarningOutlined htmlColor={AlertColor.warning} />}
                    title="Есть ответы измененные аппаратом"
                    />)}
            {notVoted.length > 0 && (
                <Alert
                    key="has_not_voted"
                    icon={<WarningOutlined htmlColor={AlertColor.error} />}
                    title="Еще не проголосовали"
                >
                    <ul>
                        {notVoted.map(pm => <li key={pm}>{pm}</li>)}
                    </ul>
                </Alert>
            )}
            <TableForFields
                data={data.data}
                schema={mergeSchema(schema, { result: { label: "Результат" }})}
                fields={[
                    ["member_id"],
                    ["result"],
                    ...(isManager ? [["is_last_modified_by_admin"]] as FieldDefinition[] : []),
                ]}
                rowButtons={r => data.canEdit && (
                    <TableRowButtons>
                        {isManager && <Tooltip key="edit" text="Изменить ответ">
                            <IconButton size="small" onClick={() => editResponse.startEdit(r)}>
                                <EditOutlined />
                            </IconButton>
                        </Tooltip>}
                        <Tooltip key="cancel" text="Очистить ответ">
                            <IconButton size="small" onClick={() => setCancelResponse(r)}>
                                <RefreshOutlined />
                            </IconButton>
                        </Tooltip>
                    </TableRowButtons>
                )}
                fieldElement={f => {
                    if(f === "result") {
                        return r => getResultDescription(r);
                    }
                }}
                rowStyle={r => r.is_voted ? { background: "#00cc0020" } : undefined}
                />
            
            <EditResponseDialog data={editResponse} />

            <ConfirmationDialog
                isOpen={!!cancelResponse}
                close={() => setCancelResponse(null)}
                title="Отменить ответ?"
                hint="Ответ будет удален, вопрос снова станет доступным для голосвания члену президиума"
                execute={() => cancelResponse && data.cancelResponse(cancelResponse.member_id)}
                />
        </>
    );
}
