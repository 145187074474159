import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Paper,
    TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import { ArrowDownward, ArrowLeft, ArrowRight, ArrowUpward, Settings } from '@mui/icons-material';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGrid } from '../primitives/Forms';
import { Tooltip } from '../primitives/Tooltip';
import { useTextFilter } from './Filtering/useTextFilter';
import { FieldsSettings } from './useFields';

interface Props {
    fieldsSettings: FieldsSettings;
}

interface DialogProps extends Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
}

const Title = styled(Typography)`
  margin: ${props => props.theme.spacing(0, 2)};
`;

const ListItemStyled = styled(ListItem)`
  padding-right: 96px;
`;

export const FieldSettingsPopup = ({ isOpen, setIsOpen, fieldsSettings }: DialogProps) => {
    const { includedFields, excludedFields, schema, extraSettings } = fieldsSettings;

    const { formatMessage } = useIntl();

    const fieldLabel = (f: string): string =>
        (extraSettings || {})[f]?.label
        || (schema[f]?.label_id && formatMessage({ id: schema[f].label_id}))
        || schema[f]?.label as string
        || f;

    const filter = useTextFilter<string>(f => `${fieldLabel(f)} ${f}`);

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth={"md"} PaperProps={{ style: { minHeight: "calc(100% - 64px)" }}} fullWidth>
            <DialogTitle>
                <Typography variant="h6"><FormattedMessage id="fieldssettings.title" /></Typography>
            </DialogTitle>
            <DialogContent>
                <FormGrid columns="1fr 1fr" noMargin style={{ rowGap: '1rem' }}>
                    <TextField
                        label={<FormattedMessage id="searchField.label" />}
                        onChange={e => filter.setFilter(e.target.value || "")}
                        value={filter.filter}
                        style={{ gridColumn: "1 / span 2"}}
                        autoFocus
                        onKeyPress={e => {
                          if(e.key === "Enter") {
                            const excl = filter.filterData(excludedFields);
                            if(excl.length === 1) {
                              fieldsSettings.setFieldActive(excl[0], true);
                            }
                          }
                        }}
                        />
                    
                    <Paper>
                        <Title variant="caption"><FormattedMessage id="fieldssettings.available" /></Title>
                        <List dense role="list">
                            {filter.filterData(excludedFields).map(f => (
                                <ListItem>
                                    <ListItemText primary={fieldLabel(f)} secondary={f !== fieldLabel(f) ? f : undefined} />
                                    <ListItemSecondaryAction>
                                        <IconButton size="small" edge="end" aria-label="include" onClick={() => fieldsSettings.setFieldActive(f, true)}>
                                            <ArrowRight />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                    <Paper>
                        <Title variant="caption"><FormattedMessage id="fieldssettings.active" /></Title>
                        <List dense role="list">
                            {filter.filterData(includedFields).map(f => (
                                <ListItemStyled dense>
                                    <ListItemIcon>
                                        <IconButton size="small" aria-label="exclude" onClick={() => fieldsSettings.setFieldActive(f, false)}>
                                            <ArrowLeft />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText primary={fieldLabel(f)} secondary={f !== fieldLabel(f) ? f : undefined} />
                                    <ListItemSecondaryAction>
                                        <IconButton size="small" edge="end" aria-label="include" onClick={() => fieldsSettings.moveFieldUp(f)}>
                                            <ArrowUpward />
                                        </IconButton>
                                        <IconButton size="small" edge="end" aria-label="include" onClick={() => fieldsSettings.moveFieldDown(f)}>
                                            <ArrowDownward />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItemStyled>
                            ))}
                        </List>
                    </Paper>
                </FormGrid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fieldsSettings.resetToDefaults()}><FormattedMessage id="fieldssettings.reset" /></Button>
                <Button color="primary" onClick={() => setIsOpen(false)}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>
    );
}

export const FieldSettingsPopupButton = (props: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <>
        <Tooltip text_id="fieldssettings.title">
            <IconButton size="small" onClick={() => setIsOpen(true)}>
                <Settings />
            </IconButton>
        </Tooltip>
        <FieldSettingsPopup isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
    </>;
}
