import { useSettings } from "../../toolympus/api/useSettings"

export interface PartiesPollsPrepSettings {
  poll_after_case_finished_days?: number;
  poll_prevent_repeat_days?: number;
  poll_request_email_template?: string;
}

export const usePartiesPollsPrepSettings = () => {
  const data = useSettings<PartiesPollsPrepSettings>("/api/party-poll/settings", {});

  return data;
}
