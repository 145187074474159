import React, { PropsWithChildren, useContext, useMemo, useState } from "react";
import { useLoadedData } from "../../hooks/useLoadedData";
import { DefaultUser, useUser } from "../../userContext/UserContext";
import { apiFetch } from "../../api/core";
import { useItemActionWithConfirmation } from "../../api/useAction";

export interface UserSettingsConfig {
  noUser?: boolean;
  noGlobal?: boolean;
  autoLoad?: boolean;
  canUpdateGlobal?: (user: DefaultUser | undefined) => boolean;
}

export type SettingsT = Record<string, any>;

export interface IUserSettingsContext {
  isAvailable: boolean;
  canUpdateGlobal: boolean;
  
  isLoadingGlobal: boolean;
  isLoadingUser: boolean;

  updateUser: (changes: SettingsT) => Promise<SettingsT>;
  updateGlobal: (changes: SettingsT) => Promise<SettingsT>;

  user: SettingsT;
  global: SettingsT;

  ensureLoaded: () => void;
}

export const useUserSettingsData = (apiPath: string, cfg: UserSettingsConfig): IUserSettingsContext => {
  const [isLoadRequested, setIsLoadRequested] = useState<boolean>(cfg.autoLoad || false);
  const userData = useLoadedData<SettingsT>(`${apiPath}/my`, {}, isLoadRequested && !cfg.noUser);
  const globalData = useLoadedData<SettingsT>(`${apiPath}/global`, {}, isLoadRequested && !cfg.noGlobal);

  const { user } = useUser();
  const canUpdGlobalFn = cfg.canUpdateGlobal;

  const updateUserAction = useItemActionWithConfirmation<SettingsT, SettingsT>(
    changes => apiFetch<SettingsT>(`${apiPath}/my`, "put", changes)
      .then(updated => {
        userData.setData(updated);
        return updated;
      }), {
        skipConfirmation: true,
      }
  );

  const updateGlobalAction = useItemActionWithConfirmation<SettingsT, SettingsT>(
    changes => apiFetch<SettingsT>(`${apiPath}/global`, "put", changes)
      .then(updated => {
        globalData.setData(updated);
        return updated;
      }), {
        skipConfirmation: true,
      }
  );
  
  const value: IUserSettingsContext = useMemo(() => {
    const canUpdateGlobal = canUpdGlobalFn ? canUpdGlobalFn(user) : false;

    return {
      isAvailable: true,
      canUpdateGlobal,
      ensureLoaded: () => setIsLoadRequested(true),
      
      isLoadingGlobal: globalData.isLoading || updateGlobalAction.isRunning,
      isLoadingUser: userData.isLoading || updateUserAction.isRunning,
      
      global: globalData.data,
      user: userData.data,
  
      updateGlobal: c => updateGlobalAction.run(c),
      updateUser: c => updateUserAction.run(c),
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalData.data, globalData.isLoading, userData.data, userData.isLoading, updateGlobalAction.isRunning, updateUserAction.isRunning, user, canUpdGlobalFn])

  return value;
}


export const UserSettingsContext = React.createContext<IUserSettingsContext>({
  isAvailable: false,
  canUpdateGlobal: false,
  ensureLoaded: () => {},
  
  isLoadingGlobal: false,
  isLoadingUser: false,
  
  global: {},
  user: {},

  updateGlobal: (c) => Promise.resolve(c),
  updateUser: (c) => Promise.resolve(c),
});

export const useUserSettings = () => useContext(UserSettingsContext);


export const UserSettingsProvider = (props: PropsWithChildren<UserSettingsConfig & { apiPath: string }>) => {
  const data = useUserSettingsData(props.apiPath, props);

  return (
    <UserSettingsContext.Provider value={data}>
      {props.children}
    </UserSettingsContext.Provider>
  )
}