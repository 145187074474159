import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react"

export const useThrottledState = <T,>(dflt: T, throttleDelay: number = 50) => {
  const [state, setState] = useState<T>(dflt);
  const ref = useRef<T>(dflt);
  const timeout = useRef<any>(null);

  const setStateX = useCallback((newState: T) => {
    if(timeout.current) {
      clearTimeout(timeout.current);
    }
    ref.current = newState;
    timeout.current = setTimeout(() => { setState(ref.current) }, throttleDelay);
  }, [throttleDelay]);

  return [
    state,
    setStateX,
  ] as [T,(x:T) => void];
}

export const useThrottledCapture = <T,>(source: T, throttleDelay: number = 200): T => {
  const [double, setDouble] = useState<T>(source);
  useEffect(() => {
    const timeout = setTimeout(() => setDouble(source), throttleDelay);
    return () => clearTimeout(timeout);
  }, [source, throttleDelay]);

  return double;
}

export const useThrottledDoubleState = <T,>(dflt: T, throttleDelay: number = 200): [T, Dispatch<SetStateAction<T>>, T] => {
  const [state, setState] = useState<T>(dflt);
  const double = useThrottledCapture(state, throttleDelay);
  
  return [state, setState, double];
}
