import React, { useMemo } from 'react';
import { LoadedData, PagedData } from '../../hooks/useLoadedData';
import { NumberingSequenceEvent } from './types';
import { FieldType, Schema, mergeSchema } from '../../hooks/useSchema';
import { EmptyState, LoadingIndicator } from '../primitives';
import { TableForFields } from '../schemed';
import { useIntl } from 'react-intl';

interface Props {
  data: LoadedData<PagedData<NumberingSequenceEvent>>;
  usersDictionary?: string;
}

const EventSchema: Schema = {
  description: { label_id: "numbering.event.description" },
  created_by: { label_id: "numbering.event.created_by" },
  created_at: { label_id: "numbering.event.created_at", type: FieldType.datetime },
}

const describeEvent = (message: (id: string, params?: any) => string, e: NumberingSequenceEvent) => {
  const type = message(`numbering.event.event_types.${e.event_type}`);
  const details = e.event_type === "generated"
    ? e.event_data?.formatted || "..."
    : Object.keys(e.event_data?.changes).reduce<string[]>((r,k) => { r.push(`${message(`numbering.${k}`)} ${(e.event_data?.original || {})[k] || ""} → ${e.event_data?.changes[k]}`); return r; }, []).join(", ");
  return message("numbering.event.description_base", { type, details });
}

export const NumberingSequenceEvents = (props: Props) => {
  const { data } = props;

  const { formatMessage } = useIntl();

  const items = useMemo(() => {
    return data.data.data.map(e => ({ ...e, description: describeEvent((id,params) => formatMessage({ id }, params), e) }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  const schema = useMemo(
    () => mergeSchema(EventSchema, props.usersDictionary ? { created_by: { type: FieldType.dictionarySelect, dictionary: props.usersDictionary }} : {}),
    [props.usersDictionary]);


  return (
    data.isLoading && items.length === 0
      ? <EmptyState text={<LoadingIndicator sizeVariant="s" />} />
      : <TableForFields
          data={items}
          schema={schema}
          fields={[
            ["created_at", { utcToLocal: true }],
            ["description"],
            ["created_by"],
          ]}
        />
  );
}
