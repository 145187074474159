import { useMemo } from "react";
import { apiFetch } from "../../api/core";
import { useCrudItem } from "../../api/useSimpleCrud";
import { useLoadedData } from "../../hooks/useLoadedData";
import { mergeSchema } from "../../hooks/useSchema";
import { ProgramDataSchema, ProgramOperationSchema, ProgramSchema } from "./schema";
import { Program, ProgramOperation } from "./types";
import { useProgramExecutions } from "./useProgramExecutions";
import { useTriggers } from "./useTriggers";

const useEditOperations = ({ data, update }: { data: Program, update: (c: Partial<Program>) => void }) => {
    const operations = data.operations || [];
    const addOperation = () => {
        update({ operations: [ ...operations, { kind: "sql", script: "" }]});
    }

    const updateOperation = (idx: number, changes: Partial<ProgramOperation>) => {
        if(changes.user_email !== null && changes.user_email !== undefined) {
            changes.user = { email: changes.user_email };
        }
        update({ operations: operations.map((op,i) => i === idx ? {
            ...op, ...changes,
        } : op)})
    }

    const removeOperation = (idx: number) => {
        update({ operations: operations.filter((op,i) => i !== idx) });
    }

    return {
        operations,
        addOperation,
        updateOperation,
        removeOperation,
    }
}

export const useProgram = (apiPath: string, id: string | number) => {
    const programApiPath = `${apiPath}/program`;
    const data = useCrudItem<Program>(`${programApiPath}/${id}`, {
        defaultValue: {} as Program,
    });

    const { data: datasources } = useLoadedData<{ datasources?: { custom?: string[] }, operations?: { custom?: { code: string, label: string }[] }}>(`${apiPath}/config`, {});

    const [dataSchema, operationsSchema] = useMemo(() => {
      const customSources = datasources.datasources?.custom || [];
      const dataSchema =  customSources.length
      ? mergeSchema(ProgramDataSchema, {
        kind: { values: [ ...(ProgramDataSchema.kind.values || []), { value: "custom", label: "Custom" } ]},
        name: { values: customSources.map(name => ({ value: name, label: name })) },
      })
      : ProgramDataSchema;

      const customOperations = datasources.operations?.custom || [];
      const operationsSchema =  customOperations.length
      ? mergeSchema(ProgramOperationSchema, {
        kind: { values: [ ...(ProgramOperationSchema.kind.values || []), { value: "custom", label: "Custom" } ]},
        name: { values: customOperations.map(o => ({ value: o.code, label: o.label })) },
      })
      : ProgramOperationSchema;

      return [dataSchema, operationsSchema]
    }, [datasources]);

    const copyItem = () => {
        return apiFetch<Program>(
            `${programApiPath}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                data: data.data.data,
                operations: data.data.operations,
            });
    }

    const operations = useEditOperations(data);
    const triggers = useTriggers(programApiPath, data.data);
    const executions = useProgramExecutions(apiPath, data.data._id);

    return {
        ...data,
        schema: ProgramSchema,
        copyItem,
        operations,
        triggers,
        executions,
        dataSchema,
        operationsSchema,
    }
}
