import { useState } from "react";

const AnyEvent = "*";
type AnyEventT = "*";

export interface EventBus<EventType extends string> {
    subscribe: (et: EventType | AnyEventT, handler: (et: EventType | AnyEventT, data?: any) => void) => number;
    unsubscribe: (subscriberId: number) => void;
    dispatch: (et: EventType, data?: any) => void;
}

interface Subscriber<EventType extends string> {
    etype: EventType | AnyEventT;
    id: number;
    handler: (et: EventType | AnyEventT, data?: any) => void;
}

export const useEventBus = <EventType extends string>(): EventBus<EventType> => {
    const [subscribers, setSubscribers] = useState<Subscriber<EventType>[]>([]);

    const dispatch: EventBus<EventType>["dispatch"] = (et: EventType, data?: any) => {
        subscribers
            .filter(s => s.etype === et || s.etype === AnyEvent)
            .forEach(s => s.handler(et, data));
    }

    const subscribe: EventBus<EventType>["subscribe"] = (et, handler) => {
        const id = Math.floor(1e6 * Math.random());
        setSubscribers(ss => ([ ...ss, { etype: et, handler, id }]));
        return id;
    }

    const unsubscribe: EventBus<EventType>["unsubscribe"] = (id) => {
        setSubscribers(ss => ss.filter(s => s.id === id));
    }

    return {
        dispatch,
        subscribe,
        unsubscribe,
    }
}