import React, { useState } from 'react';
import styled from '@emotion/styled';
import { HistoryFavoritesData, HistoryItem as HI } from './useHistoryFavoritesMain';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import BookmarkIcon from './bookmark.svg';
import { FormattedMessage } from 'react-intl';

interface Props {
    data: HistoryFavoritesData;
}

const HistoryItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;

    & a {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
    }

    & .fav {
        visibility: hidden;
        transform: scale(0.75);
    }

    &:hover {
        & .fav {
            visibility: visible;
        }
    }
`;

const Separator = styled.div`
    height: 1px;
    border-bottom: 1px dotted #cccccc;
    margin: 0.5rem 0;
`;

const Toggle = styled.div`
    position: absolute;
    top: 100%;
    left: 10%;
    height: 30px;
    opacity: 0.8;
    width: 20px;
    background-color: ${props => props.theme.palette.primary.main};
    mask: url(${BookmarkIcon});
    mask-position: bottom;
    mask-size: cover;
    cursor: pointer;

    transition: all 0.1s linear;

    z-index: 1990;
    transform: translateY(-50%);

    &:hover {
        transform: translateY(0);
        opacity: 1;
    }
`;
Toggle.defaultProps = { role: "button" };

const HistoryFavoritesPanelInner = styled.div`
    width: 100%;
    position: relative;
    z-index: 2000;


    padding: 0.5rem;

    display: flex;
    flex-flow: column;

    background: ${props => props.theme.palette.background.paper};
    box-shadow: 0 0 8px -4px #33333380;
    border-radius: ${props => props.theme.shape.borderRadius}px;
`;

export const HistoryFavoritesPanelWrapper = styled.div`
    position: fixed;
    z-index: 2000;
    right: 1rem;
    width: 300px;
    top: 0;

    &.closed {
        transform: translate(0,-100%);
    }
    
    transition: all 0.3s ease;
`;

const HistoryLink = (props: HI) => {
    const titleMsg = props.title as { id: string, values?: Record<string, any>};
    
    return <Link to={props.location}>
        {titleMsg.id
            ? <FormattedMessage id={titleMsg.id} values={titleMsg.values} />
            : props.title}
    </Link>
}

export const HistoryFavoritesPanel = (props: Props) => {
    const { data } = props;
    const { history, favorites } = data.history;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <HistoryFavoritesPanelWrapper className={`history-favorites-panel ${isOpen ? "open" : "closed"}`}>
            <Toggle className="toggle" onClick={() => setIsOpen(x => !x)} />

            <HistoryFavoritesPanelInner
                className="history-favorites-panel-inner"
                onMouseLeave={() => setIsOpen(false)}>
                {favorites.map(h => (
                    <HistoryItem className="history-item favorite-item">
                        <IconButton className="fav" color="primary" size="small" onClick={() => data.removeFavorite(h)}><Star /></IconButton>
                        
                        <HistoryLink key={h.location} {...h} />
                    </HistoryItem>
                ))}

                {favorites.length > 0 && history.length > 0 && <Separator className="separator" />}
                {history.map(h => (
                    <HistoryItem className="history-item">
                        {favorites.find(f => f.location === h.location)
                            ? <IconButton className="fav" color="primary" size="small" onClick={() => data.removeFavorite(h)}><Star /></IconButton>
                            : <IconButton className="fav" color="primary" size="small" onClick={() => data.saveFavorite(h)}><StarBorder /></IconButton>}
                        
                        <HistoryLink key={h.location} {...h} />
                    </HistoryItem>
                ))}
            </HistoryFavoritesPanelInner>

        </HistoryFavoritesPanelWrapper>
    );
}
