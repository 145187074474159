import { Dictionaries, Dictionary } from "../../../hooks/useDictionaries";
import { Schemas } from "../../../hooks/useSchema";
import { OpenDetailsWidgetFn, WidgetResult } from "../types";

export interface ChartRow {
    label: string;
    value: any;
}

export const getChartRows = (result: WidgetResult, dictionaries: Dictionaries, schemas: Schemas): ChartRow[] => {
    const { label_field, result_fields } = result;
  
    const labelField = result_fields.find(field => field.name === label_field);
    let dict: Dictionary | null = null;
  
    if (labelField && labelField.entity && labelField.entity_field) {
      const schema = schemas[labelField.entity][labelField.entity_field];
  
      dict = dictionaries[schema.dictionary || ""];
    }

    return (result.rows || []).map(row => {
        let label = row[label_field];
        if(dict && dict.values) {
            const dictValue = dict.values.find(dict => dict.value === row[label_field]);
            if(dictValue) {
                label = dictValue.label;
            }
        }
        return {
            // spreading full rows is needed for charts to be able to open details
            ...row,
            value: row[result.value_field],
            label,
        }
    });
}


export interface ChartProps {
    rows: ChartRow[];
    openDetails: OpenDetailsWidgetFn;
    width: number;
}
