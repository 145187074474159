import moment from "moment";
import { useEffect, useState } from "react";
import { apiFetch } from "../../api/core";

export interface Rate {
    date: string;
    rate: string;
}

export const useCurrencyRates = (code: string | null, date: string | null | undefined) => {
    const [rates, setRates] = useState<Rate[]>([]);

    const dateParsed = (date && date.length ? moment(date) : null);

    const dateFinal = (dateParsed || moment(new Date())).format("YYYY-MM-DD");

    useEffect(() => {
        if (code && dateFinal && dateFinal !== "Invalid date") {
            apiFetch<Rate[]>(`/api/currency/${code}/rates?date=${dateFinal}`)
                .then(setRates);
        }
    }, [code, dateFinal])


    return rates
}
