import React, { useContext } from "react";
import { decodeToken, storeApiToken } from "../api/core";

export interface IDefaultUser {
    roles: string[];
    email: string;
    _id: string;
}

export type DefaultUser = IDefaultUser | null;

export interface IUserContext<IUser> {
    user: IUser | null;
    setUser: (u: IUser) => void;
}

export const UserContext = React.createContext<IUserContext<DefaultUser>>({
    user: null,
    setUser: (u: DefaultUser | null) => { },
});

export const useUser = () => useContext(UserContext);

export const login = <IUser = DefaultUser>(context: IUserContext<IUser>, token: string) => {
    storeApiToken(token);
    const decoded = decodeToken<IUser>(token);
    context.setUser(decoded);
    return decoded;
}

interface IUserWithRoles {
    roles: string[];
}

export type DefaultUserWithRoles = IUserWithRoles | null;

export const checkRole = <RolesUser extends DefaultUserWithRoles>(user: RolesUser, role: string) => user && user.roles.find(r => r === role) ? true : false;

export const checkContextRole = <RolesUser extends DefaultUserWithRoles>(context: IUserContext<RolesUser>, role: string) => checkRole(context.user, role);