import React from 'react';
import { useHistory } from 'react-router';
import { useNewPracticeData, usePracticeList } from './practiceData';
import { PracticeListPage } from './PracticeListPage';

export const PracticeListContainer = () => {
    const history = useHistory();

    const data = usePracticeList();
    const newPracticeData = useNewPracticeData();
    const submitAndOpen = () => newPracticeData.submit().then(p => { history.push(`/practice/${p._id}`); return p; });

    return <PracticeListPage data={data} newRecordData={{ ...newPracticeData, submit: submitAndOpen }} />
}
