import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { apiFetch } from '../../api/core';
import { useLoadedData } from '../../hooks/useLoadedData';
import { PowerEditorBaseProps } from '../PowerDoc';
import { DocumentationDoc } from './useDocumentationPages';

export interface DocumentationPagesConfig {
    apiPath: string;
    pagePath: string;
    rootSlug: string;
    defaultRootPageTitle?: string;
    
    editor?: React.ComponentType<PowerEditorBaseProps>;
    noEdit?: boolean;
    enableCustomFieldsBlock?: boolean;
}


export interface IDocumentationPages {
    config: DocumentationPagesConfig;
    documents: Omit<DocumentationDoc, "content">[];
    getDocument: (docId: string) => DocumentationDoc | null;
    createDocument: (doc: Partial<DocumentationDoc>) => Promise<DocumentationDoc>;
    updateDocument: (docId: string, changes: Partial<DocumentationDoc>) => Promise<DocumentationDoc>;
}

const DocumentationPagesContext = React.createContext<IDocumentationPages>({
    config: {
        pagePath: "",
        rootSlug: "",
        apiPath: "",
    },
    documents: [],
    getDocument: () => null,
    createDocument: () => { throw Error("not ready"); },
    updateDocument: () => { throw Error("not ready"); },
});

export const useDocumentationPages = () => useContext(DocumentationPagesContext);

const useDocumentationPagesApi = (config: DocumentationPagesConfig): IDocumentationPages => {
    const documents = useLoadedData<DocumentationDoc[]>(config.apiPath, []);

    const documentsDict = useMemo(
        () => documents.data.reduce<Record<string, DocumentationDoc>>((r, d) => { r[d._id] = d; return r }, {}),
        [documents.data]);

    return {
        config,
        documents: documents.data,
        getDocument: id => documentsDict[id] || null,
        createDocument: (d) =>
            apiFetch<DocumentationDoc>(config.apiPath, "post", { ...d })
            .then(d => {
                documents.setData([...documents.data, d]);
                return d;
            }),
        updateDocument: (id, d) =>
            apiFetch<DocumentationDoc>(`${config.apiPath}/${id}`, "put", { ...d }),
    }
}

interface Props extends DocumentationPagesConfig {
}

export const DocumentationPagesProvider = (props: PropsWithChildren<Props>) => {
    const { children, ...config } = props;
    const data = useDocumentationPagesApi(config);

    return (
        <DocumentationPagesContext.Provider value={data}>
            {children}
        </DocumentationPagesContext.Provider>
    );
}
