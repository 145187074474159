import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { NewItemProps } from '../../../toolympus/api/useNewItem';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { NewQuestion, PresidiumQuestion } from './usePresidiumQuestions';
import { CaseSelect } from '../../Cases/CaseSelect';

interface Props {
    schema: Schema;
    data: NewItemProps<NewQuestion, PresidiumQuestion>;
}

export const NewPresidiumQuestionPopup = ({ data, schema }: Props) => {
    const { isEditing, isLoading, item, cancel, update, save: add, errors } = data;
    return (
        <Dialog open={isEditing} onClose={cancel} fullWidth maxWidth="md">
            <DialogContent>
                <FormGrid areas={[
                    "question_type committee case_id",
                    "title title title",
                    "comments comments comments"
                ]}>
                    {item && <>
                        <FormControlsForFields
                            schema={schema}
                            data={item}
                            fields={[
                                ["question_type"],
                                ["committee"],
                            ]}
                            onChange={(o,c) => update(c)}
                            errors={errors}
                            withArea
                            />

                        <CaseSelect
                            field="case_id"
                            schema={schema}
                            setCaseId={id => update({ case_id: id })}
                            case_id={item.case_id}
                            key="case_id"
                            />

                        <FormControlsForFields
                            schema={schema}
                            data={item}
                            fields={[
                                ["title"],
                                ["comments"],
                            ]}
                            onChange={(o,c) => update(c)}
                            errors={errors}
                            withArea
                            />
                    </>}
                </FormGrid>
            </DialogContent>
            <DialogActions>
                {!isLoading && <Button onClick={cancel}><FormattedMessage id="common.cancel"/></Button>}
                {!isLoading && <Button color="primary" variant="contained" onClick={() => add()}><FormattedMessage id="common.save"/></Button>}
                {isLoading && <LoadingIndicator />}
            </DialogActions>
        </Dialog>
    );
}
