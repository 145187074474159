import { IconButton, TextField, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { CustomElement } from '../../../../../slate';
import { ActionRow, OccupyFreeSpace } from '../../../primitives';
import { BlockWrapper } from '../../elements/Common';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';
import { PowerEditorBase } from '../../slate/PowerEditorBase';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';

export const ExpanderBlockType = "expander-block";

interface WithExpanded {
    open?: boolean;
    title?: string;
    content?: any;
}

const useExpander = (element: (CustomElement & WithExpanded) | null) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [localState, setLocalState] = useState<Omit<WithExpanded, "open">>({});

    const editor = useSlateStatic();
    const path = element ? ReactEditor.findPath(editor, element) : null;

    useEffect(() => {
        if(element) {
            setIsOpen(x => x || element.open || (!element.title && !element.content) || false);
            setLocalState({
                title: element.title || "",
                content: element.content,
            });
        }
    }, [element]);

    const update = (changes: Partial<Omit<WithExpanded, "open">>) => {
        const updated = { ...localState, ...changes };
        setLocalState(updated);
        if(path) {
            Transforms.setNodes(
                editor,
                updated as any,
                { at: path },
            );
        }
    }

    return {
        isOpen,
        setIsOpen,
        update,
        state: localState,
    }
}

export const ExpanderBlock = (props: RenderElementProps) => {
    const { element, attributes, children } = props;

    const isSelected = useSelected();
    const { formatMessage } = useIntl();

    const data = useExpander(element);

    const { viewMode } = usePowerEditorContext();

    const toggleExpandInViewMode = () => {
        if(viewMode) {
            data.setIsOpen(x => !x);
        }
    }

    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    return (
        <BlockWrapper {...attributes} isSelected={isSelected}>
            {children}
            <div style={{ width: "100%" }} contentEditable={false}>
                <ActionRow firstItemNoMargin itemMarginTop='0' lastItemMarginRight='0'
                    wrapperProps={{ onClick: toggleExpandInViewMode }}>
                    {!viewMode && <TextField
                        key="title"
                        value={data.state.title || ""}
                        onChange={e => data.update({ title: e.target.value })}
                        label=""
                        placeholder={formatMessage({ id: "powerdoc.plugins.tiles.tile.title"})}
                        autoFocus={data.state.title === ""}
                        fullWidth
                        />}

                    {viewMode && <Typography variant="h6">{data.state.title}</Typography>}
                    {viewMode && <OccupyFreeSpace  />}
                    
                    <IconButton size="small" onClick={e => { e.stopPropagation(); data.setIsOpen(x => !x); }}>
                        {data.isOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </ActionRow>

                {data.isOpen &&
                    <EditorComponent
                        content={data.state.content}
                        update={content => data.update({ content })}
                        viewMode={viewMode}
                        />}
            </div>
        </BlockWrapper>
    );
}
