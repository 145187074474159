import { useState, useCallback, useEffect } from 'react';
import { apiFetch, FetchTypes } from '../../../toolympus/api/core';
import { CaseArbitrator } from '../../../typings/Arbitrators';
import { LoadedData } from '../../../toolympus/hooks/useLoadedData';

export interface CaseArbitrators extends LoadedData<CaseArbitrator[]> {
    add: (a: CaseArbitrator) => Promise<void>;
    remove: (a: CaseArbitrator) => Promise<void>;
    update: (a: CaseArbitrator) => Promise<void>;
}

export const useCaseArbitrators = (caseid: string): CaseArbitrators => {
    const [arbitrators, setArbitrators] = useState<CaseArbitrator[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadArbitrators = useCallback(
        () => {
            setIsLoading(true);
            apiFetch<CaseArbitrator[]>(`/api/case/${caseid}/arbitrator`)
                .then(arbs => setArbitrators(arbs))
                .then(() => setIsLoading(false));
        },
        [caseid],
    );

    useEffect(() => {
        loadArbitrators();
    }, [loadArbitrators]);

    const add = (arbitrator: CaseArbitrator) => {
        return apiFetch<CaseArbitrator[]>(`/api/case/${caseid}/arbitrator/${arbitrator._id}`, FetchTypes.POST)
            .then(() => loadArbitrators());
    }

    const remove = (arbitrator: CaseArbitrator) => {
        return apiFetch<CaseArbitrator[]>(`/api/case/${caseid}/arbitrator/${arbitrator._id}`, FetchTypes.DELETE)
            .then(() => loadArbitrators());
    }

    const update = (arbitrator: CaseArbitrator) => {
        return apiFetch<CaseArbitrator[]>(
            `/api/case/${caseid}/arbitrator/${arbitrator._id}`,
            FetchTypes.PUT,
            { assigned_by: arbitrator.assigned_by, fee_paid: arbitrator.fee_paid })
            .then(() => loadArbitrators());
    }

    return {
        data: arbitrators,
        setData: (x: any) => {},
        isLoading,
        reload: () => loadArbitrators(),
        add,
        remove,
        update,
    }
}