import React from "react";
import { Gavel, Group, HowToVote } from "@mui/icons-material";
import { MenuRoute } from "../../../toolympus/components/frame";
import { isAdminManager } from "../../UserManagement/roles";
import { PresidiumPage } from "../Members/PresidiumPage";
import { PresidiumQuestionPage, PresidiumQuestionsList } from "../Questions";
import { PresidiumVoteListPage } from "../Votes/PresidiumVoteListPage";
import { PresidiumVotePage } from "../Votes/PresidiumVotePage";
import { PresidiumEventFeedPage } from "../PresidiumEventFeed";

export const PresidiumRoutes: MenuRoute[] = [
    {
        path: '/presidium/questions/:id',
        title: 'Вопросы',
        icon: <Gavel />,
        component: PresidiumQuestionPage,
        hidden: true,
    },
    {
        path: '/presidium/members',
        title: 'Президиум',
        icon: <Group />,
        component: PresidiumPage,
        resolveHidden: isAdminManager,
        hidden: true,
    },

    
    {
        path: '/presidium/questions',
        title: 'Президиум',
        icon: <Gavel />,
        component: PresidiumQuestionsList,
        alsoActivateForPath: (path: string) => path.startsWith('/presidium/'),
    },

    
    {
        path: '/presidium/events',
        title: 'Лента событий',
        component: PresidiumEventFeedPage,
        hidden: true,
    },
    
    {
        path: '/presidium/votes/:id',
        title: 'Голосование',
        component: PresidiumVotePage,
        hidden: true,
    },    
    {
        path: '/presidium/votes',
        title: 'Голосования',
        icon: <HowToVote />,
        component: PresidiumVoteListPage,
        hidden: true,
    },
];
