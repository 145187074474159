import { Editor, Transforms, Element as SlateElement, } from 'slate';
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { ReactEditor } from 'slate-react';
import { ElementBackgroundColors, ElementBackgroundColorField } from '../../slate/BlockElements';


const toggleBgColor = (editor: ReactEditor, toColor: string) => {
  const [item] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n),
  });
  if(item) {
    const [node] = item;
    const path = ReactEditor.findPath(editor, node);
    if((node as any)[ElementBackgroundColorField] === toColor) {
      Transforms.setNodes(
        editor,
        { [ElementBackgroundColorField]: null } as any,
        { at: path },
      );
    } else {
      Transforms.setNodes(
        editor,
        { [ElementBackgroundColorField]: toColor} as any,
        { at: path },
      );
    }
  }
}

export const BackgroundColorsPlugin: EditorPlugin = {
    key: "bg-colors-commands",
    commands: Object.keys(ElementBackgroundColors).map(color => ({
      name: `bg-${color}`,
      invoke: e => toggleBgColor(e, color),

    })),
}
