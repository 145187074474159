import { apiFetch } from "../../../toolympus/api/core";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { PresidiumQuestion } from "./usePresidiumQuestions"

interface Config {
    question: PresidiumQuestion;
    onUpdated: (updatedQuestion: PresidiumQuestion) => void;
}

export const useQuestionSubscription = ({ question, onUpdated }: Config) => {
    const { user } = useUser();

    const subscribers = (question.subscribed_user_ids || []);
    const isSubscribed = subscribers.includes(user?._id || "x");

    const update = (newValue: boolean) => {
        if(isSubscribed !== newValue && user?._id) {
            apiFetch<PresidiumQuestion>(`/api/presidium/question/${question._id}`, "put", {
                subscribed_user_ids: newValue ? [...subscribers, user?._id] : subscribers.filter(id => id !== user._id),
            })
            .then(updated => onUpdated(updated));
        }
    }

    const toggle = () => update(!isSubscribed);

    return {
        isSubscribed,
        update,
        toggle,
    }
}

export type QuestionSubscriptionData = ReturnType<typeof useQuestionSubscription>;