import React, { ReactNode, useContext } from 'react';
import moment, { MomentInput } from "moment";
import "moment-timezone";
import { useIntl } from 'react-intl';
// @ts-ignore
import "moment/locale/ru";

export const ShortFormat = "D MMM HH:mm";

export type ThousandSeparatorType = ',' | ' ' | '' | '\xA0';

const DefaultFormats = {
    date: "YYYY/MM/DD",
    datetime: " DD.MM.YYYY HH:mm",
    shortDatetime: "D MMM HH:mm",
    thousandSeparator: '' as ThousandSeparatorType,
    decimalPrecision: 2,
    boolean: {
        true: "true",
        false: "false",
    },
}

type Formats = typeof DefaultFormats;

export const FormatsContext = React.createContext<Formats>(DefaultFormats);

export const formatDecimal = (v: string | number, precision: number, thousandSeparator: string) => {
  const vv = +(v || 0);
  const parts = vv.toFixed(precision).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return parts.join(".");
}

export const useFormats = () => {
    const fmts = useContext(FormatsContext);
    const { locale } = useIntl();

    const format = (f: string) => (v: MomentInput) => {
            moment.locale(locale);
            return (!v || v === "") ? "" : moment(v).format(f);
        };
    
    const formatDecimal = (v: string | number) => {
        const vv = +(v || 0);
        const parts = vv.toFixed(fmts.decimalPrecision).toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, fmts.thousandSeparator);
        return parts.join(".");
    }

    const formatBoolean = (v: boolean) => (v === undefined || v === null) ? "" : v ? fmts.boolean.true : fmts.boolean.false;

    return {
        ...fmts,
        formatDate: format(fmts.date),
        formatDatetime: format(fmts.datetime),
        formatDatetimeShort: format(fmts.shortDatetime),
        formatDatetimeWithFormat: (d: MomentInput, f: string) => format(f)(d),
        formatDecimal,
        formatBoolean,
    }
};

interface ProviderProps {
    formats?: Partial<Formats>;
    children: ReactNode;
}

export const FormatsProvider = (props: ProviderProps) => {
    return <FormatsContext.Provider value={{ ...DefaultFormats, ...props.formats}}>
        {props.children}
    </FormatsContext.Provider>
}
