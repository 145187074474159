import React, { ReactNode, useContext, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface DialogConfig {
    title?: ReactNode;
    hint?: ReactNode;
    confirmationLabel?: ReactNode;
    confirmationText?: string;
    execute: () => void;
}

interface DialogState {
    isOpen: boolean;
    close: () => void;
}

interface Props extends DialogState, DialogConfig {
}

export const ConfirmationDialog = (props: Props) => {
    const { isOpen, close: closeX, title, hint, confirmationText, confirmationLabel, execute } = props;
    const [textEntered, setTextEntered] = useState<string>("");
    
    const close = () => {
        setTextEntered("");
        closeX();
    }

    return (
        <Dialog open={isOpen} onClose={close}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {hint}
                {confirmationText && (
                    <TextField
                        fullWidth
                        value={textEntered}
                        onChange={e => setTextEntered(e.target.value)} />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color='secondary'
                    variant='contained'
                    disabled={!!confirmationText && textEntered !== confirmationText}
                    onClick={() => {
                        close();
                        execute();
                    }}>
                    {confirmationLabel || <FormattedMessage id="common.yes" />}
                </Button>
                <Button onClick={close}><FormattedMessage id="common.cancel" /></Button>
            </DialogActions>
        </Dialog>
    );
}


interface IConfirmationContext {
    dialogState: DialogState;
    config: DialogConfig;
    open: (config: DialogConfig) => void;
}

const DefaultConfig = { execute: () => {} };

const ConfirmationContext = React.createContext<IConfirmationContext>({
    dialogState: { isOpen: false, close: () => {} },
    config: DefaultConfig,
    open: (config) => { config.execute(); },
})

export const ConfirmationDialogProvider = (props: { children?: ReactNode }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [config, setConfig] = useState<DialogConfig>(DefaultConfig);

    
    const open = (config: DialogConfig) => {
        setConfig(config);
        setIsOpen(true);
    };
    
    const close = () => {
        setIsOpen(false);
        setConfig(DefaultConfig);
    }

    return <ConfirmationContext.Provider value={{
        dialogState: { isOpen, close },
        config,
        open,
    }}>
        {props.children}
        <ConfirmationDialog {...config} isOpen={isOpen} close={close} />
    </ConfirmationContext.Provider>
}

export const useConfirmationDialog = () => useContext<IConfirmationContext>(ConfirmationContext);
