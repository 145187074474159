import React, { ReactNode } from 'react';
import { CurrencyAmountEdit } from '../../../toolympus/components/currency/CurrencyAmountEdit';
import { FormGrid, SectionTitle } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { mergeSchema, useSchema } from '../../../toolympus/hooks/useSchema';
import { Case } from '../../../typings/Cases';
import { CaseData } from '../caseData';

interface Props {
    data: CaseData;
    fieldPrefix?: string;
    sectionsSuffix?: ReactNode;
}

export const CaseMoneyForm = ({ data, fieldPrefix, sectionsSuffix }: Props) => {
    const onFieldChange = (_: Case, changes: Partial<Case>) => data.update(changes);
    const case_ = data.data;

    const {
        case: caseSchema,
    } = useSchema();

    const schema = mergeSchema(
        caseSchema,
        {
            counter_arbitration_fee_amount: { label: caseSchema.arbitration_fee_amount?.label },
            counter_admin_fee_amount: { label: caseSchema.admin_fee_amount?.label },
            counter_reward_fee_amount: { label: caseSchema.reward_fee_amount?.label },
            counter_has_non_money_claim: { label: caseSchema.has_non_money_claim?.label },
            counter_timebilled_fee_amount: { label: caseSchema.timebilled_fee_amount?.label },
            counter_extra_expense_amount: { label: caseSchema.extra_expense_amount?.label },
            counter_return_amount: { label: caseSchema.return_amount?.label },
        },
    );


    const fieldName = (f: string) => fieldPrefix ? `${fieldPrefix}${f}` : f;

    return (
        <FormGrid columns="1fr" style={{ paddingBottom: "2rem" }}>
            <CurrencyAmountEdit
                fieldPrefix={fieldName("case_price")}
                schema={schema}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
        
            <SectionTitle style={{ marginTop: "2rem" }}>Сборы {sectionsSuffix}</SectionTitle>
            <CurrencyAmountEdit
                fieldPrefix={fieldName("arbitration_fee")}
                schema={schema}
                extraProps={{ amount: { readOnly: true } }}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
            <CurrencyAmountEdit
                fieldPrefix={fieldName("admin_fee")}
                onlyAmounts
                schema={schema}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
            <CurrencyAmountEdit
                fieldPrefix={fieldName("reward_fee")}
                onlyAmounts
                schema={schema}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
            <FormControlsForFields
                fields={[
                    [fieldName("has_non_money_claim")],
                ]}
                schema={schema}
                data={case_}
                onChange={onFieldChange} />
            <CurrencyAmountEdit
                fieldPrefix={fieldName("timebilled_fee")}
                schema={schema}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
        
            <SectionTitle style={{ marginTop: "2rem" }}>Другое {sectionsSuffix}</SectionTitle>
            <CurrencyAmountEdit
                fieldPrefix={fieldName("extra_expense")}
                schema={schema}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
            <CurrencyAmountEdit
                fieldPrefix={fieldName("return")}
                schema={schema}
                data={case_}
                defaultRateDate={case_.claim_received_date}
                onChange={onFieldChange} />
        </FormGrid>
    );
}
