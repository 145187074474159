import React from 'react';
import styled from '@emotion/styled';
import { Case } from "../../typings/Cases";
import { Typography } from '@mui/material';

const SideKindEmailsCountText = styled(Typography)<{ danger?: boolean }>`
  color: ${props => props.danger ? "#990000" : "#008800"};
`;

export const CaseSideKindEmailsCount = ({ c, sideKind }: { c: Case, sideKind: string }) => {
  const emails = (c.sides_alt || [])
    .filter(s => s.kind === sideKind)
    .reduce<string[]>((r,s) => {
      r.push(...((s.emails as any as { value: { email: string }[] })?.value || []).map(em => em.email as string).filter(s => !!s));
      return r;
    }, []);

  return <SideKindEmailsCountText danger={!emails.length && sideKind !== "other"}>{emails.length}</SideKindEmailsCountText>
}
