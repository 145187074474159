import React, { useMemo } from 'react';
import { Schema } from '../../toolympus/hooks/useSchema';
import { EditItemProps, NewItemProps } from '../../toolympus/api/useNewItem';
import { TimetrackingRatetable, TimetrackingRecord } from './types';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { FormControlProps } from '../../toolympus/components/schemed/FormControlProps';
import { Autocomplete } from '../../toolympus/hooks/useForm/Autocomplete';
import { TextEdit } from '../../toolympus/components/schemed/TextEdit';
import { TimeEditorControl } from './TimeEditor';

interface Props {
  schema: Schema;
  data: EditItemProps<TimetrackingRecord> | NewItemProps<Partial<TimetrackingRecord>, TimetrackingRecord>;
  mode: "create" | "edit";
  rateTable: TimetrackingRatetable | null;
}

export const TimeRecordDialog = (props: Props) => {
  const { schema, data, mode } = props;

  const { controls } = useItemWithControls({ data: data.item || {}, update: data.update, errors: data.errors }, { schema });

  const ActorControl = useMemo(() => {
    if(!props.rateTable) {
      return TextEdit;
    } else {
      const suggestions = (props.rateTable.rates || []).map(r => r.actor_role);
      return (props: FormControlProps) => {
        return <Autocomplete
          suggestions={suggestions}
          label={schema?.actor_role?.label}
          onChange={v => props.onChange(props.row, { [props.field]: v })}
          value={props.row[props.field]}
          openOnFocus
          noClear
          />
      };
    }
  }, [props.rateTable, schema])

  return (
    <SimpleDialog
      dialogTitle="Запись о времени"
      save={() => data.save()}
      isOpen={data.isEditing}
      close={() => data.cancel()}
      submitOnModEnter>
      {data.item &&
        <>
          <FormGrid noMargin columns="1fr">
            {controls([
                ["record_date", { controlProps: { autoFocus: true } }],
                ["time_logged", { control: TimeEditorControl }],
                ["description"],
                ["actor_role", { control: ActorControl }],
                ["actor_description"],
              ])}
          </FormGrid>

          <FormGrid noMargin columns="1fr 1fr" style={{ alignItems: "end"}}>
            {controls([
                ["rate"],
                ["is_excluded"],
                mode === "edit" ? ["created_at", { utcToLocal: true, readOnly: true }] : null,
                mode === "edit" ? ["created_by", { readOnly: true }] : null,
              ])}
          </FormGrid>
        </>}
    </SimpleDialog>
  );
}
