import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react'

const PhantomItem = styled.div`
    padding: 15px;
    transition: 150ms;

    & > div {
        border-radius: 5px;
        border: 0px dashed grey;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
`;

type PhantomPropsType = {
    dragElement: HTMLElement | null, 
    onSelect: () => void,
}

export const Phantom = ({ dragElement, onSelect }: PhantomPropsType) => {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        const { current } = ref;
        
        if (dragElement && current) {
            console.log("test");
            const inCurrent = () => {
                const dragRect = dragElement.getBoundingClientRect();
                const rect = current.getBoundingClientRect();

                const x = dragRect.x + dragRect.width / 2;
                const y = dragRect.y + dragRect.height / 2;
               
               return (
                    x > rect.x - 50 &&
                    x < rect.x + rect.width + 50 &&
                    y > rect.y &&
                    y < rect.y + rect.height
                )
            }

            const move = () => {
                const checkInCurrent = inCurrent();

                current.style.width = (checkInCurrent? 100/6 : 0) + '%';
                current.style.padding = (checkInCurrent? 15 : 0) + 'px';
                (current.firstElementChild as HTMLElement).style.borderWidth = (checkInCurrent? 5 : 0) + 'px';
            }
            
            const onup = () => {
                if (inCurrent()) {
                    onSelect()
                }

                current.style.width = '0';
                current.style.padding = '0';
                (current.firstElementChild as HTMLElement).style.borderWidth = '0'
            }

            document.addEventListener('mousemove', move);
            document.addEventListener('mouseup', onup);

            return () => {
                document.removeEventListener('mouseup', onup);
                document.removeEventListener('mousemove', move);
            };
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragElement, ref]);

    return <>{!!dragElement &&
        <PhantomItem 
            ref={ref as any}
            style={{
                height: dragElement.style.height
            }}
        >
            <div/>
        </PhantomItem>
    }
    </>
}
