import React from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Check, Close, MoreHoriz } from '@mui/icons-material';
import { LoadingIndicator } from './LoadingIndicator';

export type ProcessingResult = "success" | "error" | "loading" | "unknown";

interface Props {
  result: ProcessingResult;
}

const ButtonX = styled(IconButton)<Props>`
  background: ${props => props.result === "success"
    ? props.theme.palette.success.main
    : props.result === "error"
      ? props.theme.palette.error.main
      : props.theme.palette.grey[300]};
  color: ${props => props.result === "success"
    ? props.theme.palette.success.contrastText
    : props.result === "error"
      ? props.theme.palette.error.contrastText
      : props.theme.palette.grey[600]};
  pointer-events: none;
`;

export const ProcessingResultDisplay = (props: Props) => {
  return (
    <ButtonX size="small" {...props}>
      {props.result === "success"
        ? <Check fontSize="small" />
        : props.result === "error"
          ? <Close fontSize="small" />
          : props.result === "loading"
            ? <LoadingIndicator color="inherit" sizeVariant="s" />
            : <MoreHoriz fontSize="small" />}
    </ButtonX>
  );
}
