import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { NewItemProps, useNewItem } from "../../../toolympus/api/useNewItem";
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { useQuery } from '../../../toolympus/hooks/useQueryParamsState';
import { Schema, useSchema } from "../../../toolympus/hooks/useSchema";
import { getCase, useCaseNbrs } from '../../Cases/caseData';
import { SmartListData, useSmartList } from '../../../toolympus/hooks/useSmartList';
import { Arbitrator } from '../../../typings/Arbitrators';

export interface Option {
    id: number;
    label: string;
    label_en?: string;
    suggested_by?: string;
    score?: number;
    win?: boolean;
}

export interface PresidiumQuestion {
    _id: string;
    question_type: string;
    committee?: string;
    case_id?: string | null;
    batch?: number | null;
    title: string;
    casenbr?: string | null;
    comments?: string;
    poll_id?: string;
    is_closed: boolean;
    is_launched?: boolean;
    is_closed_down: boolean;
    attributes: Record<string, string>;
    status?: string;
    votes_nbr?: number;
    respondees_nbr?: number;
    is_candidates_question?: boolean;
    is_board_statement_prepared?: boolean;
    candidates_count?: number;
    
    options?: Option[];
    selected_option?: Option | null;
    selected_arbitrators?: Arbitrator[] | null;
    
    start_date?: string | null;
    end_date?: string | null;
    subscribed_user_ids?: string[];
}

export type NewQuestion = Omit<PresidiumQuestion, '_id' | 'is_closed' | 'is_closed_down'>;

type PresidiumQuestionsList = Omit<SmartListData<PresidiumQuestion>, "newItem"> & {
    schema: Schema;
    attributesSchema: Schema;
    newQuestion: NewItemProps<NewQuestion, PresidiumQuestion>;
}

export const QuestionType_JuryFormation = 'arbitration_jury_formation';
export const QuestionType_NoVote = 'no_vote_question';

export const useQuestionTitleGenerator = () => {
    const { presidium_question: schema } = useSchema();
    const caseNbrs = useCaseNbrs();

    const generate = (questionType: string | null | undefined, caseId: string | null | undefined): string | undefined => {
        if(questionType && caseId) {
            const casenbr = caseNbrs.data.find(c => c._id === caseId)?.casenbr;
            if(casenbr) {
                const typeLabel = schema["question_type"].valueDict[questionType];
                return `${typeLabel} по делу ${casenbr}`;
            }
        }
    };

    const injectInChanges = (original: Pick<PresidiumQuestion, "question_type" | "case_id" | "title">, changes: Partial<PresidiumQuestion>) => {
        const updated = { ...original, ...changes };
        if((!original.question_type || !original.case_id) && updated.question_type && updated.case_id && !updated.title) {
            const title = generate(updated.question_type, updated.case_id);
            if(title) {
                return { ...changes, title };
            }
        }
        return changes;
    }

    return {
        generate,
        injectInChanges,
    }
}

export const usePresidiumQuestionsList = (): PresidiumQuestionsList => {
    const { presidium_question: schema, presidium_question_attributes: attributesSchema } = useSchema();

    const dataX = useSmartList<PresidiumQuestion>("/api/presidium/question", {
      newRecordDefault: {},
      schema,
      extraParams: { show_closed: "true" },
      lsKeysPrefix: "__presidium_questions",
      viewDefault: "drafts",
      noCount: true,
      skipTextFilter: true,
      textFilterApi: { paramName: "filter" },
      sorting: {
      },
    });
    
    
    const questionTitleGen = useQuestionTitleGenerator();

    const newQuestion = useNewItem<NewQuestion, PresidiumQuestion>('/api/presidium/question', {
        question_type: QuestionType_JuryFormation,
        title: '',
        attributes: {},
    },
    { onChange: questionTitleGen.injectInChanges })

    const history = useHistory();
    const query = useQuery();
    const new_for_case = query.get('new_for_case');
    const new_question_type = query.get('new_question_type');

    useEffect(() => {
        if(new_for_case && new_for_case.length) {
            getCase(new_for_case).then(c => {
                newQuestion.startEditing({
                    case_id: c._id,
                    question_type: new_question_type && new_question_type.length ? new_question_type : QuestionType_JuryFormation,
                    title: `Формирование СА по делу ${c.casenbr}`,
                    committee: c.case_type === 'international' ? 'international' : 'internal',
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [new_for_case]);
    
    const saveNew = () => newQuestion.save().then(q => { history.push(`/presidium/questions/${q._id}`); return q; });

    return {
        ...dataX,
        schema,
        attributesSchema,
        newQuestion: { ...newQuestion, save: saveNew },
    }
}

export const useCasePresidiumQuestions = (caseId: string) =>
    useLoadedData<PresidiumQuestion[]>(`/api/presidium/question?view=case&case-id=${caseId}`, []);
