import { useEffect, useMemo, useState } from "react";
import { apiFetch } from "../../../api/core";
import { useLoadedData } from "../../../hooks/useLoadedData";
import { useTextFilter } from "../../schemed/Filtering/useTextFilter";
import { CMSLink, CMSRecord } from "../types";
import { LinkConfig, LinkKindTranslation, RecordTypeConfig } from "../useRecordTypeConfig";

interface Config {
    apiPath: string;
    config: RecordTypeConfig;
    record: CMSRecord;
    onUpdatedLinks?: (updatedPartial: Record<string, CMSLink[]>) => void;
}

export const addTranslationLink = (apiPath: string, translationLink: LinkConfig | undefined, record: CMSRecord, translationRecord: CMSRecord, cfg?: Pick<Config, "onUpdatedLinks">) => {
  return apiFetch<Record<string, CMSLink[]>>(
      `${apiPath}/manage/${record.record_type}/${record._id}/link/${translationLink?.link_type}`,
      "post",
      { other_record_id: translationRecord._id })
      .then(result => {
          if(cfg?.onUpdatedLinks) {
              cfg.onUpdatedLinks(result);
          }
          return result;
      })
}

export const useRecordTranslationLinks = (cfg: Config) => {
    const { apiPath, config, record } = cfg;
    const { lang: currentLang } = record;
    const translationLinkType = (config.links || []).find(l => l.kind === LinkKindTranslation);


    const links = useMemo(() => {
        const links = translationLinkType ? (record.links || {})[translationLinkType.link_type] || [] : [];

        return (config.languages || []).filter(l => l !== currentLang).map(l => {
            const link = links.find(lnk => lnk.lang === l);
            return { lang: l, link: link };
        });
    }, [config, currentLang, record.links, translationLinkType]);

    const hasTranslationLinks = links.length > 0 && !config['translations-in-place'];

    const [candidatesLang, setCandidatesLang] = useState<string | null>(null);

    const candidates = useLoadedData<CMSRecord[]>(`${apiPath}/manage/${record.record_type}?lang=${candidatesLang}&order-by=created_datetime:desc`, [], !!candidatesLang);

    const candidatesFilter = useTextFilter<CMSRecord>(r => `${r.title} ${r.slug}`);

    useEffect(() => {
        // this closes links popup upon navigation by links
        setCandidatesLang(null);
    }, [record._id]);

    const loadCandidatesForLang = (lang: string) => {
        candidates.setData([]);
        candidatesFilter.setFilter("");
        setCandidatesLang(lang);
    }

    const addTranslation = (translationRecord: CMSRecord) => {
      return addTranslationLink(apiPath, translationLinkType, record, translationRecord, cfg);
    }

    const removeTranslation = (link: CMSLink) => {
        return apiFetch<Record<string, CMSLink[]>>(
            `${apiPath}/manage/${record.record_type}/${record._id}/link/${translationLinkType?.link_type}/${link.link_record_id}`,
            "delete")
            .then(result => {
                if(cfg.onUpdatedLinks) {
                    cfg.onUpdatedLinks(result);
                }
                return result;
            })
    }

    const isRecordLinkedToUs = (record: CMSRecord) => {
        return !!links.find(l => l.lang === candidatesLang && l.link?._id === record._id)
    }

    return {
        config,
        hasTranslationLinks,
        links,
        currentLang,
        translationLinkType,

        candidates: {
            ...candidates,
            data: candidatesFilter.filterData(candidates.data),
            filter: candidatesFilter,
        },
        loadCandidatesForLang,
        candidatesLang,
        setCandidatesLang,
        
        addTranslation,
        removeTranslation,
        isRecordLinkedToUs,
    }
}

export type TranslationLinksData = ReturnType<typeof useRecordTranslationLinks>;
