import React from 'react';
import { PagesData } from './usePages';
import { SimpleList } from '../primitives/SimpleList';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

interface PageManagementFormProps {
    pagesData: PagesData;
}

export const PageManagementForm = (props: PageManagementFormProps): JSX.Element => {
    let { url } = useRouteMatch();

    const { schema, pages } = props.pagesData;


    return (
        <SimpleList
            title={<FormattedMessage id="pages.pageListTitle" />}
            data={pages}
            schema={schema}
            fields={[
                ["slug"],
                ["language"],
                ["styles"],
                ["properties"],
            ]}
            linkFields={["slug"]}
            getLinkValue={({ _id }: { _id: string }) => `${url}/${_id}`}
            searchAutoFocus
        />
    );
}