import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { PollConfig } from "./polls.types";
import { PartiesPollListCfg, usePartiesPollsList } from "./usePartiesPollsList";

export const BasePollConfig = "base";

const ApiPath = "/api/party-poll";

export const usePollConfigEdit = (code: string) => {
  const data = useCrudItem<PollConfig>(`${ApiPath}/config/${code}`, {
    defaultValue: { code: "", config: { fields: [] }},
  });

  return {
    ...data,
  }
}

export type PartiesPollConfigEdit = ReturnType<typeof usePollConfigEdit>;





export const usePartiesPolls = (cfg: PartiesPollListCfg) => {
  const config = usePollConfigEdit(BasePollConfig);

  const polls = usePartiesPollsList(config.data, cfg);

  return {
    config,
    polls,
  }
}
