import React from 'react';
import { EditItemProps } from '../../toolympus/api/useNewItem';
import { FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { CaseSelect } from '../Cases/CaseSelect';
import { LinkPollToCase } from './usePartiesPollsList';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useSingleSchema } from '../../toolympus/hooks/useSchema';


interface Props {
  data: EditItemProps<Partial<LinkPollToCase>>;
}

export const LinkPollToCaseDialog = (props: Props) => {
  const { data } = props;
  const { schema } = useSingleSchema(`/api/party-poll/request/uiconfig`);
  
  return (
    <SimpleDialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle="Привязать опрос к делу"
      save={() => data.save()}
      noSubmitOnEnter
      >
      <FormGrid columns="1fr" noMargin>
        <CaseSelect
          field="case_id"
          schema={schema}
          case_id={data.item?.case_id}
          setCaseId={(cid) => data.update({ case_id: cid || undefined })}
          noArea
          />
        
        <FormControlsForFields
            schema={schema}
            data={data.item}
            onChange={(o,c) => data.update(c)}
            errors={data.errors}
            fields={[
              ["side_kind"],
              ["side_title"],
            ]}
            />
      </FormGrid>
    </SimpleDialog>
  );
}
