import { FieldType, Schema } from "../../hooks/useSchema";

export const ProgramSchema: Schema = {
    _id: { label_id: "robud.program.labels._id" },
    title: { label_id: "robud.program.labels.title" },
    created_at: { label_id: "robud.program.labels.created_at", type: FieldType.datetime },
    data: { label_id: "robud.program.labels.data" },
    operations: { label_id: "robud.program.labels.operations" },
    triggers: { label_id: "robud.program.labels.triggers" },
}

export const ProgramDataSchema: Schema = {
    kind: {
        label_id: "robud.program.data.labels.kind",
        type: FieldType.select,
        values: [{ value: "sql", label: "SQL" }, { value: "constant", label: "Constant" }],
    },
    name: { label_id: "robud.program.data.labels.custom_name", type: FieldType.select, values: [] },
    script: { label_id: "robud.program.data.labels.script", type: FieldType.textlong },
    values: { label_id: "robud.program.data.labels.values", type: FieldType.json, hint_id: "robud.program.data.labels.values_hint" },
}

export const ProgramOperationSchema: Schema = {
    kind: {
        label_id: "robud.program.operations.labels.kind",
        type: FieldType.select,
        values: [{ value: "sql", label: "SQL" }, { value: "email", label: "Email" }],
    },
    name: { label_id: "robud.program.data.labels.custom_name", type: FieldType.select, values: [] },
    script: { label_id: "robud.program.operations.labels.sql.script", type: FieldType.textlong },
    template: { label_id: "robud.program.operations.labels.email.template" },
    user_email: { label_id: "robud.program.operations.labels.email.user_email" },
}

export const TriggerSchema: Schema = {
    trigger_id: { label_id: "robud.program.triggers.labels.trigger_id" },
    is_active: { label_id: "robud.program.triggers.labels.is_active", type: FieldType.bool },
    kind: {
        label_id: "robud.program.triggers.labels.kind",
        type: FieldType.select,
        values: [{ value: "manual", label: "Manual" }, { value: "schedule", label: "Schedule" }],
    },
}

export const TriggerPeriodSchema: Schema = {
    unit: {
        label_id: "robud.program.triggers.labels.configuration.period.unit",
        type: FieldType.select,
        values: [{ value: "d", label: "d" }, { value: "h", label: "h" }, { value: "m", label: "m" }]
    },
    qty: { label_id: "robud.program.triggers.labels.configuration.period.qty", type: FieldType.number },
}

export const TriggerCalendarSchema: Schema = {
    at: { label_id: "robud.program.triggers.labels.configuration.calendar.at" },
    from: { label_id: "robud.program.triggers.labels.configuration.calendar.from", type: FieldType.datetime },
    to: { label_id: "robud.program.triggers.labels.configuration.calendar.to", type: FieldType.datetime },
    dow: {
        label_id: "robud.program.triggers.labels.configuration.calendar.dow",
        type: FieldType.multiselect,
        values: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((dow,idx) => ({ value: idx+1, label: dow })),
    },
    dom: {
        label_id: "robud.program.triggers.labels.configuration.calendar.dom",
        type: FieldType.multiselect,
        values: new Array(31).fill(0).map((_,idx) => ({ value: idx+1, label: (idx+1).toString() })),
    },
}



export const ExecutionSchema: Schema = {
    program_id: { label_id: "robud.executions.labels.program_id" },
    program_title: { label_id: "robud.executions.labels.program_title" },
    trigger_id: { label_id: "robud.executions.labels.trigger_id" },
    execution_id: { label_id: "robud.executions.labels.execution_id" },
    created_at: { label_id: "robud.executions.labels.created_at", type: FieldType.datetime },
    data_rows_nbr: { label_id: "robud.executions.labels.data_rows_nbr" },
    data: { label_id: "robud.executions.labels.data", type: FieldType.json },
    results: { label_id: "robud.executions.labels.results", type: FieldType.json },
    errors: { label_id: "robud.executions.labels.errors", type: FieldType.json },
}
