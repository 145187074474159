import React from 'react';
import { ArbitratorSelection, ArbitratorSelectionData } from './usePresidiumQuestionArbitratorsSelection';
import { ArbitratorsTable } from './QuestionArbitratorsSelection';
import { ActionRow, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { FieldSettingsPopupButton, useFields } from '../../../toolympus/components/schemed';
import { CandidateDetailsDialog } from './CandidateDetailsDialog';
import { useEditable } from '../../../toolympus/api/useEditable';


interface Props {
    data: ArbitratorSelectionData;
}

export const QuestionSelectedArbitrators = ({ data }: Props) => {
    const fields = useFields({
        defaultFields: [
            "lastname",
            "firstname",
            "middlename",
            "country",
            "city",
            "experts_list_site",
            "phone",
            "email",
            "confirmation_status",
            "confirmation_date",
        ],
        extraSettings: {
            confirmation_status: { editable: !data.disableEdits },
            confirmation_date: { editable: !data.disableEdits, cellStyle: { minWidth: 180 } },
        },
        schema: data.schema,
        storageKey: "fields_pres_q_arbitrators_selected",
    });
    const editCandidate = useEditable<ArbitratorSelection>(null);

    return (
        <>
            <ActionRow>
                <OccupyFreeSpace />
                <FieldSettingsPopupButton fieldsSettings={fields} />
            </ActionRow>
            <ArbitratorsTable
                {...data}
                fields={fields.activeFields}
                data={data.selected}
                sorting={undefined}
                openDetails={r => editCandidate.reset(r)}
                allowExclude
                />

            <CandidateDetailsDialog
                title="Кандидат"
                isOpen={!!editCandidate.state}
                close={() => editCandidate.reset()}
                candidate={editCandidate.state}
                update={editCandidate.update}
                questionId={data.question_id}
                canEdit={!data.disableEdits}
                hasChanges={editCandidate.hasChanges}
                changes={editCandidate.changes}
                saveChanges={changes => {
                    if(editCandidate.state) {
                        data.updateArbitrator(editCandidate.state.arbitrator_id, changes);
                    }
                }}
                infoFileEdit
            />
        </>)
}
