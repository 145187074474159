import React, { useState, ReactNode } from 'react';
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormFullColumn } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FormControlsForFields } from '../schemed';
import { Action } from './types';
import { UseActions } from './useActions';

interface PopupProps {
    data: {
        action: Action;
        onAction: (params: any) => void;
        onClose: () => void;
    }
}

export const Popup = ({data: {action, onAction, onClose}}: PopupProps) => {
    const [params, setParams] = useState<any>(action['params-defaults']);

    return <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose}>
        <DialogTitle>{action.title}</DialogTitle>
        <DialogContent>
            {action.description}
            <div>
                <FormFullColumn>
                    <FormControlsForFields 
                        fields={action['params-order'].filter(param => !!action.params[param]).map(param => [param])}
                        schema={action.params}
                        data={params}
                        onChange={(oldData, changes) => setParams({...oldData, ...changes})}
                    />
                </FormFullColumn>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onAction(params)} variant='contained' color="primary">{action.title}</Button>
            <Button onClick={onClose}><FormattedMessage id="statusActions.cancel" /></Button>
        </DialogActions>
    </Dialog>
}

export interface ExtraAction {
    label: string | ReactNode;
    action: () => void;
    disabled?: boolean;
}

interface ActionsProps {
    data: UseActions & {
        title?: string | JSX.Element;
        onAction: (params: any, code: string) => void
    },
    extraActions?: ExtraAction[];
}

export const Actions = (props: ActionsProps) => {
    const { extraActions } = props;
    const {loading, data, title, onAction} = props.data;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAction, setSelectedAction] = useState<Action | null>(null);
    const close = () => {
        setAnchorEl(null);
        setSelectedAction(null);
        if(props.data.cancelAction) {
            props.data.cancelAction();
        }
    };

    if (loading) {
        return <LoadingIndicator />
    }

    const hasActions = data.length > 0 || (extraActions && extraActions.length > 0);

    const actualAction = (selectedAction || props.data.selectedAction)

    return <div>

        <Button
            disabled={!hasActions}
            onClick={e => setAnchorEl(e.currentTarget)}>
            {title || <FormattedMessage id="statusActions.action" />}
        </Button>
        {hasActions &&
            <Menu open={!!anchorEl} anchorEl={anchorEl} keepMounted onClose={close}>
                {data.map(item => 
                    <MenuItem 
                        key={item.code} 
                        onClick={() => setSelectedAction(item)}
                    >
                        {item.title}
                    </MenuItem>
                )}
                {(extraActions || []).map(({ label, action, disabled }) =>
                    <MenuItem key={label?.toString()} onClick={action} disabled={disabled}>
                        {label}
                    </MenuItem>)}
            </Menu>}
        {actualAction && <Popup 
            data={{
                action: actualAction as Action,
                onAction: (params) => {onAction(params, actualAction.code); close();},
                onClose: () => close(),
            }}
        />}
    </div>
}

interface SimpleActionsProps {
    actions: (ExtraAction | null | false)[];
    isLoading?: boolean;
    title?: ReactNode;
    buttonProps?: ButtonProps;
    buttonComponent?: React.ComponentType;
}

export const SimpleActions = ({ actions, isLoading, title, buttonProps, buttonComponent }: SimpleActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const close = () => {
        setAnchorEl(null);
    };

    const actionsReal = (actions || []).filter(a => !!a) as ExtraAction[];

    const hasActions = actionsReal.length > 0;

    const ButtonT = buttonComponent || Button;

    return <div>
        <ButtonT
            disabled={!hasActions || isLoading}
            onClick={e => setAnchorEl(e.currentTarget)}
            {...(buttonProps || {})}>
            {title || <FormattedMessage id="statusActions.action" />}
        </ButtonT>
        {hasActions &&
            <Menu open={!!anchorEl} anchorEl={anchorEl} keepMounted onClose={close}>
                {actionsReal.map(({ label, action, disabled }) =>
                    <MenuItem key={label?.toString()} onClick={() => { action(); close(); }} disabled={disabled}>
                        {label}
                    </MenuItem>)}
            </Menu>}
    </div>
}
