import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FormBody, FormRow, FormColumn } from '../../toolympus/components/primitives/Forms';
import { FormContainer } from '../../toolympus/components/primitives/Modal.styles';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { NewPracticeData } from './practiceData';

interface Props {
    data: NewPracticeData;
}

export const NewPracticeForm = (props: Props) => {
    const { data, isActive, setIsActive, update, submit, isSaving } = props.data;

    const { practice: schema } = useSchema();

    return (
        <Dialog open={isActive} onClose={() => setIsActive(false)} maxWidth="lg">
            <DialogTitle>Практика</DialogTitle>
            <DialogContent>
                <FormContainer id="newpracticeform" onSubmit={e => { e.preventDefault(); submit(); }}>
                    <FormBody>
                        <FormRow>
                            <FormColumn>
                                <FormControlsForFields
                                    fields={[["document_type"], ["document_date"], ["case_casenbr", { disabled: true }]]}
                                    schema={schema}
                                    data={data}
                                    onChange={(_, changes) => update(changes)} />
                            </FormColumn>
                        </FormRow>
                        <FormRow>
                            <FormColumn>
                                <FormControlsForFields
                                    fields={[["procedure"], ["place_country"]]}
                                    schema={schema}
                                    data={data}
                                    onChange={(_, changes) => update(changes)} />
                            </FormColumn>
                            <FormColumn>
                                <FormControlsForFields
                                    fields={[["rules"], ["place_city"]]}
                                    schema={schema}
                                    data={data}
                                    onChange={(_, changes) => update(changes)} />
                            </FormColumn>
                        </FormRow>
                    </FormBody>
                </FormContainer>
            </DialogContent>
            <DialogActions>
                <Button type='submit'
                    form="newpracticeform"
                    disabled={isSaving}
                    color='primary'
                    variant='contained'
                    endIcon={isSaving && <LoadingIndicator sizeVariant="font" />}>
                    Создать
                </Button>
                <Button disabled={isSaving} color='secondary' variant='contained' onClick={() => setIsActive(false)}>Отмена</Button>
            </DialogActions>
        </Dialog>);
}
