import React from 'react';
import { NewItemProps, EditItemProps } from '../../toolympus/api/useNewItem';
import { PollRequest } from './usePartiesPollsPrep';
import { Dialog, FormGrid } from '../../toolympus/components/primitives';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Schema } from '../../toolympus/hooks/useSchema';
import { CaseSelect } from '../Cases/CaseSelect';


interface EditRequestDialogProps {
  isCreate?: boolean;
  schema: Schema;
  data: NewItemProps<Partial<PollRequest>, PollRequest> | EditItemProps<PollRequest>;
}

export const EditRequestDialog = (props: EditRequestDialogProps) => {
  const { isCreate, schema, data } = props;

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      actions={<>
        {(isCreate || (data as any).hasChanges) && <Button color="primary" variant="contained" onClick={() => data.save()}><FormattedMessage id="common.save" /></Button>}
        <Button><FormattedMessage id="common.cancel" /></Button>
      </>}
      dialogTitle="Запрос на опрос">
      
      <FormGrid noMargin columns="1fr">
        {data.item && <>
          <CaseSelect
            field="case_id"
            noArea
            schema={schema}
            setCaseId={(cid,c) => data.update({ casenbr: c?.casenbr, case_id: cid || undefined })}
            case_id={data.item.case_id}
            />
          
          <FormControlsForFields
            schema={schema}
            data={data.item}
            onChange={(o,c) => data.update(c)}
            fields={[
              ["email"],
              ["side_kind"],
              ["side_title"],
            ]}
            />
        </>}
      </FormGrid>
    </Dialog>
  )
}
