import { IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { Buttons, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { Form } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../toolympus/components/primitives/SearchField';
import { FieldSettingsPopupButton, TableForFields, useFields } from '../../toolympus/components/schemed';
import { NewCorpDisputeDialog } from './NewCorpDisputeDialog';
import { partiesTitles, useCorpDisputesList } from './useCorpDisputesList';

export const CorpDisputesListPage = () => {
    const data = useCorpDisputesList();

    const fields = useFields({
        defaultFields: [
            "casenbr",
            "document_title",
            "entity_title",
            "entity_inn",
            "published_date",
        ],
        schema: data.schema,
        storageKey: "fields_corp_disputes",
        blacklist: ["id_counter", "representatives", "change_description", "changes"],
    });
    
    return (
        <Form title="Корпоративные споры (раскрытие)"
            headerItems={
              <Buttons fullWidth alignItems="flex-end">
                <IconButton color='primary' onClick={() => data.newItem.startEditing()}><Add /></IconButton>
                <Typography variant="caption" color="textSecondary">Всего записей: {data.data.length}</Typography>
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                
                <OccupyFreeSpace />
                
                <SearchField
                    autoFocus
                    filter={data.filter.filter}
                    setFilter={data.filter.setFilter}
                    noButton
                  />
                
                
                <FieldSettingsPopupButton fieldsSettings={fields} />
              </Buttons>}>

            
            <TableForFields
                fields={fields.activeFields}
                schema={data.schema}
                data={data.data}
                autoexpander={{ initialRows: 20, increment: 20 }}
                fieldLink={field => ["casenbr", "document_title"].includes(field) ? ({_id}) => `/corp-disputes/${_id}` : null}
                fieldElement={f => {
                    if(["claimants", "respondants", "third_parties", "entity_representative"].includes(f)) {
                        return row => partiesTitles((row as any)[f]);
                    }
                }}
            />
            
            <NewCorpDisputeDialog schema={data.schema} data={data.newItem} />
            
        </Form>
    );
}
