import { useFetch } from '../../../toolympus/api/useFetch';
import { Schema, useSingleSchema } from '../../../toolympus/hooks/useSchema';

export type UseHistoryData = (id: string) => {
    data: {
        _id: string,
        time: string,
        params: Record<string, any>,
        track?: Record<string, any>,
        user_id: string,
        action: string
    }[],
    uiconfig: Schema,
    loading: boolean,
}

export const useHistoryData: UseHistoryData = (id) => {
    const { schema: uiconfig, isLoading: uiconfigLoading } = useSingleSchema(`/api/case/${id}/action/history/uiconfig`);
    const { data, loading } = useFetch([], {url: `/case/${id}/action/history`});

    return {
        data,
        uiconfig,
        loading: loading && uiconfigLoading
    };
}
