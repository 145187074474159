import { Button } from '@mui/material';
import isHotkey from 'is-hotkey';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NewItemProps } from '../../../api/useNewItem';
import { Schema } from '../../../hooks/useSchema';
import { Dialog, FormGrid, SlugEdit } from '../../primitives';
import { FormControlsForFields } from '../../schemed';
import { CMSRecord, NewCMSRecord } from '../types';
import { RecordTypeConfig } from '../useRecordTypeConfig';

interface Props {
    data: NewItemProps<NewCMSRecord, CMSRecord>;
    onCreated: (record: CMSRecord) => void;
    recordConfig: RecordTypeConfig;
    schema: Schema;
}

export const NewRecordPopup = (props: Props) => {
    const save = () => {
        props.data.save()
            .then(r => props.onCreated(r));
    }

    return (
        <Dialog
            isOpen={props.data.isEditing}
            close={props.data.cancel}
            dialogTitle={<FormattedMessage id="cms.list.new_record" values={{ record_type: props.recordConfig.label }} />}
            actions={<>
                <Button onClick={props.data.cancel}><FormattedMessage id="common.cancel" /></Button>
                <Button onClick={() => save()} color="primary"><FormattedMessage id="common.create" /></Button>
            </>}
            noFullscreen>
            
            <FormGrid columns="1fr" onKeyDown={e => {
                if(isHotkey("mod+enter", e)) {
                    save();
                }
            }}>
                <FormControlsForFields
                    data={props.data.item}
                    schema={props.schema}
                    onChange={(o,c) => props.data.update(c)}
                    fields={[
                        ["title", { controlProps: { autoFocus: true }}],
                    ]}
                    errors={props.data.errors}
                    />
                
                {props.recordConfig.slugs &&
                    <SlugEdit
                        row={props.data.item}
                        field="slug"
                        sourceField="title"
                        onChange={(o,c) => props.data.update(c)}
                        schema={props.schema["slug"]}
                        errors={props.data.errors}

                        />}

                {props.recordConfig.languages && props.recordConfig.languages.length > 1 &&
                    <FormControlsForFields
                        data={props.data.item}
                        schema={props.schema}
                        onChange={(o,c) => props.data.update(c)}
                        fields={[
                            ["lang", { autoComplete: true }],
                        ]}
                        errors={props.data.errors}
                        />}
            </FormGrid>

        </Dialog>
    );
}
