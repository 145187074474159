import { useVisibilityTrigger } from "./useVisibilityTrigger";

interface Config {
  step?: number;
  limit: number | undefined;
  update: (v: number) => void;
  currentDataLength: number;
}

export const useVisibilityLimitTrigger = (cfg: Config) => {
  const step = cfg.step || 30;
  const { anchor: pagingAnchor } = useVisibilityTrigger({
    onBecameVisible: () => cfg.update((cfg.limit || step) + step),
    isDisabled: cfg.currentDataLength < (cfg.limit || step),
  });

  return {
    pagingAnchor,
  }
}
