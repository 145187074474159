import React, { PropsWithChildren, useContext, useMemo } from "react";
import { useCrudItem } from "./useSimpleCrud";
import { useSingleSchema } from "../hooks/useSchema";
import { useItemWithControls } from "./useWithControls";
import { useLoadedData } from "../hooks/useLoadedData";

export interface ISettingsContext<T> {
  settings: T;
  reload: () => void;
}


export const createSettingsCombined = <T,>(apiPath: string, dflt: T) => {
  const SettingsContext = React.createContext<ISettingsContext<T>>({ settings: dflt, reload: () => {}});
  const useSettings = () => useContext(SettingsContext);

  const useSettingsEdit = () => {
    const ctx = useSettings();
    const data = useCrudItem(apiPath, { defaultValue: dflt });
    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const withControls = useItemWithControls(data, { schema });

    return {
        ...data,
        save: (extraChanges?: Partial<T> | undefined) => {
          return data.save(extraChanges)
            .then(x => {
              ctx.reload();
              return x;
            })
        },
        settings: data.data,

        schema,
        withControls,
    }
  }

  const SettingsProvider = (props: PropsWithChildren<{}>) => {
    const loaded = useLoadedData<T>(apiPath, dflt);

    const x = useMemo(() => {
      return {
        settings: loaded.data,
        reload: loaded.reload,
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded.data]);
    
    return (
      <SettingsContext.Provider value={x}>
        {props.children}
      </SettingsContext.Provider>
    )
  }


  return {
    SettingsContext,
    SettingsProvider,
    useSettings,
    useSettingsEdit,
  }

}