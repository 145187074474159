import { IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Schema } from '../../hooks/useSchema';
import { FormControl, OnFieldChange } from '../schemed';
import { CurrencyPopup } from './CurrencyPopup';
import { ExtraProps as ExtraPropsT } from '../schemed/FormControlProps';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 4rem 8rem 1fr;
    gap: 1rem;
`;

const BaseCurrency = "RUB";

interface ExtraProps {
    amount?: ExtraPropsT;
    base?: ExtraPropsT;
    currency?: ExtraPropsT;
    rate?: ExtraPropsT;
}

interface Props {
    fieldPrefix: string;
    data: any;
    schema: Schema;
    onChange: OnFieldChange;
    onlyAmounts?: boolean;
    extraProps?: ExtraProps;
    defaultRateDate?: string | null;
}


export const CurrencyAmountEdit = (props: Props) => {
    const [currencyCodeForPopup, setCurrencyCodeForPopup] = useState<string | null>(null)
    const { fieldPrefix, schema, onChange, data, onlyAmounts, extraProps, defaultRateDate } = props;

    const amountField = `${fieldPrefix}_amount`;
    const baseField = `${fieldPrefix}_amount_base`;
    const currencyField = `${fieldPrefix}_currency`;
    const rateField = `${fieldPrefix}_rate`;

    const onFieldUpdate = (row: any, changes: any) => {
        const fullChanges = { ...changes };
        if(changes[currencyField] === BaseCurrency) {
            fullChanges[rateField] = "1.0000";
            fullChanges[baseField] = changes[amountField] || row[amountField];
        }
        onChange(row, fullChanges);
    }
    

    const CurrencyIconButton = () => (
        <IconButton
            color="primary"
            size="small"
            onClick={() => setCurrencyCodeForPopup(data[currencyField] as string)}
            disabled={!data[currencyField] || data[currencyField] === BaseCurrency}
        >
            <Search />
        </IconButton>);

    return (
        <Container>
            <FormControl
                key={amountField}
                field={amountField}
                schema={schema[amountField]}
                extraProps={extraProps?.amount}
                row={data}
                onChange={onFieldUpdate}
                />
            {!onlyAmounts
                ? <FormControl
                    key={currencyField}
                    field={currencyField}
                    schema={{...schema[currencyField], label: "Валюта" }}
                    extraProps={{ ...extraProps?.currency, showValues: true }}
                    row={data}
                    onChange={onFieldUpdate}
                    />
                : <div />}
            {!onlyAmounts
                ? <FormControl
                    key={rateField}
                    field={rateField}
                    schema={{...schema[rateField], label: "Курс" }}
                    extraProps={{ 
                        ...extraProps?.rate, 
                        // actions: () => <CurrencyIconButton />, 
                        precision: 4, 
                        controlProps: {
                            InputProps: {
                                endAdornment: <CurrencyIconButton />,
                            },
                        },
                        disabled: data[currencyField] === BaseCurrency
                    }}
                    row={data}
                    onChange={onFieldUpdate}
                    />
                    : <div />}
            <FormControl
                key={baseField}
                field={baseField}
                schema={{...schema[baseField], label: "В базовой валюте" }}
                extraProps={{ ...extraProps?.base, readOnly: true }}
                row={data}
                onChange={onFieldUpdate}
                />


            <CurrencyPopup 
                code={currencyCodeForPopup}
                defaultDate={defaultRateDate}
                close={rate => {
                    if (rate) {
                        onFieldUpdate(data, { [rateField]: rate}); 
                    }
                    setCurrencyCodeForPopup(null);  
                }}
            />
        </Container>
    );
}
