import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { GetAppOutlined, PublishOutlined } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, LoadingIndicator, OccupyFreeSpace } from '../../primitives';
import { Checkbox } from '../../schemed/Checkbox';
import { useCMSImportExport } from './useCMSImportExport';

interface Props {
    apiPath: string;
}

export const CMSImportExportPage = ({ apiPath }: Props) => {
    const {
        isLoading,
        exportAction,
        importAction,
        recordTypes,
        selectedTypes,
        isAllSelected,
        selectAll,
        selectType
    } = useCMSImportExport(apiPath);

    return (<>
        <Form title={<FormattedMessage id="cms.importexport.title" />}
            formHeaderProps={{ style: { alignItems: "flex-end" }}}
            headerItems={<>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator />}
                
                <Button
                    size="small"
                    color="primary"
                    onClick={() => exportAction.run()}
                    disabled={!exportAction.canRun || exportAction.isRunning}
                    startIcon={<GetAppOutlined />}
                    endIcon={exportAction.isRunning && <LoadingIndicator sizeVariant="s" />}
                    >
                    <FormattedMessage id="cms.importexport.export_action" />
                </Button>

                <Button
                    size="small"
                    color="primary"
                    onClick={() => importAction.run()}
                    disabled={!importAction.canRun || importAction.isRunning}
                    startIcon={<PublishOutlined />}
                    endIcon={importAction.isRunning && <LoadingIndicator sizeVariant="s" />}
                    >
                    <FormattedMessage id="cms.importexport.import_action" />
                </Button>
            </>}>
            
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    field="isAllSelected"
                                    row={{ isAllSelected }}
                                    onChange={(o,c) => selectAll(c.isAllSelected)}
                                    schema={{ label: " "}}
                                    />
                            </TableCell>

                            <TableCell>
                                <FormattedMessage id="cms.importexport.record_type" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="cms.importexport.record_type_label" />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recordTypes.map(r => (
                            <TableRow key={r.record_type}>
                                <TableCell>
                                    <Checkbox
                                        field="isSelected"
                                        row={{ isSelected: selectedTypes.includes(r.record_type) }}
                                        onChange={(o,c) => selectType(r.record_type, c.isSelected)}
                                        schema={{ label: " "}}
                                        />
                                </TableCell>

                                <TableCell>
                                    {r.record_type}
                                </TableCell>
                                <TableCell>
                                    {r.label}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Form>
    </>);
}
