import React from 'react';
import { Buttons, Dialog, Form, Invoker, Link, LoadingIndicator, MoreActionsMenuButton, OccupyFreeSpace, PseudoLink2, SearchField, TabPanel, TabsHeader, useDialogState, useTabsState } from '../../toolympus/components/primitives';
import { Badge, Button, Checkbox, IconButton } from '@mui/material';
import { usePartiesPollsPrep } from './usePartiesPollsPrep';
import { TableForFields } from '../../toolympus/components/schemed';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { CaseSideKindEmailsCount } from './PollsPrepComponents';
import { usePartiesPollsPrepSettings } from './usePartiesPollsPrepSettings';
import { PartiesPollsPrepSettingsForm } from './PartiesPollsPrepSettingsForm';
import { RequestsProcessingDialog } from './RequestsProcessingDialog';
import { FormattedMessage } from 'react-intl';
import { Add, Settings } from '@mui/icons-material';
import { EditRequestDialog } from './EditRequestDialog';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';

interface Props {
  
}


export const PartiesPollsPrepPage = (props: Props) => {
  const data = usePartiesPollsPrep();
  const schemas = useSchema();

  const settings = usePartiesPollsPrepSettings();
  const settingsDialog = useDialogState();

  const tabs = useTabsState([
    [
      "cases",
      <Badge color={data.cases.data.length ? "primary" : "default"} badgeContent={data.cases.isLoading ? <LoadingIndicator sizeVariant="s" /> : data.cases.data.length.toString()}>
        Дела&nbsp;&nbsp;
      </Badge>
    ],
    [
      "requests",
      <Badge color={data.requests.data.length ? "primary" : "default"} badgeContent={data.requests.isLoading ? <LoadingIndicator sizeVariant="s" /> : data.requests.data.length.toString()}>
        Отправка&nbsp;&nbsp;
      </Badge>
    ],
    ["processed_requests", "Обработанные"],
  ], "cases", "tab", "__polls_prep_tab");

  
  return (
    <Form title="Подготовка опросов"
        headerItems={<Buttons fullWidth alignItems="flex-end">
          <Link to="/parties-polls">
            <Button size="small" color="primary">опросы</Button>
          </Link>
          {tabs.current === "requests" && <IconButton size="small" color="primary" onClick={() => data.requests.newRequest.startEditing()} >
            <Add />
          </IconButton>}

          <OccupyFreeSpace />

          {(data.cases.isLoading || data.requests.isLoading || data.processedRequests.isLoading) && <LoadingIndicator />}
          {tabs.current === "cases" && (
            <Button
              size="small"
              color="primary"
              disabled={!data.cases.generateRequests.canRun}
              onClick={() => data.cases.generateRequests.run().then(x => { if(x) { data.requests.reload(); data.cases.reload(); tabs.setCurrent("requests"); } return x; })}
              startIcon={data.cases.generateRequests.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}>
              сгенерировать запросы
            </Button>
          )}
          {tabs.current === "cases" &&
            <SearchField
              {...data.cases.filter}
              autoFocus
              noButton
              small
              />}


          {tabs.current === "requests" && (
            <Button
              size="small"
              color="primary"
              disabled={!data.requests.processing.validateRequests.canRun}
              onClick={() => data.requests.processing.validateRequests.run()}
              startIcon={data.requests.processing.validateRequests.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}>
              отправка опросов
            </Button>
          )}
          {tabs.current === "requests" &&
            <SearchField
              {...data.requests.filter}
              autoFocus
              noButton
              small
              />}

          {tabs.current === "processed_requests" &&
            <SearchField
              {...data.processedRequests.filter}
              autoFocus
              noButton
              small
              />}
          
          <IconButton size="small" onClick={() => settingsDialog.open()}><Settings /></IconButton>
          <PluggableDocumentationButton documentationKey="parties_polls" />
        </Buttons>}>

        <TabsHeader state={tabs} noMargin />


        <TabPanel state={tabs.forTab("cases")}>
          <TableForFields
            data={data.cases.data}
            fields={[
              ["selected", { label: "" }],
              ["casenbr"],
              ["arbitration_start_date"],
              ["arbitration_end_date"],
              ["status"],
              ["emails_count_claimant", { label: "@ истцы" }],
              ["emails_count_respondant", { label: "@ ответчики" }],
              ["emails_count_other", { label: "@ иные" }],
            ]}
            fieldHeader={f => {
              switch(f) {
                case "selected":
                  return (
                    <Checkbox
                      size="small"
                      checked={data.cases.selection.isAllSelected}
                      onChange={e => data.cases.selection.selectAll(e.target.checked)}
                      />)
              }
            }}
            fieldElement={f => {
              switch(f) {
                case "selected":
                  return r => (
                    <Checkbox
                      size="small"
                      checked={data.cases.selection.isSelected(r)}
                      onChange={e => data.cases.selection.select(r, e.target.checked)}
                      />)
                case "emails_count_claimant":
                  return r => <CaseSideKindEmailsCount c={r} sideKind="claimant" />
                case "emails_count_respondant":
                  return r => <CaseSideKindEmailsCount c={r} sideKind="respondant" />
                case "emails_count_other":
                  return r => <CaseSideKindEmailsCount c={r} sideKind="other" />
              }
            }}
            fieldLink={f => {
              switch(f) {
                case "casenbr":
                  return c => `/case/${c._id}`;
              }
            }}
            fieldLinkNewTab
            schema={schemas.case}
            autoexpander={{ initialRows: 20, increment: 20 }}
            />
        </TabPanel>



        <TabPanel state={tabs.forTab("requests")}>
          <TableForFields
            data={data.requests.data}
            fields={[
              ["selected", { label: "" }],
              ["email"],
              ["casenbr"],
              ["side_kind"],
              ["side_title"],
              ["created_datetime", { utcToLocal: true, label: "Создан" }],
              ["actions", { label: " "}],
            ]}
            sorting={data.requests.sorting}
            fieldHeader={f => {
              switch(f) {
                case "selected":
                  return (
                    <Checkbox
                      size="small"
                      checked={data.requests.selection.isAllSelected}
                      onChange={e => data.requests.selection.selectAll(e.target.checked)}
                      />)
              }
            }}
            fieldElement={f => {
              switch(f) {
                case "email":
                  return (r,s,orig) => <PseudoLink2 onClick={() => data.requests.editRequest.startEditing(r)}>{orig}</PseudoLink2>
                case "selected":
                  return r => (
                    <Checkbox
                      size="small"
                      checked={data.requests.selection.isSelected(r)}
                      onChange={e => data.requests.selection.select(r, e.target.checked)}
                      />)
                case "actions":
                  return r => (
                    <MoreActionsMenuButton
                      actions={[
                        ["clone", "Скопировать", () => data.requests.createRequest(r)],
                        ["discard", "Удалить", () => data.requests.discardRequest(r)],
                      ]}
                      />)
              }
            }}
            fieldLink={f => {
              switch(f) {
                case "casenbr":
                  return c => `/case/${c.case_id}`;
              }
            }}
            fieldLinkNewTab
            schema={data.requests.schema}
            autoexpander={{ initialRows: 20, increment: 20 }}
            />
        </TabPanel>


        <TabPanel state={tabs.forTab("processed_requests")}>
          <TableForFields
            data={data.processedRequests.data}
            fields={[
              ["email"],
              ["casenbr"],
              ["side_kind"],
              ["side_title"],
              ["created_datetime", { utcToLocal: true, label: "Создан" }],
              ["processed_datetime", { utcToLocal: true, label: "Обработан" }],
              ["is_after_link"],
            ]}
            fieldLink={f => {
              switch(f) {
                case "casenbr":
                  return r => `/case/${r.case_id}`;
                case "processed_datetime":
                  return r => `/parties-polls?tab=polls&search=${r.merge_group}`;
              }
            }}
            fieldLinkNewTab
            schema={data.processedRequests.schema}
            autoexpander={{ initialRows: 20, increment: 20 }}
            />

          <Invoker onMount={() => data.processedRequests.ensureLoaded()} />
        </TabPanel>


        <Dialog
          dialogTitle="Настройки"
          {...settingsDialog}
          actions={<>
            {settings.hasChanges && <Button color="primary" variant="contained" onClick={() => settings.save()}><FormattedMessage id="common.save" /></Button>}
            <Button onClick={() => settingsDialog.close()}><FormattedMessage id="common.close" /></Button>
          </>}
        >
          <PartiesPollsPrepSettingsForm data={settings} />
        </Dialog>

        <EditRequestDialog
          data={data.requests.editRequest}
          schema={data.requests.schema}
          />

        <EditRequestDialog
          data={data.requests.newRequest}
          schema={data.requests.schema}
          isCreate
          />

        <RequestsProcessingDialog data={data.requests.processing} settings={settings.settings} />
    </Form>
  );
}
