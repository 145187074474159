import React from 'react';
import { TextField, Button, FormControl, FormHelperText } from '@mui/material';
import { Login } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../primitives';

interface Props {
    loginData: Login;
    className?: string;
}

export const LoginForm = (props: Props) => {
    return (
        <form onSubmit={e => { e.preventDefault(); props.loginData.send(); }} className={props.className}>
            <OuterCentralizedForm>
                <LoginFormFields loginData={props.loginData} />
            </OuterCentralizedForm>
        </form>
    )
};

export const LoginFormFields = (props: Omit<Props, "className"> & { noButton?: boolean }) => {
  const {
    data: credentials,
    update: onChangeCredentials,

    isSecondFactorRequested,
    code,
    setCode,
  } = props.loginData;

  return (<>
    <TextField
      key="email"
      autoFocus
      label={<FormattedMessage id="login.email"/>}
      name='email'
      variant='outlined'
      value={credentials.login}
      disabled={isSecondFactorRequested}
      onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || '' })} />
    {!isSecondFactorRequested && <TextField
      key="password"
      label={<FormattedMessage id="login.password"/>}
      name='password'
      type='password'
      variant='outlined'
      value={credentials.password}
      onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || '' })} />}
    {isSecondFactorRequested && <FormControl>
      <TextField
        key="code"
        autoFocus
        label={<FormattedMessage id="login.code"/>}
        name='code'
        type='code'
        variant='outlined'
        value={code}
        onChange={e => setCode(e.target.value || '')} />
      <FormHelperText><FormattedMessage id="login.code_hint"/></FormHelperText>

    </FormControl>}
    {!props.noButton && 
      <Button
        type='submit'
        variant='contained'
        color='primary'
        startIcon={props.loginData.isSaving ? <LoadingIndicator color="inherit" sizeVariant="s" /> : undefined}>
        <FormattedMessage id="login.submit"/>
      </Button>}
  </>)
}

export const LoginFormUnwrapped = (props: Props) => {
  

  return (
      <form onSubmit={e => { e.preventDefault(); props.loginData.send(); }} className={props.className}>
        <LoginFormFields loginData={props.loginData} />
        
        <Button type='submit' variant='contained' color='primary'><FormattedMessage id="login.submit"/></Button>
      </form>
  )
};

