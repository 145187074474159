import { Button } from '@mui/material';
import { Check, Warning } from '@mui/icons-material';
import React from 'react';
import { useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { Alert, AlertColor } from '../../../toolympus/components/primitives/Alert';
import { TableForFields } from '../../../toolympus/components/schemed';
import { OptionSchema } from './QuestionOptionsForm';
import { PresidiumQuestionResultData } from './usePresidiumQuestionResult';

interface Props {
    data: PresidiumQuestionResultData;
}

export const PresidiumQuestionOptionResults = (props: Props) => {
    const { data, canFinish, finish, question } = props.data;

    const doFinish = useActionWithConfirmation(finish, {
        canRun: canFinish,
        title: "Завершить голосование по вопросу",
        confirmationHint: `Выбран вариант: ${(data.options || []).find(o => o.win)?.label || "-"}. Вопрос будет переведен в статус "закрыт"`,
        confirmationLabel: "завершить",
    });

    const isClosed = question.is_closed;

    return (
        <>
            <div key="alerts">
                {!data.has_enough_votes &&
                    <Alert key="not-enough-votes" title="Кворум не собран" icon={<Warning htmlColor={AlertColor.error} />} />}
                {!data.has_all_votes && data.has_enough_votes &&
                    <Alert key="missing-votes" title="Нет части голосов" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_admin_votes &&
                    <Alert key="admin-votes" title="Есть голоса проставленные аппаратом" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_unbroken_tie &&
                    <Alert key="ties" title="Есть варианты с равными результатами" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_enough_votes &&
                    <Alert key="all-votes" title="Кворум собран" icon={<Check htmlColor={AlertColor.success} />} />}
                {data.has_enough_votes && canFinish && !isClosed && (
                    <Alert
                        key="done"
                        icon={<Check htmlColor={AlertColor.success} />}
                        title={`Вариант выбран (${data.options.find(o => o.win)?.label || ""}) - можно завершать голосование по вопросу`}
                        action={<Button size="small" variant="contained" color="primary" onClick={doFinish.run}>завершить</Button>}
                        />)}
            </div>

            <TableForFields
                dense
                data={data.options || []}
                schema={OptionSchema}
                fields={[
                    ["label"],
                    ["score"],
                    ["suggested_by"],
                ]}
                rowStyle={o => o.win ? { background: "#00cc0030" } : undefined}
                />

        </>
    );
}
