import { useMemo } from "react";
import { useSettings } from "./SettingsPage"
import { toMap } from "../toolympus/api/data";

export const useRegionCityDefaulter = () => {
  const { settings } = useSettings();

  const defaulter = useMemo(() => {
    if(!settings.geo_region_to_city_default) {
      return () => "";
    } else {
      const items = settings.geo_region_to_city_default.split("\n")
        .map(l => l.trim())
        .filter(l => !!l)
        .map(l => l.split("->").map(s => s.trim()));
      const mapping = toMap(items, i => i[0].toLowerCase(), i => i[1]);
      return (region: string | undefined) => mapping[(region || "").trim().toLowerCase()] || "";
    }
  }, [settings]);

  return defaulter;
}
