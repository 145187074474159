import React from 'react';
import { FormControl as MuiFormControl, InputLabel, Input } from '@mui/material';
import { FieldType } from '../../hooks/useSchema';
import { expandProps, FormControlProps } from './FormControlProps';
import { Hint } from './Hint';

interface TextEditProps extends FormControlProps {
    isPassword?: boolean;
}

export const TextEdit = (props: TextEditProps) => {
    const { row, field, schema, onChange, value, label, extraProps, controlProps, error } = expandProps(props);

    const { disabled } = extraProps || {};
    const { rows } = controlProps.view;
    const isLong = schema?.type === FieldType.textlong || rows;

    const processChange = extraProps?.processChange || (v => v);

    return <MuiFormControl fullWidth size="small" error={error} required={controlProps.required}>
        <InputLabel>{label}</InputLabel>
        <Input
            type={props.isPassword ? "password" : undefined}
            {...controlProps}
            multiline={isLong}
            rows={rows || undefined}
            value={value || ""} 
            onChange={({target: {value}}) => onChange(row, {[field]: processChange(value)})} 
            disabled={disabled}
        />
        <Hint schema={schema} extraProps={extraProps} />
    </MuiFormControl>;
}
