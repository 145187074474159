import { useMemo } from "react";
import { useLoadedData } from "../../hooks/useLoadedData"
import { QueryChunk, QueryChunksConfig } from "./types"
import { createSelectSchema } from "../../hooks/useSchema";
import { useBrowserStoredValue } from "../../hooks/useBrowserStoredValue";
import { useCrudItem } from "../../api/useSimpleCrud";
import { useEditItem2, useNewItem } from "../../api/useNewItem";
import { apiFetch } from "../../api/core";

export const useQueryChunksConfiguration = (apiPath: string) => {
  const available = useLoadedData<QueryChunksConfig[]>(apiPath, []);

  const schema = useMemo(() => {
    return {
      code: createSelectSchema(available.data.map(s => ({ value: s.code, label: s.code })), { label_id: "dataschema.query_chunks.code" }),
    };
  }, [available.data]);

  const current = useBrowserStoredValue("", "config", "__tl_dsqc_config_current");

  const data = useCrudItem<QueryChunksConfig>(`${apiPath}/${current.value}`, {
    defaultValue: { code: "" },
    noLoad: !current.value,
    resetChangesOnReload: true,
  });

  const newItem = useNewItem<Partial<QueryChunksConfig>, QueryChunksConfig>(apiPath, { code: "" });

  const chunkTest = useEditItem2<{ chunks: QueryChunk[], query?: string}>({
    dontResetOnSave: true,
    save: (item, changes) => {
      return apiFetch<{ query: string }>(`${apiPath}/test/${current.value}`, "post", { chunks: item.chunks })
        .then(x => {
          return ({ chunks: item.chunks, query: x.query });
        });
    }
  });

  return {
    schema,
    current,
    newItem: {
      ...newItem,
      save: (e?: Partial<QueryChunksConfig>) => {
        return newItem.save().then(x => { available.reload(); current.update(x.code); return x; });
      },
    },
    chunkTest: {
      ...chunkTest,
      save: (e?: Partial<{ chunkId: string, query?: string}>) => {
        return chunkTest.save(e)
          .then(x => {
            chunkTest.setItem(x);
            return x;
          });
      },
    },
    data,
    isLoading: data.isLoading || available.isLoading,
  }
}


export type QueryChunksConfigurationData = ReturnType<typeof useQueryChunksConfiguration>;
