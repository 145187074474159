import React, { HTMLProps, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Menu } from '@mui/icons-material';
import { ReorderItemsData } from './useReorderItemsBySortorder';
import { Dialog } from '../Dialogs';
import { LoadingIndicator } from '../LoadingIndicator';

export const ReorderableItemsWrapper = styled.div`
`;

export interface ReorderableItemProps {
  idx: number;
  isDragTarget?: boolean;
  isDragged?: boolean;
}

export const ReorderableItem = styled.div<ReorderableItemProps>`
  padding: 0.5rem 1.5rem 0.5rem 2rem;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.palette.grey[400]};
  border-top: ${props => props.isDragTarget ? 3 : 0}px solid ${props => props.theme.palette.primary.main};
  cursor: pointer;
  opacity: ${props => props.isDragged ? 0.5 : 1};

  &:before {
    display: ${props => props.idx ? "block" : "none"};
    content: '${props => props.idx}.';
    position: absolute;
    top: 0.67rem;
    left: 0;
    font-size: 0.85rem;
    color: ${props => props.theme.palette.text.secondary};
  }

  & .drag-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.85rem;
    color: ${props => props.theme.palette.text.secondary};
  }

  &:hover {
    & .drag-icon {
      color: ${props => props.theme.palette.text.primary};
    }
  }
`;

interface Props<T> {
  data: ReorderItemsData<T>;
  title: ReactNode;
  ItemComponent: React.ComponentType<{ item: T }>;
  itemKey: (item: T) => string;

  WrapperComponent?: React.ComponentType<HTMLProps<HTMLDivElement>>;
  ItemWrapperComponent?: React.ComponentType<HTMLProps<HTMLDivElement> & ReorderableItemProps>;
}

export const ReorderItemsDialog = <T,>(props: Props<T>) => {
  const { data, ItemComponent } = props;
  const [draggingItem, setDraggingItem] = useState<T | null>(null);
  const [dragTarget, setDragTarget] = useState<T | null>(null);

  const dropItemToIdx = (targetIdx: number) => {

    if(data.items && draggingItem) {
      setDraggingItem(null);
      setDragTarget(null);
      data.moveItemTo(draggingItem, targetIdx);
    }
  }

  const WrapperComponent = props.WrapperComponent || ReorderableItemsWrapper;
  const ItemWrapperComponent = props.ItemWrapperComponent || ReorderableItem;

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={props.title}
      actions={<>
        {data.isSaving && <LoadingIndicator />}
        <Button onClick={() => data.save()} color="primary" variant="contained" disabled={data.isSaving}><FormattedMessage id="common.save" /></Button>
        <Button onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>
      </>}>
      {data.items?.length &&
        <WrapperComponent>
          {data.items.map((item,idx) => (
            <ItemWrapperComponent
              idx={idx+1}
              key={props.itemKey(item)}
              draggable
              onDragStart={() => {
                setDraggingItem(item);
              }}
              onDragEnter={() => {
                setDragTarget(item);
              }}
              onDragOver={e => {
                e.preventDefault();
                setDragTarget(item);
              }}
              onDragLeave={() => {
                setDragTarget(x => x === item ? null : x);
              }}
              onDrop={e => {
                e.preventDefault();
                dropItemToIdx(idx);
              }}
              isDragTarget={dragTarget === item}
              isDragged={draggingItem === item}
              >
              <ItemComponent item={item} />

              <Menu className="drag-icon" />
            </ItemWrapperComponent>
          ))}
        </WrapperComponent>}
    </Dialog>
  );
}
