import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { PartiesPollsRequestsProcessingData, PollRequest } from './usePartiesPollsPrep';
import { Dialog, Link, LoadingIndicator, useCopyText } from '../../toolympus/components/primitives';
import { Button, Checkbox, Typography } from '@mui/material';
import { NewTabLinkProps, useFormats } from '../../toolympus/components/schemed';
import { utc } from '../../toolympus/components/timezone';
import { FormattedMessage } from 'react-intl';
import { PartiesPollsPrepSettings } from './usePartiesPollsPrepSettings';

interface Props {
  data: PartiesPollsRequestsProcessingData;
  settings: PartiesPollsPrepSettings;
}

const SideKindLabel: Record<string, string> = {
  claimant: "истец",
  respondant: "ответчик",
  other: "иной",
}

const RequestText = styled(Typography)`
  & .details {
    color: ${props => props.theme.palette.text.secondary};
    font-size: 0.85em;
  }
`;

interface RequestDisplayProps {
  r: PollRequest;
  isSelected: boolean;
  select: (r: PollRequest, selected: boolean) => void;
  extra?: ReactNode;
}

const RequestDisplay = ({ r, select, isSelected, extra }: RequestDisplayProps) => {
  const copyText = useCopyText();

  return (
    <RequestText>
      <Checkbox
        size="small"
        checked={isSelected}
        onChange={e => select(r, e.target.checked)}
        />
      <span onClick={() => copyText(r.email)}>{r.email}</span>{extra}, <span className="details"><Link to={`/case/${r.case_id}`} {...NewTabLinkProps}>{r.casenbr}</Link> ({r.side_title}, {SideKindLabel[r.side_kind]})</span>
    </RequestText>
  )
}

const RequestsList = styled.ol`
  padding-left: 1rem;
`;

export const RequestsProcessingDialog = (props: Props) => {
  const { data } = props;

  const { formatDatetimeShort } = useFormats();

  const [validationMerged, validationDiscardable, validationOk] = useMemo(() => {
    const reqs = (data.processingRequests || []);
    const validation = (data.validationResult || {});
    return [
      reqs.filter(r => validation[r._id]?.result === "merge").reduce<PollRequest[][]>((result,r) => {
        const mergeGroup = result.find(x => validation[x[0]._id].merge_group === validation[r._id].merge_group);
        if(mergeGroup) {
          mergeGroup.push(r);
        } else {
          result.push([r]);
        }
        return result;
      }, []),
      reqs.filter(r => validation[r._id]?.result === "repeated"),
      reqs.filter(r => validation[r._id]?.result === "ok"),
    ];
  }, [data.validationResult, data.processingRequests]);

  return (
    <Dialog
      dialogTitle="Отправка опросов"
      isOpen={data.isActive}
      close={() => data.cancel()}
      fullWidth
      maxWidth="md"
      actions={<>
        {data.isInValidation && <Button
          color="primary"
          variant="contained"
          onClick={() => data.processRequests.run()}
          disabled={!data.processRequests.canRun}
          startIcon={data.processRequests.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}>
          создать и отправить опросы
        </Button>}
        {data.isInValidation && <Button onClick={() => data.cancel()}>
          <FormattedMessage id="common.cancel" />
        </Button>}
        {data.isInResult && <Link to="/parties-polls?tab=polls">
          <Button color="primary" variant="contained">
            к опросам
          </Button>
        </Link>}
        {data.isInResult && <Button onClick={() => data.cancel()}>
          <FormattedMessage id="common.close" />
        </Button>}
      </>}>

      {data.isInResult &&
        <Typography><strong>{data.createdPolls?.length}</strong> опросов создано. Система сама отправит их адресатам в течение нескольких минут - это окно можно закрыть.</Typography>}

      {data.isInValidation && <>

        <Typography>Вы можете снять выбор у тех опросов, которые не нужно отправлять.</Typography>
      
        {!!validationDiscardable.length && <>
          <Typography>Следущим адресатам за последние <strong>{props.settings.poll_prevent_repeat_days}</strong> дней <strong>уже отправлялся опрос</strong>:</Typography>
          <RequestsList>
            {validationDiscardable.map(r => (
              <li key={r._id}>
                <RequestDisplay
                  r={r}
                  isSelected={data.selection.isSelected(r)}
                  select={data.selection.select}
                  extra={` - ${formatDatetimeShort(utc.toLocal((data.validationResult || {})[r._id]?.original_datetime || ""))}`}
                  />
              </li>))}
          </RequestsList>
        </>}

        {!!validationMerged.length && <>
          <Typography>Для следующих адресатов несколько опросов будут объединены в один:</Typography>
          <RequestsList>
            {validationMerged.map(rs => (
              <li key={rs[0].merge_group}>
                {rs.map(r => (
                  <RequestDisplay key={r._id} r={r} isSelected={data.selection.isSelected(r)} select={data.selection.select} />
                ))}
              </li>))}
          </RequestsList>
        </>}

        {!!validationOk.length && <>
          <Typography>Для следующих адресатов опросы будут сгенерированы без изменений:</Typography>
          <RequestsList>
            {validationOk.map(r => (
              <li key={r._id}>
                <RequestDisplay r={r} isSelected={data.selection.isSelected(r)} select={data.selection.select} />
              </li>))}
          </RequestsList>
        </>}
      
      </>}
    </Dialog>
  );
}
