export const Messages = {
    arbitrators: {
        error: {
            cant_delete_has_cases: "Невозможно удалить арбитра, назначенного на дела",
            need_reason_for_temp_dont_assign: "Необходимо указать причину, по которой арбитр временно не назначается",
        }
    },

    cases: {
        error: {
            cant_delete_has_arbitrators: "Невозможно удалить дело, у которого назначены арбитры",
            cant_update_checked: "Дело проверено - изменения заблокированы",
            need_claimant_and_respondant: "Должны быть указаны истец и ответчик",
            case_and_arbitrator_already_linked: "Арбитр уже участвует в деле",
            case_and_arbitrator_not_linked: "Этот арбитр не участвует в деле",
            
            cant_have_decision_and_agreed_terms: "По делу не может одновременно быть решение и согласованные условия",
            cant_have_decision_on_returned_case: "По возвращенному делу не может быть решения",
        }
    },

    presidium: {
        error: {
            cant_delete_started: "Нельзя удалить запущенное голосование",
            cant_start_jury_vote_without_dates: "Для запуска голосования по составу арбитража необходимо указать даты",
            contradicting_dates: "Некорректные даты",
            question_already_assigned: "Вопрос уже включен в другое голосование",
            wrong_committee: "Комитет вопроса отличается от комитета голосования",

            cant_select_arbitrator_without_confirmation: "Для выбора арбитра требуется получение согласия",
            question_is_closed: "Вопрос закрыт",
            question_already_closed: "Вопрос закрыт",
            question_not_closed: "Вопрос еще не закрыт",
            question_missing_votes: "По вопросу получены не все голоса",
            no_candidates_selected: "Не выбран арбитр",
            wrong_nbr_of_candidates_selected: "Выбрано некорректное число арбитров",
            confirmation_date_missing_for_selected_candidates: "Необходимо указать даты получения согласия для выбранных кандидатов",
            cant_finish_question: "Невозможно завершить вопрос",
            
            vote_not_all_positions_filled: "Необходимо заполнить все позиции",
            vote_wrong_candidate: "Выбран кандидат, не участвующий в голосовании",
            vote_same_candidate_twice: "Один и тот же кандидат указан дважды",
            must_select_at_least_one_candidate: "Необходимо выбрать хотя бы одного кандидата",

            cant_suggest_at_this_stage: "Невозможно предложить вариант на этой стадии вопроса",
            cant_suggest_candidates_for_options_question: "Невозможно предложить кандидата для вопроса по вариантам",
            cant_suggest_options_for_candidates_question: "Невозможно предложить вариант для вопроса по кандидатам",
            cant_suggest_with_conflict: "Невозможно предложить вариант при наличии конфликта",
            cant_vote_against_all_options: "Невозможно проголосовать против всех вариантов",
            invalid_question_response: "Некорректный формат ответа",
            wrong_option: "Такого варианта не существует для этого вопроса",
        },
    },

    corp_disputes: {
        error: {
            cant_update_published_without_description: "Для обновления опубликованной записи необходимо указать описание",
        }
    },

    system: {
        snapshots: {
            title: "Снэпшоты БД",
            create_snapshot: "Создать снэпшот",
        },
    },
}
