import React from 'react';
import { IconButton } from '@mui/material';
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { DocumentTitle } from '../PowerDoc';
import { FormBody, FormHeader, FormWrapper, LoadingIndicator, SaveButton, useWindowHotkey } from '../primitives';
import { DocumentationPageData } from './useDocumentationPages';
import { DocumentationEditor } from './DocumentationEditor';
import { CurrentDocumentationPageProvider } from './CurrentDocumentationPageContext';
import { Breadcrumbs } from './Breadcrumbs';
import { DocumentationPagesConfig } from './DocumentationPagesContext';
import { useSaveable } from '../primitives/useSaveable';

interface Props extends DocumentationPagesConfig {
    data: DocumentationPageData;
}

export const DocumentationPageUnbound = (props: Props) => {
    const { data } = props;

    const Editor = props.editor || DocumentationEditor;

    useSaveable(data);
    useWindowHotkey("alt+e", () => !props.noEdit && data.setViewMode(!data.viewMode));

    return (
        <CurrentDocumentationPageProvider page={data.data}>
            <FormWrapper>
                <Breadcrumbs breadcrumbs={data.data.breadcrumbs} />

                <FormHeader>
                    <DocumentTitle
                        value={data.data.title}
                        placeholder="..."
                        update={v => data.update({ title: v })}
                        readOnly={data.viewMode}
                        style={{ flex: "1 0 auto" }}
                    />

                    {data.isLoading && <LoadingIndicator sizeVariant="s" />}
                    {!props.noEdit && <IconButton size="small" onClick={() => data.setViewMode(!data.viewMode)}>
                        {data.viewMode ? <EditOutlined /> : <VisibilityOutlined />}
                    </IconButton>}
                    {data.hasChanges && <SaveButton action={() => data.save()} />}
                </FormHeader>

                <FormBody>
                    <Editor
                        key={data.data._id}
                        content={data.data.content}
                        update={v => data.update({ content: v })}
                        viewMode={data.viewMode}
                        />
                </FormBody>
            </FormWrapper>
        </CurrentDocumentationPageProvider>
    );
}
