import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { QueryChunksConfigurationData } from './useQueryChunksConfiguration';
import { useQueryChunksField } from './useQueryChunksField';
import Back from './field_crosses.svg';
import { Dialog, SimpleDialog, Tooltip, useThrottledState } from '../primitives';
import { QueryChunk } from './types';
import { generateCode } from '../PowerDoc/plugins/common';
import { QueryChunkEditForm } from './QueryChunkEditForm';
import { Button, IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DeleteOutlined } from '@mui/icons-material';

interface Props {
  data: QueryChunksConfigurationData;
}

const ChunkBox = styled.div<{ isDragged?: boolean, chunkColor?: string | null }>`
  background: ${props => props.chunkColor
    ? `linear-gradient(${props.chunkColor},${props.chunkColor}), #ffffff`
    : "#ffffff"};
  border-radius: 8px;
  width: 200px;
  padding: 0.5rem;
  box-shadow: 2px 2px 5px -3px #00000080;
  cursor: pointer;
  position: absolute;
  opacity: ${props => props.isDragged ? 0.5 : 1};

  & p {
    margin: 0;
    line-height: 1;
  }
`;

const Field = styled.div<{ w: number, h: number }>`
  min-width: max(100%, ${props => props.w}px);
  min-height: max(100%, ${props => props.h}px);
  flex: 1 0 auto;
  background: url(${Back}), #00000020;
  box-shadow: 0 0 15px -3px #00000020 inset;

  position: relative;
`;

const FieldOuter = styled.div`
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex: 1 0 auto;
  border-radius: 8px;
  overflow: auto;
`;

export const QueryChunksField = (props: Props) => {
  const data = useQueryChunksField(props.data);
  const { chunks } = data;

  const fieldRef = useRef<HTMLDivElement | null>(null);

  const mouseCoords = useRef<{ x: number, y: number}>({ x: 0, y: 0 });
  const [dragging,setDragging] = useThrottledState<QueryChunk | null>(null);

  const [fieldSize,setFieldSize] = useState<{ w: number, h: number }>({ w: 0, h: 0 });

  useEffect(() => {
    let maxX = 0;
    let maxY = 0;

    chunks.forEach(c => {
      if(c.x && c.x > maxX) {
        maxX = c.x;
      }
      if(c.y && c.y > maxY) {
        maxY = c.y;
      }
    });

    setFieldSize({ w: maxX + 300 + 10 + 10, h: maxY + 50 + 10 + 10 });
  }, [chunks]);
  

  return (<>
  <FieldOuter>
    <Field
      ref={fieldRef}
      w={fieldSize.w}
      h={fieldSize.h}
      onMouseMove={e => {
        const fieldRect = fieldRef.current?.getBoundingClientRect();
        const x = e.clientX - (fieldRect?.left || 0);
        const y = e.clientY - (fieldRect?.top || 0);
        mouseCoords.current = { x, y };
      }}
      onClick={e => {
        const { x, y } = mouseCoords.current;
        data.newChunk.startEditing({ _xid: generateCode(), _id: "", x, y });
      }}
      onDragEnter={e => { e.preventDefault(); }}
      onDragOver={e => { e.preventDefault(); }}
      onDragLeave={e => { e.preventDefault(); }}
      onDrop={(e) => {
        const item = dragging;
        if(item) {
          
        const fieldRect = fieldRef.current?.getBoundingClientRect();
        const x = e.clientX - (fieldRect?.left || 0);
        const y = e.clientY - (fieldRect?.top || 0);
          setDragging(null);
          data.updateChunk(item._xid, { x, y });
        }
      }}>
      {chunks.map(c => (
        <Tooltip text={c.description || c.title || c._id}>
          <ChunkBox
            key={c._id || ""}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if(e.ctrlKey) {
                data.cloneChunk(c);
              } else {
                data.editChunk.startEditing(JSON.parse(JSON.stringify(c)));
              }
            }}
            style={{ left: c.x || 0, top: c.y || 0 }}
            
            draggable
            isDragged={c === dragging}
            chunkColor={c.color}
            onDragStart={() => setDragging(c)}>
              <Typography>{c.title || c._id}</Typography>
              {!!c.title && <Typography variant="caption" color="textSecondary">{c._id}</Typography>}
          </ChunkBox>
        </Tooltip>
      ))}


    </Field>
    </FieldOuter>

    <SimpleDialog
      dialogTitle="New chunk"
      isOpen={data.newChunk.isEditing}
      close={() => data.newChunk.cancel()}
      save={() => data.newChunk.save()}>
      
      <QueryChunkEditForm
        data={data.newChunk}
        />
    </SimpleDialog>

    <Dialog
      dialogTitle="Edit chunk"
      isOpen={data.editChunk.isEditing}
      close={() => data.editChunk.cancel()}
      noSubmitOnEnter
      titleActions={
        <>
          <IconButton
            size="small"
            onClick={() => {
              if(data.editChunk.item) {
                const xid = data.editChunk.item._xid;
                data.editChunk.cancel();
                data.removeChunk(xid);
              }
            }}>
            <DeleteOutlined />
          </IconButton>
        </>
      }
      actions={<>
        <Button onClick={() => data.editChunk.cancel()}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button color="primary" variant="contained" onClick={() => data.editChunk.save()}>
          <FormattedMessage id="common.save" />
        </Button>
      </>}>
      
      <QueryChunkEditForm
        data={data.editChunk}
        />
    </Dialog>
  </>);
}
