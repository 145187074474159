import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { EditItemProps, NewItemProps } from '../../../toolympus/api/useNewItem';
import { Buttons, FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { CalendarEvent, DurationOptions, DayEndTime, DayStartTime } from './useCalendarEvents';
import { PowerEditorFormControl } from '../../../toolympus/components/PowerDoc/slate/PowerEditorFormControl';
import { useItemWithControls } from '../../../toolympus/api/useWithControls';
import { Chip } from '@mui/material';
import { CaseSelect } from '../../Cases/CaseSelect';
import { InsetPanel } from '../../Common/UI';
import { DateAndTimeEdit } from '../../../toolympus/components/schemed/TimeEdit';
import moment from 'moment';
import { DateInternalFormat, TimeInternalFormat } from '../../../toolympus/api/datetimeUtil';

interface Props {
  data: EditItemProps<CalendarEvent> | NewItemProps<Partial<CalendarEvent>, CalendarEvent>;
  schema: Schema;
}

const DatesButtons = styled(Buttons)`
  flex-flow: row wrap;
`;


export const EditCalendarEventDialog = (props: Props) => {
  const { data } = props;

  const { controls } = useItemWithControls({ data: data.item || {}, update: data.update, errors: data.errors }, { schema: props.schema });

  const [isMultiDate, setIsMultiDate] = useState<boolean>(false);

  useEffect(() => {
    if(data.item) {
      if(!data.item.start_datetime && !data.item.end_datetime) {
        setIsMultiDate(false);
      } else if(!!data.item.start_datetime && !!data.item.end_datetime 
        && moment(data.item.start_datetime).format(DateInternalFormat) === moment(data.item.end_datetime).format(DateInternalFormat)) {
          setIsMultiDate(false);
      } else {
        setIsMultiDate(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.isEditing]);

  return (
    <SimpleDialog
      dialogTitle="Событие"
      noFullscreen
      submitOnModEnter
      maxWidth="md"
      fullWidth
      isOpen={data.isEditing}
      close={() => data.cancel()}
      save={() => data.save()}>
      {!!data.item &&
        <FormGrid columns="1fr">
          <FormGrid noMargin columns="3fr 200px">
            {controls([
              ["title", { controlProps: { autoFocus: true }}],
            ])}
            <CaseSelect
              field="case_id"
              schema={props.schema}
              setCaseId={id => data.update({ case_id: id })}
              case_id={data.item?.case_id}
              noArea
              />
            <div />
            {controls([
              ["confirmation"],
            ])}
          </FormGrid>


            {isMultiDate &&
              <FormGrid columns="3fr 3fr 200px" alignItems="center">
                {controls([
                  ["start_datetime"],
                  ["end_datetime", {
                    readOnly: data.item?.duration_option === DurationOptions.full_day || data.item?.duration_option === DurationOptions.till_day_end,
                  }],
                ])}

                <DatesButtons>
                  <Chip
                    size="small"
                    variant="outlined"
                    label="однодневное"
                    onClick={() => {
                      setIsMultiDate(false);
                      data.update({ end_datetime: `${moment(data.item?.start_datetime).format(DateInternalFormat)} ${moment(data.item?.end_datetime).format(TimeInternalFormat)}`})
                    }}
                    />
                </DatesButtons>
              </FormGrid>}

            {!isMultiDate &&
              <FormGrid columns="2fr 2fr 2fr 200px" alignItems="center">
                <DateAndTimeEdit
                  row={data.item || {}}
                  field="start_datetime"
                  onChange={(o,c) => data.update(c)}
                  schema={{}}
                  dateSchema={{ label: "Дата"}}
                  timeSchema={{ label: "Начало"}}
                  />

                <DateAndTimeEdit
                  row={data.item || {}}
                  field="end_datetime"
                  noDate
                  onChange={(o,c) => data.update(c)}
                  schema={{}}
                  timeSchema={{ label: "Окончание"}}
                  />

                <DatesButtons>
                  <Chip
                    size="small"
                    color={data.item?.duration_option === DurationOptions.full_day ? "primary" : "default"}
                    variant={data.item?.duration_option === DurationOptions.full_day ? "filled" : "outlined"}
                    label="до конца дня"
                    onClick={() => {
                      data.update({ 
                        end_datetime: `${moment(data.item?.end_datetime).format(DateInternalFormat)} ${DayEndTime}`,
                      })
                    }}
                    />
                  <Chip
                    size="small"
                    color={data.item?.duration_option === DurationOptions.full_day ? "primary" : "default"}
                    variant={data.item?.duration_option === DurationOptions.full_day ? "filled" : "outlined"}
                    label="весь день"
                    onClick={() => {
                      data.update({ 
                        start_datetime: `${moment(data.item?.start_datetime).format(DateInternalFormat)} ${DayStartTime}`,
                        end_datetime: `${moment(data.item?.end_datetime).format(DateInternalFormat)} ${DayEndTime}`,
                      })
                    }}
                    />
                  <Chip
                    size="small"
                    variant="outlined"
                    label="многодневное"
                    onClick={() => setIsMultiDate(true)}
                    />
                </DatesButtons>
              </FormGrid>}

            <FormGrid columns="1fr 1fr" alignItems="flex-end">
              {controls([
                ["conf_room_id", { autoComplete: true }],
                ["equipment"],
              ])}
            </FormGrid>
            <InsetPanel>

          {controls([
            ["owners"],
            ["description", { control: PowerEditorFormControl }],
            ["permissions_requests", { control: PowerEditorFormControl }],
          ])}
          </InsetPanel>
        </FormGrid>}
    </SimpleDialog>
  );
}



// :case_id 
// :confirmation 
// :conf_room_id 