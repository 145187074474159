import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
    Button,
    Table,
    TextField,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TableContainer} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ClipboardButton } from '../primitives/Buttons';
import { Form, FormFullColumn, FormRow } from '../primitives/Forms';
import { QueryConsoleData } from './useQueryConsole';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { QueryHistoryPopup } from './QueryHistoryPopup';
import isHotkey from 'is-hotkey';

const ErrorView = styled.pre`
    width: 100%;
    background: ${props => props.theme.palette.grey[100]};
    padding: 2rem 1rem;
    font-family: 'Courier New', Courier, monospace;
`;

interface Props {
    data: QueryConsoleData;
}

export const QueryConsole = (props: Props) => {
    const { query, updateQuery, isQueryLoading, run, result, error, toggleNumericDetailsForColumn, resultJSON, resultTSV, keys } = props.data;

    const [isHistoryPopupActive, setHistoryPopupActive] = useState<boolean>(false);

    useEffect(() => {
        const eventHandler = (e: KeyboardEvent) => {
            if (isHotkey("mod+Enter", e)) {
                run()
            }
        } 

        document.addEventListener('keydown', eventHandler);
        return () => document.removeEventListener('keydown', eventHandler);
    }, [run]);

    return (
        <Form>
            <FormRow>
                <FormFullColumn>
                    <TextField
                        label={<FormattedMessage id="queryconsole.title" />}
                        value={query.title}
                        onChange={e => updateQuery({ title: e.target.value })}
                        />
                    <TextField
                        label={<FormattedMessage id="queryconsole.query" />}
                        value={query.query}
                        onChange={e => updateQuery({ query: e.target.value })}
                        multiline
                        rows={10}
                        InputProps={{ style: { fontFamily: "monospace" } }}
                        />
                </FormFullColumn>
            </FormRow>
            <FormRow>
                <ActionRow>
                    <Button color="primary" variant="contained" onClick={() => run()} endIcon={isQueryLoading && <LoadingIndicator sizeVariant="s" />}>
                        <FormattedMessage id="queryconsole.run" />
                    </Button>

                    <OccupyFreeSpace />

                    <ClipboardButton value={resultJSON} />JSON
                    <ClipboardButton value={resultTSV} />TSV

                    <Button color="secondary" onClick={() => setHistoryPopupActive(true)}>
                        <FormattedMessage id="queryconsole.history" />
                    </Button>
                </ActionRow>
            </FormRow>
            <FormRow>
                {error && <ErrorView>
                    {JSON.stringify(error, undefined, 2)}
                </ErrorView>}
                {!error && result && <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {keys.map(k => <TableCell key={k}>
                                        {k} <IconButton size="small" onClick={() => toggleNumericDetailsForColumn(k)}>%</IconButton>
                                        </TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((row,i) => (
                                    <TableRow key={i}>
                                        {keys.map(k => {
                                            const val = row[k];
                                            const toShow = typeof val === "object" && val !== null ? JSON.stringify(val) : val;
                                            return (<TableCell key={k}>{toShow}</TableCell>)
                                            })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
            </FormRow>

            <QueryHistoryPopup
                history={props.data.history}
                restore={props.data.restoreHistory}
                delete={props.data.deleteHistory}
                isActive={isHistoryPopupActive}
                deactivate={() => setHistoryPopupActive(false)}
            />
        </Form>
    );
}
