import React, { useEffect, useState } from "react";
import { Autocomplete } from "./Autocomplete";
import { FieldSchema, FieldType } from "../useSchema";
import { FormControl } from "../../components/schemed";

interface Props {
    label?: JSX.Element | string | number;
    noRequired?:boolean;
    hint?: JSX.Element | string | number;
    disabled?: boolean;
}

interface InputProps extends Props {
    rows?: number;
    [key: string]: any;
}

interface ControlProps {
    schema?: FieldSchema,
    extraProps?: InputProps 
}

type Schema<F> = {
    [key in (keyof F)]?: FieldSchema;
}

interface UseFormProps<T> {
    onChange?: (newState: T, setState: (s: T) => void) => void;
    required? : (keyof T)[];
    fullSchema?: Schema<T>;
}

/**
 * !!! В первоначальное значение нельзя передавать проинициализированное в функции значение.
 * К примеру нельзя делать так: const form = useForm({value: ''});
 * т.к. состояние обновляется всегда когда меняется пропс.
 * 
 * @param initialState Первоначальное значение
 * @param param1 не обязательные параметры для события изменения и перечисления обязательных полей для валидации
 * 
 */
export const useForm = <T extends Object>(initialState: T, props: UseFormProps<T> = {}) => {
    const {onChange, required, fullSchema = ({} as Schema<T>)} = props;

    type Field = keyof T;
    const [state, setState] = useState(initialState);
    const [valid, setValid] = useState(false);

    useEffect(() => setState(initialState), [initialState]);
    useEffect(() => {
        setValid((required || []).filter(field => !state[field]).length === 0);
    }, [required, state]);

    const getLabel = (field: Field, props: Props) => {
        return props.label || (field as string)[0].toUpperCase() + (field as string).slice(1) + (props.noRequired? "" : "*")
    }

    const change = (field: Field, value: any) => {
        setState({...state, [field]: value});
        !!onChange && onChange({...state, [field]: value}, setState);
    }

    const update = (changes: Partial<T>) => {
      setState(s => ({ ...s, ...changes }));
    }



    const input = (field: Field, props: InputProps = {}) => 
        controlOfType(field, FieldType.text, props);

    const checkbox = (field: Field, props: InputProps = {}) => 
        controlOfType(field, FieldType.bool, props);

    const select = <T extends unknown>(
        field: Field, options: T[], 
        getOption: (v: T) => {value: any, label: string}, 
        props: InputProps = {}
    ) => 
        control(field, {
            schema: { 
                type: FieldType.select, 
                label: getLabel(field, props),
                values: options.map(getOption)
            },
            extraProps: props
        });

    const autocomplete = (field: Field, options: string[], props: InputProps = {}) => 
        <Autocomplete 
            value={state[field] as unknown as string}
            onChange={value => change(field, value)}
            label={getLabel(field ,props)}
            suggestions={options}
        />

    const control = (field: Field, {schema, extraProps}: ControlProps = {}) => {
        if (!schema && !fullSchema[field]) {
            throw new Error('Schema not found!!!')
        }

        return <FormControl 
            row={state}
            field={field as string}
            schema={schema || ((fullSchema as any)[field])}
            onChange={(_, changes) => change(field, changes[field])}
            extraProps={extraProps}
        />
    }

    const controlOfType = (field: Field, fieldType: FieldType, extraProps: any = {}) => 
        control(field, {extraProps, schema: {type: fieldType, label: getLabel(field, extraProps)}});

    return { 
        autocomplete,
        input, 
        checkbox, 
        select,
        control,
        controlOfType,
        change,
        update,
        valid,

        state, setState
    };
}