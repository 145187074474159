import { useMemo } from "react";
import { useEditItem2, useNewItem, withActionOnSaveItem } from "../../../toolympus/api/useNewItem";
import { useTextFilter } from "../../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { createSelectSchema, useSingleSchema } from "../../../toolympus/hooks/useSchema";

export interface ConfRoom {
  _id: string;
  title: string;
  description: string;
}

const ApiPath = "/api/organization/calendar/conf-room";

export const useConfRoomsConfiguration = () => {
  const data = useLoadedData<ConfRoom[]>(ApiPath, []);
  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);

  const newItem = useNewItem<Partial<ConfRoom>, ConfRoom>(ApiPath, {
    title: "",
    description: "",
  });

  const editItem = useEditItem2<ConfRoom>({
    getApiPath: r => `${ApiPath}/${r._id}`,
  });

  const filter = useTextFilter<ConfRoom>(r => `${r.title} ${r.description}`);

  return {
    ...data,
    data: filter.filterData(data.data),
    schema,
    newItem: withActionOnSaveItem(newItem, () => { data.reload(); }),
    editItem: withActionOnSaveItem(editItem, () => { data.reload(); }),
    filter,
  }
}


export const useConfRoomsSelection = () => {
  const data = useLoadedData<ConfRoom[]>(ApiPath, []);

  const selectorSchema = useMemo(() => {
    return createSelectSchema(data.data.map(cr => ({ value: cr._id, label: cr.title })));
  }, [data.data]);

  return {
    ...data,
    selectorSchema,
  }
}