import { List, ListItem, ListItemText, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Editor } from 'slate';
import { Dialog, LoadingIndicator } from '../../../primitives';
import { BlocksData } from './useTemplateBlocks';

interface Props {
    data: BlocksData;
    editor: Editor;
}

export const InsertBlockDialog = ({ data, editor }: Props) => {
    return (
        <Dialog
            dialogTitle={<FormattedMessage id="powerdoc.plugins.template_block.dialog_title" />}
            isOpen={data.isOpen}
            close={data.close}
            noFullscreen
            onKeyDown={e => {
                if(e.key === "Enter" && data.blocks.data.length === 1) {
                    data.insertBlock(editor, data.blocks.data[0]);
                    e.preventDefault();
                } else if(e.key === "Escape") {
                    data.close();
                }
            }}
            >
            <TextField
                style={{ width: "100%" }}
                label={<FormattedMessage id="searchField.label" />}
                value={data.filter.filter}
                onChange={e => data.filter.setFilter(e.target.value)}
                autoFocus
                />
            <List>
                {data.blocks.data.map(b => (
                    <ListItem key={b._id} button onClick={() => data.insertBlock(editor, b)}>
                        <ListItemText primary={b.title} />
                    </ListItem>
                ))}
            </List>
            {data.blocks.isLoading && <LoadingIndicator />}
        </Dialog>
    );
}
