import { useState } from "react"

export const useWithPromiseLoading = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return {
        isLoading,
        setIsLoading,
        withLoading: <T,>(p: Promise<T>): Promise<T> => {
            setIsLoading(true);
            return p.then(r => { setIsLoading(false); return r; }).catch(e => { setIsLoading(false); throw e; });
        },
    }
}
