import styled from "@emotion/styled";


export const WidgetsGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  user-select: none;

  & .widget-box {
    display: grid;
    padding: 15px;
  }

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .MuiSvgIcon-root {
      font-size: 14px;
      margin-right: 10px;
    }

    & div:last-child .MuiSvgIcon-root {
      font-size: 14px;
      margin: 0;
    }
  }

  & .arrows {
    display: flex;
    align-items: center;
  }

  & .arrow-icon {
    cursor: pointer;
  }

  & .move-icon {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
`;


export const ParametersPane = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]};

    & .section-title {
        cursor: pointer;
        .MuiButtonBase-root {
            margin-left: 0.5rem;
        }
    }
`;
