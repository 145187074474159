import { useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useAction } from "../../toolympus/api/useAction";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useListSelection } from "../../toolympus/hooks/useListSelection";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { Case } from "../../typings/Cases";
import { PartyPoll } from "./polls.types";
import { BasePollConfig } from "./usePartiesPolls";
import { useFieldSorting } from "../../toolympus/hooks/useFieldSorting";
import { useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";

export interface PollRequest {
  _id: string;
  case_id: string;
  casenbr: string;
  side_kind: string;
  side_title: string;
  email: string;
  merge_group?: string;
  is_processed?: boolean;
  is_discarded?: boolean;
  is_after_link?: boolean;
  created_datetime: string;
  processed_datetime?: string;
}

export interface PollRequestValidationStatus {
  result: "ok" | "merge" | "repeated";
  merge_group?: string;
  original_datetime?: string;
  original_id?: string;
}

const useRequestsProcessing = (requestsIn: PollRequest[]) => {
  const [processingRequests, setProcessingRequests] = useState<PollRequest[] | null>(null);
  const selection = useListSelection<PollRequest>(r => r._id, { items: processingRequests || [] });
  const [validationResult, setValidationResult] = useState<Record<string, PollRequestValidationStatus> | null>(null);
  
  const validateRequests = useAction(() => {
    setProcessingRequests(requestsIn);
    selection.selectMass(requestsIn, true, true);
    return apiFetch<Record<string, PollRequestValidationStatus>>(
      "/api/party-poll/request/validate",
      "post",
      { requests: requestsIn.map(r => r._id) })
      .then(r => { setValidationResult(r); return r; });
  }, !!requestsIn.length);

  const [createdPolls, setCreatedPolls] = useState<PartyPoll[] | null>(null);

  const processRequests = useAction(() => {
    return apiFetch<PartyPoll[]>("/api/party-poll/request/generate",
      "post",
      { poll_config_code: BasePollConfig, requests: selection.selectedItems.map(r => r._id)})
      .then(polls => {
        setCreatedPolls(polls);
        return polls;
      });
  }, !!selection.selectedItems.length);

  return {
    processingRequests,
    validationResult,
    validateRequests,
    selection,

    processRequests,
    createdPolls,

    isInValidation: !!validationResult && !createdPolls,
    isInResult: !!createdPolls,
    isActive: !!validationResult || !!createdPolls,

    cancel: () => {
      setProcessingRequests(null);
      selection.selectAll(false);
      setValidationResult(null);
      setCreatedPolls(null);
    },
  }
}

export type PartiesPollsRequestsProcessingData = ReturnType<typeof useRequestsProcessing>;


export const usePartiesPollsPrep = () => {
  const cases = useLoadedData<Case[]>(`/api/party-poll/request/cases`, []);
  const casesFilter = useTextFilter<Case>(c => `${c.casenbr} ${c._id}`);
  const casesSelection = useListSelection<Case>(c => c._id, { items: cases.data });

  const generateRequestsForSelectedCases = useAction(() => {
    return apiFetch<any[]>(`/api/party-poll/request/cases/generate`, "post", { cases: casesSelection.selectedItems.map(c => c._id) })
      .then(r => {
        return r.length > 0;
      })
  }, !!casesSelection.selectedItems.length);


  const requestsSorting = useFieldSorting({
    disallowedFields: [
      "selected",
      "actions",
    ],
  });
  const requests = useLoadedData<PollRequest[]>(`/api/party-poll/request?view=unprocessed&${requestsSorting.queryParameter}`, []);
  const { schema } = useSingleSchema(`/api/party-poll/request/uiconfig`);
  const requestsFilter = useTextFilter<PollRequest>(r => `${r.casenbr} ${r.email} ${r.side_title} ${r._id}`);
  const requestsSelection = useListSelection<PollRequest>(r => r._id, { items: requests.data });

  const requestsProcessing = useRequestsProcessing(requestsSelection.selectedItems);

  const discardRequest = (r: PollRequest) => {
    return apiFetch<{}>(`/api/party-poll/request/${r._id}`, "put", { is_discarded: true })
      .then(() => {
        requests.reload();
        return {};
      })
  }

  const createRequest = (r: Partial<PollRequest>) => {
    const data = { ...r };
    delete data._id;
    delete data.created_datetime;
    delete data.processed_datetime;
    delete data.is_after_link;
    delete data.is_discarded;
    delete data.is_processed;
    delete data.merge_group;
    return apiFetch<{}>(`/api/party-poll/request`, "post", data)
      .then(() => {
        requests.reload();
        return {};
      })
  }

  const newRequest = useNewItem<Partial<PollRequest>, PollRequest>(`/api/party-poll/request`, {}, {
  });

  const editRequest = useEditItem2<PollRequest>({
    getApiPath: r => `/api/party-poll/request/${r._id}`,
  })

  const [doLoadProcessed, setDoLoadProcessed] = useState<boolean>(false);
  const processedRequests = useLoadedData<PollRequest[]>(`/api/party-poll/request?view=processed`, [], doLoadProcessed);
  const processedRequestsFilter = useTextFilter<PollRequest>(r => `${r.casenbr} ${r.email} ${r.side_title} ${r._id}`);


  return {
    cases: {
      ...cases,
      data: casesFilter.filterData(cases.data),
      filter: casesFilter,
      selection: casesSelection,
      generateRequests: generateRequestsForSelectedCases,
    },

    requests: {
      ...requests,
      data: requestsFilter.filterData(requests.data),
      filter: requestsFilter,
      sorting: requestsSorting,
      schema,
      selection: requestsSelection,
      processing: requestsProcessing,
      discardRequest,
      createRequest,
      newRequest: {
        ...newRequest,
        save: (ec?: Partial<PollRequest>) => newRequest.save(ec).then(x => { requests.reload(); return x; }),
      },
      editRequest: {
        ...editRequest,
        save: (ec?: Partial<PollRequest>) => editRequest.save(ec).then(x => { requests.reload(); return x; }),
      },
    },

    processedRequests: {
      ...processedRequests,
      data: processedRequestsFilter.filterData(processedRequests.data),
      filter: processedRequestsFilter,
      schema,
      ensureLoaded: () => setDoLoadProcessed(true),
    }
  }
}

export type PartiesPollsPrepData = ReturnType<typeof usePartiesPollsPrep>;
