import React, { PropsWithChildren, useMemo } from 'react';
import { QueryChunksConfig } from './types';
import { FieldSchema, FieldType, Schema, createSelectSchema, mergeSchema } from '../../hooks/useSchema';
import { useLoadedData } from '../../hooks/useLoadedData';

export interface IDataschemaContext {
  dataschema: QueryChunksConfig | null | undefined;
  chunkSchema: Schema;
}


const ChunkEditorSchema: Schema = {
  _id: { label_id: "dataschema.query_chunks.chunk_config._id" },
  inline: { label_id: "dataschema.query_chunks.chunk_config.inline", type: FieldType.bool },
  composite: { label_id: "dataschema.query_chunks.chunk_config.composite", type: FieldType.bool },

  from: { label_id: "dataschema.query_chunks.chunk_config.from" },
  where: { label_id: "dataschema.query_chunks.chunk_config.where" },
  select: { label_id: "dataschema.query_chunks.chunk_config.select" },
}

const EmptyContext: IDataschemaContext = { dataschema: null, chunkSchema: ChunkEditorSchema };

const DataschemaContext = React.createContext<IDataschemaContext>(EmptyContext);

export const useDataschema = () => React.useContext(DataschemaContext);

interface BareProps {
  dataschema: QueryChunksConfig;
}


export const DataschemaContextBareProvider = (props: PropsWithChildren<BareProps>) => {
  const v = useMemo(() => {
    const chunkSelectorSchema: FieldSchema = !props.dataschema || !props.dataschema.chunks_schema?.chunks?.length
      ? {}
      : createSelectSchema(props.dataschema.chunks_schema.chunks.map(c => ({ value: c._id || "", label: c.title || c._id || "" })));
      
      return { dataschema: props.dataschema, chunkSchema: mergeSchema(ChunkEditorSchema, { _id: chunkSelectorSchema }) };
  }, [props.dataschema]);

  return (
    <DataschemaContext.Provider value={v}>
      {props.children}
    </DataschemaContext.Provider>
  );
}


interface LoadedProps {
  apiPath?: string;
  code?: string;
}


export const DataschemaContextLoadedProvider = (props: PropsWithChildren<LoadedProps>) => {
  const data = useLoadedData<QueryChunksConfig>(`${props.apiPath}/${props.code}`, { code: "" }, !!props.apiPath && !!props.code);

  const v = useMemo(() => {
    if(!data.data.code) {
      return EmptyContext;
    }
    const dataschema = data.data;
    const chunkSelectorSchema: FieldSchema = !dataschema || !dataschema.chunks_schema?.chunks?.length
      ? {}
      : createSelectSchema(dataschema.chunks_schema.chunks.map(c => ({ value: c._id || "", label: c.title || c._id || "" })));
      
      return { dataschema: dataschema, chunkSchema: mergeSchema(ChunkEditorSchema, { _id: chunkSelectorSchema }) };
  }, [data.data]);

  return (
    <DataschemaContext.Provider value={v}>
      {props.children}
    </DataschemaContext.Provider>
  );
}
