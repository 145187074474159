import React from 'react'
import styled from '@emotion/styled';
import {
    ArrowBack,
    ArrowForward,
    Clear,
    TableChartOutlined,
    VerticalAlignBottom,
    VerticalAlignTop,
} from '@mui/icons-material';
import {
    Editor,
    Range,
    Point,
    Element as SlateElement,
    Node,
} from 'slate'
import { RenderElementProps } from 'slate-react';
import { CustomElement, CustomText } from '../../../../../slate';
import { EditorPlugin } from '../../slate/PowerEditorConfig';
import {
    TableCellElementType,
    TableElementType,
    TableRowElementType,
    DefaultTableElement,
    insertRow,
    insertColumn,
    removeRow,
    removeColumn,
    isInTable,
    moveRowCursor,
} from './Commands';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';

const unwrapChildren = (editor: Editor, result: Node[], fragment: Node[]): Node[] => {
    return fragment.reduce<CustomText[]>((r, node) => {
        const n = node as any;
        if([TableElementType, TableCellElementType, TableRowElementType].includes(n.type)) {
            return [...r, ...unwrapChildren(editor, r, n.children)];
        } else if(editor.isInline(n)) {
            return [...r, n];
        } else if(n.children) {
            return [...r, ...n.children];
        } else if (n.text) {
            return [...r, n];
        } else {
            return r;
        }
    }, result as any);
}

const withTables = (editor: Editor) => {
    const { deleteBackward, deleteForward, insertBreak, insertFragment } = editor
    
    editor.deleteBackward = unit => {
        const { selection } = editor
        
        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === TableCellElementType,
            })
            
            if (cell) {
                const [, cellPath] = cell
                const start = Editor.start(editor, cellPath)
                
                if (Point.equals(selection.anchor, start)) {
                    return
                }
            }
        }
        
        deleteBackward(unit)
    }
    
    editor.deleteForward = unit => {
        const { selection } = editor
        
        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === TableCellElementType,
            })
            
            if (cell) {
                const [, cellPath] = cell
                const end = Editor.end(editor, cellPath)
                
                if (Point.equals(selection.anchor, end)) {
                    return
                }
            }
        }
        
        deleteForward(unit)
    }
    
    editor.insertBreak = () => {
        const { selection } = editor
        
        if (selection) {
            const [table] = Editor.nodes(editor, {
                match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === TableElementType,
            })
            
            if (table) {
                return
            }
        }
        
        insertBreak()
    }

    editor.insertFragment = (fragment: Node[]) => {
        const { selection } = editor;
        
        if (selection) {
            const [cell] = Editor.nodes(editor, {
                match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === TableCellElementType,
            })
            
            if (cell) {
                const insertedChildren = unwrapChildren(editor, [], fragment);
                insertFragment(insertedChildren);
                return;
            }
        }
        
        insertFragment(fragment);
    }
    
    return editor
}


const Table = styled.table<{ noBorder?: boolean, viewMode?: boolean }>`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    & td {
        border: ${props => props.noBorder && props.viewMode ? 0 : 1.5}px solid ${props => props.noBorder ? props.theme.palette.grey[100] : props.theme.palette.text.secondary};
        padding: 0.25em 0.5em;
    }
`;

const TableBlock = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const { viewMode } = usePowerEditorContext();

    return (
        <Table
            noBorder={(element as any).no_border}
            viewMode={viewMode}
            >
            <tbody {...attributes}>{children}</tbody>
        </Table>);
}

export const TablesPlugin: EditorPlugin = {
    key: "tables",
    customBlocks: {
        [TableElementType]: TableBlock,
        [TableRowElementType]: (props: RenderElementProps) => <tr {...props.attributes}>{props.children}</tr>,
        [TableCellElementType]: (props: RenderElementProps) => <td {...props.attributes}>{props.children}</td>,
    },
    inject: e => withTables(e),
    commands: [{
        name: "insert-table",
        invoke: editor => editor.insertNode(DefaultTableElement as unknown as CustomElement),
        isAvailable: e => !isInTable(e),
        menu: {
            section: "insert-item",
            label: "Table",
            label_id: "powerdoc.plugins.table.title",
            icon: <TableChartOutlined />,
        }
    },
    {
        name: "insert-row-after",
        invoke: e => insertRow(e, "after"),
        isAvailable: isInTable,
        hotkey: "alt+shift+down",
        menu: {
            section: "insert-item",
            label: "Row after",
            label_id: "powerdoc.plugins.table.insert.row_after",
            icon: <VerticalAlignBottom />,
        }
    },
    {
        name: "insert-row-before",
        invoke: e => insertRow(e, "before"),
        isAvailable: isInTable,
        hotkey: "alt+shift+up",
        menu: {
            section: "insert-item",
            label: "Row before",
            label_id: "powerdoc.plugins.table.insert.row_before",
            icon: <VerticalAlignTop />,
        }
    },
    {
        name: "insert-column-after",
        invoke: e => insertColumn(e, "after"),
        isAvailable: isInTable,
        hotkey: "alt+shift+right",
        menu: {
            section: "insert-item",
            label: "Column after",
            label_id: "powerdoc.plugins.table.insert.column_after",
            icon: <ArrowForward />,
        }
    },
    {
        name: "insert-column-before",
        invoke: e => insertColumn(e, "before"),
        isAvailable: isInTable,
        hotkey: "alt+shift+left",
        menu: {
            section: "insert-item",
            label: "Column before",
            label_id: "powerdoc.plugins.table.insert.column_before",
            icon: <ArrowBack />,
        }
    },
    {
        name: "remove-row",
        invoke: removeRow,
        isAvailable: isInTable,
        menu: {
            section: "insert-item",
            label: "Delete row",
            label_id: "powerdoc.plugins.table.remove.row",
            icon: <Clear />,
        }
    },
    {
        name: "remove-column",
        invoke: removeColumn,
        isAvailable: isInTable,
        menu: {
            section: "insert-item",
            label: "Delete column",
            label_id: "powerdoc.plugins.table.remove.column",
            icon: <Clear />,
        }
    },
    {
        name: "prev-row",
        invoke: e => moveRowCursor(e, "up"),
        isAvailable: isInTable,
        hotkey: "up",
        menu: {
            section: "",
            label: "Prev row",
        }
    },
    {
        name: "next-row",
        invoke: e => moveRowCursor(e, "down"),
        isAvailable: isInTable,
        hotkey: "down",
        menu: {
            section: "",
            label: "Next row",
        }
    },
    ]
}
