import { ReactNode, useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useVisibilityTrigger } from "../../toolympus/components/primitives/useVisibilityTrigger";
import { FeedEvent } from "./Questions/useQuestionFeed";

export interface PresidiumEventFeed {
    data: FeedEvent[];
    isLoading: boolean;
    anchor: ReactNode;
}

const PageSize = 100;

export const usePresidiumEventFeed = (): PresidiumEventFeed => {
    const [offset, setOffset] = useState<number>(-100);
    const [data, setData] = useState<FeedEvent[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stopLoading, setStopLoading] = useState<boolean>(false);

    const load = (offset: number) => {
        setIsLoading(true);
        setOffset(offset);
        apiFetch<FeedEvent[]>(`/api/presidium/event?start=${offset}`, "get")
            .then(dataArrived => {
                setData([...data, ...dataArrived]);
                setIsLoading(false);
                if(dataArrived.length < PageSize) {
                    setStopLoading(true);
                }
            })
            .catch(e => { setIsLoading(false); throw e; });
    }

    const { anchor } = useVisibilityTrigger({
        onBecameVisible: () => !isLoading && load(offset+PageSize),
        isDisabled: stopLoading,
    })

    return {
        data,
        isLoading,
        anchor,
    }
}
