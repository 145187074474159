import { Button, IconButton, Typography } from '@mui/material';
import { Add, LockOpenOutlined, Lock } from '@mui/icons-material';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { mergeSchema, Schema } from '../../../hooks/useSchema';
import { Buttons, ClipboardButton, Dialog, Form, LoadingIndicator, OccupyFreeSpace, Tooltip, useCopyText } from '../../primitives';
import { FormControlsForFields, TableForFields } from '../../schemed';
import { IUserRole } from '../UserManagementForm';
import { APITokenSchema, APITokensManagementData } from './useApiTokensManagement';

interface Props {
  data: APITokensManagementData;
  roles: IUserRole[];
}

export const ApiTokensManagementForm = (props: Props) => {
  const { data, roles } = props;

  const isAnythingLoading = data.isLoading || data.createToken.isLoading || data.blockToken.isLoading;

  const schema = useMemo(
    () => {
      return mergeSchema(
        APITokenSchema,
        {
          roles: {
            values: roles.map(r => ({ label: r.name, value: r.key })),
            valueDict: roles.reduce<Record<string, string>>((r,role) => { r[role.key] = role.name; return r;}, {}),
          },
        }
      )
    },
    [roles]);

  return (<>
    <Form title={<FormattedMessage id="auth.apitokens.title" />}
        formHeaderProps={{ style: { alignItems: "flex-end" }}}
        headerItems={<>
            <IconButton size="small" color="primary" onClick={() => data.createToken.startEditing()}>
                <Add />
            </IconButton>
            <OccupyFreeSpace />
            {isAnythingLoading && <LoadingIndicator />}
        </>}>
        
        <TableForFields
            data={data.data}
            schema={schema}
            fields={[
                ["_id"],
                ["email"],
                ["roles"],
                ["created_datetime", { utcToLocal: true }],
            ]}
            autoexpander={{ initialRows: 20, increment: 20 }}
            rowButtons={row => <Buttons>
              <Tooltip text_id={row.is_blocked ? "auth.apitokens.fields.is_blocked" : "auth.apitokens.block_token"}>
                <IconButton
                  size="small"
                  onClick={() => !row.is_blocked && data.blockToken.startEditing(row)}
                  >
                  {row.is_blocked ? <Lock /> : <LockOpenOutlined />}
                </IconButton>
              </Tooltip>
            </Buttons>}
            />
    </Form>

    <NewTokenDialog
      schema={schema}
      item={data.createToken}
      />
    <CreatedTokenDialog
      schema={schema}
      item={data.createToken}
      />
    <BlockTokenDialog
      item={data.blockToken}
      />
</>);
}

interface NewTokenDialogProps {
  item: APITokensManagementData["createToken"];
  schema: Schema;
}

const NewTokenDialog = (props: NewTokenDialogProps) => {
  const { item, schema } = props;

  return (
    <Dialog
      dialogTitle={<FormattedMessage id="auth.apitokens.create" />}
      isOpen={item.isEditing}
      close={() => item.cancel()}
      noFullscreen
      actions={<>
        <Button
          color="primary"
          onClick={() => item.save()}
          disabled={item.isLoading || !item.item?.roles?.length || !!item.createdItem}
          >
          <FormattedMessage id="common.create" />
        </Button>
      </>}
    >
      <FormControlsForFields
        data={item.item}
        fields={[
          ["roles"],
        ]}
        schema={schema}
        onChange={(o,c) => item.update(c)}
        />
    </Dialog>
  )
}

const CreatedTokenDialog = (props: NewTokenDialogProps) => {
  const { item, schema } = props;

  const copyText = useCopyText();

  const token = item.createdItem?.token;

  useEffect(() => {
    if(token) {
      copyText(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])


  return (
    <Dialog
      dialogTitle={<FormattedMessage id="auth.apitokens.create" />}
      isOpen={!!item.createdItem}
      close={() => item.resetCreated()}
      noFullscreen
      actions={<>
        <Button
          onClick={() => item.resetCreated()}
          >
          <FormattedMessage id="common.close" />
        </Button>
      </>}
    >
      <Typography>
        <FormattedMessage id="auth.apitokens.token_created_hint" />
      </Typography>

      <FormControlsForFields
        data={item.createdItem}
        fields={[
          ["token", {
            disabled: true,
            controlProps: {
              endAdornment: <ClipboardButton getValue={() => item.createdItem?.token || ""} />,
          }}],
        ]}
        schema={schema}
        onChange={(o,c) => item.update(c)}
        />
    </Dialog>
  )
}


interface BlockTokenDialogProps {
  item: APITokensManagementData["blockToken"];
}

const BlockTokenDialog = (props: BlockTokenDialogProps) => {
  const { item } = props;

  return (
    <Dialog
      dialogTitle={<FormattedMessage id="auth.apitokens.block_token" />}
      isOpen={item.isEditing}
      close={() => item.cancel()}
      noFullscreen
      actions={<>
        <Button
          onClick={() => item.cancel()}
          disabled={item.isLoading}
          >
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          color="primary"
          onClick={() => item.save()}
          disabled={item.isLoading}
          >
          <FormattedMessage id="auth.apitokens.block_token" />
        </Button>
      </>}
    >
      <Typography>
        <FormattedMessage id="auth.apitokens.block_token_hint" />
      </Typography>
    </Dialog>
  )
}
