import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { ButtonOptionPicker } from '../../../toolympus/components/primitives/ButtonOptionPicker';
import { Form, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { FormControlsForFields, PlaceholderField, TableForFields } from '../../../toolympus/components/schemed';
import { mergeSchema, useSchema } from '../../../toolympus/hooks/useSchema';
import { usePresidiumVoteList } from './usePresidiumVoteList';
import { useHistory } from 'react-router';
import { addRemainingVoteTime, VoteSchemaConfig } from './VoteEnhancements';
import { VoteStatus } from './VoteStatus';
import { useTableComments } from '../../Comments';
import { PresidiumDropMenu } from '../Menu/PresidiumDropMenu';
import { useQuery } from '../../../toolympus/hooks/useQueryParamsState';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';

export const PresidiumVoteListPage = () => {
    const history = useHistory();
    const { 
        views, 
        view, 
        setView, 
        isNewItemPopupActive,
        setIsNewItemPopupActive, 
        data, 
        submitNewItem,
        newItem,
        updateNewItem,
    } = usePresidiumVoteList(v => history.push(`/presidium/votes/${v._id}`));
    const { presidium_vote: schema } = useSchema();

    const comments = useTableComments('presidium_vote');

    const query = useQuery();

    useEffect(() => {
        if(query.get("create") === "true") {
            updateNewItem({
                committee: query.get("committee") || undefined,
                start_date: query.get("start_date") || undefined,
                end_date: query.get("end_date") || undefined,
            });
            setIsNewItemPopupActive(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            title={<><PresidiumDropMenu current="votes" /> Президиум: голосования</>}
            headerItems={<>
                <IconButton color='primary' onClick={() => setIsNewItemPopupActive(true)}><Add /></IconButton>
                <OccupyFreeSpace />
                <ButtonOptionPicker
                    options={views || []}
                    selected={view}
                    setSelected={setView} />
                <PluggableDocumentationButton documentationKey="board_questions" />
            </>}>
            <TableForFields
                fields={[
                    ["batch"],
                    ["committee"],
                    ["start_date"],
                    ["end_date"],
                    ["remaining"],
                    ["questions"],
                    ["status"],
                ]}
                schema={mergeSchema(schema, VoteSchemaConfig.schemaChanges)}
                data={data.map(v => addRemainingVoteTime(v))}
                fieldLink={f => ["batch", "committee", "start_date", "end_date"].includes(f) ? (r => `/presidium/votes/${r._id}`) : null}
                fieldElement={f => f === "status" ? (r => <VoteStatus vote={r} />) : null}
                rowButtons={r => comments.buttonForRecord(r._id)}
                autoexpander={{ initialRows: 20, increment: 20 }}
                />
            
            <Dialog open={isNewItemPopupActive} onClose={() => setIsNewItemPopupActive(false)}>
                <DialogTitle>Новое голосование</DialogTitle>
                <DialogContent>
                    <FormGrid columns="repeat(2, 1fr)">
                        <FormControlsForFields
                            data={newItem}
                            onChange={(o,c) => updateNewItem(c)}
                            schema={schema}
                            fields={[
                                ["committee"],
                                [PlaceholderField],
                                ["start_date"],
                                ["end_date"],
                            ]}
                            />
                    </FormGrid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={submitNewItem}>создать</Button>
                </DialogActions>
            </Dialog>
            {comments.popup}
        </Form>
    );
}
