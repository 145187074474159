import styled from '@emotion/styled';
import { Card, CardContent } from '@mui/material';

export const Gutter = styled.div<{ width: string }>`
    padding: 10px;
    width: ${props => props.width};
`;

export const WidgetLoadingContent = styled(CardContent)`
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const ChartWrapper = styled(Card)`
    padding: 5px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & .clipboard-button {
        visibility: hidden;
    }

    &:hover .clipboard-button {
        visibility: visible;
        align-self: center;
    }
`;

export const ChartHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ChartTitle = styled.div`
    padding: 10px;
    width: 100%;
`;

interface DiagramStyleProps {
    type: "pie" | "bar";
    showTable?: boolean;
    hideChart?: boolean;
}


export const Table = styled.div<DiagramStyleProps>`
    width: ${({ hideChart: hidePie, type }) => hidePie ? "100%" : (type === "pie" ? "65%" : "50%")};

    ${props => props.theme.breakpoints.down("sm")} {
        width: 100%;
    }
`;
