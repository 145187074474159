import React from 'react';
import ArbitratorList from './presentation/ArbitratorList';
import { useArbitratorList } from './arbitratorsData';

const ArbitratorListContainer = () => {
    const arbitratorsData = useArbitratorList();

    return <ArbitratorList arbitrators={arbitratorsData} />
}

export default ArbitratorListContainer;