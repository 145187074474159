import React, { ReactNode } from 'react';
import { Checkbox } from '@mui/material';
import { FieldDefinition, TableForFields } from '../../../toolympus/components/schemed';
import { PresidiumQuestion } from './usePresidiumQuestions';
import { mergeSchema, Schema, useSchema } from '../../../toolympus/hooks/useSchema';
import { QuestionStatus } from './QuestionStatus';
import { FieldSorting } from '../../../toolympus/hooks/useFieldSorting';
import { ListSelection } from '../../../toolympus/hooks/useListSelection';
import { ProcessingResult, ProcessingResultDisplay } from '../../../toolympus/components/primitives/ProcessingResult';

interface TableProps {
    questions: PresidiumQuestion[];
    fields: FieldDefinition[];
    schema?: Schema;
    autoexpander?: boolean;
    tailAnchor?: ReactNode;
    sorting?: FieldSorting;
    selection?: ListSelection<PresidiumQuestion>;
    getProcessingResult?: (q: PresidiumQuestion) => ProcessingResult;
}

export const questionFieldLink = (f: string): ((r: PresidiumQuestion) => string) | null => {
    switch(f) {
        case "title":
            return r => `/presidium/questions/${r._id}`;
        case "is_board_statement_prepared":
            return r => `/presidium/questions/${r._id}?tab=details`;
        case "casenbr":
            return r => `/case/${r.case_id}`;
        case "batch":
            return r => `/presidium/votes/${r.poll_id}`;
        default:
            return null;
    }
}

export const questionVotesOf = (q: PresidiumQuestion) => `${q.votes_nbr} / ${q.respondees_nbr}`;

export const PresidiumQuestionsTable = (props: TableProps) => {
    const { schema, questions, fields, sorting, selection, getProcessingResult, autoexpander, tailAnchor } = props;
    const { presidium_question: defaultSchema } = useSchema();

    const s = mergeSchema(schema || defaultSchema, {
        status: { label: "Статус" },
        votes_of: { label: "Проголосовали" },
        selected_option: { label: "Результат" },
    })

    return (
        <TableForFields
            data={questions}
            schema={s}
            fields={[
                getProcessingResult ? ["_processing_result", { label: " "}] : null,
                selection ? ["_is_selected", { label: " "}] : null,
                ...fields,
                ["selected_option"],
                ["status"]
            ]}
            fieldElement={f => {
                switch(f) {
                    case "status":
                        return q => <QuestionStatus question={q} />;
                    case "votes_of":
                        return questionVotesOf;
                    case "selected_option":
                        return q => {
                          if(q.selected_option?.label) {
                            return q.selected_option.label || "";
                          } else if(q.selected_arbitrators?.length) {
                            return q.selected_arbitrators.map(a => `${a.lastname} ${a.firstname} ${a.middlename}`).join(",\n");
                          } else {
                            return "";
                          }
                        };
                    case "_processing_result":
                      if(getProcessingResult) {
                        return q => <ProcessingResultDisplay result={getProcessingResult(q)} />
                      }
                      break;
                    case "_is_selected":
                      if(selection) {
                        return q => <Checkbox size="small" checked={selection.isSelected(q) || false} onChange={e => selection.select(q, !!e.target.checked)} />
                      }
                      break;
                }
            }}
            sorting={sorting}
            fieldLink={questionFieldLink}
            autoexpander={autoexpander ? { initialRows: 20, increment: 20 } : undefined}
            tailAnchor={tailAnchor}
            />
    );
}
