import React, { useState } from 'react';
import { Case } from '../../typings/Cases';
import { EmptyFilters, useCaseTimeTracking } from './useCaseTimeTracking';
import { useTimetrackingRateTable } from './useTimetrackingRateTable';
import { ActionRow, Buttons, FormGrid, Link, LoadingIndicator, OccupyFreeSpace, PseudoLink2, Tooltip, useCopyText, useWindowHotkey } from '../../toolympus/components/primitives';
import { FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { Button, IconButton, Typography } from '@mui/material';
import { Add, CallMadeOutlined, EditOutlined, FileCopyOutlined, FilterList, GetAppOutlined } from '@mui/icons-material';
import { TimeRecordDialog } from './TimeRecordDialog';
import { DisplayProps } from '../../toolympus/components/schemed/FormControlProps';
import { TimeTrackDisplay, TimeTrackDisplayControl } from './TimeEditor';
import { createTimeRecordsTsv, createTotalsTsv, exportTimeRecordsXlsx } from './TimerecordsExport';
import { CustomRatetableEditDialog } from './CustomRatetableEditDialog';
import moment from 'moment';
import { useDictionaries } from '../../toolympus/hooks/useDictionaries';
import { CreatedBySchema } from './useTimetrackingRateTablesConfiguration';

interface Props {
  case: Case;
  updateCase: (changes: Partial<Case>) => void;
  saveCase: (changes?: Partial<Case>) => Promise<void>;
}

const MoneyDisplay = (props: DisplayProps) => {
  const value = (props.row || {})[props.field];
  return <>
    {value === null || value === undefined ? "" : (value as number).toLocaleString("ru-RU")}
  </>;
}

export const CaseTimeRecordsTable = (props: Props) => {
  const rates = useTimetrackingRateTable(
    props.case.timetracking_ratetable_id || null,
    id => props.saveCase({ timetracking_ratetable_id: id }),
    props.case.casenbr);
  const data = useCaseTimeTracking(props.case._id, rates.table.data);

  const [isShowTotals, setIsShowTotals] = useState<boolean>(false);

  const copyText = useCopyText();
  const dicts = useDictionaries();

  useWindowHotkey("alt+n", () => data.newItem.startEditing());

  return (
    <>
      <ActionRow lastItemMarginRight="0" alignItems="flex-end">
        <div style={{ width: 300 }}>
          <FormControlsForFields
            data={rates.isCustom ? { timetracking_ratetable_id: rates.table.data.title } : props.case}
            onChange={(o,c) => !rates.isCustom && props.updateCase(c)}
            schema={{ timetracking_ratetable_id: rates.isCustom ? { label: rates.tableSelectorSchema.label } : rates.tableSelectorSchema }}
            fields={[
              ["timetracking_ratetable_id", { autoComplete: true, readOnly: rates.isCustom }]
            ]}
            />
        </div>

        {!!props.case.timetracking_ratetable_id && !rates.isCustom &&
          <Link to={`/system/timerates?table=${props.case.timetracking_ratetable_id}`}>
            <IconButton size="small"><CallMadeOutlined /></IconButton>
          </Link>}

        {!rates.isCustom && <Button
          size="small"
          color="primary"
          onClick={() => rates.switchToCustomRates.run()}
          startIcon={rates.switchToCustomRates.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
          >
          использовать индивидуальные ставки
        </Button>}

        {rates.isCustom &&
          <Tooltip text="редактировать индивидуальные ставки">
            <IconButton
              size="small"
              color="inherit"
              style={{ color: "#0000008a" }}
              onClick={() => rates.editCustomRatesDialog.open()}>
              <EditOutlined />
            </IconButton>
          </Tooltip>}

        {rates.isCustom &&
          <Button
            size="small"
            color="inherit"
            style={{ color: "#0000008a" }}
            onClick={() => rates.switchToNormalRates.run()}
            startIcon={rates.switchToNormalRates.isRunning ? <LoadingIndicator sizeVariant="s" /> : undefined}
            >
            использовать стандартные ставки
          </Button>}

        <OccupyFreeSpace />
        
        {(data.records.isLoading || data.newItem.isLoading || data.editItem.isLoading) &&
          <LoadingIndicator />}

        <Buttons>
          <Typography variant="caption">Показывать: </Typography>
          <Button
            size="small"
            color="primary"
            onClick={() => setIsShowTotals(x => !x)}>
              {isShowTotals ? "всего" : "записи"}
          </Button>
        </Buttons>

        <Tooltip text="фильтры">
          <IconButton
            size="small"
            color={data.filters.isEditing ? "primary" : "inherit"}
            style={{ color: data.filters.isEditing ? undefined : "#0000008a" }}
            onClick={() => data.filters.isEditing ? data.filters.cancel() : data.filters.startEditing(EmptyFilters)}>
            <FilterList />
          </IconButton>
        </Tooltip>

        <Tooltip text="скопировать tsv">
          <IconButton
            size="small"
            color="inherit"
            style={{ color: "#0000008a" }}
            onClick={() => {
              copyText(isShowTotals
                  ? createTotalsTsv(data.totals, data.totalsSchema)
                  : createTimeRecordsTsv(data.filteredRecords, data.schema));
            }}>
            <FileCopyOutlined />
          </IconButton>
        </Tooltip>

        <Tooltip text="выгрузить">
          <IconButton
            size="small"
            color="inherit"
            style={{ color: "#0000008a" }}
            onClick={() => {
              exportTimeRecordsXlsx(data.filteredRecords.filter(r => !r.is_excluded), data.schema, dicts[CreatedBySchema.dictionary || ""], `${props.case.casenbr} - время - ${moment().format("DDMMYYYY")}.xlsx`);
            }}>
            <GetAppOutlined />
          </IconButton>
        </Tooltip>

        <IconButton
          size="small"
          color="primary"
          onClick={() => data.newItem.startEditing()}>
          <Add />
        </IconButton>
      </ActionRow>

      {data.filters.isEditing &&
        <FormGrid columns="300px repeat(3,2fr) 1fr" alignItems="flex-end">
          <div />
          <FormControlsForFields
            schema={data.filtersSchema}
            data={data.filters.item}
            fields={[
              ["date_from"],
              ["date_to"],
              ["actor_role", { autoComplete: true }],
            ]}
            onChange={(o,c) => data.filters.update(c)}
            />
          
          <Button size="small" onClick={() => data.filters.update(EmptyFilters)}>сбросить</Button>
        </FormGrid>}
      
      {!isShowTotals &&
        <TableForFields
          data={data.filteredRecords}
          schema={data.schema}
          fields={[
            ["record_date"],
            ["time_logged"],
            ["rate", { tableControl: MoneyDisplay }],
            ["total"],
            ["description"],
            ["actor_role"],
            ["actor_description"],
            ["created_at", { utcToLocal: true }],
            ["created_by"],
          ]}
          rowStyle={r => r.is_excluded ? { opacity: 0.35 } : undefined}
          fieldElement={f => {
            switch(f) {
              case "record_date":
                return (row,schema,orig) => <PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>{orig}</PseudoLink2>
              case "time_logged":
                return (row,schema,orig) => (<PseudoLink2 border onClick={() => data.editItem.startEditing(row)}>
                  <TimeTrackDisplay value={row.time_logged} />
                </PseudoLink2>)
              case "total":
                return (row) => <span><MoneyDisplay field="total" schema={data.schema} row={{ total: row.rate && row.time_logged ? Math.floor(row.rate * row.time_logged / 60) : null }} /></span>
            }
          }}
          />}

      {isShowTotals &&
        <TableForFields
          data={data.totals}
          schema={data.totalsSchema}
          fields={[
            ["actor_role"],
            ["time_logged", { tableControl: TimeTrackDisplayControl }],
            ["total", { tableControl: MoneyDisplay }],
          ]}
          rowStyle={row => row.is_total ? { background: "#5B99D220", borderTop: `3px solid #5B99D280` } : undefined}
          />}

      <TimeRecordDialog data={data.newItem} schema={data.schema} rateTable={rates.table.data} mode="create" />
      <TimeRecordDialog data={data.editItem} schema={data.schema} rateTable={rates.table.data} mode="edit" />

      <CustomRatetableEditDialog
        data={rates.table}
        dialogState={rates.editCustomRatesDialog}
        />
    </>
  );
}
