import { Schema, FieldType } from "../../toolympus/hooks/useSchema";


type TextLabels = Record<string,string> & { title?: string, label?: string, hint?: string };
type LabelsTranslations = Record<string, TextLabels>;

export interface PollConfigField {
  code: string;
  type: string;
  label?: string;
  min_score?: number;
  max_score?: number;
  labels?: LabelsTranslations;
}

export interface PollConfigSection {
  title: string;
  section: string;
  fields: PollConfigField[];
  labels?: LabelsTranslations;
}

export interface PollConfig {
  code: string;
  config: {
    fields: PollConfigSection[];
  };
}

export interface PartyPoll {
  _id: string;
  email?: string;
  config_code: string;
  fields: Record<string, any>;
  created_datetime?: string;
  filled_datetime?: string;
  request_merge_group?: string;
}

export const PollConfigSchema: Schema = {
  config: { type: FieldType.json, label: "Конфигурация" },
}

export const pollConfigToFields = (config: PollConfig): (PollConfigField & { key: string })[] => {
  const fields: (PollConfigField & { key: string })[] = [];
  (config?.config?.fields || []).forEach(section => {
    (section.fields || []).forEach(field => {
      const label = field.labels?.ru?.label || section?.labels?.ru?.title || field.code;
      fields.push({ key: `${section.section}_${field.code}`, ...field, label });
    })
  });
  return fields;
}