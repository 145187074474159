import { useEffect, useMemo } from "react";
import { useBrowserStoredValue } from "../../toolympus/hooks/useBrowserStoredValue";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { NewTimetrackingRatetable, TimetrackingRatetable } from "./types";
import { FieldSchema, FieldType, createSelectSchema, mergeSchema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { Case } from "../../typings/Cases";
import { apiFetch } from "../../toolympus/api/core";
import { useActionWithConfirmation } from "../../toolympus/api/useAction";

export const TimetrackingRatetablesApiPath = "/api/timetracking/ratetable";

export const CreatedBySchema: FieldSchema = { type: FieldType.dictionarySelect, dictionary: "Users" };


export const useTimetrackingRatetableSchema = () => {
  const { schema: schemaBase } = useSingleSchema(`${TimetrackingRatetablesApiPath}/uiconfig`);

  const schema = useMemo(
    () => mergeSchema(schemaBase, { created_by: CreatedBySchema }),
    [schemaBase]);

  return {
    schema,
  }
}

export const useTimetrackingRateTablesConfiguration = () => {
  const view = useBrowserStoredValue("normal", "v", "_raca_tt_ratetable_view");
  const available = useLoadedData<TimetrackingRatetable[]>(`${TimetrackingRatetablesApiPath}?view=${view.value}`, []);
  const current = useBrowserStoredValue("", "table", "_raca_tt_ratetable");

  const tableSelectorSchema = useMemo(() => {
    return createSelectSchema(available.data.map(rt => ({ label: rt.title, value: rt._id })), { label: "Таблица" });
  }, [available.data]);

  const { schema } = useTimetrackingRatetableSchema();

  const newItem = useNewItem<NewTimetrackingRatetable, TimetrackingRatetable>(
    TimetrackingRatetablesApiPath,
    { title: "", rates: [] },
  );

  const editItem = useCrudItem<TimetrackingRatetable>(`${TimetrackingRatetablesApiPath}/${current.value}`, {
    defaultValue: { rates: [] } as any as TimetrackingRatetable,
    resetChangesOnReload: true,
    noLoad: !current.value,
  });

  useEffect(() => {
    if(editItem.data.table_type && view.value !== editItem.data.table_type) {
      current.update("");
      editItem.setData({ rates: [] } as any as TimetrackingRatetable)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view.value]);

  const viewUsages = useEditItem2<{ tableId: number }>({
  });

  const usages = useLoadedData<Pick<Case, "_id" | "casenbr">[]>(`${TimetrackingRatetablesApiPath}/${viewUsages.item?.tableId}/usage`, [], !!viewUsages.item);

  const removeItem = useActionWithConfirmation(
    () => {
      if(editItem.data._id) {
        return apiFetch<{}>(`${TimetrackingRatetablesApiPath}/${current.value}`, "delete")
          .then(() => {
            current.update("");
            available.reload();
            editItem.setData({ rates: [] } as any as TimetrackingRatetable);
            return;
          });
      } else {
        return Promise.resolve();
      }
    }, {
      canRun: !!editItem.data._id,
      title: "Удалить таблицу ставок",
      confirmationHint: "Система не проверяет, используется ли удаляемая таблица ставок. Если вы удалите таблицу, которая используется хотя бы в одном деле, все сломается и вам придется жить в проклятом мире, который сами и создали.",
      // memoTrigger: editItem.data._id,
    }
  )

  return {
    current,
    tableSelectorSchema,
    schema,

    newItem: {
      ...newItem,
      save: (c?: NewTimetrackingRatetable) => {
        return newItem.save(c)
          .then(x => {
            available.reload();
            current.update(x._id.toString());
            return x;
          });
      }
    },

    editItem: {
      ...editItem,
      save: (c?: Partial<TimetrackingRatetable>) => {
        return editItem.save(c)
          .then(x => {
            available.reload();
            return x;
          });
      }
    },
    
    removeItem,

    view,

    viewUsages,
    usages,

    isLoading: available.isLoading,
  }
}
