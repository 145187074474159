import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
    Paper,
    TableContainer,
} from '@mui/material';
import { Case, StatusHints } from '../../../typings/Cases';
import { FieldSchema, mergeSchema, useSchema } from '../../../toolympus/hooks/useSchema';
import { TableForFields, FieldDefinition, FieldsSettings, FieldElementRenderer } from '../../../toolympus/components/schemed';
import { Status } from '../../../toolympus/components/StatusAction';
import { FieldSorting } from '../../../toolympus/hooks/useFieldSorting';
import { TableComments } from '../../../toolympus/components/Comments/useTableComments';

interface TableProps {
    cases: Case[];
    emptyState?: ReactNode;
}

interface Props extends TableProps {
    header?: ReactNode;
    sorting?: FieldSorting;
    fieldssettings?: FieldsSettings;
    comments?: TableComments;
}

export const StatusElement = (c: Case, schema: FieldSchema) => <Status status={c.status} labels={schema.valueDict} hints={StatusHints} />;

const fields: FieldDefinition[] = [
    ["casenbr"],
    ["rules"],
    ["case_type"],
    ["claimant_title"],
    ["respondant_title"],
    ["arbitration_start_date"],
    ["arbitration_end_date"],
    ["status", { element: StatusElement }]
];

export const PracticeCasenbrWrapper = styled.span`
  color: ${props => props.theme.palette.secondary.main};
`;

const caseFieldElement = (field: string): FieldElementRenderer<Case> => {
  switch(field) {
    case "practice_casenbr":
      return (row,s,orig) => (
        <PracticeCasenbrWrapper>
          {row.practice_ids?.length
          ? row.practice_casenbr || "без номера"
            : null}
        </PracticeCasenbrWrapper>
      )
  }
}

export const CasesListTableOnly = (props: Props) => {
    const schemas = useSchema();
    
    const schema = mergeSchema(schemas.case, {
        claimant_title: { label: "Истец" },
        respondant_title: { label: "Ответчик" },
        arbitration_start_date: { label: "Начало" },
        arbitration_end_date: { label: "Окончание" },
    });

    return <>
            <TableForFields
                fields={props.fieldssettings ? props.fieldssettings.activeFields : fields}
                schema={schema}
                data={props.cases}
                sorting={props.sorting}
                fieldLink={field => field === 'casenbr'? ({_id}: { _id: string}) => `/case/${_id}` : null}
                fieldElement={caseFieldElement}
                emptyState={props.emptyState}
                rowButtons={props.comments ? row => props.comments && props.comments.buttonForRecord(row._id) : undefined}
            />
            {props.comments && props.comments.popup}
        </>;
}

const CasesList = (props: Props) => {
    return (
        <TableContainer component={Paper}>
            {props.header}
            <CasesListTableOnly {...props} />
        </TableContainer>
    )
};

export default CasesList;