import React from 'react';
import { ActionRow } from '../../../toolympus/components/primitives/ActionRow';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { TableForFields } from '../../../toolympus/components/schemed';
import { checkRole, useUser } from '../../../toolympus/userContext/UserContext';
import { CaseStatusAction } from '../../../typings/Cases';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { CaseStatusActionData } from '../caseData';

interface Props {
    data: CaseStatusActionData;
}

export const CaseActionsTable = (props: Props) => {
    const { records, isLoading, remove, schema } = props.data;
    const { user } = useUser();

    const buttons = checkRole(user, "admin") ?
        (row: CaseStatusAction) => (
            <DeleteButton
                remove={() => remove(row)}
                title="Удалить запись о действии?"
                hint="Поля в деле не будут изменены - будет удалено только действие. Это может повлиять на расчет сроков."
                preventGoBack
                />) :
        undefined;

    return (
        <>
            <ActionRow>
                {isLoading && <LoadingIndicator />}
            </ActionRow>
            <TableForFields
                data={records}
                schema={schema}
                fields={[
                    ["action"],
                    ["date"],
                    ["reason"],
                    ["days"],
                    ["is_retro"],
                ]}
                rowButtons={buttons}
                />
        </>
    );
}
