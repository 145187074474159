import React from 'react';
import {
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Schema } from '../hooks/useSchema';
import { TableForFields, FieldDefinition } from './schemed';
import { ActionRow, OccupyFreeSpace } from './primitives/ActionRow';
import { SectionTitle } from './primitives/Forms';
import { LoadingIndicator } from './primitives/LoadingIndicator';
import { SearchData } from '../typings/data';
import { FormattedMessage } from 'react-intl';

interface Props<ItemType> {
    isActive: boolean;
    setIsActive: (v: boolean) => void;
    search: SearchData<ItemType>;
    buttonAction: (arbitrator: ItemType) => void;
    resultsSchema: Schema;
    resultsFields: FieldDefinition[];
    filter?: (item: ItemType) => boolean;
    disableButton?: (item: ItemType) => boolean;

    title?: string;
    searchFieldLabel?: string;
    buttonLabel?: string;

    width?: DialogProps["maxWidth"];
};


export const SearchModal = <ItemType,>(props: Props<ItemType>) => {
    const {
        isActive,
        setIsActive,
        search,
        buttonAction,
        resultsSchema,
        resultsFields,
        filter,
        disableButton,

        title,
        buttonLabel,
        searchFieldLabel,

        width,
    } = props;

    const rows = filter ? search.results.filter(filter): search.results;
    const shouldDisableButton = disableButton || (() => false);

    return (
        <Dialog open={isActive} onClose={() => setIsActive(false)} fullWidth maxWidth={width || "md"}>
            <DialogTitle>
                <ActionRow itemMarginLeft="0">
                    <SectionTitle style={{ marginRight: '1rem' }}>{title || <FormattedMessage id="searchModal.title"/>}</SectionTitle>
                    {search.isLoading && <LoadingIndicator sizeVariant="s" />}
                    <OccupyFreeSpace />
                    <IconButton onClick={() => setIsActive(false)}><Close/></IconButton>
                </ActionRow>
                <TextField
                    style={{ width: "100%" }}
                    label={searchFieldLabel || <FormattedMessage id="searchModal.searchFieldLabel"/>}
                    value={search.searchPhrase}
                    onChange={e => search.setSearchPhrase(e.target.value)}
                    />
            </DialogTitle>
            <DialogContent>                    
                <TableForFields
                    fields={resultsFields}
                    schema={resultsSchema}
                    data={rows}
                    rowButtons={arbitrator => (
                        <Button
                        color="primary"
                        variant="outlined"
                        disabled={shouldDisableButton(arbitrator)}
                        onClick={() => buttonAction(arbitrator)}>
                            {buttonLabel || <FormattedMessage id="searchModal.pickItem"/>}
                        </Button>)}
                    emptyStateText={<FormattedMessage id="searchModal.emptyState"/>}
                    />
            </DialogContent>
        </Dialog>);
};