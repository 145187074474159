import { useEffect, useMemo } from "react";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { createSelectSchema } from "../../toolympus/hooks/useSchema";
import { TimetrackingRatetable } from "./types";
import { TimetrackingRatetablesApiPath } from "./useTimetrackingRateTablesConfiguration";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { useActionWithConfirmation } from "../../toolympus/api/useAction";
import { apiFetch } from "../../toolympus/api/core";
import { useDialogState } from "../../toolympus/components/primitives";

const CustomRateTableType = "custom";

export const useTimetrackingRateTable = (selectedId: number | null, update: (id: number | null) => Promise<void>, casenbr: string | null) => {
  const available = useLoadedData<TimetrackingRatetable[]>(TimetrackingRatetablesApiPath, []);

  const tableSelectorSchema = useMemo(() => {
    return createSelectSchema(available.data.map(rt => ({ label: rt.title, value: rt._id })), { label: "Ставки" });
  }, [available.data]);

  const table = useCrudItem(`${TimetrackingRatetablesApiPath}/${selectedId}`, {
    noLoad: !selectedId,
    resetChangesOnReload: true,
    defaultValue: { rates: [] } as any as TimetrackingRatetable,
  });

  useEffect(() => {
    if(selectedId === null || selectedId === undefined) {
      table.setData({ rates: [] } as any as TimetrackingRatetable);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const isCustom = !!table.data && table.data.table_type === CustomRateTableType;

  const switchToCustomRates = useActionWithConfirmation(() => {
    if(!table.data || table.data) {
      const data: Partial<TimetrackingRatetable> = {
        table_type: CustomRateTableType,
        title: `Ставки ${casenbr || ""}`,
      };
      if(table.data) {
        data.currency = table.data.currency;
        data.rates = table.data.rates;
      }
      return apiFetch<TimetrackingRatetable>(TimetrackingRatetablesApiPath, "post", data)
        .then(x => {
          update(x._id);
          return x;
        })
    } else {
      return Promise.resolve(table.data);
    }
  }, {
    title: "Использовать индивидуальные ставки",
    confirmationHint: "Для дела будет создана специальная таблица ставок на основе использующейся сейчас стандартной. Вы сможете отредактировать эти ставки не затрагивая другие дела.",
  });

  const switchToNormalRates = useActionWithConfirmation<any>(() => {
    if(table.data && table.data.table_type === CustomRateTableType) {
      return update(null);
    } else {
      return Promise.resolve({});
    }
  }, {
    title: "Использовать стандартные ставки",
    confirmationHint: "Текущие индивидуальные ставки будут удалены и вы сможете выбрать одну из стандартных таблиц ставок."
  });

  const editCustomRatesDialog = useDialogState();

  return {
    tableSelectorSchema,
    table,
    isCustom,
    editCustomRatesDialog,
    switchToCustomRates,
    switchToNormalRates,
  }
  
}
