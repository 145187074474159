import { useState, useCallback, useEffect } from 'react';
import { apiFetch, FetchTypes } from '../../../toolympus/api/core';
import { Case, CaseCreate, CaseSide, DescribedEmail } from '../../../typings/Cases';
import { LoadedData } from '../../../toolympus/hooks/useLoadedData';
import { SendData } from '../../../toolympus/hooks/useSendData';
import { useSnackbar } from 'notistack';
import { useActions, UseActions } from '../../../toolympus/components/StatusAction';
import { useValidationErrors, ValidationErrors } from '../../../toolympus/components/schemed';
import { CaseCourtData, useCaseCourt } from './useCaseCourtData';
import { CaseStatusActionData, useCaseStatusActions } from './useCaseStatusActions';
import { PresidiumQuestion, useCasePresidiumQuestions } from '../../Presidium/Questions/usePresidiumQuestions';
import { ArbitratorSuggestionsData, useCaseArbitratorSuggestions } from '../../Presidium/Questions/useArbitratorSuggestions';
import { CaseEmployeesData, useCaseEmployees } from './useCaseEmployees';
import { UseHistoryData, useHistoryData } from './useHistoryData';
import { CasePowerDocsData, useCasePowerDocs } from './useCasePowerDocs';
import { useCaseExpressionProcessor } from './useCaseExpressionProcessor';
import { useRegionCityDefaulter } from '../../useRegionCityDefaulter';
import { DocumentStubGenerator, useCaseDocumentStub } from './useCaseDocumentStub';


export interface SaveableData<DataType> extends LoadedData<DataType> {
    update: (changes: Partial<DataType>) => void;
    save: (extraChanges?: Partial<DataType>) => Promise<void>;
    isUpdated: boolean;
    errors?: ValidationErrors;
}

export interface CaseData extends SaveableData<Case> {
    actions: UseActions;
    historyData: ReturnType<UseHistoryData>;
    remove: () => Promise<{}>;
    courtData: CaseCourtData;
    actionsData: CaseStatusActionData;
    presidiumQuestionsData: LoadedData<PresidiumQuestion[]>;
    arbitratorSuggestions: ArbitratorSuggestionsData;
    documents: CasePowerDocsData;
    documentStub: DocumentStubGenerator;
    employees: CaseEmployeesData;
    sides: CaseSides;
};

export const getCase = (caseid: string): Promise<Case> => apiFetch<Case>(`/api/case/${caseid}`);

export const useCase = (caseid: string): CaseData => {
    const [case_, setCase] = useState<Case>({ _id: caseid, casenbr: ""} as Case);
    const [changes, setChanges] = useState<{ [k: string]: any}>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const employees = useCaseEmployees(caseid);
    const courtData = useCaseCourt(caseid);
    const actionsData = useCaseStatusActions(caseid);
    const presidiumQuestionsData = useCasePresidiumQuestions(caseid);
    const errors = useValidationErrors();

    const { enqueueSnackbar } = useSnackbar();

    const load = useCallback(() => {
        if(!caseid) {
            return;
        }
        setIsLoading(true);
        apiFetch<Case>(`/api/case/${caseid}`).then(setCase)
            .then(() => setIsLoading(false))
            .catch(e => { setIsLoading(false); throw e; });
    }, [caseid]);

    
    const actions = useActions(`/api/case/${caseid}/action`, () => { actionsData.reload(); load(); });

    useEffect(() => {
        load();
    }, [load]);

    const updateCase = (changes: Partial<Case>) => {
        if(case_.is_checked && (changes.is_checked === undefined || changes.is_checked)) {
            enqueueSnackbar("Дело проверено - изменения заблокированы", { variant: 'error', autoHideDuration: 2000 });
            return;
        }

        if(changes.rejections && !case_.rejections && actions.startAction) {
            actions.startAction("rejection");
            return;
        }

        // @ts-ignore
        const moreChanges = Object.keys(changes).reduce((r,k) => changes[k] === case_[k] ? r : { ...r, [k]: changes[k]}, {});
        setCase(c => ({ ...c, ...changes }));

        setChanges(c => ({ ...c, ...moreChanges }));
    };

    const saveCase = (extraChanges?: Partial<Case>) => {
        setIsLoading(true);
        employees.save();
        const fullChanges = extraChanges ? { ...changes, ...extraChanges } : changes;
        return apiFetch<Case>(`/api/case/${caseid}`, FetchTypes.PUT, fullChanges)
            .then(setCase)
            .then(() => setChanges({}))
            .then(() => setIsLoading(false))
            .then(() => errors.clearErrors())
            .catch(e => { errors.handleErrors(e); throw e; });
    };

    const remove = () => apiFetch<Case>(`/api/case/${caseid}`, FetchTypes.DELETE);

    const historyData = useHistoryData(case_._id);

    const arbitratorSuggestions = useCaseArbitratorSuggestions(caseid);

    const sides = useCaseSides(case_, updateCase);
    
    const generatorsData = {
      case: case_,
      sides,
      assistant: employees.assistant,
      other_employees: employees.other,
  };

    const expressionProcessor = useCaseExpressionProcessor(generatorsData);
    const documents = useCasePowerDocs(caseid, { expressionProcessor });

    const documentStub = useCaseDocumentStub({ expressionProcessor, data: generatorsData });

    return {
        data: case_,
        setData: (x: any) => {},
        isUpdated: Object.keys(changes).length !== 0 || employees.hasChanges,
        isLoading: isLoading || employees.isLoading,
        actions,
        reload: load,
        update: updateCase,
        save: saveCase,
        remove,
        employees,
        sides,
        historyData,
        courtData,
        actionsData,
        presidiumQuestionsData,
        arbitratorSuggestions,
        documents,
        documentStub,
        errors,
    };
}


export const useNewCase = (onCreated: (c: Case) => void): SendData<CaseCreate, Case> => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [data, update] = useState<CaseCreate>({
        casenbr: '',
        rules: "internal",
        case_type: "internal",
        claimant_inn: '',
        claimant_title: '',
        respondant_inn: '',
        respondant_title: '',
    });

    const send = () => {
        setIsSaving(true);
        setIsSaved(false);
        return apiFetch<Case>("/api/case", "post", {
            casenbr: data.casenbr,
            rules: data.rules,
            case_type: data.case_type,
            sides: [
                { kind: "claimant", title: data.claimant_title, inn: data.claimant_inn },
                { kind: "respondant", title: data.respondant_title, inn: data.respondant_inn },
            ],
        })
            .then(record => {
                setIsSaving(false);
                setIsSaved(true);
                if(onCreated) {
                    onCreated(record);
                }
            })
            .catch(e => {
                setIsSaving(false);
                throw e;
            });
    }

    return {
        isSaving,
        isSaved,
        data,
        update,
        send,
    }
}


export interface CaseSides {
    claimants: CaseSide[];
    respondants: CaseSide[];
    others: CaseSide[];

    add: (kind: CaseSide["kind"]) => void;
    update: (original: CaseSide, changes: Partial<CaseSide>) => void;
    remove: (original: CaseSide) => void;

    addEmail: (side: CaseSide) => void;
    updateEmail: (side: CaseSide, idx: number, changes: Partial<DescribedEmail>) => void;
    removeEmail: (side: CaseSide, idx: number) => void;
}

const useCaseSides = (c: Case, updateCase: (changes: Partial<Case>) => void): CaseSides => {
    const sides = c.sides || [];

    const cityDefaulter = useRegionCityDefaulter();


    const add = (kind: CaseSide["kind"]) => {
        updateCase({ sides: [...sides, { kind, title: "", inn: "" }] });
    };

    const update = (original: CaseSide, changes: Partial<CaseSide>) => {
      const changesX = { ...changes };
      if(changesX.geo_region && !changesX.geo_city && !original.geo_city) {
        changesX.geo_city = cityDefaulter(changesX.geo_region);
      }
      updateCase({ sides: sides.map(s => s === original ? { ...s, ...changesX } : s) });
    }

    const remove = (original: CaseSide) => {
        updateCase({ sides: sides.filter(s => s !== original) });
    }

    const addEmail = (side: CaseSide) => {
        update(side, { emails: [...(side.emails || []), { email: "", description: "" }]});
    }

    const updateEmail = (side: CaseSide, emailIdx: number, changes: Partial<DescribedEmail>) => {
        update(side, { emails: (side.emails || []).map((email,idx) => idx === emailIdx ? { ...email, ...changes } : email) });
    }

    const removeEmail = (side: CaseSide, emailIdx: number) => {
        update(side, { emails: (side.emails || []).filter((_,idx) => idx !== emailIdx)});
    }

    return {
        claimants: sides.filter(s => s.kind === "claimant"),
        respondants: sides.filter(s => s.kind === "respondant"),
        others: sides.filter(s => s.kind === "other"),

        add,
        update,
        remove,

        addEmail,
        updateEmail,
        removeEmail,
    }
}
