interface Data<T> {
    items: T[];
    update: (updatedItems: T[]) => void;
    prepareItemChanges?: (c: Partial<T>, original: T) => Partial<T>;
    dflt: T;
    createDflt?: () => T;
}

export interface EditArrayData<ItemType> {
    items: ItemType[];
    add: (item?: ItemType) => ItemType;
    update: (idx: number, changes: Partial<ItemType>) => void;
    remove: (idx: number) => void;

    moveUp: (idx: number) => void;
    moveDown: (idx: number) => void;
    moveTo: (idxFrom: number, idxTo: number) => void;
}

export const moveArrayItem = <T>(items: T[], movedIdx: number, direction: 1 | -1): T[] => {

    if(movedIdx < 0 || items[movedIdx] === undefined) {
        return items;
    }

    const resultIdx = movedIdx + direction;
    const moved = items[movedIdx];
    if(resultIdx < 0) {
        return [...items.slice(1), moved];
    } else if (resultIdx >= items.length) {
        return [moved, ...items.slice(0, movedIdx)];
    } else {
        const l = Math.min(movedIdx, resultIdx);
        return [...items.slice(0,l), items[l+1], items[l], ...items.slice(l+2),];
    }
}

export const moveArrayItemTo = <T>(items: T[], movedIdx: number, toIdx: number): T[] => {

  if(movedIdx < 0 || items[movedIdx] === undefined || toIdx < 0 || toIdx > items.length || movedIdx === toIdx) {
      return items;
  }

  const movedItem = items[movedIdx];
  const result = [...items];
  result.splice(movedIdx, 1);
  const actualToIdx = toIdx > movedIdx ? toIdx - 1 : toIdx;
  result.splice(actualToIdx, 0, movedItem);
  return result;
}

export const useEditArray = <T>(data: Data<T>): EditArrayData<T> => {
    const { items } = data;
    const prepareItemChanges = data.prepareItemChanges || ((t,o) => t);

    
    const add = (item?: T) => {
      const added = item || { ...(data.createDflt ? data.createDflt() : data.dflt) };
      data.update([...items, added]);
        return added;
    }

    const update = (idx: number, changes: Partial<T>) => {
        data.update(items.map((item,i) => i === idx ? { ...item, ...prepareItemChanges(changes,item) } : item));
    }

    const remove = (idx: number) => {
        data.update(items.filter((s,i) => i !== idx));
    }


    const moveUp = (idx: number) => {
        data.update(moveArrayItem(items, idx, -1));
    }

    const moveDown = (idx: number) => {
        data.update(moveArrayItem(items, idx, +1));
    }
    
    const moveTo = (idxFrom: number, idxTo: number) => {
      data.update(moveArrayItemTo(items, idxFrom, idxTo));
    }

    return {
        items,
        add,
        update,
        remove,
        moveUp,
        moveDown,
        moveTo,
    }
}
