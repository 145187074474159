import { useEffect } from "react";
import { useUserSettings } from "../../toolympus/components/Settings/UserSettingsContext"
import { BackgroundOptionDefault } from "./BackgroundOptions";
import { usePersonalizationService } from "./PersonalizationServiceContext";

const BackgroundSettingKey = "p13n_background";

export const usePersonalization = () => {
  const settings = useUserSettings();

  const background = settings.user[BackgroundSettingKey] || BackgroundOptionDefault.name;

  const setBackground = (name: string) => settings.updateUser({ [BackgroundSettingKey]: name });

  return {
    background,
    setBackground,
  }
}


export const usePersonalizationMonitor = () => {
  const settings = useUserSettings();
  const backgroundOption = settings.user[BackgroundSettingKey];

  const p13nService = usePersonalizationService();

  useEffect(() => {
    if(backgroundOption) {
      p13nService.setBackground(backgroundOption);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundOption]);
}