import React from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    DialogActions,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { WidgetDetailsData } from './useWidgetDetails';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { Link } from '../primitives/Common.styles';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { ClipboardButton } from '../primitives/Buttons';
import { injectLinkFields } from './links';

interface Props {
    data: WidgetDetailsData;
}


export const WidgetDetailsPopup = ({ data: { isLoading, isOpen, close, fields, getResultTSV, details, error, } }: Props) => {
    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogContent>                
                {isLoading && <LoadingIndicator />}
                {error && <Typography><i><FormattedMessage id={error} /></i></Typography>}
                {!isLoading && !error && (
                    <div style={{ display: 'flex' }}>
                        <OccupyFreeSpace />
                        <ClipboardButton getValue={getResultTSV} />
                    </div>
                )}
                {!isLoading && !error && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                {fields.map(({ name, label }) => <TableCell key={name}>
                                    {label}
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {details.map((row,i) => (
                                <TableRow key={i}>
                                    {fields.map(({ name, link }) => (
                                        <TableCell key={name}>
                                            {link ?
                                                <Link to={injectLinkFields(link, row)}>{row[name]}</Link> :
                                                row[name]}
                                        </TableCell>))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>
    );
}
