import React from 'react';
import { ImportExportScreen } from './presentation/ImportExportScreen';
import { useCaseExport, useCaseImport, useCaseImportResults } from './useCaseImport';

const ImportExportContainer = () => {
    const importData = useCaseImportResults();
    const importer = useCaseImport();
    const exporter = useCaseExport();

    return <ImportExportScreen importData={importData} importer={importer} exporter={exporter} />
}

export default ImportExportContainer;