import { useMemo } from "react";
import { PowerDoc } from "../../toolympus/components/PowerDoc"
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { createSelectSchema } from "../../toolympus/hooks/useSchema";

export const useDocumentTemplatesSelector = () => {
  const data = useLoadedData<PowerDoc[]>("/api/powerdoc/doc", []);

  const selectorSchema = useMemo(() => {
    return createSelectSchema(
      data.data.map(d => ({ label: d.title, value: d._id })),
    )
  }, [data.data]);

  return {
    ...data,
    selectorSchema,
  }
}
