import React from 'react';
import styled from '@emotion/styled';
import { NumberingSequence } from './types';
import { Button } from '@mui/material';
import { Buttons } from '../primitives';

const Sequence = styled(Button)`
  text-transform: none;
`;

const Wrapper = styled(Buttons)`
  flex-flow: row wrap;
`;

interface Props {
  available: NumberingSequence[];
  currentId: number | null;
  setCurrentId: (v: number | null) => void;
}

export const NumberingSequenceSelector = (props: Props) => {
  return (
    <Wrapper>
      {props.available.map(s => (
        <Sequence
          color={s._id === props.currentId ? "primary" : undefined}
          variant={s._id === props.currentId ? "contained" : "outlined"}
          onClick={() => props.setCurrentId(s._id)}>
          {s.title}
        </Sequence>
      ))}
    </Wrapper>
  );
}
