import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MenuRoute, Routes } from '../frame';
import { Submenu } from '../frame/Submenu';
import { LoadingIndicator, SearchField } from '../primitives';
import { SingleRecordTypePages, SingleRecordTypePagesMultiple } from './SingleRecordTypePages';
import { CMSMenuData, useCMSMenu } from './useCMSMenu';
import isHotkey from 'is-hotkey';
import { ImportExportOutlined, PhotoAlbumOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { CMSImportExportPage } from './ImportExport';
import { CMSConfigurationProvider, ICMSConfiguration } from './Configuration/CMSConfigurationContext';
import { MediaLibForm } from '../medialib';
import { MenuGroup } from '../frame/Submenu';
import { PowerEditorSettings, PowerEditorSettingsProvider } from '../PowerDoc/PowerEditorSettingsContext';

export interface CMSMenuProps {
    apiPath: string;
    icons?: Record<string, ReactNode>;
    includeMediaLib?: boolean;
    configuration?: Partial<ICMSConfiguration>;
}

export interface CMSMenuWithEditorProps extends CMSMenuProps, Partial<PowerEditorSettings> {
}

export const CMSMenuWithEditorSettings = (props: CMSMenuWithEditorProps) => {
  return (
    <PowerEditorSettingsProvider {...props}>
        <CMSMenu {...props} />
    </PowerEditorSettingsProvider>      
);
}

export const CMSMenu = ({ apiPath, icons, includeMediaLib, configuration, }: CMSMenuProps) => {
    const data = useCMSMenu(apiPath);
    let { path } = useRouteMatch();

    const routes = [
      { path: `${path}/import-export`, component: CMSImportExportPage, componentProps: { apiPath } },
      includeMediaLib ? { path: `${path}/media`, component: MediaLibForm } : null,
      { path: `${path}/:record_type`, component: SingleRecordTypePagesMultiple, componentProps: { apiPath } },
      { path: path, component: CMSMenuForm, componentProps: { apiPath, path, data, icons, includeMediaLib } },
    ].filter(r => !!r) as MenuRoute[];

    return (
        <CMSConfigurationProvider
            getSiteUrl={configuration?.getSiteUrl}
            getRecordUrl={r => r?.record_type && r._id ? `${path}/${r.record_type}/${r._id}` : null}
            apiPath={apiPath}>
            <Routes routes={routes} />
        </CMSConfigurationProvider>
    );
}

const Header = styled.div`
    display: flex;
    align-items: center;
    
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 1.5rem;

    & > :first-child {
        flex: 1 0 auto;
    }
`;

interface CMSMenuFormProps extends CMSMenuProps {
    data: CMSMenuData;
    path: string;
}

export const CMSMenuForm = (props: CMSMenuFormProps) => {
    const { data: configs, filter, isLoading } = props.data;
    const { apiPath, path } = props;

    const groups = useMemo(
        () => [
            ...configs.map(c => ({
                key: c.record_type,
                icon: (props.icons || {})[c.record_type],
                label: c.label || c.record_type,
                items: [{ key: c.record_type, label: c.label, component: SingleRecordTypePages, componentProps: { apiPath, recordType: c.record_type }}]
                })),
            {
                key: "import-export",
                icon: <ImportExportOutlined />,
                label: <FormattedMessage id="cms.importexport.title" />,
                items: [{ key: "import-export", label: <FormattedMessage id="cms.importexport.title" />, component: CMSImportExportPage, componentProps: { apiPath }}]
            },
            props.includeMediaLib ? {
              key: "media",
              icon: <PhotoAlbumOutlined />,
              label: <FormattedMessage id="medialib.title" />,
              items: [{ key: "media", label: <FormattedMessage id="medialib.title" />, component: MediaLibForm }]
          } : null,
        ].filter(r => !!r) as MenuGroup[],
        [configs, apiPath, props.icons, props.includeMediaLib],
    );

    const history = useHistory();

    return <>
        <Header onKeyDown={e => {
            if(isHotkey("Enter", e) && configs.length === 1) {
                history.push(`${path}/${configs[0].record_type}`);
            }
        }}>
            <SearchField
                filter={filter.filter}
                setFilter={filter.setFilter}
                noButton
                autoFocus
                fullWidth
                />
            {isLoading && <LoadingIndicator  />}
        </Header>
        <Submenu pathPrefix={path} noCloseButton noSearchField groups={groups} />
    </>
}
