import React from 'react';
import { TableForFields } from '../../toolympus/components/schemed';
import { PartiesPollsListData } from './usePartiesPollsList';
import { PollReviewDialog } from './PollReviewDialog';
import { Buttons, PseudoLink2, useCopyText } from '../../toolympus/components/primitives';
import { ColorMarker } from './ColorMarker';
import { LinkPollToCaseDialog } from './LinkPollToCaseDialog';



interface Props {
  data: PartiesPollsListData;
}

export const PartiesPollsList = (props: Props) => {
  const { data, schema, fields } = props.data;
  const copyText = useCopyText();

  return (
    <>
      <TableForFields
        data={data}
        schema={schema}
        fields={fields.activeFields}
        fieldElement={f => {
          if(schema[f]?.type as any === "email") {
            return (row,s,orig) => <PseudoLink2 border onClick={() => copyText((row as any)[f])}>{orig}</PseudoLink2>
          } else if(schema[f]?.type as any === "score") {
            return (row,s) => (
              <Buttons>
                <ColorMarker score={(row as any)[f]} min_score={(s as any).min_score} max_score={(s as any).min_score} />
                {((row as any)[f])?.toString()}
              </Buttons>
            )
          }
          switch(f) {
            case "email":
            case "casenbr":
            case "_id":
            case "created_datetime":
            case "filled_datetime":
              return (row,s,orig) => <PseudoLink2 border onClick={() => props.data.reviewPoll.startEditing(row)}>{orig}</PseudoLink2>;
          }
        }}
        />

      <PollReviewDialog
        poll={props.data.reviewPoll.item}
        close={props.data.reviewPoll.cancel}
        schema={schema}
        config={props.data.config}
        startLinkToCase={poll => props.data.linkToCase.startEditing({ poll_id: poll._id })}
        />

      <LinkPollToCaseDialog
        data={props.data.linkToCase}
        />
    </>
  );
}
