import React from 'react';
import { IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { FormControl } from '../schemed';
import { FormControlProps } from '../schemed/FormControlProps';
import slugify from 'slugify';
import { TextEdit } from '../schemed/TextEdit';

interface Props extends FormControlProps {
    sourceField: string;
}

const slugifyX = (v: string | null | undefined) => slugify(v || "", { lower: true, remove: /[""«»/\\:;,]/g });

export const SlugEdit = (props: Props) => {
    const { row, field, onChange, sourceField } = props;

    const generateSlug = () => slugifyX(row[sourceField] || "");
    
    return (
        <FormControl
            {...props}
            extraProps={{
                ...(props.extraProps || {}),
                controlProps: {
                    endAdornment: (
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => onChange(row, { [field]: generateSlug() })}>
                            <Refresh />
                        </IconButton>) },
            }}
            />
    );
}

export const SlugEditControl = (props: FormControlProps) => {
  const { row, field, onChange } = props;

  const generateSlug = () => {
    return slugifyX(props.extraProps?.slugSource || "");
  } ;
  
  return (
      <TextEdit
          {...props}
          extraProps={{
              ...(props.extraProps || {}),
              controlProps: {
                  endAdornment: (
                      <IconButton
                          size="small"
                          color="primary"
                          onClick={() => onChange(row, { [field]: generateSlug() })}>
                          <Refresh />
                      </IconButton>) },
          }}
          />
  );
}
