import React, { ReactNode } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { Signup, SignupMode } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '../primitives';

interface Props {
    mode: SignupMode;
    onErrorMessage?: ReactNode;
    onSuccessMessage?: ReactNode;
    signupData: Signup;
}

export const SignupForm: React.FC<Props> = (props: Props) => {
    return (
        <form onSubmit={e => { e.preventDefault(); props.signupData.send(); }}>
            <OuterCentralizedForm>
              <SignupFormFields {...props} />
            </OuterCentralizedForm>
        </form>
    )
};


export const SignupFormFields = (props: Props & { noButton?: boolean }) => {
  const { mode, onErrorMessage, onSuccessMessage, signupData } = props;
  const { isSubmitted, credentials, update: onChangeCredentials, isError } = signupData;

  const isPremade = mode === SignupMode.Invite;

  return (
      <>
        {isSubmitted
          ? isError
            ? (onErrorMessage || <Typography><FormattedMessage id="signup.errorMessage"/></Typography>)
            : (onSuccessMessage || <Typography><FormattedMessage id="signup.successMessage"/></Typography>)
          : <>
            <TextField
                label={<FormattedMessage id="signup.email"/>}
                name='email'
                variant='outlined'
                disabled={isPremade}
                value={credentials.login}
                onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || '' })} />
            <TextField
                label={<FormattedMessage id="signup.password"/>}
                name='password'
                type='password'
                variant='outlined'
                value={credentials.password}
                onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || '' })} />
            <TextField
                label={<FormattedMessage id="signup.passwordRepeat"/>}
                name='password'
                type='password'
                variant='outlined'
                value={credentials.passwordRepeat}
                onChange={e => onChangeCredentials({ ...credentials, passwordRepeat: e.target.value || '' })} />
            {!props.noButton && <Button
              type='submit'
              variant='contained'
              color='primary'
              startIcon={props.signupData.isSaving ? <LoadingIndicator color="inherit" sizeVariant="s" /> : undefined}>
              <FormattedMessage id="signup.submit"/>
            </Button>}
          </>
        }
      </>
  )
};
