import { TimetrackingRecord } from "./types";
import { toLocalDatetimeInternal } from "../../toolympus/api/datetimeUtil";
import { createTsv } from "../../toolympus/components/primitives";
import { Schema } from "../../toolympus/hooks/useSchema";
import { minutesToStr } from "./TimeEditor";
import ExcelJS from "exceljs";
import { downloadBuffer } from "../../toolympus/api/core";
import { Dictionary } from "../../toolympus/hooks/useDictionaries";
import { formatDecimal } from "../../toolympus/components/schemed";


export const createTimeRecordsTsv = (data: TimetrackingRecord[], schema: Schema) => {
  const columns = [
    "record_date",
    "time_logged",
    "rate",
    "total",
    "description",
    "actor_role",
    "actor_description",
    "created_at",
    "created_by",
  ];

  return createTsv(
    columns.map(f => schema[f]?.label as string || ""),
    r => ([
      r.record_date,
      minutesToStr(r.time_logged),
      r.rate,
      r.rate && r.time_logged ? Math.floor(r.rate * r.time_logged / 60) : 0,
      r.description,
      r.actor_role,
      r.actor_description,
      toLocalDatetimeInternal(r.created_at),
      r.created_by,
    ]),
    data);
}

export const createTotalsTsv = (data: any[], schema: Schema) => {
  const columns = [
    "actor_role",
    "time_logged",
    "total",
  ];

  return createTsv(
    columns.map(f => schema[f]?.label as string || ""),
    r => ([
      r.actor_role,
      minutesToStr(r.time_logged),
      r.total || "",
    ]),
    data);
}


export const exportTimeRecordsXlsx = (data: TimetrackingRecord[], schema: Schema, usersDictionary: Dictionary, filename: string) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Отчет");

  const columnsConfig = [
    { header: "Сотрудник", key: "actor_description", width: 20 },
    { header: "Должность", key: "actor_role", width: 20 },
    
    { header: "Ставка", key: "rate", width: 11 },
    { header: "Затраченное время", key: "time_logged", width: 14 },
    { header: "Сумма", key: "total", style: { numFmt: "#,##0" }, width: 12 },

    { header: "Дата", key: "record_date", width: 12 },
    { header: "Описание работы", key: "description", width: 30 },
  ];

  sheet.columns = columnsConfig;

  // const userNames = usersDictionary.valueDict;

  const rows = data.map(record => {
    const { time_logged, rate } = record;
    const timeLoggedRoundedH = (time_logged / 60).toFixed(2);
    const total = Math.floor((+timeLoggedRoundedH) * rate);

    return {
      ...record,
      // created_at: utc.toLocal(created_at).format(DateTimeInternalFormat),
      // created_by: userNames[created_by],
      rate: `${formatDecimal(record.rate, 0, " ")}/час`,
      record_date: new Date(record.record_date),
      total,
      time_logged: +timeLoggedRoundedH,
    };
  });

  const totals = rows.reduce<{ total: number, time_logged: number }>((r,row) => {
    r.time_logged += row.time_logged;
    r.total += row.total;
    return r;
  }, { time_logged: 0, total: 0 });

  // rows.forEach(record => sheet.addRow(record));

  sheet.addTable({
    ref: "A1",
    columns: columnsConfig.map(c => ({ name: c.header, filterButton: true })),
    name: "Report",
    displayName: "Отчет",
    rows: rows.map(r => columnsConfig.map(c => (r as any)[c.key])),
    style: { showRowStripes: true, theme: "TableStyleLight16" },
  })

  sheet.addRow({ rate: "Итог за месяц", ...totals });

  const headRow = sheet.getRow(1);
  headRow.font = { bold: true };
  headRow.alignment = { wrapText: true, vertical: "middle" };

  const totalRow = sheet.getRow(rows.length+2);
  totalRow.font = { bold: true };
  "ABCDEFG".split("").forEach(k => {
    const cell = totalRow.getCell(k);
    cell.fill = { pattern: "solid", type: "pattern", fgColor: { argb: "FFFF00" } };
  });
  

  
  "AB".split("").forEach(col => {
    sheet.getColumn(col).alignment = { horizontal: "center", vertical: "middle", wrapText: true };
  });
  sheet.getColumn("C").alignment = { horizontal: "right", vertical: "middle", wrapText: true };
  sheet.getColumn("G").alignment = { vertical: "middle", wrapText: true };

  


  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filename));
}