import styled from '@emotion/styled';
import BackgroundImage from '../dot-hatch-b.svg';

export const InsetPanel = styled.div`
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-top: 2px solid ${props => props.theme.palette.grey[300]};
    border-bottom: 2px solid ${props => props.theme.palette.grey[300]};
    background: ${props => props.theme.palette.grey[100]}
      url(${BackgroundImage});
    background-size: 200px 200px;
    background-repeat: repeat;
`;