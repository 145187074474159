import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { Schema, useSchema } from "../../../toolympus/hooks/useSchema";
import { CaseStatusAction } from "../../../typings/Cases";

export interface CaseStatusActionData {
    records: CaseStatusAction[];
    isLoading: boolean;
    reload: () => void;

    remove: (record: CaseStatusAction) => Promise<void>;
    schema: Schema;
}

export const useCaseStatusActions = (case_id: string): CaseStatusActionData => {
    const apiPath = `/api/case/${case_id}/statusactions`;
    const data = useLoadedData<CaseStatusAction[]>(apiPath, []);
    const { case_status_action: schema } = useSchema();

    const remove = (record: CaseStatusAction) => {
        return apiFetch<void>(`${apiPath}/${record.line}`, FetchTypes.DELETE)
            .then(() => data.reload());
    }

    return {
        records: data.data,
        isLoading: data.isLoading,
        reload: data.reload,
        remove,
        schema,
    };
}