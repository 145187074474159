import React from 'react';
import styled from '@emotion/styled';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { useColorScheme } from '../Colors';
import { ChartProps, ChartRow } from '../util';

const PieWrapper = styled.div`
    position: relative;

    & .pie-arc {
        transition: transform 0.5s ease, opacity 1s ease;
        &:hover {
            opacity: 0.9;
            transform: scale(1.05);
        }
    }
`;


export const PieChart = (props: ChartProps) => {
    const { rows, width, openDetails } = props;

    const height = width;

    const { getColor } = useColorScheme(rows);
    const tooltip = useTooltip();

    if(!width) {
        return null;
    }

    const margin = { left: 10, right: 10, top: 10, bottom: 10 };

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;

    return (
        <PieWrapper>
            <svg width={width} height={height}>
                <Group top={centerY + margin.top} left={centerX + margin.left}>
                    <Pie
                        width={width}
                        height={height}
                        outerRadius={radius}
                        innerRadius={radius * 0.35}
                        padAngle={0.05}
                        data={rows}
                        pieValue={r => r.value}
                    >
                        {(pie) => {
                            return (<>
                                <Group className="visx-pie-arcs-group">
                                    {pie.arcs.map((arc, i) => {
                                        const [centroidX, centroidY] = pie.path.centroid(arc);

                                        return (
                                            <g key={`pie-arc-${i}`} onClick={() => openDetails(arc.data)}>
                                                <path
                                                    className="pie-arc"
                                                    d={pie.path(arc) || ""}
                                                    fill={getColor(arc.data.label)}
                                                    onMouseLeave={() => {
                                                        tooltip.hideTooltip();
                                                    }}
                                                    onMouseMove={e => {
                                                        tooltip.showTooltip({
                                                            tooltipData: arc.data,
                                                            tooltipTop: centroidY,
                                                            tooltipLeft: centroidX,
                                                        });
                                                    }}
                                                    />
                                            </g>
                                        )})}
                                </Group>
                            </>);
                        }}
                    </Pie>
                </Group>
            </svg>
            {tooltip.tooltipOpen && tooltip.tooltipData &&
                <TooltipWithBounds
                    applyPositionStyle
                    top={centerX + (tooltip.tooltipTop || 0)}
                    left={centerY + (tooltip.tooltipLeft || 0)}>
                    {(tooltip.tooltipData as ChartRow).label} – {(tooltip.tooltipData as ChartRow).value}
                </TooltipWithBounds>}
        </PieWrapper>
    );
}
