import { useState } from "react";
import { CrudItemData } from "../../toolympus/api/useSimpleCrud";
import { CorpDispute, Party, Representative } from "./useCorpDisputesList";

export type SideKey = "claimants" | "respondants" | "third_parties" | "entity_representative";

export const useEditGroups = (data: CrudItemData<CorpDispute>) => {
    const [addingPartyToGroup, setAddingPartyToGroup] = useState<{ side: SideKey, party: Party} | null>(null);

    const startAddingToGroup = (side: SideKey, party: Party) => {
        if(party.id !== undefined) {
            setAddingPartyToGroup({ side, party });
        }
    };
    const cancelAddingToGroup = () => setAddingPartyToGroup(null);

    const completeAddingToGroup = (targetSide: SideKey, targetId: number) => {
        if(addingPartyToGroup && targetId !== addingPartyToGroup.party.id) {
            addToGroup({ side: addingPartyToGroup.side, id: addingPartyToGroup.party.id as number }, { side: targetSide, id: targetId });
            setAddingPartyToGroup(null);
        }
    }

    const addToGroup = (added: { side: SideKey, id: number }, target: { side: SideKey, id: number }) => {
        const targetParty = (data.data[target.side] || []).find(p => p.id === target.id);
        const addedParty = (data.data[added.side] || []).find(p => p.id === added.id);

        if(addedParty && targetParty) {
            const groupId = (targetParty.group_id === null || targetParty.group_id === undefined) ? targetParty.id : targetParty.group_id;
            data.update({
                [added.side]: data.data[added.side].map(p => p.id === added.id ? { ...p, group_id: groupId } : p),
            })
        }
    }

    const removeFromGroup = (side: SideKey, id: number) => {
        data.update({
            [side]: data.data[side].map(p => p.id === id ? { ...p, group_id: null } : p),
        });
    }

    const canAddToThisGroup = (target: Party) => {
        return (target.id !== undefined && addingPartyToGroup && addingPartyToGroup.party.id !== target.id) || false;
    }

    return {
        startAddingToGroup,
        cancelAddingToGroup,
        completeAddingToGroup,
        addingPartyToGroup,

        addToGroup,
        removeFromGroup,
        canAddToThisGroup,
    }
}

export const useEditParties = (data: CrudItemData<CorpDispute>) => {
    const groups = useEditGroups(data);
    const side = (sideKey: SideKey) => data.data[sideKey] || [];

    const allParties = () => {
        return [
            ...side("claimants"),
            ...side("respondants"),
            ...side("third_parties"),
            ...side("entity_representative"),
        ];
    }

    const findParty = (id: number) => allParties().find(p => p.id === id);

    const update = (sideKey: SideKey, idx: number, changes: Partial<Party>) => {
        data.update({ [sideKey]: side(sideKey).map((p,i) => i === idx ? { ...p, ...changes } : p) });
    }

    const add = (sideKey: SideKey) => {
        data.update({ [sideKey]: [...side(sideKey), { title: "", inn: "" }] });
    }

    const remove = (sideKey: SideKey, idx: number) => {
        const removed = side(sideKey)[idx];
        
        const oldGroupId = removed.id;
        let changes: Partial<CorpDispute> = {};

        if(oldGroupId !== undefined) {
            const groupMembers = allParties().filter(p => p.id !== undefined && p.group_id === oldGroupId);
            const representative = data.data.representatives[oldGroupId];

            if(groupMembers.length > 0) {
                const newLead = groupMembers[0];
                delete newLead.group_id;
                const others = groupMembers.slice(1).map(p => ({ ...p, group_id: newLead.id }));
                const updatedParties = [newLead, ...others];

                changes = {
                    ...changes,
                    claimants: side("claimants").map(p => updatedParties.find(px => px.id === p.id) || p),
                    respondants: side("respondants").map(p => updatedParties.find(px => px.id === p.id) || p),
                    third_parties: side("third_parties").map(p => updatedParties.find(px => px.id === p.id) || p),
                    entity_representative: side("entity_representative").map(p => updatedParties.find(px => px.id === p.id) || p),  
                };

                if(representative) {
                    const representatives = { ...data.data.representatives };
                    delete representatives[oldGroupId];
                    representatives[newLead.id as number] = { ...representative };
                    changes.representatives = representatives;
                }
            }
        }
        changes[sideKey] = (changes[sideKey] || side(sideKey)).filter((s,i) => i !== idx);
        data.update(changes);
    }


    const representative = (groupId: number) => (data.data.representatives || {})[groupId];

    const addRepresentative = (groupId: number) => {
        data.update({
            representatives: {
                ...(data.data.representatives || {}),
                [groupId]: { title: "" },
            },
        });
    }

    const updateRepresentative = (groupId: number, changes: Partial<Representative>) => {
        data.update({
            representatives: {
                ...(data.data.representatives || {}),
                [groupId]: { ...data.data.representatives[groupId], ...changes },
            },
        });
    }

    const removeRepresentative = (groupId: number) => {
        const representatives = { ...(data.data.representatives || {}) };
        delete representatives[groupId];
        data.update({ representatives });
    }

    return {
        side,
        update,
        add,
        remove,
        findParty,

        representative,
        addRepresentative,
        updateRepresentative,
        removeRepresentative,

        groups,
    }
}
