import React from 'react';
import { AlertColor } from '../../../toolympus/components/primitives/Alert';
import { Status } from '../../../toolympus/components/StatusAction';
import { Vote } from './usePresidiumVoteList';

interface Props {
    vote: Vote;
}

export const VoteStatus = ({ vote }: Props) => {
    return <Status
        status={vote.status || "draft"}
        labels={{
            draft: "Черновик",
            inprogress: "Голосование",
            closed: "Завершено",
            "closed-down": "Отменено",
        }}
        hints={{
            draft: { },
            inprogress: { color: AlertColor.warning },
            closed: { color: AlertColor.success },
            "closed-down": { color: AlertColor.error },
        }}
        chipStyles={{ justifySelf: 'flex-end' }}
        />
}
