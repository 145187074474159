import { Code, FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, FormatBold, FormatColorText, FormatItalic, FormatListBulleted, FormatListNumbered, FormatStrikethrough, FormatUnderlined } from '@mui/icons-material';
import React from 'react';
import { Editor } from 'slate';
import { toggleMark, isMarkActive, toggleBlock, isBlockActive, TextFormat } from "./EditorCommands";
import { EditorCommand } from "./PowerEditorConfig";

export const StockCommands: EditorCommand[] = [
    {
        name: "bold",
        hotkey: "mod+b",
        menu: { section: "formatting", label: "Bold", label_id: "powerdoc.format.bold", icon: <FormatBold />},
        invoke: e => toggleMark(e, "bold"),
        isActive: e => isMarkActive(e, "bold"),
    },
    {
        name: "italic",
        hotkey: "mod+i",
        menu: { section: "formatting", label: "Italic", label_id: "powerdoc.format.italic", icon: <FormatItalic />},
        invoke: e => toggleMark(e, "italic"),
        isActive: e => isMarkActive(e, "italic"),
    },
    {
        name: "underline",
        hotkey: "mod+u",
        menu: { section: "formatting", label: "Underline", label_id: "powerdoc.format.underline", icon: <FormatUnderlined />},
        invoke: e => toggleMark(e, "underline"),
        isActive: e => isMarkActive(e, "underline"),
    },
    {
        name: "strikethrough",
        hotkey: "mod+alt+s",
        menu: { section: "formatting", label: "Strike-through", label_id: "powerdoc.format.strikethrough", icon: <FormatStrikethrough />},
        invoke: e => toggleMark(e, "strikethrough"),
        isActive: e => isMarkActive(e, "strikethrough"),
    },
    {
        name: "code",
        hotkey: "mod+`",
        menu: { section: "formatting", label: "Code", label_id: "powerdoc.format.code", icon: <Code />},
        invoke: e => toggleMark(e, "code"),
        isActive: e => isMarkActive(e, "code"),
    },
    {
        name: "highlight",
        hotkey: "mod+h",
        menu: { section: "formatting", label: "Highlight", label_id: "powerdoc.format.highlight", icon: <FormatColorText />},
        invoke: e => toggleMark(e, "highlight"),
        isActive: e => isMarkActive(e, "highlight"),
    },

    
    {
        name: "align-left",
        menu: { section: "formatting", label: "Left", label_id: "powerdoc.align.left", icon: <FormatAlignLeft />},
        invoke: e => toggleBlock(e, "left"),
        isActive: e => isBlockActive(e, "left"),
    },
    {
        name: "align-right",
        menu: { section: "formatting", label: "Right", label_id: "powerdoc.align.right", icon: <FormatAlignRight />},
        invoke: e => toggleBlock(e, "right"),
        isActive: e => isBlockActive(e, "right"),
    },
    {
        name: "align-center",
        menu: { section: "formatting", label: "Center", label_id: "powerdoc.align.center", icon: <FormatAlignCenter />},
        invoke: e => toggleBlock(e, "center"),
        isActive: e => isBlockActive(e, "center"),
    },
    {
        name: "align-justify",
        menu: { section: "formatting", label: "Justify", label_id: "powerdoc.align.justify", icon: <FormatAlignJustify />},
        invoke: e => toggleBlock(e, "justify"),
        isActive: e => isBlockActive(e, "justify"),
    },
    
    {
        name: "ol",
        menu: { section: "elements", label: "Numbered List", label_id: "powerdoc.elements.ol", icon: <FormatListNumbered /> },
        invoke: e => toggleBlock(e, "ol"),
        isActive: e => isBlockActive(e, "ol"),
    },
    {
        name: "ul",
        menu: { section: "elements", label: "List", label_id: "powerdoc.elements.ul", icon: <FormatListBulleted /> },
        invoke: e => toggleBlock(e, "ul"),
        isActive: e => isBlockActive(e, "ul"),
    },

    ...([1,2,3,4,5,6].map(hl => ({
        name: `heading${hl}`,
        menu: { section: "elements", label: `Heading ${hl}`, label_id: "powerdoc.elements.heading", label_values: { level: hl } },
        invoke: (e: Editor) => toggleBlock(e, `h${hl}` as unknown as TextFormat),
        isActive: (e: Editor) => isBlockActive(e, `h${hl}` as unknown as TextFormat),
    }))),
    {
        name: "paragraph",
        menu: { section: "elements", label: "Paragraph", label_id: "powerdoc.elements.paragraph" },
        invoke: e => toggleBlock(e, "paragraph" as TextFormat),
        isActive: e => isBlockActive(e, "paragraph" as TextFormat),
    },

];
