import React, { useState } from 'react';
import { TableContainer, Paper, Button } from '@mui/material';
import { TableForFields } from '../../../toolympus/components/schemed';
import { Exporter, ImportData, Importer, ImportResult } from '../useCaseImport';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { ImportPopup } from './ImportPopup';
import { ImportResultPopup } from './ImportResultPopup';
import { ImportResultSchema } from '../schema';

interface Props {
    importData: ImportData;
    importer: Importer;
    exporter: Exporter;
}

export const ImportExportScreen = ({ importData, importer, exporter, }: Props) => {
    const [importPopupActive, setImportPopupActive] = useState<boolean>(false);
    const [currentResult, setCurrentResult] = useState<ImportResult | null>(null);

    const augmentedImporter = {
        ...importer,
        performImport: () => importer.performImport().then(r => {
            setImportPopupActive(false);
            setCurrentResult(r);
            importData.reload();
            return r;
        })
    }

    return (
        <TableContainer component={Paper}>
            <div>
                <Button color="primary" onClick={() => { importer.clear(); setImportPopupActive(true)}}>импорт</Button>
                <Button color="primary" onClick={exporter.performExport} endIcon={exporter.isLoading && <LoadingIndicator size="font" />}>экспорт</Button>
                {importData.isLoading && <LoadingIndicator />}
            </div>

            <TableForFields
                fields={[
                    ["created_datetime"],
                    ["created_by"],
                    ["filename"],
                    ["processed_n"],
                    ["skipped_n"],
                    ["errors_n"],
                ]}
                schema={ImportResultSchema}
                data={importData.data}
                onRowClick={row => setCurrentResult(row)} />

            <ImportPopup importer={augmentedImporter} isActive={importPopupActive} setIsActive={setImportPopupActive} />
            <ImportResultPopup result={currentResult} isActive={!!currentResult} close={() => setCurrentResult(null)} />
        </TableContainer>
    )
}
