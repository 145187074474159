import { WidgetConfig } from "./types";

export const injectLinkFields = (link: string, row: Record<string, any>) => link.replace(/{(\w+)}/g, (_, f) => row[f]);

export const getWidgetLink = (w: WidgetConfig, row: Record<string, any> | null): string | null => {
    const links = (w.display_settings.link || "").split("\n").map(l => l.trim()).filter(l => l.length);
    const label = row && row[w.label_field];

    if(links.length === 0) {
        return null;
    } else if(links.length === 1) {
        return injectLinkFields(links[0], row || {});
    } else {
        const linksMap = links.map(l => {
            const sep = l.indexOf(':');
            if(sep > 0) {
                return [l.substring(0, sep).trim(), l.substring(sep+1).trim()]
            } else {
                return ["_never_match_", "#"];
            }
        }).reduce((r, [k,v]) => ({ ...r, [k]: v }), {} as Record<string, string>);

        return linksMap[label || "*"] || linksMap["*"] || null;
    }
}
