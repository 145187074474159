import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, ButtonGroup, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, Typography, } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ActionRow, Dialog, Form, OccupyFreeSpace, SaveButton, SearchField, SimpleDialog, Tooltip, useCopyText, useListAutoexpander, useWindowHotkey } from '../primitives';
import { FormControlsForFields } from '../schemed';
import { LocalizationMessagesEdit, Mode } from './useLocalizationMessagesEdit';
import { Add, Warning } from '@mui/icons-material';
import { Checkbox } from '../schemed/Checkbox';
import { useSaveable } from '../primitives/useSaveable';


const JsonEditor = styled.textarea`
    background: #333355;
    color: #fefeee;
    border-radius: 4px;
    min-height: 100%;
    font-family: monospace;
`;

const MessageEditorWrapper = styled.div`
  width: 100%;
  padding: 6px 0 2px;
  position: relative;

  & textarea {
    resize: none;
    width: 100%;
    padding: 0;
    font-size: 16px;
    font-family: inherit;
    border: none;
    outline: none;
    box-shadow: none;
    color: ${props => props.theme.palette.text.primary};
  }


  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.palette.grey[500]};
    display: block;
    content: '';
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid ${props => props.theme.palette.primary.main};
    display: block;
    content: '';
    opacity: 0;
    transform: scaleX(0);
    transition: all 0.2s ease;
  }

  &:focus-within, &:hover {
    &:after {
      opacity: 1;
      transform: scaleX(1);
      transition: opacity 0.4s ease;
    }
  }
`;

const MessageEditor = ({ value, update, k }: { k: string, value: string | undefined, update: (k: string, v: string) => void}) => (
  <MessageEditorWrapper>
    <TextareaAutosize
      value={value || ""}
      onChange={e => update(k, e.target.value)}
      />
  </MessageEditorWrapper>
)

const MessageEditorM = React.memo(MessageEditor);


interface Props {
    data: LocalizationMessagesEdit;
}

export const LocalizationMessages = (props: Props) => {
    const {
        mode,
        setMode,

        selectedLanguage,
        setSelectedLanguage,
        availableLanguages,
        addLanguage,

        messages,
        messagesKeys,
        updateMessage,
        addMessage,
        showEmptyOnly,
        setShowEmptyOnly,
        filter,
        setFilter,
        
        updateJson,
        messagesJson,
        isJsonError,
        
        hasChanges,
        save,

        pullMessagesFromOtherLanguage,
    } = props.data;

    const copyText = useCopyText();

    const [newLanguage, setNewLanguage] = useState<{ lang: string } | null>(null);

    const startAddNewLanguage = () => {
        setNewLanguage({ lang: "" });
    }

    const [newKey, setNewKey] = useState<string | null>(null);
    const startAddNewKey = () => {
        setNewKey("");
    }

    const [isPullMessagesOpen, setIsPullMessagesOpen] = useState<boolean>(false);

    
    const addNewIfInStrings = () => {
        if(mode === "strings") {
            startAddNewKey();
        }
    }
    
    useSaveable(props.data);
    useWindowHotkey("alt+n", addNewIfInStrings);

    const autoexpander = useListAutoexpander(messagesKeys, 30, 30);

    return (
        <Form
            fitFullHeight
            formBodyProps={{
                style: { height: "100%" },
            }}
            title={<FormattedMessage id="localization.messages.title" />}
            headerItems={<>
                <ButtonGroup key="mode" size="small" color="primary" disabled={hasChanges}>
                    {["strings", "json"].map(m => (
                        <Button
                            key={m}
                            variant={m === mode ? "contained" : "outlined"}
                            onClick={() => setMode(m as Mode)}>
                                <FormattedMessage id={`localization.messages.mode.${m}`} />
                        </Button>
                    ))}
                </ButtonGroup>
                <OccupyFreeSpace />

                <Tooltip text_id="localization.messages.pull_messages_from_other_lang_hint">
                    <Button
                        key="pull"
                        color="primary"
                        disabled={hasChanges}
                        onClick={() => setIsPullMessagesOpen(true)}>
                            <FormattedMessage id="localization.messages.pull_messages_from_other_lang" />
                    </Button>
                </Tooltip>

                {isJsonError && (
                    <Tooltip text_id="localization.messages.json_error">
                        <Warning htmlColor="#cc0000" />
                    </Tooltip>
                )}

                <ButtonGroup key="lang" size="small" color="primary">
                    {availableLanguages.map(l => (
                        <Button
                            key={l}
                            variant={l === selectedLanguage ? "contained" : "outlined"}
                            onClick={() => setSelectedLanguage(l)}>
                                {l}
                        </Button>
                    ))}
                    <Button
                        key="new"
                        variant="outlined"
                        onClick={startAddNewLanguage}>
                            + <FormattedMessage id="localization.messages.add_language" />
                    </Button>
                </ButtonGroup>

                {hasChanges && <SaveButton action={() => save()} />}
            </>}
        >
            {mode === "strings" && (
                <>
                    <ActionRow alignItems="end">
                        <IconButton size="small" onClick={startAddNewKey}>
                            <Add />
                        </IconButton>

                        <OccupyFreeSpace />
                        <Checkbox
                            field="showEmptyOnly"
                            row={{ showEmptyOnly }}
                            onChange={(_,c) => setShowEmptyOnly(c.showEmptyOnly)}
                            schema={{ label_id: "localization.messages.show_empty_only" }}
                            extraProps={{ controlProps: { style: { padding: "0 9px" }}}}
                            />

                        <SearchField
                            filter={filter}
                            setFilter={setFilter}
                            noButton
                            autoFocus
                            />
                    </ActionRow>

                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell key="k"><FormattedMessage id="localization.messages.table.key" /></TableCell>
                                    <TableCell key="v"><FormattedMessage id="localization.messages.table.message" /></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {autoexpander.visibleItems.map(k => (
                                    <TableRow key={k}>
                                        <TableCell key="k" onClick={() => copyText(k)}>{k}</TableCell>
                                        <TableCell key="v">
                                            <MessageEditorM value={messages[k]} k={k} update={updateMessage} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {autoexpander.anchor}
                    </TableContainer>
                </>
            )}

            {mode === "json" && <JsonEditor
                value={messagesJson}
                onChange={e => updateJson(e.target.value)}
                
                />}

            <SimpleDialog
                isOpen={!!newLanguage}
                close={() => setNewLanguage(null)}
                dialogTitle={<FormattedMessage id="localization.messages.add_language" />}
                noFullscreen
                save={() => {
                    if(newLanguage?.lang) {
                        addLanguage(newLanguage.lang);
                        setNewLanguage(null);
                    }
                }}>
                <FormControlsForFields
                    data={newLanguage}
                    fields={[["lang", { controlProps: { autoFocus: true }}]]}
                    schema={{ lang: { label_id: "localization.messages.language_code" }}}
                    onChange={(o,c) => setNewLanguage({ ...o, ...c })}
                    />
            </SimpleDialog>

            <SimpleDialog
                isOpen={newKey !== null}
                close={() => setNewKey(null)}
                dialogTitle={<FormattedMessage id="localization.messages.add_message" />}
                noFullscreen
                save={() => {
                    if(newKey && !messages[newKey]) {
                        addMessage(newKey);                        
                        setNewKey(null);
                    }
                }}>
                <FormControlsForFields
                    data={{ k: newKey }}
                    fields={[["k", { controlProps: { autoFocus: true }}]]}
                    schema={{ k: { label_id: "localization.messages.table.key" }}}
                    onChange={(o,c) => setNewKey(c.k)}
                    />
            </SimpleDialog>


            <Dialog
                isOpen={!!isPullMessagesOpen}
                close={() => setIsPullMessagesOpen(false)}
                dialogTitle={<FormattedMessage id="localization.messages.pull_messages_from_other_lang" />}
                noFullscreen>
                    <Typography><FormattedMessage id="localization.messages.pull_messages_from_other_lang_select_lang" /></Typography>
                    <ButtonGroup color="primary">
                        {availableLanguages.filter(l => l !== selectedLanguage).map(l => (
                            <Button
                                key={l}
                                variant="outlined"
                                onClick={() => { pullMessagesFromOtherLanguage(l); setIsPullMessagesOpen(false); }}>
                                    {l}
                            </Button>
                        ))}
                    </ButtonGroup>
            </Dialog>
        </Form>
    );
}
