import { Button, IconButton } from '@mui/material';
import { CallMade, FileCopyOutlined } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ActionRow, Dialog, FormGrid, ItemDialogState, OccupyFreeSpace, useCopyText } from '../primitives';
import { FormControlsForFields } from '../schemed';
import { displayJson } from '../schemed/JsonEdit';
import { getProgramLink, RobudConfig } from './Config';
import { ExecutionSchema } from './schema';
import { ProgramExecution } from './types';

interface Props extends Pick<RobudConfig, "pagePath"> {
    state: ItemDialogState<ProgramExecution>;
}

const LinkButton = ({ link }: { link: string }) => (
    <Link to={link}><IconButton size="small"><CallMade /></IconButton></Link>
)

export const ExecutionDetailsPopup = ({ state, pagePath }: Props) => {
    const copyText = useCopyText();
    return (
        <Dialog
            {...state}
            dialogTitle={<FormattedMessage id="robud.executions.details_title" />}
            fullWidth
            maxWidth="md"
            noFullscreen
            >
            <FormGrid columns="40px 50px 1fr 50px max-content">
                {state.item && <FormControlsForFields
                    fields={[
                        ["execution_id", { readOnly: true }],
                        ["program_id", {
                            readOnly: true,
                            controlProps: {
                                endAdornment: <LinkButton link={getProgramLink(pagePath, { program_id: state.item.program_id })} />,
                            }
                        }],
                        ["program_title", { readOnly: true }],
                        ["trigger_id", {
                            readOnly: true,
                            controlProps: {
                                endAdornment: <LinkButton link={getProgramLink(pagePath, state.item)} />,
                            }
                        }],
                        ["created_at", { utcToLocal: true, readOnly: true }],
                    ]}
                    data={state.item}
                    schema={ExecutionSchema}
                    onChange={() => {}}
                    />}
            </FormGrid>

            <ActionRow lastItemMarginRight="0" itemMarginTop="1rem">
                <OccupyFreeSpace />
                
                <Button
                    size="small"
                    color="primary"
                    startIcon={<FileCopyOutlined />}
                    onClick={() => state.item && copyText(displayJson(state.item.log?.data))}
                    >
                    <FormattedMessage id="robud.executions.copy_data" />
                </Button>
                <Button
                    size="small"
                    color="primary"
                    startIcon={<FileCopyOutlined />}
                    onClick={() => state.item && copyText(displayJson(state.item.log?.results))}
                    >
                    <FormattedMessage id="robud.executions.copy_results" />
                </Button>
                {(state.item?.log?.errors || []).length > 0 && <Button
                    size="small"
                    color="primary"
                    startIcon={<FileCopyOutlined />}
                    onClick={() => state.item && copyText(displayJson(state.item.log?.errors))}
                    >
                    <FormattedMessage id="robud.executions.copy_errors" />
                </Button>}
            </ActionRow>
            <FormGrid columns="1fr">
                {state.item && <FormControlsForFields
                    fields={[
                        ["data", { readOnly: true }],
                        ["results", { readOnly: true }],
                        ["errors", { readOnly: true }],
                    ]}
                    data={state.item.log || {}}
                    schema={ExecutionSchema}
                    onChange={() => {}}
                    />}
            </FormGrid>
        </Dialog>
    );
}
