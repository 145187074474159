import React from 'react';
import styled from '@emotion/styled';
import { DisplayProps, FormControlProps } from '../../schemed/FormControlProps';
import { PowerEditorBase } from './PowerEditorBase';
import { Buttons } from '../../primitives';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled(Buttons)`
  & > div[role="textbox"] {
    width: 100%;
  }
`;

interface Props extends FormControlProps {
  
}

export const PowerEditorFormControl = (props: Props) => {
  const { field, row, onChange, schema } = props;


  return (<>
    <Wrapper vertical fullWidth gap="small">
      <Typography variant="caption" color="textSecondary">
        {props.extraProps?.label || (schema?.label_id ? <FormattedMessage id={schema?.label_id} /> : schema?.label)}
      </Typography>
      
      <PowerEditorBase
        content={(row || {})[field]}
        update={v => onChange(row, { [field]: v })}
        autoFocus={props.extraProps?.controlProps?.autoFocus}
        viewMode={props.extraProps?.readOnly || props.extraProps?.disabled}
        />
    </Wrapper>
  </>
  );
}

export const PowerEditorDisplay = (props: DisplayProps) => {
  const { field, row } = props;

  return (
    <PowerEditorBase
      content={(row || {})[field]}
      update={() => {}}
      viewMode
      placeholder=" "
      />
  )
}
