import { Button, DialogActions, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, LoadingIndicator } from '../primitives';
import { SendInvitesData } from './useSendInvites';
import { IDefaultRolesUser } from './useUserManagement';

interface Props<IUser extends IDefaultRolesUser> {
    data: SendInvitesData<IUser>;
}

export const SendInvitesConfirmationPopup = <IUser extends IDefaultRolesUser>({ data }: Props<IUser>) => {
    return (
        <Dialog
            {...data.dialog}
            dialogTitle={<FormattedMessage id="userManagement.header.sendInvites" />}
            noFullscreen>
            <Typography>
                <FormattedMessage id="userManagement.header.sendInvintesTip" />:
            </Typography>
            <List dense>
                {data.dryRunUsers.map(user => (
                    <ListItem key={user._id}>
                        <ListItemText
                            primary={user.email}
                            secondary={user.roles.join(", ")}
                            />
                    </ListItem>
                ))}
            </List>

            <DialogActions>
                <Button onClick={data.dialog.close}><FormattedMessage id="common.cancel" /></Button>
                <Button
                    onClick={() => {
                        data.sendInvites()
                            .then(x => {
                                data.dialog.close();
                                return x;
                            })
                    }}
                    disabled={data.isRunning || data.dryRunUsers.length === 0}
                    startIcon={data.isRunning ? <LoadingIndicator size="s" /> : undefined}
                    color="primary"
                    variant="contained">
                    <FormattedMessage id="userManagement.header.sendInvites" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
