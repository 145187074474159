import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { useColorScheme } from '../Colors';
import { ChartRow, ChartProps } from '../util';

const BarWrapper = styled.div`
    position: relative;

    & .bar-bar {
        transform-origin: center bottom;
        transition: transform 0.3s ease, opacity 1s ease;
        &:hover {
            opacity: 0.9;
            transform: scaleX(1.05);
        }
    }
`;



export const ColumnChart = (props: ChartProps) => {
    const { rows, width, openDetails } = props;

    const height = width;

    const { singleColor, axisColor, axisLabelColor, gridColor } = useColorScheme(rows);
    const tooltip = useTooltip();

    const margin = { left: 30, right: 20, top: 10, bottom: 10 };

    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const xScale = useMemo(
        () =>
          scaleBand<string>({
            range: [0, xMax],
            round: true,
            domain: rows.map(r => r.label),
            padding: 0.4,
          }),
        [rows, xMax],
      );

    const yScale = useMemo(
        () =>
            scaleLinear<number>({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...rows.map(r => r.value))],
            }),
        [rows, yMax],
    );

    if(!width) {
        return null;
    }

    return (
        <BarWrapper>
            <svg width={width} height={height}>
            <Group top={margin.top / 2} left={margin.left}>
                
                <GridRows scale={yScale} width={xMax} height={yMax} stroke={gridColor} />

                {rows.map((d) => {
                    const barWidth = xScale.bandwidth();
                    const barHeight = yMax - (yScale(d.value) ?? 0);
                    const barX = xScale(d.label);
                    const barY = yMax - barHeight;

                    return (
                        <Bar
                            key={`bar-${d.label}`}
                            x={barX}
                            y={barY}
                            width={barWidth}
                            height={barHeight}
                            style={{ transformOrigin: `${(barX || 0) + 0.5*barWidth}px 0px` }}
                            fill={singleColor}
                            onClick={() => openDetails(d)}
                            onMouseLeave={() => {
                                tooltip.hideTooltip();
                            }}
                            className="bar-bar"
                            onMouseMove={() => {
                                tooltip.showTooltip({
                                    tooltipData: d,
                                    tooltipTop: (barY || 0) - 20,
                                    tooltipLeft: (barX || 0) + barWidth*0.25,
                                });
                            }}
                        />
                    );
                })}

                <AxisLeft
                    hideTicks
                    scale={yScale}
                    stroke={axisColor}
                    tickStroke={axisColor}
                    tickLabelProps={() => ({
                        fill: axisLabelColor,
                        fontSize: 11,
                        textAnchor: 'end',
                        dy: '0.33em',
                    })}
                    />
                <AxisBottom
                    top={yMax}
                    scale={xScale}
                    stroke={axisColor}
                    tickStroke={axisColor}
                    tickValues={rows.map(() => " ")}
                    hideZero
                    hideTicks
                    tickLabelProps={() => ({
                        fill: axisLabelColor,
                        fontSize: 11,
                        textAnchor: 'middle',
                    })}
                    />
            </Group>
            </svg>
            {tooltip.tooltipOpen && tooltip.tooltipData &&
                <TooltipWithBounds
                    applyPositionStyle
                    top={(tooltip.tooltipTop || 0)}
                    left={(tooltip.tooltipLeft || 0)}>
                    {(tooltip.tooltipData as ChartRow).label} – {(tooltip.tooltipData as ChartRow).value}
                </TooltipWithBounds>}
        </BarWrapper>
    );
}
