import React from 'react';
import { PartyPoll, PollConfig } from './polls.types';
import { Schema } from '../../toolympus/hooks/useSchema';
import { Dialog, FormGrid } from '../../toolympus/components/primitives';
import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { LinkOutlined } from '@mui/icons-material';

interface Props {
  poll: PartyPoll | null;
  close: () => void;
  config: PollConfig;
  schema: Schema;
  startLinkToCase: (poll: PartyPoll) => void;
}

export const PollReviewDialog = (props: Props) => {
  return (
    <Dialog
      isOpen={!!props.poll}
      close={props.close}
      dialogTitle="Опрос"
      fullWidth
      maxWidth="md"
      noFullscreen
      titleActions={<>
        <Button size="small" color="primary" startIcon={<LinkOutlined />} onClick={() => props.poll && props.startLinkToCase(props.poll)}>связать с делом</Button>
      </>}
      actions={<>
        <Button onClick={() => props.close()}><FormattedMessage id="common.close" /></Button>
      </>}>

      
      <FormGrid columns="1fr 2fr 2fr" noMargin>
        <FormControlsForFields
          data={props.poll}
          schema={props.schema}
          fields={[
            ["_id"],
            ["created_datetime", { utcToLocal: true }],
            ["filled_datetime", { utcToLocal: true }],
          ]}
          readOnly
          onChange={() => {}}
          />
      </FormGrid>

      {!!props.poll && (props.config.config?.fields || []).map(section => (
        <FormGrid columns="1fr" key={section.section}>
          <Typography style={{ fontWeight: 500 }}>{section?.labels?.ru?.title || section.section}</Typography>
          <FormControlsForFields
            data={props.poll?.fields || {}}
            schema={props.schema}
            fields={(section.fields || []).map(f => (
              [`${section.section}_${f.code}`, { rowsAuto: true }]
            ))}
            readOnly
            onChange={() => {}}
            />
        </FormGrid>
      ))}
    </Dialog>
  );
}
