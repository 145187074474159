import React from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FormBody, FormRow, FormColumn, FormWiderColumn, SectionTitle } from '../../../toolympus/components/primitives/Forms';
import { FieldDefinition, FormControlsForFields } from '../../../toolympus/components/schemed';
import { ImportResultSchema, ImportResultLogSchema } from '../schema';
import { ImportResult } from '../useCaseImport';

interface Props {
    isActive: boolean;
    close: () => void;
    result: ImportResult | null;
}

const JsonView = styled.pre`
    width: 100%;
    background: ${props => props.theme.palette.grey[100]};
    padding: 2rem 1rem;
    font-family: 'Courier New', Courier, monospace;
    white-space: break-spaces;
`;

export const ImportResultPopup = ({ isActive, close, result }: Props) => {
    const disableField: (x: string[]) => FieldDefinition = ([f]) => ([ f, { controlProps: { disabled: true }}]);
    const preventChanges = () => { };

    return (
        <Dialog open={isActive} onClose={close} maxWidth="md">
            <DialogTitle>Результаты импорта</DialogTitle>
            {result && (
                <DialogContent>
                    <FormBody>
                        <FormRow>
                            <FormColumn>
                                <FormControlsForFields
                                        fields={[
                                            ["row-n"],
                                        ].map(disableField)}
                                        schema={ImportResultLogSchema}
                                        data={result.log}
                                        onChange={preventChanges} />
                                <FormControlsForFields
                                        fields={[
                                            ["processed-n"],
                                            ["skipped-n"],
                                            ["errors-n"],
                                        ].map(disableField)}
                                        schema={ImportResultLogSchema}
                                        data={result.log.rows}
                                        onChange={preventChanges} />
                            </FormColumn>
                            <FormWiderColumn>
                                <FormControlsForFields
                                        fields={[
                                            ["created_datetime"],
                                            ["created_by"],
                                            ["filename"],
                                        ].map(disableField)}
                                        schema={ImportResultSchema}
                                        data={result}
                                        onChange={preventChanges} />
                            </FormWiderColumn>
                        </FormRow>
                        <FormRow>
                            <SectionTitle>Конфигурация</SectionTitle>
                            <JsonView>
                                {JSON.stringify(result.mode, undefined, 2)}
                            </JsonView>
                        </FormRow>
                        <FormRow>
                            <SectionTitle>Лог</SectionTitle>
                            <JsonView>
                                {JSON.stringify(result.log, undefined, 2)}
                            </JsonView>
                        </FormRow>
                    </FormBody>
                </DialogContent>
            )}
            <DialogActions>
                <Button color='secondary' variant='contained' onClick={close}>Закрыть</Button>
            </DialogActions>
        </Dialog>);
}
