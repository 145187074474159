import React from 'react';
import { OpenDetailsWidgetFn, WidgetConfig, WidgetResult } from "../types";
import { Number } from "./Number/Number";
import { Placeholder } from './Number/Placeholder';
import { DiagramContainer } from './Diagram/DiagramContainer';

type WidgetFn = (config: WidgetConfig, result: WidgetResult, openDetails: OpenDetailsWidgetFn, getColumnWidth?: (cols: number) => string) => JSX.Element;

export const cases: Record<string, WidgetFn> = {
    counter:
     (_, res, openDetails, getColumnWidth = () => '100%') => <Number data={res} openDetails={openDetails} getColumnWidth={getColumnWidth} />,
    pie:
        (_, res, openDetails, getColumnWidth) => 
            <DiagramContainer type="pie" openDetails={openDetails} getColumnWidth={getColumnWidth} res={res} />,
    bar: 
        (_, res, openDetails, getColumnWidth) => 
            <DiagramContainer type="bar" openDetails={openDetails} getColumnWidth={getColumnWidth} res={res} />,
    placeholder:
        (config, res, openDetails, getColumnWidth) =>
            <Placeholder config={config} getColumnWidth={getColumnWidth} />,
}

export const switchWidget = (
    config: WidgetConfig,
    result: WidgetResult,
    openDetails: OpenDetailsWidgetFn,
    custom: typeof cases,
    getColumnWidth: (cols: number) => string = () => '100%') => {
    
        const fn = custom[result.widgettype] || cases[result.widgettype] || (() => <></>);
        return fn(config, result, openDetails, getColumnWidth);
}