import React from 'react';
import styled from '@emotion/styled';
import { RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { IconButton, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { CustomElement } from '../../../../../slate';
import { EditorPlugin } from '../../slate/PowerEditorConfig';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { BlockSelectionCss } from '../../elements/Common';
import { createWithVoidInjector } from '../common';
import { OccupyFreeSpace } from '../../../primitives';
import { BlockSettingsCommand } from '../BlockSettings';

export const SettingsBlockElementType = "settings_block";


const Wrapper = styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}

    & > div {
      display: flex;
      align-items: center;
      gap: 6px;

      & .title + .description {

      }

      & .description {
        white-space: pre-line;
      }
    }
`;


export const SettingsBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const isSelected = useSelected();

    const { viewMode, invokeCommand } = usePowerEditorContext();
    const editor = useSlateStatic();

    const settings = element as any;

    return (
        <Wrapper {...attributes} isSelected={isSelected}>
            {children}
            
            <div style={{ width: "100%", alignItems: settings.hint ? "flex-start" : undefined }} contentEditable={false}>
              <div className="texts">
                {settings.title &&
                  <Typography className="title">{settings.title}</Typography>}
                {settings.hint &&
                  <Typography className="hint" variant="caption" color="textSecondary" component="p">{settings.hint}</Typography>}
              </div>
              <OccupyFreeSpace />
              {!viewMode &&
                <IconButton size="small" onClick={() => invokeCommand(BlockSettingsCommand, editor, element as any)}><Settings /></IconButton>}
            </div>
        </Wrapper>
    );
}

const withSettingsBlock = createWithVoidInjector(SettingsBlockElementType);

export const SettingsBlockPlugin: EditorPlugin = {
    key: "settings-block",
    commands: [{
        name: "insert-settings-block",
        menu: { section: "insert-item", label: "Settings block", label_id: "powerdoc.plugins.settings_block.title" },
        invoke: editor => {
            editor.insertNode({
                type: SettingsBlockElementType,
                children: [{ text: "", }],
            } as CustomElement);
        }
    }],
    customBlocks: { [SettingsBlockElementType]: SettingsBlockElement },
    inject: e => withSettingsBlock(e),
};
