import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, FormGrid } from '../components/primitives';
import { Checkbox } from '../components/schemed/Checkbox';
import { FeatureManager, FeaturesStateBase } from './FeatureManager';

export interface FeatureSwitchesProps<T extends FeaturesStateBase> {
    featureManager: FeatureManager<T>;
}

export const FeatureSwitchesForm = <T extends FeaturesStateBase>(props: FeatureSwitchesProps<T>) => {
    const { features, isFeatureOn, setIsFeatureOn } = props.featureManager;

    // This is a dirty trick to force re-renders when feature state is changed
    // needed because featureManager gets states from localStorage and does not notify of any changes
    // (its identity is stable, so it won't automatically case re-renders of the checkboxes)
    const [updater, setUpdater] = useState<number>(0);

    return (
        <Form title={<FormattedMessage id="features.switches.title" />}>
            <FormGrid columns="1fr 1fr" key={updater}>
                {features.map(feature => (
                    <Checkbox
                        field={feature.toString()}
                        schema={{ label: feature }}
                        row={{ [feature]: isFeatureOn(feature) }}
                        onChange={(o,c) => { setIsFeatureOn(feature, c[feature]); setUpdater(x => x + 1)}}
                        />
                ))}
            </FormGrid>
        </Form>
    );
}
