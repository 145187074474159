import { Container } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

export interface AppWrapperProps {
    children?: ReactNode;
    limitHeight?: boolean;
}

export const AppContainerView = styled(Container)<{ limitHeight?: boolean }>`
    min-height: 100vh;
    display: flex !important;
    max-width: 100vw;

    ${props => props.theme.breakpoints.up('lg')} {
        ${props => props.limitHeight ?  "max-height: 100vh;" : ""}
    }

    @media(max-width: 1280px){
        max-width: 100vw;
        display: flex;
    }
`;;

export const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps) => {
    return (
        <AppContainerView disableGutters maxWidth={false} limitHeight={props.limitHeight}>
            {props.children || ""}
        </AppContainerView>
    )
}