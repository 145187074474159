import React from 'react';
import { FormControl } from './FormControl';
import { FieldSchema } from '../../hooks/useSchema';
import { OnFieldChange } from './Common';
import { ExtraProps } from './FormControlProps';

interface Props {
    row: any;
    field: string;
    schema: FieldSchema;
    onChange: OnFieldChange;
    extraProps?: ExtraProps;
}

export const TableEditor = (props: Props) => <FormControl {...props} extraProps={{ ...(props.extraProps || {}), label: " "}} />;
