import React from 'react';
import styled from '@emotion/styled';
import { Dialog, Paper } from '@mui/material';
import { MediaFile, PossiblyProtectedImg, useMediaLibContext } from '../../../medialib';

const DialogPaper = styled(Paper)`
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: ${props => props.theme.palette.grey[200]};
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: ${props => props.theme.palette.grey[400]};
    }
`;

interface Props {
    image: MediaFile | null;
    close: () => void;
}

export const PreviewImageDialog = (props: Props) => {
    const { image, close } = props;
    const medialib = useMediaLibContext();

    return (
        <Dialog
            open={!!image}
            onClose={() => close()}
            PaperComponent={DialogPaper}
            maxWidth="md">
            {image && <PossiblyProtectedImg
                protected={medialib.protectedFiles}
                src={medialib.getFilepath(image)}
                alt={image.filename}
                />}
        </Dialog>
    );
}
