import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { QueryChunk, QueryChunkItem } from './types';
import { ItemEditorProps, ItemWrapperBasic, ListEditor, ListWrapper } from '../primitives/ListEditor';
import { generateCode } from '../PowerDoc/plugins/common';
import { useDataschema } from './DataschemaContext';
import { Buttons, FormGrid, MoreActionsMenuButton, PseudoLink2, Tooltip } from '../primitives';
import { EditArrayData } from '../../hooks/useEditArray';
import { FormattedMessage } from 'react-intl';
import { QueryChunkQueryEditor, RemoveEndAdornment } from './QueryChunkQueryEditor';
import { TextField } from '@mui/material';
import { useItemWithControls } from '../../api/useWithControls';
import { InfoOutlined } from '@mui/icons-material';


const ChunkInclusionWrapper = styled(FormGrid)`
  & .MuiAutocomplete-root .MuiInputBase-root {
    margin-top: 0;
  }
  & .MuiButtonBase-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
ChunkInclusionWrapper.defaultProps = { noMargin: true, alignItems: "end", columns: "1fr 1fr", forceEvenColumns: true };


const QueryChunkParamsEditor = (props: Pick<ItemEditorProps<QueryChunk>, "item" | "update"> & { config: QueryChunk }) => {
  const paramsConfig = props.config.params_config;

  if(!paramsConfig?.length) {
    return null;
  }

  const paramsValues = props.item.params || {};

  return (
    <FormGrid columns="1fr 1fr" forceEvenColumns>
      {paramsConfig.map(p => (
        <TextField
          key={p.code}
          label={`Param: ${p.code}`}
          placeholder={p.default}
          value={paramsValues[p.code] || ""}
          onChange={e => props.update({ params: { ...paramsValues, [p.code]: e.target.value }})}
          InputProps={{
            endAdornment: (
              <RemoveEndAdornment remove={() => props.update({ params: { ...paramsValues, [p.code]: undefined }})}/>
            )
          }}
          />
      ))}
    </FormGrid>
  )
}


export const QueryChunkInclusionEditor = (props: ItemEditorProps<QueryChunk>) => {
  const { chunkSchema, dataschema } = useDataschema();

  const { item } = props;

  const selectedChunkBase = useMemo(() => {
    return item._id
      ? (dataschema?.chunks_schema?.chunks || []).find(x => x._id === item._id)
      : undefined;
  }, [dataschema, item._id])

  const { controls } = useItemWithControls({ data: props.item, update: props.update }, { schema: chunkSchema });

  return <>
    <ChunkInclusionWrapper>
      {!item.inline && <>
        {controls([
          ["_id", { autoComplete: true, label: " " }],
        ])}
        
        <Buttons>
          {controls([
            ["inline"],
          ])}

          <Tooltip text_id="dataschema.query_chunks.chunk_config.suppress_select" text_params={{ included: (selectedChunkBase?.select || []).join(", ") || "-"}} >
            <PseudoLink2
              onClick={() => props.update({ suppress_select: !item.suppress_select })}
              style={{ textDecoration: "line-through", transform: "scale(0.75)", color: item.suppress_select ? undefined : "inherit" }}>
                select
            </PseudoLink2>
          </Tooltip>
          <Tooltip text_id="dataschema.query_chunks.chunk_config.suppress_order" text_params={{ included: (selectedChunkBase?.order || []).join(", ") || "-"}}>
            <PseudoLink2
              onClick={() => props.update({ suppress_order: !item.suppress_order })}
              style={{ textDecoration: "line-through", transform: "scale(0.75)", color: item.suppress_order ? undefined : "inherit" }}>
                order
            </PseudoLink2>
          </Tooltip>

          {selectedChunkBase && selectedChunkBase.description &&
            <Tooltip text={selectedChunkBase.description}>
              <InfoOutlined fontSize="small" />
            </Tooltip>}
        </Buttons>
      </>}

      
      {!!item.inline &&
        controls([
          ["inline"],
          ["composite"],
        ])
      }
      
    </ChunkInclusionWrapper>

    {!item.inline && !!selectedChunkBase &&
      <QueryChunkParamsEditor item={item} update={props.update} config={selectedChunkBase} />}

    {!!item.inline &&
      <QueryChunkQueryEditor item={item} update={props.update} />}

  </>
}

const ChunkListWrapper = styled(ListWrapper)`
  padding: 0.5rem 0;
`;

const ChunkOuterWrapper = styled(ItemWrapperBasic)`
  & > .item-actions {
    top: 5px;
  }

  border-radius: 16px;
  border: 2px solid ${props => props.theme.palette.primary.main}40;

  margin-bottom: 0.5rem;

  &:focus-within {
    border: 2px solid ${props => props.theme.palette.primary.main}80;
  }
`;





const QueryChunkCopyClipboardKey = "_tl_dsqc_chunk_copy"; 

export const copyQueryChunkToClipboard = (chunk: QueryChunk) => {
  const copy = { ...chunk };
  const text = JSON.stringify({ [QueryChunkCopyClipboardKey]: copy});
  navigator.clipboard.writeText(text);
}

export const getQueryChunkFromClipboard = (): Promise<QueryChunk | null> => {
  return navigator.clipboard.readText()
    .then(text => {
      if(text.includes(QueryChunkCopyClipboardKey)) {
        const data = JSON.parse(text);
        const chunk = data[QueryChunkCopyClipboardKey] as QueryChunk;
        if(chunk) {
          return chunk;
        }
      }
      return null;
    });
}


const ChunkExtraActions = (props: ItemEditorProps<QueryChunk> & { arrayData: EditArrayData<QueryChunk> }) => {

  return (
    <MoreActionsMenuButton
      actions={[
        ["common.delete", <FormattedMessage id="common.delete" />, () => {
          const idx = props.arrayData.items.indexOf(props.item);
          if(idx >= 0) {
            props.arrayData.remove(idx);
          }
        }],
        ["common.copy", <FormattedMessage id="common.copy" />, () => copyQueryChunkToClipboard(props.item)],
        ["common.paste", <FormattedMessage id="common.paste" />, () => {
          getQueryChunkFromClipboard().then(x => {
            if(x) {
              props.arrayData.add(x);
            }
          })
        }],
      ]}
      />
  )
}





interface Props {
  data: QueryChunkItem | null | undefined;
  update: (changes: Partial<QueryChunkItem>) => void;
}

export const CompositeQueryChunkEditor = (props: Props) => {
  return (
    <ListEditor
      items={props.data?.chunks}
      update={v => props.update({ chunks: v })}
      createDflt={() => ({ _xid: generateCode() })}
      idField="_xid"
      itemEditor={QueryChunkInclusionEditor}
      itemWrapper={ChunkOuterWrapper}
      wrapperComponent={ChunkListWrapper}
      noItemMoveButtons
      noItemRemoveButton
      itemExtraActions={ChunkExtraActions}
      addLabel={<FormattedMessage id="dataschema.query_chunks.chunk_config.add_chunk" />}
      />      
  );
}
