import { useCallback, useState } from "react";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { Editor } from "slate";
import { FormGrid, SimpleDialog } from "../../../primitives";
import React from "react";
import { TextField } from "@mui/material";
import { LabelOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

export const TextClassCommand = "text-class";
export const TextClassField = "_class";

interface EditingContext {
  editor: Editor;
  _class: string;
}

const isClassPresent = (editor: Editor) => {
  const marks = Editor.marks(editor)
  return marks ? !!(marks as any)[TextClassField] : false;
}

export const useTextClassPlugin = (): EditorPlugin => {
  const [editingContext, setEditingContext] = useState<EditingContext | null>(null);


  const startEdit = useCallback(
      (editor: Editor) => {
        const marks = Editor.marks(editor);
        const present = (marks || {})[TextClassField];
        if(!!present) {
          // Editor.removeMark(editor, TextClassField);
          setEditingContext({ editor, _class: present });
        } else {
          setEditingContext({ editor, _class: "" });
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

  const completeEdit = () => {
    const editor = editingContext?.editor;
    const _class = editingContext?._class;
    if(editor) {
      if(_class) {
        Editor.addMark(editor, TextClassField, _class);
      } else {
        Editor.removeMark(editor, TextClassField);
      }

    }
    setEditingContext(null);
  };

  const dialog = (<>
    <SimpleDialog
      isOpen={!!editingContext}
      close={() => setEditingContext(null)}
      dialogTitle={<FormattedMessage id="powerdoc.format.text_class" />}
      save={completeEdit}>
        <FormGrid columns="1fr">
          <TextField
            value={editingContext?._class || ""}
            onChange={e => setEditingContext(x => x ? ({ ...x, _class: e.target.value }) : x)}
            />
        </FormGrid>
    </SimpleDialog>
  </>);

  return {
      key: "text-class",
      dialogs: dialog,
      commands: [{
          name: TextClassCommand,
          invoke: editor => startEdit(editor),
          menu: { section: "formatting", label: "Class", label_id: "powerdoc.format.text_class", icon: <LabelOutlined /> },
          isActive: isClassPresent,
      }],
  };
}
