import { PartyPoll } from "./polls.types";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";

export const useCasePartiesPolls = (caseId: string) => {
  const data = useLoadedData<PartyPoll[]>(`/api/party-poll/case/${caseId}/poll`, [], !!caseId);

  return {
    ...data,
  }
}
