import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import AppRouter from './AppRouter';
import { theme } from './theme';

import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';
import { Messages } from './components/messages';
import { LoginForm, SignupForm, ResetPasswordForm } from './components/Auth';
import { CommentsConfigurationProvider } from './toolympus/components/Comments';
import { Message } from '@mui/icons-material';
import { ApiConfgurationProvider } from './toolympus/components/ApiConfguration';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';
import { PluggableDocumentationProvider } from './toolympus/components/Documentation';
import { DefaultUser } from './toolympus/userContext/UserContext';
import { PersonalizationServiceContextProvider } from './components/Personalization';

export const isAdmin = (user: DefaultUser) => (user?.roles || []).includes("admin");

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
        <StylesProvider injectFirst >
            <LocalizationProvider locale="ru" defaultLocale="ru" messages={Messages}>
              <ApiConfgurationProvider emailTemplatesApiPath="/api/emails">
                <FormatsProvider formats={{ date: "DD.MM.YYYY", thousandSeparator: '\xA0', boolean: { true: "да", false: "нет" } }}>
                  <CommentsConfigurationProvider commentPresentIcon={<Message color="secondary" />}>
                    <PluggableDocumentationProvider apiPath="/api/documentation" canManage={isAdmin}>
                      <ConfirmationDialogProvider>
                        <PersonalizationServiceContextProvider>
                          <Router>
                            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                                <ErrorBoundary showErrorDetails>
                                  <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                                        <Switch>
                                            <Route path='/signup' component={SignupForm} />
                                            <Route path='/login' component={LoginForm} />
                                            <Route path="/password-restore" component={ResetPasswordForm} />
                                            <Route path='/' component={AppRouter} />
                                        </Switch>
                                  </DatesLocalizationProvider>
                                </ErrorBoundary>
                            </SnackbarProvider>
                          </Router>
                        </PersonalizationServiceContextProvider>
                      </ConfirmationDialogProvider>
                    </PluggableDocumentationProvider>
                  </CommentsConfigurationProvider>
                </FormatsProvider>
              </ApiConfgurationProvider>
            </LocalizationProvider>
        </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
