import { ViewAgenda } from '@mui/icons-material';
import React, { useState } from 'react';
import { Editor } from "slate";
import { useDialogState } from "../../../primitives"
import { useTextFilter } from "../../../schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../../../hooks/useLoadedData";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { Content } from '../../types';
import { InsertBlockDialog } from './InsertBlockDialog';

export interface TemplateBlock {
    _id: string;
    title: string;
    content: Content;
}


interface Config {
    blocksApiPath?: string;
}

export const useTemplateBlocks = (cfg: Config) => {
    const dialog = useDialogState();
    const blocks = useLoadedData<TemplateBlock[]>(cfg.blocksApiPath || "", [], dialog.isOpen && !!cfg.blocksApiPath);
    const filter = useTextFilter<TemplateBlock>(b => b.title);

    const insertBlock = (editor: Editor, block: TemplateBlock) => {
        if(block.content.blocks) {
            editor.insertFragment(block.content.blocks);
        }
        dialog.close();
    }


    return {
        ...dialog,
        close: () => {
            dialog.close();
            filter.setFilter("");
        },
        filter,
        blocks: {
            ...blocks,
            data: filter.filterData(blocks.data),
        },
        insertBlock,
    }
}

export type BlocksData = ReturnType<typeof useTemplateBlocks>;

export const useTemplateBlocksPlugin = (cfg: Config): BlocksData & { plugin: EditorPlugin | undefined } => {
    const data = useTemplateBlocks(cfg);
    const [editor, setEditor] = useState<Editor | null>(null);

    return {
        ...data,
        plugin: cfg.blocksApiPath ? {
            key: "template-blocks",
            dialogs: editor ? <InsertBlockDialog editor={editor} data={data} /> : undefined,
            commands: [{
                name: "insert-block",
                hotkey: "alt+b",
                invoke: editor => { setEditor(editor); data.open(); },
                menu: { label: "Block", label_id: "powerdoc.plugins.template_block.title", section: "insert-item", icon: <ViewAgenda /> },
            }],
        }
        : undefined,
    }
}
