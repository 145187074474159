import { TextField } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { DisplayProps, FormControlProps } from '../../toolympus/components/schemed/FormControlProps';

interface Props {
  value: number | null | undefined;
  update: (v: number | null) => void;
  label?: ReactNode;
  hint?: ReactNode;
}

export const minutesToStr = (minutes: number | null | undefined) => {
  if(!minutes) {
    return "";
  } else {
    return [
      [Math.floor(minutes/60),"ч"],
      [minutes % 60,"м"],
    ].filter(([x]) => !!x)
    .map(([d,w]) => `${d}${w}`)
    .join(" ");
  }
}

const Regexes = {
  hAlternatives: /[HxXчЧрР]/,
  mAlternatives: /[MvVмМьЬ]/,
  colonAlternatives: /[Ж^]/,
  despace: /(\d+)\s*(\w+)/,
  extract: /(\d+)(\w+)?/,
  digits: /\d+/,
}

export const strToMinutes = (v: string | null | undefined) => {
  if(!v) {
    return 0;
  } else {
    const vClean1 = v.replace(Regexes.colonAlternatives, ":");
    if(vClean1.includes(":")) {
      const [h,m] = vClean1.split(":").map(s => s.trim());
      if(h.match(Regexes.digits) && m.match(Regexes.digits)) {
        return +m + 60*(+h);
      }
      return 0;
    }
    const parts = vClean1.replace(Regexes.hAlternatives, "h").replace(Regexes.mAlternatives, "m").replace(Regexes.despace, (_,p1,p2) => `${p1}${p2}`).split(" ").map(s => s.trim());
    return parts.reduce<number>((r,part) => {
      const [,d,w] = Regexes.extract.exec(part) || [];
      
      if(!!d && (w === "m" || !w)) {
        return r+(+d);
      } else if(!!d && w === "h") {
        return r + (+d * 60);
      } else {
        return r;
      }
    }, 0);
  }
}

export const TimeEditor = (props: Props) => {
  const [internal,setInternal] = useState<string>(minutesToStr(props.value));
  useEffect(() => {
    setInternal(minutesToStr(props.value));
  }, [props.value]);

  return (
    <TextField
      size="small"
      fullWidth
      onChange={e => setInternal(e.target.value)}
      value={internal}
      label={props.label}
      helperText={props.hint}
      onBlur={() => {
        const m = strToMinutes(internal);
        props.update(strToMinutes(internal));
        setInternal(minutesToStr(m));
      }}
      />
  );
}


export const TimeEditorControl = (props: FormControlProps) => {
  return (
    <TimeEditor
      value={(props.row || {})[props.field]}
      update={v => props.onChange(props.row, { [props.field]: v })}
      hint={props.schema?.hint}
      label={props.schema?.label}
      />
  )
}


interface TTDisplayProps {
  value: number | null | undefined;
}

export const TimeTrackDisplay = (props: TTDisplayProps) => {

  return (
    <span>
      {minutesToStr(props.value)}
    </span>
  );
}

export const TimeTrackDisplayControl = (props: DisplayProps) => {

  return (
    <TimeTrackDisplay value={(props.row || {})[props.field]} />
  );
}
