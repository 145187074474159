import React, { useCallback, useState } from "react";
import styled from '@emotion/styled';
import { Dialog, DialogState, useDialogState } from "../../primitives";
import { RenderElementProps, useSlate, useSlateStatic } from "slate-react";
import { InlineChromiumBugfix } from "../elements/InlineChromiumBugfix";
import { Button, DialogActions, IconButton, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Editor, Transforms, Element, Range } from "slate";
import { EditorPlugin } from "../slate/PowerEditorConfig";
import { CallMade, Link } from "@mui/icons-material";
import { usePowerEditorContext } from "../slate/PowerEditorContext";

const InsertLinkCommand = "insert-link";

const InlineLinkBase = styled.a`
    position: relative;
    color: ${props => props.theme.palette.primary.main};
    border-bottom: 1px dotted ${props => props.theme.palette.primary.main};
    padding-bottom: 2px;
    cursor: pointer;
    text-decoration: none;
`;

const InlineLink = InlineLinkBase;

const InlineLinkLike = InlineLinkBase.withComponent("span");

export const LinkElementType = "link";

const getActiveLink = (editor: Editor) => {
    const [link] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === LinkElementType,
    });
    
    if(link) {
        return link[0] as Element;
    } else {
        return undefined;
    }
}

export const isLinkActive = (editor: Editor) => {
    return !!getActiveLink(editor);
}

const unwrapLink = (editor: Editor) => {
    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === LinkElementType,
    })
  }

const wrapLink = (editor: Editor, url: string) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }
  
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: url }] : [],
    }
  
    if (isCollapsed) {
      Transforms.insertNodes(editor, link)
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  }

export const insertLink = (editor: Editor, url: string) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}

const LinkDialog = (props: { url?: string, setUrl: (v: string) => void } & Pick<DialogState, "close" | "isOpen">) => {
    const { url, setUrl, isOpen, close } = props;
    const editor = useSlate();

    const updateUrl = (v: string) => {
        Transforms.setNodes(
            editor,
            { url: v },
            { match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === LinkElementType }
        )
    }

    const removeLink = () => {
        unwrapLink(editor);
        close();
    }

    const finishEditing = () => {
        if(isLinkActive(editor)) {
            updateUrl(url || "");
        } else {
            insertLink(editor, url || "");
        }
        close();
    }

    const cancelEdits = () => {
        close();

        // restore selection in the editor
        const sel = editor.selection;
        if(sel) {
            setTimeout(() => {
                Transforms.setSelection(
                    editor,
                    { ...sel },
                );
            }, 150);
        }
    }

    return (
        <Dialog
            isOpen={isOpen}
            close={cancelEdits}
            dialogTitle=""
            noFullscreen>
            <TextField
                value={url || ""}
                label={<FormattedMessage id="powerdoc.plugins.links.url" />}
                onChange={e => setUrl(e.target.value)}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: <IconButton target="_blank" href={url || ""} rel="noreferrer noopener nofollow" size="small"><CallMade /></IconButton>
                }}
                onKeyDown={e => {
                    if(e.key === "Enter") {
                        e.preventDefault();
                        finishEditing();
                    }
                }}
                />
            <DialogActions>
                <Button color="secondary" onClick={() => removeLink()}><FormattedMessage id="powerdoc.plugins.links.remove_link" /></Button>
                <Button color="primary" onClick={() => finishEditing()}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>
    )
}

export const LinkElement = ({ element, attributes, children }: RenderElementProps) => {
    const { viewMode, invokeCommand } = usePowerEditorContext();
    const editor = useSlateStatic();
    
    return viewMode
        ? (<InlineLink {...attributes} href={element.url} target="_blank" rel="noreferrer noopener nofollow">
            {children}
        </InlineLink>)
        : (<InlineLinkLike {...attributes} onClick={() => invokeCommand(InsertLinkCommand, editor, element)}>
            <InlineChromiumBugfix />{children}<InlineChromiumBugfix />
        </InlineLinkLike>)
}

export const withLinks = (editor: Editor) => {
    const { isInline } = editor;
    editor.isInline = element => element.type === LinkElementType ? true : isInline(element);
    return editor;
}

export const useLinksPlugin = (): EditorPlugin => {
    const dialogState = useDialogState();
    const [editedUrl, setEditedUrl] = useState<string>("");

    const startEdit = useCallback(
        (e?: Element) => {
            setEditedUrl(e?.url || "");
            dialogState.open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const dialog = (
        <LinkDialog
            isOpen={dialogState.isOpen}
            close={() => dialogState.close()}
            url={editedUrl || ""}
            setUrl={setEditedUrl}
            />
    );


    return {
        key: "links",
        inject: e => withLinks(e),
        customBlocks: { [LinkElementType]: LinkElement },
        dialogs: dialog,
        commands: [{
            name: InsertLinkCommand,
            hotkey: "mod+k",
            invoke: editor => startEdit(getActiveLink(editor)),
            menu: { section: "formatting", label: "Link", label_id: "powerdoc.plugins.links.title", icon: <Link /> },
        }],
    };
}
