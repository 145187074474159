import React from 'react';
import { TableForFields } from '../../toolympus/components/schemed';
import { useCasePartiesPolls } from './useCasePartiesPolls';
import { PseudoLink2, useCopyText } from '../../toolympus/components/primitives';

interface Props {
  caseId: string;
}

export const CasePartiesPollsList = (props: Props) => {
  const data = useCasePartiesPolls(props.caseId);
  const copyText = useCopyText();
  return (
    <TableForFields
      data={data.data}
      fields={[
        ["email", { label: "E-mail" }],
        ["created_datetime", { label: "Создан" }],
        ["filled_datetime", { label: "Заполнен" }],
      ]}
      schema={{}}
      fieldElement={f => {
        switch(f) {
          case "email":
            return (r,s,orig) => <PseudoLink2 onClick={() => copyText(r.email || "")}>{orig}</PseudoLink2>
          }
        }}
      fieldLink={f => {
        switch(f) {
          case "created_datetime":
          case "filled_datetime":
            return r => `/parties-polls?tab=polls&search=${r.request_merge_group}`
        }
      }}
      fieldLinkNewTab
      />
  );
}
