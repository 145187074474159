import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useNewItem } from "../../../api/useNewItem";
import { useCrudUnpagedList } from "../../../api/useSimpleCrud";
import { useFieldSorting } from "../../../hooks/useFieldSorting";
import { CMSRecord, NewCMSRecord } from "../types";
import { RecordTypeConfigData, useRecordTypeConfig } from "../useRecordTypeConfig";
import { apiFetch } from "../../../api/core";
import { useItemActionWithConfirmation } from "../../../api/useAction";
import { toMap } from "../../../api/data";
import { addTranslationLink } from "../Forms/useRecordTranslationLinks";
import { useBrowserStoredValue } from "../../../hooks/useBrowserStoredValue";

export const LangAll = "all";
export const LangGroupBySlug = "by-slug";
export const LangLinkedTranslations = "linked-translations";

const createRecordInDifferentLanguage = (apiPath: string, record: CMSRecord, lang: string) => {
  return apiFetch<CMSRecord>(`${apiPath}/manage/${record.record_type}/${record._id}`, "get")
    .then(sourceRecord => {
      const copy: Partial<NewCMSRecord> = {
        slug: sourceRecord.slug,
        title: `${sourceRecord.title} (${lang})`,
        attributes: sourceRecord.attributes,
        content: sourceRecord.content,
        record_datetime: sourceRecord.record_datetime,
        published_datetime: sourceRecord.published_datetime,
        lang,
      }

      return apiFetch<CMSRecord>(`${apiPath}/manage/${record.record_type}`, "post", copy);
    })
}



const useRecordsTranslationsGrouping = (apiPath: string, data: CMSRecord[], lang: string | null, config: RecordTypeConfigData) => {
  const groupedBySlug = useMemo(() => {
    if(lang === LangGroupBySlug) {
      return data.reduce<(CMSRecord & { byLang: Record<string, CMSRecord> })[]>((r,record) => {
        const withThisSlug = r.find(x => x.slug === record.slug);
        if(withThisSlug) {
          withThisSlug.byLang[record.lang || ""] = record;
        } else {
          const copy = { ...record, byLang: { [record.lang || ""]: record }};
          r.push(copy);
        }
        return r;
      }, []);
    } else {
      return [];
    }
  }, [lang, data]);

  const groupedByTranslationLinks = useMemo(() => {
    if(lang === LangLinkedTranslations) {
      let listCopy = [...data];
      const result: Record<string, CMSRecord>[] = [];
      data.forEach(record => {
        const source = listCopy.find(r => r._id === record._id);
        if(!source) {
          return;
        }

        const translationsLinks = (source.links || {})[config.translationLink?.link_type || ""] || [];
        const translations = translationsLinks
          .map(l => listCopy.find(r => r._id === l._id))
          .filter(t => !!t && t._id !== record._id);
        const group = [record, ...translations] as CMSRecord[];
        const groupIds = group.map(r => r._id);
        const groupRecord = toMap(group, r => r?.lang || "");
        (groupRecord as any)["_id"] = record._id;
        
        listCopy = listCopy.filter(r => !groupIds.includes(r._id));
        result.push(groupRecord);
      })
      return result;
    } else {
      return [];
    }
  }, [lang, data, config.translationLink]);


  const createInOtherLanguage = useItemActionWithConfirmation<{ record: CMSRecord, lang: string}, CMSRecord>(
    x => createRecordInDifferentLanguage(apiPath, x.record, x.lang)
      .then(newRecord => {
        if(lang === LangLinkedTranslations) {
          return addTranslationLink(apiPath, config.translationLink, x.record, newRecord)
            .then(() => newRecord);
        } else {
          return newRecord;
        }
      }), {
      skipConfirmation: true,
    }
  );

  return {
    groupedBySlug,
    groupedByTranslationLinks,
    translationLink: config.translationLink,
    createInOtherLanguage,
  }
}



export const useRecordsList = (apiPath: string, recordType: string) => {
    const config = useRecordTypeConfig(apiPath, recordType);
    const { value: lang, update: setLang } = useBrowserStoredValue(LangAll, "lang", `_${recordType}_lang`);

    const sorting = useFieldSorting({
      defaultSort: { field: "created_datetime", direction: "desc" },
      allowedFields: [
        "_id",
        "title",
        "slug",
        "lang",
        "record_datetime",
        "published_datetime",
        "created_datetime",
      ]
    });

    const viewParams = useMemo(() => {
        const params = {} as Record<string,string>;
        if(lang && lang !== LangAll && lang !== LangGroupBySlug && lang !== LangLinkedTranslations) {
            params["lang"] = lang;
        }
        if(lang === LangLinkedTranslations) {
          params["include-links"] = "true";
        }
        if(sorting.sort?.field) {
            params["order-by"] = `${sorting.sort?.field || ""}:${sorting.sort?.direction}`;
        }
        return params;
    }, [lang, sorting.sort]);

    const data = useCrudUnpagedList<CMSRecord>(`${apiPath}/manage/${recordType}`, {
        defaultView: LangAll,
        filterBy: r => `${r.title} ${r.slug} /${(r.slug || "").replaceAll(".", "/")}`,
        extraParams: viewParams,
    });

    const history = useHistory();
    useEffect(() => {
        const query = new URLSearchParams(history.location.search);
        data.setFilter(query.get("filter") || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setFilter = (filter: string) => {
        data.setFilter(filter);
        const urlp = new URLSearchParams(history.location.search);
        urlp.set("filter", filter);
        history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    }

    const hasLanguages = config.data.languages && config.data.languages.length > 1;

    const newItem = useNewItem<NewCMSRecord, CMSRecord>(
        `${apiPath}/manage/${recordType}`,
        { title: "", slug: "", lang: hasLanguages ? lang : undefined });

    const translationsGrouping = useRecordsTranslationsGrouping(apiPath, data.data, lang, config);

    return {
        recordType,
        config: config.data,
        schema: config.schema,
        hasLanguages,
        lang,
        setLang,
        sorting,

        ...data,
        setFilter,
        newItem,
        isLoading: config.isLoading || data.isLoading || translationsGrouping.createInOtherLanguage.isRunning,
        isConfigLoading: config.isLoading,
        
        translationsGrouping,
    }
}


export type RecordsListData = ReturnType<typeof useRecordsList>;
