import { useCommonSignup } from "./useCommonSignup";
import { useInviteSignup } from "./useInviteSignup";

export interface SignupCredentials {
    login: string;
    password: string;
    passwordRepeat: string;
    inviteCode?: string;
}

export interface PremadeCredentials {
    email: string;
    code: string;
}

export interface UseSignupProps {
    signupApiPath: string;
    mode: SignupMode;
    redirectPath?: string;
    creds?: PremadeCredentials;
    loginOnSuccess?: boolean;
}

export enum SignupMode {
    Common,
    Invite
}

export interface Signup {
    credentials: SignupCredentials;
    update: (changes: SignupCredentials) => void;
    send: () => Promise<void>;
    isSaving: boolean;
    isError: boolean;
    isSubmitted: boolean;
}

export interface SignupCommonProps {
    signupApiPath: string;
    redirectPath?: string;
    loginOnSuccess?: boolean;
}

export const useSignup = ({ signupApiPath, redirectPath, creds, loginOnSuccess, mode }: UseSignupProps): Signup => {
    const common = useCommonSignup({ signupApiPath: signupApiPath, redirectPath: redirectPath, loginOnSuccess });
    const invite = useInviteSignup({ signupApiPath: signupApiPath, redirectPath: redirectPath, creds: creds, loginOnSuccess });

    switch (mode) {
        case SignupMode.Common: {
            return common;
        }
        case SignupMode.Invite: {
            return invite;
        }
    }
}