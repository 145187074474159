import React, { CSSProperties, useEffect, useState } from "react";
import styled from '@emotion/styled';
import { FieldSchema } from "../../../toolympus/hooks/useSchema";
import { CaseSideSuggstion, useCaseSideSuggestions } from "./useCaseSideSuggestions";
import { IconButton, InputAdornment, ListItem, ListItemText, MenuList, Paper, TextField } from "@mui/material";
import { CaseSide } from "../../../typings/Cases";
import isHotkey from "is-hotkey";
import { MoreHoriz } from "@mui/icons-material";
import { LoadingIndicator } from "../../../toolympus/components/primitives";
import { useDictionaryMap } from "../../../toolympus/hooks/useDictionaries";


const SuggestionsWrapper = styled(Paper)`
  background: #ffffff;

  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  width: 100%;
  z-index: 2000;
`;



interface InnEditorProps {
  value: string | undefined;
  update: (v: string | undefined) => void;
  schema: FieldSchema;
  selectSuggestion: (changes: Partial<CaseSide>) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
}


export const InnEditor = (props: InnEditorProps) => {
  const suggestions = useCaseSideSuggestions();

  const [changeTracker, setChangeTracker] = useState<number>(0);

  const getCountry = useDictionaryMap("Country");

  
  useEffect(() => {
    if(props.value && props.value.length > 2 && changeTracker) {
      const timeout = setTimeout(() => suggestions.requestSuggestions(props.value || ""), 800);
      return () => clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, changeTracker]);

  const selectSuggestion = (s: CaseSideSuggstion) => {
    props.selectSuggestion(suggestions.createSuggestionChanges(s))
    suggestions.clearSuggestions();
    setChangeTracker(0);
  }

  return (
    <TextField
      size="small"
      label={props.schema.label}
      value={props.value || ""}
      autoFocus={props.autoFocus}
      style={props.style}
      onBlur={() => setTimeout(() => suggestions.clearSuggestions(), 500)}
      onChange={e => {
        setChangeTracker(x => x+1);
        props.update(e.target.value);
      }}
      onKeyUp={e => {
        if(isHotkey("enter")(e) && suggestions.suggestions.length) {
          e.preventDefault();
          e.stopPropagation();
          selectSuggestion(suggestions.suggestions[0]);
        }
      }}
      InputProps={{
        endAdornment: <>
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => props.value && suggestions.requestSuggestions(props.value)}>
              {suggestions.isLoading ? <LoadingIndicator sizeVariant="s" /> : <MoreHoriz />}
            </IconButton>
          </InputAdornment>
          {!!suggestions.suggestions.length &&
            <SuggestionsWrapper>
              <MenuList dense>
                {suggestions.suggestions.map((s,i) => (
                  <ListItem
                    key={`${s.title}_${s.inn}_${i}`}
                    button
                    dense
                    onClick={() => selectSuggestion(s)}>
                    <ListItemText
                      primary={s.title}
                      secondary={[s.inn, s.kpp, s.geo_country ? getCountry(s.geo_country)?.label || s.geo_country : null, s.geo_city].filter(x => !!x).join(", ")}
                      />
                  </ListItem>
                ))}
              </MenuList>
            </SuggestionsWrapper>}
        </>
      }}
      />
  )
}