import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import { Add, EditOutlined } from '@mui/icons-material';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../schemed';
import { DeleteButton } from '../primitives/DeleteButton';
import { FormattedMessage } from 'react-intl';
import { SimpleDocumentListData } from './useSimpleDocumentList';
import { PseudoLink } from '../primitives/Common.styles';
import { createFileDropHandler } from '../../api/files';
import { useDragTarget } from '../../hooks/useDragTarget';
import { FormGrid } from '../primitives';

interface Props {
    data: SimpleDocumentListData;
    disabled?: boolean;
}

const TableWrapper = styled.div<{ isDragTarget?: boolean }>`
    width: 100%;
    box-shadow: ${props => props.isDragTarget ? `0 0 5px 0 ${props.theme.palette.primary.main}` : "none"};

    & * {
        /* pointer-events: ${props => props.isDragTarget ? "none" : "auto"}; */
        /* pointer-events: auto; */
    }
`;

export const SimpleDocumentList = ({ data, disabled }: Props) => {
    const {
        documents,
        isLoading,
        download,
        create,
        upload,
        schema,
        editedRecord,
        startEditing,
        cancelEditing,
        replace,
        remove,
        saveEditing,
        update,
    } = data;

    const dragTarget = useDragTarget({ disabled, onDrop: createFileDropHandler(f => upload(f)) });

    const editedRecordControls = (fields: FieldDefinition[]) => (
        editedRecord &&
            <FormControlsForFields
                schema={schema}
                fields={fields}
                data={editedRecord}
                onChange={(o,c) => update(c)}
                />
    )

    return (
        <>
            <ActionRow>
                {isLoading && <LoadingIndicator />}
                <OccupyFreeSpace />
                {!disabled && <IconButton size="small" color="primary" onClick={() => create()}><Add /></IconButton>}
            </ActionRow>
            <TableWrapper {...dragTarget}>
                <TableForFields
                    data={documents}
                    schema={schema}
                    fields={[
                        ["filename"],
                        ["comment"],
                    ]}
                    fieldElement={f => {
                        if(f === "filename") {
                            return (row, _, display) => <PseudoLink onClick={() => download(row)}>{display}</PseudoLink>;
                        } else {
                            return null;
                        }
                    }}
                    rowButtons={d => <IconButton size="small" onClick={() => startEditing(d)}><EditOutlined /></IconButton>}
                    emptyStateClick={() => !disabled && create()}
                    />
            </TableWrapper>
            <Dialog open={!!editedRecord} onClose={cancelEditing} fullWidth maxWidth="sm">
                <DialogContent>
                    <FormGrid noMargin columns="1fr">
                        {editedRecordControls([
                            ["filename", { disabled: true }],
                            ["created_datetime", { disabled: true, utcToLocal: true }],
                            ["comment", { disabled }],
                        ])}
                    </FormGrid>
                    
                </DialogContent>
                
                <DialogActions>
                    {!disabled && <DeleteButton
                        title={<FormattedMessage id="files.removeConfirmation" />}
                        preventGoBack
                        remove={() => editedRecord ? remove(editedRecord) : Promise.resolve({})}
                        button={<Button color="secondary"><FormattedMessage id="common.delete" /></Button>}
                        />}

                    {!disabled && <Button
                      color="primary"
                      style={{ gridArea: 'replace'}}
                      onClick={() => editedRecord ? replace(editedRecord) : Promise.resolve({})}>
                      <FormattedMessage id="common.replace" />
                    </Button>}
                    
                    <Button variant="contained" color="primary" onClick={saveEditing}><FormattedMessage id="common.save" /></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
