import { useState } from 'react';
import { apiFetch, FetchTypes } from '../api/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface SendData<DataType, ResultType> {
    data: DataType;
    isSaving: boolean;
    isSaved: boolean;
    update: (changes: DataType) => void;
    send: () => Promise<void>;
}

interface Options<ResultType> {
    apiMethod?: FetchTypes;
    apiExtraParams?: any;
    saveAPIPathResolver?: (apiPath: string) => string;
    onSuccess?: (result: ResultType) => void;
}

export const useSendData = <DataType, ResultType>(apiPath: string, defaultValues: DataType, options?: Options<ResultType>): SendData<DataType, ResultType> => {
    const apiMethod = options?.apiMethod || FetchTypes.POST;
    const onSent = options?.onSuccess || (() => { });

    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [record, update] = useState<DataType>(defaultValues);

    const save = () => {
        setIsSaving(true);
        setIsSaved(false);
        const resultingPath = options?.saveAPIPathResolver ? options.saveAPIPathResolver(apiPath) : apiPath;
        return apiFetch<ResultType>(resultingPath, apiMethod, record, options?.apiExtraParams)
            .then(record => {
                setIsSaving(false);
                setIsSaved(true);
                onSent(record);
            })
            .catch(e => {
                setIsSaving(false);
                throw e;
            });
    }

    return {
        data: record,
        update,
        send: save,
        isSaving,
        isSaved,
    }
}
