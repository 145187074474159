import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { EditItemProps, NewItemProps } from '../../../toolympus/api/useNewItem';
import { NewMember, PresidiumMember } from './usePresidiumMembers';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';

interface Props {
    schema: Schema;
    data: NewItemProps<NewMember, PresidiumMember> | EditItemProps<PresidiumMember>;
}

export const PresidiumMemberPopup = ({ data, schema }: Props) => {
    const { isEditing, isLoading, item, cancel, update, save: add, errors } = data;
    return (
        <Dialog open={isEditing} onClose={cancel} fullWidth maxWidth="md">
            <DialogContent>
                <FormGrid>
                    {item && 
                        <FormControlsForFields
                            schema={schema}
                            data={item}
                            fields={[
                                ["lastname"],
                                ["firstname"],
                                ["middlename"],
                                ["lastname_en"],
                                ["firstname_en"],
                                ["middlename_en"],
                                ["role_full"],
                                ["role_internal"],
                                ["role_international"],
                                ["user_id"],
                                ["language"],
                                ["notifications_mode"],
                            ]}
                            onChange={(o,c) => update(c)}
                            errors={errors}
                            />}
                </FormGrid>
            </DialogContent>
            <DialogActions>
                {!isLoading && <Button onClick={cancel}><FormattedMessage id="common.cancel"/></Button>}
                {!isLoading && <Button color="primary" variant="contained" onClick={() => add()}><FormattedMessage id="common.save"/></Button>}
                {isLoading && <LoadingIndicator />}
            </DialogActions>
        </Dialog>
    );
}
