import React from 'react';
import { PowerEditorBase, PowerEditorProps } from '../../toolympus/components/PowerDoc';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { CustomFieldsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { SettingsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/SettingsBlock';

interface Props extends PowerEditorProps {
  
}

export const CMSEditor = (props: Props) => {
  const medialibPlugin = useMediaFilesPlugin();
  return (
    <PowerEditorBase
      plugins={[
        medialibPlugin,
        CustomFieldsBlockPlugin,
        SettingsBlockPlugin,
      ]}
      {...props}
      />
  );
}
