import React from 'react';
import { PowerEditorBaseProps } from '../../toolympus/components/PowerDoc';
import { ConditionalBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/ConditionalBlock';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { placeholdersEntityConfigFromSchema, usePlaceholdersPlugin } from '../../toolympus/components/PowerDoc/plugins/Placeholders';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { useTemplateBlocksPlugin } from '../../toolympus/components/PowerDoc/plugins/TemplateBlocks';
import { BlockSettingsByType, PowerEditorSettingsProvider, SuggestedBlockSettingsByType } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { DefaultMenuConfig, alterMenu, commandsTransformer, ElementsSection } from '../../toolympus/components/PowerDoc/slate/Menu';
import { PowerEditorBase } from '../../toolympus/components/PowerDoc/slate/PowerEditorBase';
import { useSchema } from '../../toolympus/hooks/useSchema';
import { PowerDocApiPath } from './PowerDocRoutes';
import { CustomFieldsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { SettingsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/SettingsBlock';
import { ColumnsPlugin } from '../../toolympus/components/PowerDoc/plugins/Columns';

export const RacaEditorMenuConfig = alterMenu(
    DefaultMenuConfig,
    commandsTransformer(
        ElementsSection.section,
        commands => commands.filter(c => c !== "heading5" && c !== "heading6"),
        ),
)

export const RacaPowerEditorUnwrapped = (props: PowerEditorBaseProps) => {
    const blocks = useTemplateBlocksPlugin({ blocksApiPath: `${PowerDocApiPath}?view=block-templates` });
    
    const schemas = useSchema();
    const placeholders = usePlaceholdersPlugin([
        placeholdersEntityConfigFromSchema("case", "Дело", schemas.case),
        placeholdersEntityConfigFromSchema("sides.claimants.0", "Истец", schemas.case_side),
        placeholdersEntityConfigFromSchema("sides.respondants.0", "Ответчик", schemas.case_side),
        placeholdersEntityConfigFromSchema("sides.others.0", "Третьи лица", schemas.case_side),
        placeholdersEntityConfigFromSchema("assistant", "Ассистент", schemas.employee),
        placeholdersEntityConfigFromSchema("other_employees.0", "Другие сотрудники", schemas.employee),
        { expression: "date", label: "Дата", fields: [{ expression: "today", label: "Сегодня" }]},
    ]);
    
    const mediaFilesPlugin = useMediaFilesPlugin();
    
    return (
        <PowerEditorBase
            plugins={[
                placeholders.plugin,
                blocks.plugin,
                ConditionalBlockPlugin,
                mediaFilesPlugin,
                TablesPlugin,
                CustomFieldsBlockPlugin,
                SettingsBlockPlugin,
                ColumnsPlugin,
                ]}
            menuConfig={RacaEditorMenuConfig}
            {...props}
            />
    );
}

const RacaSuggestedBlockSettingsByType: BlockSettingsByType = {
    ...SuggestedBlockSettingsByType,
    table: ["no_border", "even_columns"],
}

const SuggestedSettingsValues = {
    any: {
        no_border: ["true"],
        even_columns: ["true"],
    },
}

export const RacaPowerEditor = (props: PowerEditorBaseProps) => {
    return <PowerEditorSettingsProvider
        EditorComponent={RacaPowerEditorUnwrapped}
        suggestedBlockSettingsByType={RacaSuggestedBlockSettingsByType}
        suggestedBlockSettingValues={SuggestedSettingsValues}>
        <RacaPowerEditorUnwrapped
            {...props}
            />
    </PowerEditorSettingsProvider>
}
