import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, TextField } from '@mui/material';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { FormGrid, SectionTitle } from '../../../toolympus/components/primitives/Forms';
import { FieldDefinition, FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema, useSchema } from '../../../toolympus/hooks/useSchema';
import { CaseSide, DescribedEmail } from '../../../typings/Cases';
import { CaseSides } from '../caseData';
import { InnEditor } from './InnEditor';
import { Buttons } from '../../../toolympus/components/primitives';
import isHotkey from 'is-hotkey';


interface SidesProps extends CaseSides {
    kind: CaseSide["kind"];
    titleOne: string;
    titleMany: string;
    sides: CaseSide[];
    schema: Schema;
}


const PartyCard = styled(FormGrid)`
  padding: 12px;
  position: relative;
  align-items: end;

  border-radius: ${props => props.theme.shape.borderRadius}px;
  box-shadow: ${props => props.theme.shadows[2]};
`;

interface CaseSideProps {
  side: CaseSide;
  add: (kind: CaseSide["kind"]) => void;
  update: (side: CaseSide, changes: Partial<CaseSide>) => void;
  remove: (side: CaseSide) => void;
  schema: Schema;

  addEmail: (side: CaseSide) => void;
  updateEmail: (side: CaseSide, idx: number, changes: Partial<DescribedEmail>) => void;
  removeEmail: (side: CaseSide, idx: number) => void;
}


const CaseSideForm = (props: CaseSideProps) => {
  const { side, add, update, remove, schema } = props;

  const [isEmailsVisible, setIsEmailsVisible] = useState<boolean>(false);

  return (
    <PartyCard
      columns="repeat(6,1fr)"
      gap="dense"
      forceEvenColumns
      areas={[
          "innx kpp ogrn buttons buttons buttons",
          "title title title title_full title_full title_full",
          "geo_country geo_region geo_city address address address",
          "emails emails emails emails emails emails",
      ]}
      onKeyPress={e => {
        if(isHotkey("mod+enter", e)) {
          add(side.kind);
        }
      }}>

      <InnEditor
        value={side.inn}
        update={v => update(side, { inn: v })}
        selectSuggestion={s => update(side, s)}
        schema={schema.inn}
        style={{ gridArea: "innx" }}
        autoFocus={!side.inn}
        />

      <FormControlsForFields
          data={side}
          schema={schema}
          onChange={(o,c) => update(o, c)}
          fields={[
              "kpp",
              "ogrn",
              "title",
              "title_full",
              ["geo_country", { withArea: true, autoComplete: true }],
              ["geo_region", { withArea: true, autoComplete: true }],
              "geo_city",
              "address",
          ].map(f => Array.isArray(f) ? f as FieldDefinition : [f, { withArea: true }])}
          />

      {isEmailsVisible &&
        <FormGrid columns="1fr 1fr" style={{ alignItems: "end", gridArea: "emails" }}>
          {(side.emails || []).map((em,idx) => <React.Fragment>
              <TextField
                  value={em.email || ""}
                  onChange={e => props.updateEmail(side, idx, { email: e.target.value })}
                  size="small"
                  label="E-mail"
                  InputProps={{
                      endAdornment: <IconButton size="small" onClick={() => props.removeEmail(side, idx)}><DeleteOutlined /></IconButton>
                  }}
                  autoFocus={!em.email}
                  />
              <TextField
                  value={em.description || ""}
                  onChange={e => props.updateEmail(side, idx, { description: e.target.value })}
                  size="small"
                  label="Описание"
                  />
          </React.Fragment>)}

          <div />
          
          <Buttons style={{ justifyContent: "flex-end" }}>
            <Button
              size="small"
              onClick={() => props.addEmail(side)}>
              добавить E-mail
            </Button>
          </Buttons>
      </FormGrid>}

      <Buttons style={{ gridArea: "buttons", alignItems: "end", alignSelf: "end", justifyContent: "flex-end" }}>
        <Button
          size="small"
          color="error"
          onClick={() => remove(side)}
          startIcon={<DeleteOutlined />}>
          удалить
        </Button>


        <Button
          size="small"
          color={side.emails?.length ? "primary" : "inherit"}
          onClick={() => {
            setIsEmailsVisible(x => !x);
            if(!isEmailsVisible && !side.emails?.length) {
              props.addEmail(side);
            }
          }}>
          e-mail {side.emails?.length ? `(${side.emails.length})` : ""}
        </Button>
      </Buttons>
  </PartyCard>
  )
}

const CaseCertainSidesForm = (props: SidesProps) => {
    const {
        kind,
        titleOne,
        titleMany,
        sides,
        add,
        update,
        remove,
        schema,
    } = props;

    return (
        <div>
            <SectionTitle>{sides.length > 1 ? titleMany : titleOne} {sides.length > 1 ? `(${sides.length})` : ""} <IconButton size="small" onClick={() => add(kind)}><Add /></IconButton></SectionTitle>
            {sides.map(side => (
              <CaseSideForm
                key={side.line_nbr}
                side={side}
                add={add}
                update={update}
                remove={remove}
                schema={schema}
                addEmail={props.addEmail}
                updateEmail={props.updateEmail}
                removeEmail={props.removeEmail}
                />
            ))}
        </div>
    );
}

interface Props {
    data: CaseSides;
}

export const CaseSidesForm = ({ data }: Props) => {
    const { case_side: schema } = useSchema();

    return (
        <FormGrid columns="1fr" style={{ rowGap: "36px", paddingBottom: "36px" }}>
            <CaseCertainSidesForm
                {...data}
                kind="claimant"
                titleOne="Истец"
                titleMany="Истцы"
                sides={data.claimants}
                schema={schema}
                />

            <CaseCertainSidesForm
                {...data}
                kind="respondant"
                titleOne="Ответчик"
                titleMany="Ответчики"
                sides={data.respondants}
                schema={schema}
                />

            <CaseCertainSidesForm
                {...data}
                kind="other"
                titleOne="Третьи лица"
                titleMany="Третьи лица"
                sides={data.others}
                schema={schema}
                />

        </FormGrid>
    );
}
