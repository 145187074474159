import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { CalendarEvent, calendarMonthFilterFromDate, DateFilteringT } from './useCalendarEvents';
import moment, { Moment } from 'moment';
import { Buttons, Dialog, EmptyState, OccupyFreeSpace, PseudoLink2 } from '../../../toolympus/components/primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { Add, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Hatch from './dot-hatch.svg';
import { StrippedIconButton } from '../../../toolympus/components/primitives/StrippedButtons';
import { DateTimeInternalFormat } from '../../../toolympus/api/datetimeUtil';
import { TableForFields, useFormats } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { eventComparerByStart } from './useCalendarEventsConflicts';
import { ConflictsBadge, OwnershipBadge } from './Elements';

interface DayProps {
  isToday?: boolean;
  isSelectedMonth?: boolean;
  isWeekend?: boolean;
}

const DayLabel = styled.span<Pick<DayProps, "isToday">>`
  font-size: 1rem;
  display: block;
  text-align: right;
  opacity: 0.5;
  font-weight: 500;
  opacity: ${props => props.isToday ? 1 : 0.5};
  user-select: none;

  position: absolute;
  top: 3px;
  right: 8px;
`;

const SelectionMarker = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 2px solid ${props => props.theme.palette.primary.main};

`;

const MoreEvents = styled.span`
  border-radius: 1rem;
  font-size: 0.75rem;
  padding: 0 5px;
  background: #00000008;
  cursor: pointer;
`;

const EventItem = styled.div`
  display: flex;
  flex-flow: column;
  line-height: 1.1;
  cursor: pointer;
  position: relative;
  
  & .time {
    font-size: 0.6rem;
    opacity: 0.75;
  }

  & .title {
    font-size: 0.85rem;
    max-height: 2rem;
    overflow-y: hidden;
  }

  & .badge-anchor {
    color: transparent;
    opacity: 0;
    user-select: none;
  }
  
  & .MuiBadge-root + .MuiBadge-root {
    margin-left: 0.5rem;
  }

  & + & {
    margin-top: 0.4rem;
  }

`;

const DayBase = styled.div`
  padding: 3px 8px;
`;

const DayOfWeek = styled(DayBase)`
  font-size: 0.9rem;
  text-align: right;
  opacity: 0.5;
`;

const AddButton = styled(StrippedIconButton)`
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: none;

  & > * {
    display: block;
  }
`;

const cellBackground = (props: DayProps & { theme: any }) => {
  const hatchPart = !props.isSelectedMonth
    ? `url(${Hatch})`
    : "";
  const colorPart = props.isToday
    ? `transparent`
  : !props.isSelectedMonth
    ? "#00000008"
    : props.isWeekend
      ? `${props.theme.palette.primary.main}10`
      : "transparent";

  return [hatchPart, colorPart].filter(x => !!x).join(", ");

};

const Day = styled(DayBase)<DayProps>`
  background: ${props => cellBackground(props)};
  background-size: 100%;
  padding: 8px;

  border-left: 1.5px solid #00000020;
  border-top: 1.5px solid #00000020;

  &:nth-child(7n) {
    border-right: 1.5px solid #00000020;
  }

  position: relative;

  &:hover {
    ${AddButton} {
      display: block;
    }
  }

`;

const CalendarDaysOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const CalendarInner = styled.div<{ weeks: number }>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(${props => props.weeks || 4}, 1fr);
  border-bottom: 1.5px solid ${props => props.theme.palette.grey[200]};
  flex: 1 0 auto;
`;

const TitleLine = styled(Buttons)`
  margin: 0.5rem 0 0;

  & h6 {
    font-weight: 400;
  }

  & .MuiButton-root {
    font-weight: 400;
  }

  & .title {
    text-transform: capitalize;
  }
`;

const CalendarOuter = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  
  flex: 1 0 auto;
`;


const DateFormat = "yyyy-MM-DD";

const useCalendarMonthDates = (dateInMonth: Moment) => {
  const x = useMemo(() => {
    const monthStart = dateInMonth.clone().set("D", 1);
    let startDate = monthStart.clone();

    if(startDate.day() === 0) {
      startDate = startDate.add(-6, "d");
    } else if(startDate.day() !== 1) {
      startDate = startDate.add(1-startDate.day(), "d");
    }
    let endDate = monthStart.clone().add(1, "M").add(-1, "d");
    if(endDate.day() !== 0) {
      endDate = endDate.add(7-endDate.day(), "d");
    }

    const days = [startDate];
    let d = startDate.clone().add(1, "d");
    while(d.isBefore(endDate)) {
      days.push(d);
      d = d.clone().add(1, "d");
    }
    days.push(endDate);

    const today = moment().format(DateFormat);
    const selectedMonth = monthStart.format("yyyy-MM");

    const richDays = days.map(d => {
      const str = d.format(DateFormat);

      return {
        date: d,
        str,
        label: d.format("DD, dd"),
        isToday: str === today,
        isSelectedMonth: str.substring(0,7) === selectedMonth,
        isWeekend: d.get("d") === 0 || d.get("d") === 6,
      }
    })

    return {
      startDate,
      endDate,
      days: richDays,
    };

  }, [dateInMonth]);

  return x;
}

const eventInDate = (date: string, e: CalendarEvent) => {
  return e.start_datetime.startsWith(date) || (
    e.start_datetime < date && e.end_datetime >= date);
}

interface Props {
  events: CalendarEvent[];
  create: (start?: Partial<CalendarEvent>) => void;
  edit: (event: CalendarEvent) => void;
  myEmployeeId: string;
  schema: Schema;
  haveConflicts: Record<number, boolean>;
  setDateFiltering: (v: DateFilteringT) => void;
}


export const CalendarEventsView = (props: Props) => {
  const today = moment().set("D", 1);
  const [monthStart, setMonthStart] = useState<Moment>(today);

  const [selectedDay, setSelectedDay] = useState<Moment | null>(null);

  const selectedDayEvents = useMemo(() => {
    if(!selectedDay) {
      return [];
    }
    const d = selectedDay.format(DateFormat);

    return props.events.filter(e => eventInDate(d,e));
  }, [selectedDay, props.events]);

  const moveToToday = () => {
    setMonthStart(today);
    props.setDateFiltering(calendarMonthFilterFromDate(today));
  }


  const moveMonth = (direction: 1 | -1) => {
    const targetDate = monthStart.clone().add(direction, "M")
    setMonthStart(targetDate);
    props.setDateFiltering(calendarMonthFilterFromDate(targetDate));
  }

  const x = useCalendarMonthDates(monthStart);
  const formats = useFormats();


  const daysWithEvents = useMemo(() => {
    return x.days.map(d => {
      const events = props.events.filter(e => eventInDate(d.str, e));

      return {
        ...d,
        events,
      }
    })
  }, [x.days, props.events]);

  return (<>
    <CalendarOuter>
      <TitleLine>
        <Typography variant="h6" className="title">
          {monthStart.format("MMMM, yyyy")}
        </Typography>

        <OccupyFreeSpace />

        <Button size="small" onClick={() => moveMonth(-1)} startIcon={<ArrowBackIos />}>
          {monthStart.clone().add(-1, "M").format("MMM").substring(0,3)}
        </Button>

        <Button size="small" onClick={() => moveToToday()}>
          •
        </Button>

        <Button size="small" onClick={() => moveMonth(+1)} endIcon={<ArrowForwardIos />}>
          {monthStart.clone().add(+1, "M").format("MMM").substring(0,3)}
        </Button>
      </TitleLine>

      <CalendarDaysOfWeek>
        {Array.from({ length: 7}).map((_,i) => {
          const d = x.startDate.clone().add("d", i);
          const label = d.format("dd");
          return (<DayOfWeek key={label}>
            {label}
          </DayOfWeek>)
        })}
      </CalendarDaysOfWeek>

      <CalendarInner weeks={Math.floor(x.days.length / 7)}>
        {daysWithEvents.map(d => {
          let events = d.events;
          let hasHiddenEvents = false;
          if(events.length > 2) {
            events = d.events.slice();
            events.sort((a,b) => (a.owners?.includes(props.myEmployeeId) ? -1 : 1) < (b.owners?.includes(props.myEmployeeId) ? -1 : 1)
              ? -1
              : eventComparerByStart(a,b));
            events = events.slice(0,2);
            events.sort(eventComparerByStart);
            hasHiddenEvents = true;
          }

          return (
            <Day
              key={d.str}
              isToday={d.isToday}
              isSelectedMonth={d.isSelectedMonth}
              isWeekend={d.isWeekend}
              onClick={() => {
                setSelectedDay(d.date);
              }}>
              {d.isToday && <SelectionMarker />}
              <DayLabel className="date" isToday={d.isToday}>{d.date.format("D")}</DayLabel>
              {events.map(e => (
                <EventItem
                  key={e._id}
                  onClick={ev => {
                    ev.stopPropagation();
                    props.edit({ ...e });
                  }}>
                    <span className="time">
                      {moment(e.start_datetime).format(DateFormat) === d.str ? moment(e.start_datetime).format("HH:mm") : "…"} – {moment(e.end_datetime).format(DateFormat) === d.str ? moment(e.end_datetime).format("HH:mm") : "…"}
                      {!!e.owners?.includes(props.myEmployeeId || "x") &&
                        <OwnershipBadge event={e} myEmployeeId={props.myEmployeeId}>
                          <span className="badge-anchor">:</span>
                        </OwnershipBadge>}
                      {!!props.haveConflicts[e._id] &&
                        <ConflictsBadge hasConflict>
                          <span className="badge-anchor">:</span>
                        </ConflictsBadge>}
                    </span>
                  <span className="title">{e.title}</span>
                </EventItem>
              ))}

              {hasHiddenEvents &&
                <MoreEvents>+ ещё {d.events.length - 2}</MoreEvents>}

              <AddButton
                onClick={e =>{
                  e.stopPropagation();
                  props.create({
                    start_datetime: d.date.clone().set("h", 12).set("m", 0).set("s", 0).set("ms", 0).format(DateTimeInternalFormat),
                    end_datetime: d.date.clone().set("h", 13).set("m", 0).set("s", 0).set("ms", 0).format(DateTimeInternalFormat),
                  })}}
                >
                <Add />
              </AddButton>
            </Day>
        )})}
      </CalendarInner>
    </CalendarOuter>

    <Dialog
      isOpen={!!selectedDay}
      close={() => setSelectedDay(null)}
      dialogTitle={selectedDay ? selectedDay.format("D MMM yyyy") : "..."}
      maxWidth="lg"
      fullWidth
      titleActions={<>
        <IconButton size="small" color="primary" onClick={() => {
          if(selectedDay) {
            props.create({
              start_datetime: selectedDay.clone().set("h", 12).set("m", 0).set("s", 0).set("ms", 0).format(DateTimeInternalFormat),
              end_datetime: selectedDay.clone().set("h", 13).set("m", 0).set("s", 0).set("ms", 0).format(DateTimeInternalFormat),
            })
          }
        }}>
          <Add />
        </IconButton>
      </>}>
      
      <TableForFields
        fields={[
          ["title"],
          ["casenbr"],
          ["conf_room_id"],
          ["start_date", { label: "Дата" }],
          ["start_datetime"],
          ["end_datetime"],
          ["confirmation"],
        ]}
        data={selectedDayEvents}
        schema={props.schema}
        fieldElement={f => {
          switch(f) {
            case "title":
              return (r,s,o) => (
                <PseudoLink2 onClick={() => props.edit({ ...r })}>
                  <OwnershipBadge event={r} myEmployeeId={props.myEmployeeId}>
                    <span>
                      {o}
                    </span>
                  </OwnershipBadge>
                </PseudoLink2>);
            case "start_date":
              return (r) => formats.formatDate(r.start_datetime);
            case "start_datetime":
              return r => moment(r.start_datetime).format("HH:mm");
            case "end_datetime":
              return (r,s,o) => {
                const start = moment(r.start_datetime);
                const end = moment(r.end_datetime);
                if(start.isSame(end, "date")) {
                  return end.format("HH:mm")
                } else {
                  return `${end.format("HH:mm")} ! ${formats.formatDate(r.end_datetime)}`
                }
              }
            case "conf_room_id":
              return (r,s,o) => props.haveConflicts[r._id]
                ? <ConflictsBadge hasConflict>
                  <span>{o}</span>
                </ConflictsBadge>
                : <>{o}</>
          }
        }}
        emptyState={<EmptyState text="..." />}
        />
    </Dialog>
  </>);
}
