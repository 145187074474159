import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { apiFetch, FetchTypes } from "./core";
import { LoadedData, PagedLoadedData, useLoadedData, usePagedLoadedData } from "../hooks/useLoadedData"
import { useValidationErrors, ValidationErrors } from "../components/schemed";
import { useThrottledDoubleState } from "../components/primitives";


interface ListConfig<T> {
    defaultView: string;
    newItem: Partial<T>;
    runAuto?: boolean;
    newItemPath: (created: T) => string | null;
    extraParams?: Record<string, string>;
    filterBy?: (r: T) => string;
    viewLocalStorageKey?: string;
}

const createDefaultListConfig = <T>(): ListConfig<T> => ({
    defaultView: "all",
    newItem: {},
    newItemPath: (_: T) => null,
})

interface CrudUtilData<T> {
    view: string;
    setView: (_: string) => void;
    filter: string;
    setFilter: (_: string) => void;
    create: () => Promise<T>;
}

type CrudListDataUnpaged<T> = CrudUtilData<T> & LoadedData<T[]>;
type CrudListDataPaged<T> = CrudUtilData<T> & PagedLoadedData<T>;

export type CrudListData<T> = CrudListDataPaged<T> | CrudListDataUnpaged<T>;

const useView = (defaultView: string, localStorageKey?: string) => {
    const dfltView = (localStorageKey && localStorage.getItem(localStorageKey)) || defaultView;
    const [view, setViewX] = useState<string>(dfltView);

    const setView = (v: string) => {
        if(localStorageKey) {
            localStorage.setItem(localStorageKey, v);
        }
        setViewX(v);
    }

    return {
        view,
        setView,
    }
}

export const useCrudPagedList = <T>(url: string, config: Partial<ListConfig<T>>): CrudListData<T> => {
    const { defaultView, newItem, newItemPath, filterBy, viewLocalStorageKey } = { ...createDefaultListConfig<T>(), ...config };

    const { view, setView } = useView(defaultView, viewLocalStorageKey);
    const [filter, setFilter] = useState<string>("");
    const history = useHistory();

    const data = usePagedLoadedData<T>(`${url}?view=${view}&filter=${filter}`);

    const create = () => {
        return apiFetch<T>(url, FetchTypes.POST, newItem)
            .then(e => {
                const path = newItemPath(e);
                if(path) {
                    history.push(path);
                } else {
                    data.reload();
                }
                return e;
             });
    }

    return {
        view,
        setView,
        filter,
        setFilter,
        create,

        ...data,
        data: filterBy && filter.length ? data.data.filter(r => filterBy(r).toLowerCase().includes(filter.toLowerCase())) : data.data,
    }
}

export const useCrudUnpagedList = <T>(url: string | null, config: Partial<ListConfig<T>>): CrudListData<T> => {
    const { defaultView, newItem, newItemPath, extraParams, filterBy, viewLocalStorageKey } = { ...createDefaultListConfig<T>(), ...config };

    const { view, setView } = useView(defaultView, viewLocalStorageKey);
    // const [filter, setFilter] = useState<string>("");
    const [filter, setFilter, appliedFilter] = useThrottledDoubleState<string>("");
    const history = useHistory();

    const params = Object.entries(extraParams || {})
        .concat([["view", view]])
        .concat(filterBy ? [] : [["filter", appliedFilter]])
        .filter(([k,v]) => k !== "view" || !!v);

    const data = useLoadedData<T[]>(`${url}?${params.map(([k,v]) => `${k}=${v}`).join('&')}`, [], config.runAuto !== undefined ? config.runAuto : true);

    const create = () => {
        return url !== null ?
         apiFetch<T>(url, FetchTypes.POST, newItem)
            .then(e => {
                const path = newItemPath(e);
                if(path) {
                    history.push(path);
                } else {
                    data.reload();
                }
                return e;
             })
        : new Promise<T>(() => {return});
    }

    return {
        view,
        setView,
        filter,
        setFilter,
        create,

        ...data,
        data: filterBy && filter.length ? data.data.filter(r => filterBy(r).toLowerCase().includes(filter.toLowerCase())) : data.data,
    }
}

export const useCrudList = useCrudPagedList;

interface ItemConfig<T> {
    defaultValue: T;
    returnPath?: string;
    prepareChanges?: (c: Partial<T>) => Partial<T>;
    prepareLoadedItem?: (item: T) => T;
    onChange?: (o: T, changes: Partial<T>) => Partial<T>;
    noLoad?: boolean;
    resetChangesOnReload?: boolean;
}

export interface CrudItemData<T> {
    data: T;
    changes: Partial<T>;
    hasChanges: boolean;
    isLoading: boolean;
    errors?: ValidationErrors;

    reload: () => Promise<T>;
    save: (extraChanges?: Partial<T>) => Promise<T>;
    remove: () => Promise<any>;
    update: (changes: Partial<T>) => void;
    setData: (v: T) => void;
}

export const useCrudItem = <T>(url: string, config: ItemConfig<T>): CrudItemData<T> => {
    const { defaultValue, returnPath } = config;
    const [data, setData] = useState<T>(defaultValue);
    const [changes, setChanges] = useState<Partial<T>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();

    const prepareChanges = config.prepareChanges || (v => v);

    const history = useHistory();

    const reload = useCallback(() => {
        setIsLoading(true);
        errors.clearErrors();
        return apiFetch<T>(url)
            .then(item => {
                const final = config.prepareLoadedItem ? config.prepareLoadedItem(item) : item; 
                setData(final);
                if(config.resetChangesOnReload) {
                  setChanges({});
                }
                setIsLoading(false);
                return final;
            })
            .catch(e => { setIsLoading(false); throw e; });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const save = (extraChanges?: Partial<T>) => {
        setIsLoading(true);
        errors.clearErrors();
        const actualChanges = extraChanges ? { ...changes, ...extraChanges } : changes;
        return apiFetch<T>(url, FetchTypes.PUT, prepareChanges(actualChanges))
            .then(item => {
                const final = config.prepareLoadedItem ? config.prepareLoadedItem(item) : item; 
                setData(final);
                setIsLoading(false);
                setChanges({});
                return final;
            })
            .catch(e => { setIsLoading(false); errors.handleErrors(e); throw e;});
    }

    const remove = () => {
        setIsLoading(true);
        return apiFetch<{}>(url, FetchTypes.DELETE)
            .then(x => {
                setIsLoading(false);
                if(returnPath) {
                    history.replace(returnPath);
                }
                return x; })
            .catch(e => { setIsLoading(false); throw e; });
    }

    const update = (newChanges: Partial<T>) => {
        const newChangesX = config.onChange ? config.onChange(data, newChanges) : newChanges;
        setChanges(old => ({ ...old, ...newChangesX }));
        setData(old => ({ ...old, ...newChangesX }));
    }

    const noLoad = config?.noLoad || false;
    
    useEffect(() => {
        if(!noLoad) {
            reload();
        }
    }, [reload, noLoad]);

    const hasChanges = changes && Object.keys(changes).length !== 0;

    return {
        data,
        changes,
        hasChanges,
        isLoading,

        reload,
        save,
        remove,
        update,
        setData,

        errors,
    }
}
