import React, { ReactNode } from 'react';
import { Button, IconButton, InputAdornment, PropTypes } from '@mui/material';
import { CallMadeOutlined, FileCopyOutlined, Save } from '@mui/icons-material';
import styled from '@emotion/styled';
import { LoadingIndicator } from './LoadingIndicator';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

export const ButtonView = styled(Button)`
    margin-right: 1rem;
`

interface ActionButtonProps {
    action: () => void;
}

export const SaveButton = ({ action }: ActionButtonProps) => {
    return <IconButton size="small" onClick={action}><Save /></IconButton>;
}

interface SimpleButtonProps extends ActionButtonProps {
    label: string | ReactNode;
    isLoading?: boolean;
}

export const SimpleButton: React.FC<SimpleButtonProps> = ({ action, label: title, isLoading }: SimpleButtonProps) => {
    return (
        <div>
            <ButtonView onClick={action} color="primary">{title}</ButtonView>
            {isLoading !== undefined && (isLoading && <LoadingIndicator />)}
        </div>
    )
}

interface SimpleIconButtonProps extends ActionButtonProps {
    title?: string;
    icon: JSX.Element;
}

export const SimpleIconButton = ({ action, title, icon }: SimpleIconButtonProps) => {
    return <IconButton size="small" onClick={action} title={title}>{icon}</IconButton>
}

interface ClipboardButtonProps {
    icon?: ReactNode;
    getValue?: () => string;
    value?: Object;
    size?: 'small' | 'medium';
    showNotification?: boolean;
    notificationText?: ReactNode;
    iconColor?: PropTypes.Color;
    stopPropagation?: boolean;
}

export const ClipboardButton = ({ icon, value, getValue, size, showNotification, notificationText, iconColor, stopPropagation }: ClipboardButtonProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const show = showNotification ?? true;
    const notificationMessage = notificationText ?? <FormattedMessage id="success.clipboard" />

    const copyValue = () => {
        const copiedValue = (getValue ? getValue() : value?.toString()) || '';
        navigator.clipboard.writeText(copiedValue);

        if (show) {
            enqueueSnackbar(notificationMessage, { variant: 'success', autoHideDuration: 2000 })
        }
    }

    return <IconButton
                size={size || 'small'} color={iconColor} className="clipboard-button"
                onClick={e => {
                    if(stopPropagation) {
                        e.stopPropagation();
                    }
                    copyValue();
                }}
                >
        {icon || <FileCopyOutlined />}
    </IconButton>
}

export const InputIconButton = (props: { icon: ReactNode, tooltip?: string, action?: () => void, position?: "start" | "end" }) => {
  return props.action
    ? <InputAdornment position={props.position || "end"}>
        <IconButton title={props.tooltip} size="small" onClick={() => props.action && props.action()}>
          {props.icon}
        </IconButton>
      </InputAdornment>
    : null;
}

export const InputLinkIconButton = (props: { icon?: ReactNode, tooltip?: string, link?: string, position?: "start" | "end" }) => {
  return props.link
    ? <InputAdornment position={props.position || "end"}>
        <Link to={props.link}>
          <IconButton title={props.tooltip} size="small">
            {props.icon || <CallMadeOutlined />}
          </IconButton>
        </Link>
      </InputAdornment>
    : null;
}

export const SmallerButton = styled(Button)`
  padding: 0.1rem 0.5rem;
  text-transform: none;
  min-width: 0;
`;
