import { Button, IconButton } from '@mui/material';
import { Check, EditOutlined, Warning } from '@mui/icons-material';
import React from 'react';
import { useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { useEditable } from '../../../toolympus/api/useEditable';
import { Alert, AlertColor } from '../../../toolympus/components/primitives/Alert';
import { Link } from '../../../toolympus/components/primitives/Common.styles';
import { TableForFields } from '../../../toolympus/components/schemed';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { CandidateDetailsDialog } from './CandidateDetailsDialog';
import { ArbitratorSelection } from './usePresidiumQuestionArbitratorsSelection';
import { PresidiumQuestionResultData } from './usePresidiumQuestionResult';

interface Props {
    data: PresidiumQuestionResultData;
}

export const PresidiumQuestionResults = (props: Props) => {
    const { arbitrator_suggestion: schema } = useSchema();
    const { data, canFinish, finish, updateCandidate, question } = props.data;

    const doFinish = useActionWithConfirmation(finish, {
        canRun: canFinish,
        title: "Завершить голосование по вопросу",
        confirmationHint: `Вопрос будет переведен в статус "закрыт", выбранные арбитры будут назначены в дело.`,
        confirmationLabel: "завершить",
    });

    const editCandidate = useEditable<ArbitratorSelection>(null);

    const isClosed = question.is_closed;

    return (
        <>
            <div key="alerts">
                {!data.has_enough_votes &&
                    <Alert key="not-enough-votes" title="Кворум не собран" icon={<Warning htmlColor={AlertColor.error} />} />}
                {!data.has_all_votes && data.has_enough_votes &&
                    <Alert key="missing-votes" title="Нет части голосов" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_ties &&
                    <Alert key="ties" title="Есть кандидаты с равными результатами" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_enough_votes && data.has_unbroken_tie && !data.has_all_votes &&
                    <Alert key="all-votes" title="Кворум собран, но у кандидатов равные результаты и еще не все проголосовали" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_enough_votes && !(data.has_unbroken_tie && !data.has_all_votes) &&
                    <Alert key="all-votes" title="Кворум собран" icon={<Check htmlColor={AlertColor.success} />} />}
                
                {data.has_admin_votes &&
                    <Alert key="admin-votes" title="Есть голоса, проставленные аппаратом" icon={<Warning htmlColor={AlertColor.warning} />} />}
                {data.has_enough_votes && !data.has_all_approvals && !isClosed && (
                    <Alert key="missing-selection" icon={<Warning htmlColor={AlertColor.error} />} title="Необходимо выбрать арбитров" />)}
                {data.has_enough_votes && data.has_all_approvals && canFinish && !isClosed && (
                    <Alert
                        key="done"
                        icon={<Check htmlColor={AlertColor.success} />}
                        title="Арбитры выбраны - можно завершать голосование по вопросу"
                        action={<Button size="small" variant="contained" color="primary" onClick={doFinish.run}>завершить</Button>}
                        />)}
            </div>

            <TableForFields
                dense
                data={data.candidates}
                schema={schema}
                fields={[
                    ["fullname", { label: "ФИО"}],
                    ["confirmation_status", { editable: !isClosed }],
                    ["confirmation_date", { editable: !isClosed }],
                    ["disclosure_edit", { label: " " }],
                    ["score"],
                    ["select", { label: " "}],
                ]}
                fieldElement={f => {
                    switch(f) {
                        case "fullname":
                            return r => <Link to={`/arbitrator/${r.arbitrator_id}`}>{r.lastname} {r.firstname} {r.middlename}</Link>;
                        case "disclosure_edit":
                            return (r,s,o) => r.confirmation_status === "disclosure" ? (
                                <IconButton
                                    size="small"
                                    color={(r.disclosure_details || r.disclosure_document_id) ? "primary" : "default"}
                                    onClick={() => editCandidate.reset(r)}
                                    >
                                    <EditOutlined />
                                </IconButton>)
                                : "";
                        case "select":
                            return r => (
                                <Button
                                    size="small"
                                    color="primary"
                                    disabled={isClosed || (!r.is_approved && data.has_all_approvals)}
                                    variant={r.is_approved ? "contained" : "outlined"}
                                    style={{ minWidth: 90 }}
                                    onClick={() => updateCandidate(r.arbitrator_id, { is_approved: !r.is_approved })}
                                    >
                                    {r.is_approved ? "выбран" : "выбрать"}
                                </Button>
                            );
                    }
                }}
                onChange={(o,c) => updateCandidate(o.arbitrator_id, c)}
                />

            <CandidateDetailsDialog
                isOpen={!!editCandidate.state}
                close={() => editCandidate.reset()}
                candidate={editCandidate.state}
                update={editCandidate.update}
                questionId={question._id}
                canEdit={!isClosed}
                hasChanges={editCandidate.hasChanges}
                changes={editCandidate.changes}
                saveChanges={changes => {
                    if(editCandidate.state) {
                        updateCandidate(editCandidate.state.arbitrator_id, changes);
                    }
                }}
            />
        </>
    );
}
