import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../frame';
import { NumberingSequencesPage } from './NumberingSequencesPage';
import { NumberingSequencesEditPage } from './NumberingSequencesEditPage';

interface Props {
  apiPath: string;
  usersDictionary?: string;
  isEditAvailable?: boolean;
  documentationKey?: string;
}

export const NumberingSequencesRoutes = (props: Props) => {
  let { path } = useRouteMatch();

  const xprops = useMemo(() => {
    return {
      apiPath: props.apiPath,
      usersDictionary: props.usersDictionary,
      isEditAvailable: props.isEditAvailable,
      documentationKey: props.documentationKey
    }
  }, [props.apiPath, props.usersDictionary, props.isEditAvailable, props.documentationKey]);
  
  return (
    <Routes
      routes={[
        { path: `${path}/edit`, component: NumberingSequencesEditPage, componentProps: xprops },
        { path: `${path}`, component: NumberingSequencesPage, componentProps: xprops },
      ]}
    />
  );
}
