import { useState } from "react";
import { today } from "../../toolympus/api/datetimeUtil";
import { NewItemProps, useNewItem } from "../../toolympus/api/useNewItem";
import { CrudItemData, useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { FieldType, Schema, useSchema } from "../../toolympus/hooks/useSchema";
import { CorpDispute, CorpDisputeApiPath } from "./useCorpDisputesList";
import { useEditParties, SideKey as SideKeyT } from "./useEditParties";

export type SideKey = SideKeyT;

type PublishFields = Pick<CorpDispute, "is_published" | "published_date">;

export const ChangesHistorySchema: Schema = {
    date: { type: FieldType.date, label: "Дата обновления"},
    description: { type: FieldType.textlong, label: "Описание обновления"},
    change: { label: "Изменения" },
};

export interface EditCorpDisputeData extends CrudItemData<CorpDispute> {
    schema: Schema;
    
    publish: NewItemProps<PublishFields, CorpDispute>;
    
    isSavingPublished: boolean;
    cancelSaving: () => void;

    parties: ReturnType<typeof useEditParties>;
}

export const useEditCorpDispute = (id: string): EditCorpDisputeData => {
    const data = useCrudItem<CorpDispute>(`${CorpDisputeApiPath}/${id}`, {
        defaultValue: {} as CorpDispute,
    });

    const parties = useEditParties(data);

    const [isSavingPublished, setIsSavingPublished] = useState<boolean>(false);

    const { corp_dispute: schema } = useSchema();

    const publish = useNewItem<PublishFields, CorpDispute>(
        `${CorpDisputeApiPath}/${id}`,
        { is_published: true, published_date: today() },
        { httpMethod: "PUT" });

    return {
        ...data,
        schema,

        save: () => {
            if(!data.hasChanges) {
                return Promise.resolve(data.data);
            }

            if(data.data.is_published && !data.changes.is_published && !isSavingPublished) {
                setIsSavingPublished(true);
                data.update({ change_description: { description: "", date: today() }})
                return Promise.resolve(data.data);
            } else {
                return data.save().then(x => { setIsSavingPublished(false); return x; });
            }
        },

        publish: {
            ...publish,
            startEditing: (x) => {
                if(data.hasChanges) {
                    data.save().then(() => publish.startEditing(x));
                } else {
                    publish.startEditing(x);
                }
            },
            save: () => publish.save().then(x => { data.reload(); return x; }),
        },

        isSavingPublished,
        cancelSaving: () => setIsSavingPublished(false),

        parties,
    }
}

