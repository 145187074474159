import { Button } from '@mui/material';
import { Check } from '@mui/icons-material';
import React from 'react';
import { useActionWithConfirmation } from '../../../toolympus/api/useAction';
import { Alert, AlertColor } from '../../../toolympus/components/primitives/Alert';
import { PresidiumQuestionResultData } from './usePresidiumQuestionResult';

interface Props {
    data: PresidiumQuestionResultData;
}

export const PresidiumQuestionNoVoteResults = (props: Props) => {
    const { canFinish, finish, question } = props.data;

    const doFinish = useActionWithConfirmation(finish, {
        canRun: canFinish,
        title: "Завершить обсуждение по вопросу",
        confirmationLabel: "завершить",
    });

    const isClosed = question.is_closed;

    return (
        <>
            <div key="alerts">
                {canFinish && !isClosed && (
                    <Alert
                        key="done"
                        icon={<Check htmlColor={AlertColor.success} />}
                        title={`можно завершать голосование по вопросу`}
                        action={<Button size="small" variant="contained" color="primary" onClick={doFinish.run}>завершить</Button>}
                        />)}
            </div>
        </>
    );
}
