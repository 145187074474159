import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonGroup, Chip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { NotificationTargetsManagerX } from './useNotificationTasks';
import { FormControl, TableForFields } from '../schemed';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { SelectButton } from '../primitives/SelectButton';
import { SearchField } from '../primitives/SearchField';
import { Dialog } from '../primitives/Dialogs';
import { Tooltip } from '../primitives';

interface Props {
    isOpen: boolean;
    close: () => void;
    targets: NotificationTargetsManagerX;
}

const RecordTagContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.25rem 0.5rem;
`;

const FilterTagContainer = styled(RecordTagContainer)``;

export const TargetsSelection = (props: Props) => {
    const { isOpen, close } = props;
    const {
        targets,
        schema,
        group,
        setGroup,
        availableTags,
        isTagSelected,
        setIsTagSelected,
        filter,
        setFilter,
        tagFilterMode,
        setTagFilterMode,
        notFilterMode,
        setNotFilterMode,

        isTargetSelected,
        addTarget,
        removeTarget,
        addBatch,
        removeBatch,
        removeAll,
    } = props.targets;

    const toggleTagFilterMode = () => setTagFilterMode(tagFilterMode === "or" ? "and" : "or");

    return (
        <Dialog
            isOpen={isOpen}
            close={close}
            maxWidth="lg"
            PaperProps={{ style: { height: "100%" }}}
            dialogTitle={<FormattedMessage id="notifications.task.targets" />}
            actions={<Button variant="contained" color="primary" onClick={close} >
                    <FormattedMessage id="common.close" />
                </Button>}>

            <ActionRow key="control" firstItemNoMargin itemMarginTop="0px">
                    <FormControl
                        extraProps={{ label: " ", wrapperStyle: { minWidth: "200px" }, controlProps: { style: { margin: 0 } }}}
                        schema={schema["kind"]}
                        field="kind"
                        row={{ kind: group }}
                        onChange={(o,c) => setGroup(c.kind)}
                    />
                    
                    <SearchField
                        filter={filter}
                        setFilter={setFilter}
                        doSearch={() => { }}
                        noButton
                        />

                    <OccupyFreeSpace />

                    <Button onClick={() => addBatch(targets)}><FormattedMessage id="notifications.task.target.add_filtered" /></Button>
                    <Button onClick={() => removeBatch(targets)}><FormattedMessage id="notifications.task.target.remove_filtered" /></Button>
                    <Button onClick={() => removeAll()}><FormattedMessage id="notifications.task.target.remove_all" /></Button>
                </ActionRow>

                {availableTags.length > 0 && <ActionRow key="filter-tags" firstItemNoMargin>
                    <Typography>
                        <FormattedMessage id="notifications.task.controls.filter_by_tags" />
                    </Typography>

                    <Button
                        size="small"
                        color="primary"
                        variant={notFilterMode ? "contained" : "outlined"}
                        onClick={() => setNotFilterMode(!notFilterMode)}>
                        <FormattedMessage id={`notifications.task.controls.filter_by_tags_mode_not`} />
                    </Button>

                    <Tooltip text_id="notifications.task.controls.filter_by_tags_mode_hint">
                        <ButtonGroup size="small" variant="outlined">
                            {["or", "and"].map(m => (
                                <Button
                                    key={m}
                                    color={tagFilterMode === m ? "primary" : "inherit"}
                                    variant={tagFilterMode === m ? "contained" : "outlined"}
                                    onClick={toggleTagFilterMode}>
                                        <FormattedMessage id={`notifications.task.controls.filter_by_tags_mode_${m}`} />
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Tooltip>

                    <FilterTagContainer>
                        {availableTags.map(t => {
                            const isSelected = isTagSelected(t);
                            return <Chip
                                color={isSelected ? "primary" : undefined}
                                variant={isSelected ? undefined : "outlined"}
                                key={t}
                                label={t}
                                onClick={() => setIsTagSelected(t, !isSelected)}
                                />;
                            })}
                    </FilterTagContainer>
                </ActionRow>}

                <TableForFields
                    fields={[
                        ["display_name"],
                        ["kind"],
                        ["email"],
                        ["tags"],
                    ]}
                    data={targets}
                    schema={schema}
                    rowButtons={r => <SelectButton
                                        isSelected={isTargetSelected(r)}
                                        select={() => addTarget(r)}
                                        deselect={() => removeTarget(r)}
                                        />}
                    fieldElement={f => {
                        if(f === "tags") {
                            return r => <RecordTagContainer>{(r.tags || []).map(t => <Chip variant="outlined" size="small" key={t} label={t} />)}</RecordTagContainer>
                        }
                    }}
                    />
        </Dialog>
    );
}
