import React from 'react';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FieldDefinition, TableForFields } from '../../../toolympus/components/schemed';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { ArbitratorSuggestionsData } from './useArbitratorSuggestions';
import { Buttons } from '../../../toolympus/components/primitives';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { CallMade } from '@mui/icons-material';

interface Props {
    data: ArbitratorSuggestionsData;
    hideArbFields?: boolean;
    hideCaseFields?: boolean;
}

export const ArbitratorSuggestionsTable = ({ data, hideArbFields, hideCaseFields }: Props) => {
    const {
        arbitrator_suggestion: schema,
    } = useSchema();

    return data.isLoading ?
        <LoadingIndicator /> :
        <TableForFields
            fields={[
                ...(hideArbFields ? [] : [
                    ["lastname"],
                    ["firstname"],
                    ["middlename"],
                ]) as FieldDefinition[],
                ...(hideCaseFields ? [] : [
                    ["casenbr"],
                ]) as FieldDefinition[],
                ["source"],
                ["vote_date"],
                ["result", { emptyLabel: "-" }],
            ]}
            schema={schema}
            data={data.data}
            fieldLink={f => {
                switch(f) {
                    case "lastname":
                    case "firstname":
                    case "middlename":
                        return a => `/arbitrator/${a._id}`;
                    case "casenbr":
                        return a => `/case/${a.case_id}`;
                    case "result":
                        return a => `/presidium/questions/${a.question_id}?tab=responses`;
                    default:
                        return null;
                }
            }}
            fieldElement={f => {
              switch(f) {
                case "vote_date":
                  return (row,s,orig) => (
                    <Buttons gap="small">
                      {orig} <Link to={`/presidium/questions/${row.question_id}`}><IconButton size="small"><CallMade /></IconButton></Link>
                    </Buttons>
                  );
              }
            }}
            />;
}
