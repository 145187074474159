import React from 'react';
import { DialogState, FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { TimetrackingRatetable } from './types';
import { CrudItemData } from '../../toolympus/api/useSimpleCrud';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useTimetrackingRatetableSchema } from './useTimetrackingRateTablesConfiguration';
import { Typography } from '@mui/material';
import { TimetrackingRatesEditor } from './TimetrackingRatesEditor';


interface Props {
  data: CrudItemData<TimetrackingRatetable>;
  dialogState: DialogState;  
}

export const CustomRatetableEditDialog = (props: Props) => {
  const { data, dialogState } = props;
  const { schema } = useTimetrackingRatetableSchema();
  
  return (
    <SimpleDialog
      {...dialogState}
      save={() => data.save().then(x => { dialogState.close(); return x; })}
      dialogTitle="Таблица ставок">
          {data.data &&
            <>
              <FormGrid columns="1fr 1fr">
                <FormControlsForFields
                  schema={schema}
                  data={data.data}
                  onChange={(o,c) => data.update(c)}
                  fields={[
                    ["title", { readOnly: true }],
                    ["currency"],
                    ["created_by", { readOnly: true }],
                    ["created_at", { utcToLocal: true, readOnly: true }],
                  ]}
                  />
              </FormGrid>
            
              <Typography variant="h6" style={{ marginTop: "1rem" }}>Ставки</Typography>
              <TimetrackingRatesEditor
                rates={data.data.rates}
                update={v => data.update({ rates: v })}
                />
            </>}
      
    </SimpleDialog>
  );
}
