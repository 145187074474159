import { Button } from '@mui/material';
import React from 'react';
import { ButtonOptionPicker } from '../../../toolympus/components/primitives/ButtonOptionPicker';
import { Dialog } from '../../../toolympus/components/primitives/Dialogs';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';
import { FormControl } from '../../../toolympus/components/schemed';
import { SelectControl } from '../../../toolympus/components/schemed/Select';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { EditResponseData } from './useEditResponse';

interface Props {
    data: EditResponseData;
}

export const EditResponseDialog = (props: Props) => {
    const {
        isOpen,
        close,
        update,
        save,
        canSave,
        response,
        responseType,
        updateResponseType,
        candidates,
        selectCandidate,
        options,
        selectOption,
        isCandidatesQuestion,
    } = props.data;
    const { presidium_response: schema } = useSchema();

    const candidateOptions = candidates.map(c => ({ value: c._id, label: `${c.lastname} ${c.firstname} ${c.middlename}`}));
    const currentVote = (response?.response?.vote || []);
    
    return (
        <Dialog
            dialogTitle="Изменить ответ"
            isOpen={isOpen}
            close={close}
            actions={<>
                <Button key="cancel" onClick={close}>отмена</Button>
                <Button key="save" disabled={!canSave} variant="contained" color="primary" onClick={() => save()}>сохранить</Button>
            </>}
            >

            <ButtonOptionPicker
                selected={responseType}
                setSelected={updateResponseType}
                options={[
                    ["Воздержался", "is_abstained"],
                    ["Против всех", "is_against_all"],
                    ["Конфликт", "is_conflict"],
                    ["Голос", "vote"],
                ]}
                />

            <FormGrid columns="1fr">

                {responseType === "vote" && isCandidatesQuestion && candidates.map((x,idx) => (
                    <SelectControl
                        key={idx}
                        value={currentVote[idx]}
                        onChange={v => selectCandidate(idx, v as string || null)}
                        label={`Кандидат ${idx+1}`}
                        values={candidateOptions.filter(option => {
                            const selectedFor = currentVote.indexOf(option.value);
                            return selectedFor < 0 || selectedFor === idx;
                        })}
                        />
                    ))}

                {responseType === "vote" && isCandidatesQuestion && !!response?.response?.candidates_conflict?.length &&
                  <p>
                    Конфликты: {response?.response?.candidates_conflict.map((cId,idx) => candidates.find(c => c._id === cId)?.lastname || "").join(", ")}
                  </p>}

                {responseType === "vote" && !isCandidatesQuestion && (
                    <SelectControl
                        key="option"
                        value={response?.response?.option ? response?.response?.option.id : null}
                        onChange={v => selectOption({ id: v })}
                        label="Вариант"
                        values={options.map(({ id, label }) => ({ value: id, label }))}
                        />
                    )}

                {(responseType === "is_conflict" || responseType === "vote") && (
                    <FormControl
                        field="comment"
                        row={response}
                        schema={schema["comment"]}
                        onChange={(o,c) => update(c)}
                        />
                    )}

            </FormGrid>

        </Dialog>
    );
}
