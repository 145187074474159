import { useState } from "react";
import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { EditItemProps, NewItemProps, useEditItem, useNewItem } from "../../../toolympus/api/useNewItem";
import { CrudListData, useCrudUnpagedList } from "../../../toolympus/api/useSimpleCrud"
import { Schema, useSchema } from "../../../toolympus/hooks/useSchema";

export interface PresidiumMember {
    _id: string;
    is_excluded?: boolean;
    lastname: string;
    firstname: string;
    middlename?: string | null;
    language?: string;
    user_id?: string | null;
    role_full?: string | null;
    role_internal?: string | null;
    role_international?: string | null;
}

export type NewMember = Omit<PresidiumMember, '_id'>;

export type PresidiumMembersData = CrudListData<PresidiumMember> & {
    schema: Schema;
    newItem: NewItemProps<NewMember, PresidiumMember>;
    editedItem: EditItemProps<PresidiumMember>;
    remove: (item: PresidiumMember) => Promise<{}>;
    exclude: (item: PresidiumMember) => Promise<PresidiumMember>;
    unExclude: (item: PresidiumMember) => Promise<PresidiumMember>;
}

export const usePresidiumMembers = (): PresidiumMembersData => {
    const apiPath = '/api/presidium/member';
    const data = useCrudUnpagedList<PresidiumMember>(apiPath, {
        defaultView: 'all',
    });

    const { presidium_member: schema } = useSchema();

    const [filter, setFilter] = useState<string>("");

    const newItem = useNewItem<NewMember, PresidiumMember>(apiPath, { lastname: '', firstname: '', role_full: 'member' });
    const editedItem = useEditItem<PresidiumMember>(apiPath, "_id");


    const filterRecords = (records: PresidiumMember[]) => {
        if(filter && filter.length) {
            const f = filter.trim().toLowerCase();
            return records.filter(r => `${r.lastname} ${r.firstname} ${r.lastname}`.toLowerCase().includes(f));
        }

        return records;
    }

    const exclude = (member: PresidiumMember) => {
        return apiFetch<PresidiumMember>(`${apiPath}/${member._id}/exclude`, "post")
            .then(x => { data.reload(); return x; });
    }

    const unExclude = (member: PresidiumMember) => {
        return apiFetch<PresidiumMember>(`${apiPath}/${member._id}/unexclude`, "post")
            .then(x => { data.reload(); return x; });
    }

    return {
        ...data,
        data: filterRecords(data.data),
        filter,
        setFilter,
        schema,
        
        newItem: { ...newItem, save: () => newItem.save().then(r => { data.reload(); return r; }) },
        editedItem: { ...editedItem, save: () => editedItem.save().then(r => { data.reload(); return r; }) },

        remove: item => apiFetch<{}>(`${apiPath}/${item._id}`, FetchTypes.DELETE).then(r => { data.reload(); return r; }),
        exclude,
        unExclude,
    }
}