import React, { ReactNode } from 'react';
import { SnapshotsForm } from './SnapshotsForm';
import { SnapshotsConfig, useSnapshots } from './useSnapshots';

interface Props extends Partial<SnapshotsConfig> {
    extraActions?: ReactNode;
}

export const SnapshotsContainer = (props: Props) => {
    const data = useSnapshots(props);
    return <SnapshotsForm data={data} extraActions={props.extraActions} />;
}
