import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React from 'react';
import { FormBody, FormRow, FormWiderColumn } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { FormContainer } from '../../../toolympus/components/primitives/Modal.styles';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { ImportConfigSchema } from '../schema';
import { Importer } from '../useCaseImport';
import { FileUpload } from './FileUpload';

interface Props {
    isActive: boolean;
    setIsActive: (v: boolean) => void;
    importer: Importer;
}

export const ImportPopup = ({ isActive, setIsActive, importer }: Props) => {

    return (
        <Dialog open={isActive} onClose={() => { importer.clear(); setIsActive(false); }} maxWidth="sm">
            <DialogTitle>Импорт дел</DialogTitle>
            <DialogContent>
                <FormContainer id="importform" onSubmit={e => { e.preventDefault(); importer.performImport() }}>
                    <FormBody>
                        <FormRow>
                            <FormWiderColumn>
                                <FormControlsForFields
                                    fields={[
                                        ["updateExisting"],
                                        ["updateChecked"],
                                        ["failUnknownArbiters"],
                                        ["matchByEsacID"],
                                        ["dryRun"]]}
                                    schema={ImportConfigSchema}
                                    data={importer.config}
                                    onChange={(oldData, changes) => importer.updateConfig(changes)} />
                            </FormWiderColumn>
                        </FormRow>
                        <FormRow>
                            <FileUpload file={importer.file} onFileAdded={f => importer.setFile(f)} />
                        </FormRow>
                    </FormBody>
                </FormContainer>
            </DialogContent>
            <DialogActions>
                <Button type='submit'
                    form="importform"
                    disabled={importer.isLoading}
                    color='primary'
                    variant='contained'
                    endIcon={importer.isLoading && <LoadingIndicator sizeVariant="font" />}>
                    Импорт
                </Button>
                <Button disabled={importer.isLoading} color='secondary' variant='contained' onClick={() => setIsActive(false)}>Отмена</Button>
            </DialogActions>
        </Dialog>);
}
