import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";

export const useBrowserStoredValue = (dflt: string, queryParam: string | undefined, localStorageKey: string | undefined) => {
  const [value, setValue] = useState<string>(dflt);

  const history = useHistory();

  const update = (v: string) => {
    setValue(v);
    if(queryParam) {
      const urlp = new URLSearchParams(history.location.search);
      urlp.set(queryParam, v);
      history.replace({ pathname: history.location.pathname, search: `?${urlp.toString()}` });
    }
    if(localStorageKey) {
      localStorage.setItem(localStorageKey, v);
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const queryValue = queryParam ? query.get(queryParam) : "";
    const localStorageValue = localStorageKey ? localStorage.getItem(localStorageKey) : "";
    const v = queryValue || localStorageValue || dflt;
    if(v !== dflt) {
      update(v);
    } else {
      setValue(v);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam, localStorageKey]);

  

  return {
    value,
    update,
  }
}
