import React, { useEffect, useMemo } from "react";
import { ButtonOptionPicker, Buttons, Dialog, FormGrid, OccupyFreeSpace, Tooltip } from "../../../toolympus/components/primitives";
import { Button, TextField, IconButton, Typography } from "@mui/material";
import { NotificationsOutlined, Send, WarningOutlined } from "@mui/icons-material";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { QuestionFeed, Message } from "./useQuestionFeed";
import { PresidiumQuestionResponses } from "./usePresidiumResponses";
import { useSchema } from "../../../toolympus/hooks/useSchema";
import { useDictionaries } from "../../../toolympus/hooks/useDictionaries";
import { Checkbox } from "../../../toolympus/components/schemed/Checkbox";


interface Props {
  data: Pick<QuestionFeed, "sendMessage">;
  responses: PresidiumQuestionResponses;
  ensureResponsesLoaded?: () => void;
}

const useSendBoardMessage = (props: Props) => {
  const { sendMessage } = props.data;
  const message = useEditItem2<Message & { isExplicitMembers?: boolean }>({
    save: (item) => {
      const copy = { ...item };
      if(!copy.isExplicitMembers || !copy.is_urgent) {
        delete copy.urgent_members_ids;
      }
      delete copy.isExplicitMembers;
      return sendMessage(copy).then(() => item);
    }
  });

  useEffect(() => {
    if(message.item?.isExplicitMembers && props.ensureResponsesLoaded) {
      props.ensureResponsesLoaded();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.item?.isExplicitMembers]);

  const { presidium_response: responseSchema } = useSchema();
  const dictionaries = useDictionaries();
  const membersDictionary = dictionaries[responseSchema.member_id.dictionary || ""];

  const members = useMemo(() => {
    return props.responses.data.map(r => ({
      member_id: r.member_id,
      name: membersDictionary.valueDict[r.member_id],
      is_selected: message.item?.urgent_members_ids && message.item?.urgent_members_ids.includes(r.member_id),
    }));
  }, [membersDictionary, props.responses.data, message.item]);

  const setMemberSelection = (memberId: string, isSelected: boolean) => {
    const currentSelection = message.item?.urgent_members_ids || [];
    message.update({
      urgent_members_ids: isSelected ? [...currentSelection, memberId] : currentSelection.filter(x => x !== memberId),
    })
  }

  
  return {
    ...message,
    members,
    setMemberSelection,
  }
}

export const PresidiumSendMessageButton = (props: Props) => {
  const data = useSendBoardMessage(props);
  

  return <>
    <Button
      size="small"
      onClick={() => data.startEditing({ text: "" })}
      startIcon={<Send />}
      color="primary">
        отправить сообщение
    </Button>

    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle="Отправить сообщение"
      noFullscreen
      actions={<>
        <Button onClick={() => data.cancel()}>
          отмена
        </Button>
        <Button
          disabled={!data.item?.text}
          onClick={() => data.save()}
          variant="contained"
          color="primary">
            отправить
        </Button>

      </>}>

      {data.item &&
        <FormGrid columns="1fr" noMargin style={{ alignItems: "end" }}>
          <TextField
            value={data.item.text}
            onChange={e => data.update({ text: e.target.value})}
            label="Отправить сообщение"
            multiline
            />

          <Buttons>
            {data.item.is_urgent &&
              <>
                <Typography>Отправить e-mail:</Typography>
                <ButtonOptionPicker
                  selected={data.item.isExplicitMembers ? "explicit" : "all"}
                  options={[
                    ["Всем", "all"],
                    ["Выбранным", "explicit"],
                  ]}
                  setSelected={() => data.update({ isExplicitMembers: !data.item?.isExplicitMembers })}
                  />
              </>}

            <OccupyFreeSpace />
          
            <Tooltip key="is_urgent" text="Срочное (немедленное email-уведомление)">
              <IconButton
                size="small"
                color={data.item.is_urgent ? "secondary" : "default"}
                onClick={() => data.update({ is_urgent: !data.item?.is_urgent })}>
                <NotificationsOutlined />
              </IconButton>
            </Tooltip>

            <Tooltip key="is_require_attention" text="Важное (подсветить в ленте)">
              <IconButton
                size="small"
                color={data.item.is_require_attention ? "secondary" : "default"}
                onClick={() => data.update({ is_require_attention: !data.item?.is_require_attention })}>
                <WarningOutlined />
              </IconButton>
            </Tooltip>
          </Buttons>

          {data.item.isExplicitMembers &&
            <FormGrid columns="1fr" gap="dense">
              {data.members.map(m => (
                <Checkbox
                  key={m.member_id}
                  field="is_selected"
                  row={m}
                  onChange={(o,c) => data.setMemberSelection(m.member_id, c.is_selected)}
                  schema={{ label: m.name }}
                  extraProps={{ label: m.name }}
                  />
              ))}
            </FormGrid>}


        </FormGrid>}
    </Dialog>
  </>
}