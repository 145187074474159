import { useState } from "react";
import { useHistory } from "react-router-dom";
import { login, useUser } from "../../userContext/UserContext";
import { useSendData } from "../useSendData";
import { usePasswordCheck } from "./useCommonSignup";
import { PremadeCredentials, Signup, SignupCommonProps, SignupCredentials } from "./useSignup";

export interface UseInviteSignupProps extends SignupCommonProps {
    creds?: PremadeCredentials;
}

export const useInviteSignup = ({ signupApiPath, loginOnSuccess, redirectPath, creds }: UseInviteSignupProps): Signup => {
    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const user = useUser();

    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);

    const queryEmail = urlParams.get("email");
    const queryCode = urlParams.get("code");

    if (queryEmail && queryCode) {
        creds = { email: queryEmail, code: queryCode };
    }

    const { data, update, send, isSaving } = useSendData<SignupCredentials, string>(signupApiPath, { login: creds?.email || '', password: '', passwordRepeat: '', inviteCode: creds?.code }, {
        apiExtraParams: { noAuth: true },
        onSuccess: (result: string) => {
            if(loginOnSuccess) {
                login(user, result);
            }
            if (redirectPath) {
                history.push(redirectPath);
            }
        }
    })

    const validatePasswords = usePasswordCheck(data);

    const updateWrapper = (changes: SignupCredentials) => {
        if (creds) {
            changes = { ...changes, login: creds.email, inviteCode: creds.code }
        }

        update(changes);
    }

    const sendWrapper = () => {
        if (!validatePasswords()) {
            return new Promise<void>(_ => {});
        }
        
        setIsError(false);

        return send()
            .catch(er => {
                setIsError(true);
                throw er;
            })
            .then(x => { setIsSubmitted(true); return x; });
    }

    return {
        credentials: data,
        update: updateWrapper,
        send: sendWrapper,
        isSaving,
        isError,
        isSubmitted,
    }
}