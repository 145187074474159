import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LocaleSwitch } from '../../LocalizedRecords';
import { Form, OccupyFreeSpace, LoadingIndicator, SearchField, useWindowHotkey, Link, Buttons } from '../../primitives';
import { FieldDefinition, TableForFields } from '../../schemed';
import { SiteLink } from '../Elements/SiteLink';
import { getRecordLink } from '../util';
import { NewRecordPopup } from './NewRecordPopup';
import { LangAll, LangGroupBySlug, LangLinkedTranslations, RecordsListData } from './useRecordsList';

interface Props {
    data: RecordsListData;
}

export const RecordsList = (props: Props) => {
    const { config, lang, setLang, hasLanguages, schema, isLoading, isConfigLoading, data } = props.data;

    let { path } = useRouteMatch();
    const history = useHistory();
    useWindowHotkey("alt+n", () => props.data.newItem.startEditing());

    const languages = hasLanguages && config && !config['translations-in-place']
      ? [
        [LangAll, <FormattedMessage id="cms.list.all_languages" />],
        ...(config.languages || []).map(l => ([l, l])),
        props.data.translationsGrouping.translationLink
          ? [LangLinkedTranslations, <FormattedMessage id="cms.list.languages_group_slug" />]
          : [LangGroupBySlug, <FormattedMessage id="cms.list.languages_group_slug" />],
      ] as [string, ReactNode][]
      : [];

    const cycleLanguage = useCallback(() => {
      const langCodes = hasLanguages && config.languages && !config['translations-in-place']
        ? [LangAll, ...config.languages]
        : [];
      if(langCodes.length) {
        const currentIdx = langCodes.indexOf(lang || LangAll);
        const nextIdx = (currentIdx + 1) % langCodes.length;
        setLang(langCodes[nextIdx]);
      }
    }, [hasLanguages, config, lang, setLang]);

    const { groupedBySlug, groupedByTranslationLinks } = props.data.translationsGrouping;

    useWindowHotkey("mod+alt+l", cycleLanguage);

    return (<>
        <Form title={isConfigLoading ? "" : config.label || config.record_type}
            formHeaderProps={{ style: { alignItems: "flex-end" }}}
            headerItems={<>
                <IconButton size="small" color="primary" onClick={() => props.data.newItem.startEditing()}>
                    <Add />
                </IconButton>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator />}
                <SiteLink record={{ record_type: props.data.recordType, lang }} />
                <SearchField
                    filter={props.data.filter}
                    setFilter={props.data.setFilter}
                    autoFocus
                    noButton
                    small
                    doSearch={() => {
                        if(data && data.length === 1) {
                            const r = data[0];
                            history.push(`${getRecordLink(path, r)}${r._id}`);
                        }
                    }}
                    />
                {hasLanguages && !config['translations-in-place'] &&
                    <LocaleSwitch
                        availableLocales={languages}
                        locale={lang || ""}
                        setLocale={setLang}
                        />}
            </>}>
            
            {lang !== LangGroupBySlug && lang !== LangLinkedTranslations &&
              <TableForFields
                data={data}
                schema={schema}
                fields={[
                    ["_id"],
                    ["title"],
                    ["slug"],
                    hasLanguages ? ["lang"] : null,
                    ["record_datetime", { utcToLocal: true }],
                    ["published_datetime", { utcToLocal: true }],
                    ["created_datetime", { utcToLocal: true }],
                ]}
                sorting={props.data.sorting}
                fieldLink={f => {
                    switch(f) {
                        case "_id":
                        case "title":
                        case "slug":
                            return r => `${getRecordLink(path, r)}${r._id}`;
                    }
                }}
                autoexpander={{ initialRows: 20, increment: 20 }}
                />}

            {lang === LangGroupBySlug &&
              <TableForFields
                data={groupedBySlug}
                schema={schema}
                fields={[
                    ["slug"],
                    ...(config.languages || []).map(l => ([l, { label: l }] as FieldDefinition)),
                ]}
                sorting={props.data.sorting}
                fieldElement={f => {
                  if(f !== "slug") {
                    return rGroup => {
                      const r = rGroup.byLang[f || ""];
                      return r
                        ? <Link to={`${getRecordLink(path, r)}${r._id}`}>
                          {r?.title || ""} {!config['disable-drafts'] && r?.is_published ? "✅" : ""}
                        </Link>
                        : <Buttons>
                          <IconButton
                            size="small"
                            disabled={!props.data.translationsGrouping.createInOtherLanguage.canRun}
                            onClick={() => {
                              return props.data.translationsGrouping.createInOtherLanguage.run({
                                record: rGroup.byLang[(config.languages || [])[0] || ""] || rGroup,
                                lang: f
                              }).then(result => history.push(`${getRecordLink(path, result)}${result._id}`))
                              }}>
                            <Add />
                          </IconButton>
                        </Buttons>;
                    };
                  }
                }}
                autoexpander={{ initialRows: 20, increment: 20 }}
                />}

            {lang === LangLinkedTranslations &&
              <TableForFields
                data={groupedByTranslationLinks}
                schema={schema}
                fields={[
                    ...(config.languages || []).map(l => ([l, { label: l }] as FieldDefinition)),
                ]}
                sorting={props.data.sorting}
                fieldElement={f => {
                  return rGroup => {
                    const r = rGroup[f || ""];
                    return r
                      ? <Link to={`${getRecordLink(path, r)}${r._id}`}>
                        {r?.title || ""} {!config['disable-drafts'] && r?.is_published ? "✅" : ""}
                      </Link>
                      : <Buttons>
                        <IconButton
                          size="small"
                          disabled={!props.data.translationsGrouping.createInOtherLanguage.canRun}
                          onClick={() => {
                            return props.data.translationsGrouping.createInOtherLanguage.run({
                              record: rGroup[(config.languages || [])[0] || ""] || rGroup[(config.languages || []).find(l => !!rGroup[l]) || ""],
                              lang: f
                            }).then(result => history.push(`${getRecordLink(path, result)}${result._id}`))
                            }}>
                          <Add />
                        </IconButton>
                      </Buttons>;
                  };
                }}
                autoexpander={{ initialRows: 20, increment: 20 }}
                />}
        </Form>

        <NewRecordPopup
            data={props.data.newItem}
            recordConfig={config}
            schema={schema}
            onCreated={record => history.push(`${getRecordLink(path, record)}${record._id}`)}
            />
    </>);
}
