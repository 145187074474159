import { useRef } from "react";
import { apiFetch, apiFetchFile } from "../../../../toolympus/api/core";
import { collectDocImagesByUrls, collectDocImagesUrls, PowerDoc } from "../../../../toolympus/components/PowerDoc";
import { generateDocx, NodeProcessor, packDocx } from "../../../../toolympus/components/PowerDoc/docx";
import { entitySetExpressionProcessor, processPowerDocTemplate } from "../../../../toolympus/components/PowerDoc/template";
import { SchemaFormatter, useSchemaFormatter } from "../../../../toolympus/components/schemed";
import { FieldType, Schema, useSchema } from "../../../../toolympus/hooks/useSchema";
import { Case } from "../../../../typings/Cases";
import { readDocumentSettings } from "../../../Cases/caseData/documentSettings";
import { useSettings } from "../../../SettingsPage";
import { PresidiumQuestion } from "../usePresidiumQuestions";
import { sanitizeFilename } from "../../../../toolympus/api/files";
import { PresidiumResponse } from "../usePresidiumResponses";
import { PresidiumQuestionResult } from "../usePresidiumQuestionResult";
import { pluralizeRu } from "../../../../toolympus/api/pluralize";
import { ArbitratorSelection } from "../usePresidiumQuestionArbitratorsSelection";

interface QuestionResultInfo {
  selected_fullname: string;
  selected_fullname_short: string;
  voted_nbr_text: string;
  abstained_nbr_text: string;
  assigning: string;
  rules_article: string;
}

interface QuestionResultInfoWithEn extends QuestionResultInfo {
  en?: QuestionResultInfo;
}

interface DocData {
  case: Case;
  question: PresidiumQuestion;
  result_info: QuestionResultInfoWithEn;
}

const createCustomProcessors = (data: DocData, formatter: SchemaFormatter): NodeProcessor[] => {
  return [
    // createCaseDocumentFooterProcessor(data as any, {}),
  ];
}

const createExpressionProcessor = (data: DocData, schemas: Record<string,Schema>, formatter: SchemaFormatter) => {
  return entitySetExpressionProcessor(
    {
        ...data,
        date: {
            today: new Date(),
        },
    },
    (entity: string, field: string, value: any) => {
        if(entity === "case") {
            return formatter(value, (schemas.case || {})[field]);
        } else if(entity === "date") {
            return formatter(value, { type: FieldType.date });
        } else if(entity === "question") {
          return formatter(value, (schemas.presidium_question || {})[field]);
        }
        return value;
    }
  );
}

type MaybeStr = string | null | undefined;

const getFullnames = (firstname: MaybeStr, middlename: MaybeStr, lastname: MaybeStr) => {
  const full = [firstname, middlename, lastname].filter(x => !!x).join(" ");
  const shortened = [
    ...[firstname, middlename].filter(x => !!x).map(x => `${x?.substring(0,1)}.`),
    lastname,
  ].filter(x => !!x).join(" ");

  return { full, shortened };
}

const getAssigningWhoMessageKey = (question: PresidiumQuestion): string => {
  if(question.attributes?.jury_type === "multiple") {
    switch(question.attributes?.assigning) {
      case "side_claimant":
        return "multiple_side_claimant";
      case "side_respondant":
        return "multiple_side_respondant";
      default:
        return "multiple_presiding";
    }
  } else {
    return "single";
  }
}

const generateResultInfo = (messages: Record<string,string>, question: PresidiumQuestion, selected: ArbitratorSelection | undefined, participated: number, abstained: number) => {
  const names = getFullnames(selected?.firstname, selected?.middlename, selected?.lastname);
  const namesEn = getFullnames(selected?.firstname_en, selected?.middlename_en, selected?.lastname_en);
  const assigningWho = getAssigningWhoMessageKey(question);
  const assigning = `assigning_text_${assigningWho}`;
  const rulesArticle = `rules_article_${assigningWho}`;

  const result_info: QuestionResultInfo = {
    voted_nbr_text: 
      `${participated} ${pluralizeRu(participated, "член", "члена", "членов")} Президиума ${pluralizeRu(participated, "принял", "приняли", "приняли")} участие в заседании.`,
    abstained_nbr_text: abstained
      ? `${abstained} ${pluralizeRu(abstained, "член", "члена", "членов")} Президиума ${pluralizeRu(abstained, "воздержался", "воздержались", "воздержались")} от голосования.`
      : "",
    selected_fullname: names.full,
    selected_fullname_short: names.shortened,
    assigning: messages[assigning] || assigning,
    rules_article: messages[rulesArticle] || rulesArticle,
  }

  const result_info_en: QuestionResultInfo = {
    voted_nbr_text: 
      `${participated} ${pluralizeRu(participated, "member", "members")} of the Board participated in the voting.`,
    abstained_nbr_text: abstained
      ? `${abstained} ${pluralizeRu(abstained, "member", "members")} of the Board abstained from voting.`
      : "",
    selected_fullname: namesEn.full,
    selected_fullname_short: namesEn.shortened,
    assigning: messages[`en_${assigning}`] || `en_${assigning}`,
    rules_article: messages[`en_${rulesArticle}`] || `en_${rulesArticle}`,
  }


  return {
    ...result_info,
    en: result_info_en,
  };
}

const collectData = (question: PresidiumQuestion, messages: Record<string,string>): Promise<DocData> => {
  return Promise.all([
    apiFetch<Case>(`/api/case/${question.case_id}`, "get"),
    apiFetch<PresidiumResponse[]>(`/api/presidium/question/${question._id}/response`, "get"),
    apiFetch<PresidiumQuestionResult>(`/api/presidium/question/${question._id}/result`, "get"),
  ]).then(([c,responses,result]) => {
    const selected = result.candidates?.find(c => c.is_approved);
    const abstained = responses.filter(r => r.is_abstained);
    const participated = responses.filter(r => r.is_voted);

    const result_info: QuestionResultInfo = generateResultInfo(messages, question, selected, participated.length, abstained.length)

    return {
      case: c,
      question,
      result_info,
    } as any;
  });
}

const getDynamicMessagesFromTemplate = (template: PowerDoc) => {
  return (template?.content?.blocks?.find(b => (b as any).type === "settings_block" && (b as any).block_type === "messages") || {}) as any as Record<string, string>;
}

export const usePrepareBoardStatementDocx = () => {
  const schemas = useSchema();
  const formatter = useSchemaFormatter();
  const { settings } = useSettings();

  const templatesStore = useRef<{ doc: PowerDoc, docInternational: PowerDoc, dotx: Blob | null } | null>(null);


  const ensureTemplatesLoaded = async () => {
    if(templatesStore.current) {
      return templatesStore.current;
    } else {
      const template = await apiFetch<PowerDoc>(`/api/powerdoc/doc/${settings.board_statement_common_template_id}`, "get");
      const templateInternational = await apiFetch<PowerDoc>(`/api/powerdoc/doc/${settings.board_statement_international_template_id}`, "get");
      const dotxTemplate = await getDotxTemplate();
      const templates = { doc: template, docInternational: templateInternational, dotx: dotxTemplate };
      templatesStore.current = templates;
      return templates;
    }
  }


  const getDotxTemplate = (): Promise<Blob | null> => settings.document_generator_dotx_url
    ? apiFetchFile(settings.document_generator_dotx_url)
    : Promise.resolve(null);
        
  const generateDocument = async (question: PresidiumQuestion) => {
    const templates = await ensureTemplatesLoaded();
    const template = question.committee === "international" ? templates.docInternational : templates.doc;
    const messages = getDynamicMessagesFromTemplate(template);
    const data = await collectData(question, messages);
    const expressionProcessor = createExpressionProcessor(data, schemas, formatter);
    const customProcessors = createCustomProcessors(data, formatter);

    
    const doc = processPowerDocTemplate(template, expressionProcessor);
    const content = doc.content?.blocks || [];
    const images = await collectDocImagesByUrls([ ...collectDocImagesUrls(content), settings.document_generator_signature_url || ""]);

    const docx = await generateDocx(
      { getImage: url => images[url], customProcessors },
      content,
      templates.dotx,
      readDocumentSettings(content)
    );
    const filename = sanitizeFilename(`Постановление президиума ${expressionProcessor?.getFormattedValue("case.casenbr") || ""}.docx`);
    const packed = await packDocx(docx);
    const file = new File([packed], filename);
    return { file, filename };
  }

  return {
    generate: generateDocument,
  }
}
