import { ArrowDropDown } from '@mui/icons-material';
import { TextField, Autocomplete as MuiAutocomplete, InputAdornment } from '@mui/material';
import React, { ReactNode } from 'react'


interface Props {
  label: ReactNode;
  value: string;
  onChange: (v: string) => void;
  suggestions: string[];
  openOnFocus?: boolean;
  noClear?: boolean;
}


export const Autocomplete = (props: Props) => {
  return (
    <MuiAutocomplete 
      freeSolo
      fullWidth
      openOnFocus={props.openOnFocus}
      clearIcon={props.noClear ? null : undefined}
      disableClearable={props.noClear}
      options={props.suggestions}
      value={props.value}
      onChange={(_,v) => props.onChange(v || "")}
      onInputChange={(_,v) => props.onChange(v || "")}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <InputAdornment position="end"><ArrowDropDown /></InputAdornment>
          }}
        />
      )}
    />
  )
}
