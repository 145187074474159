import React from 'react';
import { EmptyState, Form, FormGrid, Link, LoadingIndicator, OccupyFreeSpace, SaveButton, useSaveable } from '../primitives';
import { Button, IconButton } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { useNumberingSequencesEdit } from './useNumberingSequencesEdit';
import { Add } from '@mui/icons-material';
import { NewNumberingSequenceDialog } from './NewNumberingSequenceDialog';
import { FormattedMessage } from 'react-intl';
import { NumberingSequenceSelector } from './NumberingSequenceSelector';
import { useItemWithControls } from '../../api/useWithControls';
import { NumberingSequenceEvents } from './NumberingSequenceEvents';
import { PluggableDocumentationButton } from '../Documentation';

interface Props {
  apiPath: string;
  usersDictionary?: string;
  documentationKey?: string;
}

export const NumberingSequencesEditPage = (props: Props) => {
  let { path } = useRouteMatch();

  const data = useNumberingSequencesEdit(props.apiPath);
  useSaveable(data.item);

  const { controls } = useItemWithControls(data.item, { schema: data.schema });
  
  return (
    <Form
      title={<FormattedMessage id="numbering.page_title" />}
      headerItems={
        <>
          {data.allSequences.isLoading && <LoadingIndicator sizeVariant="s" />}
          <OccupyFreeSpace />
          {data.item.hasChanges && <SaveButton action={() => data.item.save()} />}
          <IconButton color="primary" onClick={() => data.newItem.startEditing()}><Add /></IconButton>
          
          <Link to={`${path.replace("/edit", "")}`} ><Button size="small"><FormattedMessage id="numbering.to_view" /></Button></Link>
          {props.documentationKey && <PluggableDocumentationButton documentationKey={props.documentationKey} />}
        </>
      }>
      
      <NumberingSequenceSelector
        available={data.allSequences.data}
        currentId={data.currentId.value ? +data.currentId.value : null}
        setCurrentId={v => data.currentId.update(v ? v.toString() : "")}
        />

      {!data.currentId.value && <EmptyState text={<FormattedMessage id="numbering.empty_select_or_create_sequence" />} />}

      {!!data.currentId.value &&
        <>
          <FormGrid>
            {controls([
              ["title", { wrapperStyle: { gridColumn: "1 / span 2" }}],
              ["is_active"],
              ["format"],
              ["last_number"],
            ])}
          </FormGrid>

          <NumberingSequenceEvents data={data.events} usersDictionary={props.usersDictionary} />
        </>}

      <NewNumberingSequenceDialog data={data.newItem} schema={data.schema} />
    </Form>
  );
}
