import React, { PropsWithChildren, useContext, useState } from 'react';
import { BackgroundOption, BackgroundOptionDefault, BackgroundOptions } from './BackgroundOptions';


export interface IPersonalizationServiceContext {
  background: string;
  backgroundOption: BackgroundOption;
  setBackground: (name: string) => void;
}

const PersonalizationServiceContext = React.createContext<IPersonalizationServiceContext>({
  background: BackgroundOptionDefault.name,
  setBackground: () => {},
  backgroundOption: BackgroundOptionDefault,
});

export const usePersonalizationService = () => useContext(PersonalizationServiceContext);

const BackgroundLSKey = "__raca_background";

export const PersonalizationServiceContextProvider = (props: PropsWithChildren<{}>) => {
  const [background, setBackgroundX] = useState<string>(() => localStorage.getItem(BackgroundLSKey) || BackgroundOptionDefault.name);

  const backgroundOption = BackgroundOptions.find(o => o.name === background) || BackgroundOptionDefault;

  const setBackground = (name: string) => {
    setBackgroundX(name);
    localStorage.setItem(BackgroundLSKey, name);
  }

  return (
    <PersonalizationServiceContext.Provider value={{ background, setBackground, backgroundOption }}>
      {props.children}
    </PersonalizationServiceContext.Provider>
  );
}
