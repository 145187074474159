import { useState } from "react"
import { apiFetch, apiFetchFile } from "../../api/core"
import { useCrudItem } from "../../api/useSimpleCrud"
import { useDialogState } from "../primitives"
import { useLoadedData } from "../../hooks/useLoadedData"
import { collectNodes, generateDocx, saveDocx } from "./docx"
import { entitySetExpressionProcessor, ExpressionProcessor, processPowerDocTemplate } from "./template";
import { PowerDoc } from "./types";
import { ImageElementType } from "./plugins/InlineImages"

export interface RecordPowerDocsConfig {
    expressionProcessor: ExpressionProcessor;
    dotxTemplateUrl?: string;
}

const DefaultExpressionProcessor: ExpressionProcessor = entitySetExpressionProcessor({}, () => "");

export const collectDocImagesByUrls = (urls: string[]): Promise<Record<string, ArrayBuffer>> => {
  const uniqueUrls = Array.from(new Set((urls || []).filter(url => !!url)));
  const imagesDownloaded = uniqueUrls.map(url => {
    try {
      return apiFetchFile(url).then(blob => blob.arrayBuffer()).then(arrayBuffer => [url, arrayBuffer]) as Promise<[string, ArrayBuffer | null]>;
    } catch(_) {
      return Promise.resolve<[string, ArrayBuffer | null]>([url, null]);
    }
  });

  return Promise.all(imagesDownloaded)
    .then(imageBlobs => imageBlobs.reduce<Record<string, ArrayBuffer>>(
      (r,[url,blob]) => {
          if(blob) {
              r[url] = blob;
          }
          return r;
      },
      {}));
}

export const collectDocImagesUrls = (content: any): string[] => {
  const imageNodes = collectNodes(content, n => n.type === ImageElementType);
  return imageNodes.map(img => img.url as string);
}

export const collectDocImages = (content: any[]): Promise<Record<string, ArrayBuffer>> => {
  return collectDocImagesByUrls(collectDocImagesUrls(content));
}

export const useRecordPowerDocsList = <T extends PowerDoc = PowerDoc>(apiPath: string, entity: string, recordId: string, cfg?: Partial<RecordPowerDocsConfig>) => {
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const createDialog = useDialogState();

    const documents = useLoadedData<T[]>(
        `${apiPath}?view=for-record&entity=${entity}&record_id=${recordId}`,
        [],
        !!recordId);

    const templates = useLoadedData<T[]>(
        `${apiPath}`,
        [],
        createDialog.isOpen,
    );

    const editDocument = useEditRecordPowerDoc<T>(apiPath);

    const createDocument = (template: T) => {
        apiFetch<T>(apiPath, "post", {
            title: template.title,
            content: template.content,
            entity: entity,
            record_id: recordId,
        }).then(() => documents.reload());
    }

    const removeDocument = (id: string) => {
        apiFetch(`${apiPath}/${id}`, "delete")
            .then(() => documents.reload());
    }

    const getTemplate = (): Promise<Blob | null> => cfg?.dotxTemplateUrl
        ? apiFetchFile(cfg.dotxTemplateUrl)
        : Promise.resolve(null);

    const generateDocument = (id: string) => {
        setIsGenerating(true);
        apiFetch<T>(`${apiPath}/${id}`, "get")
            .then(template => {
                return getTemplate()
                    .then(dotxTemplate => {
                        const doc = processPowerDocTemplate(template, cfg?.expressionProcessor || DefaultExpressionProcessor);
                        const content = doc.content?.blocks || [];
                        return collectDocImages(content)
                            .then(images => {
                                return generateDocx({ getImage: url => images[url] }, content, dotxTemplate)
                                    .then(file => saveDocx(file, `${doc.title}.docx`));;
                            });
                    })
            })
            .then(() => { setIsGenerating(false); })
            .catch(() => { setIsGenerating(false); });
    }

    return {
        documents,
        templates,
        createDialog,
        createDocument,
        removeDocument,
        generateDocument,
        isGenerating,
        
        editDocument,
    }
}

export type RecordPowerDocsData = ReturnType<typeof useRecordPowerDocsList>;


const useEditRecordPowerDoc = <T extends PowerDoc = PowerDoc>(apiPath: string, defaultDocumentId?: string) => {
    const [documentId, setDocumentId] = useState<string | undefined>(defaultDocumentId);

    const data = useCrudItem<T>(`${apiPath}/${documentId}`, {
        defaultValue: {} as T,
        noLoad: !documentId,
    });

    return {
        ...data,
        open: (documentId: string) => setDocumentId(documentId),
        close: () => setDocumentId(undefined),
        isOpen: !!documentId,
        documentId,
    };
}

export type EditRecordPowerDocData = ReturnType<typeof useEditRecordPowerDoc>;
