import React from 'react';
import { Button, Divider } from '@mui/material';
import { FileEdit } from '../../../toolympus/components/files/FileEdit';
import { Dialog } from '../../../toolympus/components/primitives/Dialogs';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { ArbitratorSelection } from './usePresidiumQuestionArbitratorsSelection';
import { FormGrid } from '../../../toolympus/components/primitives/Forms';

interface CandidateDetailsDialogProps {
    isOpen: boolean;
    close: () => void;
    candidate: ArbitratorSelection | null;
    update: (changes: Partial<ArbitratorSelection>) => void;
    saveChanges: (changes: Partial<ArbitratorSelection>) => void;
    hasChanges: boolean;
    changes: Partial<ArbitratorSelection>;
    questionId: string;
    canEdit?: boolean;
    infoFileEdit?: boolean;
    title?: string;
}

export const CandidateDetailsDialog = (props: CandidateDetailsDialogProps) => {
    const {
        isOpen,
        close,
        candidate,
        update,
        saveChanges,
        canEdit,
        changes,
        hasChanges,
        infoFileEdit,
        title,
    } = props;

    const { arbitrator_suggestion: schema } = useSchema();

    return (
        <Dialog
            dialogTitle={title || "Раскрытие обстоятельств"}
            isOpen={isOpen}
            close={close}
            noFullscreen
            actions={<>
                <Button key="close" onClick={close}>отмена</Button>
                <Button
                    key="save"
                    color="primary"
                    variant="contained"
                    disabled={!hasChanges}
                    onClick={() => {
                        saveChanges(changes);
                        close();
                    }}>
                    сохранить
                </Button>
            </>}>
            {candidate && (<FormGrid columns="1fr" noMargin>
                
                <FormControlsForFields
                    key="name"
                    data={{ ...candidate, fullname: `${candidate.lastname} ${candidate.firstname} ${candidate.middlename}`}}
                    fields={[
                        ["fullname", { label: "ФИО", readOnly: true }],
                        ["description", {}],
                    ]}
                    schema={schema}
                    onChange={(o,c) => update(c)}
                    />

                {infoFileEdit && <FileEdit
                    fileId={candidate.document_id}
                    updateFileId={docId => {
                        update({ document_id: docId });
                        if(candidate) {
                            saveChanges({ document_id: docId });
                        }
                    }}
                    label="Справка (файл)"
                    disabled={!canEdit}
                    config={{
                        apiPath: `/api/presidium/question/${props.questionId}/documents`,
                        uploadParams: { unlisted: "true" },
                    }}
                    />}

                <Divider style={{ marginTop: "1rem" }} />

                <FormGrid columns="1fr 1fr">
                    <FormControlsForFields
                        key="confirmation"
                        data={candidate}
                        fields={[
                            ["confirmation_status", { disabled: !canEdit }],
                            ["confirmation_date", { disabled: !canEdit }],
                        ]}
                        schema={schema}
                        onChange={(o,c) => update(c)}
                        />
                </FormGrid>

                <FormControlsForFields
                    key="disclosure_details"
                    data={candidate}
                    fields={[
                        ["disclosure_details", { autoRows: true, disabled: !canEdit }]
                    ]}
                    schema={schema}
                    onChange={(o,c) => update(c)}
                    />

                <FileEdit
                    fileId={candidate.disclosure_document_id}
                    updateFileId={docId => {
                        update({ disclosure_document_id: docId });
                        if(candidate) {
                            saveChanges({ disclosure_document_id: docId });
                        }
                    }}
                    label="Документ по раскрытию"
                    disabled={!canEdit}
                    config={{
                        apiPath: `/api/presidium/question/${props.questionId}/documents`,
                    }}
                    />
            </FormGrid>)}
        </Dialog>);
}