import React, { useEffect, useMemo, useState } from 'react';
import { useUser } from '../../../userContext/UserContext';
import { useUserSettings } from '../../Settings/UserSettingsContext';
import { Badge, IconButton } from '@mui/material';
import { BookOutlined } from '@mui/icons-material';
import { Buttons, Dialog, EmptyState, FormGrid, LoadingIndicator, PseudoLink2, SimpleDialog, useDialogState } from '../../primitives';
import { DocumentationDoc, useDocumentationPage } from '../useDocumentationPages';
import { useLoadedData } from '../../../hooks/useLoadedData';
import { useEditItem2 } from '../../../api/useNewItem';
import { FormControlsForFields } from '../../schemed';
import { createSelectSchema } from '../../../hooks/useSchema';
import { FormattedMessage } from 'react-intl';
import { DocumentationEditor } from '../DocumentationEditor';
import { PluggableDocumentationSettings, usePluggableDocumentationContext } from './PluggableDocumentationContext';

interface PropsUnbound {
  documentationKey: string;
}

interface PropsFull extends PluggableDocumentationSettings, PropsUnbound {
}

export const DocumentationPagesSettingKey = "_tl_page_docs";

export const PluggableDocumentationButtonUnbound = (props: PropsFull) => {
  const settings = useUserSettings();
  const { user } = useUser();
  const canManage = !!user && props.canManage(user);
  const dialog = useDialogState();
  const [doLoad,setDoLoad] = useState<boolean>(false);
  useEffect(() => {
    setDoLoad(true);
  }, [dialog.isOpen]);

  useEffect(() => {
    settings.ensureLoaded();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const pagesKeys = (settings.global || {})[DocumentationPagesSettingKey] || {};
  const documentId = pagesKeys[props.documentationKey];
  const selecting = useEditItem2<{ page_id: string | null}>({
    save: item => {
      return settings.updateGlobal({ [DocumentationPagesSettingKey]: { ...pagesKeys, [props.documentationKey]: item.page_id }})
        .then(() => item);
    }
  });
  
  const docData = useDocumentationPage(props.apiPath, doLoad ? documentId : "");
  
  const remove = () => {
    const updated = { ...pagesKeys };
    delete updated[props.documentationKey];
    settings.updateGlobal({ [DocumentationPagesSettingKey]: updated });
  }



  const pageSelection = useLoadedData<DocumentationDoc[]>(props.apiPath, [], selecting.isEditing);

  const selectionSchema = useMemo(() => {
    return {
      page_id: createSelectSchema(pageSelection.data.map(d => ({ value: d._id, label: d.title })), { label_id: "documentation.plugged.selection.page_id"})
    }
  }, [pageSelection.data]);

  if(!settings.isAvailable || !(documentId || canManage)) {
    return null;
  }

  const Editor = props.editor || DocumentationEditor;

  return (
    <>
      <IconButton size="small" onClick={() => dialog.open()}>
        <Badge badgeContent="" variant="dot" invisible={!!documentId} color="primary">
          <BookOutlined />
        </Badge>
      </IconButton>

      {dialog.isOpen &&
        <Dialog
          maxWidth="md"
          fullWidth
          isOpen={dialog.isOpen}
          close={dialog.close}
          dialogTitle={docData.data.title || <FormattedMessage id="documentation.plugged.title_stub" />}>
          {!!canManage &&
            <Buttons>
              {!!documentId && <PseudoLink2 color="inherit" onClick={() => remove()}>
                <FormattedMessage id="documentation.plugged.remove" />
              </PseudoLink2>}

              {!!documentId && <PseudoLink2 onClick={() => selecting.startEditing({ page_id: null })}>
                <FormattedMessage id="documentation.plugged.replace" />
              </PseudoLink2>}
            </Buttons>}


          {!documentId &&
            <EmptyState
              text={<FormattedMessage id="documentation.plugged.empty_state" />}
              onClick={() => selecting.startEditing({ page_id: null })}
              />}

          {!!documentId && (docData.isLoading || settings.isLoadingGlobal) &&
            <LoadingIndicator />}

          {!!documentId && !docData.isLoading &&
            <Editor
              key={docData.data._id}
              content={docData.data.content}
              update={v => {}}
              viewMode
              />}

        </Dialog>}

      {selecting.isEditing &&
        <SimpleDialog
          dialogTitle={<FormattedMessage id="documentation.plugged.selection.page_id" />}
          save={() => {
            if(selecting.item?.page_id) {
              selecting.save();
            }
          }}
          isOpen={selecting.isEditing}
          close={() => selecting.cancel()}
          maxWidth="sm"
          fullWidth
          noFullscreen
        >
          {!!selecting.item &&
            <FormGrid columns="1fr">
              <FormControlsForFields
                fields={[
                  ["page_id", { autoComplete: true }]
                ]}
                data={selecting.item}
                onChange={(o,c) => selecting.update(c)}
                schema={selectionSchema}
                />
            </FormGrid>}
        </SimpleDialog>}
    </>
  );
}

export const PluggableDocumentationButton = (props: PropsUnbound) => {
  const ctx = usePluggableDocumentationContext();

  return (
    <PluggableDocumentationButtonUnbound
      apiPath={ctx.apiPath}
      canManage={ctx.canManage}
      editor={ctx.editor}
      documentationKey={props.documentationKey}
      />
  )
}