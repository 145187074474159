import { useCallback, useEffect, useState } from "react";
import { apiFetch, FetchTypes } from "../../toolympus/api/core";
import { apiUploadFile } from "../../toolympus/api/files";
import { FieldFilters, useFieldFilters } from "../../toolympus/components/schemed/Filtering";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { FieldSorting, useFieldSorting } from "../../toolympus/hooks/useFieldSorting";
import { LoadedData, useLoadedData } from "../../toolympus/hooks/useLoadedData"
import { useQuery } from "../../toolympus/hooks/useQueryParamsState";
import { useSchema } from "../../toolympus/hooks/useSchema";
import { SaveableData } from "../Cases/caseData";

export interface PracticeItem {
    _id: string,
    short_id: string;
    is_published: true,
    casenbr?: string;
    case_id?: string;
    case_casenbr?: string;
    practice_casenbr?: string;
    document_type: string,
    description: string,
    document_date: string,
    procedure: string,
    rules: string,
    place_city: string,
    place_city_en: string;
    place_country: string,
    other_info: string[];
    file_url?: string | null;
    file_url_en?: string | null;
    arbitrators_consent: Record<string, { _id: string; is_available: boolean; name?: string }>;
}

export enum PracticeFilter {
    Published = "published",
    Drafts = "drafts",
    All = "all",
}

export interface PracticeListData extends LoadedData<PracticeItem[]>{
    filter: string;
    setFilter: (v: string) => void;
    applyFilter: () => void;
    listtype: PracticeFilter;
    setListType: (v: PracticeFilter) => void;
    sorting: FieldSorting;
    filtering: FieldFilters;
};

export const usePracticeList = (): PracticeListData => {
    const query = useQuery();
    
    const [listtype, setListType] = useState<PracticeFilter>((query.get('type') || 'all') as PracticeFilter);

    const sorting = useFieldSorting({
        disallowedFields: ["arbitrators"],
    });

    const data = useLoadedData<PracticeItem[]>(`/api/practice?view=${listtype}&${sorting.queryParameter}`, []);
    const filtering = useFieldFilters(useSchema().practice, data.data, { storageKey: 'filters_practice'});

    const { filter, setFilter, filterData } = useTextFilter<PracticeItem>(p => `${p.case_casenbr} ${p.practice_casenbr} ${p.description} ${Object.values(p.arbitrators_consent || {}).map(p => p.name || "").join(" ")}`);

    return {
        filter,
        setFilter,
        applyFilter: () => {},
        listtype,
        setListType,
        sorting,
        filtering,
        ...data,
        data: filterData(data.data.filter(filtering.filterFn)),
    }
}

export interface NewPracticeData {
    data: Partial<PracticeItem>;
    update: (newValues: Partial<PracticeItem>) => void;
    submit: () => Promise<PracticeItem>;
    isActive: boolean;
    setIsActive: (v: boolean) => void;
    isSaving: boolean;
}

export const useNewPracticeData = (): NewPracticeData => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [data, setData] = useState<Partial<PracticeItem>>({});

    const update = (newValues: Partial<PracticeItem>) => setData({ ...data, ...newValues });

    const submit = () => {
        setIsSaving(true);
        return apiFetch<PracticeItem>('/api/practice', FetchTypes.POST, { ...data, case_casenbr: undefined })
            .then(data => { setIsSaving(false); return data })
            .catch(e => { setIsSaving(false); throw e; });
    }
    
    return {
        isActive,
        isSaving,
        setIsActive,
        data,
        update,
        submit,
    }
}

export interface PracticeItemData extends SaveableData<PracticeItem> {
    uploadDocument: (file: any, lang: string) => void;
    deleteDocument: (lang: string) => void;
    isDocumentUploading: boolean;
    remove: () => Promise<any>;
}

export const usePracticeItem = (id: string): PracticeItemData => {
    const [item, setItem] = useState<PracticeItem>({ _id: "" } as PracticeItem);
    const [changes, setChanges] = useState<{ [k: string]: any}>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDocumentUploading, setIsDocumentUploading] = useState<boolean>(false);

    const load = useCallback(() => {
        if(!id) {
            return;
        }
        setIsLoading(true);
        apiFetch<PracticeItem>(`/api/practice/${id}`).then(setItem)
            .then(() => setIsLoading(false))
            .catch(e => { setIsLoading(false); throw e; });
    }, [id]);

    

    useEffect(() => {
        load();
    }, [load]);

    const updateItem = (changes: Partial<PracticeItem>) => {
        // @ts-ignore
        const moreChanges = Object.keys(changes).reduce((r,k) => changes[k] === item[k] ? r : { ...r, [k]: changes[k]}, {});
        setItem(c => ({ ...c, ...changes }));

        setChanges(c => ({ ...c, ...moreChanges }));
    };

    const saveItem = () => {
        setIsLoading(true);
        return apiFetch<PracticeItem>(`/api/practice/${id}`, FetchTypes.PUT, changes)
            .then(setItem)
            .then(() => setChanges({}))
            .then(() => setIsLoading(false))
            .catch(e => { setIsLoading(false); throw e; });
    };

    
    const uploadDocument = (file: File, lang: string) => {
        if(item._id) {
            setIsDocumentUploading(true);
            apiUploadFile(`/api/practice/${item._id}/document${lang === "en" ? "?lang=en" : ""}`, FetchTypes.PUT, "file", file)
                .then(({ data }) => {
                    setIsDocumentUploading(false);
                    setItem({
                        ...item,
                        file_url: (data as PracticeItem).file_url,
                        file_url_en: (data as PracticeItem).file_url_en,
                    })
                })
                .catch(e => { setIsDocumentUploading(false); throw e; });
        }
    }

    const deleteDocument = (lang: string) => {
        if(item._id) {
            setIsDocumentUploading(true);
            apiFetch<PracticeItem>(`/api/practice/${item._id}/document${lang === "en" ? "?lang=en" : ""}`, FetchTypes.DELETE)
                .then(data => {
                    setIsDocumentUploading(false);
                    setItem({
                        ...item,
                        file_url: (data as PracticeItem).file_url,
                        file_url_en: (data as PracticeItem).file_url_en,
                    })
                })
                .catch(e => { setIsDocumentUploading(false); throw e; });
        }
    }

    const remove = () => apiFetch(`/api/practice/${item._id}`, FetchTypes.DELETE);

    return {
        data: item,
        setData: (x: any) => {},
        isUpdated: Object.keys(changes).length !== 0,
        isLoading,
        reload: load,
        update: updateItem,
        save: saveItem,
        uploadDocument,
        deleteDocument,
        isDocumentUploading,
        remove,
    };
}
