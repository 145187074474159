import React, { PropsWithChildren } from 'react';
import { Badge, BadgeProps } from "@mui/material";
import { CalendarEvent } from './useCalendarEvents';

export const BadgeAnchorTopLeft: BadgeProps["anchorOrigin"] = { horizontal: "left", vertical: "top"};
export const BadgeAnchorTopRight: BadgeProps["anchorOrigin"] = { horizontal: "left", vertical: "top"};


interface OwnershipBadgeProps {
  myEmployeeId: string | undefined;
  event: CalendarEvent;
}

export const OwnershipBadge = (props: PropsWithChildren<OwnershipBadgeProps>) => {
  return (
    <Badge
      variant="dot"
      color="primary"
      invisible={!props.event.owners?.includes(props.myEmployeeId || "")}
      anchorOrigin={BadgeAnchorTopLeft}>
      {props.children}      
    </Badge>
  );
}


interface ConflictsBadgeProps {
  hasConflict?: boolean;
}

export const ConflictsBadge = (props: PropsWithChildren<ConflictsBadgeProps>) => {
  return (
    <Badge variant="dot" color="error" invisible={!props.hasConflict} anchorOrigin={BadgeAnchorTopRight}>
      {props.children}      

    </Badge>
  );
}
