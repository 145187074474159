import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useFetch } from '../../api/useFetch';
import { Dashboard } from './Dashboard';
import { DashboardConfig } from './types';
import { useDashboard, queryParams } from './useDashboard';
import { DashItem } from '../EditDashboards/types';
import { Link } from '../primitives/Common.styles';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { Form } from '../primitives/Forms';
import { useHistory, useLocation } from 'react-router-dom';
import { useTrackHistory } from '../HistoryFavorites/useTrackHistory';

const DashboardSelectorWrapper = styled(FormControl)<{ editAllowed?: boolean }>`
    width: 460px;
    ${props => props.theme.breakpoints.down("sm")} {
        width: ${props => props.editAllowed ?  75 : 100}%;
    }
`;

export interface DashboardPageProps { 
    defaultDashboard: string;
    allowChangeDashboard?: boolean;
    hideTitle?: boolean;
    hideRefresh?: boolean;
    getEditLink?: (d: DashboardConfig | null) => string;
    isEmbeded?: boolean;
}

export type DashboardPageType = (props: DashboardPageProps) => JSX.Element;

export const getWidgetUrl = (id: string, params: any) => `/dashboard/widget/${id}/result${queryParams(params)}`;

export const DashboardPage: DashboardPageType = (props) => {
    const { defaultDashboard, allowChangeDashboard, getEditLink, hideTitle, isEmbeded } = props;
    const { data, loading } = useFetch<DashItem[]>([], {url: '/dashboard'});

    const [selected, setSelected] = useState<null | string>(null);

    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        const codeFromUrl = query.get("code");
        if(codeFromUrl) {
            setSelected(codeFromUrl);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectDashboard = (code: string) => {
        setSelected(code);
        query.set("code", code);
        history.replace({ pathname: location.pathname, search: `?${query.toString()}`});
    }

    const code = selected || query.get("code") || defaultDashboard;

    const dashData = useDashboard('/dashboard', code, getWidgetUrl);

    const editLink = getEditLink && getEditLink(dashData.dashboard);

    const dashTitle = dashData.dashboard?.title;
    const historyTitle = useMemo(() => {
        if(dashTitle && !isEmbeded) {
            return { id: "dashboard.screen.history_title", values: { title: dashTitle } }
        }
    }, [dashTitle, isEmbeded])

    useTrackHistory(historyTitle, { includeQuery: true });

    return <Form
            title={(allowChangeDashboard || hideTitle) ? "" : dashData.dashboard?.title}
            headerItems={!loading && <>
                {allowChangeDashboard &&
                    (<DashboardSelectorWrapper editAllowed={!!editLink}>
                        <InputLabel><FormattedMessage id="dashboard.screen.dash" /></InputLabel>
                        <Select value={code} onChange={(e) => selectDashboard((e.target as any).value)}>
                            {data.map(item => <MenuItem key={item.code} value={item.code}>{item.title}</MenuItem>)}
                        </Select>
                    </DashboardSelectorWrapper>)}
                <OccupyFreeSpace />
                {editLink && <Link to={editLink}><Button color="primary"><FormattedMessage id="dashboard.screen.edit" /></Button></Link>}
            </>}
            formPaperProps={isEmbeded ? { elevation: 0, style: { paddingLeft: 0, paddingRight: 0 } } : undefined}>
        <Dashboard data={dashData} hideTitle hideRefresh={props.hideRefresh} />
    </Form>;
} 