import { WarningOutlined } from '@mui/icons-material';
import React from 'react';
import { useParams } from 'react-router';
import { useItemWithControls } from '../../../toolympus/api/useWithControls';
import { useTrackHistory } from '../../../toolympus/components/HistoryFavorites';
import { AlertColor, Tooltip } from '../../../toolympus/components/primitives';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { SaveButton } from '../../../toolympus/components/primitives/Buttons';
import { Form, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { SelectButton } from '../../../toolympus/components/primitives/SelectButton';
import { TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives/Tabs';
import { useSaveable } from '../../../toolympus/components/primitives/useSaveable';
import { PlaceholderField, TableForFields } from '../../../toolympus/components/schemed';
import { SimpleActions } from '../../../toolympus/components/StatusAction';
import { mergeSchema, useSchema } from '../../../toolympus/hooks/useSchema';
import { Comments } from '../../Comments';
import { PresidiumDropMenu } from '../Menu/PresidiumDropMenu';
import { questionFieldLink, questionVotesOf } from '../Questions';
import { QuestionStatus } from '../Questions/QuestionStatus';
import { QuestionType_JuryFormation } from '../Questions/usePresidiumQuestions';
import { usePresidiumVote } from './usePresidiumVote';
import { addRemainingVoteTime, VoteSchemaConfig } from './VoteEnhancements';
import { VoteStatus } from './VoteStatus';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';


export const PresidiumVotePage = () => {
    const { id } = useParams<{ id: string }>();
    const data = usePresidiumVote(id);
    const { controls } = useItemWithControls(
        { ...data, data: addRemainingVoteTime(data.data) },
        VoteSchemaConfig
    );
    const { presidium_question: questionSchema } = useSchema();

    const tabs = useTabsState([['questions', 'Вопросы']], 'questions');

    const disableEdits = data.data.status !== "draft" ? { disabled: true } : {};

    useSaveable(data);
    useTrackHistory(data.data._id ? `Голосование ${data.data.batch}` : undefined);

    return (
        <Form
            title={<><PresidiumDropMenu current="votes" /> Голосование</>}
            headerItems={<>
                {data.isLoading && <LoadingIndicator />}
                <OccupyFreeSpace />
                
                <Buttons gap="small">

                  <SimpleActions
                      actions={[
                        data.canLaunch && { label: "запустить", action: data.launch },
                        data.canFinish && { label: "завершить", action: data.finish },
                        data.canCloseDown && { label: "снять", action: data.closeDown },
                      ]}
                      />
                  <PluggableDocumentationButton documentationKey="board_questions" />
                  {data.hasChanges && <SaveButton action={() => data.save()} />}
                  {id && <Comments entity="presidium_vote" recordId={id} />}
                  <VoteStatus vote={data.data} />
                </Buttons>
            </>}
            >
            <FormGrid>
                {controls([
                        ["committee", disableEdits],
                        [PlaceholderField],
                        [PlaceholderField],

                        ["start_date", disableEdits],
                        ["suggestions_end_date", disableEdits],
                        ["vote_start_date", disableEdits],

                        ["vote_end_date", disableEdits],
                        ["end_date", disableEdits],
                        ["remaining", { disabled: true }],
                    ])}
            </FormGrid>

            <TabsHeader state={tabs} />

            <TabPanel state={tabs.forTab('questions')}>
                <TableForFields
                    fields={[
                        ["action", { width: 50 }],
                        ["warn", { width: 50, label: " " }],
                        ["title"],
                        ["committee"],
                        ["question_type"],
                        ["casenbr"],
                        ["votes_of", { label: "Проголосовали" }],
                        ["status"],
                    ]}
                    getRowId={r => `${r._id}${r.poll_id}`}
                    data={data.questions.all}
                    schema={mergeSchema(questionSchema, { action: { label: ' '}})}
                    fieldLink={questionFieldLink}
                    fieldElement={f => {
                        switch(f) {
                            case "action":
                                return row => <SelectButton
                                    selectTooltip="Добавить"
                                    deselectTooltip="Исключить"
                                    isSelected={row.poll_id === id}
                                    select={() => !disableEdits.disabled && data.questions.add(row._id)}
                                    deselect={() => !disableEdits.disabled && data.questions.remove(row._id)} />;
                            case "warn":
                                return (row,_,orig) => !!row.poll_id && row.question_type === QuestionType_JuryFormation && (row.candidates_count || 0) === 0
                                    ? <Tooltip text="Для вопроса не указаны кандидаты"><WarningOutlined htmlColor={AlertColor.warning} /></Tooltip>
                                    : <></>;
                            case "status":
                                return row => <QuestionStatus question={row} />;
                            case "votes_of":
                                return questionVotesOf;
                        }}}
                    />
            </TabPanel>
        </Form>
    );
}
