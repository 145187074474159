import React from 'react';
import styled from '@emotion/styled';
import { NumberingSequencesData } from './useNumberingSequences';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';
import { LoadingIndicator, PseudoLink2, useCopyText } from '../primitives';
import { Refresh } from '@mui/icons-material';

interface Props {
  data: NumberingSequencesData;
}

const GetNumberButton = styled(Button)<{ isReady?: boolean }>`
  width: 300px;
  height: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: none;
  
  border-radius: 36px;
  font-weight: ${props => props.isReady ? 500 : 400};

  cursor: pointer;
  white-space: pre-line;
`;

export const GetNumberWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  gap: 12px;
`;

export const GetNumberBlock = (props: Props) => {
  const { data } = props;
  const copyText = useCopyText();
  

  return (<>
      <GetNumberButton
        isReady={data.current?.is_active}
        color="primary"
        variant={data.current?.is_active && !data.generatedNumber ? "contained" : "outlined"}
        role="button"
        onClick={() => {
          if(data.generateNumber.isRunning) {
            return;
          } else if(data.generatedNumber) {
            data.reset();
          } else if(data.generateNumber.canRun) {
            data.generateNumber.run()
          }
        }}
        >
          {!data.current &&
            <FormattedMessage id="numbering.empty_select_sequence" />}
          {data.generateNumber.isRunning &&
            <LoadingIndicator color="inherit" />}
          {!!data.generatedNumber &&
            <Refresh fontSize="large" />}
          {data.generateNumber.canRun &&
            <FormattedMessage id="numbering.generate" values={{ title: data.current?.title }} />}
        
      </GetNumberButton>
      {!!data.generatedNumber &&
        <Typography onClick={() => copyText(data.generatedNumberFormatted)}><FormattedMessage id="numbering.generated_number" /><PseudoLink2>{data.generatedNumber}</PseudoLink2></Typography>}
    </>
  );
}
