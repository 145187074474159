import React from 'react';
import { Panel, PanelContainer } from './Number.style';
import { WidgetConfig } from '../../types';

export type PlaceholderType = (props: { config?: WidgetConfig, getColumnWidth?: (cols: number) => string }) => JSX.Element

export const Placeholder: PlaceholderType = ({ config, getColumnWidth = () => '100%' }) => {
    return (
        <PanelContainer style={{width: getColumnWidth(config?.display_settings?.width || 1)}}>
            <Panel elevation={0} />
        </PanelContainer>)
}