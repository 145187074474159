import React, { useMemo } from 'react'
import styled from '@emotion/styled';
import { Button, DialogActions, DialogContent } from '@mui/material'
import { useForm } from '../../hooks/useForm'
import { DialogButton } from '../primitives/DialogButton'
import { LoadingIndicator } from '../primitives/LoadingIndicator'
import { EditForm } from './EditForm'
import { useEditDash } from './useEditDash'
import { Form } from '../primitives/Forms'
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { FormattedMessage } from 'react-intl'
import { Select } from '../schemed/Select';
import { createSelectSchema } from '../../hooks/useSchema';
import { DataschemaContextLoadedProvider } from '../Dataschema';
import { useQuery } from '../../hooks/useQueryParamsState';

const formAddData = {
    code: '',
    title: '',
}

const DashboardSelectorWrapper = styled.div`
    width: 500px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 10px;
`;


interface Props {
    data: ReturnType<typeof useEditDash>;
};

export const EditDashboards = (props: Props) => {
    const { data, loading, add, dashboardCode, setDashboardCode } = props.data;
    const formAdd = useForm(formAddData, { required: ['code', 'title'] });

    const selectorSchema = useMemo(() => {
      return createSelectSchema(
        data.map(item => ({ value: item.code, label: `${item.title} (${item.code})` })),
        { label_id: "dashboard.screen.dash" }
      );
    }, [data]);

    return (
      <Form
        title=""
        headerItems={loading || !data
          ? <LoadingIndicator />
          : (<>    
              <DashboardSelectorWrapper>
                  <Select
                    field="code"
                    row={{ code: dashboardCode }}
                    onChange={(o,c) => setDashboardCode(c.code)}
                    schema={selectorSchema}
                    />
              </DashboardSelectorWrapper>
              <OccupyFreeSpace />

              <DialogButton button={<Button color="primary"><FormattedMessage id="dashboard.screen.create" /></Button>}>{close => <>
                  <DialogContent>
                      {formAdd.input('code', { label: <FormattedMessage id="dashboard.screen.code" /> })}
                      {formAdd.input('title', { label: <FormattedMessage id="dashboard.screen.title" /> })}
                  </DialogContent>
                  <DialogActions>
                      <Button disabled={!formAdd.valid} onClick={close(() => add(formAdd.state))}><FormattedMessage id="dashboard.screen.create" /></Button>
                  </DialogActions>
              </>}
              </DialogButton>
            </>) }>
        {dashboardCode && <EditForm code={dashboardCode} /> }
      </Form>
    );
}


interface ContainerProps {
  dataschemaApiPath?: string;
  dataschemaCode?: string;
}

export const EditDashboardsContainer = (props: ContainerProps) => {
  const defaultCode = useQuery().get("code") || null;
  const data = useEditDash({ defaultDashboardCode: defaultCode });
  
  return (
    <DataschemaContextLoadedProvider apiPath={props.dataschemaApiPath} code={props.dataschemaCode}>
      <EditDashboards data={data} />
    </DataschemaContextLoadedProvider>
  )
}
