import { IPageMarginAttributes, ISectionPropertiesOptions, convertMillimetersToTwip } from "docx-dotx-hf-fix";
import { CustomFieldsBlockElementType, extractCustomBlockFields } from "../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock";

const prepareDocumentMarginSettings = (settings: any): IPageMarginAttributes | undefined => {
  if(settings?.margin_left || settings?.margin_right || settings?.margin_top || settings?.margin_bottom) {
    return {
      left: settings?.margin_left ? convertMillimetersToTwip(settings.margin_left) : undefined,
      right: settings?.margin_right ? convertMillimetersToTwip(settings.margin_right) : undefined,
      top: settings?.margin_top ? convertMillimetersToTwip(settings.margin_top) : undefined,
      bottom: settings?.margin_bottom ? convertMillimetersToTwip(settings.margin_bottom) : undefined,
    }
  }

  return undefined;
}

export const readDocumentSettings = (content: any[]): ISectionPropertiesOptions => {
  const settings = content.map(b => {
    if(b.type === CustomFieldsBlockElementType) {
      const fields = extractCustomBlockFields(b);
      if(fields.block_type === "document_settings") {
        return fields;
      }
    }
    return null;
  }).find(x => !!x);

  if(!settings) {
    return {};
  }
  

  const margins = prepareDocumentMarginSettings(settings);

  if(margins) {
    return {
      page: {
        margin: margins,
      }
    }
  }

  return { };
}
