import { Button } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { SaveButton } from '../../toolympus/components/primitives/Buttons';
import { DeleteButton } from '../../toolympus/components/primitives/DeleteButton';
import { SimpleDialog } from '../../toolympus/components/primitives/Dialogs';
import { Form, FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives/Tabs';
import { useSaveable } from '../../toolympus/components/primitives/useSaveable';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { HistoryChangesDisplay } from './HistoryChangesDisplay';
import { PartiesForm } from './PartiesForm';
import { ChangesHistorySchema, useEditCorpDispute } from './useEditCorpDispute';


export const CorpDisputePage = () => {
    const { id } = useParams<{ id: string }>();
    const data = useEditCorpDispute(id);

    const tabs = useTabsState([
        ["info", "Детали"],
        ["parties", "Стороны"],
        ["history", "Изменения"],
    ], "info", "tab", "__corp_disputes_tab");

    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            fields={fields}
            schema={data.schema}
            data={data.data}
            onChange={(o,c) => data.update(c)}
            />
    );

    const isPublished = data.data.is_published || false;

    useSaveable(data)

    return (
        <Form title="Корпоративный спор"
            headerItems={<ActionRow itemMarginTop="0" alignItems="flex-end">
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                {!data.data.is_published && <DeleteButton size="small" title="Удалить запись?" remove={data.remove} />}
                {data.hasChanges && <SaveButton action={() => data.save()} />}
            </ActionRow>}>

            <FormGrid key="1" columns="1fr 2fr 2fr 2fr" suppressAreasOnSm>
                {controls([
                    ["entity_title", { wrapperStyle: { gridColumn: "span 3" }, readOnly: isPublished }],
                ])}

                {data.data.is_published
                    ? controls([["published_date", { readOnly: true }]])
                    : <Button color="primary" style={{ justifySelf: "end" }} onClick={() => data.publish.startEditing()}>Опубликовать</Button>}

                {controls([
                    ["casenbr", { readOnly: isPublished }],
                    ["entity_inn", { readOnly: isPublished }],
                    ["entity_ogrn", { readOnly: isPublished }],
                    ["arbitration_setup_deadline", { readOnly: isPublished }],
                ])}
            </FormGrid>

            <TabsHeader state={tabs} />

            <TabPanel key="info" state={tabs.forTab("info")}>
                <FormGrid columns="1fr">
                    {controls([
                        ["document_title"],
                        ["claim_details", { autoRows: true }],
                    ])}
                </FormGrid>
            </TabPanel>


            <TabPanel key="parties" state={tabs.forTab("parties")}>
                <FormGrid columns="1fr">
                    {controls([
                        ["is_indirect_claim"],
                    ])}
                </FormGrid>

                <PartiesForm data={data} />
            </TabPanel>

            <TabPanel key="history" state={tabs.forTab("history")}>
                <TableForFields
                    data={data.data.changes || []}
                    fields={[
                        ["date"],
                        ["description"],
                        ["change"],
                    ]}
                    schema={ChangesHistorySchema}
                    fieldElement={f => {
                        if(f === "change") {
                            return row => <HistoryChangesDisplay schema={data.schema} data={row} />
                        }
                    }}
                    />
            </TabPanel>


            <SimpleDialog
                key="publishing"
                isOpen={data.publish.isEditing}
                close={data.publish.cancel}
                dialogTitle="Опубликовать информацию"
                saveLabel="Опубликовать"
                maxWidth="xs"
                noFullscreen
                save={() => data.publish.save()}
                >
            
                <FormControlsForFields
                    fields={[
                        ["published_date"]
                    ]}
                    schema={data.schema}
                    data={data.publish.item}
                    onChange={(o,c) => data.publish.update(c)}
                    />
            </SimpleDialog>

            <SimpleDialog
                key="saving_published"
                isOpen={data.isSavingPublished}
                close={data.cancelSaving}
                dialogTitle="Обновить информацию"
                saveLabel="Обновить"
                noFullscreen
                save={() => data.save()}
                >
            
                <FormControlsForFields
                    fields={[
                        ["date"],
                        ["description"],
                    ]}
                    schema={ChangesHistorySchema}
                    data={data.data.change_description || {}}
                    onChange={(o,c) => data.update({ change_description: { ...o, ...c } })}
                    />
            </SimpleDialog>
        </Form>
    );
}
