import React, { PropsWithChildren, useContext } from 'react';
import { DocumentationDoc } from './useDocumentationPages';

export const CurrentDocumentationPageContext = React.createContext<DocumentationDoc | null>(null);

export const useCurrentDocumentationPage = () => useContext(CurrentDocumentationPageContext);

export const CurrentDocumentationPageProvider = (props: PropsWithChildren<{ page: DocumentationDoc }>) => {
    return (
        <CurrentDocumentationPageContext.Provider value={props.page}>
            {props.children}
        </CurrentDocumentationPageContext.Provider>
    );
}
