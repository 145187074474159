import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { useSignupConfirmation } from '../../hooks/auth';
import { LoadingIndicator } from '../primitives/LoadingIndicator';

interface SignupConfirmationFormProps {
    signupConfirmationApiPath: string;
    redirectPath?: string;
    defaultMessage?: ReactNode;
    successMessage?: ReactNode;
    errorMessage?: ReactNode;
}

const ConfirmationSignupView = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const MessageView = styled.div`
    margin-bottom: 1rem;
`

export const SignupConfirmationForm: React.FC<SignupConfirmationFormProps> = (props: SignupConfirmationFormProps) => {
    const { signupConfirmationApiPath, redirectPath, defaultMessage, successMessage, errorMessage } = props;
    const { isSaving, isSaved, isError } = useSignupConfirmation(signupConfirmationApiPath, redirectPath);

    const error = errorMessage || <Typography><FormattedMessage id="signupConfirmation.errorMessage"/></Typography>;
    const success = successMessage || <Typography><FormattedMessage id="signupConfirmation.successMessage"/></Typography>;
    const defaultM = defaultMessage || <Typography><FormattedMessage id="signupConfirmation.defaultMessage"/></Typography>;

    const currentMessage = isError ? error : (isSaving && !isSaved ? defaultM : success);

    return (
        <ConfirmationSignupView>
            <MessageView>
                {currentMessage}
            </MessageView>
            {isSaving && <LoadingIndicator sizeVariant='l' />}
        </ConfirmationSignupView>

    )
};
