import { PhotoOutlined } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import { Editor, Element, Transforms } from "slate";
import { ReactEditor, RenderElementProps } from "slate-react";
import { CustomElement } from "../../../../../slate";
import { MediaFile, useMediaLibContext, MediaLib } from "../../../medialib";
import { MediaLibPickerDialog } from "../../../medialib/MediaLibPickerDialog";
import { EditorPlugin } from "../../slate/PowerEditorConfig";
import { MediaFilesElement, MediaFilesElementType } from "./MediaFilesElement";
import { PreviewImageDialog } from "./PreviewImageDialog";

const insertMediaFiles = (editor: Editor, files: MediaFile[] = []) => {
    editor.insertNode({
        type: MediaFilesElementType,
        files,
        children: [{ text: "", }],
    });
}

const updateMediaFiles = (editor: Editor, element: CustomElement, selectedFiles: MediaFile[]) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
        editor,
        {
            files: selectedFiles,
        } as any,
        { at: path },
    );
}

const getActiveMediaFiles = (editor: Editor) => {
    const [found] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === MediaFilesElementType,
    });
    
    if(found) {
        return found[0] as Element;
    } else {
        return undefined;
    }
}

export const createWithMediaFiles = (medialib: MediaLib) => (editor: Editor) => {
    const { isVoid, insertData } = editor;

    editor.isVoid = (element: CustomElement) => {
        return element.type === MediaFilesElementType ? true : isVoid(element);
    }

    editor.insertData = data => {
        const files = data.files;
        if(files && files.length) {
            medialib.uploadFile(files[0])
                .then(mf => insertMediaFiles(editor, [mf]));
            return;
        }

        insertData(data);
    }
    
    return editor;
}

interface EditingContext {
    element: CustomElement;
    editor: Editor;
}

export const useMediaFilesPlugin = (): EditorPlugin => {
    const [editingContext, setEditingContext] = useState<EditingContext | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<MediaFile[]>([]);
    const [previewImage, setPreviewImage] = useState<MediaFile | null>(null);
    const medialib = useMediaLibContext();
    const { getFilepath } = medialib;

    const openBrowser = (editor: Editor, element: CustomElement) => {
        setEditingContext({ editor, element });
        setSelectedFiles(element.files || []);
    }

    const finishEditing = () => {
        if(editingContext) {
            updateMediaFiles(editingContext.editor, editingContext.element, selectedFiles);
        }
        setEditingContext(null);
        setSelectedFiles([]);
    }

    const MediaFilesElementMemo = useMemo(() => {
        return (props: RenderElementProps) => <MediaFilesElement openBrowser={openBrowser} openPreview={setPreviewImage} {...props} />;
    }, []);

    const dialogs = (<>
        <MediaLibPickerDialog
            isOpen={!!editingContext}
            close={finishEditing}
            selectFile={mf => setSelectedFiles(existing => [...existing, mf])}
            unselectFile={mf => setSelectedFiles(existing => existing.filter(f => f._id !== mf._id))}
            selectedUrls={selectedFiles.map(getFilepath)}
            />
        <PreviewImageDialog
            image={previewImage}
            close={() => setPreviewImage(null)}
            />
        </>);

    return {
        key: "media-files",
        customBlocks: { [MediaFilesElementType]: MediaFilesElementMemo
        },
        inject: createWithMediaFiles(medialib),
        commands: [{
            name: "insert-media-files",
            invoke: e => {
                insertMediaFiles(e);
                const element = getActiveMediaFiles(e);
                if(element) {
                    openBrowser(e, element);
                }
            },
            menu: { section: "insert-item", icon: <PhotoOutlined />, label: "Media files", label_id: "powerdoc.plugins.media_files.title" },            
        }],
        dialogs,
    };
}
