import React, { ReactNode } from "react";
import { apiFetch, FetchTypes } from "../../api/core";
import { useLoadedData } from "../../hooks/useLoadedData"
import { useSendData } from '../../hooks/useSendData';
import { CommentsForm } from "./CommentsForm";

export interface Comment {
    _id: string;
    entity: string;
    record_id: string;
    user_id: string;
    comment: string;
    created_at: string;
    is_resolved?: boolean;

    user_name: string;
}

export interface CommentsData {
    comments: Comment[];
    isLoading: boolean;
    
    newComment: string;
    setNewComment: (v: string) => void;
    addNew: () => void;
    remove: (c: Comment) => Promise<void>;
    isRemoveAllowed: (c: Comment) => boolean;
}

export interface Settings {
    isRemoveAllowed?: (c: Comment) => boolean;
    getUserName?: (userId: string) => string;
    suppressLoad?: boolean;
}

export const useComments = (apiPrefix: string, entity: string, recordId: string, settings?: Settings): CommentsData => {
    const path = `${apiPrefix}/${entity}/${recordId}`;

    const data = useLoadedData<Comment[]>(path, [], !settings?.suppressLoad);
    const newComment = useSendData<{ comment: string }, Comment>(path, { comment: "" }, {
        onSuccess: (c: Comment) => { newComment.update({ comment: "" }); data.reload(); },
    });

    const hideComments = data.data.length > 0 && data.data[0].record_id !== recordId;

    const addUserName = (c: Comment) => {
        if(settings?.getUserName) {
            c.user_name = settings.getUserName(c.user_id);
        } else {
            c.user_name = c.user_id;
        }
        return c;
    }

    const remove = (c: Comment) =>
        apiFetch<void>(`${apiPrefix}/${c._id}`, FetchTypes.DELETE)
        .then(() => data.reload());

    return {
        comments: hideComments ? [] : data.data.map(addUserName),
        newComment: newComment.data.comment,
        setNewComment: v => newComment.update({ comment: v }),
        addNew: newComment.send,

        remove,
        isRemoveAllowed: settings?.isRemoveAllowed || (() => false),

        isLoading: data.isLoading || newComment.isSaving,
    }
}

export interface CommentsDataWithButton extends CommentsData {
    button: ReactNode;
}

export const useCommentsWithButton = (apiPrefix: string, entity: string, recordId: string, settings?: Settings): CommentsDataWithButton => {
    const data = useComments(apiPrefix, entity, recordId, settings);
    const button = (<CommentsForm data={data} />);
    return {
        ...data,
        button,
    }
}
